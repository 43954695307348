import { RequestHelper } from "../../../../../helpers/requestHelper";
import { SesionService } from "../../../../../services/sesion.service";
import { CuotaCreditoVenta } from "../../../../ventas/types/types";

export const cuotasCreditosService = {
  cargarCuotasCompra
}

async function cargarCuotasCompra(find): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      codigo: find
    }
    const data = await RequestHelper.get<any>('proveedores/general', 'paymentsPurchases/getBuyPayment', "", obj);
    const itemsParsed = parseApiItemsData(data);
    return itemsParsed;
  } catch (error) {
    return error;
  }
}

// parseInt(x.codigo)
function parseApiItemsData(apiresult: Array<any>): Array<CuotaCreditoVenta> {
  const items = apiresult.map((x, i = 0) => {
    const item: CuotaCreditoVenta = {
      numero: i + 1,
      letra: x.letra,
      cuota: x.cuota,
      vencimiento: x.vencimiento,
      saldo: parseFloat(x.saldo),
      estado: String(x.estadoDes)
    };
    return item;
  });
  return items;
}
