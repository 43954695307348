import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptionGrupos } from "../../../../store/types";
import { Autorizacion, AutorizacionDatosEdicion, InfoAdicional, ModalAutorizacionState, OpcionComprobante } from "./types";

const initialState: ModalAutorizacionState = {
  codigoModulo: 0,
  currentAction: "Buscar",
  currentFunction: "",
  autorizacionesListado: [],
  seleccionado: null,
  infoAdicional: null,
  formulario: {
    mode: "none",
    autorizacionCompartida: false,
    autorizacionImprenta: "",
    codigo: 0,
    codigoAsociado: 0,
    codigoPertenece: 0,
    codigoTipoDocumento: "01",
    descripcionPertenece: "",
    descripcionTipoDocumento: "FACTURA",
    establecimiento: "",
    estado: true,
    fechaCaducidad: "",
    identificacionAsociado: "",
    nombreAsociado: "",
    numeracioDesde: 1,
    numeracionHasta: 0,
    numeroActual: "",
    numeroAutorizacion: "",
    puntoEmision: "",
    tipoDocumento: null,
    tiposDocumentos: [],
    comprobanteElectronico: true,
    estados: [],
    estadoSeleccionado: null
  }
}

const modalAutorizacionSlice = createSlice({
  name: "modalAutorizacion",
  initialState: initialState,
  reducers: {
    setDatosEdicion(state, action: PayloadAction<AutorizacionDatosEdicion>) {
      state.formulario = action.payload
    },
    setInfoAdicional(state, action: PayloadAction<InfoAdicional>) {
      state.infoAdicional = action.payload
    },
    setCodigoModulo(state, action: PayloadAction<number>) {
      state.codigoModulo = action.payload
    },
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setRegistros(state, action: PayloadAction<Array<Autorizacion>>) {
      state.autorizacionesListado = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Autorizacion>) {
      state.seleccionado = action.payload
    },
    setResetSeleccion(state) {
      state.seleccionado = null
    },
    setTiposDocumentos(state, action: PayloadAction<Array<OpcionComprobante>>) {
      state.formulario.tiposDocumentos = action.payload
    },
    setTipoDocumento(state, action: PayloadAction<OpcionComprobante | null>) {
      state.formulario.tipoDocumento = action.payload
    },
    setMode(state, action: PayloadAction<string>) {
      state.formulario.mode = action.payload
    },
    setIsElectronico(state, action: PayloadAction<boolean>) {
      state.formulario.comprobanteElectronico = action.payload
    },
    setNumeroAutorizacion(state, action: PayloadAction<string>) {
      state.formulario.numeroAutorizacion = action.payload
    },
    setEstablecimiento(state, action: PayloadAction<string>) {
      state.formulario.establecimiento = action.payload
    },
    setPuntoEmision(state, action: PayloadAction<string>) {
      state.formulario.puntoEmision = action.payload
    },
    setNumeroActual(state, action: PayloadAction<string>) {
      state.formulario.numeroActual = action.payload
    },
    setFechaCaducidad(state, action: PayloadAction<string>) {
      state.formulario.fechaCaducidad = action.payload
    },
    setAutorizacionCompartida(state, action: PayloadAction<boolean>) {
      state.formulario.autorizacionCompartida = action.payload
    },
    setInicial(state, action: PayloadAction<number>) {
      state.formulario.numeracioDesde = action.payload
    },
    setFinal(state, action: PayloadAction<number>) {
      state.formulario.numeracionHasta = action.payload
    },
    setAutorizacionImprenta(state, action: PayloadAction<string>) {
      state.formulario.autorizacionImprenta = action.payload
    },
    setEstados(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.formulario.estados = action.payload
    },
    setEstadoSeleccionado(state, action: PayloadAction<OptionGrupos | null>) {
      state.formulario.estadoSeleccionado = action.payload
    },
  }
})

export const { setEstadoSeleccionado, setEstados, setAutorizacionImprenta, setFinal, setInicial, setAutorizacionCompartida, setFechaCaducidad, setNumeroActual, setPuntoEmision, setEstablecimiento, setNumeroAutorizacion, setIsElectronico, setMode, setTipoDocumento, setTiposDocumentos, setDatosEdicion, setInfoAdicional, setCodigoModulo, setCurrentFunction, setRegistros, setCurrentAction, setSeleccionarDato, setResetSeleccion } = modalAutorizacionSlice.actions;
export const modalAutorizacionReducer = modalAutorizacionSlice.reducer;