import * as React from 'react';
import { BuscarProveedorLookUp } from '../../../proveedores/components/busquedaProveedor/BuscarProveedorLookUp';
import { ProveedorBusqueda } from '../../../proveedores/store/types';
import { StatesEdition } from '../../../ventas/types/enums';

interface IBuscarProveedorLookUpMemoProps {
  proveedor: ProveedorBusqueda | null,
  tab: any,
  cambiarProveedorSeleccionado: any
}

const BuscarProveedorLookMemoUp: React.FC<IBuscarProveedorLookUpMemoProps> = (props) => {

  const { proveedor, tab, cambiarProveedorSeleccionado } = props;

  return (
    <BuscarProveedorLookUp
      selected={proveedor}
      allowEdit={tab.editStatus === StatesEdition.save ? false : true}
      allowAdd={tab.editStatus === StatesEdition.save ? false : true}
      allowClear={tab.editStatus === StatesEdition.save ? false : true}
      onChanged={cambiarProveedorSeleccionado}
      disabled={tab.editStatus === StatesEdition.save}
    />
  )
}

export default React.memo(BuscarProveedorLookMemoUp);
