import * as React from 'react';
import { CButton, CTooltip } from '@coreui/react';
import { faPlus, faUserEdit, faUserTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectBox } from 'devextreme-react/select-box';
import { AutorizacionDocumentoVenta, Cliente, ItemVenta } from '../../../types/types';
import { VentasService } from '../../../services/ventas.service';
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../views/componentes/colContainer';


interface IBuscarItemsLookUpProps {
  selected: ItemVenta | null,
  onChanged: (newValue: ItemVenta | null) => void,
  fecha: string,
  cliente: Cliente | null,
  allowEdit?: boolean,
  allowClear?: boolean,
  allowAdd?: boolean,
  disabled?: boolean,
}



export const BuscarItemVentasLookUp: React.FC<IBuscarItemsLookUpProps> = (props) => {
  const { selected, onChanged, allowAdd, allowClear, allowEdit, disabled, cliente, fecha } = props;
  const [mostrarEdicion, setMostrarEdicion] = React.useState(false);
  const [itemEditando, setItemEditando] = React.useState<ItemVenta | null>(null);
  //const [selectedInternal, setSelectedInternal] = React.useState<string>();
  //const ref = React.useRef<Lookup>(null);


  const datasource = React.useMemo(() => {
    return VentasService.getItemsDatasource(cliente, fecha);
  }, [cliente, fecha]);

  const onLookUpValueChanged = React.useCallback(({ value }) => {
    if (typeof (value) == 'string') return;
    onChanged(value);
  }, [onChanged]);

  const onModificarItemChanged = React.useCallback((item) => {
    setMostrarEdicion(false);
    setItemEditando(null);
    onChanged(item);
  }, [onChanged]);

  const onModificarItemCancel = React.useCallback(() => {
    setItemEditando(null);
    setMostrarEdicion(false);
  }, []);

  const getItemDisplayExpr = React.useCallback((item: ItemVenta) => {
    return item ? `${item.codigoBarras} - ${item.descripcion}` : '';
  }, []);

  // React.useEffect(() => {
  //   console.log('BuscarClienteLookUp cambio selected');
  //   setSelectedInternal(selected ? getClienteDisplayExpr(selected) : undefined)
  // }, [setSelectedInternal, getClienteDisplayExpr, selected])

  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  React.useEffect(() => {
    console.log('BuscarItemsLookUp cambio onChanged');
  }, [onChanged])

  // React.useEffect(() => {
  //   if(ref.current){
  //     console.log(ref.current);
  //   }
  // })

  if (allowAdd || allowEdit || allowClear) {
    return (
      <>
        <RowContainer gutters={false} >
          <CustomCol xs='12' md='9'>
            <SelectBox
              dataSource={datasource}
              searchEnabled
              displayExpr={getItemDisplayExpr}
              searchExpr={['descripcion']}
              searchTimeout={500}
              onEnterKey={(e) => { console.log(e) }}
              value={selected}
              onValueChanged={onLookUpValueChanged}
              onFocusIn={onSelectBoxFocusIn}
              disabled={disabled}
              minSearchLength={1}
            >
              {props.children}
            </SelectBox>
          </CustomCol>
          <CustomCol md='3' xs='6'  >
            <div style={{ display: 'flex' }}>
              {allowEdit && selected &&
                <CTooltip content='Editar Item' >
                  <div style={{ marginLeft: '1px' }} >
                    <CButton color='info' size='sm' onClick={() => {
                      setItemEditando(selected);
                      setMostrarEdicion(true);
                    }} >
                      <FontAwesomeIcon icon={faUserEdit} />
                    </CButton>
                  </div>
                </CTooltip>
              }
              {!disabled && allowAdd &&
                <CTooltip content='Crear Item'>
                  <div style={{ marginLeft: '1px' }} >
                    <CButton color='info' size='sm' onClick={() => {
                      setItemEditando(null);
                      setMostrarEdicion(true);
                    }} >
                      <FontAwesomeIcon icon={faPlus} />
                    </CButton>
                  </div>
                </CTooltip>
              }
              {!disabled && allowClear && selected &&
                <CTooltip content='Quitar item seleccionado'>
                  <div style={{ marginLeft: '1px' }} >
                    <CButton color='danger' size='sm' onClick={() => {
                      onChanged(null);
                    }} >
                      <FontAwesomeIcon icon={faUserTimes} />
                    </CButton>
                  </div>
                </CTooltip>
              }
            </div>
          </CustomCol>
        </RowContainer>
      </>
    );
  }

  return (
    <SelectBox
      dataSource={datasource}
      searchEnabled
      displayExpr={getItemDisplayExpr}
      searchExpr={['descripcion']}
      searchTimeout={500}
      onEnterKey={(e) => { console.log(e) }}
      value={selected}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
    >
      {props.children}
    </SelectBox>
  )
}
