import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccionMenu } from "../../../../../../store/types";
import { MenuState } from "../../../../../ventas/types/types";

const initialState: MenuState = {
  loading: true,
  accions: []
}

const menuModalVentasSlice = createSlice({
  name: 'ventasModalMenu',
  initialState: initialState,
  reducers: {
    setMenuModalButtons(state, action: PayloadAction<Array<AccionMenu>>) {
      state.accions = action.payload;
      state.loading = false;
    }
  }
})

//export const selectMenuVentas = (state: RootState) => { return state.ventas.menuVentas };
export const { setMenuModalButtons } = menuModalVentasSlice.actions;
export const menuModalVentasReducer = menuModalVentasSlice.reducer;