import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../helpers/dateUtils";
import { RootState } from "../../../../../store/store";
import { FETCH_STATUS, TiposComprobantesSri } from "../../../../../store/types";
import { VentasService } from "../../../services/ventas.service";
import { SearchState } from "../../../types/generics";
import {
  Cliente,
  EstablecimientoSri,
  EstadoComprobante,
  FiltroBusquedaState,
  FormaPagoModuloVentas,
  PuntoEmisionSri,
  VentaListado,
} from "../../../types/types";

const initialState: SearchState<VentaListado> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: "",
    cliente: null,
    activas: false,
    documento: "",
    formaPago: null,
    pendientesAbutorizar: false,
    establecimiento: null,
    estado: null,
    puntoEmision: null,
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: "",
  resultados: [],
};

export const fetchVentas = createAsyncThunk<
  Array<VentaListado>,
  FiltroBusquedaState
>("ventas/searchVentas", async (filtro) => {
  try {
    const ventas = await VentasService.getVentas(
      0,
      DateUtils.pickersDateToApiDate(filtro.fechaInicio) ?? "",
      DateUtils.pickersDateToApiDate(filtro.fechaFin) ?? "",
      filtro.puntoVenta,
      filtro.documento ?? undefined,
      filtro.estado ? filtro.estado.codigo.toString() : undefined,
      filtro.cliente?.identificacion,
      filtro.pendientesAbutorizar === undefined
        ? undefined
        : !filtro.pendientesAbutorizar,
      filtro.claveAcceso,
      filtro.formaPago ? filtro.formaPago.codigo.toString() : undefined,
      filtro.establecimiento
        ? filtro.establecimiento.establecimiento
        : undefined,
      filtro.puntoEmision ? filtro.puntoEmision.puntoEmision : undefined,
      TiposComprobantesSri.Factura
    );
    return ventas;
  } catch (error) {
    return Promise.reject(error);
  }
});

const searchVentasSlice = createSlice({
  name: "searchVentas",
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      };
    },
    setCustomer(state, action: PayloadAction<Cliente | null>) {
      state.filter.cliente = action.payload;
    },
    setSalesPoint(state, action: PayloadAction<string>) {
      state.filter.puntoVenta = action.payload;
    },
    setDateStart(state, action: PayloadAction<string>) {
      state.filter.fechaInicio = action.payload;
    },
    setDateEnd(state, action: PayloadAction<string>) {
      state.filter.fechaFin = action.payload;
    },
    setInvoiceNumber(state, action: PayloadAction<string>) {
      state.filter.documento = action.payload;
    },
    setInvoiceClaveAcceso(state, action: PayloadAction<string>) {
      state.filter.claveAcceso = action.payload;
    },
    setPendientesAutorizar(state, action: PayloadAction<boolean>) {
      state.filter.pendientesAbutorizar = action.payload;
    },
    setFormaPago(state, action: PayloadAction<FormaPagoModuloVentas | null>) {
      state.filter.formaPago = action.payload;
    },
    setStatus(state, action: PayloadAction<EstadoComprobante | null>) {
      state.filter.estado = action.payload;
    },
    setEstablecimiento(
      state,
      action: PayloadAction<EstablecimientoSri | null>
    ) {
      state.filter.establecimiento = action.payload;
    },
    setPuntoEmision(state, action: PayloadAction<PuntoEmisionSri | null>) {
      state.filter.puntoEmision = action.payload;
    },
    setCleanResult(state) {
      state.resultados = [];
    },
    setResultados(state, action: PayloadAction<Array<VentaListado> | []>) {
      state.resultados = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVentas.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING;
        state.mensaje = "";
      }
    });
    builder.addCase(fetchVentas.fulfilled, (state, { payload }) => {
      state.resultados = payload;
      state.status = FETCH_STATUS.SUCCESS;
    });
    builder.addCase(fetchVentas.rejected, (state, { payload }) => {
      state.resultados = [];
      state.status = FETCH_STATUS.FAILED;
      state.mensaje = payload as string;
    });
  },
});

export const selectFiltroBusquedaVentas = (state: RootState) => {
  return state.ventas.ventas.search.filter;
};
export const selectResultadosBusquedaVentas = (state: RootState) => {
  return state.ventas.ventas.search.resultados;
};
export const selectBusquedaVentasEstado = (state: RootState) => {
  return state.ventas.ventas.search.status;
};
export const {
  setResultados,
  setCustomer,
  setSalesPoint,
  setDateStart,
  setDateEnd,
  setInvoiceNumber,
  setInvoiceClaveAcceso,
  setPendientesAutorizar,
  setFormaPago,
  setEstablecimiento,
  setPuntoEmision,
  setStatus,
  resetState,
  setCleanResult
} = searchVentasSlice.actions;
export const searchVentasReducer = searchVentasSlice.reducer;
