import { CCol, CFormGroup, CLabel, CRow } from '@coreui/react';
import * as React from 'react';
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { CuadresService } from '../../../../../services/cuadres.service';
import { DateUtils, formatoFechasApi } from '../../../../../../../../../helpers/dateUtils';
import {
  updateDpCierre9Only, updateDptotales9Only, updateDptotalesOnly,
  updateTotalCom_tiOnly,
  updateTotalEgreso_tiOnly
} from '../../../store/editDataReducer';
import DataGrid, {
  Column, HeaderFilter, Paging, Pager,
  Grouping,
  GroupPanel,
} from 'devextreme-react/data-grid';
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../../../views/componentes/colContainer';
import { utilidades } from '../../../../../../../../../helpers/utilidades';
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize';
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper';
import { ComprasCuadresCajaListado, ccDetalle, formasPagoCuadre } from '../../../../../types/types';

interface IComprasCuadresProps {
  setToast: (texto, type) => void,
  playLoader: () => void,
  stopLoader: () => void,
  onErrorConfirm: (sms, title) => void,
  onConfirmarRegistro: (sms, method) => void
  tabId: string,
}

const ComprasCuadres: React.FunctionComponent<IComprasCuadresProps> = (props) => {
  const {
    tabId, setToast, playLoader, stopLoader,
    onErrorConfirm, onConfirmarRegistro
  } = props;
  const dataGrid = React.useRef<any>();

  const dispatch = useDispatch();
  const [heigth] = useGridResultadosSize();

  const local = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].local });
  const puntoVenta = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].puntoVenta });
  const fechaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fechaCierre });
  const venInicial9 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial9 });
  const venFinal9 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal9 });
  const generarCuadre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre });
  const totalcom_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].totalcom_ti });
  const dptotales = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales });
  const dpfpagos = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpfpagos });
  const dpcierre9 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre9 });

  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [showPageSizeSelector,] = React.useState<boolean>(true);

  const resultadoCuadresAbonos = React.useCallback(async (dpCierreFacturasVar: Array<ComprasCuadresCajaListado> | []) => {
    console.log("resultadoCuadresAbonos")
    console.log("dpCierreFacturasVar", dpCierreFacturasVar)
    console.log("dpfpagos", dpfpagos);

    let detalle = {
      ccdCodigo: 0,
      ccaCodigo: "",
      subValor: "",
      ccdValor: 0,
      subCodigo: 0,
    };

    let valor: number = 0;
    const dptotales9Local: Array<formasPagoCuadre> = [];
    if (dpCierreFacturasVar.length === 0) {
      for (const fpg of dpfpagos) {
        dptotales9Local.push({
          subCodigo: Number(fpg.codigo) ?? -1,
          subValor: String(fpg.descripcion) ?? "",
          ccdValor: valor,
        });
      }

      console.log("dptotales9Local", dptotales9Local)
      dispatch(updateDptotales9Only({
        dptotales9: dptotales9Local,
        key: tabId
      }));

    } else {

      let i = 0;

      for (let j = 0; j <= dpfpagos.length - 1; j++) {
        valor = 0;
        for (i = 0; i < dpCierreFacturasVar.length; i++) {
          if (dpCierreFacturasVar[i].formaPago === dpfpagos[j].codigo) {
            valor = valor + Number(Number(dpCierreFacturasVar[i].saldo).toFixed(2));
          }
        }

        dptotales9Local.push({
          subCodigo: Number(dpfpagos[j].codigo),
          subValor: String(dpfpagos[j].descripcion),
          ccdValor: Number(Number(valor).toFixed(2)),
        });

      }

      console.log("dptotales9Local", dptotales9Local)
      dispatch(updateDptotales9Only({
        dptotales9: dptotales9Local,
        key: tabId
      }));

      let k = 0;

      let total: number = 0;
      for (k = 0; k < dptotales9Local.length; k++) {
        total = total + dptotales9Local[k].ccdValor;
      }
      total = Number(Number(total).toFixed(2));
      dispatch(updateTotalCom_tiOnly({
        totalcom_ti: total,
        key: tabId
      }));
      dispatch(updateTotalEgreso_tiOnly({
        totalegreso_ti: total,
        key: tabId
      }));

      let dptotalesLocal: any = dptotales.slice();;
      console.log("dptotales9Local ", dptotales9Local);
      if (dptotales9Local.length > 0) {

        let x: number = -1;
        let i: number = 0;
        for (i = 0; i < dptotalesLocal.length; i++) {
          if (dptotalesLocal[i].subCodigo === 6) {
            x = i;
          }
        }

        if (x === -1) {
          let addDetallTotales: ccDetalle = {
            ...detalle,
            ccdValor: Number(Number(dptotales9Local[i].ccdValor).toFixed(2)),
            subCodigo: 6,
            subValor: 'EFECTIVO',
          }
          dptotalesLocal.push({ ...addDetallTotales });
        } else {
          dptotalesLocal[x].ccdValor = Number((dptotalesLocal[x].ccdValor - Number(dptotales9Local[0].ccdValor)).toFixed(2));
        }


        dispatch(updateDptotalesOnly({
          dptotales: dptotalesLocal,
          key: tabId
        }));

      }
    }


  }, [dispatch, tabId, dpfpagos, dptotales]);

  const getCuadresCompras = React.useCallback(async () => {
    console.log("getCuadresCompras");
    let fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi);
    let dpCierreRet = await CuadresService.getAbonosCompras(fechaFilter, local?.codigo ?? -1, puntoVenta?.codigoPuntoVenta ?? -1, venInicial9, venFinal9);
    console.log("getCuadresCompras dpCierreRet", dpCierreRet);

    dispatch(updateDpCierre9Only({
      dpcierre9: dpCierreRet,
      key: tabId
    }));

    await resultadoCuadresAbonos(dpCierreRet)
  }, [
    fechaCierre, tabId, dispatch, local, puntoVenta, venInicial9, venFinal9, resultadoCuadresAbonos
  ]);

  React.useEffect(() => {
    if (generarCuadre === true) {
      getCuadresCompras();
    }
  }, [generarCuadre])

  return (
    <>
      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">


          <RowContainer >
            <CustomCol xs='12' md='12'   >

              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr='compraCodigo'
                dataSource={dpcierre9}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode='widget'
                height={heigth}
                ref={dataGrid}
              >
                <Paging defaultPageSize={20}/>
                <Pager
                  visible={dpcierre9.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(dpcierre9)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <HeaderFilter visible={true} />
                <Column dataField='compraTipoDocumentoDescripcion' dataType='string' caption='Comprobante' width='15%' allowEditing={false} allowSearch={false} allowFiltering />
                <Column dataField='compraNumero' caption='Número' width='20%' allowEditing={false} />
                <Column dataField='proveedorNombre' caption='Proveedor' width='20%' allowEditing={false} />
                <Column dataField='compraFormaPagoDescripcion' caption='F. Pago' allowEditing={false} width={'15%'} />
                <Column dataField='compraTotal' caption='Total' width='15%' allowEditing={false} />
                <Column dataField='retencionCompraTotal' caption='Retención' width='15%' allowEditing={false} />
                <Column dataField='saldo' caption='Saldo' width='15%' allowEditing={false} />

              </DataGrid>

            </CustomCol>
          </RowContainer>

        </CCol>
      </CRow>


      <CRow >
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">

          <CCol lg='2' md="2" xs="12">
            <CFormGroup>
              <CLabel htmlFor="total" className="col-form-label-md">
                <strong> {"Total"} </strong>
              </CLabel>
              <CRow>
                <CCol md="12">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={totalcom_ti}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
              </CRow>
            </CFormGroup>
          </CCol>

        </CCol>
      </CRow>

    </>
  )
}
export default ComprasCuadres;
