import { FETCH_STATUS } from "../../../../store/types";
import { ButtonTypes } from "../../../../views/componentes/globalMenu/types";
import { StatesEdition, TabTypes } from "../../../ventas/types/enums";
import { FiltroBusquedaState, MenuState } from "../../../ventas/types/types";

export interface CustomDictionary<T> {
  [key: string]: T;
}

/**
 *
 */
export type DocumentInfo<T> = {
  numero: number;
  info?: T;
};

export type WindowVentasState<T, K> = {
  menu: MenuState;
  tabs: TabsStateComanda<T>;
  search: SearchState<T>;
  editData: CustomDictionary<K>; // Array<K>
};

export type TabsStateComanda<T> = {
  tabs: CustomDictionary<TabStateComanda<T>>;
  current: string;
};


export type CustomButtonsComanda = {
  [k in ButtonTypes]?: boolean;
}

/**
 * Estado del tab
 */
export type TabStateComanda<T> = {
  id: number;
  tittle: string;
  type: TabTypes;
  info?: DocumentInfo<T>;
  canClose: boolean;
  editStatus: StatesEdition;
  /**Controla los botones presionados fuera del tab (Un menu por ejemplo) y se guarda aquí el ultimo botone presionado,
   * una vez controlada la accion se deberia dejar como ButtonTypes.none
   */
  globalButtonClick: ButtonTypes;
  imprimir: boolean;
  /** Identificador del tab, debe ser unico e identifica el tab entre los demás */
  tabKey: string;
  /** Botones que se van a controlar y su estado.
   * Los botones que no aparezcan aquí se marcan como desabilitados / no usados y podrian
   * no aparecer en ciertas pantallas.
   */
  buttons: CustomButtonsComanda;
  currentExecutingAction?: ButtonTypes;
  templateMode?: boolean
};

export type SearchState<T> = {
  filter: FiltroBusquedaState;
  status: FETCH_STATUS;
  mensaje: string;
  resultados: Array<T>;
};

export type SearchStateCustomFilter<T, K> = {
  filter: K;
  status: FETCH_STATUS;
  mensaje: string;
  resultados: Array<T>;
};

export enum EnumTabs {
  TabSearch = 'BUSQUEDA'
}