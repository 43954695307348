import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../../store/store";
import { ConfiguracionesCuadres } from "../types/types";

const initialState: ConfiguracionesCuadres = {
  modalCuotas: {
    open: false,
    name: "",
    info: null
  },
  cuadreSeleccionado: null
}

const configuracionesCuadreSlice = createSlice(
  {
    name: 'configuraciones',
    initialState: initialState,
    reducers: {
      toogleModalDetalleOrden(state, acion: PayloadAction<any>) {
        state.modalCuotas = acion.payload;
      },
      seleccionarCuadre(state, acion: PayloadAction<any>) {
        state.cuadreSeleccionado = acion.payload;
      },
      resetSeleccioCuadre(state) {
        state.cuadreSeleccionado = null;
      }
    }
  }
)

export const configuracionesCuadreReducer = configuracionesCuadreSlice.reducer;
export const {
  toogleModalDetalleOrden, seleccionarCuadre, resetSeleccioCuadre
} = configuracionesCuadreSlice.actions;