import { RequestHelper } from "../../../helpers/requestHelper";
import { SesionService } from "../../../services/sesion.service";
import {
  LineaInventario,
  MarcaInventario,
  GrupoInventario,
  UnidadInventario,
  ItemInventario,
  IceInventario,
} from "../store/types";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { PagedResult, PaginationInfo } from "../../../store/types";

export const InventarioService = {
  getLineas,
  getLineasPaged,
  getLineasDatasource,
  getGrupos,
  getGruposPaged,
  getGruposDatasource,
  getMarcas,
  getMarcasPaged,
  getMarcasDatasource,
  getUnidades,
  getUnidadesDatasource,
  getIce,
  getItemXCodigoBarras
};

async function getLinea(codigo: number) {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    codigo: codigo,
  };
  const apiData = await RequestHelper.get<LineaInventario>(
    "inventario",
    "line/get",
    "",
    queryData
  );
  return apiData;
}

async function getLineas(descripcion: string) {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    descripcion: descripcion,
  };
  const apiData = await RequestHelper.get<Array<any>>(
    "inventario",
    "classification/all",
    "",
    queryData
  );
  const lineas = parseLineasApiData(apiData);
  return lineas;
}

function parseLineasApiData(apiData: Array<any>): Array<LineaInventario> {
  const lineas: Array<LineaInventario> = apiData.map((lineaApi) => {
    return {
      codigo: lineaApi.lineaCodigo,
      particular: lineaApi.lineaParticular,
      descripcion: lineaApi.lineaDescripcion,
      estado: lineaApi.lineaEstado === 1,
      estadoNombre: lineaApi.lineaEstadoDes,
      imagen: lineaApi.lineaImagen,
      conCodigo: lineaApi.conCodigo,
      conConcepto: lineaApi.conConcepto,
      susCodigo: lineaApi.susCodigo,
      utilidad: lineaApi.lineaUtilidad,
    };
  });
  return lineas;
}

async function getLineasPaged(
  descripcion: string,
  pagination: PaginationInfo
): Promise<PagedResult<LineaInventario>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    descripcion: descripcion,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  };
  const apiDataPaged = await RequestHelper.get<any>(
    "inventario",
    "classification/all",
    "",
    queryData
  );
  const apiData = apiDataPaged.auto as Array<any>; //await RequestHelper.get<Array<any>>('inventario', 'classification/all', '', queryData);
  const lineas = parseLineasApiData(apiData);
  const pagedResult: PagedResult<LineaInventario> = {
    currentPage: parseInt(apiDataPaged.paginaActual),
    from: parseInt(apiDataPaged.desde),
    to: parseInt(apiDataPaged.hasta),
    count: parseInt(apiDataPaged.totalRegistros),
    result: lineas,
  };
  return pagedResult;
}

function getLineasDatasource() {
  const store = new CustomStore({
    key: "codigo",
    byKey: async (key) => {
      var linea = await getLinea(key);
      return linea;
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      };
      let pagedResult: PagedResult<LineaInventario>;
      pagedResult = await getLineasPaged(
        loadOptions.searchValue ?? "",
        paginInfo
      );
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      };
    },
  });
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: "codigo",
  });
  return source;
}

async function getGrupos(descripcion: string) {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    descripcion: descripcion,
    estado: "1",
  };
  const apiData = await RequestHelper.get<Array<any>>(
    "inventario",
    "grupos/listar",
    "",
    queryData
  );
  const grupos = parseGruposApidata(apiData);
  return grupos;
}

function parseGruposApidata(apiData: Array<any>): Array<GrupoInventario> {
  const grupos: Array<GrupoInventario> = apiData.map((g) => {
    const grupo: GrupoInventario = {
      codigo: g.grupoCodigo,
      descripcion: g.grupoDescripcion,
      perteneceCodigo: g.grupoPertenece,
      perteneceNombre: g.grupoPerteneceDes,
      estadoCodigo: g.grupoEstado,
      estadoNombre: g.grupoEstadoDes,
      items: parseInt(g.ITEMS),
    };
    return grupo;
  });
  return grupos;
}

async function getGruposPaged(
  descripcion: string,
  pagination: PaginationInfo
): Promise<PagedResult<GrupoInventario>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    descripcion: descripcion,
    estado: "1",
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  };
  const apiDataPaged = await RequestHelper.get<any>(
    "inventario",
    "grupos/listar",
    "",
    queryData
  );
  const apiData = apiDataPaged.auto as Array<any>;
  const grupos = parseGruposApidata(apiData);
  const pagedResult: PagedResult<GrupoInventario> = {
    currentPage: parseInt(apiDataPaged.paginaActual),
    from: parseInt(apiDataPaged.desde),
    to: parseInt(apiDataPaged.hasta),
    count: parseInt(apiDataPaged.totalRegistros),
    result: grupos,
  };
  return pagedResult;
}

async function getGrupo(
  codigo: number
): Promise<GrupoInventario | null> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    codigo: codigo,
  };
  const apiData = await RequestHelper.get<GrupoInventario>(
    "inventario",
    "group/get",
    "",
    queryData
  );
  return apiData;
}

function getGruposDatasource() {
  const store = new CustomStore({
    key: "codigo",
    byKey: async (key) => {
      var linea = await getGrupo(key);
      return linea;
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      };
      let pagedResult: PagedResult<GrupoInventario>;
      pagedResult = await getGruposPaged(
        loadOptions.searchValue ?? "",
        paginInfo
      );
      console.log("current page ", pagedResult.currentPage);
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      };
    },
  });
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: "codigo",
  });
  return source;
}

async function getMarca(
  codigo: number
): Promise<MarcaInventario | null> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    codigo: codigo,
  };
  const apiData = await RequestHelper.get<MarcaInventario>(
    "inventario",
    "brand/get",
    "",
    queryData
  );
  return apiData;
}

async function getMarcas(nombre: string) {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    nombre: nombre,
  };
  const apiData = await RequestHelper.get<Array<any>>(
    "inventario",
    "brand/all",
    "",
    queryData
  );
  const marcas = parseMarcasApiData(apiData);
  return marcas;
}

async function getMarcasPaged(
  nombre: string,
  pagination: PaginationInfo
): Promise<PagedResult<MarcaInventario>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    nombre: nombre,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  };
  const apiDataPaged = await RequestHelper.get<any>(
    "inventario",
    "brand/all",
    "",
    queryData
  );
  const apiData = apiDataPaged.auto as Array<any>;
  const marcas = parseMarcasApiData(apiData);
  const pagedResult: PagedResult<MarcaInventario> = {
    currentPage: parseInt(apiDataPaged.paginaActual),
    from: parseInt(apiDataPaged.desde),
    to: parseInt(apiDataPaged.hasta),
    count: parseInt(apiDataPaged.totalRegistros),
    result: marcas,
  };
  return pagedResult;
}

function parseMarcasApiData(apiData: Array<any>): Array<MarcaInventario> {
  const marcas: Array<MarcaInventario> = apiData.map((marcaApi) => {
    return {
      codigo: marcaApi.marcaCodigo,
      nombre: marcaApi.marcaNombre,
      estado: marcaApi.marcaEstado,
      estadoNombre: marcaApi.marcaEstadoDes,
    };
  });
  return marcas;
}

function parseIceApiData(apiData: Array<any>): Array<IceInventario> {
  const ice: Array<IceInventario> = apiData.map((iceApi) => {
    return {
      codigo: iceApi.codigo,
      nombre: iceApi.nombre,
      descripcion: iceApi.descripcion,
      valor: iceApi.valor,
      vigencia: iceApi.vigencia,
      hasta: iceApi.hasta,
      estado: iceApi.estado,
      estadoDescripcion: iceApi.estadoDescripcion,
      tipo: iceApi.tipo,
      tipoValor: iceApi.tipoValor,
      adValorEm: iceApi.adValorEm,
      exFabrica: iceApi.exFabrica,
      exAduana: iceApi.exAduana,
      exhibir: iceApi.exhibir
    };
  });
  return ice;
}

function getMarcasDatasource() {
  const store = new CustomStore({
    key: "codigo",
    byKey: async (key) => {
      var linea = await getMarca(key);
      return linea;
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      };
      let pagedResult: PagedResult<MarcaInventario>;
      pagedResult = await getMarcasPaged(
        loadOptions.searchValue ?? "",
        paginInfo
      );
      console.log("current page ", pagedResult.currentPage);
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      };
    },
  });
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: "codigo",
  });
  return source;
}

async function getUnidades(): Promise<Array<UnidadInventario>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    estado: 1,
  };
  const cacheKey = "unidadesVenta" + JSON.stringify(queryData);
  const savedData = sessionStorage.getItem(cacheKey) ?? null;
  if (savedData) {
    return JSON.parse(savedData);
  }
  const apiData = await RequestHelper.get<Array<any>>(
    "inventario",
    "unidades/listar",
    "",
    queryData
  );
  const unidades = parseUnidadApiData(apiData);
  sessionStorage.setItem(cacheKey, JSON.stringify(unidades));
  return unidades;
}

function parseUnidadApiData(apiData: Array<any>): Array<UnidadInventario> {
  const unidades = apiData.map((u) => {
    const unidad: UnidadInventario = {
      codigo: u.codigo,
      nombre: u.nombre,
      siglas: u.siglas,
      grupoCodigo: u.grupoCodigo,
      grupoNombre: u.grupoNombre,
      estadoCodigo: u.estadoCodigo,
      estadoNombre: u.estadoNombre,
      fraccion: u.fraccion,
    };
    return unidad;
  });
  return unidades;
}

function getUnidadesDatasource() {
  const store = new CustomStore({
    key: "codigo",
    byKey: async (key) => {
      // var linea = await getCliente("", key);
      // return linea;
      return null
    },
    load: async (loadOptions) => {
      const unidades = await getUnidades();
      return unidades;
    },

  });
  const source = new DataSource({
    paginate: false,
    pageSize: 10,
    store: store,
    key: "codigo",
  });
  return source;
}


async function getIce(): Promise<Array<IceInventario>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    estado: 1,
  };
  const cacheKey = "iceVenta" + JSON.stringify(queryData);
  const savedData = sessionStorage.getItem(cacheKey) ?? null;
  if (savedData) {
    return JSON.parse(savedData);
  }
  const apiData = await RequestHelper.get<Array<IceInventario>>(
    "generales",
    "taxes/cBoxLoadICE",
    "",
    queryData
  );
  const ice = apiData; //parseIceApiData(apiData);
  sessionStorage.setItem(cacheKey, JSON.stringify(ice));
  return ice;
}

async function getItemXCodigoBarras(
  codigoBarras: string
): Promise<ItemInventario | null> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    itemMobile: -1,
    itemBarras: codigoBarras,
    exacta: 1,
  };

  const apiData = await RequestHelper.get<Array<ItemInventario>>(
    "inventario",
    "items/searchItemsBase",
    "",
    queryData
  );
  return apiData.length > 0 ? apiData[0] : null;
}
