import { ChildElement } from '@coreui/react';
import * as React from 'react';

interface IContainerProps {
  style?: React.CSSProperties,
  fluid?: boolean,
  children?: ChildElement
}

const Container: React.FunctionComponent<IContainerProps> = ({ style = {}, fluid = true, children }) => {
  return (
    <div className={`container${fluid ? '-fluid' : ''}`} style={style} >
      {children}
    </div>
  );
};

export default Container;
