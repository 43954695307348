import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../store/types'
import { Seleccionado } from '../../cheques/store/types'
import {
  ConciliacionState,
  conciliacionTotales,
  CuentaConciliacion,
  CuentaDetalleConciliacion,
  formularioBusqueda,
  formularioEdicion,
  Novedades,
  periodoContable,
  SaldoDetalleConciliacion
} from './types'

const initialState: ConciliacionState = {
  modulo: 'cconciliacionban',
  actionDisplay: '',
  currentAction: 'Buscar',
  currentFunction: "",
  registros: [],
  registrosDetalle: [],
  seleccionado: {
    index: null,
    row: []
  },
  transaccionesSeleccionadas: [],
  tipos: [],
  estados: [],
  conciliacionTotales: {
    saldoestado_ti: 0,
    depositost_ti: 0,
    cobrosp_ti: 0,
    saldoeajustado_ti: 0,
    saldolibros_ti: 0,
    creditos_ti: 0,
    debitos_ti: 0,
    saldolajustado_ti: 0
  },
  saldoanterior_ti: '',
  diferencia_ti: '',
  periodosContables: [],
  saldoDetalleConciliacion: [],
  novedades: [],
  currentExecute: null,
  loader: {
    show: false,
    mensaje: ''
  },
  formularioBusqueda: {
    fechaDesde: null,
    fechaHasta: null,
    banco: {
      codigo: 0,
      nombre: ""
    },
    mes: null,
    anio: null
  },
  formularioEdicion: {
    fechaDesde: null,
    fechaHasta: null,
    fechaRegistro: null,
    codigoBanco: null,
    valor: null,
    saldoAnterior: null,
    diferencia: null,
    descuento: null,
    estado: null,
    todo: null,
    tipo: null,
  }
}

const cconciliacionSlice = createSlice({
  name: 'cconciliacion',
  initialState: initialState,
  reducers: {
    setActionDisplay(state, action: PayloadAction<string>) {
      state.actionDisplay = action.payload
    },
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setData(state, action: PayloadAction<Array<CuentaConciliacion>>) {
      state.registros = action.payload
    },
    setDataDetalle(
      state,
      action: PayloadAction<Array<CuentaDetalleConciliacion>>
    ) {
      state.registrosDetalle = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      let toAdd = {
        index: action.payload.index,
        row: action.payload.row
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      let toAdd = {
        index: null,
        row: null
      }
      state.seleccionado = toAdd
    },
    setTransaccionesSeleccionadas(
      state,
      action: PayloadAction<Array<CuentaDetalleConciliacion>>
    ) {
      state.transaccionesSeleccionadas = action.payload
    },
    setTotalesConciliacion(state, action: PayloadAction<conciliacionTotales>) {
      state.conciliacionTotales = action.payload
    },
    setResetTotalesConciliacion(state) {
      let conciliacionTotales = {
        saldoestado_ti: 0,
        depositost_ti: 0,
        cobrosp_ti: 0,
        saldoeajustado_ti: 0,
        saldolibros_ti: 0,
        creditos_ti: 0,
        debitos_ti: 0,
        saldolajustado_ti: 0
      }
      state.conciliacionTotales = conciliacionTotales
    },
    setSaldoAnterior(state, action: PayloadAction<string>) {
      state.saldoanterior_ti = action.payload
    },
    setDiferencia(state, action: PayloadAction<string>) {
      state.diferencia_ti = action.payload
    },
    setPeriodosContables(
      state,
      action: PayloadAction<Array<periodoContable>>
    ) {
      state.periodosContables = action.payload
    },
    setSaldoDetalleConciliacion(
      state,
      action: PayloadAction<Array<SaldoDetalleConciliacion>>
    ) {
      state.saldoDetalleConciliacion = action.payload
    },
    setNovedades(
      state,
      action: PayloadAction<Array<Novedades>>
    ) {
      state.novedades = action.payload
    },
    setCurrentExecute(state, action: PayloadAction<any>) {
      state.currentExecute = action.payload
    },
    setChangeLoaderConciliaciones(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      let toAdd = {
        fechaDesde: action.payload.fechaDesde,
        fechaHasta: action.payload.fechaHasta,
        banco: action.payload.banco,
        mes: action.payload.mes,
        anio: action.payload.anio
      }
      state.formularioBusqueda = toAdd
    },
    setFormularioEdicion(state, action: PayloadAction<formularioEdicion>) {
      let toAdd = {
        fechaDesde: action.payload.fechaDesde,
        fechaHasta: action.payload.fechaHasta,
        fechaRegistro: action.payload.fechaRegistro,
        codigoBanco: action.payload.codigoBanco,
        valor: action.payload.valor,
        saldoAnterior: action.payload.saldoAnterior,
        diferencia: action.payload.diferencia,
        descuento: action.payload.descuento,
        estado: action.payload.estado,
        todo: action.payload.todo,
        tipo: action.payload.tipo,
      }
      state.formularioEdicion = toAdd
    },
  }
})
export const {
  setFormularioEdicion,
  setChangeLoaderConciliaciones,
  setFormularioBusqueda,
  setCurrentExecute,
  setData,
  setCurrentAccion,
  setSeleccionarDato,
  setResetSeleccion,
  setDataDetalle,
  setTransaccionesSeleccionadas,
  setTotalesConciliacion,
  setSaldoAnterior,
  setDiferencia,
  setPeriodosContables,
  setSaldoDetalleConciliacion,
  setResetTotalesConciliacion,
  setActionDisplay,
  setCurrentFunction
} = cconciliacionSlice.actions
export const CConciliacionReducer = cconciliacionSlice.reducer
