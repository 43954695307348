import React, { memo } from 'react';
import { DateUtils } from '../../../../../helpers/dateUtils';
import { RootState } from '../../../../../store/store';
import { Autorizacion, AutorizacionDatosEdicion, AutorizacionIngresar } from '../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { isMobileOnly, isMobile } from 'react-device-detect';
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../views/componentes/colContainer';
import ValidationSummary from 'devextreme-react/validation-summary';
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput';
import SelectBox from '../../../../../views/componentes/selectBox/selectBox';
import {
  Validator,
  RequiredRule,
  AsyncRule,
  StringLengthRule,
} from 'devextreme-react/validator';
import { setEstadoSeleccionado, setAutorizacionImprenta, setFinal, setInicial, setNumeroActual, setPuntoEmision, setEstablecimiento, setNumeroAutorizacion, setDatosEdicion, setTipoDocumento, setMode, setIsElectronico, setFechaCaducidad, setAutorizacionCompartida, setCurrentFunction, setCurrentAction, setRegistros } from '../../store/modalAutorizacion';
import { autorizacionService } from '../../service/autorizacionServices';
import { Switch } from 'devextreme-react/switch';
import TextBox from 'devextreme-react/text-box';
import { utilidades } from '../../../../../helpers/utilidades';
import DateBox from 'devextreme-react/date-box'
import NumberBox from 'devextreme-react/number-box'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types';
import { lh, MessagesKeys } from '../../../../../helpers/localizationHelper';

interface IFormularioEdicionProps {
  onChanged: (codigoProveedor: number) => void,
  currentTab: string,
  stopLoader: () => void
  playLoader: () => void
  setToast: (sms, type) => void
  onErrorConfirm: (data, tittle) => void
}

const FormularioEdicion = (props: IFormularioEdicionProps) => {
  const { stopLoader, playLoader, setToast, onErrorConfirm } = props;
  const dispatch = useDispatch();
  const validationGroupRef = React.useRef<any>();
  const inputAutorizacion = React.useRef<any>();
  const inputEstablecimiento = React.useRef<any>();
  const inputPuntoEmision = React.useRef<any>();
  const inputInicial = React.useRef<any>();
  const inputFinal = React.useRef<any>();
  const inputCaducidad = React.useRef<any>();
  const inputAutorizacionImprenta = React.useRef<any>();
  const inputNumeroActual = React.useRef<any>();



  const autorizacionesListado = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.autorizacionesListado);
  const autorizacionesSeleccionada = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.seleccionado);

  const currentTab = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.currentAction);
  const currentFunction = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.currentFunction);
  const infoAdicional = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.infoAdicional);
  const datosAutorizacion = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario);
  const codigoAutorizacion = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario?.codigo);
  const tiposDocumentos = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario?.tiposDocumentos);
  const tipoDocumento = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario?.tipoDocumento);
  const numeroAutorizacion = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario.numeroAutorizacion);
  const comprobanteElectronico = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario.comprobanteElectronico);
  const fechaCaducidad = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario.fechaCaducidad);
  const numeroActual = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario.numeroActual);
  const nombreAsociado = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario.nombreAsociado);
  const autorizacionCompartida = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario.autorizacionCompartida);
  const establecimiento = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario.establecimiento);
  const puntoEmision = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario.puntoEmision);
  const numeracioDesde = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario.numeracioDesde);
  const numeracionHasta = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario.numeracionHasta);
  const autorizacionImprenta = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario.autorizacionImprenta);
  const estadoSeleccionado = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario.estadoSeleccionado);
  const estados = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.formulario.estados);


  const modoNuevo = React.useCallback(async () => {
    validationGroupRef.current.instance.reset();
    dispatch(setMode("none"))

    const data = { ...defaulDatosEdicionAtorizacion }
    try {
      data.codigoAsociado = Number(infoAdicional?.codigoAsociado) ?? 0;
      data.nombreAsociado = String(infoAdicional?.nombreAsociado) ?? "";
      data.codigoPertenece = Number(infoAdicional?.codigoPertenece) ?? 0;
      let find = {
        mensaje: "Elija un tipo"
      }
      const tipoComp = await autorizacionService.getTiposComprobantes(find);
      if (tipoComp && tipoComp.length > 0) {
        const tipos = tipoComp.map(function (dat) {
          return {
            codigo: String(dat?.codigo) ?? "",
            descripcion: String(dat?.comprobante) ?? "",
          }
        })
        data.tiposDocumentos = tipos;
        data.tipoDocumento = tipos[0];
      }

      let findEstados = {
        tipoCodigo: 1,
        mensaje: "Elija un tipo"
      }
      const dpEstados = await autorizacionService.getEstados(findEstados);
      if (dpEstados && dpEstados.length > 0) {
        const tipos = dpEstados.map(function (dat) {
          return {
            value: Number(dat?.SUB_CODIGO) ?? -1,
            label: String(dat?.SUB_VALOR) ?? "",
          }
        })
        data.estados = tipos;
        data.estadoSeleccionado = tipos[0];
      }

      dispatch(setDatosEdicion(data))
    } catch (error) {
      console.error(error)
    }
  }, [dispatch, infoAdicional]);

  const cargarAutorizacion = React.useCallback(async (datosAutorizacion) => {
    dispatch(setMode("none"))

    let data = { ...defaulDatosEdicionAtorizacion }
    try {
      playLoader()
      data.codigoAsociado = Number(infoAdicional?.codigoAsociado) ?? 0;
      data.nombreAsociado = String(infoAdicional?.nombreAsociado) ?? "";
      data.codigoPertenece = Number(infoAdicional?.codigoPertenece) ?? 0;
      let find = {
        mensaje: "Elija un tipo"
      }
      const tipoComp = await autorizacionService.getTiposComprobantes(find);
      if (tipoComp && tipoComp.length > 0) {
        const tipos = tipoComp.map(function (dat) {
          return {
            codigo: String(dat?.codigo) ?? "",
            descripcion: String(dat?.comprobante) ?? "",
          }
        })
        data.tiposDocumentos = tipos;

        const tipoDoc = tipos.find(x => x.codigo === autorizacionesSeleccionada?.codigoTipoDocumento)

        if (tipoDoc) {
          data.tipoDocumento = tipoDoc;
        }
      }

      let findEstados = {
        tipoCodigo: 1,
        mensaje: "Elija un tipo"
      }
      const dpEstados = await autorizacionService.getEstados(findEstados);

      if (dpEstados && dpEstados.length > 0) {
        const tipos = dpEstados.map(function (dat) {
          return {
            value: Number(dat?.SUB_CODIGO) ?? -1,
            label: String(dat?.SUB_VALOR) ?? "",
          }
        })
        data.estados = tipos;

        const estadoSel = tipos.find(x => x.value === Number(autorizacionesSeleccionada?.estado))
        if (estadoSel) {

          data.estadoSeleccionado = estadoSel;
        }
      }

      data.codigo = Number(datosAutorizacion?.codigo) ?? 0;
      data.comprobanteElectronico = String(autorizacionesSeleccionada?.numeroAutorizacion).length === 10 ? false : true;
      data.numeroAutorizacion = autorizacionesSeleccionada?.numeroAutorizacion ?? "";
      data.establecimiento = autorizacionesSeleccionada?.establecimiento ?? "";
      data.puntoEmision = autorizacionesSeleccionada?.puntoEmision ?? "";
      data.numeracioDesde = autorizacionesSeleccionada?.numeracioDesde ?? 0;
      data.numeracionHasta = autorizacionesSeleccionada?.numeracionHasta ?? 0;
      data.numeroActual = autorizacionesSeleccionada?.numeroActual ?? "";
      data.fechaCaducidad = DateUtils.apiDateToPickersDate(autorizacionesSeleccionada?.fechaCaducidad ?? "") ?? "";
      data.codigoAsociado = autorizacionesSeleccionada?.codigoAsociado ?? 0;
      data.identificacionAsociado = autorizacionesSeleccionada?.identificacionAsociado ?? "";
      data.nombreAsociado = autorizacionesSeleccionada?.nombreAsociado ?? "";
      data.autorizacionImprenta = autorizacionesSeleccionada?.autorizacionImprenta ?? "";
      data.autorizacionCompartida = Number(autorizacionesSeleccionada?.autorizacionCompartida) === 1 ? true : false;
      data.codigoPertenece = autorizacionesSeleccionada?.codigoPertenece ?? 0;
      data.descripcionPertenece = autorizacionesSeleccionada?.descripcionPertenece ?? "";

      data = {
        ...datosAutorizacion,
        ...data
      }
      stopLoader()

      dispatch(setDatosEdicion(data))
    } catch (error) {
      console.error(error);
      stopLoader()

    }
  }, [dispatch, infoAdicional, autorizacionesSeleccionada, stopLoader, playLoader]);

  const validate = React.useCallback(async () => {
    let resolved = false;

    const validationResult = validationGroupRef.current.instance.validate();

    new Promise(async (resolve) => {
      while (!resolved) {
        await utilidades.sleep(150);
      }
      resolve(resolved);
    });

    if (!validationResult.isValid) {
      //setTieneErroresValidacion(true);

      let errors: any = [];

      validationResult.brokenRules.forEach(function (item: any) {
        let limpiaTexto: any = item['message'].replace("-", "");
        errors.push({ ...item, message: limpiaTexto })
      })
      return { 'isValid': false, 'errors': errors };
    }

    return { 'isValid': true, 'errors': [] };

  }, [])

  const addAutorizacion = React.useCallback((codRegistrado: Number, update: boolean) => {

    let toAdd: Autorizacion = {
      codigo: Number(codRegistrado),
      codigoTipoDocumento: String(datosAutorizacion.tipoDocumento?.codigo),
      descripcionTipoDocumento: String(datosAutorizacion.tipoDocumento?.descripcion),
      numeroAutorizacion: String(datosAutorizacion.numeroAutorizacion),
      establecimiento: String(datosAutorizacion.establecimiento),
      puntoEmision: String(datosAutorizacion.puntoEmision),
      numeracioDesde: Number(datosAutorizacion.numeracioDesde),
      numeracionHasta: Number(datosAutorizacion.numeracionHasta),
      fechaCaducidad: String(DateUtils.pickersDateToApiDate(datosAutorizacion.fechaCaducidad)),
      numeroActual: String(datosAutorizacion.numeroActual),
      codigoPertenece: Number(datosAutorizacion.codigoPertenece),
      descripcionPertenece: String(datosAutorizacion.descripcionPertenece),
      codigoAsociado: Number(datosAutorizacion.codigoAsociado),
      identificacionAsociado: String(datosAutorizacion.identificacionAsociado),
      nombreAsociado: String(datosAutorizacion.nombreAsociado),
      autorizacionImprenta: String(datosAutorizacion.autorizacionImprenta),
      autorizacionCompartida: datosAutorizacion.autorizacionCompartida,
      estado: datosAutorizacion.estadoSeleccionado?.value === 1 ? true : false,
      comprobanteElectronico: datosAutorizacion.comprobanteElectronico
    }
    const _autorizacionesListado = autorizacionesListado.slice(0);

    if (!update) {
      _autorizacionesListado.push(toAdd);
      dispatch(setRegistros(_autorizacionesListado))
    } else {
      const autorizacionUpdate = autorizacionesListado.findIndex(x => Number(x.codigo) === Number(codRegistrado))
      if (autorizacionUpdate && autorizacionUpdate > -1) {
        _autorizacionesListado[autorizacionUpdate] = toAdd;
        dispatch(setRegistros(_autorizacionesListado))
      }
    }
  }, [autorizacionesListado, datosAutorizacion, dispatch]);

  const guardar = React.useCallback(async () => {

    var validated = await validate();
    if (!validated['isValid']) {
      if (validated.errors.length > 0) {
        let sms: any = [];
        validated['errors'].map(function (item) {
          sms.push(item.message)
        });
        onErrorConfirm(sms, "Antes de continuar revise lo siguiente:");
        return false;
      }
      return false;
    } else {
      playLoader()

      try {
        const ingresarAutorizacion: AutorizacionIngresar = {
          codigo: codigoAutorizacion,
          ticCodigo: tipoDocumento?.codigo ?? "01",
          autorizacion: numeroAutorizacion,
          establecimiento: establecimiento,
          puntoEmision: puntoEmision,
          desde: numeracioDesde,
          hasta: numeracionHasta,
          caducidad: DateUtils.pickersDateToApiDate(fechaCaducidad),
          numActual: numeroActual,
          pertenece: infoAdicional?.codigoPertenece ?? 0,
          asociado: infoAdicional?.codigoAsociado ?? 0,
          imprenta: autorizacionImprenta,
          compartida: autorizacionCompartida === true ? 1 : 0,
          estado: estadoSeleccionado?.value === 0 ? false : true,
        }
        const resultado = await autorizacionService.ingresar(ingresarAutorizacion);
        stopLoader()
        if (resultado.error === false) {
          addAutorizacion(ingresarAutorizacion.codigo === 0 ? resultado.auto : ingresarAutorizacion.codigo, ingresarAutorizacion.codigo === 0 ? false : true)
          setToast(resultado.message, "success");
          dispatch(setCurrentAction("Buscar"))

        } else {
          setToast(resultado.message, "danger");
        }

      } catch (error) {
        console.error(error)
        stopLoader()
        setToast(JSON.stringify(error), "danger")

      }
    }
  }, [
    codigoAutorizacion, tipoDocumento, numeroAutorizacion, establecimiento,
    puntoEmision, numeracioDesde, numeracionHasta, numeroActual, infoAdicional,
    autorizacionImprenta, autorizacionCompartida, estadoSeleccionado, fechaCaducidad,
    validate, setToast, playLoader, stopLoader, dispatch, addAutorizacion, onErrorConfirm
  ]);


  const getMessage = React.useCallback((reemplazar, message) => {
    let sms = message.replace("%replace%", reemplazar ?? 's/n');
    return sms;
  }, []);

  const validateSelection = React.useCallback((data) => {
    const { value } = data;
    return new Promise((resolve) => {
      setTimeout(function () {
        if (value && value.codigo && Number(value.codigo) > -1) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 1000);
    });

  }, []);

  const validateSelectionEstado = React.useCallback((data) => {
    const { value } = data;
    return new Promise((resolve) => {
      setTimeout(function () {
        if (value && value.value && Number(value.value) > -1) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, 1000);
    });

  }, []);

  React.useEffect(() => {
    if (datosAutorizacion.codigo === 0 && datosAutorizacion.mode === "new") {
      modoNuevo();
    } else if (datosAutorizacion.codigo !== 0 && datosAutorizacion.mode === "save") {
      cargarAutorizacion(datosAutorizacion);
    }
  }, [datosAutorizacion]);

  // currentFunction
  React.useEffect(() => {
    if (currentFunction && currentFunction !== ButtonTypes.none) {
      switch (currentFunction) {
        case ButtonTypes.save: {
          guardar();
          break;
        }
        case ButtonTypes.undo: {
          if (currentTab === "Nuevo") {
            modoNuevo();
          } else if (currentTab === "Editar") {
            cargarAutorizacion(datosAutorizacion);
          }
          break;
        }
        default:
          break;
      }
      dispatch(setCurrentFunction(ButtonTypes.none))
    }
  }, [currentFunction]);

  return (
    <>
      <fieldset>
        <ValidationGroup id={`valGroupEdition}${codigoAutorizacion}`} ref={validationGroupRef}>
          {!isMobileOnly &&
            <RowContainer>
              <CustomCol>
                <ValidationSummary />
              </CustomCol>
            </RowContainer>
          }

          <RowContainer>
            <CustomCol xs='12' md='6'>
              <RowContainer>
                <CustomCol >
                  <Labeled
                    label='Tipo de Comprobante'
                  >
                    <SelectBox
                      id='selectTipoDocumento'
                      options={tiposDocumentos ?? []}
                      placeholder='Tipo de comprobante'
                      onChange={(value) => {
                        if (value) {
                          dispatch(setTipoDocumento(value));
                        } else {
                          dispatch(setTipoDocumento(null));
                        }
                      }}
                      selected={tipoDocumento}
                      displayExpr='descripcion'
                      keyExpr='codigo'
                      elementAttr={{
                        autocomplete: "nope",
                        autoComplete: "nope",
                      }}
                    >
                      <Validator>
                        <AsyncRule message={getMessage("Tipo de comprobante", lh.getMessage(MessagesKeys.GenericSelectRequerid))} validationCallback={validateSelection} />
                      </Validator>
                    </SelectBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol>
                  <Labeled label='Comp. electrónico:'                        >
                    <Switch
                      value={comprobanteElectronico}
                      onValueChanged={(e) => {
                        dispatch(setEstablecimiento(""))
                        dispatch(setPuntoEmision(""))
                        dispatch(setNumeroActual(""))
                        dispatch(setNumeroAutorizacion(""))
                        dispatch(setAutorizacionImprenta(""))
                        inputAutorizacion.current.instance.focus();
                        dispatch(setIsElectronico(e.value));
                      }} />
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol>
                  <Labeled label='Autorización SRI:'                        >
                    <TextBox
                      ref={inputAutorizacion}
                      name='autorizacionSri'
                      value={numeroAutorizacion}
                      useMaskedValue={true}
                      onValueChanged={(e) => {
                        dispatch(setNumeroAutorizacion(e.value))
                      }}
                      onKeyDown={utilidades.filtraTeclasNoNumericas}
                      maxLength={comprobanteElectronico === false ? 10 : 49}
                      onKeyUp={() => {
                        if (comprobanteElectronico === true && numeroAutorizacion.length === 49) {
                          inputEstablecimiento.current.instance.focus();
                        } else if (comprobanteElectronico === false && numeroAutorizacion.length === 10) {
                          inputEstablecimiento.current.instance.focus();
                        }
                      }}
                      valueChangeEvent="keyup"
                      hoverStateEnabled={true}
                    >
                      <Validator>
                        <RequiredRule message={getMessage("Número de Autorización ", lh.getMessage(MessagesKeys.GenericRequerid))} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol>
                  <Labeled label='Establecimiento:'                        >
                    <TextBox
                      ref={inputEstablecimiento}
                      name='establecimiento'
                      value={establecimiento}
                      useMaskedValue={true}
                      onValueChanged={(e) => {
                        dispatch(setEstablecimiento(e.value))
                      }}
                      onKeyDown={utilidades.filtraTeclasNoNumericas}
                      maxLength={comprobanteElectronico === false ? 10 : 49}
                      onKeyUp={() => {
                        if (establecimiento.length >= 3) {
                          inputPuntoEmision.current.instance.focus();
                        }
                      }}
                      valueChangeEvent="keyup"
                      hoverStateEnabled={true}
                    >
                      <Validator>
                        <RequiredRule message={getMessage(" Establecimiento ", lh.getMessage(MessagesKeys.GenericRequerid))} />
                        <StringLengthRule message="El Establecimiento debe tener 3 dígitos." min={3} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>

              </RowContainer>

              <RowContainer>
                <CustomCol>
                  <Labeled label='Punto de Emisión:'                        >
                    <TextBox
                      ref={inputPuntoEmision}
                      name='puntoEmision'
                      value={puntoEmision}
                      useMaskedValue={true}
                      onValueChanged={(e) => {
                        dispatch(setPuntoEmision(e.value))
                      }}
                      onKeyDown={utilidades.filtraTeclasNoNumericas}
                      maxLength={comprobanteElectronico === false ? 10 : 49}
                      onKeyUp={() => {
                        if (puntoEmision.length >= 3) {
                          inputInicial.current.instance.focus();
                        }
                      }}
                      valueChangeEvent="keyup"
                      hoverStateEnabled={true}
                    >
                      <Validator>
                        <RequiredRule message={getMessage("Punto de Emisión", lh.getMessage(MessagesKeys.GenericRequerid))} />
                        <StringLengthRule message="El Punto de Emisión debe tener 3 dígitos." min={3} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>


              <RowContainer>
                <CustomCol xs="12" md="6" >
                  <Labeled label='Inical:'                        >
                    <NumberBox
                      ref={inputInicial}
                      name='inicial'
                      value={numeracioDesde}
                      useMaskedValue={true}
                      onValueChanged={(e) => {
                        dispatch(setInicial(e.value))
                      }}
                      onKeyDown={utilidades.filtraTeclasNoNumericas}
                      valueChangeEvent="keyup"
                      hoverStateEnabled={true}
                      readOnly={comprobanteElectronico}
                      onKeyUp={() => {
                        if (String(numeracioDesde).length >= 9) {
                          inputFinal.current.instance.focus();
                        }
                      }}
                      onEnterKey={() => {
                        inputFinal.current.instance.focus();
                      }}
                    >
                      <Validator>
                        <RequiredRule message={getMessage("Número Inical", lh.getMessage(MessagesKeys.GenericRequerid))} />
                        <StringLengthRule message="El Número Inical debe tener 9 dígitos." min={1} max={9} />
                      </Validator>
                    </NumberBox>
                  </Labeled></CustomCol>
                <CustomCol xs="12" md="6" >
                  <Labeled label='Final:'                        >
                    <NumberBox
                      ref={inputFinal}
                      name='final'
                      value={numeracionHasta}
                      useMaskedValue={true}
                      onValueChanged={(e) => {
                        dispatch(setFinal(e.value))
                      }}
                      onKeyDown={utilidades.filtraTeclasNoNumericas}
                      valueChangeEvent="keyup"
                      hoverStateEnabled={true}
                      readOnly={comprobanteElectronico}
                      onKeyUp={() => {
                        if (String(numeracionHasta).length >= 9) {
                          inputCaducidad.current.instance.focus();
                        }
                      }}
                      onEnterKey={() => {
                        inputCaducidad.current.instance.focus();
                      }}
                    >
                      <Validator>
                        <RequiredRule message={getMessage("Número Final", lh.getMessage(MessagesKeys.GenericRequerid))} />
                        <StringLengthRule message="El Número Final debe tener 9 dígitos." min={1} max={9} />
                      </Validator>
                    </NumberBox>
                  </Labeled>
                </CustomCol>

              </RowContainer>

            </CustomCol>
            <CustomCol xs='12' md='6'>

              <RowContainer>
                <CustomCol xs='12' md='4'>
                  <Labeled label='Caducidad'                        >
                    <DateBox
                      ref={inputCaducidad}
                      applyValueMode="useButtons"
                      displayFormat='dd/MM/yyyy'
                      value={DateUtils.strDateToDate(fechaCaducidad)}
                      onValueChanged={({ value }) => {
                        if (value) {
                          dispatch(setFechaCaducidad(DateUtils.dateToString(value)))
                        }
                      }}
                      readOnly={comprobanteElectronico}
                      onEnterKey={() => {
                        inputNumeroActual.current.instance.focus();
                      }}
                    >
                    </DateBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol>

                  <Labeled label='Número Comprobante Actual:'                        >
                    <TextBox
                      ref={inputNumeroActual}
                      name='numeroActual'
                      value={numeroActual}
                      useMaskedValue={true}
                      onValueChanged={(e) => {
                        dispatch(setNumeroActual(e.value))
                      }}
                      onKeyDown={utilidades.filtraTeclasNoNumericas}
                      maxLength={9}
                      valueChangeEvent="keyup"
                      hoverStateEnabled={true}
                      onEnterKey={() => {
                        inputAutorizacionImprenta.current.instance.focus();
                      }}
                    >
                      <Validator>
                        <RequiredRule message={getMessage("Número Comprobante Actual", lh.getMessage(MessagesKeys.GenericRequerid))} />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol>

                  <Labeled label='Asociado a:'                        >
                    {nombreAsociado}
                  </Labeled>
                </CustomCol>

              </RowContainer>

              <RowContainer>
                <CustomCol>
                  <Labeled label='Autorización Imprenta:'                        >
                    <TextBox
                      ref={inputAutorizacionImprenta}
                      name='autorizacionImprenta'
                      value={autorizacionImprenta}
                      useMaskedValue={true}
                      onValueChanged={(e) => {
                        dispatch(setAutorizacionImprenta(e.value))
                      }}
                      maxLength={5}
                      valueChangeEvent="keyup"
                      hoverStateEnabled={true}
                      readOnly={comprobanteElectronico}
                    >
                    </TextBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol>
                  <Labeled label='Autorización Compartida:'                        >
                    <Switch
                      value={autorizacionCompartida}
                      onValueChanged={(e) => {
                        dispatch(setAutorizacionCompartida(e.value));
                      }} />
                  </Labeled>
                </CustomCol>
              </RowContainer>

              <RowContainer>
                <CustomCol >
                  <Labeled
                    label='Estado'
                  >
                    <SelectBox
                      id='selectEstado'
                      options={estados ?? []}
                      placeholder='Estado'
                      onChange={(value) => {
                        if (value) {
                          dispatch(setEstadoSeleccionado(value));
                        } else {
                          dispatch(setEstadoSeleccionado(null));
                        }
                      }}
                      selected={estadoSeleccionado}
                      displayExpr='label'
                      keyExpr='value'
                      elementAttr={{
                        autocomplete: "nope",
                        autoComplete: "nope",
                      }}
                      onEnterKey={() => {
                        guardar();
                      }}
                    >
                      <Validator>
                        <AsyncRule message={getMessage("Estado", lh.getMessage(MessagesKeys.GenericSelectRequerid))} validationCallback={validateSelectionEstado} />
                      </Validator>
                    </SelectBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>



            </CustomCol>
          </RowContainer>


          {isMobileOnly &&
            <RowContainer>
              <CustomCol>
                <ValidationSummary />
              </CustomCol>
            </RowContainer>
          }


        </ValidationGroup>

      </fieldset>
    </>
  )
}
export default memo(FormularioEdicion);

export const defaulDatosEdicionAtorizacion: AutorizacionDatosEdicion = {
  autorizacionCompartida: false,
  autorizacionImprenta: "",
  codigo: 0,
  codigoAsociado: 0,
  codigoPertenece: 0,
  codigoTipoDocumento: "01",
  descripcionPertenece: "",
  descripcionTipoDocumento: "FACTURA",
  establecimiento: "",
  estado: true,
  fechaCaducidad: DateUtils.getCurrentDateAsString(),
  identificacionAsociado: "",
  nombreAsociado: "",
  numeracioDesde: 1,
  numeracionHasta: 0,
  numeroActual: "",
  numeroAutorizacion: "",
  puntoEmision: "",
  tipoDocumento: null,
  tiposDocumentos: [],
  mode: "none",
  comprobanteElectronico: false,
  estados: [],
  estadoSeleccionado: null
}