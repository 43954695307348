//#region imports
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import {
  FacturaDatosEdicion,
  TFacturaIngresar, TFacturaIngresarDetalle, TTotalesFactura,
} from '../../../pages/ventas/types/types';
import { RootState } from '../../../../../store/store';


import { AutorizacionDocumentoVenta, Cliente, ItemVenta, VentaListado } from '../../../types/types';
import { VentasService } from '../../../services/ventas.service';
import { ClientesService } from '../../../services/clientes.service';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { VentaHelper } from '../../../pages/ventas/ventaHelper';
import { StatesEdition } from '../../../types/enums';
import { DateUtils, formatoFechasApi, formatoFechasDatePickers } from '../../../../../helpers/dateUtils';
import config from '../../../../../config/config';
import {
  setDatosEdicion, updateDetalles, updateTotales, setEditLoader,
  updateCliente, updateObservaciones, clearDatosEdicion, setMuestraError,
  addDetalle, setImprimir, setFormaPago, setCuotasCredito, setMostrarBusquedaItems, updateClienteOnly
} from '../../../pages/ventas/store/editDataReducer';
import { addToast } from '../../../../../store/toasterReducer';
import { Autorizacion, TiposComprobantesSri, ToastTypes } from '../../../../../store/types';
import { buttonClick, changeEditStatus, clearButtonClick, closeTab, setButtons, setCurrentExecutingAction, setTabInfoAsInactive } from '../../../pages/ventas/store/tabsReducer'
import { DetalleVenta, DetalleVentaRedux } from '../../../pages/ventas/types/detalleVenta';
import { SheetTextEditor } from '../sheetEditors/sheetTextEditor';
import Dialog from 'react-bootstrap-dialog'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types';
import { VerRIDE } from '../../verRide/verRide';
import AgregarDetalleVentaMobile from './agregarDetalleVentaMobile';
import ModificaItem from '../../items/modificarItem';
import MainModalItems from '../../items/busquedaItemsModal/MainModalItems';
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../views/componentes/colContainer';
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput';
import { CBadge, CCol, CInput, CRow } from '@coreui/react';
import { faEdit, faMinusCircle } from '@fortawesome/pro-light-svg-icons';
import Barcode from '../../../../../views/componentes/barcode/barcode';
import MUIDataTable from "mui-datatables";
import { getColumnnasTablaDetalleVentas, OptionsTablaDetalleVentas } from './helper';
import SelectBox from '../../../../../views/componentes/selectBox/selectBox';
import { CustomButtons, DocumentInfo, TabState } from '../../../types/generics';
import { v4 as uuidv4 } from 'uuid';
import { CustomSheet } from '../../../../../views/componentes/customSheet/customSheet';
import { DataSheetColumn } from '../../../../../views/componentes/customSheet/dataSheetColumn';
import { CustomSheetTextEditor } from '../../../../../views/componentes/customSheet/editors/textEditor';
import { SheetDescripcionItemSelectEditor } from '../sheetEditors/sheetDescripcionItemSelectEditor';
import { CustomSheetNumberEditor } from '../../../../../views/componentes/customSheet/editors/numberEditor';
import CustomSheetNumberViewer from '../../../../../views/componentes/customSheet/viewers/sheetNumberViewer';
import { CellChangedArgs } from '../../../../../views/componentes/customSheet/cellChangedArgs';
import { RowCommand } from '../../../../../views/componentes/customSheet/genericRow';
import VisualizaError from '../../../pages/shared/visualizaError/visualizaError'
import { setAutorizacionModulo, toogleUsaDescuentoGlobal } from '../../../pages/ventas/store/configuracionesVentaReducer'
import { CreditosVenta } from '../creditosVenta/creditosVenta';
import BuscarClienteLookUp from '../../busquedaCliente/busquedaCliente';
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator';
import Button from 'devextreme-react/button';
import PopupReenvioMail from '../busquedaVentas/popupReenvioMail/index'
import { AnularDocumentoVenta } from '../../../pages/shared/anularDocumentoVenta/anularDocumentoVenta';
import TextArea from 'devextreme-react/text-area';
import TextBox from 'devextreme-react/text-box';
import DateBox from 'devextreme-react/date-box';


//#endregion

interface IVentaProps {
  info: DocumentInfo<VentaListado>,
  tabId: string,
  tab: TabState<VentaListado>,
  templateMode?: boolean
}


const Venta: React.FunctionComponent<IVentaProps> = (props) => {
  const { tabId, info, tab } = props;
  const dispatch = useDispatch();
  const usuario = useSelector((state: RootState) => { return state.global.session?.usuario });
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  const local = useSelector((state: RootState) => { return state.global.session?.local });
  //const currentTab = useSelector((state: RootState) => { return state.ventas.ventas.tabs.tabs[props.tabIndex] });
  const datosEdicion = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId] });
  const detalles = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].detalles });
  //const templateMode = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].templateMode });
  const imprimir = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].imprimir });
  const cliente = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].cliente });
  const observaciones = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].observaciones });
  const claveAcceso = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].claveAcceso });
  const fecha = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].fecha });
  const loading = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].loading });
  const descuentoGlobal = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].descuentoGlobal });
  const puntoVenta = useSelector((state: RootState) => { return state.global.puntoVenta });
  const usaDescuentoGlobal = useSelector((state: RootState) => { return state.ventas.ventas.configuraciones.usaDescuentoGlobal });
  const formasPago = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].formasPago });
  const formaPago = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].formaPago });
  const iva = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].iva });
  const cuotas = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].cuotas });
  const totales = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].totales });
  const mostrarBusquedaItems = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].mostrarBusquedaItems });
  const desdePlantilla = useSelector((state: RootState) => { return state.ventas.ventas.editData[props.tabId].desdePlantilla });

  const dialogRef = React.useRef<any>(null);
  const [filaEliminar, setFilaEliminar] = React.useState(-1);

  const [agregarDetalleMovil, setAgregarDetalleMovil] = React.useState(false);
  const [mostrarEdicionItems, setMostrarEdicionItems] = React.useState(false);
  //const [mostrarBusquedaItems, setMostrarBusquedaItems] = React.useState(false);
  const [codigoBarrasItemEditar, setCodigoBarrasItemEditar] = React.useState('');
  const [verFacturaPdf, setVerFacturaPdf] = React.useState<{ claveAcceso: string, design: boolean }>();
  const [mostrarIngresoCreditos, setMostrarIngresoCreditos] = React.useState(false);
  //const [cuotasCreditos, setCuotasCreditos] = React.useState<Array<CuotaCreditoVenta>>([]);

  const [showReenviarMail, setShowReenviarMail] = React.useState<boolean>(false);
  const [mostrarAnular, setMostrarAnular] = React.useState(false);

  const sheetCommands = React.useMemo(() => {
    const commads: Array<RowCommand<DetalleVentaRedux>> = [];
    commads.push({
      id: 'Eliminar',
      text: 'Eliminar',
      icon: faMinusCircle,
      onExecute: (rowIndex) => { setFilaEliminar(rowIndex) },
    });
    commads.push({
      id: 'Editar',
      text: 'Editar',
      icon: faEdit,
      onExecute: (rowIndex) => {
        setMostrarEdicionItems(true);
        setCodigoBarrasItemEditar(detalles[rowIndex].codigoBarras);
      },
    });
    return commads;
  }, [detalles]);

  const validarPorcentajeDescuentoFactura = React.useCallback((totalSinDescuento, valorDescuento): boolean => {
    if (local && empresa) {
      const porcentajeDescontado = (100 / totalSinDescuento) * valorDescuento;
      const maximoDescuento = local.descuento > 0 ? local.descuento : empresa.descuento;
      return porcentajeDescontado <= maximoDescuento;
    }
    const mensajeError = 'El estado es incorrecto (local, empresa)';
    throw mensajeError
  }, [local, empresa]);

  const cargarVenta = React.useCallback(async (numero: number, setInitData: boolean) => {
    if (datosEdicion.imprimir) {
      setVerFacturaPdf({ claveAcceso: datosEdicion.claveAcceso, design: false });;
    }
    if (datosEdicion.loading === false) {
      return;
    }
    const data = { ...defaultDatosEdicionFactura }; //utilidades.unfreeze(defaultDatosEdicionFactura);
    try {
      if (setInitData) dispatch(setDatosEdicion({
        data: { ...data },
        key: tabId
      }));
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.find
      }))
      const venta = await VentasService.getVenta(numero); //VentasService.getVentas(0, "", "", "", "", "", "", undefined, clave_acceso, undefined, undefined, undefined, TiposComprobantesSri.Factura);
      if (venta) {
        const detalles = await VentasService.getDetalleVenta(numero, TiposComprobantesSri.Factura);
        const cliente = await ClientesService.getCliente(venta?.clienteIdentificacion);
        const formasPago = await VentasService.getFormasPago(cliente.identificacion);
        const iva = await VentasService.getIva(venta.fecha, null);
        data.iva = iva;
        data.establecimiento = venta.establecimiento;
        data.puntoEmision = venta.puntoEmision;
        data.numerofactura = venta.numero;
        data.observaciones = venta.observaciones;
        data.formasPago = formasPago;
        const formaPago = formasPago.find(x => x.codigo === venta.formaPago)
        if (formaPago) {
          data.formaPago = [formaPago];
        }
        data.cliente = cliente;
        const tmpDet2 = VentaHelper.detallesToDetallesVenta(detalles, empresa?.precision ??4);
        data.detalles = tmpDet2;
        data.fechaAutorizacion = venta.claveAccesoFecha ?? '';
        data.claveAcceso = venta.claveAcceso ?? ''; //ventas[0].autorizacion.replace("'", "");
        //data.claveAcceso = venta.claveAcceso ?? '';//ventas[0].claveAcceso;
        data.fecha = DateUtils.apiDateToPickersDate(venta.fecha); //ventas[0].fecha;
        data.descuentoGlobal = venta.descuentoGlobal;// ventas[0].descuento_global;
        data.numero = venta.codigo;
        data.loader = {
          mensaje: '',
          show: false
        }
        data.loading = false;

        dispatch(setDatosEdicion({
          data: data,
          key: tabId
        }));

        dispatch(setButtons({
          tabKey: tabId,
          buttons: {
            ...InvoiceButtons,
            Guardar: false,
            Autorizar: venta.claveAccesoFecha === '',
            Enviar: venta.claveAccesoFecha !== '',
            Deshacer: false,
            Descuento: false
          }
        }))

      }
    } catch (error) {
      data.tieneError = true;
      data.mensajeError = error;
      data.loading = false;
      dispatch(setDatosEdicion({
        data: data,
        key: tabId
      }));
    }
  }, [datosEdicion.imprimir, datosEdicion.loading, datosEdicion.claveAcceso, dispatch, tabId]);


  const modoNuevo = React.useCallback(async (puntoVenta: string, template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return;
    }
    sessionStorage.removeItem('autorizacionModulo');

    const data = { ...defaultDatosEdicionFactura };
    dispatch(setButtons({
      tabKey: tabId,
      buttons: {
        ...InvoiceButtons,
        Editar: false,
        Imprimir: false,
        Exportar: false,
        Autorizar: false,
        Enviar: false,
        Guardar: true,
      }
    }))
    try {
      const fecha = DateUtils.getCurrentDateAsString(formatoFechasDatePickers);//  moment().format('yyyy-MM-DD');

      const autorizacion = await VentasService.getAutorizacion(puntoVenta, TiposComprobantesSri.Factura);
      dispatch(setAutorizacionModulo(autorizacion));
      sessionStorage.setItem('autorizacionModulo', JSON.stringify(autorizacion));

      const iva = await VentasService.getIva(DateUtils.pickersDateToApiDate(fecha), autorizacion);
      if (template > 0) {
        const venta = await VentasService.getVenta(template); //VentasService.getVentas(0, "", "", "", "", "", "", undefined, clave_acceso, undefined, undefined, undefined, TiposComprobantesSri.Factura);
        if (venta) {
          const detalles = await VentasService.getDetalleVenta(template, TiposComprobantesSri.Factura);
          const cliente = await ClientesService.getCliente(venta?.clienteIdentificacion);
          const tmpDet2 = VentaHelper.detallesToDetallesVenta(detalles, empresa?.precision ?? 4);
          data.detalles = tmpDet2;
          data.cliente = cliente;
          data.observaciones = venta.observaciones;
          const formasPago = await VentasService.getFormasPago(cliente.identificacion);
          data.formasPago = formasPago;
          const fp = formasPago.find(fp => fp.codigo === venta.formaPago);
          if (fp) {
            data.formaPago = [fp];
            //setFormaPago([fp]);
          }
          data.desdePlantilla = true;
        }
      } else {
        const cliente = await ClientesService.getCliente(config.rucConsumidorFinal);
        data.cliente = cliente;
        data.detalles = [];
        data.observaciones = '';
        const formasPago = await VentasService.getFormasPago(config.rucConsumidorFinal);
        data.formasPago = formasPago;
        const fp = formasPago.find(fp => fp.codigo === 6 ?? 0);
        if (fp) {
          data.formaPago = [fp];
          //setFormaPago([fp]);
        }
        data.desdePlantilla = false;
      }

      data.autorizacion = '';
      data.autorizacionVenta = autorizacion;
      data.fechaAutorizacion = 'NO AUTORIZADO (NUEVO)';

      data.iva = iva;
      data.fecha = fecha;
      data.establecimiento = autorizacion.establecimiento;
      data.puntoEmision = autorizacion.ptoemision;
      data.numerofactura = autorizacion.numActual.toString();
      data.identificadorTransaccion = uuidv4();


      data.loading = false;
      data.loader = {
        mensaje: '',
        show: false
      }

    } catch (error) {
      data.loading = false;
      data.tieneError = true;
      data.mensajeError = typeof error == 'string' ? error : JSON.stringify(error);
      data.loader = {
        mensaje: '',
        show: false
      }
      dispatch(addToast({
        content: data.mensajeError,
        type: ToastTypes.Danger
      }))
    }
    dispatch(setDatosEdicion({
      data: data,
      key: tabId
    }));

  }, [loading, tabId, empresa, dispatch]);


  const modoEdicion = React.useCallback(() => {
    if (cliente == null) {
      return;
    }
    if (claveAcceso.length === 37 || claveAcceso.length === 49) {
      dispatch(addToast({
        autoHide: true,
        content: 'Una factura eléctronica no puede ser editada.',
        fade: true,
        id: '',
        title: 'Editar factura',
        type: ToastTypes.Info
      }));
    } else {

    }
  }, [dispatch, claveAcceso, cliente]);


  const guardar = React.useCallback(async () => {
    if (usuario == null || empresa == null || local == null || puntoVenta == null) {
      return;
    }
    if (datosEdicion.detalles.length === 0) {
      dispatch(addToast({
        autoHide: true,
        content: 'La factura no tiene detalles',
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Warning
      }))
      return;
    }

    if (datosEdicion.totales.total <= 0) {
      dispatch(addToast({
        autoHide: true,
        content: 'La factura no puede ser por un valor menor que 0',
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Warning
      }))
      return;
    }
    if (!datosEdicion.cliente) {
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Debe de seleccionar el cliente',
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Warning
      }))
      return;
    }

    if (datosEdicion.formaPago === null ||
      datosEdicion.formaPago.length === 0 ||
      datosEdicion.formaPago[0] == null) {
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Debe de seleccionar la forma de pago',
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Warning
      }))
      return;
    }
    if (!validarPorcentajeDescuentoFactura(datosEdicion.totales.subtotal, datosEdicion.totales.descuento)) {
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'El descuento aplicado en la factura sobrepasa el máximo permitido',
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Warning
      }))
      return;
    }

    if (datosEdicion.totales.total > 200 && datosEdicion.cliente.identificacion === config.rucConsumidorFinal) {
      dispatch(addToast({
        title: 'Guardar',
        content: 'La factura excede el monto maximo ($200) permitido para consumidor final, por favor ingrese los datos del cliente',
        type: ToastTypes.Warning
      }))
      return;
    }

    if (datosEdicion.formaPago[0].descripcion === 'CREDITO' && cuotas.length === 0) {
      dispatch(addToast({
        title: 'Guardar',
        content: 'Por favor, detalle la forma de pago!!!',
        type: ToastTypes.Warning
      }))
      setMostrarIngresoCreditos(true);
      return;
    }

    try {
      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: true,
            mensaje: 'Guardando factura...'
          }
        },
        key: tabId
      }));
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.save
      }))
      //setLocalLoader({ show: true, mensaje: 'Guardando factura...' });
      //setGuardando(true);
      const factura: TFacturaIngresar = {
        identificador: datosEdicion.identificadorTransaccion,
        usuario: usuario.codigo.toString(),
        tipodoc: "01",
        tipo_emision: "1",
        local: local.codigo.toString(),
        pventa: puntoVenta,
        fecha_emision: DateUtils.getCurrentDateAsString(formatoFechasApi),
        emisor: {
          ruc: empresa.ruc,
          razon_social: empresa.nombre,
          nombre_comercial: empresa.comercial,
          contribuyente_especial: "",
          direccion: empresa.direccion,
          obligado_contabilidad: true,
          establecimiento: {
            punto_emision: datosEdicion.puntoEmision,
            codigo: local.codigo.toString(),
            direccion: local.direccion
          }
        },
        comprador: {
          tipo_identificacion: datosEdicion.cliente?.tipoIdentificacion,
          identificacion: datosEdicion.cliente?.identificacion,
          nombres: datosEdicion.cliente.nombres,
          razon_social: datosEdicion.cliente.nombreComercial ?? "",
          direccion: datosEdicion.cliente.direccion,
          email: datosEdicion.cliente.email ?? "",
          telefono: datosEdicion.cliente.telefono ?? "S/T",
          ciudad: datosEdicion.cliente.ciudad
        },
        informacion_adicional: {
          Cliente: datosEdicion.cliente.nombres,
          Enviado_a: datosEdicion.cliente.email ?? "",
          Direccion: datosEdicion.cliente.direccion,
          Telefono: datosEdicion.cliente.telefono ?? "S/T",
        },
        totales: {
          total_sin_impuestos: parseFloat(datosEdicion.detalles.reduce((prev, next) => prev + (next._subtotal + next._descuento), 0).toFixed(2)),
          importe_total: parseFloat(datosEdicion.totales.total.toFixed(2)),
          propina: 0,
          descuento: parseFloat(datosEdicion.totales.descuento.toFixed(2)),
          descuento_adicional: datosEdicion.totales.descuentoGloabal,
          impuestos: [
            {
              codigo: 2,
              codigo_porcentaje: "2",
              descuento_adicional: 0,
              base_imponible: parseFloat(datosEdicion.detalles.reduce((prev, next) => prev + next._subtotalIva + (next._subtotalIva > 0 ? next._descuento : 0), 0).toFixed(2)),
              valor: parseFloat(datosEdicion.detalles.reduce((prev, next) => prev + next._iva, 0).toFixed(2))
            },
            {
              codigo: 2,
              codigo_porcentaje: "0",
              descuento_adicional: 0,
              base_imponible: parseFloat(datosEdicion.detalles.reduce((prev, next) => prev + next._subtotal0 + (next._subtotal0 > 0 ? next._descuento : 0), 0).toFixed(2)),
              valor: 0
            }
          ]
        },
        observaciones: datosEdicion.observaciones,
        moneda: "USD",
        formaPago: {
          value: datosEdicion.formaPago[0].codigo,
          label: datosEdicion.formaPago[0].descripcion
        },
        ambiente: empresa.uso_electronica.toString(),
        items: datosEdicion.detalles.map(det => {
          const detalle: TFacturaIngresarDetalle = {
            codigo_auxiliar: det.codigo.toString(),
            codigo_principal: det.codigoBarras,
            precio_unitario: det._precioUnitario,
            cantidad: det._cantidad,
            precio_total_sin_impuestos: det._subtotal + det._descuento,
            descripcion: det.descripcion,
            descuento: det._porcentajeDescuento,
            detalles_adicionales: {},
            impuestos: [
              {
                codigo: det.tieneIva ? 3 : 2,
                tarifa: (det.porcentajeiva * 100).toFixed(0),
                codigo_porcentaje: det.tieneIva ? 2 : 0,
                base_imponible: det._subtotal + det._descuento,
                valor: det._iva
              }
            ]
          };
          return detalle;
        }),
        cuotas: cuotas.map(c => {
          return {
            cuota: c.cuota,
            fechaVencimiento: c.vencimiento,
            saldo: c.saldo,
            interes: 0,
            letra: c.letra,
            saldoCapital: c.saldo
          }
        }),
        pagos: [{
          total: parseFloat(datosEdicion.totales.total.toFixed(2)),
          medio: datosEdicion.formaPago[0].descripcion,
          id_medio: datosEdicion.formaPago[0].codigo.toString(),
        }]

      }
      const resultado = await VentasService.ingresarVenta(factura);
      // const ventas = await VentasService.getVentas(0, "", "", "", "", "", "", undefined, resultado.claveAcceso, undefined, undefined, undefined, TiposComprobantesSri.Factura);
      // const venta = ventas[0];

      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          claveAcceso: resultado.claveAcceso,
          autorizacion: resultado.claveAcceso,
          fechaAutorizacion: resultado.fechaAutorizacion ?? '',
          numerofactura: resultado.numero,
          fecha: DateUtils.apiDateToPickersDate(resultado.fecha),
          loader: {
            show: false,
            mensaje: ''
          },
          imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false
        },
        key: tabId
      }));

      dispatch(changeEditStatus({
        estado: StatesEdition.save,
        tabKey: tabId,
        info: resultado,
        buttons: {
          ...InvoiceButtons,
          Guardar: false,
          Editar: true,
          Deshacer: false,
          Autorizar: resultado.estadoElectronico !== "AUTORIZADO",
          Enviar: resultado.estadoElectronico === "AUTORIZADO",
          Descuento: false,
          Anular: resultado.estadoElectronico === "AUTORIZADO",
        }
      }));

      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Guardado',
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Success
      }))
    } catch (error) {
      //console.log(error);
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al guardar factura ' + (typeof error === 'string' ? error : JSON.stringify(error)),
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Danger
      }));
      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: false,
            mensaje: ''
          }
        },
        key: tabId
      }));
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
      return;
    }
  }, [datosEdicion, empresa, local, puntoVenta, usuario, cuotas, tabId, validarPorcentajeDescuentoFactura, dispatch]);

  const deshacer = React.useCallback(() => {
    if (info.numero === 0) {
      dispatch(closeTab(tabId));
      dispatch(clearDatosEdicion(tabId))
    } else {
      dispatch(changeEditStatus({
        estado: StatesEdition.save,
        tabKey: tabId,
        buttons: { ...InvoiceButtons }
      }));
      cargarVenta(info.numero, false);
    }

  }, [info, cargarVenta, tabId, dispatch]);

  const showEditLoader = React.useCallback((mensaje: string) => {
    dispatch(setEditLoader({
      key: tabId,
      info: {
        mensaje: mensaje,
        show: true
      }
    }));
  }, [tabId, dispatch])


  const hideEditLoader = React.useCallback(() => {
    dispatch(setEditLoader({
      key: tabId,
      info: {
        mensaje: '',
        show: false
      }
    }));
  }, [tabId, dispatch])

  const sheetOnCellsChanged = React.useCallback(async (arrayOfChanges: Array<CellChangedArgs<DetalleVentaRedux>>) => {
    const _detalles = detalles.slice(0);
    for (const change of arrayOfChanges) {
      const detalle = new DetalleVenta(); //clase que realiza los calculos para un detalle
      detalle.setDetalle(change.rowData, empresa?.precision ?? 4);
      switch (change.col.name) {
        case 'codigoBarras':
          showEditLoader('Buscando item...');
          const item = await VentasService.getItemPorCodigoBarras(detalle.codigoBarras, datosEdicion.cliente?.identificacion ??
            config.rucConsumidorFinal, DateUtils.pickersDateToApiDate(detalle.fecha));
          if (item) {
            detalle.setItem(item, empresa?.precision ?? 4);
            hideEditLoader();
          } else {
            dispatch(addToast({
              content: 'No se encuentra el item con código ' + change.newValue,
              title: 'Agregar item',
              type: ToastTypes.Warning,
              autoHide: 2000
            }))
            continue;
          }
          break;
        case 'cantidad':
          detalle.cantidad = change.newValue;
          detalle.calcular();
          break;
        case 'descripcion':
          if (typeof change.newValue === 'string') {
            if (detalle.codigo === undefined) {
              dispatch(addToast({
                content: 'No se puede pegar un item, debe usar la búsqueda o la columna código.',
                title: 'Agregar item',
                type: ToastTypes.Warning,
                autoHide: 2000
              }))
              continue;
            }
            detalle.descripcion = change.newValue;
          }
          break;
        case '_cantidad':
          detalle.cantidad = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        case '_porcentajeDescuento':
          detalle.porcentajeDescuento = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        case '_precioUnitario':
          detalle.precio = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        case '_precioIva':
          detalle.precioIva = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        default:
          break;
      }
      if (change.rowIndex === -1) { //nueva fila
        _detalles.push(detalle.getDetalle());
      } else {
        _detalles[change.rowIndex] = detalle.getDetalle();
      }
    }
    dispatch(updateDetalles({
      detalles: _detalles,
      key: tabId
    }));
    hideEditLoader();
  }, [datosEdicion, detalles, empresa, dispatch, hideEditLoader, tabId, showEditLoader]);



  // const validarPorcentajeDescuento = React.useCallback((valor: number, tipo: string): boolean => {
  //   let resultado = true;
  //   let mensaje: string = '';

  //   if (tipo === '%') {
  //     if (valor < 0) {
  //       resultado = false;
  //       mensaje = 'El porcentaje descuento no puede ser negativo!!!'
  //     }
  //     else if (valor > 100) {
  //       resultado = false;
  //       mensaje = 'El porcentaje descuento no puede ser > al 100%!!!'
  //     }
  //     else if (local && empresa) {
  //       const maximoDescuento = local.descuento > 0 ? local.descuento : empresa.descuento;
  //       if (valor > maximoDescuento) {
  //         resultado = false;
  //         mensaje = `El descuento máximo permitido es del ${maximoDescuento.toFixed(2)}%`;
  //       }
  //     }
  //   }
  //   if (!resultado) {
  //     dispatch(addToast({
  //       id: '',
  //       autoHide: true,
  //       content: mensaje,
  //       fade: true,
  //       title: 'Aplicar descuento',
  //       type: ToastTypes.Warning
  //     }));
  //   }
  //   return resultado;
  // }, [empresa, local, dispatch])


  // const establecerDescuentoPorItem = React.useCallback((valor: number, tipo: string) => {
  //   if (!validarPorcentajeDescuento(valor, tipo)) {
  //     return;
  //   }
  //   if (tipo === '$') {

  //   } else { //%
  //     const _detalles = detalles.slice(0);
  //     for (let index = 0; index < _detalles.length; index++) {
  //       const _detalle = _detalles[index];
  //       const _detalleTmp: DetalleVenta = new DetalleVenta();
  //       _detalleTmp.setDetalle(_detalle);
  //       _detalleTmp.porcentajeDescuento = valor;
  //       _detalles[index] = _detalleTmp.getDetalle();
  //     }
  //     dispatch(updateDetalles({
  //       key: props.tabId,
  //       detalles: _detalles
  //     }))

  //   }
  // }, [datosEdicion.detalles]);

  const calcularTotales = React.useCallback((detalles: Array<DetalleVentaRedux>, descuentoGlobal: number) => {
    let totalCero: TTotalesFactura = {
      descuento: 0,
      descuentoGloabal: descuentoGlobal,
      ice: 0,
      iva: 0,
      subtotal: 0,
      subtotal0: 0,
      subtotalIva: 0,
      total: 0
    };
    let totales = detalles.reduce((sum, next) => {
      sum.subtotal += next._subtotal;
      sum.subtotal0 += next._subtotal0;
      sum.subtotalIva += next._subtotalIva;
      sum.iva += next._iva;
      sum.descuento += next._descuento;
      sum.total += next._total;

      return sum;
    }, totalCero);

    if (descuentoGlobal > totales.total) {
      dispatch(addToast({
        title: 'Calcular totales',
        content: 'El descuento no puede ser mayor al total',
        type: ToastTypes.Warning,
        autoHide: 2500
      }))
    }

    if (descuentoGlobal > 0) {
      if (totales.total === 0) {
        return;
      }
      const proporciaonalIva = ((100 / totales.subtotal * totales.subtotalIva) / 100);
      const proporcionalBaseCero = 1 - proporciaonalIva;
      totales.subtotalIva = totales.subtotalIva - (descuentoGlobal * proporciaonalIva);
      totales.iva = totales.subtotalIva * (iva / 100);
      totales.subtotal0 = totales.subtotal0 - (descuentoGlobal * proporcionalBaseCero);
      totales.total = totales.subtotalIva + totales.iva + totales.subtotal0;
    } else if (descuentoGlobal < 0) {
      return;
    }

    dispatch(updateTotales({
      totales: totales,
      key: tabId
    }));
  }, [tabId, dispatch, iva])

  const establecerDescuentoGlobal = React.useCallback((valor) => {
    calcularTotales(detalles, valor);
    //setdescuentoGlobal(valor);
  }, [detalles, calcularTotales]);


  const agregarDetalleVentaMobile = React.useCallback((detalle: DetalleVenta) => {
    const _detalles = detalles.slice(0);
    _detalles.push(detalle.getDetalle());
    dispatch(updateDetalles({
      key: tabId,
      detalles: _detalles
    }))
    setAgregarDetalleMovil(false);
  }, [detalles, tabId, dispatch])

  const cambiarCliente = React.useCallback(async (cliente: Cliente) => {
    const formasPago = await VentasService.getFormasPago(cliente.identificacion);
    //setFormasPago(formasPago);
    //dispatch(updateDetalles({ key: tabId, detalles: detalles }))
    dispatch(updateCliente({
      cliente: cliente,
      formasPago,
      formaPago: [formasPago.find(x => x.codigo === empresa?.formaPagoDefectoVenta) ?? formasPago[0]],
      key: tabId
    }));
  }, [empresa, tabId, dispatch])

  const cambiarClienteConfirm = React.useCallback((clienteNuevo: Cliente, detalles: Array<DetalleVentaRedux>, fecha: string) => {
    if (cliente?.codigo === clienteNuevo.codigo) {
      dispatch(updateClienteOnly({
        cliente: clienteNuevo,
        key: tabId
      }));
      return;
    }

    const clienteLocal = clienteNuevo;
    const fnActualizaOnOk = async (clienteNuevo) => {
      showEditLoader('Actualizando precios...')
      for (let index = 0; index < detalles.length; index++) {
        const detalle = detalles[index];
        const _detalle = new DetalleVenta();
        const item = await VentasService.getItemPorCodigoBarras(detalle.codigoBarras, clienteNuevo.identificacion, DateUtils.pickersDateToApiDate(fecha));
        if (item) {
          _detalle.setDetalle(detalle, empresa?.precision ?? 4);
          _detalle.setItem(item, empresa?.precision ?? 4);
          detalles[index] = _detalle.getDetalle();
        } else {
          addToast({
            content: `No se pudo recuperar el item ${detalle.descripcion}, el item no existe`,
            title: 'Cambiar precios',
            type: ToastTypes.Danger,
          })
        }

      }
      const formasPago = await VentasService.getFormasPago(clienteNuevo.identificacion);
      //setFormasPago(formasPago);
      //dispatch(updateDetalles({ key: tabId, detalles: detalles }))
      dispatch(updateCliente({
        cliente: clienteNuevo,
        formasPago,
        formaPago: [formasPago.find(x => x.codigo === empresa?.formaPagoDefectoVenta) ?? formasPago[0]],
        detalles: detalles,
        key: tabId
      }));
      addToast({
        content: `Precios actualizados`,
        title: 'Cambiar precios',
        type: ToastTypes.Success,
      })
      hideEditLoader();
    };
    dialogRef.current.show({
      title: 'Cambio de cliente',
      body: `¿Desea actualizar el cliente?, esto afectará a los precios de los productos seleccionados actualmente`,
      actions: [
        Dialog.Action(
          <span><u>A</u>ceptar</span>,
          () => fnActualizaOnOk(clienteLocal),
          'btn-success',
          'a'
        ),
        Dialog.Action(
          <span><u>C</u>ancelar</span>,
          (dialog) => {
            if (cliente !== null) {
              const tmpCliente = { ...cliente };
              cambiarCliente(tmpCliente)
            }
            dialog.hide()
          },
          'btn-danger',
          'c'
        )
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    });
    return;
  }, [tabId, empresa, cliente, cambiarCliente, hideEditLoader, showEditLoader, dispatch]);


  const cambiarClienteSeleccionado = React.useCallback(async (clienteORuc: string | Cliente | null) => {
    clienteORuc = clienteORuc ?? config.rucConsumidorFinal
    if (tab.editStatus === StatesEdition.save) {
      const cliente = (typeof clienteORuc === 'string') ? await ClientesService.getCliente(clienteORuc) : clienteORuc;

      const formasPago = await VentasService.getFormasPago(cliente?.identificacion ?? config.rucConsumidorFinal);
      //setFormasPago(formasPago);
      dispatch(updateCliente({
        cliente: cliente,
        formasPago: formasPago,
        key: tabId,
        formaPago: [formasPago.find(x => x.codigo === empresa?.formaPagoDefectoVenta) ?? formasPago[0]],
      }));
      //setMostrarEdicionClientes(false);
      return;
    }
    //setMostrarEdicionClientes(false);
    if (detalles.length > 0) {
      if (typeof clienteORuc === 'string') {
        showEditLoader('Recuperando cliente...');
      }
      const cliente = (typeof clienteORuc === 'string') ? await ClientesService.getCliente(clienteORuc) : clienteORuc;
      if (cliente == null) {
        dispatch(addToast({ content: 'No se puede recuperar el cliente ' + clienteORuc, type: ToastTypes.Danger }))
        return;
      }
      if (detalles.length > 0 && !desdePlantilla) {
        cambiarClienteConfirm(cliente, detalles.slice(0), fecha)
      } else {
        cambiarCliente(cliente);
      }
    } else {
      // if (typeof clienteORuc === 'string' && clienteORuc !== config.rucConsumidorFinal) {
      // }
      const cliente = (typeof clienteORuc === 'string') ? await ClientesService.getCliente(clienteORuc) : clienteORuc;
      const formasPago = await VentasService.getFormasPago(cliente?.identificacion ?? config.rucConsumidorFinal);
      if (cliente) {
        dispatch(updateCliente({
          cliente: cliente,
          formasPago,
          key: tabId,
          formaPago: [formasPago.find(x => x.codigo === empresa?.formaPagoDefectoVenta) ?? formasPago[0]],
        }));
      }
      //setFormasPago(formasPago);
      // if (typeof clienteORuc === 'string' && clienteORuc !== config.rucConsumidorFinal) {
      //   //setLocalLoader({ show: false, mensaje: 'Recuperando cliente...' })
      // }
    }

  }, [detalles, fecha, desdePlantilla, cambiarClienteConfirm, cambiarCliente, tab, empresa, tabId, showEditLoader, dispatch]);

  const reenviarAutorizacion = React.useCallback(async () => {
    try {
      showEditLoader('Reenviando documento a autorizar...');
      await VentasService.reintentarAutorizacion(claveAcceso, TiposComprobantesSri.Factura);
      dispatch(addToast({
        content: 'El comprobante fue enviado al sri para su autorización.',
        type: ToastTypes.Info,
      }))
    } catch (error) {
      if (typeof (error) == 'string') {
        dispatch(addToast({
          content: error,
          type: ToastTypes.Danger,
        }))
      }
      console.log(error);
      //dispatch( )
    }
    hideEditLoader();
  }, [claveAcceso, showEditLoader, hideEditLoader, dispatch])



  const handlePrint = React.useCallback((design: boolean) => {
    setVerFacturaPdf({ claveAcceso: claveAcceso, design: design });
  }, [claveAcceso])


  const exportarDetalle = React.useCallback(() => {

  }, []);

  const onModalItemsItemSelected = React.useCallback((item: ItemVenta) => {
    const detalle: DetalleVenta = new DetalleVenta();
    detalle.setItem(item, empresa?.precision ?? 4);
    detalle.cantidad = 1;
    dispatch(addDetalle({
      key: tabId,
      detalle: detalle.getDetalle()
    }))
    dispatch(setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: false }));
  }, [tabId, empresa, dispatch]);

  //#region efectos

  const onCreditoButtonClick = React.useCallback(() => {
    if (formaPago.length > 0 && formaPago[0].descripcion !== "CREDITO") {
      dispatch(addToast({
        content: 'La forma de pago debe de ser credito para usar esta opción!!!',
        type: ToastTypes.Warning
      }))
      return;
    }
    const total = parseFloat(totales.total.toFixed(2))
    if (total <= 0) {
      dispatch(addToast({
        content: 'La factura debe tener valor para poder ingresar las cuotas de credito.',
        type: ToastTypes.Warning
      }))
      return;
    }
    setMostrarIngresoCreditos(true);
  }, [totales, formaPago, dispatch])

  const onFormaPagoChanged = React.useCallback((newValue) => {
    dispatch(setFormaPago({
      formaPago: newValue,
      key: tabId
    }));
  }, [tabId, dispatch])



  const onCuotasCreditoChanged = React.useCallback((nuevasCuotas) => {
    dispatch(setCuotasCredito({
      key: tabId,
      cuotas: nuevasCuotas
    }))
    setMostrarIngresoCreditos(false);
    dispatch(buttonClick({
      tabKey: tabId,
      button: ButtonTypes.save
    }))
  }, [tabId, dispatch])

  const buttonClickAnular = React.useCallback(() => {
    if (info && info.info?.estadoElectronico !== "AUTORIZADO") {
      dispatch(addToast({
        content: 'La factura no está autorizada, debe autorizarce antes de proceder a la anulación.',
        type: ToastTypes.Warning
      }))
    }
    setMostrarAnular(true);
  }, [info, dispatch])


  const handleButtonClick = React.useCallback((button: ButtonTypes) => {
    switch (button) {
      case ButtonTypes.edit:
        modoEdicion();
        break;
      case ButtonTypes.save: {
        guardar();
        break;
      }
      case ButtonTypes.undo:
        deshacer();
        break;
      case ButtonTypes.print:
        handlePrint(false);
        break;
      case ButtonTypes.print_design:
        handlePrint(true);
        break;
      case ButtonTypes.discount:
        dispatch(toogleUsaDescuentoGlobal());
        break;
      case ButtonTypes.export:
        exportarDetalle();
        break;
      case ButtonTypes.sendMail:
        setShowReenviarMail(true);
        break;
      case ButtonTypes.authorizations:
        reenviarAutorizacion();
        break;
      case ButtonTypes.broom:
        modoNuevo(puntoVenta ?? "", 0, true);
        break;
      case ButtonTypes.credit:
        onCreditoButtonClick();
        break;
      case ButtonTypes.disable:
        buttonClickAnular();
        break;
      default:
        break;
    }
    dispatch(clearButtonClick(tabId))
  }, [deshacer, dispatch, exportarDetalle, guardar, buttonClickAnular, handlePrint, modoEdicion, modoNuevo, tabId, puntoVenta, reenviarAutorizacion, onCreditoButtonClick])

  //#region  anular
  const onAnular = React.useCallback(async () => {
    if (info.info == null || info === undefined) {
      dispatch(addToast({
        content: 'No se encuentra la información del documento, comunicar a los desarrolladores.',
        type: ToastTypes.Danger
      }))
      return;
    }
    try {
      setMostrarAnular(false);
      showEditLoader("Anulando Comprobante...");
      await VentasService.anularDocumento(info.info);
      dispatch(addToast({
        content: 'El documento fue anulado correctamente.',
        type: ToastTypes.Success
      }))
      dispatch(setTabInfoAsInactive(tabId));
    } catch (error) {
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al anular ' + (typeof error === 'string' ? error : JSON.stringify(error)),
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Danger
      }));
    } finally {
      hideEditLoader();
    }
  }, [info, showEditLoader, hideEditLoader, tabId, dispatch])
  const onCancelAnular = React.useCallback(() => {
    setMostrarAnular(false);
  }, [])
  //#endregion

  //#region productos


  const onCrearProductoButtonClick = React.useCallback(() => {
    setCodigoBarrasItemEditar('');
    setMostrarEdicionItems(true);
  }, [])

  const onMasOpcionesButtonClick = React.useCallback(() => {
    dispatch(setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: true }));
    //setMostrarBusquedaItems(true);
  }, [tabId, dispatch])

  //#endregion

  const onObservacionesChanged = React.useCallback(({ value }) => {
    dispatch(updateObservaciones({
      key: tabId,
      observaciones: value ?? ''
    }))
  }, [tabId, dispatch])

  const muiTableCellsChanged = React.useCallback((row, col, newValue) => {
    const _detalles = detalles.slice(0);
    //const detalle = _detalles[row];
    const _detalle = new DetalleVenta();
    _detalle.setDetalle(_detalles[row], empresa?.precision ?? 4);

    //const dt = datosEdicion.detallesMuiTable;
    switch (col) {
      case 2://TABLECOL_DESCRIPCION
        _detalle.descripcion = newValue;
        //dt[row].descripcion = newValue;
        break;
      case 3://TABLECOL_CANTIDAD
        _detalle.cantidad = parseFloat(newValue);
        //dt[row].cantidad = newValue ? parseFloat(newValue) : 0;
        break;
      case 4://TABLECOL_PRECIO
        _detalle.precio = parseFloat(newValue);
        //dt[row].precio = newValue ? parseFloat(newValue) : 0;
        break;
      default:
        break;
    }
    _detalles[row] = _detalle.getDetalle();
    dispatch(updateDetalles({
      key: tabId,
      detalles: _detalles
    }))
  }, [detalles, tabId, empresa, dispatch]);


  const ocultarBusquedaItems = React.useCallback(() => {
    dispatch(setMostrarBusquedaItems({ key: tabId, mostrarBusquedaItems: false }));
  }, [tabId, dispatch]);

  //#region efectos

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(puntoVenta ?? "", tab.info?.numero ?? 0);
    } else {
      cargarVenta(info.numero, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick]);


  React.useEffect(() => {
    if (!loading) {
      calcularTotales(detalles, descuentoGlobal);
    }
  }, [loading, detalles, descuentoGlobal, calcularTotales])

  React.useEffect(() => {
    const eliminarDetalle = (indice: number, detalles: Array<DetalleVentaRedux>) => {
      detalles.splice(indice, 1);
      dispatch(updateDetalles({
        detalles: detalles,
        key: tabId
      }));
      setFilaEliminar(-1);
    };

    const eliminarDetalleConfirm = (indice: number) => {
      const detalle = detalles[indice];
      dialogRef.current.show({
        title: 'Eliminar detalle',
        body: `¿Desea eliminar el item ${detalle.descripcion}?`,
        actions: [
          Dialog.Action(
            <span><u>E</u>liminar</span>,
            () => {
              eliminarDetalle(indice, detalles.slice(0));
            },
            'btn-success',
            'e'
          ),
          Dialog.Action(
            <span><u>C</u>ancelar</span>,
            (dialog) => {
              setFilaEliminar(-1);
              dialog.hide()
            },
            'btn-danger',
            'c'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide();
          setFilaEliminar(-1);
          console.log('closed by clicking background.')
        }
      });
      return;
    };
    if (filaEliminar >= 0) {
      eliminarDetalleConfirm(filaEliminar);
    }
  }, [filaEliminar, detalles, tabId, dispatch])

  React.useEffect(() => {
    if (imprimir) {
      handlePrint(false);
    }
  }, [imprimir, handlePrint, tabId, dispatch])

  //#endregion
  //#endregion
  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }} >
      <BlockUi tag="div" loader={LoadingIndicator} blocking={datosEdicion.loader.show} message={datosEdicion.loader.mensaje}  >
        {info.info !== null && mostrarAnular &&
          <AnularDocumentoVenta onOk={onAnular} onCancel={onCancelAnular} info={info.info} />
        }
        {showReenviarMail && datosEdicion.cliente != null && (
          <PopupReenvioMail
            showConfirm={showReenviarMail}
            itemReenviarMail={{
              claveAcceso: datosEdicion.claveAcceso,
              cliente: datosEdicion.cliente,
            }}
            closeConfirm={() => setShowReenviarMail(false)}
          />
        )}
        {datosEdicion.tieneError &&
          <VisualizaError titulo='Error en ventas' mensaje={datosEdicion.mensajeError} onOk={() => {
            dispatch(setMuestraError({ key: props.tabId, tieneError: false, mensajeError: '' }))

          }} />
        }
        {tab.editStatus === StatesEdition.save && verFacturaPdf &&
          <VerRIDE modo='DevExpressReport' claveAcceso={verFacturaPdf.claveAcceso} vista={verFacturaPdf.design ? "Designer" : "Viewer"} onClose={() => {
            setVerFacturaPdf(undefined)
            dispatch(setImprimir({
              imprimir: false,
              key: tabId
            }))
          }
          } />
        }
        {mostrarIngresoCreditos &&
          <CreditosVenta
            cuotas={cuotas}
            fechaFactura={datosEdicion.fecha}
            totalCobrar={datosEdicion.totales.total}
            onCancel={() => setMostrarIngresoCreditos(false)}
            onOk={onCuotasCreditoChanged}
          />
        }
        {agregarDetalleMovil &&
          <AgregarDetalleVentaMobile
            key='agregarDetalleVentaMovil'
            onOk={agregarDetalleVentaMobile}
            onCancel={() => setAgregarDetalleMovil(false)}
            cliente={datosEdicion.cliente}
            fecha={DateUtils.pickersDateToApiDate(datosEdicion.fecha)}
          />
        }
        <Dialog ref={dialogRef} />
        {mostrarEdicionItems &&
          <ModificaItem
            transaccion={"ventas"}
            show={mostrarEdicionItems}
            codigoBarras={codigoBarrasItemEditar}
            onCancel={() => setMostrarEdicionItems(false)}
            onChanged={async (codigoBarras) => {
              //console.log(codigoBarras)
              setMostrarEdicionItems(false);
              setCodigoBarrasItemEditar('');
              if (codigoBarrasItemEditar === '') { //nuevo
                const items = await VentasService.buscarItemCodigoBarras(codigoBarras, cliente?.identificacion ?? config.rucConsumidorFinal, DateUtils.pickersDateToApiDate(fecha)
                );
                if (items.length > 0) {
                  onModalItemsItemSelected(items[0]);
                }
              }
            }}
          />
        }
        {mostrarBusquedaItems && (
          <MainModalItems
            transaccion='ventas'
            show={mostrarBusquedaItems}
            cliente={datosEdicion.cliente !== null ? datosEdicion.cliente : config.rucConsumidorFinal}
            onCancel={ocultarBusquedaItems}
            returnItem={onModalItemsItemSelected}
          />
        )}
        <fieldset disabled={tab.editStatus === StatesEdition.save} >
          <div>
            <RowContainer gutters={true} >
              <CustomCol xs='12' md='7'>
                <div>
                  <RowContainer gutters={true}>

                    <CustomCol xs='12'>
                      <Labeled
                        label='Nombre / Razón Social del cliente'
                      >
                        <BuscarClienteLookUp disabled={tab.editStatus === StatesEdition.save} selected={cliente} onChanged={cambiarClienteSeleccionado} allowAdd allowClear allowEdit />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  {cliente &&
                    <RowContainer>
                      <CustomCol
                        xs='12'
                      >
                        <Labeled
                          label='Dirección'
                        >
                          <TextBox value={cliente.direccion} readOnly />
                        </Labeled>
                      </CustomCol>
                      <CustomCol
                        xs='4'
                      >
                        <Labeled
                          label='Teléfono'
                        >
                          <TextBox
                            readOnly
                            value={cliente.telefono ?? ''}
                          />
                        </Labeled>
                      </CustomCol>
                      <CustomCol
                        xs='8'
                      >
                        <Labeled
                          label='Correo eléctronico'
                        >
                          <TextBox
                            readOnly
                            value={cliente.email ?? ''}
                          />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                  }
                </div>
              </CustomCol>
              <CustomCol xs='12' md='5'>
                <div>
                  <RowContainer>
                    <CustomCol xs='12' md='5'>
                      <Labeled
                        label='Fecha Factura'
                      >
                        <DateBox
                          value={fecha}
                          readOnly
                        />
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs='12' md='7'>
                      <Labeled
                        label='Número de factura'
                      >
                        <CRow gutters={false}>
                          <CCol xs='3' >
                            <TextBox readOnly placeholder='001' value={datosEdicion.establecimiento} />
                          </CCol>
                          <CCol xs='3'>
                            <TextBox readOnly placeholder='001' value={datosEdicion.puntoEmision} />
                          </CCol>
                          <CCol xs='6'>
                            <TextBox readOnly placeholder='0000000000' value={"0".repeat(10 - datosEdicion.numerofactura.toString().length) + datosEdicion.numerofactura.toString()} />
                          </CCol>
                        </CRow>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol
                      xs='6' md='3'
                    >
                      <Labeled
                        label='# Detalles'
                      >
                        <TextBox
                          readOnly
                          value={datosEdicion.detalles.length.toString()}
                        />
                      </Labeled>
                    </CustomCol>
                    <CustomCol
                      xs='6' md='3'
                    >
                      <Labeled
                        label='# Articulos'
                      >
                        <TextBox
                          readOnly
                          value={detalles.reduce((prev, next) => prev + next._cantidad, 0).toFixed(2)}
                        />
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs='12' md='6'>
                      <Labeled
                        label='Punto Venta'
                      >
                        <TextBox
                          readOnly
                          text={puntoVenta ?? ''}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                  {((tab.editStatus === StatesEdition.save)) && //Electronica
                    <>
                      < RowContainer >
                        <CustomCol xs='12' xxl='8'>
                          {!isMobileOnly &&
                            <Barcode text={datosEdicion.claveAcceso} />
                          }
                          {isMobileOnly &&
                            <Labeled
                              label='Autorización'
                            >
                              <small>{datosEdicion.claveAcceso}</small>
                            </Labeled>
                          }
                        </CustomCol>
                        <CustomCol xs='12' xxl='4'>
                          <Labeled
                            label='Fecha Autorización'
                          >
                            <CBadge
                              color={datosEdicion.fechaAutorizacion ? 'info' : 'danger'}
                            >
                              {datosEdicion.fechaAutorizacion ? datosEdicion.fechaAutorizacion : "No Autorizado"}
                            </CBadge>
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                    </>
                  }
                </div>
              </CustomCol>
            </RowContainer>
            {tab.editStatus !== StatesEdition.save &&
              <RowContainer>
                <CCol>
                  <div className='buttonsContainer' >
                    {isMobileOnly &&
                      <Button
                        text='Agregar Detalle'
                        icon='add'
                        stylingMode='contained'
                        type='default'
                        onClick={() => {
                          setAgregarDetalleMovil(true);
                        }}
                      />
                    }
                    <Button
                      style={buttonsProductoStyle}
                      text='Crear Producto'
                      icon='file'
                      stylingMode='contained'
                      type='default'
                      onClick={onCrearProductoButtonClick}
                      elementAttr={{
                        id: 'btnCreateItem'
                      }}
                    />
                    <Button
                      style={buttonsProductoStyle}
                      hint='Más opciones'
                      icon='more'
                      stylingMode='contained'
                      type='default'
                      onClick={onMasOpcionesButtonClick}
                      elementAttr={{
                        id: 'btnOptionsItems'
                      }}
                    >

                    </Button>
                  </div>
                </CCol>
              </RowContainer>
            }
            <div id="detalleVentayTotales" >

              {isMobileOnly && //Mobiles
                <RowContainer>
                  <div style={{ paddingTop: '5px' }}>
                    <MUIDataTable
                      data={detalles}
                      columns={getColumnnasTablaDetalleVentas(
                        muiTableCellsChanged,
                        (row) => setFilaEliminar(row)
                      )}
                      options={OptionsTablaDetalleVentas}
                    />
                  </div>
                </RowContainer>
              }
              {isMobile && !isMobileOnly &&  //Tablets
                < RowContainer >
                  <CCol xs='12'>

                    <div className='detallesTableContainer' style={{ maxHeight: `${window.innerHeight / 2}px` }}  >
                      <CustomSheet
                        id='sheetDetalle'
                        striped
                        blankRows={2}
                        columns={sheetColumns}
                        data={detalles}
                        editable={tab.editStatus !== StatesEdition.save}
                        initializeNewRow={(col, value) => {
                          const detalle: DetalleVenta = new DetalleVenta();
                          if (col.name === 'descripcion') {
                            const item = value as ItemVenta;
                            detalle.setItem(item, empresa?.precision ??4);
                          } else {
                            detalle[col.name] = value;
                          }

                          return detalle.getDetalle();
                        }}
                        onCellChanged={sheetOnCellsChanged}
                        showRowNumber
                        rowCommands={sheetCommands}
                      />
                    </div>

                  </CCol>
                </RowContainer>
              }

              {!isMobile && //Escritorio
                < RowContainer >
                  <CCol xs='12'>
                    <div className='detallesTableContainer overflow-auto' onKeyDown={(e) => { }} style={{ width: window.innerWidth > 900 ? '100%' : `${window.innerWidth - 20}px`, maxHeight: `${window.innerHeight / 2}px` }}  >
                      <CustomSheet
                        id='sheetDetalle'
                        blankRows={2}
                        striped
                        columns={sheetColumns}
                        data={detalles}
                        editable={tab.editStatus !== StatesEdition.save}
                        initializeNewRow={(col, value) => {
                          const detalle: DetalleVenta = new DetalleVenta();
                          if (col.name === 'descripcion') {
                            const item = value as ItemVenta;
                            detalle.setItem(item, empresa?.precision ?? 4);
                          } else {
                            detalle[col.name] = value;
                          }

                          return detalle.getDetalle();
                        }}
                        onCellChanged={sheetOnCellsChanged}
                        showRowNumber
                        rowCommands={sheetCommands}
                      />
                    </div>
                  </CCol>
                </RowContainer>
              }

              <RowContainer>
                <CustomCol xs='12' md={usaDescuentoGlobal ? '4' : '8'}>
                  <Labeled
                    label='Observaciones'
                  >
                    <TextArea

                      value={observaciones}
                      onValueChanged={onObservacionesChanged}
                    />
                  </Labeled>
                </CustomCol>
                {usaDescuentoGlobal &&
                  <>
                    <CustomCol xs='3' md='1' lg='1'>
                      <Labeled
                        label='Desc ($)'
                      >
                        <CInput
                          name='inputDescuentoXItemValor'
                          type='number'

                          defaultValue={datosEdicion.descuentoGlobal}
                          onBlur={(ev) => {
                            if (ev.currentTarget.value) {
                              establecerDescuentoGlobal(parseFloat(ev.currentTarget.value));
                            } else {
                              establecerDescuentoGlobal(datosEdicion.descuentoGlobal);
                            }
                          }}
                        />
                      </Labeled>

                    </CustomCol>
                  </>
                }
                <CustomCol xs='12' md='3'>
                  <Labeled
                    label='Forma Pago'
                  >
                    <SelectBox
                      id='selectFormaPago'
                      displayExpr='descripcion'
                      keyExpr='codigo'
                      options={formasPago}
                      selected={formaPago && formaPago.length > 0 ? formaPago[0] : null}
                      placeholder='Forma de pago'
                      multiple={false}
                      onChange={onFormaPagoChanged}
                      disabled={tab.editStatus === StatesEdition.save}
                      clearButton={true}
                      invalid={datosEdicion.formaPago.length === 0}
                    />
                  </Labeled>
                </CustomCol>
              </RowContainer>
              {!isMobile &&
                < RowContainer >
                  <div className='totalesContainer'>
                    <div className='totalContainer'>
                      <div className='totalLabel'>
                        <span>Subtotal</span>
                      </div>
                      <div className='totalValue'>
                        <span> {(datosEdicion.totales.subtotal - datosEdicion.totales.descuentoGloabal).toFixed(2)} </span>
                      </div>
                    </div>
                    <div className='totalContainer'>
                      <div className='totalLabel'>
                        <span>Subtotal 0%</span>
                      </div>
                      <div className='totalValue'>
                        <span>{datosEdicion.totales.subtotal0.toFixed(2)} </span>
                      </div>
                    </div>
                    <div className='totalContainer'>
                      <div className='totalLabel'>
                        <span>I.C.E</span>
                      </div>
                      <div className='totalValue'>
                        <span>{datosEdicion.totales.ice.toFixed(2)} </span>
                      </div>
                    </div>
                    <div className='totalContainer'>
                      <div className='totalLabel'>
                        <span>Subtotal IVA</span>
                      </div>
                      <div className='totalValue'>
                        <span> {datosEdicion.totales.subtotalIva.toFixed(2)} </span>
                      </div>
                    </div>
                    <div className='totalContainer'>
                      <div className='totalLabel'>
                        <span>IVA {iva}%</span>
                      </div>
                      <div className='totalValue'>
                        <span> {datosEdicion.totales.iva.toFixed(2)} </span>
                      </div>
                    </div>
                    <div className='totalContainer'>
                      <div className='totalLabel'>
                        <span>Desc. Items</span>
                      </div>
                      <div className='totalValue'>
                        <span> {datosEdicion.totales.descuento.toFixed(2)} </span>
                      </div>
                    </div>
                    <div className='totalContainer'>
                      <div className='totalLabel'>
                        <span>Total</span>
                      </div>
                      <div className='totalValue'>
                        <span> {datosEdicion.totales.total.toFixed(2)} </span>
                      </div>
                    </div>
                  </div>
                </RowContainer>
              }
              {isMobile &&
                <>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Subtotal</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      <small>{datosEdicion.totales.subtotal.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Subtotal 0%</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      <small>{datosEdicion.totales.subtotal0.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>ICE</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      <small>{datosEdicion.totales.ice.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Subtotal IVA</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      <small>{datosEdicion.totales.subtotalIva.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Impuestos</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      <small>{datosEdicion.totales.iva.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Descuento  Items</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      <small>{datosEdicion.totales.descuento.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Total</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      <small>{datosEdicion.totales.total.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                </>
              }
            </div>
          </div>
        </fieldset>
        {isMobile &&
          <div style={{ height: '40px' }} />
        }
      </BlockUi>
    </div >
  );
};

const sheetColumns: Array<DataSheetColumn> = [
  {
    id: 1,
    colSpan: 1,
    name: 'codigoBarras',
    headerText: 'Código',
    width: '10%',
    order: 1,
    dataEditor: CustomSheetTextEditor,
    getReadOnly: (data) => {
      return data !== null;
    }
  },
  {
    id: 2,
    colSpan: 1,
    name: 'descripcion',
    headerText: 'Descripción',
    width: '20%',
    order: 2,
    getReadOnly: (data) => {
      return false;
    },
    getDataEditor: (data) => {
      return data == null ? SheetDescripcionItemSelectEditor : SheetTextEditor
    }
  },
  {
    id: 3,
    colSpan: 1,
    name: 'porcentajeiva',
    headerText: 'Imp.',
    width: '100px',
    order: 3,
    readOnly: true,
    getDisplay: (value, data: DetalleVentaRedux) => `${(data.porcentajeiva * 100).toFixed(2)}% - ${data._iva.toFixed(2)}`
  },
  {
    id: 4,
    colSpan: 1,
    name: '_cantidad',
    headerText: 'Cant.',
    width: '60px',
    order: 4,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer
  },
  {
    id: 5,
    colSpan: 1,
    name: '_porcentajeDescuento',
    headerText: 'Desc %.',
    width: '80px',
    order: 5,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleVentaRedux) => `${data._porcentajeDescuento.toFixed(2)}% (${data._descuento.toFixed(2)})`
  },
  {
    id: 6,
    colSpan: 1,
    name: '_precioUnitario',
    headerText: 'P. Unit.',
    width: '80px',
    order: 6,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer
  },
  {
    id: 7,
    colSpan: 1,
    name: '_precioIva',
    headerText: 'Unit. IVA',
    width: '80px',
    order: 7,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleVentaRedux) => data._precioIva.toFixed(4)
  },
  {
    id: 8,
    colSpan: 1,
    name: '_total',
    headerText: 'Subtotal',
    width: '80px',
    order: 8,
    readOnly: true,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleVentaRedux) => data._subtotal.toFixed(2)
  }
];

export const defaultDatosEdicionFactura: FacturaDatosEdicion = {
  numero: 0,
  autorizacion: '',
  claveAcceso: '',
  cliente: null,
  codigoBarrasItemEditar: '',
  descuentoGlobal: 0,
  descuentoPorItemPorcentaje: 0,
  descuentoPorItemValor: 0,
  detalles: [],
  establecimiento: '',
  fecha: '',
  fechaAutorizacion: '',
  formaPago: [],
  identificadorTransaccion: '',
  imprimir: false,
  iva: 0,
  loader: {
    mensaje: 'Cargando...',
    show: true
  },
  mensajeError: '',
  mostrarBusquedaItems: false,
  mostrarEdicionCliente: false,
  mostrarEdicionItems: false,
  numerofactura: '',
  observaciones: '',
  puntoEmision: '',
  tieneError: false,
  totales: {
    descuento: 0,
    descuentoGloabal: 0,
    ice: 0,
    iva: 0,
    subtotal: 0,
    subtotal0: 0,
    subtotalIva: 0,
    total: 0
  },
  loading: true,
  motivoNotaCredito: '',
  formasPago: [],
  cuotas: [],
  desdePlantilla: false
}

const buttonsProductoStyle = { marginLeft: '2px' };

export const InvoiceButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: true,
  Imprimir: true,
  Descuento: true,
  Credito: true,
  Enviar: true,
  Autorizar: true,

}

export default React.memo(Venta);