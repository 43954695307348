import { formatMessage } from 'devextreme/localization';

export const lh = {
  getMessage
}

export enum MessagesKeys {
  RetencionNroRequerido = 'Retencion-NroRequerido',
  RetencionNroIncorrecto = 'Retencion-NroIncorrecto',
  RetencionAutorizacionRequerida = 'Retencion-AutorizacionRequerida',
  RetencionAutorizacionIncorrecta = 'Retencion-AutorizacionIncorrecta',
  RetencionFormaPagoRequerida = 'Retencion-FormaPagoRequerida',
  RetencionSinDetalles = 'Retencion-SinDetalles',
  RetencionNroLoteIncorrecto = "Retencion-NroLoteIncorrecto",
  RetencionDetalleTipoNoSeleccionado = "Retencion-Detalle-TipoNoSeleccionado",
  RetencionDetalleBaseImponibleMayor = "Retencion-Detalle-BaseImponibleMayor",
  RetencionDetalleBaseImponibleCero = "Retencion-Detalle-BaseImponibleCero",
  ClienteTipoIdentificacionRequerida = "Cliente-TipoIdentificacionRequerida",
  ClienteNumeroIdentificacionRequerido = "Cliente-NumeroIdentificacionRequerido",
  ClienteNumeroIdentificacionIncorrecto = "Cliente-NumeroIdentificacionIncorrecto",
  ClienteNumeroPasaporteRequerido = "Cliente-NumeroPasaporteRequerido",
  ClienteTipoClienteRequerido = "Cliente-TipoClienteRequerido",
  ClienteNumeroIdentificacionMax = "Cliente-NumeroIdentificacionMax",
  GlobalCampoRequerido = 'Global-CampoRequerido',
  GlobalFormatoCampoIncorrecto = "Global-Formato-Campo-Incorrecto",
  FormConfirmExitForm = "Form-Confirm-ExitForm",
  NotFoundRegisterSelec = "NotFoundRegisterSelec",
  FormConfirmDeleteForm = "Form-ConfirmDeleteForm",
  FormNotFoundChanges = "Form-NotFoundChanges",
  FormConfirmChangeForm = "Form-Confirm-ChangeForm",
  DataNotFoundByExport = "DataNotFoundByExport",
  ConfirmEnumerarEntries = "ConfirmEnumerarEntries",
  SelectSalesPoint = "SelectSalesPoint",
  ConfigurarAutorizacionSRI = "Configurar-AutorizacionSRI",
  GlobalSinDetalles = "Global-Sin-Detallles",
  EstadoRequerido = "EstadoRequerido",
  ProveedorTipoRequerida = "ProveedorTipoRequerida",
  ProveedorClasificacionRequerida = "ProveedorClasificacionRequerida",
  ProveedorUbicacionRequerida = "ProveedorUbicacionRequerida",
  ProveedorTipoTelefonoRequerida = "ProveedorTipoTelefonoRequerida",
  ProveedorTipoOperadoraRequerida = "ProveedorTipoOperadoraRequerida",
  LiquidacionCompraTipoIdentificacionIncorrecta = "LiquidacionCompra-TipoIdeProveedorIncorrecta",
  GlobalNumeroRegistrosEncontrados = "Global-NumeroRegistrosEncontrados",
  GlobalNotSelected = "Global-NotSelected",
  AuthEmpresaRequired = "Auth-Empresa-Required",
  AuthLocalRequired = "Auth-Local-Required",
  ClienteNumeroRucRequerido = "Cliente-NumeroRucRequerido",
  ClienteNumeroRucIncorrecto = "Cliente-NumeroRucIncorrecto",
  UpdateActionNotValid = "UpdateActionNotValid",
  ConfirmDuplicar = "ConfirmDuplicar",
  DataNotFoundTransactionExport = "DataNotFoundTransactionExport",
  TransaccionSinDetalles = "Transaccion-SinDetalles",
  GenericFormaPagoRequerida = 'Generic-FormaPagoRequerida',
  GenericProyectoRequerida = 'Generic-ProyectoRequerido',
  GenericLocalRequerida = 'Generic-LocalRequerido',
  GenericTipoCompraRequerida = 'Generic-TipoCompraRequerido',
  GenericSustentoTributarioRequerida = 'Generic-SustentoTributarioRequerido',
  GenericNumeroComprobanteRequerido = "Generic-NumeroComprobanteRequerido",
  GenericAutorizacionComprobanteRequerido = "Generic-AutorizacionComprobanteRequerido",
  GenericPtoEmisionRequerido = "Generic-PtoEmisionRequerido",
  GenericEstablecimientoRequerido = "Generic-AutorizacionComprobanteRequerido",
  GenericNumericInvalid = "Generic-NumericInvalid",
  GenericSelectRequerid = "Generic-SelectRequerid",
  GenericRequerid = "Generic-Requerid",
  GenericUpdate = "Generic-Update"
}

function getMessage(key: MessagesKeys | string, ...values: Array<string>) {

  return formatMessage(key, ...values ?? [])
}