import { RequestHelper } from "../../../helpers/requestHelper";
import { SesionService } from "../../../services/sesion.service";
import { TipoComprobante } from "../../../store/types";
// import { isIOS13, isMacOs } from "react-device-detect";

export const TiposComprobantesService = {
  getAll,
};

async function getAll(): Promise<Array<TipoComprobante>> {
  const sesion = SesionService.getCurrentSesion();
  let punto_venta: string = "vacio";

  punto_venta = localStorage.getItem(sesion.empresa?.codigo + "_" + sesion.usuario?.codigo + "_" + 'punto_venta') ?? 'vacio'

  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    host: punto_venta,
  };
  const apiData = await RequestHelper.get<Array<TipoComprobante>>(
    "generales",
    "voucherType/getAll",
    "",
    queryData
  );
  return apiData;
}
