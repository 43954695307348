import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccionMenu } from "../../../../../store/types";
import { MenuState } from "../../../types/types";



const initialState: MenuState = {
  loading: true,
  accions: []
}


const menuVentasSlice = createSlice({
  name: 'notasDebitoMenu',
  initialState: initialState,
  reducers: {
    setMenuButtons(state, action: PayloadAction<Array<AccionMenu>>) {
      state.accions = action.payload;
      state.loading = false;
    }
  }
})

//export const selectMenuVentas = (state: RootState) => { return state.ventas.menuVentas };
export const { setMenuButtons } = menuVentasSlice.actions;
export const menuReducer = menuVentasSlice.reducer;