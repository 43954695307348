import { findIconDefinition, IconDefinition, IconLookup } from "@fortawesome/fontawesome-svg-core";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { number, string } from "yup";
import { RequestHelper } from "../helpers/requestHelper"
import { RequestHelperDotNet } from "../helpers/requestHelperDotNet";
import { utilidades } from "../helpers/utilidades";
import { AccionMenu, MenuShortCut, ModuloMenu, OpcionMenu } from "../store/types";
import { SesionService } from "./sesion.service";

export const menuService = {
  cargarMenu,
  getPermisosUsario,
  cargarAccionesUsuario,
  getIcon
}

async function cargarMenu(objeto: any): Promise<any> {
  try {
    if (utilidades.canUseNetApi()) {
      const apiData = await RequestHelperDotNet.get<Array<ModuloMenu>>('Permissions', '', '', null);
      return apiData.map(x => {
        return {
          codigo: x.codigo,
          name: x.nombre,
          url: x.url,
          icon: x.icon,
          urlDocumentacion: null,
          children: convertNetMenuOptionsToChildren(x.opciones)
        };
      });
    } else {
      const data = await RequestHelper.postUrlEncodedAll<any>('menu', 'listar', objeto);
      return data;
    }

  } catch (error) {
    return error;
  }
}

function convertNetMenuOptionsToChildren(options: Array<OpcionMenu>): Array<any> {
  const data: Array<any> = [];
  for (const option of options) {
    const tmp: any = {
      codigo: option.codigo,
      name: option.nombre,
      url: option.url,
      icon: option.icon,
      urlDocumentacion: null,
    };
    if (option.tieneHijos) {
      tmp.children = convertNetMenuOptionsToChildren(option.opciones ?? []);
    } else {
      tmp.acciones = option.acciones?.map((x, indx) => {
        return {
          name: x.nombre,
          icon: x.icon,
          shortcut: x.shortcut,
          main: x.main ? "1" : "0",
          modal: x.modal ? "1" : "0",
          order: indx
        }
      })
    }
    data.push(tmp);
  }
  return data;
}


function getShortcut(text: string | null): MenuShortCut | null {
  if (text && text.length > 0) {
    try {
      const splited = text.split('+');
      const modifiers: Array<"ctrl" | "shift"> = [];
      let key: number = 0;
      let index = 0;
      while (index < splited.length) {
        if (splited[index] === "ctrl" || splited[index] === "shift") {
          modifiers.push(splited[index] as any);
        } else {
          key = parseInt(splited[index]);
        }
        index++;
      }
      const shortCut: MenuShortCut = {
        modificadores: modifiers,
        keyCode: key
      }
      return shortCut;
    } catch (error) {
      console.log(error);
      return null;
    }

  } else {
    return null;
  }
}

function getIcon(text: string | null): IconDefinition {
  try {
    const icon = (text ?? 'far fa-question-circle').split(' ');
    //const iconLookup2: IconLookup = { prefix: 'fal', iconName: "check-double" }
    const iconLookup: IconLookup = { prefix: icon[0] as any, iconName: icon[1].split('-').slice(1).join('-') as any }
    const iconDefinition: IconDefinition = findIconDefinition(iconLookup)
    return iconDefinition;
  } catch (error) {
    console.log('error al cargar icono ' + error)
    const iconLookup: IconLookup = { prefix: 'far', iconName: 'question-circle' }
    const iconDefinition: IconDefinition = findIconDefinition(iconLookup);
    return iconDefinition;
  }

}


function cargarAccionesMenu(acciones: Array<any>): Array<AccionMenu> {
  const acc = acciones.map(a => {
    const accion: AccionMenu = {
      nombre: a.name,
      icon: a.icon,
      shortcut: getShortcut(a.shortcut),
      main: a.main == "1",
      modal: a.modal == "1",
      actionType: a.name
    }
    return accion;
  });
  return acc;
}

function cargarOpcionesMenu(children: Array<any>): Array<OpcionMenu> {
  const opciones = children.map(o => {
    const opcion: OpcionMenu = {
      codigo: parseInt(o.codigo),
      nombre: o.name,
      url: o.url,
      icon: o.icon,
      tieneHijos: o.children !== undefined,
      acciones: []
    };
    if (opcion.tieneHijos) {
      opcion.opciones = cargarOpcionesMenu(o.children);
    } else {
      opcion.acciones = cargarAccionesMenu(o.acciones)
    }
    return opcion;
  })
  return opciones;
}

async function getPermisosUsario(): Promise<Array<ModuloMenu>> {

  if (utilidades.canUseNetApi()) {
    const apiData = await RequestHelperDotNet.get<Array<ModuloMenu>>('Permissions', '', '', null);
    return apiData;
  } else {
    const sesion = SesionService.getCurrentSesion();
    const queryData = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo
    };
    const apiData = await RequestHelper.postUrlEncoded<Array<any>>("menu", "listar", queryData);
    const menu = apiData.map(m => {
      const modulo: ModuloMenu = {
        codigo: parseInt(m.codigo),
        nombre: m.name,
        url: m.url,
        icon: getIcon(m.icon),
        opciones: cargarOpcionesMenu(m.children as Array<any>)
      }
      return modulo;
    })
    return menu;
  }


}

const sortByProperty = property => {
  return function (a, b) {
    if (a[property] > b[property]) return 1
    else if (a[property] < b[property]) return -1

    return 0
  }
}

async function cargarAccionesUsuario(codigoModulo: number, opciones: Array<number>): Promise<Array<AccionMenu>> {
  const menu = await getPermisosUsario();
  const modulo = menu.find(x => x.codigo === codigoModulo);
  if (modulo) {
    let opcion = modulo.opciones.find(op => op.codigo === opciones[0]);
    if (opcion) {
      for (let index = 1; index < opciones.length; index++) {
        const opcionCodigo = opciones[index];
        if (opcion && opcion.tieneHijos && opcion.opciones) {
          opcion = opcion.opciones?.find(op => op.codigo === opcionCodigo);
        }
      }
    }
    if (opcion) {
      return opcion?.acciones ?? [];
    } else {
      return [];
    }
  } else {
    return [];
  }

}
