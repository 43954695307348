import * as React from 'react';
import ko from 'knockout';
import 'devexpress-reporting/dx-reportdesigner';
import { UrlHelper } from '../../../helpers/urlHelper';
import { ajaxSetup } from '@devexpress/analytics-core/analytics-utils'
import { RequestHelperDotNet } from '../../../helpers/requestHelperDotNet';
import esCore from './localization/dx-analytics-core.es.json'
import esRep from './localization/dx-reporting.es.json'


export class ReportDesigner extends React.Component {
  constructor(props) {
    super(props);
    //const sesion = SesionService.getCurrentSesion();
    const urls = UrlHelper.getUrls();
    const reportUrl =props.reportName; //`${sesion.empresa.ruc}-${sesion.usuario.codigo}-${props.reportName}`;
    this.reportUrl = ko.observable(reportUrl);
    this.requestOptions = {
      host: urls.reportServerBasePath,
      getDesignerModelAction: "/api/ReportDesigner/GetReportDesignerModel"
    };
    const headers =RequestHelperDotNet.getConfig('get').headers;
    headers.dataId = props.dataId;
    delete headers['Content-Type'];
    ajaxSetup.ajaxSettings ={
      headers: headers, 
    }
    this.callbacks = {
      CustomizeLocalization: function (s, e) {
        e.LoadMessages(esCore);
        e.LoadMessages(esRep);
        
      }
    };
  }
  //api/ReportDesigner/GetReportDesignerModel
  render() {
    return (<div style={{height:`${window.innerHeight - (window.innerHeight * 0.10)}px`}} ref="designer" data-bind="dxReportDesigner: $data"></div>);
  }
  componentDidMount() {
    ko.applyBindings({
      reportUrl: this.reportUrl,
      requestOptions: this.requestOptions,
      callbacks: this.callbacks
    }, this.refs.designer);
  }
  componentWillUnmount() {
    ko.cleanNode(this.refs.designer);
  }
};