import { CButton, CDropdown, CDropdownDivider, CDropdownItem, CDropdownMenu, CDropdownToggle, CTooltip } from '@coreui/react';
import * as React from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { AccionMenu } from '../../../store/types';
import { AppBar, createStyles, makeStyles, Menu, Theme, Toolbar, MenuItem, Fade } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { menuService } from '../../../services/menu.service';
import ContextMenu from 'devextreme-react/context-menu';
import { ButtonTypes } from './types';
import { MenuSpinner } from './customSpinner';
interface IGlobalMenuProps {
  onClick: (accion: AccionMenu) => void,
  getButtonDisabled: (accion: AccionMenu) => boolean,
  acciones: AccionMenu[],
  showExportDesingOptions?: boolean,
  hideInactiveButtons?: boolean,
  currentWorking?: ButtonTypes,
  printButtonAsDropdown?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    paper: {
      paddingBottom: 50,
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    subheader: {
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      top: 'auto',
      bottom: 0,
    },
    grow: {
      flexGrow: 1,
    },
    fabButton: {
      position: 'absolute',
      zIndex: 1,
      top: -30,
      left: 0,
      right: 0,
      margin: '0 auto',
    },
  }),
);

export function GlobalMenu(props: IGlobalMenuProps) {
  const { hideInactiveButtons, acciones, currentWorking, printButtonAsDropdown } = props;
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const handleButtonClick = React.useCallback(() => {

  }, [])

  const toogleMenu = (status) => {
    setOpen(!status);
  }

  if (isMobile) {
    let maxItems = 5;
    if (window.innerWidth < 400) {
      maxItems = 5;
    } else if (window.innerWidth < 500) {
      maxItems = 6;
    } else if (window.innerWidth < 800) {
      maxItems = 10;
    } else {
      maxItems = 14;
    }
    return (
      <AppBar key='menuVentasAppBar' position="fixed" color="inherit" className={classes.appBar}>
        <Toolbar>
          {props.acciones.filter(x => !props.getButtonDisabled(x)).map((m, index) => {
            let disabled = (currentWorking !== undefined) || props.getButtonDisabled(m);
            <div className={classes.grow} />
            if (index < maxItems) {

              return (
                <CButton
                  key={m.nombre}
                  color='primary'

                  variant="ghost"
                  disabled={disabled}
                  onClick={() => props.onClick(m)}
                > 
                  <FontAwesomeIcon icon={menuService.getIcon(m.icon) ?? faQuestionCircle} size={isMobile && !isMobileOnly ? '3x' : '2x'} />
                </CButton>
              );
            }
          })
          }
          {props.acciones.filter(x => !props.getButtonDisabled(x)).length > maxItems &&
            <>
              <div className={classes.grow} />
              <CButton
                key={'buttonPopUpMenu'}
                color='primary'
                variant="ghost"
                onClick={(event) => {
                  toogleMenu(open);
                  setAnchorEl(event.currentTarget);
                }}
              >
                <FontAwesomeIcon icon={faEllipsisV} size={isMobile && !isMobileOnly ? '3x' : '2x'} />
              </CButton>
              <Menu
                id='menuButtonsMenu'
                keepMounted
                open={open}
                anchorEl={anchorEl}
                onClose={() => {
                  toogleMenu(open);
                  setAnchorEl(null);
                }}
                TransitionComponent={Fade}

              >
                {props.acciones.filter(x => !props.getButtonDisabled(x)).map((m, index) => {
                  if (index >= maxItems) {
                    return (
                      <MenuItem  
                        key={m.nombre}
                        onClick={() => props.onClick(m)}
                      >
                        <FontAwesomeIcon icon={menuService.getIcon(m.icon) ?? faQuestionCircle} size={isMobile && !isMobileOnly ? '2x' : '1x'} className="dropDownMenuGlobalMenu"/>

                        {/* <ListItemIcon>
                          <FontAwesomeIcon icon={menuService.getIcon(m.icon) ?? faQuestionCircle} size={isMobile && !isMobileOnly ? '2x' : '1x'} />
                        </ListItemIcon> */}
                        {/* <Typography variant="inherit" noWrap>
                          {m.nombre}
                        </Typography> */}
                      </MenuItem >
                    );
                  }
                })
                }
              </Menu>
            </>
          }

          {/* <div className={classes.grow} /> */}
        </Toolbar>
      </AppBar>
    );
  }

  const botones = hideInactiveButtons ? acciones.filter(x => !props.getButtonDisabled(x)) : acciones;
  return (
    <div key='menuVentasBarra' className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ marginBottom: '5px', paddingTop: '-2rem' }}>
      <div key='menuVentasBarraBotones' className="btn-group-lg btn-group btn-sm mr-2" role="group" aria-label="First group" >
        {botones.map(m => {
          let disabled = (currentWorking !== undefined) || props.getButtonDisabled(m);

          //if (m.actionType === (currentWorking ?? '')) disabled = true;
          const iconStyle: any = { "--fa-secondary-opacity": disabled ? "0.10" : "0.8", "--fa-primary-color": "#321fdb", "--fa-secondary-color": '#4638c2' }
          const idBtn = m.nombre; //'x' + uuidv4();
          return (
            <React.Fragment key={idBtn}>
              {(m.nombre === "Imprimir" && printButtonAsDropdown) &&
                <CDropdown
                >
                  <CDropdownToggle disabled={disabled} style={{ borderRadius: '0px', height: '42px' }} className="btn btn-secondary md" >
                    {m.actionType !== (currentWorking ?? '') &&
                      <FontAwesomeIcon icon={menuService.getIcon(m.icon) ?? faQuestionCircle} size='sm' style={iconStyle} />
                    }
                    {m.actionType === (currentWorking ?? '') &&
                      <MenuSpinner animation='border'   >
                        <FontAwesomeIcon icon={menuService.getIcon(m.icon) ?? faQuestionCircle} size='sm' style={iconStyle} />
                      </MenuSpinner>
                    }
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem onClick={(e) => props.onClick({ ...m, actionType: ButtonTypes.print })} href="#">Imprimir</CDropdownItem>
                    <CDropdownItem onClick={(e) => props.onClick({ ...m, actionType: ButtonTypes.print_design })} href="#">Diseñar</CDropdownItem>
                  </CDropdownMenu>
                </CDropdown >
              }
              {(m.nombre !== "Imprimir" || !printButtonAsDropdown) &&
                <CButton
                  id={idBtn}
                  size='sm'
                  className="btn btn-secondary md"
                  disabled={disabled}
                  onClick={() => props.onClick(m)}
                  title={m.nombre}
                >
                  {m.actionType !== (currentWorking ?? '') &&
                    <FontAwesomeIcon icon={menuService.getIcon(m.icon) ?? faQuestionCircle} size='sm' style={iconStyle} />
                  }
                  {m.actionType === (currentWorking ?? '') &&
                    <MenuSpinner animation='border'   >
                      <FontAwesomeIcon icon={menuService.getIcon(m.icon) ?? faQuestionCircle} size='sm' style={iconStyle} />
                    </MenuSpinner>
                  }
                </CButton>
              }
              {props.showExportDesingOptions && m.nombre === "Exportar" && !disabled &&
                <ContextMenu
                  dataSource={[{ text: 'Visualizar', icon: 'dx-icon-download' },
                  { text: 'Diseñar', icon: 'dx-icon-add' },]}
                  width={200}
                  target={'#' + idBtn}
                  onItemClick={(e) => props.onClick({ ...m, actionType: e.itemData.text === "Visualizar" ? ButtonTypes.export : ButtonTypes.export_design })} />
              }
            </React.Fragment>
          );
        })
        }
      </div>
    </div>
  );
}
