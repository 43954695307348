import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../helpers/dateUtils";
import { RootState } from "../../../../../store/store";
import { FETCH_STATUS, TiposComprobantesSri } from "../../../../../store/types";
import { VentasService } from "../../../services/ventas.service";
import { SearchState } from "../../../types/generics";
import { Cliente, FiltroBusquedaState, VentaListado } from "../../../types/types";


const initialState: SearchState<VentaListado> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    cliente: null,
    activas: false,
    documento: '',
    formaPago: null,
    pendientesAbutorizar: false,
    establecimiento: null,
    estado: null,
    puntoEmision: null
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: []
}

export const fetchNotasDebito = createAsyncThunk<Array<VentaListado>, FiltroBusquedaState>(
  'ventas/searchNotasDebito',
  async (filtro) => {
    try {
      const notasDebito = await VentasService.getVentas(0, DateUtils.pickersDateToApiDate(filtro.fechaInicio) ?? "", DateUtils.pickersDateToApiDate(filtro.fechaFin) ?? "", filtro.puntoVenta,
        filtro.documento ?? undefined, filtro.estado ? filtro.estado.codigo.toString() : undefined, filtro.cliente?.identificacion, filtro.pendientesAbutorizar === undefined ? undefined : !filtro.pendientesAbutorizar,
        filtro.claveAcceso ?? "", filtro.formaPago ? filtro.formaPago.codigo.toString() : undefined, filtro.establecimiento ? filtro.establecimiento.establecimiento : undefined,
        filtro.puntoEmision ? filtro.puntoEmision.puntoEmision : undefined, TiposComprobantesSri.NotaDebito);
      return notasDebito;
    } catch (error) {
      return Promise.reject(error);
    }
  }

)


const searchNotasDebitoSlice = createSlice({
  name: 'searchNotasDebito',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      };
    },
    setCustomer(state, action: PayloadAction<Cliente | null>) {
      state.filter.cliente = action.payload;
    },
    setSalesPoint(state, action: PayloadAction<string>) {
      state.filter.puntoVenta = action.payload;
    },
    setDateStart(state, action: PayloadAction<string>) {
      state.filter.fechaInicio = action.payload;
    },
    setDateEnd(state, action: PayloadAction<string>) {
      state.filter.fechaFin = action.payload;
    },
    setInvoiceClaveAcceso(state, action: PayloadAction<string>) {
      state.filter.claveAcceso = action.payload;
    },
    setInvoiceNumber(state, action: PayloadAction<string>) {
      state.filter.documento = action.payload;
    },
    setPendientesAutorizar(state, action: PayloadAction<boolean>) {
      state.filter.pendientesAbutorizar = action.payload;
    },
    setCleanResult(state) {
      state.resultados = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotasDebito.pending, (state) => {
      if (state.status === FETCH_STATUS.IDDLE || state.status === FETCH_STATUS.SUCCESS) {
        state.status = FETCH_STATUS.LOADING;
        state.mensaje = '';
      }
    })
    builder.addCase(fetchNotasDebito.fulfilled, (state, { payload }) => {
      state.resultados = payload;
      state.status = FETCH_STATUS.SUCCESS;
    })
    builder.addCase(fetchNotasDebito.rejected, (state, { payload }) => {
      state.resultados = [];
      state.status = FETCH_STATUS.FAILED;
      state.mensaje = payload as string;
    })
  }
})

export const selectFiltroBusquedaNotasDebito = (state: RootState) => { return state.ventas.notasDebito.search.filter };
export const selectResultadosBusquedaNotasDebito = (state: RootState) => { return state.ventas.notasDebito.search.resultados };
export const selectBusquedaNotasDebitoEstado = (state: RootState) => { return state.ventas.notasDebito.search.status };
export const { setCustomer, setDateEnd, setDateStart,
  setInvoiceNumber, setPendientesAutorizar, setSalesPoint, resetState,
  setCleanResult, setInvoiceClaveAcceso } = searchNotasDebitoSlice.actions;
export const searchNotasDebitoReducer = searchNotasDebitoSlice.reducer;