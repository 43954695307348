var CryptoJS = require("crypto-js");
var key_code = "86758576376418652952657157128997";

function testHelper(date: string) {
    // your logic
    return date;
}

function encriptarAes(encriptarDatos: any) {
    var key = CryptoJS.enc.Utf8.parse(key_code);

    var iv = CryptoJS.enc.Utf8.parse("");
    var encrypted = CryptoJS.AES.encrypt(encriptarDatos, key, {
        iv: iv,
    });

    var codificado = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    return codificado;
}

function desencriptarAes(datoParaDesencriptar: any) {
    var key = CryptoJS.enc.Utf8.parse(key_code);
    var iv = CryptoJS.enc.Utf8.parse("");
    const plainText = CryptoJS.AES.decrypt(datoParaDesencriptar, key, {
        iv: iv,
    });
    var originalText = plainText.toString(CryptoJS.enc.Utf8);

    var str = originalText.split('"').join("");
    return str;
}

function encriptarJson(encriptarDatos: any) {
    var ciphertext = CryptoJS.AES.encrypt(encriptarDatos, key_code).toString();
    return ciphertext;
}

function desencriptarJson(encriptarDatos: any) {
    var bytes = CryptoJS.AES.decrypt(encriptarDatos, key_code);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
}

function getAuthJsonSelect() {
    let authSelect = sessionStorage.getItem("auth_selec");
    let encripted = authSelect;
    let setEncript = desencriptarJson(encripted);
    return setEncript;
}

function getAuthJson() {
    let authRes = sessionStorage.getItem("auth_res");
    let encripted = authRes;
    let setEncript = desencriptarJson(encripted);
    return setEncript;
}

function formatMoney(num: number, x = 2, s = ".", c = ",") {
    try {
        var p = num.toFixed(x).split(s);
        return (
            p[0]
                .split("")
                .reverse()
                .reduce(function (acc, num, i, orig) {
                    if ((num === "-") === false) {
                        return num === "-" ? acc : num + (i && !(i % 3) ? c : "") + acc;
                    } else {
                        return num + (i && !(i % 3) ? "" : "") + acc;
                    }
                }, "") +
            s +
            p[1]
        );
    } catch (e) {
        console.log(e);
    }
}

function dateFormat(fecha: any) {
    let dayInicio =
        fecha.getDate() < 10 ? "0" + fecha.getDate() : fecha.getDate();
    let monthInicio =
        fecha.getMonth() + 1 < 10
            ? "0" + (fecha.getMonth() + 1)
            : fecha.getMonth() + 1;
    let fechaFinal = dayInicio + "/" + monthInicio + "/" + fecha.getFullYear();
    return fechaFinal;
}
//  buscar en un objeto Objeto, Key, val
const findOnObject = (obj: any, key: any, val: any) => {
    let objects: any = [];
    for (let i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        if (typeof obj[i] === 'object') {
            objects = objects.concat(findOnObject(obj[i], key, val));
        } else
            //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
            if (i === key && obj[i] === val || i === key && val === '') { //
                objects.push(obj);
            } else if (obj[i] === val && key === '') {
                //only add if the object is not already in the array
                if (objects.lastIndexOf(obj) === -1) {
                    objects.push(obj);
                }
            }
    }
    return objects;
}

const generarColumnasTabla = (Columnas: any) => {
    let armar_objeto: any = [];
    let objetoColumnasVarios: any = [];

    Columnas.map((key) => {
        let toObject: any = {
            title: key,
            titleFooter:
                key.hasOwnProperty("titleFooter") === false
                    ? key
                    : key,
            id: key,
            numeric: true,
            disablePadding: true,
            label: key,
            name: key,
            filter: key,
            options: {
                filter: true,
                setCellHeaderProps: (value) => ({ style: { fontWeight: 800 } }),
            },
        };
        armar_objeto.push(toObject);
    });

    objetoColumnasVarios = Object.assign(objetoColumnasVarios, armar_objeto);

    return objetoColumnasVarios;
}

const allowedPageSizes = [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 250, 500];

const getAllowedPageSizes = (data: any) => {
    const tableSizes = allowedPageSizes.filter(tableSize => tableSize <= data.length);
    if(tableSizes[tableSizes.length-1] < data.length && data.length < allowedPageSizes[allowedPageSizes.length-1]){
      tableSizes.push(allowedPageSizes[tableSizes.length]);
    }
    return tableSizes;
  }

// Now you have to export each function you want
export {
    dateFormat,
    formatMoney,
    testHelper,
    desencriptarAes,
    encriptarAes,
    encriptarJson,
    desencriptarJson,
    getAuthJsonSelect,
    getAuthJson,
    findOnObject,
    generarColumnasTabla,
    getAllowedPageSizes
};
