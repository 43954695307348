//import { CButton, CCol, CDropdown, CDropdownItem, CDropdownToggle, CRow, CDropdownMenu } from '@coreui/react';
// import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
// import { Col } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import DropDownButton from 'devextreme-react/drop-down-button';
import Button from 'devextreme-react/button';
import { CDropdown, CDropdownToggle,CDropdownMenu,CDropdownItem,CCol,CRow } from '@coreui/react';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

export default function CustomSheetCommandViewer(props) {
  const { cell, row } = props

  const onButtonClick = useCallback((e) => {
    cell.commands[0].onExecute(row)
  }, [cell, row])


  const onItemClick = useCallback((e) => {
    console.log(e)
  }, [])

  if (!cell.commands) {
    return <div style={{ width: '100%', height: '100%' }} className='' ></div>
  }
  const element = document.querySelector("#modal");
  if (element === null) {
    console.error('No se encuentra #modal, debe declarar este elemento al mismo nivel que #root para que el menú se muestre por encima de los demás elementos')
  }
  if (cell.commands.length === 1) {
    const cmd = cell.commands[0];
    return (
      <div>
        <Button
          onClick={() => cmd.onExecute(row)}
          variant='ghost'
          color={cmd.color}
        >
          <FontAwesomeIcon size='1x' icon={cmd.icon} />
        </Button>
      </div>
    )
  }
  // return (
  //   <DropDownButton
  //     splitButton={true}
  //     useSelectMode={false}
  //     icon={cell.commands.length > 0 ? cell.commands[0].icon : ''}
  //     dropDownOptions={{ width: 100 }}
  //     items={cell.commands.map((cmd, i) => {
  //       return (
  //         {
  //           id: cmd.id,
  //           name: cmd.text,
  //           icon: cmd.icon
  //         })
  //     })}
  //     displayExpr="name"
  //     keyExpr="id"
  //     onButtonClick={onButtonClick}
  //     onItemClick={onItemClick}
  //   >

  //   </DropDownButton>
  // )
  return (
    <div className='' style={{ width: '100%', height: '100%' }}>
      <CDropdown className=""  >
        <CDropdownToggle size='sm' caret color="info" >
          <FontAwesomeIcon size='xs' icon={faPlus} />
        </CDropdownToggle>
        {
          ReactDOM.createPortal(
            <CDropdownMenu>
              {cell.commands.map(cmd => {
                return (
                  <CDropdownItem
                    key={`customSheetRowCommand${cmd.id}${row}`}
                    onClick={() => cmd.onExecute(row)}
                  >
                    <CRow gutters={true}>
                      <CCol xs='1'>
                        <FontAwesomeIcon size='1x' icon={cmd.icon} />
                      </CCol>
                      <CCol>
                        <span>{cmd.text}</span>
                      </CCol>
                    </CRow>
                  </CDropdownItem>
                )
              })

              }
            </CDropdownMenu>, element)
        }

      </CDropdown>
    </div>
  )
}