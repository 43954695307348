import { CSpinner } from '@coreui/react';
import * as React from 'react';
import './LoadingIndicator.scss'
import 'spinkit/spinkit.min.css'

interface ILoadingIndicatorProps {
  mensaje
}

const SmallLoadingIndicator: React.FunctionComponent<ILoadingIndicatorProps> = (props) => {
  return (
    <>
      <div className='container fluid'>
        <div className='row justify-content-center'>
          <CSpinner color="primary" size="md" />
        </div>
        <div className='row justify-content-center'>
          <strong>{props.mensaje ?? 'Cargando...'}</strong>
        </div>
      </div>
    </>
  );

};

const LoadingIndicator: React.FunctionComponent<ILoadingIndicatorProps> = (props) => {
  return (
    <>
      <div className='container fluid'>
        <div className='row justify-content-center'>
          {/*
          <div className='loader'>
          </div>
          */}


          <div className="sk-flow" >
            <div className="sk-flow-dot"></div>
            <div className="sk-flow-dot"></div>
            <div className="sk-flow-dot"></div>
          </div>
        </div>
        <div className='row justify-content-center' >
          {/*
          <strong className="textColor" >
            {props.mensaje ?? 'Cargando...'}
          </strong>
          */}
        </div>
      </div>
    </>
  );

};

export default LoadingIndicator;
export { SmallLoadingIndicator };