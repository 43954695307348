import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../../../helpers/dateUtils";
import { RootState } from "../../../../../../../store/store";
import { DateRange, FETCH_STATUS } from "../../../../../../../store/types";
import { CuadresService } from "../../../services/cuadres.service";
import { CuadresCajaListado, FiltroBusquedaCuadreCajaState, SearchCuadresState } from "../../../types/types";


const initialState: SearchCuadresState<CuadresCajaListado> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: []
}

export const fetchCuadresCaja = createAsyncThunk<Array<CuadresCajaListado>, FiltroBusquedaCuadreCajaState>(
  'cuadrecaja/all',
  async (filtro) => {
    try {
      const rangofechas: DateRange = {
        inicio: DateUtils.pickersDateToApiDate(filtro.fechaInicio),
        fin: DateUtils.pickersDateToApiDate(filtro.fechaFin)
      }
      const cuadresCaja = await CuadresService.getAllCuadresCaja(rangofechas,
        filtro.puntoVenta ? filtro.puntoVenta : "",
      );

      return cuadresCaja;
    } catch (error) {
      return Promise.reject(error);
    }
  }
)



const searchCuadresCajaSlice = createSlice({
  name: 'searchCuadreCaja',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      };
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaCuadreCajaState>) {
      state.filter = action.payload;
    },
    setSearchStatus(state, action: PayloadAction<FETCH_STATUS>) {
      state.status = action.payload;
    },
    setCleanResult(state) {
      state.resultados = [];
    },
    setDateStart(state, action: PayloadAction<string>) {
      state.filter.fechaInicio = action.payload;
    },
    setDateEnd(state, action: PayloadAction<string>) {
      state.filter.fechaFin = action.payload;
    },
    setResultFilter(state, action: PayloadAction<any>) {
      state.resultados = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCuadresCaja.pending, (state) => {
      if (state.status === FETCH_STATUS.IDDLE || state.status === FETCH_STATUS.SUCCESS) {
        state.status = FETCH_STATUS.LOADING;
        state.mensaje = '';
      }
    })
    builder.addCase(fetchCuadresCaja.fulfilled, (state, { payload }) => {
      state.resultados = payload;
      state.status = FETCH_STATUS.SUCCESS;
    })
    builder.addCase(fetchCuadresCaja.rejected, (state, { payload }) => {
      state.resultados = [];
      state.status = FETCH_STATUS.FAILED;
      state.mensaje = payload as string;
    })
  }
})


export const selectFiltroBusquedaCuadresCaja = (state: RootState) => { return state.ventas.cuadres.cuadresCaja.search.filter };
export const selectResultadosBusquedaCuadresCaja = (state: RootState) => { return state.ventas.cuadres.cuadresCaja.search.resultados };
export const selectBusquedaCuadresCajaEstado = (state: RootState) => { return state.ventas.cuadres.cuadresCaja.search.status };
export const {
  setDateEnd, setDateStart, setCleanResult,
  changeFilter, setSearchStatus, resetState,
  setResultFilter
} = searchCuadresCajaSlice.actions;
export const searchCuadresCajaReducer = searchCuadresCajaSlice.reducer;