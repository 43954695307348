import { CustomDictionary, EnumTabs, TabsStateComanda, TabStateComanda } from "../types/generico";

export const comandasClearDatosEdicion = (state, action) => {
  delete state[action.payload];
};

const getNewTabKeyOnClose = (
  state: TabsStateComanda<any>,
  keyToClose: string
) => {
  let newCurrent = "";
  for (const key in state.tabs) {
    if (newCurrent === "") {
      newCurrent = key;
    }
    if (keyToClose === key) {
      return newCurrent;
    }
  }
  return EnumTabs.TabSearch;
};

export const comandasCloseTab = (state, action) => {
  const newCurrent = getNewTabKeyOnClose(state, action.payload);
  state.current = newCurrent;
  delete state.tabs[action.payload];
};

export const existeTab = (
  tabs: CustomDictionary<TabStateComanda<any>>,
  clave: number
) => {
  for (const key in tabs) {
    const tab = tabs[key];
    if (tab.id === clave) return key;
  }
};