import React from 'react';
export default function SheetTextViewer(props) {
  const {  cell } = props

  return (
    <div onKeyDown={(e)=> console.log(e)} style={{ width: '100%', textAlign: 'left' }}>
      <small onKeyDown={(e)=> console.log(e)}>{cell.display}</small>
    </div>
  )
}
