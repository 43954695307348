import * as React from 'react';
import { useBarcode } from 'react-barcodes';

interface IBarcodeProps {
  text: string
}

const Barcode: React.FunctionComponent<IBarcodeProps> = (props) => {
  const { inputRef } = useBarcode({
    value: props.text === "" ? "vacio" : props.text,
    options: {
      width:1,
      background: "transparent",
      fontSize:10,
      height:30
    }
  });

  return <img ref={inputRef} alt={props.text} />;
};

export default Barcode;
