import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageState, Registros, Seleccionado, formularioBusqueda, CuentaContable } from "./types";
import { FilaPruebaNC } from "../components/tabladetalle/store/types"
import { AccionMenu, LoaderInfo } from "../../../../../store/types";
import { Asiento, AsientoApi } from "../../../store/types";

const initialState: PageState = {
  modulo: 'contabilidad',
  currentAction: "Buscar",
  currentFunction: "",
  aplicacion: 'diarioGeneral',
  acciones: [],
  registros: [],
  registrosApi: [],
  refrescaTabla: false,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  detalleAsiento: [],
  navegacion: "",
  asiento: {
    ASI_CODIGO: 0,
    ASI_DIARIONRO: "",
    NRO: 0,
    AS_TIPO: "MANUAL",
    AS_NROTIPO: 0,
    ASI_C_FECHA_AS: "",
    ASI_FECHA_MIN: "",
    ASI_NRODOC: "",
    ASI_FECHA_MAX: "",
    AS_C_INICIAL: false,
    AS_C_AJUSTE: false,
    ELIMINADOS: false,
    COD_PROYECTO: "-1",
    COD_LOCALCC: "-1",
    TIPO_TRANSACCION: 0,
    COD_CUENTA: "",
    NUM_CUENTA: "",
    DESC_CUENTA: "",
    VALOR: "",
    REF: "",
    AS_C_OBSERVACION: "",
    AS_C_COP_REFERENC: "",
    AS_C_TOT_DEBE: 0,
    AS_C_TOT_HABER: 0,
    AS_C_TOT_DESCUADRE: 0
  },
  asientoApi: null,
  formularioBusqueda: {
    ASI_DIARIONRO: 0,
    ASI_FECHA_D: "",
    ASI_FECHA_H: "",
    porhoja: false,
    idioma: false,
    numDiario: 0,
    numeroAsiento: 0,
    descripcionAsiento: "",
    fechaInicio: "",
    fechaCierre: "",
  },
  cargarCuentaContable: {
    codigo: 0,
    numero: "",
    descripcion: ""
  },
  numeroAsientoActual: 0,
  loader: {
    show: false,
    mensaje: ''
  },
}

const diarioSlice = createSlice({
  name: "diarioGeneral",
  initialState: initialState,
  reducers: {
    obtenerAcciones(state, action: PayloadAction<Array<AccionMenu>>) {
      state.acciones = action.payload
    },
    setData(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },
    setDataApi(state, action: PayloadAction<Array<any>>) {
      state.registrosApi = action.payload
    },
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      let toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      let toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setDetalleAsiento(state, action: PayloadAction<Array<FilaPruebaNC>>) {
      state.detalleAsiento = action.payload
    },
    setResetDetalleAsiento(state) {
      state.detalleAsiento = []
    },
    setNavegacion(state, action: PayloadAction<string>) {
      state.navegacion = action.payload
    },
    setAsiento(state, action: PayloadAction<Asiento>) {
      state.asiento = action.payload
    },

    setAsientoApi(state, action: PayloadAction<AsientoApi>) {
      state.asientoApi = action.payload
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      let toAdd = {
        ASI_DIARIONRO: action.payload.ASI_DIARIONRO,
        ASI_FECHA_D: action.payload.ASI_FECHA_D,
        ASI_FECHA_H: action.payload.ASI_FECHA_H,
        porhoja: action.payload.porhoja,
        idioma: action.payload.idioma,
        numDiario: action.payload.numDiario,
        descripcionAsiento: action.payload.descripcionAsiento,
        numeroAsiento: action.payload.numeroAsiento,
        fechaInicio: action.payload.fechaInicio,
        fechaCierre: action.payload.fechaCierre,
      }
      state.formularioBusqueda = toAdd
    },
    setCargarCuentaContable(state, action: PayloadAction<CuentaContable>) {
      let toAdd = {
        codigo: action.payload.codigo,
        numero: action.payload.numero,
        descripcion: action.payload.descripcion
      }
      state.cargarCuentaContable = toAdd
    },
    setNumeroAsientoActual(state, action: PayloadAction<number>) {
      state.numeroAsientoActual = action.payload
    },
    changeLoaderDiario(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  }
})

export const {
  setAsientoApi, setDataApi, changeLoaderDiario, setCargarCuentaContable, setCurrentFunction, setFormularioBusqueda, setAsiento,
  setNavegacion, setDetalleAsiento, obtenerAcciones, setData, setCurrentAccion,
  setSeleccionarDato, setResetSeleccion, setNumeroAsientoActual, setResetDetalleAsiento
} = diarioSlice.actions;
export const diarioReducer = diarioSlice.reducer;