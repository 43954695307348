import * as React from 'react';
import * as yup from 'yup'; // for everything
import { useFormik } from 'formik';
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../views/componentes/colContainer';
import { InputNumberWithLabel } from '../../../../../views/componentes/inputText/inputText';
import { CButton,  CModal, CModalBody, CModalHeader } from '@coreui/react';
import SubmitButton from '../../../../../views/componentes/submitButton/submitButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { utilidades } from '../../../../../helpers/utilidades';
import { GuiaDatosEdicionDetalle, ItemVenta } from '../../../types/types';
import CustomAutocompleteProducto from '../../ventas/venta/autocompleteItem';

interface IAgregarDetalleVentaMobileProps {
  rucCliente: string;
  onCancel: () => void;
  onOk: (detalle: GuiaDatosEdicionDetalle) => void;
}

type DatosDetalle = {
  item: ItemVenta | null,
  cantidad: number,
}

const detalleSchema = yup.object().shape<DatosDetalle>({
  item: yup.object<ItemVenta>().required('Debe seleccionar el producto.').nullable(),
  cantidad: yup.number().required().positive(),
});


const AgregarDetalleGuiaMobile: React.FunctionComponent<IAgregarDetalleVentaMobileProps> = (props) => {

  const formik = useFormik<DatosDetalle>({
    initialValues: {
      cantidad: 1,
      item: null
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: detalleSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (values.item) {
        await utilidades.sleep(1000);
        const detalle: GuiaDatosEdicionDetalle = {
          codigoBarras: values.item.codigoBarras,
          descripcionItem: values.item.descripcion,
          idItem: values.item.codigo,
          cantidadItem: values.cantidad
        }
        props.onOk(detalle);
      }

    },
  });

  return (
    <CModal
      show={true}
      size='sm'
      backdrop={true}
      closeOnBackdrop={false}
    >
      <CModalHeader>
        Agregar Detalle
      </CModalHeader>
      <CModalBody>
        <form onSubmit={formik.handleSubmit}>
          <RowContainer >
            <CustomCol xs='12'>
              <CustomAutocompleteProducto
                id='autoComplteProductosCodigo'
                rucCliente={props.rucCliente}
                tipoBusqueda={"codigo"}
                tieneError={formik.errors.item !== undefined}
                feedBack={formik.errors.item}
                value={formik.values.item}
                onBlur={formik.handleBlur}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue('item', value)
                    formik.setFieldValue('precio', value.pvp)
                    formik.setFieldValue('precioConIva', value.porcentajeIva > 0 ?
                      (value.pvp * (1 + (value.porcentajeIva / 100.0)))
                      :
                      value.pvp, true);
                  } else {
                    formik.setFieldValue('item', null)
                    formik.setFieldValue('precio', 0)
                    formik.setFieldValue('precioConIva', 0, true);
                  }
                }}
              />
            </CustomCol>
            <CustomCol xs='12'>
              <CustomAutocompleteProducto
                tipoBusqueda={"nombre"}
                id='autoComplteProductosDescripcion'
                rucCliente={props.rucCliente}
                tieneError={formik.errors.item !== undefined}
                feedBack={formik.errors.item}
                value={formik.values.item}
                onBlur={formik.handleBlur}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue('item', value, false)
                  } else {
                    formik.setFieldValue('item', null)
                  }
                  formik.validateForm();
                }}
              />
            </CustomCol>
            <CustomCol xs='4'>
              <InputNumberWithLabel
                name='cantidad'
                label='Cantidad'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.cantidad}
                tieneError={(formik.errors.cantidad !== undefined)}
                feedBack={formik.errors.cantidad}
                autoComplete='off'
              />
            </CustomCol>
          </RowContainer>

          <RowContainer>
            <CustomCol xs='12'>
              <div className='modal-footer' >
                <SubmitButton
                  isSubmitting={formik.isSubmitting}
                  text='Agregar'
                  size='md'
                  submittingText='Agregando'
                />
                <CButton
                  color='danger'
                  onClick={props.onCancel}
                  disabled={formik.isSubmitting}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  <span style={{ marginLeft: '5px' }} >Cancelar</span>
                </CButton>
              </div>
            </CustomCol>

          </RowContainer>

        </form>
      </CModalBody>
    </CModal>
  );
};

export default AgregarDetalleGuiaMobile;
