import * as React from 'react';
import './labeled.scss';

interface ILabeledInputProps {
  label: string,
  position?: 'left' | 'top'
}

const Labeled: React.FunctionComponent<ILabeledInputProps> = (props) => {
  const position = props.position ?? 'top';
  if(position === 'top'){
    return (
      <div className='labeledContainer '>
        <label className='labeledLabel form-label-sm'>{props.label}</label>
        <div className='labeledElements'>
          {props.children}
        </div>
      </div>
    )
  }
  return(
    <div className='labeledContainer'>
      <label  className='labeledLabel form-label-sm' style={{marginRight:'0.8rem'}}  >{props.label}</label>
      {props.children}
    </div>  
  )

  // return (
  //   <>
  //     <div style={{ width: '100%', marginRight: '5px', marginBottom: '5px', display: "inline-flex" }}>
  //       {props.label &&
  //         <div style={{ marginRight: '5px', width: 'auto', fontWeight: 'lighter', whiteSpace: 'nowrap' }}>
  //           <small>{props.label}</small>
  //         </div>
  //       }
  //       <div style={{ width: '100%' }}>
  //         {props.children}
  //       </div>
  //     </div>
  //   </>
  // );
};

export default Labeled;
