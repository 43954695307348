import { RequestHelper } from "../../../helpers/requestHelper";
import { SesionService } from "../../../services/sesion.service";
import {
  VentaDetalleInfo, VentaListado, GuiaInfo, FormaPagoModuloVentas, AutorizacionDocumentoVenta, ItemVenta,
  UnidadVenta, InfoDocumentosAutorizados, ItemventaDatosModificar,
  TipoIva,
  InfoDocumentoVentaAfectar,
  EstadoComprobante,
  EstablecimientoSri,
  PuntoEmisionSri,
  VentaInfo,
  GuiaDatosEdicionDetalle,
  Cliente,
} from "../types/types";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {
  TFacturaIngresar
} from '../pages/ventas/types/types'
import { PagedResult, PaginationInfo, TiposComprobantesSri } from "../../../store/types";
import { TNotaDebitoIngresar } from "../pages/notasDebito/types/types";
import { TNotaCreditoIngresar } from "../pages/notasCredito/types/types";
import { TGuiasRemisionIngresar } from "../pages/guiasRemision/types/types";
import { DateUtils, formatoFechasApi } from "../../../helpers/dateUtils";
import { utilidades } from "../../../helpers/utilidades";
import { RequestHelperDotNet } from "../../../helpers/requestHelperDotNet";
import { UrlHelper } from "../../../helpers/urlHelper";
import config from "../../../config/config";


export const VentasService = {
  getVentas,
  getVenta,
  getDetalleVenta,
  getFormasPago,
  getIva,
  getAutorizacion,
  getEstadisticasAutorizacion,
  buscarItem,
  getItemPorCodigoBarras,
  buscarItemCodigoBarras,
  getUnidadesventa,
  modificarItem,
  getTiposIva,
  ingresarVenta,
  obtenerRutasDocumentoElectornico,
  buscarItemCodYDescripcion,
  desactivarItem,
  getGuias,
  getDetallesGuia,
  buscarDocumentosAfectar,
  ingresarNotaCredito,
  ingresarNotaDebito,
  ingresarGuiaRemision,
  getEstadosComprobantes,
  getEstablecimientosSri,
  getEstablecimientosSriWithPuntosEmision,
  getPuntosEmisionSri,
  reenviarDocumento,
  reintentarAutorizacion,
  obtenerPdfFactura,
  getItemsDatasource,
  getItemsGridDatasource,
  anularDocumento
}


async function getVenta(codigo: number): Promise<VentaInfo | null> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    codigo: codigo
  }
  const apiData = RequestHelper.get<VentaInfo | null>('ventas', 'getInvoice', '', queryData);
  return apiData;
}


async function getVentas(codigo: number, fechaInicio: string, fechaFin: string, puntoVenta: string,
  numero: string | undefined, estadoFactura: string | undefined,
  identificacion: string | undefined, autorizado: boolean | undefined,
  clave_acceso: string | undefined, formaPago: string | undefined, establecimiento: string | undefined, puntoEmision: string | undefined, tipoDoc: TiposComprobantesSri): Promise<Array<VentaListado>> {
  const inicio = fechaInicio; //fechaInicio.length > 0 ? moment(fechaInicio, "yyyy-MM-DD").format("DD/MM/yyyy") : "";
  const fin = fechaFin; //fechaInicio.length > 0 ? moment(fechaFin, "yyyy-MM-DD").format("DD/MM/yyyy") : "";
  const sesion = SesionService.getCurrentSesion();
  const data: any = {
    ruc: sesion.empresa.ruc,
    clave_acceso: clave_acceso ?? '',
    tipodoc: tipoDoc,
    desde: inicio,
    hasta: fin,
    numero: numero ?? '',
    nombre: '',
    identificacion: identificacion ?? '',
    autorizado: autorizado ?? '',
    pventa: puntoVenta,
    codigo: codigo,
    usuario: sesion.usuario.codigo,
  };

  if (estadoFactura) {
    data.estadoFactura = estadoFactura;
  }
  if (formaPago) {
    data.formaPago = formaPago;
  }
  if (establecimiento) {
    data.establecimiento = establecimiento;
  }
  if (puntoEmision) {
    data.ptoemision = puntoEmision;
  }
  if (clave_acceso) {
    data.claveAcceso = clave_acceso;
  }

  const ventas = await RequestHelper.get<Array<VentaListado>>('ventas', 'listar', "", data);
  return ventas;
}


async function getGuias(claveAcceso: string, fechaInicio: string, fechaFin: string, activas: boolean, numero: string, codigo: number = 0): Promise<Array<GuiaInfo>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
  }
  let apiData: Array<any> = [];
  if (codigo) {
    queryData.codigo = codigo;
    const guia = await RequestHelper.get<Array<GuiaInfo>>('ventas', 'guiaremisiones/listarPorCodigo', '', queryData);
    if (guia) apiData.push(guia);
  } else {
    queryData.numero = numero;
    queryData.desde = fechaInicio;
    queryData.hasta = fechaFin;
    queryData.estado = activas ? "1" : "0";
    queryData.claveAcceso = claveAcceso ?? "";
    apiData = await RequestHelper.get<Array<GuiaInfo>>('ventas', 'guiaremisiones/listar', '', queryData);
  }

  return apiData;
}

async function getDetallesGuia(codigo: number): Promise<Array<GuiaDatosEdicionDetalle>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    codigo: codigo
  }
  var apiData = await RequestHelper.get<Array<any>>('ventas', 'remission_guide/get_details', '', queryData);
  var detalles: Array<GuiaDatosEdicionDetalle> = [];
  for (const detalleApi of apiData) {
    detalles.push({
      cantidadItem: parseFloat(detalleApi.GRD_CANTIDAD),
      codigoBarras: detalleApi.ITE_BARRAS,
      idItem: parseInt(detalleApi.ITE_CODIGO),
      descripcionItem: detalleApi.ITE_DESCRIPCION
    });
  }
  return detalles;
}

async function getDetalleVenta(codigo: number, tipoDocumento: TiposComprobantesSri) {
  const sesion = SesionService.getCurrentSesion();
  const data = {
    ruc: sesion.empresa.ruc,
    codigo: codigo,
    tipodoc: tipoDocumento
  };
  const apiDetalles = await RequestHelper.get<Array<VentaDetalleInfo>>('ventas',
    'ventas/getDetail', "", data);
  return apiDetalles;
}


async function getFormasPago(identificacion: string): Promise<Array<FormaPagoModuloVentas>> {
  const sesion = SesionService.getCurrentSesion();

  const data = {
    ruc: sesion.empresa.ruc,
    identificacion: identificacion
  };

  const cacheKey = 'formasPagoVenta' + JSON.stringify(data);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return JSON.parse(cached);
  } else {
    const apiData = await RequestHelper.postUrlEncoded<Array<any>>('formasdepago', 'venta', data);
    const formasPago: Array<FormaPagoModuloVentas> = [];
    for (const formaPagoApi of apiData) {
      formasPago.push({
        codigo: parseInt(formaPagoApi.codigo),
        descripcion: formaPagoApi.descripcion
      });

    }
    sessionStorage.setItem(cacheKey, JSON.stringify(formasPago));
    return formasPago;
  }

}


async function getIva(fecha: string, autorizacion: AutorizacionDocumentoVenta | null): Promise<number> {
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion();
  const sesion = SesionService.getCurrentSesion();

  let data: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    fecha: fecha, //DateUtils.getCurrentDateAsString(formatoFechasApi)
  };
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    data.establecimiento = sesionAutorizacionModulo.establecimiento;
  }
  const cacheKey = 'porcentajeIva' + JSON.stringify(data);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return parseFloat(cached);
  } else {
    const detalles = await RequestHelper.get<number>('ventas', 'ivaEstablecimiento', '', data);
    sessionStorage.setItem(cacheKey, detalles.toString());
    return detalles;
  }

}


async function getAutorizacion(puntoVenta: string, tipoComprobante: TiposComprobantesSri): Promise<AutorizacionDocumentoVenta> {
  const sesion = SesionService.getCurrentSesion();
  const data = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    comprobante_tipo: tipoComprobante,
    equipo: puntoVenta
  }
  const detalles = await RequestHelper.get<AutorizacionDocumentoVenta>('sidebar', 'autorizacion/extraer', '', data);
  return detalles;
}



async function getEstadisticasAutorizacion(tipoComprobante: TiposComprobantesSri): Promise<InfoDocumentosAutorizados> {
  const sesion = SesionService.getCurrentSesion();
  const data = {
    ruc: sesion.empresa.ruc,
    desde: '',
    hasta: '',
    tipodoc: tipoComprobante
  }
  const apiData = await RequestHelper.get<any>('ventas', 'noautorizados', "", data);
  const result: InfoDocumentosAutorizados = {
    comprobante: apiData.COMPROBANTE,
    totalEmitidos: apiData.TOTAL,
    autorizados: apiData.AUTORIZADOS,
    sinAutorizar: apiData.SINAUTORIZAR
  };
  return result;
}


function parseApiItemsData(apiresult: Array<any>): Array<ItemVenta> {
  const items = apiresult.map(x => {
    const item: ItemVenta = {
      codigo: parseInt(x.codigo),
      codigoInterno: x.codigoint,
      codigoBarras: x.barras,
      descripcion: x.descripcion,
      existencia: parseFloat(x.existencia),
      pvdiva: parseFloat(x.pvdiva),
      pvpiva: parseFloat(x.pvpiva),
      pvd: parseFloat(x.pvd),
      pvp: parseFloat(x.pvp),
      codigoImpuesto: parseInt(x.impuesto),
      tieneIce: (x.ice === '-1'),
      porcentajeIva: parseFloat(x.porcentajeiva),
      costo: parseFloat(x.costo),
      tipoCodigo: parseInt(x.tipo),
      numeroSerie: parseInt(x.numserie),
      plantilla: parseInt(x.plantilla),
      freeitem: parseInt(x.freeitem),
      gradoAcoholico: parseInt(x.galcoholico),
      capacidad: parseFloat(x.capacidad),
      factor: parseFloat(x.factor),
      jerarquia: x.jerarquia,
      descuentos: parseFloat(x.descuentos),
      titadicional: x.titadicional,
      valadicional: x.valadicional,
      grupo: x.grupo,
      codigoUnidadVenta: parseInt(x.unidadv),
      tipoDescripcion: x.tipo_descripcion,
      codigoGrupo: parseInt(x.codgrupo)
    };
    return item;
  });
  return items;
}

async function getItemPorCodigoBarras(codigoBarras: string, identificacionCliente: string, fecha: string): Promise<ItemVenta | undefined> {
  const sesion = SesionService.getCurrentSesion();
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion();

  let query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    local: sesion.local.codigo,
    identificacion: identificacionCliente,
    barras: codigoBarras,
    fecha: fecha,
    exacta: true,
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento;
  }
  const itemsApi = await RequestHelper.get<Array<any>>('inventario', 'items/searchItemsLocalSimpleSale', '', query);
  const items = parseApiItemsData(itemsApi);
  if (items.length > 0) {
    return items[0];
  } else {
    return undefined;
  }

}


function getItemsDatasource(cliente: Cliente | null, fecha: string = DateUtils.getCurrentDateAsString(formatoFechasApi)) {
  const store = new CustomStore({
    key: "barras",
    byKey: async (key) => {
      const item = await buscarItemCodigoBarras(key, cliente?.identificacion ?? config.rucConsumidorFinal, fecha);
      return item.length > 0 ? item[0] : undefined;
      //console.log(key);
    },
    load: async (loadOptions) => {
      console.log(loadOptions);
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      };
      let pagedResult: PagedResult<ItemVenta>;
      pagedResult = await buscarItemsPaged(
        loadOptions.searchValue ?? "", "", cliente?.identificacion ?? config.rucConsumidorFinal, fecha, paginInfo);
      console.log("current page ", pagedResult.currentPage);
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      };
    },
  });
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: "barras",
  });
  return source;
}

async function buscarItemsPaged(descripcion: string, codigoBarras: string, identificacionCliente: string, fecha: string, pagination: PaginationInfo): Promise<PagedResult<ItemVenta>> {
  const sesion = SesionService.getCurrentSesion();
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion();

  let query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    local: sesion.local.codigo,
    identificacion: identificacionCliente,
    descripcion: descripcion,
    barras: codigoBarras,
    fecha: fecha,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento;
  }
  const pagedApiResult = await RequestHelper.get<any>('inventario', 'items/searchItemsLocalSimpleSale', '', query);
  const itemsParsed = parseApiItemsData(pagedApiResult.auto);
  return {
    currentPage: parseInt(pagedApiResult.paginaActual),
    from: parseInt(pagedApiResult.desde),
    to: parseInt(pagedApiResult.hasta),
    count: parseInt(pagedApiResult.totalRegistros),
    result: itemsParsed,
  };
}

async function buscarItem(descripcion: string, codigoBarras: string, identificacionCliente: string, fecha: string): Promise<Array<ItemVenta>> {
  const sesion = SesionService.getCurrentSesion();
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion();
  let query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    local: sesion.local.codigo,
    identificacion: identificacionCliente,
    descripcion: descripcion,
    barras: codigoBarras,
    fecha: fecha
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento;
  }
  const itemsApi = await RequestHelper.get<Array<any>>('inventario', 'items/searchItemsLocalSimpleSale', '', query);
  const items = parseApiItemsData(itemsApi);
  return items;
}


async function buscarItemCodigoBarras(codigoBarras: string, identificacionCliente: string, fecha: string): Promise<Array<ItemVenta>> {
  const sesion = SesionService.getCurrentSesion();
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion();

  let query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    local: sesion.local.codigo,
    identificacion: identificacionCliente,
    barras: codigoBarras,
    fecha: fecha,
    exacta: false
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento;
  }
  const itemsApi = await RequestHelper.get<Array<any>>('inventario', 'items/searchItemsLocalSimpleSale', '', query);
  const items = parseApiItemsData(itemsApi);
  return items;
}

async function buscarItemCodYDescripcion(codigoBarras: string, descripcion: string, identificacionCliente: string, fecha: string): Promise<Array<ItemVenta>> {
  const sesion = SesionService.getCurrentSesion();
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion();

  let query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    local: sesion.local.codigo,
    identificacion: identificacionCliente,
    barras: codigoBarras,
    descripcion: descripcion,
    fecha: fecha,
    exacta: false
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento;
  }
  const itemsApi = await RequestHelper.get<Array<any>>('inventario', 'items/searchItemsLocalSimpleSale', '', query);
  const items = itemsApi.map(x => {
    const item: ItemVenta = {
      codigo: parseInt(x.codigo),
      codigoInterno: x.codigoint,
      codigoBarras: x.barras,
      descripcion: x.descripcion,
      existencia: parseFloat(x.existencia),
      pvdiva: parseFloat(x.pvdiva),
      pvpiva: parseFloat(x.pvpiva),
      pvd: parseFloat(x.pvd),
      pvp: parseFloat(x.pvp),
      codigoImpuesto: parseInt(x.impuesto),
      tieneIce: (x.ice === '-1'),
      porcentajeIva: parseFloat(x.porcentajeiva),
      costo: parseFloat(x.costo),
      tipoCodigo: parseInt(x.tipo),
      numeroSerie: parseInt(x.numserie),
      plantilla: parseInt(x.plantilla),
      freeitem: parseInt(x.freeitem),
      gradoAcoholico: parseInt(x.galcoholico),
      capacidad: parseFloat(x.capacidad),
      factor: parseFloat(x.factor),
      jerarquia: x.jerarquia,
      descuentos: parseFloat(x.descuentos),
      titadicional: x.titadicional,
      valadicional: x.valadicional,
      grupo: x.grupo,
      codigoUnidadVenta: parseInt(x.unidadv),
      tipoDescripcion: x.tipo_descripcion,
      codigoGrupo: parseInt(x.codgrupo)
    };
    return item;
  });
  return items;
}


async function getUnidadesventa(): Promise<Array<UnidadVenta>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    estado: 1
  }
  const cacheKey = 'unidadesVenta' + JSON.stringify(queryData);
  const savedData = sessionStorage.getItem(cacheKey) ?? null;
  if (savedData) {
    return JSON.parse(savedData);
  }
  const apiData = await RequestHelper.get<Array<UnidadVenta>>('inventario', 'unidades/listar', '', queryData)

  sessionStorage.setItem(cacheKey, JSON.stringify(apiData));
  return apiData;
}


async function modificarItem(item: ItemventaDatosModificar): Promise<ItemVenta> {
  const sesion = SesionService.getCurrentSesion();
  const data = {
    ruc: sesion.empresa.ruc,
    local: Number(sesion.local.codigo),
    usuario: sesion.usuario.codigo,
    barras: item.codigoBarras,
    nombre: item.descripcion,
    unidadv: Number(item.codigoUnidadVenta),
    linea: item.codigoLinea,
    grupo: Number(item.codigoGrupo),
    marca: item.codigoMarca,
    pvp: Number(item.pvp),
    pvd: Number(item.pvd),
    // iva: item.codigoImpuesto === 1 ? "NO SUJETO" : (item.codigoImpuesto === 2 ? 'NO' : "SI"),
    iva: item.codigoImpuesto === 3 ? "SI" : 'NO',
    tipo: item.tipoCodigo,
    codigo: item.codigo,
    tipoIce: item.iceTipoIce,
    codGradoAlcohol: item.iceCodigoGradoAlcoholico,
    gradoAlcolico: item.iceGradoAlcoholico,
    capacidadCmCubicos: item.iceCapacidaCm3,
    codigoImpuesto: item.iceCodigoImpuesto,
    codigoClasificacion: item.iceCodigoClasificacion,
    codigoMarca: item.iceCodigoMarca,
    estado: item.iceEstado,
    codigoPresentacion: item.iceCodigoPresentacion,
    codigoUnidad: item.iceCodigoUnidad,
    gramosAzucar: item.iceGramosAzucar,
    ice: item.ice
  }
  const apiData = await RequestHelper.post<any>("inventario", "items/save", data);
  return apiData;
}



async function getTiposIva(): Promise<Array<TipoIva>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
  }
  const apiData = await RequestHelper.get<Array<any>>("iva", "listar", "", queryData);
  const tiposIva = apiData.filter(x => x.IVA_TIPO !== -1).map(x => {
    const tipoIva: TipoIva = {
      codigo: x.IVA_TIPO,
      descripcion: x.IVA_DESCRIPCION,
      valor: parseFloat(x.IVA_VALOR)
    }
    return tipoIva;
  })
  return tiposIva;
}




async function ingresarVenta(venta: TFacturaIngresar): Promise<Promise<VentaListado>> {
  const creada = await RequestHelper.post<VentaListado>("ventas", "ingresar", venta);
  return creada;
}

async function ingresarNotaCredito(notaCredito: TNotaCreditoIngresar): Promise<VentaListado> {
  const creada = await RequestHelper.post<VentaListado>("ventas", "notacredito/ingresar", notaCredito);
  return creada;
}


async function ingresarNotaDebito(notaDebito: TNotaDebitoIngresar): Promise<VentaListado> {
  const creada = await RequestHelper.post<VentaListado>("ventas", "notadebito/ingresar", notaDebito);
  return creada;
}

async function ingresarGuiaRemision(guia: TGuiasRemisionIngresar): Promise<GuiaInfo> {
  const creada = await RequestHelper.post<GuiaInfo>("ventas", "remission_guide/insert", guia);
  return creada;
}


async function obtenerPdfFactura(claveAcceso: string): Promise<Blob> {
  try {
    const sesion = SesionService.getCurrentSesion();
    const queryData = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      claveacceso: claveAcceso
    }
    const blob = await RequestHelper.getFile('ventas', 'obtenerRide', '', queryData, { type: 'application/pdf' });
    return blob;
  } catch (error) {
    throw error;
  }

}

async function obtenerRutasDocumentoElectornico(claveAcceso: string): Promise<{ pdf: string, xml: string, url: string }> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    claveacceso: claveAcceso
  }
  const apiData = await RequestHelper.get<any>('ventas', "impresion", "", queryData);
  const pathapiPdf = `${apiData.pdf}`
  const pathapiXml = `${apiData.xml}`
  const pathapiPrint = `${apiData.url}`
  const path = {
    pdf: pathapiPdf,
    xml: pathapiXml,
    url: pathapiPrint
  }
  //const blob = await Axios.get(path, { headers } )
  return path;
}


async function desactivarItem(item: ItemVenta | number): Promise<boolean> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    codigo: (typeof item === 'number' ? item : item.codigo)
  }
  const deleteResult = await RequestHelper.deleteRequest<boolean>('inventario', 'items/inactive', '', queryData);
  return deleteResult;
}



async function buscarDocumentosAfectar(identificacion: string, nombres: string, comprobante: string, tipo: TiposComprobantesSri): Promise<Array<InfoDocumentoVentaAfectar>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    id: identificacion,
    numero: comprobante,
    tipo: tipo
  }
  const apiData = await RequestHelper.get<Array<any>>('ventas', 'comprobante/modificar', '', queryData);

  const result: Array<InfoDocumentoVentaAfectar> = [];
  for (const ventaApi of apiData) {
    const _venta: InfoDocumentoVentaAfectar = {
      codigo: parseInt(ventaApi.CODIGO),
      tipoComprobante: ventaApi.TIC_CODIGO as TiposComprobantesSri,
      tipoComprobanteNombre: ventaApi.TIC_COMPROBANTE,
      numero: ventaApi.NUMERO,
      clienteCodigo: parseInt(ventaApi.CLI_CODIGO),
      clienteIdentificacion: ventaApi.CLI_IDENTIFICACION,
      clienteNombre: ventaApi.CLI_NOMBRE,
      clienteDireccion: ventaApi.CLI_DIRECCION,
      clienteTelefono: ventaApi.CLI_TELEFONO,
      asociado: ventaApi.ASOCIADO,
      clienteEmail: ventaApi.CLI_EMAIL,
      subtotal0: parseFloat(ventaApi.VEN_SUBTOTAL0),
      subtotal12: parseFloat(ventaApi.VEN_SUBTOTAL12),
      subtotalNoIva: parseFloat(ventaApi.VEN_SUBTOTALNOIVA),
      descuento: parseFloat(ventaApi.VEN_DESCUENTO),
      descuentoGlobal: parseFloat(ventaApi.VEN_DESCUENTOG),
      iva: parseFloat(ventaApi.VEN_IVA),
      total: parseFloat(ventaApi.VEN_TOTAL),
      fecha: DateUtils.strDateToDate(ventaApi.VEN_FECHA, formatoFechasApi),
      formaPagoCodigo: ventaApi.FPAGO,
      formaPagoDescripcion: ventaApi.FPAGO_DES,
      puntoVenta: ventaApi.PTO_HOST
    };
    result.push(_venta);
  }
  return result;
}



async function getEstadosComprobantes(): Promise<Array<EstadoComprobante>> {
  if (utilidades.canUseNetApi()) {
    const apiData = await RequestHelperDotNet.get<Array<EstadoComprobante>>("EntityStatus", "All", "", null);
    return apiData;
  } else {
    const sesion = SesionService.getCurrentSesion();
    const queryData = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      mensaje: ''
    }

    const apiData = await RequestHelper.get<Array<any>>("ventas", "comprobanteestado/listar", '', queryData);
    const estados = apiData.map(data => {
      return {
        codigo: parseInt(data.EST_CODIGO),
        numero: parseInt(data.EST_NUMERO),
        descripcion: data.EST_DESCRIPCION
      }
    });
    return estados;
  }

}

async function getEstablecimientosSriWithPuntosEmision(): Promise<Array<EstablecimientoSri>> {
  const data = await RequestHelperDotNet.get<Array<EstablecimientoSri>>('SalesPoint', 'EstablecimientoSri', '', null);
  return data;
}

async function getEstablecimientosSri(): Promise<Array<EstablecimientoSri>> {
  const apiData = await RequestHelper.get<Array<string>>("ventas", "sri/establishments/getAll", '', null);
  const establecimientos = apiData.slice(1).map(x => { return { establecimiento: x, puntosEmision: [] } });
  return establecimientos;
}


async function getPuntosEmisionSri(): Promise<Array<PuntoEmisionSri>> {
  const apiData = await RequestHelper.get<Array<string>>("ventas", "sri/emissionPoint/getAll", '', null);
  const puntosEmision = apiData.slice(1).map(x => { return { puntoEmision: x, establecimiento: null } });
  return puntosEmision;
  // const sesion = SesionService.getCurrentSesion();
  // const queryData = {
  //   ruc: sesion.empresa.ruc,
  //   usuario: sesion.usuario.codigo,
  //   mensaje: ''
  // }
  // const apiData = await RequestHelper.get<Array<any>>("ventas", "puntoemisionsri/listar", '', queryData);
  // const estados = apiData.map(data => {
  //   return {
  //     puntoEmision: data.AUT_PTOEMISION,
  //     establecimiento: null
  //   }
  // });
  // return estados.filter(x => x.puntoEmision !== '---');;
}



async function reenviarDocumento(claveAcceso: string) {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    dirArchivo: sesion.empresa.pathArchivosPaginaWeb,
    claveAcceso: claveAcceso,
    emp_name: sesion.empresa.comercial,
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const apiData = await RequestHelper.get<Array<any>>("ventas", "voucher/send", '', queryData);
  return true;
}

async function reintentarAutorizacion(claveAcceso: string, tipoComprobante: TiposComprobantesSri) {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    local: sesion.local.codigo,
    claveAcceso,
    tipoDocumento: tipoComprobante
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const apiData = await RequestHelper.get<Array<any>>("generales", "xml/getWS", '', queryData);
  return true;
}


function getItemsGridDatasource(cliente: Cliente | null, fecha: string = DateUtils.getCurrentDateAsString(formatoFechasApi), findItem) {
  const store = new CustomStore({
    key: "codigoBarras",
    byKey: async (key) => {
      const item = await buscarItemCodigoBarras(key, cliente?.identificacion ?? config.rucConsumidorFinal, fecha);
      return item.length > 0 ? item[0] : undefined;
      //console.log(key);
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      };
      let pagedResult: PagedResult<ItemVenta>;

      pagedResult = await buscarItemsPaged(
        findItem["descripcion"], findItem["codigoBarras"], cliente?.identificacion ?? config.rucConsumidorFinal, fecha, paginInfo);

      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      };
    },
  });
  const source = new DataSource({
    totalCount: store.totalCount,
    paginate: true,
    pageSize: 10,
    store: store,
    key: "codigoBarras",
  });
  return source;
}



async function anularDocumento(info: VentaListado): Promise<boolean> {
  const sesion = SesionService.getCurrentSesion();
  const postData = {
    infoEmpresa: {
      ruc: sesion.empresa.ruc
    },
    infoUsuario: {
      usuario: sesion.usuario.idsesion
    },
    infoRegistro: {
      codigo: info.comprobante,
      fecha: info.fecha,
      tipodoc: info.tipoDocumentoCodigo,
      fechan: info.fechaNegociacion
    }
  }
  const result = await RequestHelper.deleteRequest<boolean>("ventas", "ventas/cancel", "", undefined, postData);
  //console.log(result);
  //await utilidades.sleep(2000);
  return result;
}



