import * as React from 'react';
import CustomCol from '../../../../views/componentes/colContainer';
import Labeled from '../../../../views/componentes/labeledInput/labeledInput';
import { CustomModalDevx } from '../../../../views/componentes/modal/Modal';
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer';
import { CompraFullInfo, CompraInfo } from '../../types/types';
import TextBox from 'devextreme-react/text-box';
import Validator, { PatternRule, RequiredRule } from 'devextreme-react/validator';
import DataGrid, { Column, HeaderFilter, Paging, Scrolling, TotalItem, Summary, Button as DatagridButton } from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import DateBox from 'devextreme-react/date-box';
import { isMobileOnly } from 'react-device-detect';
import { BuscarProveedorLookUp } from '../../../proveedores/components/busquedaProveedor/BuscarProveedorLookUp';
import { ProveedorBusqueda } from '../../../proveedores/store/types';
import { CompraService } from '../../services/compra.service';
import { Switch } from 'devextreme-react/switch';
import ValidationGroup from 'devextreme-react/validation-group';
import ValidationSummary from 'devextreme-react/validation-summary';
import { SelectBuscarTiposComprobantes } from '../../../shared/components/buscarTiposComporbantes/SelectTiposComprobantes';
import { DateRange, TipoComprobante } from '../../../../store/types';
import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils';
import VisualizaError from '../../../ventas/pages/shared/visualizaError/visualizaError';
import { ReportWithLocalData } from '../../../../views/componentes/xtrareports/ReportWithLocalData';
import { lh, MessagesKeys } from '../../../../helpers/localizationHelper';
import { RegistrarCompra } from './registrarCompra';
import { utilidades } from '../../../../helpers/utilidades';


interface IBusquedaComprasProps {
  onCancel: () => void,
  onSelected: (compra: CompraInfo) => void,
  show: boolean,
  tipoComprobante?: TipoComprobante,
}

export const BusquedaCompras: React.FC<IBusquedaComprasProps> = (props) => {
  const { show, onSelected, tipoComprobante } = props;
  const [proveedor, setProveedor] = React.useState<ProveedorBusqueda | null>(null);
  const [numero, setNumero] = React.useState('');
  const [buscando, setBuscando] = React.useState(false);
  const [resultados, setResultados] = React.useState<Array<CompraInfo>>([]);
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(new Date());
  const [buscarPorFechas, setBuscarPorFechas] = React.useState<boolean>(false);
  const [tipoComprobanteBusqueda, setTipoComprobanteBusqueda] = React.useState<TipoComprobante | null>(null);
  const validationGroupRef = React.useRef<any>();
  const [mensajeError, setMensajeError] = React.useState<string>('');
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);
  const [iniciarBusqueda, setIniciarBusqueda] = React.useState<boolean>(false);
  const [registrarCompra, setRegistrarCompra] = React.useState<boolean>(false);

  const getFilterString = React.useCallback(() => {
    let filterText = "";
    if (numero && numero.length > 0) {
      filterText = `Número: ${numero}`;
    } else {
      if (buscarPorFechas) {
        filterText = `Fechas: ${startDate} - ${endDate}`;
      }
      if (proveedor) {
        filterText = `${filterText} / Proveedor:${proveedor.nombre}`;
      }
      if (tipoComprobanteBusqueda) {
        filterText = `${filterText} / Tipo Comporbante:${tipoComprobanteBusqueda.comprobante}`;
      }
    }
    return filterText;
  }, [buscarPorFechas, startDate, endDate, tipoComprobanteBusqueda, proveedor, numero])

  const buscar = React.useCallback(async (range: DateRange | null, proveedor: ProveedorBusqueda | null, numero, tipoComprobante: TipoComprobante | null) => {
    try {
      setBuscando(true);
      const compras = await CompraService.buscarCompras(range, numero, (proveedor ? proveedor.identificacion : ''), (tipoComprobante ? tipoComprobante.codigo : ""), '', "");
      setResultados(compras);
    } catch (error) {
      setMensajeError(error);
      //console.log(error);
      setResultados([]);
    } finally {
      setBuscando(false);
    }

  }, []);



  const onBuscar = React.useCallback(async () => {
    const validationResult = validationGroupRef.current.instance.validate();
    if (!validationResult.isValid) {
      return;
    }

    let range: DateRange | null = null;
    if (buscarPorFechas) {
      range = {
        inicio: DateUtils.dateToString(startDate, formatoFechasApi),
        fin: DateUtils.dateToString(endDate, formatoFechasApi)
      }
    }
    await buscar(range, proveedor, numero, tipoComprobanteBusqueda);
  }, [buscar, numero, startDate, endDate, buscarPorFechas, tipoComprobanteBusqueda, proveedor]);



  const onRowDoubleClick = React.useCallback((e) => {
    const { data } = e;
    onSelected(data);
  }, [onSelected]);


  const onCompraAgregada = React.useCallback(async (codigo: number) => {
    setRegistrarCompra(false);
    setBuscando(true);
    await utilidades.sleep(500);
    const compra = await CompraService.getCompra(codigo);
    onSelected(compra);
  }, [onSelected]);

  const convertirFechaTransaccion = React.useCallback((data: CompraFullInfo) => {
    if (data) {
      return DateUtils.strDateToDate(data.fecha, formatoFechasApi);
    }
  }, []);

  const customizeSummaryText = React.useCallback((data) => {
    return data.value.toFixed(2);
  }, [])


  React.useEffect(() => {
    if (tipoComprobante !== undefined && tipoComprobante !== null) {
      setTipoComprobanteBusqueda(tipoComprobante);
    }
  }, [tipoComprobante])

  React.useEffect(() => {
    if (iniciarBusqueda) {
      setIniciarBusqueda(false);
      onBuscar().then(() => {
      }
      )
    }
  }, [iniciarBusqueda, onBuscar])


  if (!show) {
    return null;
  }



  return (
    <CustomModalDevx
      id="modalBusquedaCompras"
      size='md'
      show
      title='Búsqueda de documentos de compra'
      onClose={props.onCancel}
      toolbarItems={[
        {
          options: {
            text: 'Buscar',
            stylingMode: 'contained',
            type: 'default',
            onClick: () => setIniciarBusqueda(true)
          },
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'after'
        },
        {
          options: {
            text: lh.getMessage(MessagesKeys.GlobalNumeroRegistrosEncontrados, resultados.length.toString()),
            stylingMode: 'contained',
            type: 'default',
            onClick: () => setIniciarBusqueda(true)
          },
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'before'
        },
        {
          options: {
            text: 'Registrar Compra',
            stylingMode: 'contained',
            type: 'default',
            icon: 'add',
            onClick: () => setRegistrarCompra(true)
          },
          widget: 'dxButton',
          toolbar: 'top',
          location: 'after'
        }

      ]}
    >
      <fieldset>
        {registrarCompra &&
          <RegistrarCompra onOk={onCompraAgregada} onCancel={() => { setRegistrarCompra(false) }} />
        }
        {reporte &&
          <CustomModalDevx
            show={reporte !== null}
            onClose={() => setReporte(null)}
            title='Reporte'
            width={window.innerWidth - (window.innerWidth * 0.1)}
            height={window.innerHeight - (window.innerHeight * 0.1)}
          >
            <ReportWithLocalData
              data={resultados}
              fileName='StaticComprasListado'
              mode={reporte ?? 'Viewer'}
              parameters={{ 'Reporte_Filtro': getFilterString() }}
              template='StaticComprasListado'
              key='reportDesigner'
            >

            </ReportWithLocalData>
          </CustomModalDevx>
        }
        <ValidationGroup id={`valGroupBuscarcompras`} ref={validationGroupRef}>
          {mensajeError && mensajeError.length > 0 &&
            <VisualizaError titulo='Error al buscar compras' mensaje={mensajeError} onOk={() => {
              setMensajeError('');
            }} />
          }

          <div className=''>
            <RowContainer>
              <CustomCol xs='12' md='10' xl='6' >
                <Labeled
                  label='Proveedor'
                >
                  <BuscarProveedorLookUp
                    onChanged={(value) => { setProveedor(value); setIniciarBusqueda(true) }}
                    selected={proveedor}
                  />
                </Labeled>

              </CustomCol>
              <CustomCol
                xs='4' md='2' xl='2'
              >
                <Labeled
                  label='# de Comprobante'
                >
                  <TextBox
                    value={numero}
                    onValueChanged={({ value }) => {
                      setNumero(value);
                      setIniciarBusqueda(true);
                    }}
                    useMaskedValue={true}
                    inputAttr={{ 'autocomplete': 'nope' }}
                  >
                    <Validator>
                      <PatternRule message='El número de comprobante es incorrecto' pattern='^[0-9]{1,}$' />
                    </Validator>

                  </TextBox>
                </Labeled>
              </CustomCol>
              {(tipoComprobante === undefined || tipoComprobante === null) &&
                <CustomCol
                  xs='8' md='6' xl='4'
                >
                  <Labeled
                    label='Tipo Documento'
                  >
                    <SelectBuscarTiposComprobantes selected={tipoComprobanteBusqueda} onChanged={(value) => {
                      setTipoComprobanteBusqueda(value);
                      setIniciarBusqueda(true);
                    }} />
                  </Labeled>
                </CustomCol>
              }

            </RowContainer>
            <RowContainer gutters={false}>
              <CustomCol xs='4' lg='2' >
                <Labeled
                  label='Fechas'
                  position='left'
                >
                  <Switch switchedOffText='No' switchedOnText='Si' value={buscarPorFechas} onValueChanged={(e) => {
                    setBuscarPorFechas(e.value)
                    setIniciarBusqueda(true);
                  }} />
                </Labeled>
              </CustomCol>
              {buscarPorFechas &&
                <>
                  <CustomCol xs='4' lg='2' >
                    <DateBox applyValueMode="instantly"
                      name='fechaInicio'
                      value={startDate}
                      onValueChanged={(e) => {
                        if (e.value) {
                          setStartDate(e.value);
                        }

                      }} >
                      <RequiredRule message='Debe ingresar la fecha de inicio s' />
                    </DateBox>
                  </CustomCol>
                  <CustomCol xs='4' lg='2' >
                    <DateBox applyValueMode="instantly"
                      name='fechaFin'
                      value={endDate}
                      onValueChanged={(e) => {
                        if (e.value) {
                          setEndDate(e.value);
                          setIniciarBusqueda(true);
                        }

                      }} >
                      <RequiredRule />
                    </DateBox>
                  </CustomCol>
                </>
              }

            </RowContainer>
            <RowContainer>


            </RowContainer>
            <ValidationSummary />
            {/* <div style={{ display: 'flex', margin: '5px', justifyContent: 'flex-end' }}  >
              <Button text='Buscar' type='default' stylingMode='contained' disabled={buscando} onClick={onBuscarClick} />
              <Button text='Imprimir' type='default' stylingMode='contained' disabled={buscando} onClick={() => { setReporte('Viewer') }} />
              <Button text='Diseñar' type='default' stylingMode='contained' disabled={buscando} onClick={() => { setReporte('Designer') }} />
              <Button text='Exportar' type='default' stylingMode='contained' disabled={buscando} onClick={() => { setReporte('Viewer') }} />
            </div> */}
            <RowContainer>
              <CustomCol xs='12'>
                {/* <CAlert color="info">
                  Para selecionar una compra de doble click sobre la fila deseada.
                </CAlert> */}
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs='12'>
                <DataGrid
                  focusedRowEnabled={true}
                  keyExpr='codigo'
                  hoverStateEnabled={true}
                  dataSource={resultados}
                  showBorders={true}
                  onRowDblClick={onRowDoubleClick}
                  height={isMobileOnly ? `${window.innerHeight - 300}px` : `${(window.innerHeight / 2).toFixed(0)}px`}
                  width='100%'
                >
                  {/* <SearchPanel visible={true} highlightCaseSensitive={true} /> */}
                  <HeaderFilter visible={true} />
                  < Column type='buttons' width='40px' >
                    <DatagridButton text='+' icon='plus' hint='Selecionar' onClick={async (e) => {
                      const data: CompraFullInfo = e.row.data;
                      onSelected(data);
                    }} />
                  </Column>
                  <Column dataField='fecha' calculateCellValue={convertirFechaTransaccion} dataType='date' width='80px' allowSearch={false} />
                  <Column dataField='tipoDocumentoDescripcion' caption='Tipo Comprobante' width='100px' allowSearch={false} />
                  <Column dataField='establecimiento' alignment='right' caption='Estab.' width='50px' />
                  <Column dataField='puntoEmision' alignment='right' caption='Pto.' width='50px' />
                  <Column dataField='numero' caption='Número' width='90px' alignment='right' />
                  <Column dataField='proveedorNombre' caption='Proveedor' width='auto' minWidth='120px' />
                  <Column dataField='total' caption='Total' width='80px' alignment='right' allowSearch={false} />
                  <Column dataField='retencionCompraNumero' caption='# Ret' width='120px' alignment='right' allowSearch={false} />
                  <Column dataField='estadoDescripcion' caption='Estado' width='70px' allowSearch={false} />
                  <Column dataField='formaPagoDescripcion' caption='F. Pago' width='80px' allowSearch={false} />
                  <Paging enabled={false} />
                  <Scrolling mode='virtual' />
                  <Summary>
                    <TotalItem customizeText={customizeSummaryText} column='total' displayFormat="{0}" summaryType='sum' valueFormat='currency' alignment="right" />
                  </Summary>
                </DataGrid>
              </CustomCol>
            </RowContainer>
          </div>
        </ValidationGroup>
      </fieldset>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={'center'}
        visible={buscando}
        showIndicator={true}
        shading={true}
        showPane={true}
        message='Buscando...'
        closeOnOutsideClick={false}
      />
    </CustomModalDevx >
  );
};
