import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/store";
import { TiposComprobantesSri } from "../../../../../store/types";
import { ButtonTypes } from "../../../../../views/componentes/globalMenu/types";
import { InvoiceButtons } from "../../../../ventas/components/ventas/venta/venta";
import { StatesEdition, TabTypes } from "../../../../ventas/types/enums";
import { CustomButtons, CustomDictionary, TabsState, TabState } from "../../../../ventas/types/generics";
import { CompraButtons } from "../../../components/compras/compras/compras";
import { CompraInfo } from "../../../types/types";

const initialState: TabsState<CompraInfo> = {
  current: "BUSQUEDA",
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: "Búsqueda",
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: "BUSQUEDA",
      buttons: {
        Buscar: true,
        Nuevo: true,
      },
    }
  },
};

const existeTab = (
  tabs: CustomDictionary<TabState<any>>,
  comprobante: number
) => {
  for (const key in tabs) {
    const tab = tabs[key];
    if (tab.id === comprobante) return key;
  }
};

const getNewTabKeyOnClose = (
  state: TabsState<any>,
  keyToClose: string
) => {
  let newCurrent = "";
  for (const key in state.tabs) {
    if (newCurrent === "") {
      newCurrent = key;
    }
    if (keyToClose === key) {
      return newCurrent;
    }
  }
  return "BUSQUEDA";
};


const tabsComprasSlice = createSlice({
  name: "comprasTabs",
  initialState: initialState,
  reducers: {
    closeTab(state, action: PayloadAction<string>) {
      const newCurrent = getNewTabKeyOnClose(state, action.payload);
      state.current = newCurrent;
      delete state.tabs[action.payload];
    },
    openTabImport(state, action: PayloadAction<{ key: string }>) {
      const tab: any = {
        id: 1,
        canClose: true,
        editStatus: StatesEdition.doesNotApply,
        globalButtonClick: ButtonTypes.none,
        tittle: "Importar",
        type: TabTypes.import,
        imprimir: false,
        tabKey: "IMPORTAR",
        buttons: {
          Buscar: true,
          Importar: true,
          Guardar: true,
        },
      };
      state.tabs[action.payload.key] = tab;
      state.current = action.payload.key;
    },
    openTab(
      state,
      action: PayloadAction<{
        key: string;
        compra?: CompraInfo;
        templateMode?: boolean
      }>
    ) {
      if (action.payload.compra === undefined || action.payload.templateMode) {
        const tab: TabState<CompraInfo> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: "Nueva",
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            claveAcceso: "",
            numero: action.payload.templateMode && action.payload.compra ? action.payload.compra.codigo : 0,
            tipoComprobante: TiposComprobantesSri.Factura,
          },
          buttons: CompraButtons,
          templateMode: action.payload.templateMode
        };
        state.tabs[action.payload.key] = tab;
        state.current = action.payload.key;
      } else {
        const tabKey = existeTab(state.tabs, action.payload.compra.codigo);
        if (tabKey) {
          state.current = tabKey;
        } else {
          const tab: TabState<CompraInfo> = {
            id: action.payload.compra.codigo,
            tittle: `${action.payload.compra.establecimiento}-${action.payload.compra.puntoEmision}-${action.payload.compra.numero}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            tabKey: action.payload.key,
            info: {
              claveAcceso: action.payload.compra.claveAcceso,
              numero: action.payload.compra.codigo,
              tipoComprobante: TiposComprobantesSri.Factura,
              info: action.payload.compra,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: InvoiceButtons
          };
          state.tabs[action.payload.key] = tab;
          state.current = action.payload.key;
        }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string;
        estado: StatesEdition;
        info?: CompraInfo;
        buttons: CustomButtons
      }>
    ) {
      const { estado, tabKey, info, buttons } = action.payload;
      state.tabs[tabKey].editStatus = estado;
      state.tabs[tabKey].currentExecutingAction = undefined;
      state.tabs[tabKey].buttons = buttons;
      if (info) {
        state.tabs[tabKey].info = {
          claveAcceso: info.claveAcceso,
          numero: info.codigo,
          tipoComprobante: TiposComprobantesSri.Factura,
          info: info ?? null,
        };
        state.tabs[tabKey].id = info.codigo;
        state.tabs[tabKey].tittle = `${info.establecimiento}-${info.puntoEmision}-${info.numero}`;
      }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button;
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none;
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload;
      }

      //if (action.payload >= state.tabs.length) return;
    },
    setTabInfoAsInactive(state, action: PayloadAction<string>) {
      //if (action.payload >= state.tabs.length) return;
      const info = state.tabs[action.payload].info;
      if (info !== undefined) {
        if (info.info) {
          info.info.estadoDescripcion = "ANULADO";
          //info.info.estadoElectronico = "ANULADO";
        }
        state.tabs[action.payload].info = info;
      }
    },
    setButtons(state, action: PayloadAction<{ tabKey: string, buttons: CustomButtons }>) {
      const { buttons, tabKey } = action.payload;
      state.tabs[tabKey].buttons = buttons;
      state.tabs[tabKey].currentExecutingAction = undefined;
    },
    setCurrentExecutingAction(state, action: PayloadAction<{ tabKey: string, buttonType: ButtonTypes | undefined }>) {
      const { buttonType, tabKey } = action.payload;
      state.tabs[tabKey].currentExecutingAction = buttonType;
    }
  },
});

export const selectTabsCompras = (state: RootState) => {
  return state.compras.compras.tabs;
};
export const {
  openTabImport,
  closeTab,
  openTab,
  changeCurrentTab,
  changeEditStatus,
  clearButtonClick,
  buttonClick,
  setButtons,
  setCurrentExecutingAction,
  setTabInfoAsInactive
} = tabsComprasSlice.actions;
export const tabsReducer = tabsComprasSlice.reducer;
