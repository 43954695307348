import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo } from "../../../../../../store/types";
import { PageState, Registros, Seleccionado, formularioBusqueda } from "./types";

const initialState: PageState = {
    modulo: 'contabilidad',
    currentAction: "Buscar",
    currentFunction: "",
    aplicacion: 'estadoFlujosEfectivo',
    acciones: [],
    registros: [],
    registrosBackup: [],
    refrescaTabla: false,
    seleccionado: {
        index: null,
        row: null,
        selectedRow: null,
    },
    formularioBusqueda: {
        ASI_DIARIONRO: -1,
        INICIA_PERIODO: new Date().toDateString(),
        ASI_FECHA_D: new Date().toDateString(),
        ASI_FECHA_H: new Date().toDateString(),
    },
    loader: {
        show: false,
        mensaje: ''
    },
}

const estadoFlujosEfectivoSlice = createSlice({
    name: "estadoFlujosEfectivo",
    initialState: initialState,
    reducers: {
        obtenerAcciones(state, action: PayloadAction<any>) {
            state.acciones = action.payload
        },
        setData(state, action: PayloadAction<Array<Registros>>) {
            state.registros = action.payload
        },
        setDataBackup(state, action: PayloadAction<Array<Registros>>) {
            state.registrosBackup = action.payload
        },
        setCurrentAccion(state, action: PayloadAction<any>) {
            state.currentAction = action.payload
        },
        setCurrentFunction(state, action: PayloadAction<string>) {
            state.currentFunction = action.payload
        },
        setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
            let toAdd = {
                index: action.payload.index,
                row: action.payload.row,
                selectedRow: action.payload.selectedRow,
            }
            state.seleccionado = toAdd
        },
        setResetSeleccion(state) {
            let toAdd = {
                index: null,
                row: null,
                selectedRow: null,
            }
            state.seleccionado = toAdd
        },
        setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
            let toAdd = {
                ASI_DIARIONRO: action.payload.ASI_DIARIONRO,
                INICIA_PERIODO: action.payload.INICIA_PERIODO,
                ASI_FECHA_D: action.payload.ASI_FECHA_D,
                ASI_FECHA_H: action.payload.ASI_FECHA_H,
            }
            state.formularioBusqueda = toAdd
        },
        changeLoaderFlujo(state, action: PayloadAction<LoaderInfo>) {
            state.loader = action.payload
        },
    }
})

export const { changeLoaderFlujo, setCurrentFunction, obtenerAcciones, setData, setDataBackup, setCurrentAccion, setSeleccionarDato, setResetSeleccion, setFormularioBusqueda } = estadoFlujosEfectivoSlice.actions;
export const estadoFlujosEfectivoReducer = estadoFlujosEfectivoSlice.reducer;