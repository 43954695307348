import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ButtonTypes } from "../../../../views/componentes/globalMenu/types";
import { RootState } from "../../../../store/store";
import { StatesEdition, TabTypes } from "../../../ventas/types/enums";
import { InvoiceButtons } from "../../../ventas/components/ventas/venta/venta";
import { comandasCloseTab, existeTab } from '../store/reducers';
import { CustomButtonsComanda, EnumTabs, TabsStateComanda } from "../types/generico";

const initialState: TabsStateComanda<any> = {
  current: EnumTabs.TabSearch,
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: "Búsqueda",
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: EnumTabs.TabSearch,
      buttons: {
        Buscar: true,
        Nuevo: true,
        Exportar: true,
      },
    },
  },
};


const tabsComandasSlice = createSlice({
  name: "comandasTabs",
  initialState: initialState,
  reducers: {
    comandasCloseTab,
    openTab(
      state,
      action: PayloadAction<{ key: string; comanda?: any, templateMode?: boolean }>
    ) {
      if (action.payload.comanda === undefined || action.payload.templateMode) {
        const tab: any = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: "Nueva",
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            info: action.payload.comanda,
          },
          buttons: InvoiceButtons,
          templateMode: action.payload.templateMode
        };
        state.tabs[action.payload.key] = tab;
        state.current = action.payload.key;
      } else {
        const tabKey = existeTab(state.tabs, action.payload.comanda.codigo);
        if (tabKey) {
          state.current = tabKey;
        } else {
          const tab: any = {
            tabKey: action.payload.key,
            id: action.payload.comanda.codigo,
            tittle: `${action.payload.comanda.numero}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              info: action.payload.comanda,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: InvoiceButtons,
          };
          state.tabs[action.payload.key] = tab;
          state.current = action.payload.key;
        }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string;
        estado: StatesEdition;
        info?: any;
        buttons: CustomButtonsComanda
      }>
    ) {
      const { estado, tabKey, info, buttons } = action.payload;
      state.tabs[tabKey].editStatus = estado;
      state.tabs[tabKey].currentExecutingAction = undefined;
      state.tabs[tabKey].buttons = buttons;
      if (info) {
        state.tabs[tabKey].info = {
          numero: info.numero,
          info: info ?? null,
        };
        state.tabs[tabKey].id = info.codigo;
        state.tabs[
          action.payload.tabKey
        ].tittle = `${info.numero}`;
      }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button;
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none;
      }

    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload;
      }
    },
    setTabInfoAsInactive(state, action: PayloadAction<string>) {
      //if (action.payload >= state.tabs.length) return;
      const info = state.tabs[action.payload].info;
      if (info !== undefined) {
        if (info.info) {
          info.info.estado = "ANULADO";
          info.info.estadoElectronico = "ANULADO";
        }
        state.tabs[action.payload].info = info;
      }
    },
    setButtons(state, action: PayloadAction<{ tabKey: string, buttons: CustomButtonsComanda }>) {
      const { buttons, tabKey } = action.payload;
      state.tabs[tabKey].buttons = buttons;
      state.tabs[tabKey].currentExecutingAction = undefined;
    },
    setCurrentExecutingAction(state, action: PayloadAction<{ tabKey: string, buttonType: ButtonTypes | undefined }>) {
      const { buttonType, tabKey } = action.payload;
      state.tabs[tabKey].currentExecutingAction = buttonType;
    }
  },
});

export const selectTabsventas = (state: RootState) => {
  return state.ventas.ventas.tabs;
};
export const {
  comandasCloseTab: closeTab,
  openTab,
  changeCurrentTab,
  changeEditStatus,
  clearButtonClick,
  buttonClick,
  setTabInfoAsInactive,
  setButtons,
  setCurrentExecutingAction
} = tabsComandasSlice.actions;
export const tabsReducer = tabsComandasSlice.reducer;
