import { CBadge, CCol, CInput, CInputGroup, CInputGroupPrepend, CInputGroupText } from '@coreui/react'
import DateBox from 'devextreme-react/date-box'
import TextBox, { Button as TextBoxButton } from 'devextreme-react/text-box'
import ValidationGroup from 'devextreme-react/validation-group'
import React, { ReactElement, useCallback, } from 'react'
import BlockUi from 'react-block-ui'
import { useDispatch, useSelector } from 'react-redux'
import { DateUtils, formatoFechasApi, formatoFechasDatePickers } from '../../../../../helpers/dateUtils'
import { seleccionarSesion } from '../../../../../store/reducers'
import { RootState } from '../../../../../store/store'
import CustomCol from '../../../../../views/componentes/colContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import { BuscarProveedorLookUp } from '../../../../proveedores/components/busquedaProveedor/BuscarProveedorLookUp'
import { StatesEdition } from '../../../../ventas/types/enums'
import { CustomButtons, DocumentInfo, TabState } from '../../../../ventas/types/generics'
import { setDatosEdicion, setEditLoader, setFechaRetencion, updateProveedor, clearDatosEdicion, updateDetalles, setNumeroRetencion, updateResultadoGuardarRetencion, setMuestraError, setFormaPago, setImprimir, updateDetalle } from '../../../pages/retenciones/store/editDataReducer'
import { CompraInfo, RetencionCompraIngresar, RetencionCompraIngresarImpuesto, RetencionDatosEdicion, RetencionInfo } from '../../../types/types'
import { BusquedaCompras } from '../../busquedaCompras/busquedaCompras'
import {
  Validator,
  RequiredRule,
  RangeRule,
  AsyncRule,
} from 'devextreme-react/validator';
import ValidationSummary from 'devextreme-react/validation-summary';
import { SelectBox as DevExSelectBox } from 'devextreme-react/select-box';
import { lh, MessagesKeys } from '../../../../../helpers/localizationHelper'
import SelectBox from '../../../../../views/componentes/selectBox/selectBox'
import DataGrid, { Column, Editing, Paging, Scrolling, Lookup, RequiredRule as GridRequiredRule } from 'devextreme-react/data-grid';
import { isMobile, isMobileOnly } from 'react-device-detect'
import { CompraService } from '../../../services/compra.service'
import { ProveedoresService } from '../../../../proveedores/services/proveedores.service'
import { addToast } from '../../../../../store/toasterReducer'
import { TipoComprobante, TiposComprobantesSri, ToastTypes } from '../../../../../store/types'
import { changeEditStatus, clearButtonClick, closeTab, setButtons, setCurrentExecutingAction } from '../../../pages/retenciones/store/tabsReducer'
import { ConceptoRetencion, DetalleRetencionRedux, TiposPorcentajeRetencion, TiposRetencion } from '../../../pages/retenciones/types/types'
import applyChanges from 'devextreme/data/apply_changes';
import { differenceBy, } from 'lodash';
import NumberBox from 'devextreme-react/number-box'
import { RetencionCompraService } from '../../../services/retencionCompra.service'
import Barcode from '../../../../../views/componentes/barcode/barcode'
import { VerRIDE } from '../../../../ventas/components/verRide/verRide'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types'
import { RetencionesService } from '../../../../ventas/services/retenciones.service'
import { VentasService } from '../../../../ventas/services/ventas.service'
import PopupReenvioMail from '../../../../ventas/components/ventas/busquedaVentas/popupReenvioMail/index'
import { utilidades } from '../../../../../helpers/utilidades'
import VisualizaError from '../../../../ventas/pages/shared/visualizaError/visualizaError'
import { v4 as uuidv4 } from 'uuid';
import { AnularRetencioCompra } from './anularRetencion'

interface IRetencionProps {
  info: DocumentInfo<RetencionInfo>,
  tabId: string,
  tab: TabState<RetencionInfo>
}
const tipoComporbanteBusqueda: TipoComprobante = {
  archivo: null,
  codigo: "01",
  comprobante: "FACTURA",
  descripcionEstado: "ACTIVO",
  estado: 1,
  ipImpresora: null,
  limite: null,
  nombreImpresora: null,
  puertoImpresora: null

}

export default function Retencion(props: IRetencionProps): ReactElement {
  const { info, tabId, tab } = props;
  const dispatch = useDispatch();
  const sesion = useSelector(seleccionarSesion);
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  // const usuario = useSelector((state: RootState) => { return state.global.session?.usuario });
  // const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  // const local = useSelector((state: RootState) => { return state.global.session?.local });
  //const currentTab = useSelector((state: RootState) => { return state.compras.retenciones.tabs.tabs[tabIndex] });
  const datosEdicion = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId] });
  const puntoVenta = useSelector((state: RootState) => { return state.global.puntoVenta });
  const establecimiento = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].establecimiento });
  const puntoEmision = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].puntoEmision });
  const fechaEmision = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].fechaEmision });
  const proveedor = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].proveedor });
  const autorizacion = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].autorizacion });
  const fechaAutorizacion = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].fechaAutorizacion });
  const detalles = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].detalles });
  const loader = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].loader });
  const error = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].error });
  const baseIva = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].baseIva });
  const baseRenta = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].baseRenta });
  const imprimir = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].imprimir });
  // const codigo = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].codigo });
  const formasPago = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].formasPago });
  const loading = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].loading });
  const numero = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].numero });
  // const documentoOrigen = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].documentoOrigen });
  const compra = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].compra });
  // const error = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].error });
  const formaPago = useSelector((state: RootState) => { return state.compras.retenciones.editData[tabId].formaPago });

  const [mostrarSeleccionDocumentos, setMostrarSeleccionDocumentos] = React.useState(false);
  const [conceptosRetencion, setConceptosRetencion] = React.useState<Array<ConceptoRetencion>>([]);
  const validationGroupRef = React.useRef<any>();
  const [changes, setChanges] = React.useState([]);
  const [editRowKey, seteditRowKey] = React.useState<string | null>(null);
  const [verFacturaPdf, setVerFacturaPdf] = React.useState<{ claveAcceso: string, design: boolean }>();

  const [showReenviarMail, setShowReenviarMail] = React.useState<boolean>(false);

  const [mostrarAnular, setMostrarAnular] = React.useState(false);

  const showEditLoader = React.useCallback((mensaje: string) => {
    dispatch(setEditLoader({
      key: tabId,
      info: {
        mensaje: mensaje,
        show: true
      }
    }));
  }, [dispatch, tabId])


  const hideEditLoader = React.useCallback(() => {
    dispatch(setEditLoader({
      key: tabId,
      info: {
        mensaje: '',
        show: false
      }
    }));
  }, [dispatch, tabId])

  const onDocumentSelected = React.useCallback(async (compra: CompraInfo) => {

    if (compra.retencionCompraNumero && compra.retencionCompraNumero.length > 0) {
      dispatch(setMuestraError({
        key: tabId,
        error: `La factura de compra ${compra.establecimiento}-${compra.puntoEmision}-${compra.numero} ya está asociada a la retención ${compra.retencionCompraNumero}!!!`
      }));
      return;
    }

    showEditLoader('Cargando...');
    setMostrarSeleccionDocumentos(false);

    const factura = await CompraService.getCompra(compra.codigo);
    if (factura) {

      const proveedor = await ProveedoresService.getProveedor(compra.proveedorIdentificacion);
      const formasPago = await CompraService.getFormasPago();
      const autorizacion = await VentasService.getAutorizacion(puntoVenta ?? '', TiposComprobantesSri.Retencion);
      const numActual = autorizacion.numActual.toString();

      const datosEdicion: RetencionDatosEdicion = {
        codigo: 0,
        numero: `${autorizacion.establecimiento}-${autorizacion.ptoemision}-${"0".repeat(9 - numActual.length)}${numActual}`,
        baseIva: factura.IVA,
        baseRenta: factura.subtotal,
        proveedor: proveedor,
        establecimiento: autorizacion.establecimiento,
        puntoEmision: autorizacion.ptoemision,
        detalles: [],
        fechaEmision: DateUtils.getCurrentDateAsString(),
        loader: {
          mensaje: '',
          show: false
        },
        documentoOrigen: {
          comprobante: factura.codigo,
          fechaEmision: factura.fecha,
          formaPagoCodigo: factura.formaPagoCodigo,
          numero: factura.numero,
          tipoDoc: "01",
          totalDocumento: factura.total
        },
        compra: compra,
        formaPago: formasPago.find(x => x.codigo === factura.formaPagoCodigo),
        formasPago: formasPago,
        loading: false,
        autorizacion: '',
        fechaAutorizacion: '',
        imprimir: false,
        tieneErroresValidar: false
      }
      dispatch(setDatosEdicion({ key: tabId, data: datosEdicion }))
      hideEditLoader();
    }
  }, [tabId, puntoVenta, showEditLoader, hideEditLoader, dispatch]);

  const onCancelDocummentSelection = React.useCallback(() => {
    setMostrarSeleccionDocumentos(false);
    dispatch(addToast({
      content: 'Debe seleccionar un documento para proceder con la retención',
      type: ToastTypes.Warning,
      autoHide: 3000
    }));
    dispatch(closeTab(tabId));
    dispatch(clearDatosEdicion(tabId));
  }, [dispatch, tabId]);


  const validateRetencion = React.useCallback(() => {
    return detalles.length === 0 ? Promise.reject(lh.getMessage(MessagesKeys.RetencionSinDetalles)) : Promise.resolve();
  }, [detalles]);

  const validateRetencionFecha = React.useCallback(() => {
    const fechaCompra = DateUtils.strDateToDate(compra?.fecha ?? "", formatoFechasApi);
    const fecha = DateUtils.strDateToDate(fechaEmision, formatoFechasDatePickers);
    return fecha >= fechaCompra ? Promise.resolve() : Promise.reject();
  }, [fechaEmision, compra]);

  const onInitNewRow = React.useCallback(({ data }) => {
    data.anioFiscal = DateUtils.strDateToDate(fechaEmision).getFullYear();
    data.baseImponible = 0;
    data.valor = 0;
    data.fecha = fechaEmision;
    data.id = uuidv4();
  }, [fechaEmision])

  const onEditorPreparing = React.useCallback((e) => {
    // if (e.parentType === 'dataRow' && ( e.dataField === 'codigo' || e.dataField === 'impuesto' ) ) {
    //   e.editorOptions.disabled = (e.row.data.tipo === undefined || e.row.data.tipo == null);
    // }
  }, []);


  const save = useCallback((e) => {
    const change = e.changes[0];
    if (change.type === "insert") {
      if (change.data) {

      }
      const _detalles = detalles.slice(0);
      //change.data.id = uuidv4();
      _detalles.push(change.data);
      dispatch(updateDetalles({
        key: tabId,
        detalles: _detalles
      }))
    } else if (change.type === 'remove') {
      const _detalles = detalles.slice(0);
      const newData = applyChanges(_detalles, [change], { keyExpr: 'id' });
      dispatch(updateDetalles({
        key: tabId,
        detalles: newData
      }))
    } else if (change.type === 'update') {
      dispatch(updateDetalle({
        key: tabId,
        updated: change.data,
        id: change.key
      }))
    }
    setChanges([]);
    seteditRowKey(null);
    //return Promise.resolve();
  }, [dispatch, detalles, tabId])

  const onSaving = React.useCallback((e) => {
    e.cancel = true;
    e.promise = save(e);
    //e.promise = saveChange(dispatch, e.changes[0]);
  }, [save]);


  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, [detalles, tabId])

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    seteditRowKey(editRowKey);
  }, [])

  const onRowRemoved = React.useCallback((editRowKey) => {
    console.log("onRowRemoved");
    console.log(editRowKey);
  }, [])

  const onRowRemoving = React.useCallback((editRowKey) => {
    console.log("onRowRemoving");
    console.log(editRowKey);
  }, [])

  const validateTipoRetencion = React.useCallback((params) => {
    const { data, } = params;
    if (!data.tipo) {
      return Promise.reject(lh.getMessage(MessagesKeys.RetencionDetalleTipoNoSeleccionado));
    }
    return Promise.resolve();
  }, []);

  const onLookupCellChanged = React.useCallback((rowData, value, currentRowData) => {
    if (currentRowData.concepto) { //edicion
      const _baseRenta = parseFloat((baseRenta - detalles.reduce((prev, next) => prev + (next.tipo === TiposRetencion.Fuente ? next.baseImponible : 0), 0)).toFixed(2)) + (currentRowData.concepto.tipo === "Fuente" ? (currentRowData.baseImponible ?? 0) : 0);
      const _baseIva = parseFloat((baseIva - detalles.reduce((prev, next) => prev + (next.tipo === TiposRetencion.IVA ? next.baseImponible : 0), 0)).toFixed(2)) + (currentRowData.concepto.tipo === "Iva" ? (currentRowData.baseImponible ?? 0) : 0);
      if (value) {
        const concepto = conceptosRetencion.find(x => x.codigo === value) ?? conceptosRetencion[0];

        rowData.concepto = concepto;
        rowData.conceptoCodigo = value;
        rowData.tipo = concepto.tipo;
        rowData.porcentaje = concepto.porcentaje
        rowData.baseImponible = concepto.tipo === TiposRetencion.Fuente ? _baseRenta : _baseIva
        rowData.valor = concepto.tipo === TiposRetencion.Fuente ? (_baseRenta * (concepto.porcentaje / 100)) : (_baseIva * (concepto.porcentaje / 100))
        rowData.valor = parseFloat(rowData.valor.toFixed(2))
      } else {
        rowData.concepto = null;
        rowData.conceptoCodigo = value;
        rowData.porcentaje = 0;
        rowData.baseImponible = 0;
        rowData.valor = 0;
      }
    } else {
      const _baseRenta = parseFloat((baseRenta - detalles.reduce((prev, next) => prev + (next.tipo === TiposRetencion.Fuente ? next.baseImponible : 0), 0)).toFixed(2));
      const _baseIva = parseFloat((baseIva - detalles.reduce((prev, next) => prev + (next.tipo === TiposRetencion.IVA ? next.baseImponible : 0), 0)).toFixed(2));
      if (value) {
        const concepto = conceptosRetencion.find(x => x.codigo === value) ?? conceptosRetencion[0];

        rowData.concepto = concepto;
        rowData.conceptoCodigo = value;
        rowData.tipo = concepto.tipo;
        rowData.porcentaje = concepto.porcentaje
        rowData.baseImponible = concepto.tipo === TiposRetencion.Fuente ? _baseRenta : _baseIva
        rowData.valor = concepto.tipo === TiposRetencion.Fuente ? (_baseRenta * (concepto.porcentaje / 100)) : (_baseIva * (concepto.porcentaje / 100))
        rowData.valor = parseFloat(rowData.valor.toFixed(2))
      } else {
        rowData.concepto = null;
        rowData.conceptoCodigo = value;
        rowData.porcentaje = 0;
        rowData.baseImponible = 0;
        rowData.valor = 0;
      }
    }


  }, [conceptosRetencion, baseIva, baseRenta, detalles]);

  const getRetencionCodesByCode = React.useCallback((options) => {
    //recuperar el listado de conceptos ya elegidos
    let selected = options.data ? detalles.map(x => x.concepto) : [];
    if (options.data && options.data.concepto) {
      if (options.isEditing) selected = selected.filter(x => x?.codigo !== options.data.concepto.codigo);
      selected = differenceBy(conceptosRetencion, options.isEditing ? selected : [options.data.concepto], 'codigo')
      return {
        store: selected,
        filter: options.data ? (options.data.tipo ? ['tipo', '=', options.data.tipo] : null) : null
      };
    } else {
      return {
        store: differenceBy(conceptosRetencion, selected, 'codigo'),
        filter: options.data ? (options.data.tipo ? ['tipo', '=', options.data.tipo] : null) : null
      };
    }
    //      store: differenceBy(conceptosRetencion, selected, 'codigo'),

  }, [conceptosRetencion, detalles]);

  const onBaseImponibleChanged = React.useCallback((newData, value, currentRowData) => {

    if (value) {
      const _value = parseFloat(value);
      newData.baseImponible = _value;
      newData.valor = currentRowData.tipo ? _value * (currentRowData.porcentaje / 100) : 0;
      newData.valor = parseFloat(newData.valor.toFixed(2))
    } else {
      newData.baseImponible = 0;
      newData.valor = 0;
    }
  }, []);

  const validateBaseImponible = React.useCallback((params) => {
    const { data, value } = params;
    if (data.tipo && data.tipo === TiposRetencion.Fuente) {
      const _baseRenta = parseFloat((baseRenta - detalles.filter(x => x.id !== (data.id ?? '')).reduce((prev, next) => prev + (next.tipo === TiposRetencion.Fuente ? next.baseImponible : 0), 0)).toFixed(2));
      return value > _baseRenta ?
        Promise.reject(lh.getMessage(MessagesKeys.RetencionDetalleBaseImponibleMayor, _baseRenta.toFixed(2))) :
        Promise.resolve();
    } else if (data.tipo && data.tipo === TiposRetencion.IVA) {
      const _baseIva = parseFloat((baseIva - detalles.reduce((prev, next) => prev + (next.tipo === TiposRetencion.IVA ? next.baseImponible : 0), 0)).toFixed(2));
      return value > _baseIva ? Promise.reject(lh.getMessage(MessagesKeys.RetencionDetalleBaseImponibleMayor, _baseIva.toFixed(2))) :
        Promise.resolve();
    } else {
      if (value === 0) {
        return Promise.reject(lh.getMessage(MessagesKeys.RetencionDetalleBaseImponibleCero))
      }
      return Promise.resolve();
    }
  }, [baseIva, baseRenta, detalles]);

  const porcentajeEditrRender = (cell) => {
    const { data } = cell;
    if (data.tipo && data.concepto) {
      const concepto: ConceptoRetencion = data.concepto;
      if (concepto.tipoPorcentaje === TiposPorcentajeRetencion.Unico) {
        return <NumberBox defaultValue={cell.value} onValueChanged={(e) => cell.setValue(e.value)} readOnly />
      } else if (concepto.tipoPorcentaje === TiposPorcentajeRetencion.Limite) {
        return <NumberBox defaultValue={cell.value} onValueChanged={(e) => cell.setValue(e.value)} min={concepto.rango?.inferior} max={concepto.rango?.superior} showSpinButtons />
      }
      else if (concepto.tipoPorcentaje === TiposPorcentajeRetencion.Opciones) {
        return <DevExSelectBox
          defaultValue={cell.value}
          items={concepto.opciones ?? []}
          searchEnabled
          searchMode={'startswith'}
          onValueChanged={(e) => cell.setValue(e.value)}
        />;
      }
    } else {
      return <NumberBox defaultValue={cell.value} onValueChanged={(e) => cell.setValue(e.value)} />
    }

  }

  const onPorcentajeRetenidoChanged = React.useCallback((newData, value, currentRowData) => {
    if (value) {
      const _value = parseFloat(value);
      const baseImponible = parseFloat(currentRowData.baseImponible);
      newData.porcentaje = _value;
      newData.valor = currentRowData.tipo ? baseImponible * (value / 100) : 0;
      newData.valor = parseFloat(newData.valor.toFixed(2))
    } else {
      //newData.baseImponible = 0;
      newData.valor = 0;
      newData.porcentaje = 0;
    }
  }, []);


  const validate = useCallback(async () => {
    let resolved = false;
    let status = false;
    const p = new Promise(async (resolve) => {
      while (!resolved) {
        await utilidades.sleep(50);
      }
      resolve(resolved);
    });
    const validationResult = validationGroupRef.current.instance.validate();
    if (!validationResult.isValid && validationResult.status !== 'pending') {
      //setTieneErroresValidacion(true);
      return false;
    }
    if (validationResult.status === 'pending') {
      validationResult.complete.then(async resolve => {
        await resolve;
        status = resolve.isValid;
        resolved = true;
      });
      await p;
      return status;
    }
    return true;
  }, [])


  const guardar = React.useCallback(async () => {
    // const validationResult = validationGroupRef.current.instance.validate();
    // if (!validationResult.isValid) {
    //   //setTieneErroresValidacion(true);
    //   return;
    // }
    var validated = await validate();
    if (!validated) {
      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...datosEdicion,
          tieneErroresValidar: true
        }
      }))
      return
    } else {
      dispatch(setDatosEdicion({
        key: tabId,
        data: {
          ...datosEdicion,
          tieneErroresValidar: false
        }
      }))
    }
    showEditLoader("Guardando retencion...")
    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: ButtonTypes.save
    }))
    if (datosEdicion.documentoOrigen) {
      try {
        const periodoFiscal = DateUtils.format(DateUtils.strDateToDate(fechaEmision, formatoFechasDatePickers), "MM/yyyy")
        const total = parseFloat(detalles.reduce((prev, next) => prev + next.valor, 0).toFixed(2));
        const info: RetencionCompraIngresar = {
          comprobanteRetencion: {
            usuario: sesion?.usuario.codigo ?? 0,
            ruc: sesion?.empresa.ruc ?? "",
            pventa: puntoVenta ?? '',
            local: sesion?.local.codigo ?? 0,
            establecimiento: establecimiento,
            ptoEmision: puntoEmision,
            infoCompRetencion: {
              fechaEmision: DateUtils.pickersDateToApiDate(fechaEmision),
              tipoIdentificacionSujetoRetenido: proveedor?.tipoIdentificacion ?? "",
              razonSocialSujetoRetenido: proveedor?.nombre ?? "",
              identificacionSujetoRetenido: proveedor?.identificacion ?? "",
              periodoFiscal: periodoFiscal
            },
            impuestos: detalles.map((x) => {
              const impuesto: RetencionCompraIngresarImpuesto = {
                codigo: parseInt(x.concepto?.codigo ?? "0"),
                codigoRetencion: x.conceptoCodigo,
                baseImponible: x.baseImponible,
                porcentajeRetener: x.porcentaje,
                valorRetenido: x.valor,
                codDocSustento: compra?.tipoDocumento ?? "",
                numDocSustento: `${compra?.establecimiento}${compra?.puntoEmision}${compra?.numero}`,
                fechaEmisionDocSustento: compra?.fecha ?? ""
              }
              return impuesto;
            }),
            pagos: [{
              medio: formaPago?.nombre ?? "",
              total: total
            }]
          }
        }
        const resultado = await RetencionCompraService.guardar(info);
        console.log("resultado ", resultado)
        //const retencion = await RetencionCompraService.getRetencionByCode(parseInt(resultado.codigo));
        //if(retencion.)
        dispatch(updateResultadoGuardarRetencion({
          key: tabId,
          retencion: resultado,
          imprimir: empresa?.imprimeAutomaticamenteAlfacturar ?? false
        }))
        // dispatch(setDatosEdicion({
        //   key: props.tabId, data: {
        //     ...datosEdicion,
        //     codigo: codigoRetencionVenta,
        //   }
        // }));

        dispatch(changeEditStatus({
          estado: StatesEdition.save,
          tabKey: tabId,
          info: resultado,
          buttons: {
            ...RetencionCompraButtons,
            Guardar: false,
            Editar: true,
            Deshacer: false,
            Autorizar: resultado.fechaAutorizacion === "",
            Anular: resultado.estadoElectronico === "AUTORIZADO",
            Enviar: resultado.estadoElectronico === "AUTORIZADO",
            Imprimir: true
          }
        }));

      } catch (error) {
        console.log(error);
        dispatch(addToast({
          id: '',
          autoHide: true,
          content: 'Error al guardar retencion ' + (typeof error === 'string' ? error : JSON.stringify(error)),
          fade: true,
          title: 'Guardar',
          type: ToastTypes.Danger
        }));
        dispatch(setMuestraError({
          key: tabId,
          error: error
        }));
        hideEditLoader();
        // dispatch(setDatosEdicion({
        //   data: {
        //     ...datosEdicion,
        //     loader: {
        //       show: false,
        //       mensaje: ''
        //     }
        //   },
        //   key: tabId
        // }));
      }
    }
    hideEditLoader();
  }, [validate,
    showEditLoader,
    datosEdicion,
    hideEditLoader,
    fechaEmision,
    detalles,
    sesion,
    puntoVenta,
    establecimiento,
    puntoEmision,
    proveedor,
    formaPago,
    compra,
    empresa,
    tabId,
    dispatch]);

  const cargarRetencion = React.useCallback(async (code: number) => {
    if (imprimir) {
      setVerFacturaPdf({ claveAcceso: autorizacion, design: false });;
    }

    if (loading === false) {
      return;
    };

    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: ButtonTypes.find
    }))

    showEditLoader("Cargando retencion...")
    const data = { ...defaulDatosEdicionRetencion };
    try {
      const retencion = await RetencionCompraService.getRetencionByCode(code);
      console.log("retencion ", retencion)
      //const detalles = await RetencionesService.ge
      const compra = await CompraService.getCompra(retencion.compraCodigo)
      if (compra == null) {
        return;
      }
      const detalles = await RetencionCompraService.getDetalles(code);
      const proveedor = await ProveedoresService.getProveedor(compra.proveedorIdentificacion);
      const formasPago = await CompraService.getFormasPago();
      data.fechaEmision = DateUtils.apiDateToPickersDate(retencion.fecha);
      data.baseIva = compra.IVA;
      data.baseRenta = compra.subtotal;
      data.proveedor = proveedor;
      data.formasPago = formasPago;
      data.formaPago = formasPago.find(x => x.codigo === retencion.formaPago);
      data.establecimiento = retencion.establecimiento;
      data.puntoEmision = retencion.puntoEmision;
      data.numero = `${retencion.establecimiento}-${retencion.puntoEmision}-${retencion.numero}`;
      data.autorizacion = retencion.claveAcceso;
      data.fechaAutorizacion = retencion.fechaAutorizacion;
      data.compra = compra;
      data.detalles = detalles.map(x => {
        const det: DetalleRetencionRedux = {
          anioFiscal: x.ejercicioFiscal,
          baseImponible: x.base,
          concepto: {
            codigo: x.conceptoRetencionCodigo,
            concepto: x.conceptoRetencionImpuesto,
            estado: true,
            porcentaje: x.porcentaje,
            tipo: x.tipo === "FUENTE" ? TiposRetencion.Fuente : TiposRetencion.IVA,
            tipoPorcentaje: TiposPorcentajeRetencion.Limite,
          },
          conceptoCodigo: x.conceptoRetencionCodigo,
          fecha: retencion.fecha,
          porcentaje: x.porcentaje,
          tipo: x.tipo === "FUENTE" ? TiposRetencion.Fuente : TiposRetencion.IVA,
          valor: x.valor,
          id: uuidv4()
        }
        return det;
      })
      data.loader = {
        show: false,
        mensaje: ''
      };
      data.loading = false;
      data.codigo = retencion.codigo;
      data.documentoOrigen = {
        comprobante: compra.codigo,
        fechaEmision: compra.fecha,
        formaPagoCodigo: compra.formaPagoCodigo,
        numero: compra.numero,
        tipoDoc: compra.tipoDocumento,
        totalDocumento: compra.total
      }
      dispatch(setDatosEdicion({
        data: data,
        key: tabId
      }));

      dispatch(setButtons({
        tabKey: tabId,
        buttons: {
          ...RetencionCompraButtons,
          Guardar: false,
          Autorizar: retencion.fechaAutorizacion === '',
          Enviar: retencion.estadoElectronico === 'AUTORIZADO',
          Anular: retencion.estadoElectronico === 'AUTORIZADO',
          Deshacer: false,
          Descuento: false,
          Imprimir: true
        }
      }))

    } catch (error) {
      console.log(error);
      data.error = error;
      dispatch(setDatosEdicion({
        data: data,
        key: tabId
      }));
    }
    hideEditLoader();
  }, [autorizacion, imprimir, loading, tabId, hideEditLoader, showEditLoader, dispatch]);

  const modoNuevo = React.useCallback(async (limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return;
    }
    dispatch(setFechaRetencion({ key: tabId, fecha: DateUtils.getCurrentDateAsString() }))
    const autorizacion = await VentasService.getAutorizacion(puntoVenta ?? '', TiposComprobantesSri.Retencion);
    const numActual = autorizacion.numActual.toString();
    dispatch(setNumeroRetencion({
      key: tabId,
      numero: `${autorizacion.establecimiento}-${autorizacion.ptoemision}-${"0".repeat(9 - numActual.length)}${numActual}`
    }))
    setMostrarSeleccionDocumentos(true);
  }, [loading, puntoVenta, tabId, dispatch]);


  const handlePrint = React.useCallback((design: boolean) => {
    setVerFacturaPdf({ claveAcceso: autorizacion, design: design });
  }, [autorizacion])


  const deshacer = React.useCallback(() => {
    if (info.numero === 0) {
      dispatch(closeTab(tabId));
      dispatch(clearDatosEdicion(tabId))
    } else {
      if (info.info === null) {
        const errorInfo = "No se encuentra la información del documento"
        throw errorInfo
      }
      // dispatch(changeEditStatus({
      //   estado: StatesEdition.save,
      //   tabIndex: tabIndex,
      //   info: info.info
      // }));
      cargarRetencion(info.numero);
    }
  }, [tabId, info, cargarRetencion, dispatch]);

  const modoEdicion = React.useCallback(() => {
    if (proveedor == null) {
      return;
    }
    if (autorizacion.length === 37 || autorizacion.length === 49) {
      dispatch(addToast({
        autoHide: true,
        content: 'Una Retención eléctronica no puede ser editada!!!',
        fade: true,
        id: '',
        title: 'Editar Retención',
        type: ToastTypes.Info
      }));
      dispatch(setMuestraError({
        key: tabId,
        error: "Una Retención eléctronica no puede ser editada!!!"
      }));
    } else {

    }
  }, [autorizacion, proveedor, tabId, dispatch]);


  const reenviarAutorizacion = React.useCallback(async () => {
    try {
      showEditLoader('Reenviando documento a autorizar...');
      await VentasService.reintentarAutorizacion(autorizacion, TiposComprobantesSri.Retencion);
      dispatch(addToast({
        content: 'El comprobante fue enviado al sri para su autorización.',
        type: ToastTypes.Info,
      }))
    } catch (error) {
      if (typeof (error) == 'string') {
        dispatch(addToast({
          content: error,
          type: ToastTypes.Danger,
        }))
      }
      console.log(error);
      //dispatch( )
    }
    hideEditLoader();
  }, [autorizacion, showEditLoader, hideEditLoader, dispatch])

  const abrirBusquedaDeCompras = useCallback(() => {
    setMostrarSeleccionDocumentos(true);
  }, []);

  const buttonClickAnular = React.useCallback(() => {
    if (info && info.info?.estadoElectronico !== "AUTORIZADO") {
      dispatch(addToast({
        content: 'La retención no está autorizada, debe autorizarce antes de proceder a la anulación.',
        type: ToastTypes.Warning
      }))
    }
    setMostrarAnular(true);
  }, [info, dispatch])

  const onCancelAnular = React.useCallback(() => {
    setMostrarAnular(false);
  }, [])

  const onAnular = React.useCallback(async () => {
    if (info.info == null || info === undefined) {
      dispatch(addToast({
        content: 'No se encuentra la información del documento, comunicar a los desarrolladores.',
        type: ToastTypes.Danger
      }))
      return;
    }
    try {
      setMostrarAnular(false);
      showEditLoader("Anulando Comprobante...");
      await RetencionCompraService.anularRetencion(info.info);
      dispatch(addToast({
        content: 'El documento fue anulado correctamente.',
        type: ToastTypes.Success
      }))
      //dispatch(setTabInfoAsInactive(tabId));
    } catch (error) {
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al anular ' + (typeof error === 'string' ? error : JSON.stringify(error)),
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Danger
      }));
    } finally {
      hideEditLoader();
    }
  }, [info, showEditLoader, hideEditLoader, tabId, dispatch])


  React.useEffect(() => {
    const cargar = async () => {
      const conceptos = await RetencionesService.getConceptosRetencion(TiposRetencion.NoDefinido, DateUtils.pickersDateToApiDate(fechaEmision));
      setConceptosRetencion(conceptos.map(x => {
        return { ...x, concepto: `${x.codigo} - ${x.concepto}${x.tipo === 'IVA' ? ` - ${x.porcentaje.toFixed(0)}%` : ''}` }
      }));
    }
    cargar();
  }, [fechaEmision])


  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo();
    } else {
      cargarRetencion(info.numero);
    }
  }, [tab, info, modoNuevo, cargarRetencion]);


  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      switch (tab.globalButtonClick) {
        case ButtonTypes.edit:
          modoEdicion();
          break;
        case ButtonTypes.save: {
          guardar();
          break;
        }
        case ButtonTypes.undo:
          deshacer();
          break;
        case ButtonTypes.print:
          handlePrint(false);
          break;
        case ButtonTypes.print_design:
          handlePrint(true);
          break;
        case ButtonTypes.sendMail:
          setShowReenviarMail(true);
          break;
        case ButtonTypes.authorizations:
          reenviarAutorizacion();
          break;
        case ButtonTypes.broom:
          modoNuevo(true);
          break;
        case ButtonTypes.disable:
          buttonClickAnular();
          break;
        default:
          break;
      }
      dispatch(clearButtonClick(tabId))
    }
  }, [tabId, handlePrint, deshacer, guardar, modoEdicion, tab, dispatch, modoNuevo, reenviarAutorizacion, buttonClickAnular]);

  React.useEffect(() => {
    if (imprimir) {
      handlePrint(false);
    }
  }, [imprimir, handlePrint, tabId, dispatch])

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }} >
      {error &&
        <VisualizaError titulo='Error en Retención' mensaje={error} onOk={() => {
          dispatch(setMuestraError({ key: props.tabId, error: '' }))
          // if (currentTab.editStatus === StatesEdition.new) {
          //   deshacer();
          // }
        }} />
      }
      {showReenviarMail && proveedor != null && (
        <PopupReenvioMail
          showConfirm={showReenviarMail}
          itemReenviarMail={{
            claveAcceso: autorizacion,
            proveedor: proveedor,
          }}
          closeConfirm={() => setShowReenviarMail(false)}
        />
      )}
      {mostrarSeleccionDocumentos &&
        <BusquedaCompras
          onCancel={onCancelDocummentSelection}
          onSelected={onDocumentSelected}
          show={mostrarSeleccionDocumentos}
          tipoComprobante={tipoComporbanteBusqueda} />
      }
      {tab.editStatus === StatesEdition.save && verFacturaPdf &&
        <VerRIDE modo='DevExpressReport' claveAcceso={verFacturaPdf.claveAcceso} onClose={() => {
          setVerFacturaPdf(undefined)
          dispatch(setImprimir({
            imprimir: false,
            key: tabId
          }))
        }} vista={verFacturaPdf.design ? "Designer" : "Viewer"} />
      }
      <BlockUi loader={LoadingIndicator} tag="div" blocking={loader.show} message={loader.mensaje}  >
        {info.info !== null && mostrarAnular &&
          <AnularRetencioCompra onOk={onAnular} onCancel={onCancelAnular} info={info.info} />
        }
        <fieldset disabled={tab.editStatus === StatesEdition.save} >
          <ValidationGroup id={`valGroupRetencion${props.tabId}`} ref={validationGroupRef}>
            {!isMobileOnly &&
              <RowContainer>
                <CustomCol>
                  <ValidationSummary />
                </CustomCol>
              </RowContainer>
            }
            <div>
              <RowContainer>
                <CustomCol xs='12' lg='7'>
                  <div>
                    <RowContainer>
                      <CustomCol xs='12'>
                        <Labeled
                          label='Proveedor'
                        >
                          <BuscarProveedorLookUp selected={proveedor} allowEdit disabled onChanged={(value) => dispatch(updateProveedor({ key: tabId, proveedor: proveedor, formasPago: [] }))} />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    {proveedor &&
                      <RowContainer>
                        <CustomCol
                          xs='12'
                        >
                          <Labeled
                            label='Dirección'
                          >
                            <TextBox
                              readOnly
                              value={proveedor.direccion}
                            >

                            </TextBox>
                          </Labeled>
                        </CustomCol>
                        <CustomCol
                          xs='4'
                        >
                          <Labeled
                            label='Teléfono'
                          >
                            <TextBox

                              readOnly
                              value={proveedor.telefono ?? ''}
                            >

                            </TextBox>
                          </Labeled>
                        </CustomCol>
                        <CustomCol
                          xs='8'
                        >
                          <Labeled
                            label='Correo eléctronico'
                          >
                            <TextBox

                              readOnly
                              value={proveedor.email ?? ''}
                            >

                            </TextBox>
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                    }
                    <RowContainer>
                      <CCol xs='6' md='5' lg='4' xl='3' >
                        <CInputGroup size='sm'>
                          <CInputGroupPrepend>
                            <CInputGroupText>Renta</CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput value={baseRenta.toFixed(2)} readOnly />
                        </CInputGroup>
                      </CCol>
                      <CCol xs='6' md='5' lg='4' xl='3' >
                        <CInputGroup size='sm'>
                          <CInputGroupPrepend>
                            <CInputGroupText>IVA</CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput value={baseIva.toFixed(2)} readOnly />
                        </CInputGroup>
                      </CCol>
                    </RowContainer>
                  </div>
                </CustomCol>
                <CustomCol xs='12' lg='5'>
                  <div>
                    <RowContainer>
                      <CustomCol >
                        <Labeled
                          label='Fecha de Emisión'
                        >
                          <DateBox applyValueMode="useButtons"
                            displayFormat='dd/MM/yyyy'
                            value={DateUtils.strDateToDate(fechaEmision)}
                            onValueChanged={({ value }) => {
                              dispatch(setFechaRetencion({ key: props.tabId, fecha: DateUtils.dateToString(value) }))
                            }} >
                            <Validator>
                              <AsyncRule ignoreEmptyValue message={"La fecha de retención debe de ser >= a la de la factura de compra."} validationCallback={validateRetencionFecha}   > </AsyncRule>
                            </Validator>
                          </DateBox>
                        </Labeled>
                      </CustomCol>
                      <CustomCol>
                        <Labeled
                          label='Retención Nro.:'
                        >
                          <TextBox
                            name='Numero'
                            value={numero}
                            readOnly
                            useMaskedValue={true}
                          >
                            <Validator>
                              <RequiredRule message={lh.getMessage(MessagesKeys.RetencionNroRequerido, "")}  ></RequiredRule>
                              <AsyncRule ignoreEmptyValue message={lh.getMessage(MessagesKeys.RetencionSinDetalles)} validationCallback={validateRetencion}   > </AsyncRule>
                            </Validator>

                          </TextBox>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    {((tab.editStatus === StatesEdition.save)) && //Electronica
                      <>
                        < RowContainer >
                          <CustomCol xs='12' xxl='8' >
                            {!isMobileOnly &&
                              <Barcode text={autorizacion} />
                            }
                            {isMobileOnly &&
                              <Labeled
                                label='Autorización'
                              >
                                <small>{autorizacion}</small>
                              </Labeled>
                            }

                          </CustomCol>
                          <CustomCol xs='12' xxl='4' >
                            <Labeled
                              label='Fecha Autorización'
                            >
                              <CBadge
                                color={fechaAutorizacion ? 'info' : 'danger'}
                              >
                                {fechaAutorizacion ? datosEdicion.fechaAutorizacion : "No Autorizado"}
                              </CBadge>
                            </Labeled>
                          </CustomCol>
                        </RowContainer>
                      </>
                    }
                    <RowContainer>
                      <CustomCol>
                        <Labeled
                          label='Forma Pago'
                        >
                          <SelectBox
                            id='formaPagoretencionVenta'
                            placeholder='Forma de Pago'
                            options={formasPago}
                            displayExpr='nombre'
                            keyExpr='codigo'
                            selected={formaPago ?? null}
                            onChange={(selected) => {
                              dispatch(setFormaPago({ key: tabId, formaPago: selected }))
                            }}
                            disabled={tab.editStatus === StatesEdition.save}
                            clearButton={true}
                          >
                            <Validator>
                              <RequiredRule message={lh.getMessage(MessagesKeys.RetencionFormaPagoRequerida)} />
                            </Validator>
                          </SelectBox>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    {compra &&
                      <RowContainer>
                        <CustomCol>
                          <Labeled
                            label='# Compra'
                          >
                            <TextBox text={`${compra?.establecimiento}-${compra?.puntoEmision}-${compra?.numero}`} readOnly >
                              <TextBoxButton
                                name="abrirBusquedaCompras"
                                location="after"
                                options={{
                                  icon: 'search',
                                  type: 'default',
                                  disabled: tab.editStatus === StatesEdition.save,
                                  onClick: abrirBusquedaDeCompras
                                }}
                              />
                            </TextBox>
                          </Labeled>
                        </CustomCol>
                        <CustomCol>
                          <Labeled
                            label='Fecha Compra'
                          >
                            <TextBox text={compra.fecha} readOnly >

                            </TextBox>
                          </Labeled>
                        </CustomCol>
                        <CustomCol>
                          <Labeled
                            label='Total Compra'
                          >
                            <TextBox readOnly text={compra.total.toFixed(2)} />
                          </Labeled>

                        </CustomCol>
                      </RowContainer>
                    }
                  </div>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CCol xs='12'>
                  <DataGrid
                    focusedRowEnabled={true}
                    keyExpr='id'
                    repaintChangesOnly
                    dataSource={detalles}
                    showBorders={true}
                    onInitNewRow={onInitNewRow}
                    onEditorPreparing={onEditorPreparing}
                    onSaving={onSaving}
                    onRowRemoved={onRowRemoved}
                    onRowRemoving={onRowRemoving}

                  //onExporting={onExporting}
                  //onRowDblClick={onRowDoubleClick}
                  >
                    <Editing
                      mode={isMobile ? 'popup' : 'row'}
                      allowUpdating={true}
                      allowDeleting={true}
                      allowAdding={true}
                      useIcons
                      changes={changes}
                      onChangesChange={onChangesChange}
                      editRowKey={editRowKey}
                      onEditRowKeyChange={onEditRowKeyChange}
                    >
                    </Editing>
                    {/* <Column dataField='id' caption='id'  visible={false}  showEditorAlways={false}/> */}
                    <Column dataField='tipo' caption='Tipo' width='100px' setCellValue={(rowData, value) => {
                      rowData.concepto = null;
                      rowData.tipo = value;
                    }} >
                      <Lookup dataSource={TiposRetencionDetalle} displayExpr="descripcion" valueExpr="id" />

                      <AsyncRule validationCallback={validateTipoRetencion} />
                    </Column>
                    <Column dataField='anioFiscal' caption='Año' width='80px' allowEditing={false} />
                    {/* <Column dataField='conceptoCodigo' width='80px' caption='Código' allowSorting={false} setCellValue={onLookupCellChanged} name='colConceptoCodigo' >
                      <Lookup dataSource={getRetencionCodesByCode} displayExpr='codigo' valueExpr='codigo' />
                      <GridRequiredRule />
                    </Column> */}
                    <Column dataField='conceptoCodigo' caption='Concepto' minWidth='200px' width='100%' setCellValue={onLookupCellChanged} name='colConceptoDescripcion'  >
                      <Lookup dataSource={getRetencionCodesByCode} displayExpr='concepto' valueExpr='codigo' />
                      <GridRequiredRule />
                    </Column>
                    <Column dataField='baseImponible' caption='B. Imponible' width='80px' alignment='right' dataType='number' setCellValue={onBaseImponibleChanged} >
                      <GridRequiredRule />
                      <AsyncRule ignoreEmptyValue validationCallback={validateBaseImponible} />
                    </Column>
                    <Column dataField='porcentaje' caption='%' width='80px' alignment='center' dataType='number' editCellRender={porcentajeEditrRender} setCellValue={onPorcentajeRetenidoChanged}   >
                      <GridRequiredRule />
                      <RangeRule min={0} max={100} />
                    </Column>
                    <Column dataField='valor' alignment='right' width='80px' dataType='number' >
                      <GridRequiredRule />
                    </Column>
                    <Paging enabled={false} />
                    <Scrolling mode="virtual" />
                  </DataGrid>
                </CCol>


              </RowContainer>
              <div className="float-right"  >
                <div className='dx-datagrid-text-content text-primary' style={{ fontSize: '16px', margin: '0.5em' }}  >
                  <span style={{ marginRight: '0.2em' }}  >TOTAL</span>
                  <span> {detalles.reduce((prev, next) => prev + next.valor, 0).toFixed(2)} </span>
                </div>
              </div>
            </div>

            {isMobileOnly &&
              <RowContainer>
                <CustomCol>
                  <ValidationSummary />
                </CustomCol>
              </RowContainer>
            }

          </ValidationGroup>
        </fieldset>
      </BlockUi>
      {isMobile &&
        <div style={{ height: '40px' }} />
      }
    </div>
  );
}


export const defaulDatosEdicionRetencion: RetencionDatosEdicion = {
  establecimiento: '',
  puntoEmision: '',
  fechaEmision: DateUtils.getCurrentDateAsString(),
  proveedor: null,
  autorizacion: '',
  fechaAutorizacion: '',
  detalles: [],
  loader: {
    mensaje: 'Cargando retención...',
    show: true
  },
  baseIva: 0,
  baseRenta: 0,
  codigo: 0,
  formasPago: [],
  loading: true,
  numero: "",
  imprimir: false,
  tieneErroresValidar: false
}

const TiposRetencionDetalle = [
  { id: 'IVA', descripcion: 'IVA' },
  { id: 'Fuente', descripcion: 'Fuente' },
]


export const RetencionCompraButtons: CustomButtons = {
  Autorizar: false,
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: false,
  Editar: false,
  Imprimir: false,
  Descuento: false,
  Credito: false,
  Enviar: false,
}
