import Lookup from 'devextreme-react/lookup';
import { DropDownOptions } from 'devextreme-react/select-box';
import * as React from 'react';
import { InventarioService } from '../../services/inventario.service';
import { MarcaInventario } from '../../store/types';

interface IBuscarMarcaLookUpProps {
  selected?: MarcaInventario,
  onChanged: (newValue:MarcaInventario | undefined) => void,
  allowEdit?: boolean,
  allowClear?: boolean,
  allowAdd?: boolean
}

export const BuscarMarcaLookUp: React.FC<IBuscarMarcaLookUpProps> = (props) => {
  const { selected, onChanged, } = props;

  const datasource = React.useMemo(() => {
    return InventarioService.getMarcasDatasource();
  }, []);

  const getDisplayExpr = React.useCallback((marca: MarcaInventario) => {
    return marca ? marca.nombre : '';
  }, []);

  return (
    <Lookup
      dataSource={datasource}
      showDataBeforeSearch
      value={selected}
      minSearchLength={3}
      searchTimeout={500}
      displayExpr={getDisplayExpr}
      searchExpr={['nombre']}
      pageLoadMode='scrollBottom'
      showClearButton={true}
      onValueChanged={({ value }) => {
        onChanged(value);
      }}
    >
      <DropDownOptions
        tabIndex={3000}
        closeOnOutsideClick={true}
        showTitle={false}
      />
      {props.children}
    </Lookup>
  )
};
