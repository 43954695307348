import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Seleccionado } from "../../../../../store/types";
import { ClientesState } from "./types";

const initialState: ClientesState = {
  modulo: 'clientes',
  currentAction: "Buscar",
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
}

const clientesSlice = createSlice({
  name: "modalClientes",
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setSeleccionarCliente(state, action: PayloadAction<Seleccionado>) {
      let toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      let toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
  }
})

export const {
  setCurrentAccion,
  setSeleccionarCliente, setResetSeleccion
} = clientesSlice.actions;
export const clientesReducer = clientesSlice.reducer;