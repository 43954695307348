import * as React from 'react';
import './guiaRemision.scss'
import { useDispatch, useSelector } from 'react-redux';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { seleccionarSesion } from '../../../../../store/reducers';
import { RootState } from '../../../../../store/store';
import {
  Cliente, GuiaDatosEdicion, GuiaDatosEdicionDetalle,
  GuiaInfo, InfoDocumentoVentaAfectar, ItemVenta, VentaInfo,
} from '../../../types/types';
import { VentasService } from '../../../services/ventas.service';
import { ClientesService } from '../../../services/clientes.service';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { VentaHelper } from '../../../pages/ventas/ventaHelper';
import { StatesEdition } from '../../../types/enums';
import config from '../../../../../config/config';
import { addToast } from '../../../../../store/toasterReducer';
import { TiposComprobantesSri, ToastTypes } from '../../../../../store/types';
import { DetalleVentaRedux } from '../../../pages/ventas/types/detalleVenta';
import Dialog from 'react-bootstrap-dialog'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types';
import { VerRIDE } from '../../verRide/verRide';
import ModificaItem from '../../items/modificarItem';
import MainModalItems from '../../items/busquedaItemsModal/MainModalItems';
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../views/componentes/colContainer';
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput';
import { CBadge, CCard, CCardBody, CCardHeader, CCol, CInput, CRow } from '@coreui/react';
import { faEdit, faMinusCircle, } from '@fortawesome/pro-light-svg-icons';
import MUIDataTable from "mui-datatables";
import { getColumnnasTablaDetalleVentas, OptionsTablaDetalleVentas } from './helper';
import { CustomButtons, DocumentInfo, TabState } from '../../../types/generics';
import { v4 as uuidv4 } from 'uuid';
import { CustomSheet } from '../../../../../views/componentes/customSheet/customSheet';
import { DataSheetColumn } from '../../../../../views/componentes/customSheet/dataSheetColumn';
import { CustomSheetTextEditor } from '../../../../../views/componentes/customSheet/editors/textEditor';
import { CustomSheetNumberEditor } from '../../../../../views/componentes/customSheet/editors/numberEditor';
import CustomSheetNumberViewer from '../../../../../views/componentes/customSheet/viewers/sheetNumberViewer';
import { CellChangedArgs } from '../../../../../views/componentes/customSheet/cellChangedArgs';
import { RowCommand } from '../../../../../views/componentes/customSheet/genericRow';
import { SheetDescripcionItemSelectEditor } from '../../ventas/sheetEditors/sheetDescripcionItemSelectEditor';
import { SheetTextEditor } from '../../ventas/sheetEditors/sheetTextEditor';
import {
  clearDatosEdicion, setDatosEdicion, setEditLoader, updateDetalles, updateObservaciones,
  updateFechaPartida, updateFechaLlegada, updateDeclaracionAduanera, updateOrdenIngreso,
  updatePuntoPartida, updatePuntoLlegada, updateCliente, updateProveedor, updatePlaca,
  updateDocumentoOrigen, updateMotivo,
  setMuestraError, setImprimir, addDetalle,
  setClaveAccesoGuardada
} from '../../../pages/guiasRemision/store/editDataReduce';
import AgregarDetalleGuiaMobile from './AgregarDetalleGuiaMobile';
import VisualizaError from '../../../pages/shared/visualizaError/visualizaError';
import DocumentoVentaAfectar from '../../busquedaDocumentoVentaAfectar/documentoVentaAfectar';
import { DocumentoOrigenFacturacion } from '../../../pages/ventas/types/types';
import { TGuiasRemisionIngresar } from '../../../pages/guiasRemision/types/types'
import { changeEditStatus, clearButtonClick, closeTab, setButtons, setCurrentExecutingAction } from '../../../pages/guiasRemision/store/tabsReducer';
import { DateUtils, formatoFechasApi, formatoFechasDatePickers } from '../../../../../helpers/dateUtils';
import TextBox from 'devextreme-react/text-box';
import BuscarClienteLookUp from '../../busquedaCliente/busquedaCliente';
import { ProveedorBusqueda } from '../../../../proveedores/store/types';
import { BuscarProveedorLookUp } from '../../../../proveedores/components/busquedaProveedor/BuscarProveedorLookUp';
import {
  Validator,
  RequiredRule,
  RangeRule,
  StringLengthRule,
} from 'devextreme-react/validator';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ValidationSummary from 'devextreme-react/validation-summary';
import { lh, MessagesKeys } from '../../../../../helpers/localizationHelper';
import DateBox from 'devextreme-react/date-box';
import TextArea from 'devextreme-react/text-area';
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator';
import { ProveedoresService } from '../../../../proveedores/services/proveedores.service';
import Barcode from '../../../../../views/componentes/barcode/barcode';
import Button from 'devextreme-react/button';
import PopupReenvioMail from '../../ventas/busquedaVentas/popupReenvioMail/index';

interface IGuiaRemisionProps {
  info: DocumentInfo<GuiaInfo>,
  tabId: string,
  tab: TabState<GuiaInfo>
}



const GuiaRemision: React.FunctionComponent<IGuiaRemisionProps> = (props) => {
  const dispatch = useDispatch();
  const { tabId, tab, info } = props;
  const sesion = useSelector(seleccionarSesion);
  const usuario = useSelector((state: RootState) => { return state.global.session?.usuario });
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  const local = useSelector((state: RootState) => { return state.global.session?.local });
  //const currentTab = useSelector((state: RootState) => { return state.ventas.guiasRemision.tabs.tabs[props.tabIndex] });
  //const datosEdicion = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId] });
  const claveAcceso = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].claveAcceso });
  const cliente = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].cliente });
  const proveedor = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].proveedor });
  const despacho = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].despacho });
  const documentoOrigen = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].documentoOrigen });
  const establecimiento = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].establecimiento });
  const fecha = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].fecha });
  const fechaDesde = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].fechaDesde });
  const fechaHasta = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].fechaHasta });
  const declaracionAduanera = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].declaracionAduanera });
  const motivo = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].motivo });
  const ordenIngreso = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].ordenIngreso });
  const puntoEmision = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].puntoEmision });
  const loading = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].loading });
  const loader = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].loader });
  const puntoPartida = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].puntoPartida });
  const puntoLlegada = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].puntoLlegada });

  const mensajeError = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].mensajeError });
  const tieneError = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].tieneError });
  const autorizacion = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].autorizacion });
  const guiaEstablecimiento = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].guiaEstablecimiento });
  const guiaEmision = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].guiaEmision });
  const guiaDatos = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId] });

  const fechaAutorizacion = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].fechaAutorizacion });

  const placa = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].placa });
  const imprimir = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].imprimir });
  const observaciones = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].observaciones });
  const detalles = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].detalles });
  const puntoVenta = useSelector((state: RootState) => { return state.global.puntoVenta });
  const dialogRef = React.useRef<any>(null);
  const [filaEliminar, setFilaEliminar] = React.useState(-1);
  const numeroGuia = useSelector((state: RootState) => { return state.ventas.guiasRemision.editData[props.tabId].numero });
  const [agregarDetalleMovil, setAgregarDetalleMovil] = React.useState(false);
  const [mostrarEdicionClientes, setMostrarEdicionClientes] = React.useState(false);
  const [mostrarEdicionItems, setMostrarEdicionItems] = React.useState(false);
  const [mostrarBusquedaItems, setMostrarBusquedaItems] = React.useState(false);
  const [codigoBarrasItemEditar, setCodigoBarrasItemEditar] = React.useState('');
  const [ruc,] = React.useState<string>('');
  const [verFacturaPdf, setVerFacturaPdf] = React.useState<{ claveAcceso: string, design: boolean }>();
  const [verSeleccionDocumentos, setVerSeleccionDocumentos] = React.useState(false);
  const validationGroupRef = React.useRef<any>();
  const [tieneErrorValidacion, settieneErrorValidacion] = React.useState<boolean>(false);
  const [erroresValidacion, setErroresValidacion] = React.useState<Array<string>>([]);

  const [showReenviarMail, setShowReenviarMail] = React.useState<boolean>(false);


  const sheetCommands = React.useMemo(() => {
    const commads: Array<RowCommand<DetalleVentaRedux>> = [];
    //fas fa-file-invoice-dollar
    commads.push({
      id: 'Eliminar',
      text: 'Eliminar',
      icon: faMinusCircle,
      onExecute: (rowIndex) => { setFilaEliminar(rowIndex) },
    });
    commads.push({
      id: 'Editar',
      text: 'Editar',
      icon: faEdit,
      onExecute: (rowIndex) => {
        setMostrarEdicionItems(true);
        setCodigoBarrasItemEditar(detalles[rowIndex].codigoBarras);
      },
    });
    return commads;
  }, [detalles]);

  const showEditLoader = React.useCallback((mensaje: string) => {
    dispatch(setEditLoader({
      key: tabId,
      info: {
        mensaje: mensaje,
        show: true
      }
    }));
  }, [dispatch, tabId])


  const hideEditLoader = React.useCallback(() => {
    dispatch(setEditLoader({
      key: tabId,
      info: {
        mensaje: '',
        show: false
      }
    }));
  }, [dispatch, tabId])

  const cambiarDocumentoOrigenFacturacion = React.useCallback(async (documento: DocumentoOrigenFacturacion) => {
    showEditLoader('Cargando documento...');
    const _venta = await VentasService.getVenta(documento.comprobante);
    if (_venta) {
      const cliente = await ClientesService.getCliente(_venta?.clienteIdentificacion);
      const _detallesApi = await VentasService.getDetalleVenta(_venta.codigo, TiposComprobantesSri.Factura);
      const _detalles = VentaHelper.detallesToDetallesVenta(_detallesApi,4);
      const _detallesGuias: GuiaDatosEdicionDetalle[] = [];
      for (let index = 0; index < _detalles.length; index++) {
        const _detalle = _detalles[index];
        const _detalleGuia: GuiaDatosEdicionDetalle = {
          idItem: _detalle.codigo,
          cantidadItem: _detalle._cantidad,
          codigoBarras: _detalle.codigoBarras,
          descripcionItem: _detalle.descripcion
        }
        _detallesGuias.push(_detalleGuia);
      }

      dispatch(updateDocumentoOrigen({
        key: tabId,
        documento: documento,
        cliente: cliente,
        detalles: _detallesGuias,
      }));
    }
    hideEditLoader();
  }, [dispatch, tabId, showEditLoader, hideEditLoader]);


  const cargarVenta = React.useCallback(async (codigo: number, setInitData: boolean) => {
    if (loading === false) {
      return;
    }
    const data = { ...defaultDatosEdicionGuias }; //utilidades.unfreeze(defaultDatosEdicionFactura);
    try {
      if (setInitData) dispatch(setDatosEdicion({
        data: { ...data },
        key: tabId
      }));
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.find
      }))
      const guias = await VentasService.getGuias("", "", "", true, "", codigo);
      const guia = guias[0];
      let venta: VentaInfo | null = null;
      const detalles = await VentasService.getDetallesGuia(codigo);
      if (guia.ventaCodigo) {
        venta = await VentasService.getVenta(guia.ventaCodigo);
      }
      data.claveAcceso = guia.claveAcceso;
      data.cliente = await ClientesService.getCliente("", guia.clienteCodigo);
      data.declaracionAduanera = guia.nroDeclaracionAduanera;
      if (venta) {
        data.documentoOrigen = {
          comprobante: venta.codigo,
          fechaEmision: venta.fecha,
          formaPagoCodigo: venta.formaPago,
          numero: venta.numero,
          tipoDoc: venta.tipoDocumento,
          totalDocumento: venta.total
        }
      }
      data.motivo = guia.motivoMovilizacion;
      data.placa = guia.proveedorPlacaTransportista;
      data.proveedor = await ProveedoresService.getProveedor(guia.proveedorTransportistaIdentificacion);
      data.observaciones = guia.observaciones;
      data.fechaDesde = DateUtils.apiDateToPickersDate(guia.fechaInicioTraslado);
      data.fechaHasta = DateUtils.apiDateToPickersDate(guia.fechaFinTraslado);
      data.establecimiento = guia.establecimiento;
      data.guiaEstablecimiento = guia.establecimiento;
      data.puntoEmision = guia.puntoEmision;
      data.numero = guia.numero;
      data.puntoPartida = guia.puntoPartida;
      data.puntoLlegada = guia.puntoLlegada;
      data.fecha = DateUtils.apiDateToPickersDate(guia.fechaEmision);
      data.fechaAutorizacion = guia.fechaAutorizacion;
      data.detalles = detalles;
      data.loading = false;
      data.loader = {
        mensaje: '',
        show: false
      }


      dispatch(setDatosEdicion({
        data: data,
        key: tabId
      }));
      dispatch(setButtons({
        tabKey: tabId,
        buttons: {
          ...RemisionGuideButtons,
          Guardar: false,
          Autorizar: guia.fechaAutorizacion === '',
          Enviar: guia.fechaAutorizacion !== '',
          Deshacer: false,
          Descuento: false
        }
      }))
    } catch (error) {
      data.tieneError = true;
      data.mensajeError = error as any;
      dispatch(setDatosEdicion({
        data: data,
        key: tabId
      }));
    }
  }, [loading, tabId, dispatch]);

  const modoNuevo = React.useCallback(async (puntoVenta: string, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return;
    }
    sessionStorage.removeItem('autorizacionModulo');

    const data = { ...defaultDatosEdicionGuias };
    dispatch(setButtons({
      tabKey: tabId,
      buttons: {
        ...RemisionGuideButtons,
        Editar: false,
        Imprimir: false,
        Exportar: false,
        Autorizar: false,
        Enviar: false,
        Guardar: true,
      }
    }))
    try {
      //setLoading(true);
      const fecha = DateUtils.getCurrentDateAsString();//  moment().format('yyyy-MM-DD');

      const autorizacion = await VentasService.getAutorizacion(puntoVenta, TiposComprobantesSri.GuiaRemision);
      sessionStorage.setItem('autorizacionModulo', JSON.stringify(autorizacion));

      //const cliente = await ClientesService.getCliente(config.rucConsumidorFinal);
      //data.cliente = cliente;
      data.autorizacion = autorizacion;
      data.claveAcceso = '';
      data.fechaAutorizacion = 'NO AUTORIZADO (NUEVO)';
      data.detalles = [];
      data.fecha = fecha;
      data.fechaDesde = fecha;
      data.fechaHasta = fecha;
      data.establecimiento = autorizacion.establecimiento;
      data.puntoEmision = autorizacion.ptoemision;
      data.guiaEstablecimiento = autorizacion.establecimiento;
      data.guiaEmision = autorizacion.ptoemision;
      data.numero = autorizacion.numActual.toString();
      data.identificadorTransaccion = uuidv4();
      data.observaciones = '';
      data.puntoPartida = local?.direccion ?? '';
      data.loading = false;
      data.loader = {
        mensaje: '',
        show: false
      }

    } catch (error) {
      data.tieneError = true;
      data.mensajeError = error as any;
    }
    dispatch(setDatosEdicion({
      data: data,
      key: tabId
    }));
  }, [tabId, loading, local, dispatch]);


  const modoEdicion = React.useCallback(() => {
    if (cliente == null) {
      return;
    }
    if (claveAcceso.length === 37 || claveAcceso.length === 49) {
      dispatch(addToast({
        autoHide: true,
        content: 'Una Guía de Remisión eléctronica no puede ser editada.',
        fade: true,
        id: '',
        title: 'Editar Guía Remisión',
        type: ToastTypes.Info
      }));
    } else {

    }
  }, [dispatch, claveAcceso, cliente]);


  const guardar = React.useCallback(async () => {
    if (usuario == null || empresa == null || local == null || puntoVenta == null) {
      return;
    }
    const validationResult = validationGroupRef.current.instance.validate();
    if (!validationResult.isValid) {
      if (validationResult.brokenRules) {
        const mensajes: Array<string> = validationResult.brokenRules.map(x => {
          return x.message;
        });
        console.log(mensajes)
        dispatch(setDatosEdicion({
          data: {
            ...guiaDatos,
            tieneErroresValidar: true
          },
          key: tabId
        }));
        setErroresValidacion(mensajes);
      }
      //settieneErrorValidacion(true);
      return;
    }
    try {
      showEditLoader('Guardando...');
      dispatch(setDatosEdicion({
        data: {
          ...guiaDatos,
          tieneErroresValidar: false
        },
        key: tabId
      }));
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.save
      }))
      const guiaRemision: TGuiasRemisionIngresar = {
        infoEmpresa: {
          ruc: sesion?.empresa.ruc ?? ''
        },
        infoRegistro: {
          idGuia: 0,
          idVenta: documentoOrigen?.comprobante ?? 0,
          idCliente: cliente?.codigo ?? 0,
          codigoProveedor: proveedor?.codigo ?? 0,
          guiaNumero: tab.editStatus === StatesEdition.new ? "" : numeroGuia,
          guiaAutorizacion: '',
          guiaEstablecimiento: establecimiento,
          guiaEmision: puntoEmision,
          fechaDesde: DateUtils.pickersDateToApiDate(fechaDesde),
          fechaHasta: DateUtils.pickersDateToApiDate(fechaHasta),
          oingreso: ordenIngreso,
          fechaEmision: DateUtils.pickersDateToApiDate(fecha),
          guiaNdAduanera: declaracionAduanera,
          guiaMotivo: motivo,
          guiaPuntoPartida: puntoPartida,
          guiaPuntollegada: puntoLlegada,
          guiaPlaca: placa,
          guiaObservaciones: observaciones,
          guiaDespacho: despacho.toString(),
          guiaVenta: documentoOrigen ? documentoOrigen.numero : '',
          codigoAccesso: '',
          claveEstadoSri: '',
          claveFecha: '',
          infoDetalle: detalles.map(det => {
            return {
              cantidadItem: det.cantidadItem,
              codigoBarras: det.codigoBarras,
              descripcionItem: det.descripcionItem,
              idItem: det.idItem
            }
          })
        },
        infoUsuario: {
          usuario: sesion?.usuario.codigo ?? 0,
          equipo: puntoVenta
        }

      }
      const resultado = await VentasService.ingresarGuiaRemision(guiaRemision);
      //const guias = await VentasService.getGuias("", "", true, "", parseInt(resultado.id));
      // if (guias.length === 0) {
      //   //no se encuentra la guía guardada
      //   return;
      // }

      dispatch(setClaveAccesoGuardada({
        key: tabId,
        claveAcceso: resultado.claveAcceso,
        imprimir:
          empresa.imprimeAutomaticamenteAlfacturar ?? false
      }))

      //const guia = guias[0];
      dispatch(changeEditStatus({
        estado: StatesEdition.save,
        tabKey: tabId,
        info: resultado,
        buttons: {
          ...RemisionGuideButtons,
          Guardar: false,
          Editar: true,
          Deshacer: false,
          Autorizar: resultado.estadoElectronico !== "AUTORIZADO",
          Enviar: resultado.estadoElectronico === "AUTORIZADO",
          Descuento: false,
          Anular: resultado.estadoElectronico === "AUTORIZADO",
        }
      }));
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Guardado',
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Success
      }))
    } catch (error) {
      console.log(error);
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al guardar guía ' + (typeof error === 'string' ? error : JSON.stringify(error)),
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Danger
      }));
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
      //hideEditLoader();
    }
    hideEditLoader();
  }, [numeroGuia, puntoEmision, establecimiento, cliente, declaracionAduanera, despacho, detalles, documentoOrigen, empresa, fecha, fechaDesde, fechaHasta, local, motivo, observaciones, ordenIngreso, placa, proveedor, puntoLlegada, puntoPartida, puntoVenta, sesion, usuario, tabId, tab, hideEditLoader, showEditLoader, dispatch, guiaDatos]);

  const deshacer = React.useCallback(() => {
    if (info.numero === 0) {
      dispatch(closeTab(tabId));
      dispatch(clearDatosEdicion(tabId))
    } else {
      if (info.info === null) {
        const errorInfo = "No se encuentra la información del documento"
        throw errorInfo
      }
      dispatch(changeEditStatus({
        estado: StatesEdition.save,
        tabKey: tabId,
        info: info.info,
        buttons: RemisionGuideButtons
      }));
      cargarVenta(info.numero, false);
    }

  }, [info, tabId, cargarVenta, dispatch]);



  const sheetOnCellsChanged = React.useCallback(async (arrayOfChanges: Array<CellChangedArgs<GuiaDatosEdicionDetalle>>) => {
    const _detalles = detalles.slice(0);
    for (const change of arrayOfChanges) {
      const _detalle: GuiaDatosEdicionDetalle = change.rowIndex === -1 ? change.rowData : { ..._detalles[change.rowIndex] };

      switch (change.col.name) {
        case 'codigoBarras':
          showEditLoader('Buscando item...');
          const item = await VentasService.getItemPorCodigoBarras(_detalle.codigoBarras, cliente?.identificacion ??
            config.rucConsumidorFinal, DateUtils.pickersDateToApiDate(fecha));
          if (item) {
            _detalle.cantidadItem = 1;
            _detalle.codigoBarras = item.codigoBarras;
            _detalle.idItem = item.codigo;

            hideEditLoader();
          } else {
            dispatch(addToast({
              content: 'No se encuentra el item con código ' + change.newValue,
              title: 'Agregar item',
              type: ToastTypes.Warning,
              autoHide: 2000
            }))
            continue;
          }
          break;
        case 'cantidadItem':
          _detalle.cantidadItem = parseFloat(change.newValue);
          break;
        case 'descripcionItem':
          if (typeof change.newValue === 'string') {
            _detalle.descripcionItem = change.newValue;
          }
          break;
        default:
          break;
      }
      if (change.rowIndex === -1) {
        _detalles.push(_detalle);
      } else {
        _detalles[change.rowIndex] = _detalle;
      }

    }
    dispatch(updateDetalles({
      detalles: _detalles,
      key: tabId
    }));
    hideEditLoader();
  }, [detalles, tabId, cliente, fecha, hideEditLoader, showEditLoader, dispatch]);


  const agregarDetalleVentaMobile = React.useCallback((detalle: GuiaDatosEdicionDetalle) => {
    const _detalles = detalles.slice(0);
    _detalles.push(detalle);
    dispatch(updateDetalles({
      key: tabId,
      detalles: _detalles
    }))
    setAgregarDetalleMovil(false);
  }, [detalles, tabId, dispatch])

  const cambiarTransportistaSeleccionado = React.useCallback(async (proveedorORuc: string | ProveedorBusqueda | null) => {
    if (proveedorORuc == null) {
      dispatch(updateProveedor({
        proveedor: null,
        key: tabId
      }));
      return;
    }
    if (typeof proveedorORuc === 'string') {
      showEditLoader('Recuperando proveedor...');

    }
    const transportista = (typeof proveedorORuc === 'string') ? await ProveedoresService.getProveedor(proveedorORuc) : proveedorORuc;
    if (transportista) {
      dispatch(updateProveedor({
        proveedor: transportista,
        key: tabId
      }));
      //setCliente(cliente);
      //setRuc(cliente.identificacion);
    }
    hideEditLoader();
  }, [tabId, hideEditLoader, showEditLoader, dispatch]);

  const cambiarClienteSeleccionado = React.useCallback(async (clienteORuc: string | Cliente | null) => {
    setMostrarEdicionClientes(false);
    if (clienteORuc == null) {
      dispatch(updateCliente({
        cliente: null,
        key: tabId
      }));
      return;
    }
    if (typeof clienteORuc === 'string' && clienteORuc !== config.rucConsumidorFinal) {
      showEditLoader('Recuperando cliente...');

    }
    const cliente = (typeof clienteORuc === 'string') ? await ClientesService.getCliente(clienteORuc) : clienteORuc;
    if (cliente) {
      dispatch(updateCliente({
        cliente: cliente,
        key: tabId
      }));
    }
    hideEditLoader();
  }, [tabId, hideEditLoader, showEditLoader, dispatch]);


  const handlePrint = React.useCallback((design: boolean) => {
    console.log("handlePrint")
    console.log("claveAcceso", claveAcceso)
    setVerFacturaPdf({ claveAcceso: claveAcceso, design: design });
  }, [claveAcceso])

  const contentErroresValidacion = React.useMemo(() => {
    if (tieneErrorValidacion) {
      return <div className='dx-validationsummary dx-widget dx-collection'>
        {erroresValidacion.map(err => {
          return (
            <div className='dx-item dx-validationsummary-item' key={err}>
              <div className='dx-item-content dx-validationsummary-item-content'>{err}</div>
            </div>)
        })
        }
      </div>
    }
    return undefined;
  }, [tieneErrorValidacion, erroresValidacion]);

  const muiTableCellsChanged = React.useCallback((row, col, newValue) => {
    const _detalles = detalles.slice(0);
    const _detalle = _detalles[row];

    switch (col) {
      case 2://TABLECOL_DESCRIPCION
        _detalle.descripcionItem = newValue;
        //dt[row].descripcion = newValue;
        break;
      case 3://TABLECOL_CANTIDAD
        _detalle.cantidadItem = parseFloat(newValue);
        //dt[row].cantidad = newValue ? parseFloat(newValue) : 0;
        break;
      default:
        break;
    }
    _detalles[row] = _detalle;
    dispatch(updateDetalles({
      key: tabId,
      detalles: _detalles
    }))
  }, [detalles, tabId, dispatch]);

  const reenviarAutorizacion = React.useCallback(async () => {
    try {
      showEditLoader('Reenviando documento a autorizar...');
      await VentasService.reintentarAutorizacion(claveAcceso, TiposComprobantesSri.GuiaRemision);
      dispatch(addToast({
        content: 'El comprobante fue enviado al sri para su autorización.',
        type: ToastTypes.Info,
      }))
    } catch (error) {
      if (typeof (error) == 'string') {
        dispatch(addToast({
          content: error,
          type: ToastTypes.Danger,
        }))
      }
      console.log(error);
      //dispatch( )
    }
    hideEditLoader();
  }, [claveAcceso, showEditLoader, hideEditLoader, dispatch])

  const onModalItemsItemSelected = React.useCallback((item: ItemVenta) => {
    const detalle: GuiaDatosEdicionDetalle = {
      cantidadItem: 1,
      codigoBarras: item.codigoBarras,
      descripcionItem: item.descripcion,
      idItem: item.codigo
    }
    dispatch(addDetalle({
      key: tabId,
      detalle: detalle
    }))
    setMostrarBusquedaItems(false);
  }, [tabId, dispatch]);

  React.useEffect(() => {

    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(puntoVenta ?? "");
    } else {
      if (props.info) cargarVenta(props.info.numero, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      switch (tab.globalButtonClick) {
        case ButtonTypes.edit:
          modoEdicion();
          break;
        case ButtonTypes.save: {
          guardar();
          break;
        }
        case ButtonTypes.undo:
          deshacer();
          break;
        case ButtonTypes.print:
          handlePrint(false);
          break;
        case ButtonTypes.print_design:
          handlePrint(true);
          break;
        case ButtonTypes.sendMail:
          setShowReenviarMail(true);
          break;
        case ButtonTypes.authorizations:
          reenviarAutorizacion();
          break;
        case ButtonTypes.broom:
          modoNuevo(puntoVenta ?? "", true);
          break;
        default:
          break;
      }
      dispatch(clearButtonClick(tabId))
    }
  }, [tabId, deshacer, guardar, handlePrint, modoEdicion, modoNuevo, tab, puntoVenta, reenviarAutorizacion, dispatch]);



  React.useEffect(() => {
    const eliminarDetalle = (indice: number, detalles: Array<GuiaDatosEdicionDetalle>) => {
      detalles.splice(indice, 1);
      dispatch(updateDetalles({
        detalles: detalles,
        key: tabId
      }));
      setFilaEliminar(-1);
    };

    const eliminarDetalleConfirm = (indice: number) => {
      const detalle = detalles[indice];
      dialogRef.current.show({
        title: 'Eliminar detalle',
        body: `¿Desea eliminar el item ${detalle.descripcionItem}?`,
        actions: [
          Dialog.Action(
            <span><u>E</u>liminar</span>,
            () => {
              eliminarDetalle(indice, detalles.slice(0));
            },
            'btn-success',
            'e'
          ),
          Dialog.Action(
            <span><u>C</u>ancelar</span>,
            (dialog) => {
              setFilaEliminar(-1);
              dialog.hide()
            },
            'btn-danger',
            'c'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide();
          setFilaEliminar(-1);
          console.log('closed by clicking background.')
        }
      });
      return;
    };
    if (filaEliminar >= 0) {
      eliminarDetalleConfirm(filaEliminar);
    }
  }, [filaEliminar, detalles, tabId, dispatch])


  React.useEffect(() => {
    if (imprimir) {
      handlePrint(false);
    }
  }, [imprimir, handlePrint, tabId, dispatch])

  //#endregion

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }} >
      <BlockUi tag="div" loader={LoadingIndicator} blocking={loader.show} message={loader.mensaje}  >
        {showReenviarMail && cliente != null && (
          <PopupReenvioMail
            showConfirm={showReenviarMail}
            itemReenviarMail={{
              claveAcceso: claveAcceso,
              cliente: cliente,
            }}
            closeConfirm={() => setShowReenviarMail(false)}
          />
        )}
        {verSeleccionDocumentos &&
          <DocumentoVentaAfectar
            onCancel={() => {
              setVerSeleccionDocumentos(false);
            }}
            onOk={(documento: InfoDocumentoVentaAfectar) => {
              cambiarDocumentoOrigenFacturacion({
                comprobante: documento.codigo,
                fechaEmision: DateUtils.format(documento.fecha, formatoFechasApi),
                formaPagoCodigo: documento.formaPagoCodigo,
                numero: documento.numero,
                tipoDoc: documento.tipoComprobante,
                totalDocumento: documento.total
              });
              setVerSeleccionDocumentos(false);

            }}
            tipo={TiposComprobantesSri.GuiaRemision} />
        }
        {tab.editStatus === StatesEdition.save && verFacturaPdf &&
          <VerRIDE modo='DevExpressReport' claveAcceso={verFacturaPdf.claveAcceso} onClose={() => {
            dispatch(setImprimir({
              imprimir: false,
              key: tabId
            }))
            setVerFacturaPdf(undefined)
          }} vista={verFacturaPdf.design ? "Designer" : "Viewer"} />
        }
        {agregarDetalleMovil &&
          <AgregarDetalleGuiaMobile
            key='agregarDetalleVentaMovil'
            onOk={agregarDetalleVentaMobile}
            onCancel={() => setAgregarDetalleMovil(false)}
            rucCliente={cliente ? cliente.identificacion : config.rucConsumidorFinal}
          />
        }
        <Dialog ref={dialogRef} />
        {mostrarEdicionItems &&
          <ModificaItem
            transaccion={"ventas"}
            show={mostrarEdicionItems}
            codigoBarras={codigoBarrasItemEditar}
            onCancel={() => setMostrarEdicionItems(false)}
            onChanged={async (codigoBarras) => {
              setMostrarEdicionItems(false);
              setCodigoBarrasItemEditar('');
              if (codigoBarrasItemEditar === '') { //nuevo
                const items = await VentasService.buscarItemCodigoBarras(codigoBarras, cliente?.identificacion ?? config.rucConsumidorFinal, DateUtils.pickersDateToApiDate(fecha));
                if (items.length > 0) {
                  onModalItemsItemSelected(items[0]);
                }
              }
            }}
          />
        }
        {mostrarBusquedaItems === true && (
          <MainModalItems
            transaccion='ventas'
            show={mostrarBusquedaItems}
            cliente={cliente !== null ? cliente : config.rucConsumidorFinal}
            onCancel={() => setMostrarBusquedaItems(false)}
            returnItem={onModalItemsItemSelected}
          />
        )}
        <fieldset disabled={tab.editStatus === StatesEdition.save} >
          <ValidationGroup key={`valGroupRetencion${props.tabId}`} id={`valGroupRetencion${props.tabId}`} ref={validationGroupRef}>
            {(tieneError || tieneErrorValidacion) &&
              <VisualizaError titulo='Error en ventas' content={contentErroresValidacion} mensaje={mensajeError} onOk={() => {
                dispatch(setMuestraError({ key: props.tabId, tieneError: false, mensajeError: '' }))
                if (tieneErrorValidacion) {
                  settieneErrorValidacion(false);
                }
              }} />
            }
            {!isMobileOnly &&
              <RowContainer>
                <CustomCol>
                  <ValidationSummary />
                </CustomCol>
              </RowContainer>
            }
            <div>
              <RowContainer gutters={true} >
                <CustomCol xs='12' md='6'>
                  <RowContainer gutters={true}>
                    <CustomCol xs='12'>
                      <CCard
                        accentColor='info'
                        borderColor='info'
                      >
                        <CCardHeader>
                          <CBadge   >Indentificación del destinatario</CBadge>
                        </CCardHeader>
                        <CCardBody>
                          <RowContainer>
                            <CustomCol>
                              <BuscarClienteLookUp
                                selected={cliente}
                                onChanged={cambiarClienteSeleccionado}
                                disabled={tab.editStatus === StatesEdition.save || documentoOrigen != null}
                                allowAdd
                                allowClear
                                allowEdit >
                                <Validator>
                                  <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Destinatario")}  ></RequiredRule>
                                </Validator>
                              </BuscarClienteLookUp>
                            </CustomCol>
                          </RowContainer>
                        </CCardBody>
                      </CCard>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer gutters={true}>
                    <CustomCol xs='12'>
                      <CCard accentColor='info' borderColor='info'  >
                        <CCardHeader>
                          <CBadge>Identificación del Transportista</CBadge>
                        </CCardHeader>
                        <CCardBody  >
                          <RowContainer gutters={true}>
                            <CustomCol xs='12' md='12' lg='8'   >
                              <BuscarProveedorLookUp
                                esTransportista
                                selected={proveedor}
                                onChanged={(value) => cambiarTransportistaSeleccionado(value)}
                                disabled={tab.editStatus === StatesEdition.save}
                                allowClear allowAdd allowEdit
                              >
                                <Validator>
                                  <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Transportista")}  ></RequiredRule>
                                </Validator>
                              </BuscarProveedorLookUp>
                            </CustomCol>
                            <CustomCol xs='12' md='6' lg='4' xxl='3' >
                              <RowContainer gutters={false}>
                                <CustomCol xs='12'>
                                  <TextBox
                                    id='textBoxGuiaRemisionPlaca'
                                    value={placa}
                                    onValueChanged={({ value }) => dispatch(updatePlaca({ key: props.tabId, placa: value ?? '' }))}
                                    useMaskedValue={true}
                                    placeholder='Placa'
                                    maxLength={10}
                                  >
                                    <Validator>
                                      <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Placa")}  ></RequiredRule>
                                    </Validator>
                                  </TextBox>
                                </CustomCol>
                              </RowContainer>
                            </CustomCol>
                          </RowContainer>
                        </CCardBody>
                      </CCard>
                    </CustomCol>

                  </RowContainer>

                </CustomCol>
                <CustomCol xs='12' md='6'>
                  <div>
                    <RowContainer>
                      <CustomCol xs='12' md='6' xl='3' >
                        <Labeled
                          label='Cmp. de Ref'
                        >
                          {documentoOrigen &&
                            <small>{`FACTURA ${documentoOrigen?.numero}`}</small>
                          }
                          {!documentoOrigen &&
                            <Button
                              text='Buscar'
                              stylingMode='contained'
                              type='default'
                              icon='find'
                              onClick={() => {
                                setVerSeleccionDocumentos(true);
                              }}
                            >
                            </Button>
                          }
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs='12' md='6' xl='3'>
                        <Labeled
                          label='Fecha Guía'
                        >
                          <DateBox value={fecha} readOnly />
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs='12' md='6' xl='3'>
                        <Labeled
                          label='Número de guía'
                        >
                          <CRow gutters={false}>
                            <CCol xs='3' >
                              <TextBox readOnly placeholder='001' value={establecimiento} />
                            </CCol>
                            <CCol xs='3'>
                              <TextBox readOnly placeholder='001' value={puntoEmision} />
                            </CCol>
                            <CCol xs='6'>
                              <TextBox readOnly placeholder='0000000000' value={numeroGuia} />
                            </CCol>
                          </CRow>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol
                        xs='6' md='3'
                      >
                        <Labeled
                          label='# Detalles'
                        >
                          <TextBox
                            value={detalles.length.toString()}
                            readOnly
                          >
                            <Validator>
                              <RangeRule min={1} message={lh.getMessage(MessagesKeys.GlobalSinDetalles, "Guía de remisión")} />
                            </Validator>
                          </TextBox>
                        </Labeled>
                      </CustomCol>
                      <CustomCol
                        xs='6' md='3'
                      >
                        <Labeled
                          label='# Articulos'
                        >
                          <TextBox
                            readOnly
                            value={detalles.reduce((prev, next) => prev + next.cantidadItem, 0).toFixed(2)}
                          >

                          </TextBox>
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs='12' md='6'>
                        <Labeled
                          label='Punto Venta'
                        >
                          <TextBox
                            readOnly
                            value={puntoVenta ?? ''}
                          />
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    {((tab.editStatus === StatesEdition.save) || (autorizacion !== undefined && autorizacion.aut == null)) && //Electronica
                      <>
                        < RowContainer >
                          <CustomCol xs='12' xxl='8' >
                            {!isMobileOnly &&
                              <Barcode text={claveAcceso} />
                            }
                            {isMobileOnly &&
                              <Labeled
                                label='Autorización'
                              >
                                <small>{claveAcceso}</small>
                              </Labeled>
                            }

                          </CustomCol>
                          <CustomCol xs='12' xxl='4' >
                            <Labeled
                              label='Fecha Autorización'
                            >
                              <CBadge
                                color={fechaAutorizacion ? 'info' : 'danger'}
                              >
                                {fechaAutorizacion ? fechaAutorizacion : "No Autorizado"}
                              </CBadge>
                            </Labeled>
                          </CustomCol>
                        </RowContainer>
                      </>
                    }
                  </div>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CustomCol
                  xs='6'
                  lg='3'
                >
                  <Labeled
                    label='Inicio Traslado'
                  >
                    <DateBox
                      value={fechaDesde}
                      onValueChanged={({ value }) => dispatch(updateFechaPartida({
                        key: props.tabId,
                        fechaPartida: value
                      }))}
                    >

                    </DateBox>
                  </Labeled>
                </CustomCol>
                <CustomCol
                  xs='6'
                  lg='3'
                >
                  <Labeled
                    label='Fin Traslado'
                  >
                    <CInput
                      size='sm'
                      type='date'
                      value={fechaHasta}
                      onChange={(
                        ev: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        dispatch(updateFechaLlegada({
                          key: props.tabId,
                          fechaLlegada: DateUtils.dateToString(ev.target.valueAsDate ?? new Date(), formatoFechasDatePickers)
                        }))
                      }}
                    />
                  </Labeled>
                </CustomCol>
                <CustomCol
                  xs='6'
                  lg='3'
                >
                  <Labeled
                    label='Declaracion Aduanera'
                  >
                    <TextBox
                      id='textBoxDeclaracionAduanera'
                      value={declaracionAduanera}
                      onValueChanged={({ value }) =>
                        dispatch(updateDeclaracionAduanera({
                          key: props.tabId,
                          declaracionAduanera: value
                        }))}
                    >
                      <Validator>
                        <StringLengthRule max='45' message='- Declaracion Aduanera: Este campo no puede tener mas de 45 caracteres' />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
                <CustomCol
                  xs='6'
                  lg='3'
                >
                  <Labeled
                    label='Orden Ingreso'
                  >
                    <TextBox
                      id='textBoxOrdenIngreso'
                      value={ordenIngreso}
                      onValueChanged={({ value }) => dispatch(updateOrdenIngreso({
                        key: props.tabId,
                        ordenIngreso: value
                      }))}
                    >
                      <Validator>
                        <StringLengthRule max='45' message='- Orden Ingreso: Este campo no puede tener mas de 45 caracteres' />
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CustomCol>
              </RowContainer>
              <RowContainer>
                <CCol xs='12' lg='6' >
                  <Labeled
                    label='Punto de partida'
                  >
                    <TextBox
                      id='textBoxGuiaPuntoPartida'
                      value={puntoPartida}
                      onValueChanged={({ value }) => dispatch(updatePuntoPartida({
                        key: props.tabId,
                        puntoPartida: value
                      }))}
                    >
                      <Validator>
                        <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Punto de Partida")}  ></RequiredRule>
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CCol>
                <CCol xs='12' lg='6' >
                  <Labeled
                    label='Punto de llegada'
                  >
                    <TextBox
                      id='textBoxGuiaPuntoLlegada'
                      value={puntoLlegada}
                      onValueChanged={({ value }) => dispatch(updatePuntoLlegada({
                        key: props.tabId,
                        puntoLlegada: value
                      }))}
                    >
                      <Validator>
                        <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Punto de Llegada")}  ></RequiredRule>
                      </Validator>
                    </TextBox>
                  </Labeled>
                </CCol>
              </RowContainer>
              {tab.editStatus !== StatesEdition.save &&
                <RowContainer>
                  <CCol>
                    <div className='buttonsContainer' >
                      {isMobileOnly &&
                        <Button
                          text='Agregar Detalle'
                          icon='add'
                          stylingMode='contained'
                          type='default'
                          onClick={() => {
                            setAgregarDetalleMovil(true);
                          }}
                        />
                      }
                      <Button
                        style={{ marginLeft: '2px' }}
                        text='Crear Producto'
                        icon='file'
                        stylingMode='contained'
                        type='default'
                        onClick={() => {
                          setCodigoBarrasItemEditar('');
                          setMostrarEdicionItems(true);
                        }}
                      />
                      <Button
                        style={{ marginLeft: '2px' }}
                        hint='Más opciones'
                        icon='more'
                        stylingMode='contained'
                        type='default'
                        onClick={() => {
                          setMostrarBusquedaItems(true);
                        }}
                      >

                      </Button>
                    </div>
                  </CCol>
                </RowContainer>
              }

              {isMobileOnly && //Mobiles
                <RowContainer>
                  <div style={{ paddingTop: '5px' }}>
                    <MUIDataTable
                      data={detalles}
                      columns={getColumnnasTablaDetalleVentas(
                        muiTableCellsChanged,
                        (row) => setFilaEliminar(row)
                      )}
                      options={OptionsTablaDetalleVentas}
                    />
                  </div>
                </RowContainer>
              }
              {isMobile && !isMobileOnly &&  //Tablets
                < RowContainer >
                  <CCol xs='12'>

                    <div className='detallesTableContainer' style={{ maxHeight: `${window.innerHeight / 2}px` }}  >
                      <CustomSheet
                        striped
                        id='sheetDetalle'
                        blankRows={2}
                        columns={sheetColumns}
                        data={detalles}
                        editable={tab.editStatus !== StatesEdition.save}
                        initializeNewRow={(col, value) => {
                          const detalle: GuiaDatosEdicionDetalle = {
                            cantidadItem: 1,
                            codigoBarras: '',
                            descripcionItem: '',
                            idItem: 0
                          }
                          if (col.name === 'descripcionItem') {
                            const item = value as ItemVenta;
                            detalle.codigoBarras = item.codigoBarras;
                            detalle.descripcionItem = item.descripcion;
                            detalle.idItem = item.codigo;
                          } else {
                            detalle[col.name] = value;
                          }

                          return detalle;
                        }}
                        onCellChanged={sheetOnCellsChanged}
                        showRowNumber
                        rowCommands={sheetCommands}

                      />
                    </div>

                  </CCol>
                </RowContainer>
              }

              {!isMobile && //Escritorio
                < RowContainer >
                  <CCol xs='12'>
                    <div className='detallesTableContainer overflow-auto' onKeyDown={(e) => { }} style={{ width: window.innerWidth > 900 ? '100%' : `${window.innerWidth - 20}px`, maxHeight: `${window.innerHeight / 2}px` }}  >
                      <CustomSheet
                        striped
                        id='sheetDetalle'
                        blankRows={2}
                        columns={sheetColumns}
                        data={detalles}
                        editable={tab.editStatus !== StatesEdition.save}
                        initializeNewRow={(col, value) => {
                          const detalle: GuiaDatosEdicionDetalle = {
                            cantidadItem: 1,
                            codigoBarras: '',
                            descripcionItem: '',
                            idItem: 0
                          }
                          if (col.name === 'descripcionItem') {
                            const item = value as ItemVenta;
                            detalle.codigoBarras = item.codigoBarras;
                            detalle.descripcionItem = item.descripcion;
                            detalle.idItem = item.codigo;
                          } else {
                            detalle[col.name] = value;
                          }

                          return detalle;
                        }}
                        onCellChanged={sheetOnCellsChanged}
                        showRowNumber
                        rowCommands={sheetCommands}
                      />
                    </div>
                  </CCol>
                </RowContainer>
              }

              <RowContainer>
                <CustomCol xs='12' md={6}>
                  <Labeled
                    label='Motivo'
                  >
                    <TextArea value={motivo} onValueChanged={({ value }) => {
                      dispatch(updateMotivo({ key: props.tabId, motivo: value }))
                    }} >
                      <Validator>
                        <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Motivo")} />

                      </Validator>
                    </TextArea>
                  </Labeled>
                </CustomCol>

                <CustomCol xs='12' md={6}>
                  <Labeled
                    label='Observaciones'
                  >
                    <TextArea value={observaciones} onValueChanged={({ value }) => {
                      dispatch(updateObservaciones({ key: props.tabId, observaciones: value }))
                    }} >

                    </TextArea>
                  </Labeled>
                </CustomCol>
              </RowContainer>
            </div>
            {isMobileOnly &&
              <RowContainer>
                <CustomCol>
                  <ValidationSummary />
                </CustomCol>
              </RowContainer>
            }
          </ValidationGroup>
        </fieldset>

      </BlockUi>
    </div >
  );
};


const sheetColumns: Array<DataSheetColumn> = [
  {
    id: 1,
    colSpan: 1,
    name: 'codigoBarras',
    headerText: 'Código',
    width: '100px',
    order: 1,
    dataEditor: CustomSheetTextEditor,
    getReadOnly: (data) => {
      return data !== null;
    }
  },
  {
    id: 2,
    colSpan: 1,
    name: 'descripcionItem',
    headerText: 'Descripción',
    width: '20%',
    order: 2,
    getReadOnly: (data) => {
      return false;
    },
    getDataEditor: (data) => {
      return data == null ? SheetDescripcionItemSelectEditor : SheetTextEditor
    }
  },
  {
    id: 3,
    colSpan: 1,
    name: 'cantidadItem',
    headerText: 'Cant.',
    width: '60px',
    order: 3,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer
  },

];

export const defaultDatosEdicionGuias: GuiaDatosEdicion = {
  claveAcceso: '',
  cliente: null,
  despacho: 0,
  detalles: [],
  estadoSri: 0,
  fecha: DateUtils.getCurrentDateAsString(),
  fechaDesde: '',
  fechaHasta: '',
  guiaEmision: '',
  guiaEstablecimiento: '',
  idGuia: 0,
  idVenta: 0,
  loader: {
    mensaje: '',
    show: true
  },
  motivo: '',
  numero: '',
  observaciones: '',
  placa: '',
  proveedor: null,
  puntoLlegada: '',
  puntoPartida: '',
  venta: 0,
  mensajeError: '',
  tieneError: false,
  fechaAutorizacion: '',
  establecimiento: '',
  identificadorTransaccion: '',
  loading: true,
  puntoEmision: '',
  documentoOrigen: null,
  declaracionAduanera: '',
  ordenIngreso: '',
  imprimir: false,
  tieneErroresValidar: true
}

export const RemisionGuideButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: true,
  Imprimir: true,
  Descuento: true,
  Credito: true,
  Enviar: true,
  Autorizar: true

}

export default GuiaRemision;
