import React from 'react'
import {
  CCol,
  CContainer,
  CRow, CImg
} from '@coreui/react'

type MantenimientoProps = {
}

const Mantenimiento: React.FunctionComponent<MantenimientoProps> = props => {

  const [desde,] = React.useState<string>((process.env.REACT_APP_MANTENIMIENTO_DESDE) ?? "");
  const [hasta,] = React.useState<string>((process.env.REACT_APP_MANTENIMIENTO_HASTA) ?? "");

  React.useEffect(() => {
    const timer = window.setInterval(() => {
      console.log('10 second has passed');
      window.location.reload();
    }, 300000); // milisegundos  5min
    return () => { // Return callback to run on unmount.
      window.clearInterval(timer);
    };
  }, []);

  return (
    <div className="c-app c-default-layout ">
      <CContainer style={{

      }}>
        <CRow className="mt-4" >
          <CCol lg="10" md="10" sm="12" xs="12" className="mr-2 ml-2 d-flex justify-content-center" >
            <CImg
              src={"https://acatha.s3.us-west-2.amazonaws.com/LogosAcatha/logo_basic_acatha.png"}
              alt={"Acatha"}
            />
          </CCol>
        </CRow>
        <CRow >
          <CCol lg="10" md="10" sm="12" xs="12" className="mr-2 ml-2 d-flex justify-content-center" >
            <span className="clearfix">
              {/* <h1 className="float-left display-3 mr-4">!Oops</h1> */}
              <h4 className="pt-3">{"Mantenimiento en curso"}</h4>
              <p className="text-muted float-left">{"Mantenimiento programado desde "} {desde} {" hasta "} {hasta}.</p>
            </span>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}
export default Mantenimiento
