import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { number } from 'yup'
import { LoaderInfo } from '../../../../../../store/types'
import { Seleccionado } from '../../../../store/types'
import { statusForms } from '../../store/types'
import {
  ProtestosClientesState,
  ProtestosCliente,
  DetalleProtestosCliente,
  formularioBusqueda,
  DatosFormularioProtestoClientes
} from './types'

const initialState: ProtestosClientesState = {
  modulo: 'protestosClientes',
  codModulo: 0,
  registros: [],
  detalleProtestoClientes: [],
  dpTipoTransaccion: [],
  loader: {
    show: false,
    mensaje: ''
  },
  formularioBusqueda: {
    fechaDesde: "",
    fechaHasta: "",
    codigo: 0,
    numero: "",
    aceptado: "",
    tipo: 0,
    concepto: "",
    planCuentasCodigo: 0,
    fecha: ""
  },
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  currentFormulario: null,
  currentAction: 'Buscar',
  currentFunction: "",
  formularioEdicion: {
    estadoFormulario: statusForms.pending,
    diarioNumero: 0,
    fechaIngreso: "",
    fechaAuditoria: "",
    usuario: "",
    numeroDocumento: 0,
    numeroAsiento: 0,
    numeroTipo: 0,
    tipoDocumento: "",
    totalDebe: 0,
    totalHaber: 0,
    proyecto: null,
    local: null,
    codigoCompra: 0,
    codigoRetencion: 0,
    codigoIngreso: 0,
    descripcion: "",
    lote: 0,
    clienteCodigo: 0,
    clienteNombre: "",
    detalleAsiento: []
  }
}

const protestosClientesSlice = createSlice({
  name: 'protestosClientes',
  initialState: initialState,
  reducers: {
    setCodModulo(state, action: PayloadAction<number>) {
      state.codModulo = action.payload
    },
    setData(state, action: PayloadAction<Array<ProtestosCliente>>) {
      state.registros = action.payload
    },
    setDetalleProtestoClientes(state, action: PayloadAction<Array<DetalleProtestosCliente>>) {
      state.detalleProtestoClientes = action.payload
    },
    setDpTipoTransaccion(state, action: PayloadAction<Array<any>>) {
      state.dpTipoTransaccion = action.payload
    },
    changeLoaderProtestosClientes(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      state.formularioBusqueda = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      state.seleccionado = action.payload;
    },
    setResetSeleccionProtestosClientes(state) {
      let toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setCurrentFormulario(state, action: PayloadAction<any>) {
      state.currentFormulario = action.payload
    },
    setCurrentAccionProtestos(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setCurrentFunctionProtestos(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setFormularioEdicionProtestoClientes(state, action: PayloadAction<DatosFormularioProtestoClientes>) {
      state.formularioEdicion = action.payload
    },
  }
})
export const {
  setData, setDetalleProtestoClientes, setDpTipoTransaccion,
  changeLoaderProtestosClientes, setFormularioBusqueda, setSeleccionarDato,
  setResetSeleccionProtestosClientes, setCodModulo,
  setCurrentFormulario, setCurrentAccionProtestos, setCurrentFunctionProtestos,
  setFormularioEdicionProtestoClientes
} = protestosClientesSlice.actions
export const ProtestosClientesReducer = protestosClientesSlice.reducer
