
import { CustomDictionary, TabsStateModal, TabStateModal } from "../types/generics";

export const modalVentasClearDatosEdicion = (state, action) => {
  delete state[action.payload];
};

const getNewTabKeyOnClose = (
  state: TabsStateModal<any>,
  keyToClose: string
) => {
  let newCurrent = "";
  for (const key in state.tabs) {
    if (newCurrent === "") {
      newCurrent = key;
    }
    if (keyToClose === key) {
      return newCurrent;
    }
  }
  return "BUSQUEDA";
};

export const modalVentasCloseTab = (state, action) => {
  const newCurrent = getNewTabKeyOnClose(state, action.payload);
  state.current = newCurrent;
  delete state.tabs[action.payload];
};


export const modalExisteTab = (
  tabs: CustomDictionary<TabStateModal<any>>,
  comprobante: number
) => {
  for (const key in tabs) {
    const tab = tabs[key];
    if (tab.id === comprobante) return key;
  }
};


