import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OpenModal } from "../../../../../store/types";
import { formularioBusqueda, ModalClientesState, SeleccionCliente } from "./types";

const initialState: ModalClientesState = {
  modulo: 'modalClientes',
  currentAction: "Buscar",
  currentFunction: "",
  aplicacion: 'modalClientes',
  registros: [],
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  modal: {
    modalName: "",
    open: false,
    codModulo: 0,
    parametros: []
  },
  formularioBusqueda: {
    identificacion: "",
    nombre: "",
    razonComercial: "",
    empresa: "",
    activos: false
  }
}

const modalClientesSlice = createSlice({
  name: "modalClientes",
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setData(state, action: PayloadAction<Array<any>>) {
      state.registros = action.payload
    },
    setSeleccionarCliente(state, action: PayloadAction<SeleccionCliente>) {
      let toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      let toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setModalClientes(state, action: PayloadAction<OpenModal>) {
      state.modal = action.payload
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      state.formularioBusqueda = action.payload
    },
  }
})

export const {
  setCurrentAccion, setCurrentFunction, setData,
  setSeleccionarCliente, setResetSeleccion, setModalClientes,
  setFormularioBusqueda
} = modalClientesSlice.actions;
export const modalClientesReducer = modalClientesSlice.reducer;