import * as React from 'react';
import { DataSheetColumn, DataSheetColumnWithCustomOptions, DataSheetColumnWithOptions } from './dataSheetColumn';
import GenericCell from './genericCell';
import GenericDatasheet from './genericDatasheet';
import GenericSheetRenderer from './genericDataSheetRenderer';
import { range } from 'lodash'
import 'react-datasheet/lib/react-datasheet.css';
import ReactDataSheet from 'react-datasheet';
import { CellChangedArgs } from './cellChangedArgs';
import CustomSheetTextViewer from './viewers/sheetTextViewer';
import { CustomSheetGenericRow, RowCommand } from './genericRow';
import CustomSheetRowNumberViewer from './viewers/sheetRowNumberViewer';
import CustomSheetCommandViewer from './viewers/sheetCommandViewer';

interface ICustomSheetProps<T extends CustomSheetGenericRow<T>> {
  id: string,
  data: Array<T>,
  className?: string
  container?: React.Component,
  editable: boolean,
  blankRows: number,
  columns: Array<DataSheetColumn | DataSheetColumnWithOptions<any> | DataSheetColumnWithCustomOptions<any>>,
  onCellChanged: (arrayOfChanges: Array<CellChangedArgs<T>>) => void | Promise<void>,
  initializeNewRow: (col: DataSheetColumn, value: any) => T | Promise<T>,
  rowCommands?: Array<RowCommand<T>>,
  showRowNumber?: boolean,
  striped?: boolean,
  //selectionMode?: SelecionModes,
  //onSelectedCellsChanged?: (selectedCells: Array<SelectedCellsArgs>) => void,
  //onSelectedRowChanged?: (rowIndex: number) => void,
}


export enum SelecionModes {
  single,
  multiple
}

type SheetColsInfo = {
  cols: Array<DataSheetColumn | DataSheetColumnWithOptions<any>>,
  userColsStartIndex: number,
}

export function CustomSheet<T extends CustomSheetGenericRow<T>>(props: ICustomSheetProps<T>) {
  const [colsInfo, setColsInfo] = React.useState<SheetColsInfo>({ cols: [], userColsStartIndex: 0 });
  //const [selection, setSelection] = React.useState<ReactDataSheet.Selection>();
  //const [currentRow, setCurrentRow] = React.useState<number>(-1);
  const getOrderedCols = React.useCallback(() => {
    return props.columns.sort(function (a, b) {
      return a.order - b.order;
    });
  }, [props])


  // const selectionToSelectedCells = React.useCallback(() => {

  // }, []);


  // const onSelectionChanged = React.useCallback((_selection: ReactDataSheet.Selection) => {
  //   //console.log(_selection);
  //   // if (props.onSelectedRowChanged) {
  //   //   if (_selection.end.i !== currentRow) {
  //   //     props.onSelectedRowChanged(_selection.end.i)
  //   //     setCurrentRow(_selection.end.i);
  //   //   }
  //   // };
  //   //SetSelection({ ..._selection });
  //   // return;
  //   // if (props.onSelectedRowChanged) {
  //   //   if (_selection.start.i !== selection?.start.i) {
  //   //    // props.onSelectedRowChanged(_selection.start.i);
  //   //   }
  //   //   SetSelection(_selection);
  //   // }
  //   //console.log()
  //   //console.log(selection.start);
  //   // if (props.selectionMode && props.selectionMode == SelecionModes.multiple) {


  //   // } else if (props.selectionMode && props.selectionMode == SelecionModes.single) {

  //   // }
  //   // else {
  //   //   SetSelection(selection);
  //   // }
  //   // if (props.onSelectedCellsChanged) {
  //   //   props.onSelectedCellsChanged([]);
  //   // }
  //   // if (props.onSelectedRowChanged) {
  //   //   props.onSelectedRowChanged(selection.start.i);
  //   // }
  // }, [currentRow]);

  const transformDataAsCells = React.useCallback((data: Array<T>, editable: boolean, blankRows: number, colsInfo: SheetColsInfo) => {
    let sheetData: Array<Array<GenericCell<T>>> = [];
    //let index = 0;
    for (const _row of data) {
      const _rowCells: GenericCell<T>[] = [];
      for (const col of colsInfo.cols) {
        const cell: GenericCell<T> = {
          readOnly: !editable ? true : (col.readOnly === undefined ? (col.getReadOnly === undefined ? false : col.getReadOnly(_row)) : col.readOnly),
          dataEditor: col.getDataEditor === undefined ? col.dataEditor : col.getDataEditor(_row),
          valueViewer: col.getValueViewer === undefined ? (col.valueViewer ?? CustomSheetTextViewer) : col.getValueViewer,
          display: col.getDisplay === undefined ? _row[col.name] : col.getDisplay(_row[col.name], _row),
          value: _row[col.name]
        };

        // if (col.forceComponent) {
        //   cell.forceComponent = col.forceComponent;
        //   cell.valueViewer = undefined;
        //   //cell.display = '';
        // }
        if (col.name === 'colCommands' && props.rowCommands) {
          cell.commands = props.rowCommands;
        }
        if ('editorOptions' in col) {//Tiene ColumnOptions que deben pasarse al editor
          cell.editorOptions = col.editorOptions;
        }
        _rowCells.push(cell);
        //index++;
      }
      sheetData.push(_rowCells);
    }
    if (editable) {
      const emptyArray = range(blankRows);
      let rows: Array<Array<GenericCell<T>>> = [];
      for (const _row of emptyArray) {
        const _rowCells: GenericCell<T>[] = [];
        for (const col of colsInfo.cols) {
          const cell: GenericCell<T> = {
            readOnly: col.readOnly === undefined ? (col.getReadOnly === undefined ? false : col.getReadOnly(null)) : col.readOnly,
            dataEditor: col.getDataEditor === undefined ? col.dataEditor : col.getDataEditor(null),
            valueViewer: col.getValueViewer === undefined ? (col.valueViewer ?? CustomSheetTextViewer) : col.valueViewer,
            display: '',
            value: undefined
          };
          if ('editorOptions' in col) {//Tiene ColumnOptions que deben pasarse al editor
            cell.editorOptions = col.editorOptions;
          }
          _rowCells.push(cell);
          //index++;
        }
        sheetData.push(_rowCells);
      }
      // const rows = emptyArray.map(x => {
      //   return (
      //     cols.map(col => {
      //       const cell: GenericCell<T> = {
      //         readOnly: col.readOnly !== undefined ? (col.getReadOnly === undefined ? col.readOnly : col.getReadOnly()) : false,
      //         dataEditor: col.getDataEditor === undefined ? col.dataEditor : col.getDataEditor(),
      //         valueViewer: col.getValueViewer === undefined ? (col.valueViewer ?? undefined) : col.valueViewer,
      //         value: undefined,
      //         display: ""
      //       };
      //       return cell;
      //     })
      //   )
      // });
      sheetData = sheetData.concat(rows);
    }
    return sheetData;
  }, [props.rowCommands]);


  const onCellsChanged = React.useCallback(async (changes: ReactDataSheet.CellsChangedArgs<GenericCell<T>>) => {
    let mappedData: Array<CellChangedArgs<T>> = [];
    const orderedCols = colsInfo.cols; //getOrderedCols();
    for (const change of changes) {
      const _col = orderedCols[change.col]; //props.columns.find(x => x.order == (change.col)) ?? props.columns[0];
      const cellChangedArgs: CellChangedArgs<T> = {
        colIndex: change.col,
        rowIndex: props.data.length <= change.row ? -1 : change.row,
        rowData: props.data.length <= change.row ? ({} as T) : props.data[change.row],
        col: _col,
        newValue: change.value
      }
      if (props.data.length <= change.row) { //nueva
        cellChangedArgs.rowData = await props.initializeNewRow(_col, change.value);
      }
      mappedData.push(cellChangedArgs);
      //return cellChangedArgs;
    }
    props.onCellChanged(mappedData);
  }, [props, colsInfo])

  const renderSheet = React.useCallback((sheetProps) => {
    return <GenericSheetRenderer columns={colsInfo.cols} className=''  {...sheetProps} />
  }, [colsInfo]);

  const renderRows = (sheetProps) => {
    return (
      <tr className={props.striped ? (sheetProps.row % 2 === 0 ? 'customsheet-row-even' : 'customsheet-row-odd') : ''} >
        { sheetProps.children}
      </tr >
    )
    //return <VentaDetalleSheetRenderer columns={ColumnasGridVentaDetalle} className=''  {...props} />
  }

  React.useEffect(() => {
    const _cols: Array<DataSheetColumn> = [];
    let index = 0;
    if (props.showRowNumber) {
      _cols.push({
        id: -1,
        colSpan: 1,
        name: '',
        headerText: '#',
        width: '40px',
        order: index,
        readOnly: true,
        valueViewer: CustomSheetRowNumberViewer
      });
      index = index + 1;
    }

    if (props.rowCommands && props.rowCommands.length > 0) {
      _cols.push({
        id: 0,
        colSpan: 1,
        name: 'colCommands',
        headerText: '',
        width: '40px',
        order: index,
        readOnly: true,
        valueViewer: CustomSheetCommandViewer
      });
      index = index + 1;
    };
    const _startIndex = index;
    for (const _col of getOrderedCols()) {
      _cols.push({ ..._col, order: index });
      index = index + 1;
    }
    setColsInfo({ cols: _cols, userColsStartIndex: _startIndex });
  }, [props.columns]);

  return (
    <div>
      <GenericDatasheet
        data={transformDataAsCells(props.data, props.editable, props.blankRows, colsInfo)}
        valueRenderer={(cell) => cell.value}
        sheetRenderer={renderSheet}
        rowRenderer={renderRows}
        onCellsChanged={onCellsChanged}

      //onSelect={onSelectionChanged}
      >

      </GenericDatasheet>
    </div>
  );
}
