import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBackward,
  faCloudUpload,
  faDollarSign,
  faEdit,
  faEllipsisH,
  faFile,
  faForward,
  faKey,
  faPrint,
  faSave,
  faSearch,
  faSignOut,
  faStepBackward,
  faStepForward,
  faUndo,
  faTimes,
  faPlus,
  faInfo,
  faMinus,
  faTrash,
  faTrashUndo,
  faFolder,
  faFolderOpen,
  faBoxes,
  faCloudDownload,
  faSync,
  faCloudDownloadAlt,
  faBroom,
  faCloudUploadAlt,
  faArrowLeft,
  faArrowAltRight,
  faUpload,
  faDownload,
  faPercent,
  faBolt,
  faEnvelope,
  faCheckDouble,
  faChevronRight,
  faChevronLeft,
  faHandHoldingUsd,
  faCheck,
  faArrowUp,
  faList,
  faRedoAlt,
  faClipboardListCheck,
  faCopy,
  faSitemap,
  faListOl,
  faUser,
  faUserTie,
  faFastForward,
  faFastBackward,
  faCheckSquare,
  faMoneyCheckEditAlt,
  faArrowAltCircleLeft,
  faStream,
  faLock,
  faHandsUsd,
  faArrowFromTop,
  faArrowFromBottom,
  faRssSquare,
  faPlusSquare,
  faMinusSquare,
  faServer,
  faBoxUsd,
  faWallet,
  faFileInvoiceDollar,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faBagsShopping,
  faCreditCard,
  faBook,
  faMoneyCheck
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faBackward,
  faCloudUpload,
  faDollarSign,
  faEllipsisH,
  faFile,
  faForward,
  faKey,
  faSignOut,
  faStepBackward,
  faStepForward,
  faSave,
  faEdit,
  faSearch,
  faPrint,
  faUndo,
  faTimes,
  faPlus,
  faInfo,
  faMinus,
  faTrash,
  faTrashUndo,
  faFolder,
  faFolderOpen,
  faBoxes,
  faCloudDownload,
  faSync,
  faCloudDownloadAlt,
  faBroom,
  faCloudUploadAlt,
  faArrowLeft,
  faArrowAltRight,
  faUpload,
  faDownload,
  faPercent,
  faBolt,
  faEnvelope,
  faCheckDouble,
  faChevronRight,
  faChevronLeft,
  faHandHoldingUsd,
  faCheck,
  faArrowUp,
  faList,
  faRedoAlt,
  faClipboardListCheck,
  faCopy,
  faSitemap,
  faListOl,
  faUser,
  faUserTie,
  faFastForward,
  faFastBackward,
  faCheckSquare,
  faMoneyCheckEditAlt,
  faArrowAltCircleLeft,
  faStream,
  faLock,
  faHandsUsd,
  faArrowFromTop,
  faArrowFromBottom,
  faRssSquare,
  faPlusSquare,
  faMinusSquare,
  faServer,
  faBoxUsd,
  faWallet,
  faFileInvoiceDollar,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faBagsShopping,
  faCreditCard,
  faBook,
  faMoneyCheck
)

//fal fa-check-double
