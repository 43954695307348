import { CCol, CRow } from '@coreui/react';
import * as React from 'react';
import { RootState } from '../../../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid, {
  Column, HeaderFilter, Paging, Pager
} from 'devextreme-react/data-grid';
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../../../views/componentes/colContainer';
import { utilidades } from '../../../../../../../../../helpers/utilidades';
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize';
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper';
import { DateUtils, formatoFechasApi } from '../../../../../../../../../helpers/dateUtils';
import { CuadresService } from '../../../../../services/cuadres.service';
import { updateDpCierre5Only } from '../../../store/editDataReducer';

interface ILetrasProps {
  setToast: (texto, type) => void,
  playLoader: () => void,
  stopLoader: () => void,
  onErrorConfirm: (sms, title) => void,
  onConfirmarRegistro: (sms, method) => void
  tabId: string,
}

const Letras: React.FunctionComponent<ILetrasProps> = (props) => {
  const {
    tabId, setToast, playLoader, stopLoader,
    onErrorConfirm, onConfirmarRegistro
  } = props;
  const dataGrid = React.useRef<any>();

  const dispatch = useDispatch();
  const [heigth] = useGridResultadosSize();
  const local = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].local });
  const puntoVenta = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].puntoVenta });
  const fechaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fechaCierre });
  const venInicial5 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial5 });
  const venFinal5 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal5 });
  const dpcierre5 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre5 });
  const generarCuadre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre });

  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [showPageSizeSelector,] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>([]);

  const clearFilter = React.useCallback(() => {
    dataGrid.current.instance.clearSorting();
    dataGrid.current.instance.clearFilter();
  }, []);

  const llenarData = React.useCallback((data) => {
    clearFilter();
    let item: any = []
    let contar: number = 0;
    try {
      data.map(function (key, i) {
        let itemAdd: any = {}
        itemAdd = {
          ...key,
        }
        contar = contar + 1;
        item.push(itemAdd);
      })
      setData(item.map(x => utilidades.unfreeze(x)))
    } catch (error) {
      console.error("on llenarData", error)
    }
  }, [clearFilter]);



  const getLetrasCuadres = React.useCallback(async () => {
    console.log("getLetrasCuadres");
    let fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi);
    let dpCierreRet = await CuadresService.getLetrasCuadres(fechaFilter, local?.codigo ?? -1, puntoVenta?.codigoPuntoVenta ?? -1, venInicial5, venFinal5);
    console.log("getLetrasCuadres dpCierreRet", dpCierreRet);

    dispatch(updateDpCierre5Only({
      dpcierre5: dpCierreRet,
      key: tabId
    }));

  }, [
    dispatch, tabId, fechaCierre, local, puntoVenta, venInicial5, venFinal5
  ]);



  React.useEffect(() => {
    if (generarCuadre === true) {
      getLetrasCuadres();
    }
  }, [generarCuadre])

  React.useEffect(() => {
    llenarData(dpcierre5);
  }, [dpcierre5])

  return (
    <>


      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">



          <RowContainer >
            <CustomCol xs='12' md='12'   >

              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr='codigo'
                dataSource={data}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode='widget'
                height={heigth}
                ref={dataGrid}
              >
                <Paging defaultPageSize={20}/>
                <Pager
                  visible={data.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(data)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <HeaderFilter visible={true} />
                <Column dataField='letra' dataType='string' caption='Letra' width='25%' allowEditing={false} allowSearch={false} allowFiltering />
                <Column dataField='cuota' caption='Cuota' width='20%' allowEditing={false} visible={true} />
                <Column dataField='interes' caption='Interes' width='15%' allowEditing={true} visible={true} />
                <Column dataField='saldo' caption='Saldo' width='15%' allowEditing={false} visible={true} />
                <Column dataField='estadoDescripcion' caption='Estado' width='10%' allowEditing={false} visible={true} />

              </DataGrid>

            </CustomCol>
          </RowContainer>

        </CCol>
      </CRow>

    </>
  )
}
export default Letras;
