import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo } from "../../../../../store/types";
import { CustomDictionary } from "../../../types/generics";
import {
  Cliente,
  GuiaDatosEdicion,
  GuiaDatosEdicionDetalle,
} from "../../../types/types";
import {
  DocumentoOrigenFacturacion,
  FacturacionEdicionPayload,
} from "../../ventas/types/types";
import { ventasClearDatosEdicion } from "../../../store/reducers";
import {
  Proveedor,
  ProveedorBusqueda,
} from "../../../../proveedores/store/types";
export const guiasEditDataInitialState: CustomDictionary<GuiaDatosEdicion> = {};

const editDataSlice = createSlice({
  name: "guiasEditData",
  initialState: guiasEditDataInitialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<FacturacionEdicionPayload<GuiaDatosEdicion>>
    ) {
      state[action.payload.key] = action.payload.data;
    },
    updateDetalles(
      state,
      action: PayloadAction<{
        key: string | number;
        detalles: Array<GuiaDatosEdicionDetalle>;
      }>
    ) {
      state[action.payload.key].detalles = action.payload.detalles;
    },
    updateCliente(
      state,
      action: PayloadAction<{ key: string | number; cliente: Cliente | null }>
    ) {
      state[action.payload.key].cliente = action.payload.cliente;
      if (action.payload.cliente != null) {
        state[
          action.payload.key
        ].puntoLlegada = `${action.payload.cliente.ciudad}, ${action.payload.cliente.direccion}`;
      }
    },
    updateProveedor(
      state,
      action: PayloadAction<{
        key: string | number;
        proveedor: ProveedorBusqueda | null;
      }>
    ) {
      state[action.payload.key].proveedor = action.payload.proveedor;
      if (action.payload.proveedor == null) {
        state[action.payload.key].placa = "";
      }
    },
    updateObservaciones(
      state,
      action: PayloadAction<{ key: string | number; observaciones: string }>
    ) {
      state[action.payload.key].observaciones = action.payload.observaciones;
    },
    updateMotivo(
      state,
      action: PayloadAction<{ key: string | number; motivo: string }>
    ) {
      state[action.payload.key].motivo = action.payload.motivo;
    },
    updateFechaPartida(
      state,
      action: PayloadAction<{ key: string | number; fechaPartida: string }>
    ) {
      state[action.payload.key].fechaDesde = action.payload.fechaPartida;
    },
    updateFechaLlegada(
      state,
      action: PayloadAction<{ key: string | number; fechaLlegada: string }>
    ) {
      state[action.payload.key].fechaHasta = action.payload.fechaLlegada;
    },
    updateDeclaracionAduanera(
      state,
      action: PayloadAction<{
        key: string | number;
        declaracionAduanera: string;
      }>
    ) {
      state[action.payload.key].declaracionAduanera =
        action.payload.declaracionAduanera;
    },
    updateOrdenIngreso(
      state,
      action: PayloadAction<{ key: string | number; ordenIngreso: string }>
    ) {
      state[action.payload.key].ordenIngreso = action.payload.ordenIngreso;
    },
    updatePuntoPartida(
      state,
      action: PayloadAction<{ key: string | number; puntoPartida: string }>
    ) {
      state[action.payload.key].puntoPartida = action.payload.puntoPartida;
    },
    updatePuntoLlegada(
      state,
      action: PayloadAction<{ key: string | number; puntoLlegada: string }>
    ) {
      state[action.payload.key].puntoLlegada = action.payload.puntoLlegada;
    },
    updatePlaca(
      state,
      action: PayloadAction<{ key: string | number; placa: string }>
    ) {
      state[action.payload.key].placa = action.payload.placa;
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(
      state,
      action: PayloadAction<FacturacionEdicionPayload<GuiaDatosEdicion>>
    ) {
      state[action.payload.key] = action.payload.data;
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: string | number; info: LoaderInfo }>
    ) {
      state[action.payload.key].loader = action.payload.info;
    },
    updateDocumentoOrigen(
      state,
      action: PayloadAction<{
        key: string | number;
        documento: DocumentoOrigenFacturacion;
        detalles: Array<GuiaDatosEdicionDetalle>;
        cliente: Cliente;
      }>
    ) {
      state[action.payload.key].documentoOrigen = action.payload.documento;
      state[action.payload.key].cliente = action.payload.cliente;
      state[action.payload.key].detalles = action.payload.detalles;
      state[
        action.payload.key
      ].puntoLlegada = `${action.payload.cliente.ciudad}, ${action.payload.cliente.direccion}`;
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: string | number;
        tieneError: boolean;
        mensajeError: string;
      }>
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError;
      state[action.payload.key].mensajeError = action.payload.mensajeError;
    },
    setImprimir(
      state,
      action: PayloadAction<{ key: number | string; imprimir: boolean }>
    ) {
      state[action.payload.key].imprimir = action.payload.imprimir;
    },
    addDetalle(
      state,
      action: PayloadAction<{
        key: number | string;
        detalle: GuiaDatosEdicionDetalle;
      }>
    ) {
      state[action.payload.key].detalles.push(action.payload.detalle);
    },
    setClaveAccesoGuardada(
      state,
      action: PayloadAction<{
        key: string | number;
        claveAcceso: string;
        imprimir: boolean;
      }>
    ) {
      state[action.payload.key].claveAcceso = action.payload.claveAcceso;
      state[action.payload.key].imprimir = action.payload.imprimir;
    },
  },
});

export const {
  initDatosEdicion,
  setDatosEdicion,
  updateDetalles,
  updateCliente,
  updateObservaciones,
  setEditLoader,
  ventasClearDatosEdicion: clearDatosEdicion,
  updateFechaPartida,
  updateFechaLlegada,
  updateOrdenIngreso,
  updateDeclaracionAduanera,
  updatePuntoPartida,
  updatePuntoLlegada,
  updateProveedor,
  updatePlaca,
  updateDocumentoOrigen,
  setMuestraError,
  updateMotivo,
  setImprimir,
  addDetalle,
  setClaveAccesoGuardada
} = editDataSlice.actions;

export const editDataReducer = editDataSlice.reducer;
