import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccionMenu } from "../../../../store/types";
import { ModalOpen } from "../../../contabilidad/general/store/types";
import { ModalListadoComprobantesState, Registros } from "./types";

const initialState: ModalListadoComprobantesState = {
  modulo: 'modalListadoComprobantes',
  currentAction: "Buscar",
  currentFunction: "",
  aplicacion: 'modalListadoComprobantes',
  acciones: [],
  registros: [],
  refrescaTabla: false,
  modal: {
    open: false,
    name: "",
    parametros: [],
    codModulo: 0
  }
}

const modalListadoComprobantesSlice = createSlice({
  name: "ListadoComprobantes",
  initialState: initialState,
  reducers: {
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    obtenerAcciones(state, action: PayloadAction<Array<AccionMenu>>) {
      state.acciones = action.payload
    },
    setRegistros(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },

    setModalNovedades(state, action: PayloadAction<ModalOpen>) {
      let toAdd = {
        open: action.payload.open,
        name: action.payload.name,
        parametros: action.payload.parametros,
        codModulo: action.payload.codModulo
      }
      state.modal = toAdd
    },

  }
})

export const { setCurrentFunction, setModalNovedades, obtenerAcciones, setRegistros, setCurrentAction } = modalListadoComprobantesSlice.actions;
export const modalListadoComprobantesReducer = modalListadoComprobantesSlice.reducer;