import { DateUtils, formatoFechasApi } from "../../../../helpers/dateUtils";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { TiposComprobantesSri } from "../../../../store/types";
import { VentasService } from "../../services/ventas.service";
import { VentaListado } from "../../types/types";

export const VentasHelper = {
  comprobarAutorizacionYPuntoDeventa,
  getVoucherTypeFromAccesKey, comprobanteIva
};

async function comprobarAutorizacionYPuntoDeventa(
  puntoVenta: string | null,
  tipoComprobante: TiposComprobantesSri,
  setConfigurarPv,
  setConfigurarAutorizacion,
  setMensajeAutorizacion
) {
  if (puntoVenta == null) {
    setConfigurarPv(true);
    return false;
  }
  try {
    var autorizacion = await VentasService.getAutorizacion(
      puntoVenta,
      tipoComprobante
    );
  } catch (error) {
    setConfigurarAutorizacion(true);
    setMensajeAutorizacion(
      typeof error == "string" ? error : JSON.stringify(error)
    );
    return false;
  }

  return true;
}

function getFormatDate(voucherType: TiposComprobantesSri, infoComprobante) {
  let date: any = "";
  let pos: any = "";
  let date1: any = "";
  switch (voucherType) {
    case TiposComprobantesSri.NotaCredito:
      console.log("NotaCredito", DateUtils.strDateToDate(infoComprobante.invoiceDate, formatoFechasApi))
      pos = String(infoComprobante.invoiceDate).split("T");
      console.log("pos ", pos)
      date1 = new Date(String(pos[0]));
      console.log(date1)
      console.log("1", DateUtils.strDateToDate(pos[0], formatoFechasApi))
      date = DateUtils.dateToString(date1, formatoFechasApi);
      break;
    case TiposComprobantesSri.NotaDebito:
      console.log("NotaDebito", DateUtils.strDateToDate(infoComprobante.invoiceDate, formatoFechasApi))
      pos = String(infoComprobante.invoiceDate).split("T");
      console.log("pos ", pos)
      date1 = new Date(String(pos[0]));
      console.log(date1)
      console.log("1", DateUtils.strDateToDate(pos[0], formatoFechasApi))
      date = DateUtils.dateToString(date1, formatoFechasApi);
      break;
    case TiposComprobantesSri.Factura:
      console.log("Factura", DateUtils.strDateToDate(infoComprobante.invoiceDate, formatoFechasApi))
      pos = String(infoComprobante.invoiceDate).split("T");
      console.log("pos ", pos)
      date1 = new Date(String(pos[0]));
      console.log(date1)
      date = DateUtils.dateToString(date1, formatoFechasApi);
      break;
    case TiposComprobantesSri.Retencion:
      console.log("Retencion", DateUtils.strDateToDate(infoComprobante.date, formatoFechasApi))
      pos = String(infoComprobante.date).split("T");
      console.log("pos ", pos)
      date1 = new Date(String(pos[0]));
      console.log(date1)
      date = DateUtils.dateToString(date1, formatoFechasApi);
      break;
    case TiposComprobantesSri.GuiaRemision:
      console.log("GuiaRemision", DateUtils.strDateToDate(infoComprobante.date, formatoFechasApi))
      pos = String(infoComprobante.invoiceDate).split("T");
      console.log("pos ", pos)
      date1 = new Date(String(pos[0]));
      console.log(date1)
      date = DateUtils.dateToString(date1, formatoFechasApi);
      break;
    case TiposComprobantesSri.LiquidacionCompra:
      console.log("LiquidacionCompra", DateUtils.strDateToDate(infoComprobante.invoiceDate, formatoFechasApi))
      pos = String(infoComprobante.invoiceDate).split("T");
      console.log("pos ", pos)
      date1 = new Date(String(pos[0]));
      console.log(date1)
      date = DateUtils.dateToString(date1, formatoFechasApi);
      break;
    default:
      break;
  }
  return date;
}

async function comprobanteIva(invoiceData: any, voucherType: TiposComprobantesSri) {
  const sesion = SesionService.getCurrentSesion();
  let dateVoucher = getFormatDate(voucherType, invoiceData);
  try {
    let data: any = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      fecha: dateVoucher,
      establecimiento: invoiceData.establishment
    };
    const dataComprobante = await RequestHelper.get<any>('ventas', 'ivaEstablecimiento', '', data);
    return dataComprobante;
  } catch (error) {

    return false;
  }

}

function getVoucherTypeFromAccesKey(
  accesKey: string
): TiposComprobantesSri | undefined {
  if (accesKey && accesKey.length === 49) {
    const voucherType = accesKey.substr(8, 2);
    switch (voucherType) {
      case "01":
        return TiposComprobantesSri.Factura;
      case "03":
        return TiposComprobantesSri.LiquidacionCompra;
      case "04":
        return TiposComprobantesSri.NotaCredito;
      case "05":
        return TiposComprobantesSri.NotaDebito;
      case "06":
        return TiposComprobantesSri.GuiaRemision;
      case "07":
        return TiposComprobantesSri.Retencion;
      default:
        break;
    }
  }
  return undefined;
}
