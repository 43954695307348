import { DateUtils } from "../../../../../helpers/dateUtils";
import { ItemCompras } from "../../../types/types";
import { DetalleValidationResult } from "./types";

export class DetalleCompraRedux {
  codigo: number = 0;
  codigoLocal: number = 0;
  codigoInterno: string = "";
  codigoBarras: string = "";
  descripcion: string = "";
  tieneIva: boolean = false;
  porcentajeiva: number = 0;
  imagenes: Array<string> = [];
  fecha: string = "";
  _iva: number = 0;
  _subtotal: number = 0;
  _subtotal0: number = 0;
  _subtotalIva: number = 0;
  _total: number = 0;
  _cantidad: number = 0;
  _precioUnitario: number = 0;
  _precioIva: number = 0;
  _porcentajeDescuento: number = 0;
  _descuento: number = 0;
  constructor() { }
}

export class DetalleCompra {
  private _iva: number;
  private _subtotal: number;
  private _subtotal0: number;
  private _subtotalIva: number;
  private _total: number;
  private _cantidad: number = 0;
  private _precioUnitario: number = 0;
  private _precioIva: number = 0;
  private _porcentajeDescuento: number = 0;
  private _descuento: number = 0;

  codigo: number;
  codigoLocal: number;
  codigoInterno: string;
  codigoBarras: string;
  descripcion: string;
  tieneIva: boolean;
  porcentajeiva: number;
  imagenes: Array<string>;
  fecha: string;

  //#region cantidad
  get cantidad(): number {
    return this._cantidad;
  }
  set cantidad(newCantidad: number) {
    if (newCantidad < 0) {
      throw new Error("la cantidad no puede ser menor a cero");
    }
    this._cantidad = newCantidad;
    if (isNaN(newCantidad)) {
      this._cantidad = 0;
    }
    this.calcular();
  }
  //#endregion

  //#region precio
  get precio(): number {
    return this._precioUnitario;
  }

  set precio(newPrecio: number) {
    if (newPrecio < 0) {
      throw new Error("el precio no puede ser menor a cero");
    }
    this._precioUnitario = newPrecio;
    if (isNaN(newPrecio)) {
      this._precioUnitario = 0;
    }
    this.calcular();
  }
  //#endregion

  get descuento(): number {
    return this._descuento;
  }

  get porcentajeDescuento(): number {
    return this._porcentajeDescuento;
  }

  set porcentajeDescuento(newPorcentaje: number) {
    this._porcentajeDescuento = newPorcentaje;
    if (isNaN(newPorcentaje)) {
      this._porcentajeDescuento = 0;
    }
    this.calcular();
  }

  get subtotal(): number {
    return this._subtotal;
  }

  get subtotal0(): number {
    return this._subtotal0;
  }

  get subtotalIVA(): number {
    return this._subtotalIva;
  }

  get iva(): number {
    return this._iva;
  }

  get total(): number {
    return this._total;
  }

  get precioIva(): number {
    return this._precioIva;
  }

  set precioIva(newPrecioIva: number) {
    this._precioIva = newPrecioIva;
    if (isNaN(newPrecioIva)) {
      this._precioIva = 0;
      newPrecioIva = 0;
    }
    this._precioUnitario = this.redondear(
      this.tieneIva ? newPrecioIva / ((this.porcentajeiva + 100) / 100) : newPrecioIva
    );
    this.calcular();
  }

  constructor() {
    this.fecha = DateUtils.getCurrentDateAsString();
    this.codigo = 0;
    this.codigoLocal = -1;
    this.codigoInterno = "";
    this.codigoBarras = "";
    this.descripcion = "";
    this.tieneIva = false;
    this._iva = 0;
    this._subtotal0 = 0;
    this._subtotal = 0;
    this._total = 0;
    this._subtotalIva = 0;
    this.porcentajeiva = 0;
    this.imagenes = [];
  }

  // constructor(codigo: number, codigoInterno: string, codigoBarras: string, descripcion: string, porcentajeiva) {
  //   this.codigo = codigo;
  //   this.codigoInterno = codigoInterno;
  //   this.codigoBarras = codigoBarras;
  //   this.descripcion = descripcion;
  //   this.tieneIva = porcentajeiva > 0;
  //   this._iva = 0;
  //   this._subtotal0 = 0;
  //   this._subtotal = 0;
  //   this._total = 0;
  //   this._subtotalIva = 0;
  //   this.porcentajeiva = (porcentajeiva / 100);
  //   this.imagenes = [];
  // }

  public redondear(valor: number): number {
    return parseFloat(valor.toFixed(4));
  }

  public calcular() {
    const subtotalAntesDescuento = this.cantidad * this._precioUnitario;
    const descuento =
      (subtotalAntesDescuento * this.porcentajeDescuento) / 100.0;
    const subtotalNeto = subtotalAntesDescuento - descuento;
    this._subtotal0 = this.tieneIva ? 0 : subtotalNeto;
    this._subtotalIva = this.tieneIva ? subtotalNeto : 0;
    this._descuento = descuento;
    this._iva = this._subtotalIva * this.porcentajeiva;
    this._subtotal = this._subtotal0 + this._subtotalIva;
    this._total = this.subtotal + this._iva;
    this._precioIva =
      this.precio * (this.tieneIva ? 1 + this.porcentajeiva : 1);
  }

  public validate(): DetalleValidationResult {
    return { resultado: true };
  }

  public setDetalle(data: DetalleCompraRedux) {
    this.codigo = data.codigo;
    this.codigoLocal = data.codigoLocal;
    this.codigoInterno = data.codigoInterno;
    this.codigoBarras = data.codigoBarras;
    this.descripcion = data.descripcion;
    this.tieneIva = data.tieneIva;
    this.porcentajeiva = data.porcentajeiva;
    this.imagenes = data.imagenes;
    this.fecha = data.fecha;
    this._iva = data._iva;
    this._subtotal = data._subtotal;
    this._subtotal0 = data._subtotal0;
    this._subtotalIva = data._subtotalIva;
    this._total = data._total;
    this._cantidad = data._cantidad;
    this._precioUnitario = data._precioUnitario;
    this._precioIva = data._precioIva;
    this._porcentajeDescuento = data._porcentajeDescuento;
    this._descuento = data._descuento;
    if (this._total <= 0) {
      this.calcular();
    }
  }

  public getDetalle(): DetalleCompraRedux {
    const detalle: DetalleCompraRedux = {
      codigo: this.codigo,
      codigoLocal: this.codigoLocal,
      codigoInterno: this.codigoInterno,
      codigoBarras: this.codigoBarras,
      descripcion: this.descripcion,
      tieneIva: this.tieneIva,
      porcentajeiva: this.porcentajeiva,
      imagenes: this.imagenes,
      fecha: this.fecha,
      _iva: this.iva,
      _subtotal: this.subtotal,
      _subtotal0: this.subtotal0,
      _subtotalIva: this.subtotalIVA,
      _total: this.total,
      _cantidad: this.cantidad,
      _precioUnitario: this.precio,
      _precioIva: this.precioIva,
      _descuento: this.descuento,
      _porcentajeDescuento: this.porcentajeDescuento,
    };
    return detalle;
  }

  public setData(
    codigo: number,
    codigoLocal: number,
    codigoInterno: string,
    codigoBarras: string,
    descripcion: string,
    porcentajeiva
  ) {
    this.codigo = codigo;
    this.codigoLocal = codigoLocal;
    this.codigoInterno = codigoInterno;
    this.codigoBarras = codigoBarras;
    this.descripcion = descripcion;
    this.tieneIva = porcentajeiva > 0;
    this._iva = 0;
    this._subtotal0 = 0;
    this._subtotal = 0;
    this._total = 0;
    this._subtotalIva = 0;
    this.porcentajeiva = porcentajeiva / 100;
    this.imagenes = [];
  }

  public setItem(item: ItemCompras) {
    this.codigo = item.codigo;
    this.codigoLocal = item.codigoLocal ?? -1;
    this.codigoInterno = item.codigoInterno;
    this.codigoBarras = item.codigoBarras;
    this.descripcion = item.descripcion;
    this.tieneIva = item.porcentajeIva > 0;
    this.porcentajeiva = item.porcentajeIva / 100;
    this.precio = item.pvp;
    this.cantidad = this.cantidad == 0 ? 1 : this.cantidad;
    this.calcular();
  }
}
