import TextBox, { Button as TextBoxButton } from 'devextreme-react/text-box';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { lh } from '../../../../../helpers/localizationHelper';
import { useModalConvertSize } from '../../../../../hooks/useModalSize';
import { addToast } from '../../../../../store/toasterReducer';
import { ToastTypes } from '../../../../../store/types';
import CustomCol from '../../../../../views/componentes/colContainer';
import Container from '../../../../../views/componentes/container';
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput';
import { CustomModalDevx } from '../../../../../views/componentes/modal/Modal';
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer';
import { RetencionInfo } from '../../../types/types';

interface IAnularRetencioCompraProps {
  onOk: (info: RetencionInfo) => void,
  onCancel: () => void,
  info?: RetencionInfo
}

export const AnularRetencioCompra: React.FC<IAnularRetencioCompraProps> = (props) => {
  const dispatch = useDispatch();
  const [modalSize] = useModalConvertSize("sm");
  const { onOk, onCancel, info } = props;

  const copiarTexto = React.useCallback((idToCopy: string) => {
    var copyText = document.getElementById(idToCopy) as any;
    /* Select the text field */
    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      /* Copy the text inside the text field */
      document.execCommand("copy");
      dispatch(addToast({
        content: 'Copiado',
        type: ToastTypes.Success,
        autoHide: 1000
      }))
    } else {
      console.error("No se encuentra el elemento con id " + idToCopy + " para enlazar al TextBoxButtonCopy!!!")
    }


  }, [dispatch])

  const handleOnOk = React.useCallback(() => {
    if (info) onOk(info);
  }, [onOk, info]);

  if (info === undefined) {
    console.error("La informacion de documento a anular es null")
    return null;
  }

  return (
    <CustomModalDevx
      size={modalSize}
      title='Anular Retención Electrónica'
      onClose={onCancel}
      toolbarItems={[
        {
          toolbar: 'bottom',
          widget: 'dxButton',
          location: 'after',
          options: {
            text: 'Anular',
            type: 'default',
            onClick: handleOnOk
          }
        },
        {
          toolbar: 'bottom',
          widget: 'dxButton',
          location: 'after',
          options: {
            text: 'Cancelar',
            type: 'danger',
            onClick: onCancel
          }
        }
      ]}
      show
    >
      <Container>
        <RowContainer>
          <CustomCol
            sm='12'
            lg='6'
          >
            <Labeled
              label='Tipo Documento'
            >
              <TextBox readOnly value={"Retención"} />
            </Labeled>
          </CustomCol>
          <CustomCol
            sm='12'
            lg='6'
          >
            <Labeled
              label='Numero Documento'
            >
              <TextBox inputAttr={{ id: 'numeroDocumentoAnular' }} readOnly value={`${info.establecimiento}-${info.puntoEmision}-${info.numero}`} >
                <TextBoxButton
                  name="copy"
                  location="after"
                  options={{
                    onClick: () => copiarTexto("numeroDocumentoAnular"),
                    stylingMode: 'contained',
                    type: 'default',
                    hint: lh.getMessage("dxDiagram-commandCopy",),
                    icon: 'copy',
                    disabled: false
                  }}
                />
              </TextBox>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol>
            <Labeled
              label='Clave Acceso / Autorización'
            >
              <TextBox inputAttr={{ id: 'claveAccesoDocumentoAnular' }} readOnly value={info.claveAcceso} >
                <TextBoxButton
                  name="copy"
                  location="after"
                  options={{
                    onClick: () => copiarTexto("claveAccesoDocumentoAnular"),
                    stylingMode: 'contained',
                    type: 'default',
                    hint: lh.getMessage("dxDiagram-commandCopy",),
                    icon: 'copy',
                    disabled: false
                  }}
                />
              </TextBox>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol

          >
            <Labeled
              label='Fecha Emisión'
            >
              <TextBox readOnly value={info.fecha ?? ""} />
            </Labeled>
          </CustomCol>
          <CustomCol

          >
            <Labeled
              label='Fecha Autorización'
            >
              <TextBox readOnly value={info.fechaAutorizacion ?? ""} />
            </Labeled>
          </CustomCol>

        </RowContainer>
        <RowContainer>
          <CustomCol
            sm='12'
            lg='8'
          >
            <Labeled
              label='Proveedor'
            >
              <TextBox readOnly value={`${info.proveedorIdentificacion}-${info.proveedorNombre}`} />
            </Labeled>
          </CustomCol>
          <CustomCol
            sm='12'
            lg='4'
          >
            <Labeled
              label='Email Proveedor'
            >
              <TextBox inputAttr={{ id: 'emailClienteDocumentoAnular' }} readOnly value={info.proveedorEmail} >
                <TextBoxButton
                  name="copy"
                  location="after"
                  options={{
                    onClick: () => copiarTexto("emailClienteDocumentoAnular"),
                    stylingMode: 'contained',
                    type: 'default',
                    hint: lh.getMessage("dxDiagram-commandCopy",),
                    icon: 'copy',
                    disabled: false
                  }}
                />
              </TextBox>
            </Labeled>
          </CustomCol>
        </RowContainer>
      </Container>
    </CustomModalDevx>
  );
};
