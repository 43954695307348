import {
  InfoFacturaXml,
  InfoTributariaXml,
  XmlFactura,
  XmlFacturacionElectronicaAutorizacion,
} from "../modulos/compras/types/types";
import { TiposComprobantesSri } from "../store/types";
import { utilidades } from "./utilidades";

export const XmlFacturacionElectronicaHelper = {
  openFile,
  proccesXml,
};

async function openFile(file: any) {
  // const input = e.target;
  let text: any = "";
  const reader = new FileReader();
  const p = new Promise(async (resolve) => {
    while (true) {
      await utilidades.sleep(50);
      if (text) break;
    }
    resolve(text);
  });
  const onload = function (event) {
    text = reader.result;
    //parseFile(text);
  };
  reader.onload = onload;
  reader.readAsText(file);
  await p;
  if (typeof text == "string") {
    return text;
  }
  return undefined;
}

async function proccesXml(
  text: string
): Promise<XmlFacturacionElectronicaAutorizacion | undefined> {
  const parser = new DOMParser();
  const doc1 = parser.parseFromString(text, "application/xml");
  let nodes = doc1.childNodes;
  let autorizacionNode: ChildNode | null = null;
  let continuar: boolean = true;
  while (continuar) {
    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index];
      if (node.nodeName.toLowerCase() === "autorizacion") {
        autorizacionNode = node;
        continuar = false;
        break;
      }
      if (node.nodeName.toLowerCase() === "autorizaciones") {
        autorizacionNode = node.childNodes[0];
        continuar = false;
        break;
      }
    }
    if (continuar) {
      continuar = nodes.length > 0;
      nodes = nodes[0].childNodes;
    }
  }
  if (autorizacionNode === null || autorizacionNode === undefined) {
    return undefined;
  }
  const result: XmlFacturacionElectronicaAutorizacion = {
    ambiente: "",
    estado: "noautorizado",
    fechaAutorizacion: "",
    numeroAutorizacion: "",
    tipoComprobante: TiposComprobantesSri.Factura,
  };
  for (let index = 0; index < autorizacionNode.childNodes.length; index++) {
    const node = autorizacionNode.childNodes[index];
    const nodeName = node.nodeName.toLowerCase();

    switch (nodeName) {
      case "estado":
        result.estado =
          (node.textContent ?? "") === "AUTORIZADO"
            ? "autorizado"
            : "noautorizado";
        break;
      case "numeroautorizacion":
        result.numeroAutorizacion = node.textContent ?? "";
        break;
      case "fechaautorizacion":
        result.fechaAutorizacion = node.textContent ?? "";
        break;
      case "ambiente":
        result.ambiente =
          (node.textContent ?? "").substr(0, 4) === "PROD"
            ? "PRODUCCIÓN"
            : "PRUEBAS";
        break;
      case "tipocomprobante":
        result.tipoComprobante = (node.textContent ?? "") as any;
        break;
      case "comprobante":
        if (result.tipoComprobante === TiposComprobantesSri.Factura) {
          result.comprobante = proccessXmlFactura(node.textContent ?? "");
        }
        break;
      default:
        break;
    }
  }
  return result;
}

function proccessXmlFactura(text: string): XmlFactura | undefined {
  const parser = new DOMParser();
  const doc1 = parser.parseFromString(text, "application/xml");
  const facturaNode = doc1.childNodes[0];
  const result: XmlFactura = {
    infoFactura: {
      contribuyenteEspecial: 0,
      dirEstablecimiento: "",
      direccionComprador: "",
      fechaEmision: "",
      identificacionComprador: "",
      importeTotal: 0,
      moneda: "",
      obligadoContabilidad: false,
      pagos: [],
      propina: 0,
      razonSocialComprador: "",
      tipoIdentificacionComprador: "",
      totalConImpuestos: [],
      totalDescuento: 0,
      totalSinImpuestos: 0,
    },
    infoTributaria: {
      ambiente: "produccion",
      claveAcceso: "",
      codDoc: TiposComprobantesSri.Factura,
      dirMatriz: "",
      estab: "",
      nombreComercial: "",
      ptoEmi: "",
      razonSocial: "",
      ruc: "",
      secuencial: "",
      tipoEmision: 0,
    },
  };
  for (let index = 0; index < facturaNode.childNodes.length; index++) {
    const node = facturaNode.childNodes[index];
    if (node.nodeName.toLowerCase() === "infotributaria") {
      result.infoTributaria = parseInfoTributaria(node);
    }
    if (node.nodeName.toLowerCase() === "infofactura") {
      result.infoFactura = parseInfofactura(node);
    }
    if (node.nodeName.toLowerCase() === "detalles") {
      console.error("Implementar lectura de detalles en xml factura");
    }
  }
  return result;
}

function parseInfoTributaria(node: Node): InfoTributariaXml {
  let data: any = {};
  for (let index = 0; index < node.childNodes.length; index++) {
    const childNode = node.childNodes[index];
    const nodeName = childNode.nodeName.toLowerCase();
    if (nodeName !== "#text") {
      data[nodeName] = childNode.textContent;
    }
  }
  const result: InfoTributariaXml = {
    ambiente: data["ambiente"] === "2" ? "produccion" : "pruebas",
    claveAcceso: data["claveacceso"],
    codDoc: data["coddoc"],
    dirMatriz: data["dirmatriz"],
    estab: data["estab"],
    nombreComercial: data["nombrecomercial"],
    ptoEmi: data["ptoemi"],
    razonSocial: data["razonsocial"],
    ruc: data["ruc"],
    secuencial: data["secuencial"],
    tipoEmision: parseInt(data["tipoemision"]),
  };
  return result;
}

function parseInfofactura(node: Node): InfoFacturaXml {
  let data: any = {};
  for (let index = 0; index < node.childNodes.length; index++) {
    const childNode = node.childNodes[index];
    const nodeName = childNode.nodeName.toLowerCase();
    if (nodeName === "totalconimpuestos") {
      data["totalconimpuestos"] = [];
      let k = 0;
      for (
        let indexTotalConImpuesto = 0;
        indexTotalConImpuesto < childNode.childNodes.length;
        indexTotalConImpuesto++
      ) {
        const nodeTotalconimpuestos =
          childNode.childNodes[indexTotalConImpuesto];
        if (nodeTotalconimpuestos.nodeName.toLowerCase() !== "totalimpuesto") {
          continue;
        }
        data["totalconimpuestos"].push({});
        for (let j = 0; j < nodeTotalconimpuestos.childNodes.length; j++) {
          const element = nodeTotalconimpuestos.childNodes[j];
          if (element.nodeName.toLowerCase() !== "#text") {
            data["totalconimpuestos"][k][element.nodeName.toLocaleLowerCase()] =
              element.textContent;
          }
        }
        k++;
      }
    } else if (nodeName === "pagos") {
      data["pagos"] = [];
      let k = 0;
      for (
        let indexPago = 0;
        indexPago < childNode.childNodes.length;
        indexPago++
      ) {
        const pagoNode = childNode.childNodes[indexPago];
        if (pagoNode.nodeName !== "#text") {
          data["pagos"].push({});
          for (let j = 0; j < pagoNode.childNodes.length; j++) {
            const element = pagoNode.childNodes[j];

            data["pagos"][k][element.nodeName.toLowerCase()] =
              element.textContent;
          }
          k++;
        }
      }
    } else {
      data[nodeName] = childNode.textContent;
    }
  }
  const result: InfoFacturaXml = {
    contribuyenteEspecial: parseInt(data["contribuyenteespecial"] ?? 0),
    dirEstablecimiento: data.direstablecimiento,
    direccionComprador: data.direccioncomprador ?? "",
    fechaEmision: data.fechaemision,
    identificacionComprador: data.identificacioncomprador,
    importeTotal: parseFloat(data.importetotal),
    moneda: data.moneda,
    obligadoContabilidad: data.obligadocontabilidad.toLowerCase() === "si",
    pagos: data.pagos.map((x) => {
      return {
        formaPago: parseInt(x.formapago ?? 0),
        total: parseFloat(x.total ?? 0),
        plazo: parseInt(x.plazo ?? 0),
        unidadTiempo: x.unidadtiempo ?? "",
      };
    }),
    propina: parseFloat(data.propina ?? 0),
    razonSocialComprador: data.razonsocialcomprador,
    tipoIdentificacionComprador: data.tipoidentificacioncomprador,
    totalConImpuestos: data.totalconimpuestos.map((x) => {
      return {
        codigo: parseInt(x.codigo ?? 0),
        codigoPorcentaje: parseInt(x.codigoporcentaje ?? 0),
        baseImponible: parseFloat(x.baseimponible ?? 0),
        valor: parseFloat(x.valor ?? 0),
      };
    }),
    totalDescuento: parseFloat(data.totaldescuento ?? 0),
    totalSinImpuestos: parseFloat(data.totalsinimpuestos ?? 0),
  };
  return result;
}
