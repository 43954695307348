import { env } from "process";
import { ButtonTypes } from "../views/componentes/globalMenu/types";

let APP_ENV = "local"; // production, local

const config = {
  canalAcathaYoutube: "https://www.youtube.com/channel/UCPlDxZy895DRuQ-i-e3XVUA/videos?view=0&sort=dd&shelf_id=1",
  apiVersion: 'v4',
  key: "86758576376418652952657157128997", // Key para encriptar y desencriptar
  timeCaduca: 5, // tiempo en minutos, caduca codigo de verificacion 
  moduloAdministracion: 46,
  clientIdGoogle:
    "334737999039-ivi8qgsmi1f04q3l0fk19plfjqnlb0nr.apps.googleusercontent.com",
  uriPrintComprobantes: "https://www.acatha.com/edocs",
  vApiGeneral: "API/", // version del api externa que se esta utilizando
  vApiServerExterna: "API/v3/", // version del api externa que se esta utilizando
  vApiServer: "api/v1", // version del api Interna Backend laravel que se esta utilizando
  rucConsumidorFinal: env.NODE_ENV === 'development' ? "9999999999999" : '9999999999999',
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  SECRET_KEY: process.env.REACT_APP_SECRET_KEY,
  Authorization: "15y580dvCzHIM", // se utiliza en las apis que aun no tienen una sesion activa
  pathmodulo: {
    contabilidad: "contabilidad",
    ventas: "ventas",
    clientes: "clientes",
    inventarios: "inventarios",
    cuenta: "cuenta",
    dashboard: "dashboard"
  },
  APP_ENV: APP_ENV,
  typeView: "circular", // circular/button,
  variablesVentas: {
    codigoTipoProductoProducto: 1
  },
  modulos: [
    {
      id: 0,
      orden: 0,
      check: true,
      labelId: "ventas_cx",
      label: "VENTA",
      cod: "VENTA",
    },
    {
      id: 1,
      orden: 1,
      check: true,
      labelId: "aboclientes_cx",
      label: "ABONOS CLIENTES",
      cod: "ABONO CLIENTE",
    },
    {
      id: 2,
      orden: 2,
      check: true,
      labelId: "cventas_cx",
      label: "COSTO DE VENTAS",
      cod: "COSTO VENTA",
    },
    {
      id: 3,
      orden: 3,
      check: true,
      labelId: "dtercero_cx",
      label: "DEC. TERCERO",
      cod: "DECIMO TERCERO",
    },
    {
      id: 4,
      orden: 4,
      check: true,
      labelId: "compras_cx",
      label: "COMPRA",
      cod: "COMPRA",
    },

    {
      id: 5,
      orden: 5,
      check: true,
      labelId: "aboproveedores_cx",
      label: "ABONOS PROVEEDORES",
      cod: "ABONO PROVEEDOR",
    },
    {
      id: 6,
      orden: 6,
      check: true,
      labelId: "inventario_cx",
      label: "INVENTARIO",
      cod: "INVENTARIO",
    },
    {
      id: 7,
      orden: 7,
      check: true,
      labelId: "dcuarto_cx",
      label: "DEC. CUARTO",
      cod: "DECIMO CUARTO",
    },
    {
      id: 8,
      orden: 8,
      check: true,
      labelId: "retventas_cx",
      label: "RETENCION EN VENTAS",
      cod: "RETENCIONES VENTA",
    },
    {
      id: 9,
      orden: 9,
      check: true,
      labelId: "antclientes_cx",
      label: "ANTICIPOS CLIENTES",
      cod: "ANTICIPO CLIENTE",
    },

    {
      id: 10,
      orden: 10,
      check: true,
      labelId: "transferencias_cx",
      label: "TRANSFERENCIAS",
      cod: "TRANSFERENCIA",
    },
    {
      id: 11,
      orden: 11,
      check: true,
      labelId: "dasdai_cx",
      label: "IMPORTACION DAS/DAI",
      cod: "IMPORTACION DAU",
    },
    {
      id: 12,
      orden: 12,
      check: true,
      labelId: "retcompras_cx",
      label: "RETENCION EN COMPRAS",
      cod: "RETENCIONES COMPRA",
    },
    {
      id: 13,
      orden: 13,
      check: true,
      labelId: "antproveedores_cx",
      label: "ANTICIPOS PROVEEDORES",
      cod: "ANTICIPO PROVEEDOR",
    },
    {
      id: 14,
      orden: 14,
      check: true,
      labelId: "rpago_cx",
      label: "ROLES DE PAGO",
      cod: "ROL DE PAGOS",
    },

    {
      id: 15,
      orden: 15,
      check: true,
      labelId: "impcosteo_cx",
      label: "IMPORTACION COSTEO",
      cod: "IMPORTACION COSTEO",
    },
    {
      id: 16,
      orden: 16,
      check: true,
      labelId: "retrecap_cx",
      label: "RETENCION TARJETA CREDITO",
      cod: "TARJETAS DE CREDITO",
    },
    {
      id: 17,
      orden: 17,
      check: true,
      labelId: "depreciaciones_cx",
      label: "DEPRECIACIONES",
      cod: "DEPRECIACIONES",
    },
    {
      id: 18,
      orden: 18,
      check: true,
      labelId: "lrpago_cx",
      label: "LIQ. ROLES DE PAGO",
      cod: "LIQUIDACION ROL DE PAGOS",
    },
    {
      id: 19,
      orden: 19,
      check: true,
      labelId: "descuadre_cx",
      label: "POR DESCUADRE",
      cod: "DESCUADRE",
    },

    { id: 20, orden: 20, check: true, labelId: "todos_cx", label: "TODOS", cod: "TODOS" },
  ],
  menuDefecto: [
    { icon: 'fal fa-file', id: "Nuevo", text: "Nuevo", type: ButtonTypes.new },
    { icon: 'fal fa-save', id: "Guardar", text: "Guardar", type: ButtonTypes.save },
    { icon: 'fal fa-edit', id: "Editar", text: "Editar", type: ButtonTypes.edit },
    { icon: 'fal fa-search', id: "Buscar", text: "Buscar", type: ButtonTypes.find },
    { icon: 'fal fa-trash', id: "Eliminar", text: "Eliminar", type: ButtonTypes.delete },
    { icon: 'fal fa-undo', id: "Deshacer", text: "Deshacer", type: ButtonTypes.undo },
  ],
  menuDefectoSearch: [
    { icon: 'fal fa-search', id: "Buscar", text: "Buscar", type: ButtonTypes.find },
    { icon: 'fal fa-broom', id: "Limpiar", text: "Limpiar", type: ButtonTypes.broom },
    { icon: 'fal fa-times', id: "Salir", text: "Salir", type: ButtonTypes.times },
  ],
};

export default config;
