import { CButton, CSpinner } from '@coreui/react';
import { faSave } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface ISubmitButtonProps {
  isSubmitting: boolean,
  text: string,
  submittingText?: string,
  size?: 'sm' | 'md' | 'lg',
  onClick?: ()=> void
}

const SubmitButton: React.FunctionComponent<ISubmitButtonProps> = (props) => {
  return (
    <CButton
      color='primary'
      type='submit'
      size={props.size ?? 'sm'}
      disabled={props.isSubmitting}
      className="mr-1"
      onClick={props.onClick}
    >
      {props.isSubmitting ? <CSpinner color="info" size="sm" /> : <FontAwesomeIcon icon={faSave} />}
      <span style={{ marginLeft: '5px' }} >{props.isSubmitting ? (props.submittingText ?? props.text) : props.text}</span>
    </CButton>
  );
};

export default SubmitButton;
