import { useCallback, useEffect, useState } from "react";

export const useModalConvertSize = (targetSize: "md" | "sm" | "lg") => {
  const [size, setSize] = useState(targetSize);

  const getlgTargetSize = (): "md" | "sm" | "lg" => {
    if(window.innerWidth >=1600){
      return "sm";
    }
    if(window.innerWidth >=1400){
      return "md";
    }
    return "lg"
  }

  const getmdTargetSize = (): "md" | "sm" | "lg" => {
    if(window.innerWidth< 1300){
      return "lg"
    }
    return "md";
  }

  const getsmTargetSize = (): "md" | "sm" | "lg" => {
    return "sm";
  }

  const handleResize = useCallback(() => {
    if (targetSize === 'lg') {
      setSize(getlgTargetSize())
    }
    if (targetSize === 'md') {
      setSize(getmdTargetSize())
    }
    if (targetSize === 'sm') {
      setSize(getsmTargetSize())
    }
  }, [targetSize])

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return window.removeEventListener('resize', handleResize);
  }, [targetSize, handleResize])

  return [size]
}