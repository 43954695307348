import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { PagedResult, PaginationInfo } from "../../../../store/types";
import { DetalleComanda, OrdenesListado, FiltraComanda, SalonesComandas, OpcionMesa, OpcionMeseros, OpcionLineas, TOrdenIngresar } from "../types/types";

export const ComandasService = {
  deleteComanda, getInfoComanda, getComandas, getSalones, getDetalleComanda, getMesasSalon, getMesasOptions, getMeseros, getItemsDatasource, buscarItemsPaged, buscarLineasItemsPaged, buscarItemsMenuPaged, ingresarOrden
}

async function getInfoComanda(buscarOrdenes: FiltraComanda): Promise<OrdenesListado | null> {
  const sesion = SesionService.getCurrentSesion();
  const data: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    ordenCodigo: buscarOrdenes.codigo ?? '',
    ordenTipo: buscarOrdenes.tipo ?? '',
  };
  const comandas = await RequestHelper.get<OrdenesListado | null>('compras/documentos', 'ordenes/get', "", data);
  return comandas;
}

async function getComandas(buscarOrdenes): Promise<Array<OrdenesListado>> {
  const inicio = buscarOrdenes.fechaInicio; //fechaInicio.length > 0 ? moment(fechaInicio, "yyyy-MM-DD").format("DD/MM/yyyy") : "";
  const fin = buscarOrdenes.fechaFin; //fechaInicio.length > 0 ? moment(fechaFin, "yyyy-MM-DD").format("DD/MM/yyyy") : "";
  const sesion = SesionService.getCurrentSesion();
  const data: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    numero: buscarOrdenes.numero ?? '',
    tipo: buscarOrdenes.tipo ?? '',
    estado: buscarOrdenes.estado,
    fechaDesde: inicio,
    fechaHasta: fin,
    salonCodigo: buscarOrdenes.codigoSalon !== null ? buscarOrdenes.codigoSalon['codigo'] ?? 0 : 0
  };
  const comandas = await RequestHelper.get<Array<OrdenesListado>>('compras/documentos/general', 'orders/getAll', "", data);
  return comandas;
}

async function getSalones(): Promise<Array<SalonesComandas>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo
  };
  // const cacheKey = 'salonesOptions' + JSON.stringify(queryData);
  // const cached = sessionStorage.getItem(cacheKey);
  // if (cached) {
  //   return JSON.parse(cached);
  // } else {
  const apiData = await RequestHelper.get<Array<any>>('restaurante/salones', 'getAll', '', queryData);
  const salonesOptions: Array<SalonesComandas> = [];
  for (const salonApi of apiData) {
    salonesOptions.push({
      codigo: parseInt(salonApi.codigo),
      nombre: salonApi.nombre,
      ubicacion: salonApi.ubicacion,
      estado: parseInt(salonApi.estado),
      estadoDescripcion: salonApi.estadoDescripcion,
      numero: parseInt(salonApi.numero),
      mesasOpciones: salonApi.mesas.length > 0 ? salonApi.mesas : []
    });
  }
  // sessionStorage.setItem(cacheKey, JSON.stringify(salonesOptions));
  return salonesOptions;
  // }
}

async function getDetalleComanda(codigoComanda): Promise<Array<DetalleComanda>> {
  const sesion = SesionService.getCurrentSesion();
  const data: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    orden: codigoComanda,
  };
  const comandas = await RequestHelper.get<Array<DetalleComanda>>('generales/orders', 'getDetail', "", data);
  return comandas;
}

async function getMesasSalon(codigoSalon): Promise<Array<OpcionMesa>> {
  const sesion = SesionService.getCurrentSesion();
  const data: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    salon: codigoSalon,
  };
  const comandas = await RequestHelper.get<Array<OpcionMesa>>('restaurante/salones', 'table/getAll', "", data);
  return comandas;
}

async function getMesasOptions(codigoSalon): Promise<Array<OpcionMesa>> {
  const sesion = SesionService.getCurrentSesion();
  const data: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    salonCodigo: codigoSalon,
  };
  const comandas = await RequestHelper.get<Array<OpcionMesa>>('restaurante/salones', 'table/cboxload', "", data);
  return comandas;
}

async function getMeseros(): Promise<Array<OpcionMeseros>> {
  const sesion = SesionService.getCurrentSesion();
  const data: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo
  };
  const comandas = await RequestHelper.get<Array<OpcionMeseros>>('restaurante/salones', 'waiters/getAll', "", data);
  return comandas;
}

function getItemsDatasource() {
  const store = new CustomStore({
    key: "codigoBarras",
    byKey: async (key) => {
      // const item = await buscarItemCodigoBarras(key);
      // return item.length > 0 ? item[0] : undefined;
      //console.log(key);
    },
    load: async (loadOptions) => {
      console.log(loadOptions);
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      };
      let pagedResult: PagedResult<any>;
      pagedResult = await buscarItemsPaged(
        loadOptions.searchValue ?? "", paginInfo);
      console.log("current page ", pagedResult.currentPage);
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      };
    },
  });
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: "codigoBarras",
  });
  return source;
}

async function buscarItemsPaged(descripcion: string, pagination: PaginationInfo): Promise<PagedResult<any>> {
  const sesion = SesionService.getCurrentSesion();
  const query = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    local: sesion.local.codigo,
    limite: 0,
    count: pagination.size ?? 15,
    page: pagination.current,
    codigoLocalFactura: 1,
    descripcionItem: descripcion,
    isPaged: true,
    paged: 1,
    limit: pagination.size ?? 15,
  }
  const pagedApiResult = await RequestHelper.get<any>('restaurante/salones', 'items/localLimit', '', query);
  const itemsParsed = parseApiItemsData(pagedApiResult.auto);
  return {
    currentPage: parseInt(pagedApiResult.paginaActual),
    from: parseInt(pagedApiResult.desde),
    to: parseInt(pagedApiResult.hasta),
    count: parseInt(pagedApiResult.totalRegistros),
    result: itemsParsed,
  };
}

function parseApiItemsData(apiresult: Array<any>): Array<any> {
  const items = apiresult.map(x => {
    const item: any = {
      codigo: parseInt(x.codigo),
      barras: x.codigoBarras,
      codigoUsuario: x.codigoUsu,
      descripcion: x.descripcion,
      fecha: x.fecha,
      generico: x.generico,
      grupoDescripcion: x.grupoDescripcion,
      factor: parseFloat(x.itemFactor),
      lineaCodigo: parseInt(x.lineaCodigo),
      lineaDescripcion: x.lineaDescripcion,
      localizacion: x.localizacion,
      marca: parseInt(x.marca),
      marcaDescripcion: x.marcaDescripcion,
      tSurtido: x.tSurtido,
      tipoCodigo: parseInt(x.tipoCodigo),
      tipoDescripcion: x.tipoDescripcion,
      unidadc: parseInt(x.unidadc),
      unidadcDescripcion: x.unidadcDescripcion,
      unidadv: parseInt(x.unidadv),
      unidadvDescripcion: x.unidadvDescripcion,
      pvdiva: parseFloat(x.pvdIva),
      pvpiva: parseFloat(x.pvpIva),
      pvd: parseFloat(x.pvd),
      pvp: parseFloat(x.pvp),
      // codigoImpuesto: parseInt(x.impuesto),
      // tieneIce: (x.ice === '-1'),
      porcentajeIva: parseFloat(x.porcentajeIva),
      // costo: parseFloat(x.costo),
      numeroSerie: x.numeroSerie ?? 0,
      plantilla: parseInt(x.plantilla) ?? 0,
      imagen: x.imagen,
      // freeitem: parseInt(x.freeitem),
      // gradoAcoholico: parseInt(x.galcoholico),
      // capacidad: parseFloat(x.capacidad),
      // factor: parseFloat(x.factor),
      // jerarquia: x.jerarquia,
      // descuentos: parseFloat(x.descuentos),
      // titadicional: x.titadicional,
      // valadicional: x.valadicional,
      // grupo: x.grupo,
      // codigoUnidadVenta: parseInt(x.unidadv),
      // tipoDescripcion: x.tipo_descripcion,
      // codigoGrupo: parseInt(x.codgrupo)
    };
    return item;
  });
  return items;
}

async function buscarLineasItemsPaged(pagination: PaginationInfo): Promise<PagedResult<any>> {
  console.log("buscarLineasItemsPaged", pagination)
  const sesion = SesionService.getCurrentSesion();
  const query = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    local: sesion.local.codigo,
    limite: 0,
    count: pagination.size ?? 15,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size ?? 15,
    paged: 1
  }
  const pagedApiResult = await RequestHelper.get<any>('restaurante/salones', 'line/loadLinesMenu', '', query);
  const itemsParsed = pagedApiResult.auto;
  return {
    currentPage: parseInt(pagedApiResult.paginaActual),
    from: parseInt(pagedApiResult.desde),
    to: parseInt(pagedApiResult.hasta),
    count: parseInt(pagedApiResult.totalRegistros),
    result: itemsParsed,
  };
}

async function buscarItemsMenuPaged(linea: OpcionLineas, pagination: PaginationInfo): Promise<PagedResult<any>> {
  const sesion = SesionService.getCurrentSesion();
  const query = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    local: sesion.local.codigo,
    limite: 0,
    count: 15,
    isPaged: true,
    page: pagination.current,
    limit: 15,
    paged: 1,
    grupo: null,
    linea: linea.codigo,
    tipoCliente: 1//TCL_CODIGO
  }
  const pagedApiResult = await RequestHelper.get<any>('restaurante/salones', 'loadItemsMenu', '', query);
  const itemsParsed = pagedApiResult.auto;
  return {
    currentPage: parseInt(pagedApiResult.paginaActual),
    from: parseInt(pagedApiResult.desde),
    to: parseInt(pagedApiResult.hasta),
    count: parseInt(pagedApiResult.totalRegistros),
    result: itemsParsed,
  };
}

async function ingresarOrden(orden: TOrdenIngresar): Promise<Promise<any>> {
  const sesion = SesionService.getCurrentSesion();
  try {
    let infoRegistro = {
      infoEmpresa: {
        ruc: sesion.empresa.ruc
      },
      infoUsuario: {
        usuario: sesion.usuario.codigo
      },
      infoRegistro: orden
    }
    const creada = await RequestHelper.postAll<any>("ordenes", "save", infoRegistro);
    return creada;
  } catch (error) {
    return error;
  }
}

async function deleteComanda(idComanda: any): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    let objRequest = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      ordenCodigo: idComanda
    }
    const data = await RequestHelper.deleteRequestAll<any>('compras/documentos', 'ordenes/inactive', "", objRequest);
    return data;
  } catch (error) {
    return error;
  }
}