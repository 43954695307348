import * as React from 'react';
import Mantenimiento from "./views/pages/mantenimiento/index";

const App = React.lazy(() => import('./App'));

interface IAppContainerProps {
  mantenimiento: boolean
}
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

export function AppContainer(props: IAppContainerProps) {
  const { mantenimiento } = props;

  if (mantenimiento) {
    return < Mantenimiento />
  }

  return (
    <React.Suspense fallback={loading}>
      <App />
    </React.Suspense>
  );
}
