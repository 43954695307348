import * as React from 'react';
import {
  CAlert, CCol,
} from '@coreui/react';
import { TipoIva, UnidadVenta } from '../../../../../types/types';
import { VentasService } from '../../../../../services/ventas.service';
import RowContainer from '../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../views/componentes/colContainer';
import Barcode from '../../../../../../../views/componentes/barcode/barcode';
import Labeled from '../../../../../../../views/componentes/labeledInput/labeledInput';
import { InventarioService } from '../../../../../../../modulos/inventario/services/inventario.service'
import * as InventarioTypes from '../../../../../../inventario/store/types';
import ValidationSummary from 'devextreme-react/validation-summary';
import TextBox from 'devextreme-react/text-box';
import {
  Validator,
  RequiredRule,
  PatternRule,
  NumericRule,
  AsyncRule
} from 'devextreme-react/validator';
import NumberBox from 'devextreme-react/number-box';
import Switch from 'devextreme-react/switch';
import { BuscarLineaLookUp } from '../../../../../../inventario/components/buscarLinea/buscarLinea';
import { BuscarGrupoLookUp } from '../../../../../../inventario/components/buscarGrupo/buscarGrupo';
import { BuscarMarcaLookUp } from '../../../../../../inventario/components/buscarMarca/buscarMarca';
import { BuscarIceLookUp } from '../../../../../../inventario/components/buscarIce/buscarIce';
import { BuscarUnidadLookUp } from '../../../../../../inventario/components/buscarUnidad/buscarUnidad';
import { addToast } from '../../../../../../../store/toasterReducer';
import { ToastTypes } from '../../../../../../../store/types';
import { lh, MessagesKeys } from '../../../../../../../helpers/localizationHelper';
import ValidationGroup from 'devextreme-react/validation-group';
import { isMobileOnly } from 'react-device-detect';
import { PopupContent } from '../../../../../../../views/componentes/popupContent';
import { utilidades } from '../../../../../../../helpers/utilidades';
import { LoadPanel } from 'devextreme-react/load-panel';
import { RootState } from '../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux'
import { changeLoader } from '../../../../../../../store/reducers';

const FormularioItem = (props: IFormularioItemProps) => {

  const { codigoBarras, funcion, resetFunction, currentTab, transaccion } = props;

  const validationGroupRef = React.useRef<any>();

  const empresaSession = useSelector((state: RootState) => state.global.session?.empresa);


  const dispatch = useDispatch();

  const loader = useSelector((state: RootState) => state.global.loader);
  const [esNuevo, setEsnuevo] = React.useState(false);
  const [errorInfo, seterrorInfo,] = React.useState<ErrorInfo>({ tieneError: false });
  const [itemModificar, setItemModificar] = React.useState<InventarioTypes.ItemInventario | null>(null);
  const [unidades, setUnidades] = React.useState<Array<UnidadVenta>>([]);
  const [datos, setDatos] = React.useState<DatosModificarItem>(datosModificarItemDefaultValue);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [tiposIva, setTiposIva] = React.useState<Array<TipoIva>>([]);
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false);
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>([]);


  React.useEffect(() => {
    if (funcion === 'Guardar') {
      resetFunction();
      handleSubmit(datos);
    } else if (funcion === "Deshacer") {
      if (currentTab === "Editar") {
        resetFunction();
        cargarItem(codigoBarras);
      } else if (currentTab === "Nuevo") {
        resetFunction();
        resetItem();
      }
    }
  }, [funcion]);




  const handleSubmit = async (values: DatosModificarItem) => {
    setIsSubmitting(true);
    playLoader();
    try {
      let validationResult = await validationGroupRef.current.instance.validate();

      if (validationResult.isValid === false) {
        stopLoader();
        setShowErrorPopup(true)
        setShowErrorMessages(validationResult.brokenRules)
        return false;
      }
      const fnGuardar = async () => {
        console.log("fnGuardar", values)
        try {
          let tipoItem = 1;
          if (transaccion === 'ventas') {
            tipoItem = values.servicio ? 2 : 1;
          } else if (transaccion === 'compras') {
            tipoItem = 1;
          }

          let restRegistro = await VentasService.modificarItem({
            codigo: esNuevo ? 0 : (itemModificar?.itemCodigo ?? 0),
            descripcion: values.descripcion,
            codigoBarras: values.codigoBarras,
            pvp: values.pvp,
            pvd: values.pvd,
            codigoImpuesto: values.impuesto === true ? 3 : 2,
            tipoCodigo: tipoItem,
            codigoUnidadVenta: values.unidad ?? 0,
            codigoGrupo: (values.grupo?.codigo ?? 0),
            codigoLinea: (values.linea?.codigo ?? 1),
            codigoMarca: (values.marca?.codigo ?? 0),
            iceTipoIce: values.iceTipoIce ?? 0,
            iceCodigoGradoAlcoholico: values.iceCodigoGradoAlcoholico,
            iceGradoAlcoholico: values.iceGradoAlcoholico,
            iceCapacidaCm3: values.iceCapacidaCm3,
            iceCodigoImpuesto: values.iceCodigoImpuesto,
            iceCodigoClasificacion: values.iceCodigoClasificacion,
            iceCodigoMarca: values.iceCodigoMarca,
            iceEstado: values.iceEstado,
            iceCodigoPresentacion: values.iceCodigoPresentacion,
            iceCodigoUnidad: values.iceCodigoUnidad,
            iceGramosAzucar: values.iceGramosAzucar,
            ice: values.ice
          });
          stopLoader();
          dispatch(addToast({
            autoHide: true,
            content: esNuevo === true ? 'Registro Satisfactorio.' : 'Item actualizado',
            fade: true,
            id: '',
            title: 'Guardar Item',
            type: ToastTypes.Success
          }));
          if (props.onChanged) {
            props.onChanged(values.codigoBarras);
          }

        } catch (error) {
          stopLoader();
          console.log(error);
          dispatch(addToast({
            autoHide: true,
            content: error,
            fade: true,
            id: '',
            title: 'Guardar Item',
            type: ToastTypes.Danger
          }))
        }

      };


      if (validationResult.complete) {
        validationResult.complete.then(async resolve => {
          await resolve;
          if (!resolve.isValid) {
            stopLoader();
            setIsSubmitting(false);
            return;
          }
          fnGuardar();
        })
      } else {
        if (!validationResult.isValid) {
          setIsSubmitting(false);
          return;
        } else {
          fnGuardar();
        }
      }
    } catch (error) {
      stopLoader();
      console.log(error);
      dispatch(addToast({
        autoHide: true,
        content: error,
        fade: true,
        id: '',
        title: 'Guardar Item',
        type: ToastTypes.Danger
      }))
    }

  }

  const playLoader = () => {
    dispatch(changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(changeLoader({ show: false, mensaje: '' }))
  }

  const resetItem = React.useCallback(async () => {
    playLoader()

    // setLoading(true);
    const unidades = await VentasService.getUnidadesventa();
    const tiposIvas = await VentasService.getTiposIva();

    setDatos({
      codigoBarras: '',
      descripcion: '',
      pvp: 0,
      pvd: 0,
      impuesto: true,
      ice: false,
      // iceTipoIce: 0,
      iceCodigoGradoAlcoholico: '',
      iceGradoAlcoholico: 0,
      iceCapacidaCm3: 0,
      iceCodigoImpuesto: '',
      iceCodigoClasificacion: '',
      iceCodigoMarca: '',
      iceEstado: '',
      iceCodigoPresentacion: '',
      iceCodigoUnidad: '',
      iceGramosAzucar: 0
    })

    stopLoader();
    setEsnuevo(true);
    setUnidades(unidades);
    // setGrupos(grupos);
    setTiposIva(tiposIvas);
    validationGroupRef.current.instance.reset()
    stopLoader();

  }, []);

  const cargarItem = React.useCallback(async (codigoBarras: string) => {
    playLoader()
    try {
      // setLoading(true);

      validationGroupRef.current.instance.reset()
      const unidades = await VentasService.getUnidadesventa();
      const tiposIvas = await VentasService.getTiposIva();
      if (codigoBarras.length > 0) {
        const producto = await InventarioService.getItemXCodigoBarras(codigoBarras);
        if (producto) {
          let pvp = producto.itemPVP;
          let pvd = producto.itemPVD;
          if (producto.itemImpuestoCodigo === 3) {
            pvp = producto.itemPVPIVA;
            pvd = producto.itemPVDIVA;
          }
          setItemModificar(producto);
          setDatos({
            codigoBarras: producto.itemBarras,
            descripcion: producto.itemDescripcion,
            pvd: pvd,
            pvp: pvp,
            iceCapacidaCm3: parseFloat(producto.iceCapacidaCm3),
            iceCodigoClasificacion: producto.iceCodigoClasificacion,
            iceCodigoGradoAlcoholico: producto.iceCodigoGradoAlcoholico,
            iceCodigoImpuesto: producto.iceCodigoImpuesto,
            iceCodigoMarca: producto.iceCodigoMarca,
            iceCodigoPresentacion: producto.iceCodigoPresentacion,
            iceCodigoUnidad: producto.iceCodigoUnidad,
            iceEstado: producto.iceEstado,
            iceGradoAlcoholico: parseFloat(producto.iceGradoAlcoholico),
            iceGramosAzucar: parseFloat(producto.iceGramosAzucar),
            iceTipoIce: producto.itemIce,// producto.ice 0, //producto.iceTipoIce,
            ice: producto.itemIce > 0 ? true : false, // producto.ice,
            grupo: { codigo: producto.itemGrupoCodigo, descripcion: '', estadoCodigo: 0, estadoNombre: '', items: 0, perteneceCodigo: 0, perteneceNombre: '' },
            // impuesto: { codigo: producto.itemImpuestoCodigo, descripcion: '', valor: 0 },
            impuesto: producto.itemImpuestoCodigo === 3 ? true : false,
            linea: { codigo: producto.itemLineaCodigo, descripcion: '' },
            marca: { codigo: producto.itemMarcaCodigo, nombre: '' },
            unidad: producto.itemUnidadVentaCodigo,
            servicio: producto.itemTipo === 2 ? true : false
          })
          stopLoader();
        } else {
          stopLoader();
          // eslint-disable-next-line no-throw-literal
          throw 'Error al cargar el producto, ningún item fue devuelto con el código de barras ' + codigoBarras;
        }
      } else {
        console.log("codigoBarras", codigoBarras)
        setDatos({ ...datos, unidad: 34, servicio: true })

        stopLoader();
        setEsnuevo(true);
        setUnidades(unidades);
        // setGrupos(grupos);
        setTiposIva(tiposIvas);
      }
    } catch (error) {
      stopLoader();
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al cargar ITEM. Error:\n' + error,
        fade: true,
        title: 'Item',
        type: ToastTypes.Danger
      }));
      // if (props.onCancel) {
      //   props.onCancel();
      // }
    }
    stopLoader();

  }, [dispatch]);

  const loaderGlobal = () => {
    return (
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={'center'}
        visible={loader.show}
        showIndicator={true}
        shading={true}
        showPane={true}
        message='Buscando...'
        closeOnOutsideClick={false}
      />
    )
  }

  const validateAsyncSeleccionUnidad = React.useCallback((datoSeleccion) => {
    if (datoSeleccion['value']) {
      return Promise.resolve();
    } else {
      return Promise.reject("Unidad : Debe seleccionar una opción.");
    }
  }, []);

  React.useEffect(() => {
    cargarItem(codigoBarras);
  }, [codigoBarras, cargarItem]);

  return (
    <>

      {loaderGlobal()}
      <ValidationGroup id={`valGroupModificarItem`} ref={validationGroupRef}>
        {!isMobileOnly &&
          <RowContainer>
            <CustomCol>
              <ValidationSummary />
            </CustomCol>
          </RowContainer>
        }
        {errorInfo.tieneError &&
          <RowContainer>
            <CustomCol xs='10'>
              <CAlert
                key='modificaItemErrorAlert'
                color='danger'
                show={10000}
                closeButton
                onShowChange={() => { seterrorInfo({ tieneError: false }) }}
              >
                {errorInfo.mensaje}
              </CAlert>
            </CustomCol>
          </RowContainer>
        }
        <RowContainer>
          <CustomCol md='6'>
            <Labeled
              label='Código de barras'
            >
              <TextBox
                name='codigoBarras'
                placeholder='Codigo de Barras'
                value={datos?.codigoBarras}
                onValueChanged={({ value }) => {
                  const tmpText = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                  setDatos({ ...datos, codigoBarras: tmpText })
                }}
                onKeyDown={(e) => {
                  utilidades.filtrarTeclasRangos(e, [
                    { min: 18, max: 18 },
                    { min: 58, max: 64 },
                    { min: 106, max: 221 },
                  ])
                }}
                inputAttr={{
                  'autocomplete': "nope"
                }}
              >
                <Validator>
                  <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Código de barras')} />
                  <PatternRule message={lh.getMessage(MessagesKeys.GlobalFormatoCampoIncorrecto, "")} pattern={codigoBarrasRegex} />
                  {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol md='6'>
            <Barcode text={datos.codigoBarras} />
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs='12'>
            <Labeled
              label='Descripción'
            >
              <TextBox
                name='descripcion'
                placeholder='Descripción'
                value={datos?.descripcion}
                onValueChanged={({ value }) => setDatos({ ...datos, descripcion: value })}
              >
                <Validator>
                  <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Descripción')} />
                  {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs='6' md='2'>
            <Labeled
              label='P.V.P'
            >
              <NumberBox
                value={datos.pvp}
                onValueChanged={({ value }) => setDatos({ ...datos, pvp: value })}
              >
                <Validator>
                  <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'PVP')} />
                  <NumericRule />
                </Validator>
              </NumberBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs='6' md='2'>
            <Labeled
              label='P.V.D'
            >
              <NumberBox
                name='pvd'
                value={datos.pvd}
                onValueChanged={({ value }) => setDatos({ ...datos, pvd: value })}
              >
                <Validator>
                  <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'PVD')} />
                  <NumericRule />
                </Validator>
              </NumberBox>
            </Labeled>
          </CustomCol>
          {/* <CustomCol xs='6' md='3'>
            <Labeled label='IVA'>
              <SelectBox
                id='selectModificarItemImpuesto'
                options={tiposIva}
                placeholder='Impuesto'
                onChange={(value) => {
                  setDatos({ ...datos, impuesto: value })
                }}
                selected={datos.impuesto}
                displayExpr='descripcion'
                keyExpr='codigo'
              >
                <Validator>
                  <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Impuesto')} />
                </Validator>
              </SelectBox>
            </Labeled>
          </CustomCol> */}
          <CustomCol xs='2' >
            <Labeled label='IVA'>
              <Switch
                switchedOnText="SI"
                switchedOffText="NO"
                value={datos.impuesto}
                onValueChange={(value) => {
                  setDatos({ ...datos, impuesto: value })
                }}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs='2' >
            <Labeled label='ICE'>
              <Switch
                switchedOnText="SI"
                switchedOffText="NO"
                value={datos.ice}
                onValueChange={(value) => {
                  setDatos({ ...datos, ice: value })
                }}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs='2'>
            <Labeled label='Servicio'>
              <Switch
                switchedOnText="SI"
                switchedOffText="NO"
                value={datos.servicio}
                onValueChange={(value) => {
                  setDatos({ ...datos, servicio: value })
                  if (value === true && esNuevo === true) {
                    setDatos({ ...datos, unidad: 34, servicio: value })
                  }
                }}
              />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CCol md='6'>
            <Labeled
              label='Unidad'
            >
              <BuscarUnidadLookUp
                selected={datos.unidad}
                onChanged={(unidad) => {
                  setDatos({ ...datos, unidad: unidad })
                }}
              >
                <Validator>
                  <AsyncRule
                    reevaluate
                    message={"- Unidad: Debe selecciona una opción"}
                    validationCallback={validateAsyncSeleccionUnidad}
                  />
                </Validator>
              </BuscarUnidadLookUp>
            </Labeled>
          </CCol>

          {(empresaSession && empresaSession?.planCodigo && Number(empresaSession.planCodigo) !== 1) &&
            <>


              <CCol md='6'>
                <Labeled
                  label='Linea'
                >
                  <BuscarLineaLookUp
                    selected={datos.linea}
                    onChanged={(linea) => setDatos({ ...datos, linea: linea })}
                  />
                </Labeled>
              </CCol>

              <CCol md='6'>
                <Labeled
                  label='Grupo'
                >
                  <BuscarGrupoLookUp
                    selected={datos.grupo}
                    onChanged={(grupo) => setDatos({ ...datos, grupo: grupo })}
                  />
                </Labeled>
              </CCol>
              <CCol md='6'>
                <Labeled
                  label='Marca'
                >
                  <BuscarMarcaLookUp
                    selected={datos.marca}
                    onChanged={(marca) => setDatos({ ...datos, marca: marca })}
                  />
                </Labeled>
              </CCol>
            </>
          }

        </RowContainer>
        {datos.ice &&
          <RowContainer>
            <CCol xs='12'>
              <Labeled
                label='Codigos ICE'
              >
                <RowContainer>
                  <CCol xs='6' md='3'>
                    <Labeled
                      label='Tipo ICE'
                    >
                      <BuscarIceLookUp
                        selected={datos.iceTipoIce}
                        onChanged={(ice) => setDatos({ ...datos, iceTipoIce: ice })}
                      />
                    </Labeled>
                  </CCol>
                  <CCol xs='6' md='3'>
                    <Labeled
                      label='Cod. Grado Alcoholico'
                    >
                      <TextBox
                        name='iceCodigoGradoAlcoholico'
                        value={datos.iceCodigoGradoAlcoholico}
                        onValueChanged={({ value }) => setDatos({ ...datos, iceCodigoGradoAlcoholico: value })}
                      >
                        <Validator>
                          <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Cód. Grado Alcoholico')} />
                          <NumericRule />
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CCol>
                  <CCol xs='6' md='3'>
                    <Labeled
                      label='Grado Alcoholico'
                    >
                      <NumberBox
                        name='iceCodigoGradoAlcoholico'
                        value={datos.iceGradoAlcoholico}
                        onValueChanged={({ value }) => setDatos({ ...datos, iceGradoAlcoholico: value })}
                      >
                        <Validator>
                          <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Grado Alcoholico')} />
                          <NumericRule />
                        </Validator>
                      </NumberBox>
                    </Labeled>
                  </CCol>
                  <CCol xs='6' md='3'>
                    <Labeled
                      label='Capacidad cm3'
                    >
                      <NumberBox
                        name='iceCapacidaCm3'
                        value={datos.iceCapacidaCm3}
                        onValueChanged={({ value }) => setDatos({ ...datos, iceCapacidaCm3: value })}
                      >
                        <Validator>
                          <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Capacidad cm3')} />
                          <NumericRule />
                        </Validator>
                      </NumberBox>
                    </Labeled>
                  </CCol>
                  <CCol xs='6' md='3'>
                    <Labeled
                      label='Código Impuesto'
                    >
                      <TextBox
                        name='iceCodigoImpuesto'
                        placeholder='Código Impuesto'
                        value={datos?.iceCodigoImpuesto}
                        onValueChanged={({ value }) => setDatos({ ...datos, iceCodigoImpuesto: value })}
                      >
                        <Validator>
                          <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Código Impuesto')} />
                          {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                        </Validator>
                      </TextBox>
                    </Labeled>

                  </CCol>
                  <CCol xs='6' md='3'>
                    <Labeled
                      label='Código Clasificación'
                    >
                      <TextBox
                        name='iceCodigoClasificacion'
                        placeholder='Código Impuesto'
                        value={datos?.iceCodigoClasificacion}
                        onValueChanged={({ value }) => setDatos({ ...datos, iceCodigoClasificacion: value })}
                      >
                        <Validator>
                          <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Código Clasificación')} />
                          {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CCol>
                  <CCol xs='6' md='3'>
                    <Labeled
                      label='Estado'
                    >
                      <TextBox
                        name='iceCodigoClasificacion'
                        placeholder='Estado'
                        value={datos?.iceEstado}
                        onValueChanged={({ value }) => setDatos({ ...datos, iceEstado: value })}
                      >
                        <Validator>
                          <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Estado ICE')} />
                          {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                        </Validator>
                      </TextBox>
                    </Labeled>

                  </CCol>
                  <CCol xs='6' md='3'>
                    <Labeled
                      label='Código Presentación'
                    >
                      <TextBox
                        name='iceCodigoPresentacion'
                        placeholder='Código Presentación'
                        value={datos?.iceCodigoPresentacion}
                        onValueChanged={({ value }) => setDatos({ ...datos, iceCodigoPresentacion: value })}
                      >
                        <Validator>
                          <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Código Presentación')} />
                          {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CCol>
                  <CCol xs='6' md='3'>
                    <Labeled
                      label='Código Unidad'
                    >
                      <TextBox
                        name='iceCodigoUnidad'
                        placeholder='Código Unidad'
                        value={datos?.iceCodigoUnidad}
                        onValueChanged={({ value }) => setDatos({ ...datos, iceCodigoUnidad: value })}
                      >
                        <Validator>
                          <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Código Unidad')} />
                          {/* <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} ignoreEmptyValue validationCallback={validateId} /> */}
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CCol>
                  <CCol xs='6' md='3'>
                    <Labeled
                      label='Gramos Azucar'
                    >
                      <NumberBox
                        name='iceGramosAzucar'
                        value={datos.iceGramosAzucar}
                        onValueChanged={({ value }) => setDatos({ ...datos, iceGramosAzucar: value })}
                      >
                        <Validator>
                          <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Gramos Azucar')} />
                          <NumericRule />
                        </Validator>
                      </NumberBox>
                    </Labeled>
                  </CCol>
                  <CCol xs='6' md='3'>
                    <Labeled
                      label='Ice. Cod. Marca'
                    >
                      <TextBox
                        name='iceCodMarca'
                        value={datos.iceCodigoMarca}
                        onValueChanged={({ value }) => setDatos({ ...datos, iceCodigoMarca: value })}
                      >
                        <Validator>
                          <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, 'Ice. Cod. Marca')} />
                          <NumericRule />
                        </Validator>
                      </TextBox>
                    </Labeled>
                  </CCol>
                </RowContainer>
              </Labeled>
            </CCol>
          </RowContainer>
        }

        {isMobileOnly &&
          (
            <>
              <RowContainer className="ml-2">
                <CustomCol>
                  <ValidationSummary />
                </CustomCol>
              </RowContainer>
              {showErrorPopup && (
                < PopupContent
                  show={showErrorPopup}
                  title={"Acatha"}
                  subTitle={"Por favor revise las siguientes advertencias:"}
                  onClose={() => {
                    setShowErrorPopup(false);
                    setShowErrorMessages([]);
                  }}
                  size={"sm"}
                  canClose={true}
                >
                  <CAlert color='danger'>
                    <ul className="errorList" >
                      {showErrorMessages.map(function (item, id) {
                        return <li key={id}>{item['message']}</li>
                      })}
                    </ul>
                  </CAlert>
                </PopupContent>
              )}
            </>
          )
        }

      </ValidationGroup>
    </>


  )
};

interface IFormularioItemProps {
  codigoBarras: string,
  onChanged?: (codigoBarras: string) => void;
  onCancel?: () => void;
  funcion: string,
  resetFunction: () => void,
  currentTab: string,
  transaccion: string
}

type ErrorInfo = {
  tieneError: boolean,
  mensaje?: string
}

type DatosModificarItem = {
  codigoBarras: string,
  descripcion: string,
  pvp: number,
  pvd: number,
  impuesto?: boolean, // TipoIva
  servicio?: boolean,
  ice: boolean,
  unidad?: number,
  linea?: InventarioTypes.LineaInventario
  grupo?: InventarioTypes.GrupoInventario,
  marca?: InventarioTypes.MarcaInventario,
  iceTipoIce?: number,
  iceCodigoGradoAlcoholico: string,
  iceGradoAlcoholico: number,
  iceCapacidaCm3: number,
  iceCodigoImpuesto: string,
  iceCodigoClasificacion: string,
  iceCodigoMarca: string,
  iceEstado: string,
  iceCodigoPresentacion: string,
  iceCodigoUnidad: string,
  iceGramosAzucar: number
}

const datosModificarItemDefaultValue: DatosModificarItem = {
  codigoBarras: '',
  descripcion: '',
  pvp: 0,
  pvd: 0,
  impuesto: true,
  //servicio: true,
  ice: false,
  // iceTipoIce: 0,
  iceCodigoGradoAlcoholico: '',
  iceGradoAlcoholico: 0,
  iceCapacidaCm3: 0,
  iceCodigoImpuesto: '',
  iceCodigoClasificacion: '',
  iceCodigoMarca: '',
  iceEstado: '',
  iceCodigoPresentacion: '',
  iceCodigoUnidad: '',
  iceGramosAzucar: 0
};

const codigoBarrasRegex: RegExp = new RegExp("^[a-zA-Z0-9]{2,25}$");




export default React.memo(FormularioItem);
