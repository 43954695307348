import React from 'react';
export default function CustomSheetRowNumberViewer(props) {
  const { row } = props

  return (
    <div className='customsheet-header' style={{ width: '100%', height: '100%' }}>
      <span>{row + 1}</span>
    </div>
  )


}
