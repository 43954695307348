import './scss/style.scss';
// import 'devextreme/dist/css/dx.common.css';
// import 'devextreme/dist/css/dx.light.compact.css';
//import "./wdyr"; // <-- first import
import './env'
//import 'react-app-polyfill/ie11' // For IE 11 support
//import 'react-app-polyfill/stable'
//import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify';
import awsconfig from './config/aws-exports';
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from "react-router-dom";
import { icons } from './assets/icons'
import { Provider } from 'react-redux'
import store from './store/store'
//import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
//import { env } from 'process'
import './assets/icons/iconsDuotone'
import './assets/icons/iconsLigth'
import './assets/icons/inconsRegular'
import './assets/icons/inconsSolid'
import { AppContainer } from './AppContainer';


//import App from './App'
//import { dom } from '@fortawesome/fontawesome-svg-core'

//dotenv.config();


//dom.watch();



Amplify.configure(awsconfig);
// if (process.env.NODE_ENV === 'development') {
//   Amplify.Logger.LOG_LEVEL = 'DEBUG';
// }


//I18n.setLanguage('es');




React.icons = icons;

// const theme = createMuiTheme({
//   palette: {
//     primary: {
//       light: '#757ce8',
//       main: '#2196f3',
//       dark: '#002884',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ff7961',
//       main: '#3d5afe',
//       dark: '#ba000d',
//       contrastText: '#000',
//     },
//   },
//   overrides: {
//     MuiPopover: {
//       root: { zIndex: 3000 },
//     },
//   },
// });

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={loading}>
      <Provider store={store}>
        <BrowserRouter>
          <AppContainer mantenimiento={(process.env.REACT_APP_MODO_MANTENIMIENTO ?? "false") === "true"} />
        </BrowserRouter>
      </Provider>
    </React.Suspense>
  </React.StrictMode>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
