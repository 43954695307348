import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoaderInfo } from '../../../../store/types';
import { CustomDictionary } from '../../../ventas/types/generics';
import { Cliente, FormaPagoModuloVentas } from '../../../ventas/types/types';
import { TTotalesFactura } from '../../../ventas/pages/ventas/types/types';
import { DetalleVentaRedux } from '../../../ventas/pages/ventas/types/detalleVenta';
import { comandasClearDatosEdicion } from '../store/reducers';
import { DetalleComandaRedux } from '../types/detalleComanda';
import { OpcionMeseros } from '../types/types';

const initialState: CustomDictionary<any> = {

}
const datosEdicionComandasSlice = createSlice({
  name: 'comandaDatosEdicion',
  initialState: initialState,
  reducers: {
    comandasClearDatosEdicion,
    setDatosEdicion(state, action: PayloadAction<any>) {
      state[action.payload.key] = action.payload.data;
    },
    setSalones(state, action: PayloadAction<any>) {
      state[action.payload.key].salones = action.payload.salones;
    },
    setMesas(state, action: PayloadAction<any>) {
      state[action.payload.key].mesas = action.payload.mesas;
    },
    setMeseros(state, action: PayloadAction<any>) {
      state[action.payload.key].meseros = action.payload.mesas;
    },
    updateTotales(state, action: PayloadAction<{ key: number | string, totales: TTotalesFactura }>) {
      state[action.payload.key].totales = action.payload.totales;
      state[action.payload.key].descuentoGlobal = action.payload.totales.descuentoGloabal;
    },
    updateDetalles(state, action: PayloadAction<{ key: number | string, detalles: Array<DetalleComandaRedux> }>) {
      state[action.payload.key].detalles = action.payload.detalles;
    },
    addDetalle(state, action: PayloadAction<{ key: number | string, detalle: DetalleComandaRedux }>) {
      state[action.payload.key].detalles.push(action.payload.detalle);
    },
    updateCliente(state, action: PayloadAction<{ key: number | string, cliente: Cliente | null, formasPago: Array<FormaPagoModuloVentas>, formaPago: Array<FormaPagoModuloVentas>, detalles?: Array<DetalleVentaRedux> }>) {
      state[action.payload.key].cliente = action.payload.cliente;
      state[action.payload.key].formasPago = action.payload.formasPago;
      state[action.payload.key].formaPago = action.payload.formaPago;
      if (action.payload.detalles) state[action.payload.key].detalles = action.payload.detalles;
    },
    updateClienteOnly(state, action: PayloadAction<{ key: number | string, cliente: Cliente }>) {
      state[action.payload.key].cliente = action.payload.cliente;
    },
    updateObservaciones(state, action: PayloadAction<{ key: number | string, observaciones: string }>) {
      state[action.payload.key].observaciones = action.payload.observaciones;
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(state, action: PayloadAction<any>) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data;
      }
    },
    setEditLoader(state, action: PayloadAction<{ key: number | string, info: LoaderInfo }>) {
      state[action.payload.key].loader = action.payload.info;
    },
    setMuestraError(state, action: PayloadAction<{ key: number | string, tieneError: boolean, mensajeError: string }>) {
      state[action.payload.key].tieneError = action.payload.tieneError;
      state[action.payload.key].mensajeError = action.payload.mensajeError;
    },
    setImprimir(state, action: PayloadAction<{ key: number | string, imprimir: boolean }>) {
      state[action.payload.key].imprimir = action.payload.imprimir;
    },
    setMostrarBusquedaItems(state, action: PayloadAction<{ key: string, mostrarBusquedaItems: boolean }>) {
      const { key, mostrarBusquedaItems } = action.payload;
      state[key].mostrarBusquedaItems = mostrarBusquedaItems;
    },
    updateMeseroOnly(state, action: PayloadAction<{ key: number | string, mesero: OpcionMeseros | null }>) {
      state[action.payload.key].mesero = action.payload.mesero;
    },
    updateOrdenNroOnly(state, action: PayloadAction<{ key: number | string, ordenNro: string }>) {
      state[action.payload.key].ordenNro = action.payload.ordenNro;
    },
    updatTipoOnly(state, action: PayloadAction<{ key: number | string, tipo: string }>) {
      state[action.payload.key].tipo = action.payload.tipo;
    },
    updateFechaOnly(state, action: PayloadAction<{ key: number | string, fecha: string }>) {
      state[action.payload.key].fecha = action.payload.fecha;
    },
    updateSalonOnly(state, action: PayloadAction<{ key: number | string, salonCodigo: number }>) {
      state[action.payload.key].salonCodigo = action.payload.salonCodigo;
    },
    updateMesaOnly(state, action: PayloadAction<{ key: number | string, mesaCodigo: number }>) {
      state[action.payload.key].mesaCodigo = action.payload.mesaCodigo;
    },
    updateObservacionOnly(state, action: PayloadAction<{ key: number | string, observaciones: string }>) {
      state[action.payload.key].observaciones = action.payload.observaciones;
    },
    updateDetalle(
      state,
      acion: PayloadAction<{
        key: string;
        codigo: string;
        updated: {
          codigo: number;
          codigoInterno: string;
          codigoBarras: string;
          descripcion: string;
          tieneIva: boolean;
          porcentajeIva: number;
          imagenes: Array<string>;
          fecha: string;
          _iva: number;
          _subtotal: number;
          _subtotal0: number;
          _subtotalIva: number;
          _total: number;
          _cantidad: number;
          _precioUnitario: number;
          _precioIva: number;
          _porcentajeDescuento: number;
          _descuento: number;
        };
      }>
    ) {
      const { key, updated, codigo } = acion.payload;

      for (let i = 0; i < state[key].detalles.length; i++) {
        const detalle = state[key].detalles[i];
        if (detalle.codigo === codigo) {
          state[key].detalles[i].codigo = updated.codigo;
          state[key].detalles[i].codigoInterno = updated.codigoInterno;
          state[key].detalles[i].codigoBarras = updated.codigoBarras;
          state[key].detalles[i].descripcion = updated.descripcion;
          state[key].detalles[i].tieneIva = updated.tieneIva;
          state[key].detalles[i].porcentajeIva = updated.porcentajeIva;
          state[key].detalles[i].imagenes = updated.imagenes;
          state[key].detalles[i].fecha = updated.fecha;
          state[key].detalles[i]._iva = updated._iva;
          state[key].detalles[i]._subtotal = updated._subtotal;
          state[key].detalles[i]._subtotal0 = updated._subtotal0;
          state[key].detalles[i]._subtotalIva = updated._subtotalIva;
          state[key].detalles[i]._total = updated._total;
          state[key].detalles[i]._cantidad = updated._cantidad;
          state[key].detalles[i]._precioUnitario = updated._precioUnitario;
          state[key].detalles[i]._precioIva = updated._precioIva;
          state[key].detalles[i]._porcentajeDescuento = updated._porcentajeDescuento;
          state[key].detalles[i]._descuento = updated._descuento;
          break;
        }
      }

      console.log("reducer state : ", state)


    },
  },
})

export const {
  setMeseros, setSalones, setMesas, initDatosEdicion, setDatosEdicion, updateTotales, updateDetalles,
  updateCliente, updateObservaciones, setEditLoader,
  setMuestraError, addDetalle, setImprimir, setMostrarBusquedaItems,
  updateClienteOnly, comandasClearDatosEdicion: clearDatosEdicion,
  updateMeseroOnly, updateOrdenNroOnly, updatTipoOnly, updateFechaOnly,
  updateSalonOnly, updateObservacionOnly, updateMesaOnly, updateDetalle
} = datosEdicionComandasSlice.actions;
export const datosEdicionComandaReducer = datosEdicionComandasSlice.reducer;