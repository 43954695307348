//#region imports
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { isMobile, isMobileOnly } from 'react-device-detect';
import Dialog from 'react-bootstrap-dialog'
import { CTabContent, CTabPane, CCol, CNav, CNavItem, CNavLink, CRow, CTabs, CLabel, CAlert, CFormGroup, CButton } from '@coreui/react';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../../../../../../../../store/store';
import { DocumentInfoCuadre } from '../../types/types';
import {
  CuadresCajaDatosEdicion, CuadresCajaListado, formasPago, LocalOption, TabStateCuadre, TCuadreCajaIngresar,
  DetalleCuadreRegistro, formasPagoCuadre, DenominacionListado
} from '../../../../types/types';
import LoadingIndicator from '../../../../../../../../views/componentes/loadingindicator/loadingindicator';
import VisualizaError from '../../../../../shared/visualizaError/visualizaError';
import {
  setDatosEdicion, setMuestraError,
  updatePuntoVentaOnly,
  updateMinutoCierreOnly, updateHoraCierreOnly,
  updateFechaCierreOnly, updateLocalOnly, updateCcaCodigoOnly, updateUsuarioNombreOnly, updateUsuarioCodigoOnly, updateCuadreTotalOnly, updateGenerarCuadreOnly
} from '../../store/editDataReducer';
import { StatesEdition } from '../../../../../../types/enums';
import RowContainer from '../../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../../views/componentes/colContainer';
import Labeled from '../../../../../../../../views/componentes/labeledInput/labeledInput';
import SelectBox from '../../../../../../../../views/componentes/selectBox/selectBox';
import { buttonClick, changeEditStatus, clearButtonClick, setButtons, setCurrentExecutingAction } from '../../store/tabsReducer';
import { DateUtils, formatoFechasApi, formatoFechasDatePickers } from '../../../../../../../../helpers/dateUtils';
import { CuadresService } from '../../../../services/cuadres.service';
import { Puntoventa, ToastTypes } from '../../../../../../../../store/types';
import { NumberBox } from 'devextreme-react/number-box'
import Denominacion from '../tabs/denominacion/denominacion';
import Facturas from '../tabs/facturas/facturas';
import NotasCredito from '../tabs/notasCredito/notasCredito';
import Retenciones from '../tabs/retenciones/retenciones';
import Anticipos from '../tabs/anticipos/anticipos';
import CobrosCartera from '../tabs/cobrosCartera/cobrosCartera';
import ComprasCuadres from '../tabs/compras/comprasCuadres';
import PagosTesoreria from '../tabs/pagosTesoreria/pagosTesoreria';
import DateBox from 'devextreme-react/date-box';
import { CustomButtons } from '../../../../../../types/generics';
import Totales from '../tabs/totales/totales';
import Cheques from '../tabs/cheques/cheques';
import Letras from '../tabs/letras/letras';
import { ButtonTypes } from '../../../../../../../../views/componentes/globalMenu/types';
import { addToast } from '../../../../../../../../store/toasterReducer';
import { ReportWithLocalDataModal } from '../../../../../../../../views/componentes/xtrareports/ReportWithLocalDataModal';
import { seleccionarCuadre } from '../../store/configuracionesComandaReducer';
import { Button as TextBoxButton } from 'devextreme-react/text-box';
import ModalListadoComprobantes from '../../../../../../../componentes/modalListadoComprobantes';


interface ICuadreCajaProps {
  info: DocumentInfoCuadre<CuadresCajaListado>,
  tabId: string,
  tab: TabStateCuadre<CuadresCajaListado>,
  templateMode?: boolean
  setToast: (texto, type) => void
  playLoader: () => void
  stopLoader: () => void,
  modulo: number
}

enum TabCuadres {
  Denominacion = "Denominación",
  Facturas = "Facturas",
  NotasCredito = "Notas de Credito",
  Retenciones = "Retenciones",
  Anticipos = "Anticipos",
  CobrosCartera = "Cobros de cartera",
  Compras = "Compras",
  PagosTesoreria = "Pagos Tesoreria",
  Totales = "Totales",
  Cheques = "Cheques",
  Letras = "Letras",
  Boveda = "Boveda",
  EgresosBoveda = "Egresos a Boveda",
}

interface ITabNavTitleDenominacionProps {
  id: TabCuadres,
  current: boolean
}

type TotalesResumen = {
  facturas: Array<any>
  notasCredito: Array<any>
  anticipo: Array<any>
  abonos: Array<any>
  pagos: Array<any>
}

type TotalesDetalle = {
  facturas: Array<any>
  notasCredito: Array<any>
  depositosGarantia: Array<any>
  ventasPorLinea: Array<any>
}

const TabNavTitleDen: React.FunctionComponent<ITabNavTitleDenominacionProps> = (props) => {
  return (
    <div>
      <span className='text-primary'>{props.id}</span>
    </div>
  );
};

enum PLANTILLA_IMPRIMIR {
  DENOMINACION_TICKET = 'StaticDenominacionTicketera',
  DENOMINACION_STARDAR = 'StaticDenominacionStandar',
  COBORSCARTERA_TICKETERA = 'StaticCobrosCarteraTicketera',
  COBORSCARTERA_STARDAR = 'StaticCobrosCarteraStardar',
  TOTALES_TOTALES = 'StaticTotales',
  TOTALES_RESUMEN = 'StaticTotalesResumen',
  TOTALES_DETALLE = 'StaticTotalesDetalle',
}

enum MODAL_DOCUMENTOS {
  CHEQUES = 'cheques',
  CUOTAS = 'cuotas',
  TARJETAS = 'tarjetas'
}

const CuadreCaja: React.FunctionComponent<ICuadreCajaProps> = (props) => {

  const {
    tabId, info, tab, setToast, playLoader,
    stopLoader, modulo
  } = props;

  const dispatch = useDispatch();

  const usuario = useSelector((state: RootState) => { return state.global.session?.usuario });
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  //const currentTab = useSelector((state: RootState) => { return state.ventas.ventas.tabs.tabs[props.tabIndex] });
  const puntoVentaGlobal = useSelector((state: RootState) => { return state.global.puntoVenta });
  const datosEdicion = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId] });
  const loading = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].loading });
  const puntoVenta = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].puntoVenta });
  const fechaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fechaCierre });
  const horaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].horaCierre });
  const minutoCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].minutoCierre });
  const segundosCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].segundosCierre });
  const local = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].local });
  const formEdition = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId] });
  const denominacion = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].denominacion });
  const dptotalresumen = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotalresumen });
  const fecha = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fecha });
  const dptotales1 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales1 });
  const dptotales2 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales2 });
  const dptotales3 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales3 });
  const dptotales7 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales7 });
  const dptotales8 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales8 });
  const dptotales9 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales9 });
  const dptotales10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales10 });
  const ccaCod1 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod1 });
  const venInicial1 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial1 });
  const venFinal1 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal1 });
  const ccaCod2 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod2 });
  const venInicial2 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial2 });
  const venFinal2 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal2 });
  const ccaCod3 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod3 });
  const venInicial3 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial3 });
  const venFinal3 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal3 });
  const ccaCod4 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod4 });
  const venInicial4 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial4 });
  const venFinal4 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal4 });
  const ccaCod5 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod5 });
  const venInicial5 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial5 });
  const venFinal5 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal5 });
  const ccaCod6 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod6 });
  const venInicial6 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial6 });
  const venFinal6 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal6 });
  const ccaCod7 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod7 });
  const venInicial7 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial7 });
  const venFinal7 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal7 });
  const ccaCod8 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod8 });
  const venInicial8 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial8 });
  const venFinal8 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal8 });
  const ccaCod9 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod9 });
  const venInicial9 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial9 });
  const venFinal9 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal9 });
  const ccaCod10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCod10 });
  const venInicial10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial10 });
  const venFinal10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal10 });
  const imprimir = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].imprimir });
  const dpcierre1 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre1 });
  const dpcierre2 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre2 });
  const dpcierre3 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre3 });
  const dpcierre4 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre4 });
  const dpcierre5 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre5 });
  const dpcierre7 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre7 });
  const dpcierre8 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre8 });
  const dpcierre9 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre9 });
  const dpcierre10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre10 });
  const ccaCodigo = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].ccaCodigo });

  const dialogRefCuadre = React.useRef<any>(null);

  const [puntosVenta, setPuntosVenta] = React.useState<Array<Puntoventa> | []>([]);
  const [locales, setLocales] = React.useState<Array<LocalOption> | []>([]);
  const [currentTabCuadreIndex, setCurrentTabCuadreIndex] = React.useState<TabCuadres>(TabCuadres.Denominacion);
  const [reporte, setReporte] = React.useState<null | "Viewer" | "Designer">(null);

  const [datosExportar, setDatosExportar] = React.useState<any>([]);
  const [plantillaNombre, setPlantillaNombre] = React.useState<PLANTILLA_IMPRIMIR>(PLANTILLA_IMPRIMIR.DENOMINACION_STARDAR);
  const [openModal, setOpenModal] = React.useState<MODAL_DOCUMENTOS | null>(null);

  const parseInfoAllCuadres = React.useCallback(async (data: CuadresCajaDatosEdicion, dataAPiCuadres: Array<CuadresCajaListado>) => {
    console.log("parseInfoAllCuadres")
    console.log("parseInfoAllCuadres data", data)
    console.log("parseInfoAllCuadres dataAPiCuadres", dataAPiCuadres)

    for (let i: number = 0; i < dataAPiCuadres.length; i++) {
      switch (dataAPiCuadres[i].tipoCuadreCajaCodigo) {
        case 1:
          data.venInicial1 = dataAPiCuadres[i].ventaInicial;
          data.venFinal1 = dataAPiCuadres[i].ventaFinal;
          data.ccaCod1 = dataAPiCuadres[i].cuadreCodigo;
          break;
        case 2:
          data.venInicial2 = dataAPiCuadres[i].ventaInicial;
          data.venFinal2 = dataAPiCuadres[i].ventaFinal;
          data.ccaCod2 = dataAPiCuadres[i].cuadreCodigo;
          break;
        case 3:
          data.venInicial3 = dataAPiCuadres[i].ventaInicial;
          data.venFinal3 = dataAPiCuadres[i].ventaFinal;
          data.ccaCod3 = dataAPiCuadres[i].cuadreCodigo;
          break;
        case 4:
          data.venInicial4 = dataAPiCuadres[i].ventaInicial;
          data.venFinal4 = dataAPiCuadres[i].ventaFinal;
          data.ccaCod4 = dataAPiCuadres[i].cuadreCodigo;
          break;
        case 5:
          data.venInicial5 = dataAPiCuadres[i].ventaInicial;
          data.venFinal5 = dataAPiCuadres[i].ventaFinal;
          data.ccaCod5 = dataAPiCuadres[i].cuadreCodigo;
          break;
        case 6:
          data.venInicial6 = dataAPiCuadres[i].ventaInicial;
          data.venFinal6 = dataAPiCuadres[i].ventaFinal;
          data.ccaCod6 = dataAPiCuadres[i].cuadreCodigo;
          break;
        case 7:
          data.venInicial7 = dataAPiCuadres[i].ventaInicial;
          data.venFinal7 = dataAPiCuadres[i].ventaFinal;
          data.ccaCod7 = dataAPiCuadres[i].cuadreCodigo;
          break;
        case 8:
          data.venInicial8 = dataAPiCuadres[i].ventaInicial;
          data.venFinal8 = dataAPiCuadres[i].ventaFinal;
          data.ccaCod8 = dataAPiCuadres[i].cuadreCodigo;
          break;
        case 9:
          data.venInicial9 = dataAPiCuadres[i].ventaInicial;
          data.venFinal9 = dataAPiCuadres[i].ventaFinal;
          data.ccaCod9 = dataAPiCuadres[i].cuadreCodigo;
          break;
        case 10:
          data.venInicial10 = dataAPiCuadres[i].ventaInicial;
          data.venFinal10 = dataAPiCuadres[i].ventaFinal;
          data.ccaCod10 = dataAPiCuadres[i].cuadreCodigo;
          break;
      }
    }
    console.log("salida parseInfoAllCuadres data", data)

    return await data;
  }, []);


  const parseInfoCuadre = React.useCallback(async (dataAPiCuadre) => {
    let cuadreRegistrado = dataAPiCuadre;
    let itemDenominacion: Array<DenominacionListado> = [];
    cuadreRegistrado.cuadreDenominacion.forEach(function (det) {
      itemDenominacion.push({
        descripcion: String(det?.descripcion) ?? "",
        valor: Number(det?.valor) ?? 0,
        grupo: Number(det?.grupo) ?? 0,
      })
    })

    let returnObject = {
      cuadreCodigo: Number(cuadreRegistrado?.cuadreCodigo) ?? 0,
      cuadreFechaCierre: String(cuadreRegistrado?.cuadreFechaCierre) ?? "",
      cuadreFechaHoraCierre: String(cuadreRegistrado?.cuadreFechaHoraCierre) ?? "",
      puntoVentaCodigo: Number(cuadreRegistrado?.puntoCodigo) ?? -1,
      puntoVentaHost: String(cuadreRegistrado?.puntoHost) ?? "",
      usuarioCodigo: Number(cuadreRegistrado?.usuarioCodigo) ?? 0,
      usuarioNombre: String(cuadreRegistrado?.usuarioNombre) ?? "",
      cuadreTotal: Number(cuadreRegistrado?.cuadreTotal) ?? 0,
      localCodigo: Number(cuadreRegistrado?.localCodigo) ?? 0,
      localNombre: String(cuadreRegistrado?.localNombre) ?? "",
      cuadreNumero: Number(cuadreRegistrado?.cuadreNumero) ?? 0,
      ventaInicial: Number(cuadreRegistrado?.ventaInicial) ?? 0,
      ventaFinal: Number(cuadreRegistrado?.ventaFinal) ?? 0,
      tipoCuadreCajaCodigo: Number(cuadreRegistrado?.tipoCuadreCajaCodigo) ?? 0,
      cuadreDenominacion: itemDenominacion
    }
    dispatch(updateCcaCodigoOnly({ ccaCodigo: Number(cuadreRegistrado?.cuadreCodigo) ?? 0, key: tabId }))
    dispatch(updateUsuarioNombreOnly({ usuarioNombre: String(cuadreRegistrado?.usuarioNombre) ?? "", key: tabId }))
    dispatch(updateUsuarioCodigoOnly({ usuarioCodigo: Number(cuadreRegistrado?.usuarioCodigo) ?? 0, key: tabId }))
    dispatch(updateCuadreTotalOnly({ cuadreTotal: Number(cuadreRegistrado?.cuadreTotal) ?? 0, key: tabId }))
    return returnObject;
  }, [tabId, dispatch]);

  const onTotalizaCantidadComprobantes = React.useCallback(async (tipoImpresion: PLANTILLA_IMPRIMIR) => {
    let cantidad = {
      facturas: 0,
      notasCredito: 0,
      retenciones: 0,
      anticipos: 0,
      cobrosCartera: 0,
      compras: 0,
      pagosTesoreria: 0,
      cheques: 0,
      credito: 0,
      totalFacturasAnuladas: 0,
    }

    let total: number = 0;
    let aux: number = 0;
    let validas: number = 0;
    let k: number = 0;
    let totalFA: number = 0;
    let totalF: number = 0;
    let totalNC: number = 0;
    let totalR: number = 0;
    let totalC: number = 0;
    let totalLC: number = 0;
    let totalAN: number = 0;
    let totalCob: number = 0;
    let totalCom: number = 0;
    let totalTesoreria: number = 0;

    // TOTALIZADO GENERAL

    for (k = 0; k < dpcierre1.length; k++) {
      if (dpcierre1[k].ventaEstado === "*") {
        total++;
      }
      if (aux !== Number(dpcierre1[k].ventaCodigo)) {
        validas++;
      }
      aux = Number(dpcierre1[k].ventaCodigo);
    }
    totalFA = total;
    totalF = validas - total;

    total = 0;
    for (k = 0; k < dpcierre2.length; k++) {
      if (dpcierre2[k].ventaEstado === "*") {
        total++;
      }
    }
    totalNC = dpcierre2.length - total;

    total = 0;
    for (k = 0; k < dpcierre3.length; k++) {
      if (dpcierre3[k].ventaEstado === "*") {
        total++;
      }
    }


    if (tipoImpresion === PLANTILLA_IMPRIMIR.DENOMINACION_TICKET || tipoImpresion === PLANTILLA_IMPRIMIR.DENOMINACION_STARDAR) {

      totalR = dpcierre3.length - total;
      totalC = dpcierre3.length - total;
      totalLC = dpcierre5.length;
      totalAN = dpcierre7.length;
      totalCob = dpcierre8.length;
      totalCom = dpcierre9.length;
      totalTesoreria = dpcierre10.length;

    } else if (tipoImpresion === PLANTILLA_IMPRIMIR.COBORSCARTERA_TICKETERA || tipoImpresion === PLANTILLA_IMPRIMIR.COBORSCARTERA_STARDAR) {

      totalR = dpcierre3.length - total;
      totalC = dpcierre4.length - total;
      totalLC = dpcierre5.length;

    }


    cantidad.facturas = totalF;
    cantidad.notasCredito = totalNC;
    cantidad.retenciones = totalR;
    cantidad.anticipos = totalAN;
    cantidad.cobrosCartera = totalCob;
    cantidad.compras = totalC;
    cantidad.pagosTesoreria = totalTesoreria;
    cantidad.cheques = totalLC;
    cantidad.credito = totalCom;
    cantidad.totalFacturasAnuladas = totalFA;


    return await cantidad;
  }, [
    dpcierre1, dpcierre2, dpcierre3,
    dpcierre5, dpcierre7, dpcierre8,
    dpcierre9, dpcierre10, dpcierre4
  ]);

  const onParseInfoReporteTotales = React.useCallback(async (tipoImpresion: PLANTILLA_IMPRIMIR) => {
    console.log("onParseInfoReporteTotales formEdition", formEdition)
    let resCargaCuadre: any = null;
    playLoader();
    let findDetall: any = {
      ccaCod1: formEdition.ccaCod1,
      ccaCod7: formEdition.ccaCod7,
      ccaCod8: formEdition.ccaCod8,
      ccaCod10: formEdition.ccaCod10,
      vendedorCodigo: formEdition?.vendedor?.vendedorCodigo ?? 0,
      localCodigo: formEdition?.local?.codigo ?? 0,
      puntoVentaCodigo: formEdition?.puntoVenta?.codigoPuntoVenta ?? -1
    }

    try {
      resCargaCuadre = await CuadresService.getCuadreCajaReportTotales(findDetall);
      stopLoader();

      let resumenTotales: TotalesResumen = {
        facturas: [],
        notasCredito: [],
        anticipo: [],
        abonos: [],
        pagos: [],
      };

      console.log("resCargaCuadre ", resCargaCuadre)

      if (resCargaCuadre) {
        let resCargaCuadreFacturas: Array<any> = [];
        resCargaCuadre.facturas.forEach(function (det) {
          resCargaCuadreFacturas.push({
            cheque: String(det?.cheque) ?? "",
            descripcion: String(det?.descripcion) ?? "",
            fecha: String(det?.fecha) ?? "",
            numero: String(det?.numero) ?? "",
            identificacion: String(det?.identificacion) ?? "",
            nombre: String(det?.nombre) ?? "",
            abono: Number(det?.abono) ?? 0,
            total: Number(det?.total) ?? 0,
            anulado: String(det?.anulado) ?? "",
          })
        })
        resumenTotales.facturas = resCargaCuadreFacturas;

        let resCargaCuadreNotasCredito: Array<any> = [];
        resCargaCuadre.notasCredito.forEach(function (det) {
          resCargaCuadreNotasCredito.push({
            descripcion: String(det?.descripcion) ?? "",
            fecha: String(det?.fecha) ?? "",
            numero: String(det?.numero) ?? "",
            identificacion: String(det?.identificacion) ?? "",
            nombre: String(det?.nombre) ?? "",
            abono: Number(det?.abono) ?? 0,
            total: Number(det?.total) ?? 0,
            anulado: String(det?.anulado) ?? "",
          })
        })
        resumenTotales.notasCredito = resCargaCuadreNotasCredito;

        let resCargaCuadreAnticipos: Array<any> = [];
        resCargaCuadre.anticipo.forEach(function (det) {
          resCargaCuadreAnticipos.push({
            fecha: String(det?.registro) ?? "",
            descripcion: String(det?.descripcion) ?? "",
            numero: String(det?.numero) ?? "",
            identificacion: String(det?.identificacion) ?? "",
            nombre: String(det?.nombre) ?? "",
            total: Number(det?.total) ?? 0,
          })
        })
        resumenTotales.anticipo = resCargaCuadreAnticipos;

        let resCargaCuadreAbonos: Array<any> = [];
        resCargaCuadre.abonos.forEach(function (det) {
          resCargaCuadreAbonos.push({
            descripcion: String(det?.descripcion) ?? "",
            identificacion: String(det?.identificacion) ?? "",
            nombre: String(det?.nombre) ?? "",
            numero: String(det?.numero) ?? "",
            fecha: String(det?.registro) ?? "",
            tipo: Number(det?.tipo) ?? 0,
            total: Number(det?.total) ?? 0,
          })
        })
        resumenTotales.abonos = resCargaCuadreAbonos;

        let resCargaCuadrePagos: Array<any> = [];
        resCargaCuadre.pagos.forEach(function (det) {
          resCargaCuadrePagos.push({
            fecha: String(det?.registro) ?? "",
            descripcion: String(det?.descripcion) ?? "",
            tipo: Number(det?.tipo) ?? 0,
            numero: String(det?.numero) ?? "",
            identificacion: String(det?.identificacion) ?? "",
            nombre: String(det?.nombre) ?? "",
            total: Number(det?.total) ?? 0,
          })
        })
        resumenTotales.pagos = resCargaCuadrePagos;
      }

      console.log("resumenTotales : ", resumenTotales)

      let hora: string = `${formEdition?.horaCierre}${':'}${formEdition.minutoCierre}`;

      let objetoEsportar = {
        cabecera: {
          cuadreCodigo: formEdition?.ccaCodigo ?? 0,
          fechaCierre: formEdition?.fechaCierre ?? "",
          horaCierre: hora,
          puntoVentaCodigo: formEdition?.puntoVenta?.codigoLocal ?? 0,
          puntoVentaDescripcion: formEdition?.puntoVenta?.host ?? "",
          localNombre: formEdition?.local?.codigo ?? 0,
          localCodigo: formEdition?.local?.nombre ?? 0,
          cuadreNumero: formEdition?.numero ?? 0,
          usuarioNombre: formEdition?.usuarioNombre ?? "",
          usuarioCodigo: formEdition?.usuarioCodigo ?? 0,
          cuadreTotal: formEdition?.cuadreTotal ?? 0,
        }, detalle: resumenTotales
      }

      console.log("objetoEsportar : ", objetoEsportar)


      setDatosExportar(objetoEsportar);

      return true;
    } catch (error) {
      stopLoader();
      console.error(error);
      return false;
    }

  }, [
    formEdition,
    playLoader, stopLoader
  ]);

  const onParseInfoReporteDetalle = React.useCallback(async (tipoImpresion: PLANTILLA_IMPRIMIR) => {
    console.log("onParseInfoReporteDetalle formEdition", formEdition)
    let resCargaCuadre: any = null;
    playLoader();
    let findDetall: any = {
      ccaCod1: formEdition.ccaCod1,
      vendedorCodigo: formEdition?.vendedor?.vendedorCodigo ?? 0,
      localCodigo: formEdition?.local?.codigo ?? 0,
      puntoVentaCodigo: formEdition?.puntoVenta?.codigoPuntoVenta ?? -1
    }
    console.log("findDetall", findDetall)

    try {
      resCargaCuadre = await CuadresService.getCuadreCajaReportDetalles(findDetall);
      stopLoader();

      let resumenTotales: TotalesDetalle = {
        facturas: [],
        notasCredito: [],
        depositosGarantia: [],
        ventasPorLinea: [],
      };

      if (resCargaCuadre) {
        let resCargaCuadreFacturas: Array<any> = [];
        resCargaCuadre.facturas.forEach(function (det) {
          resCargaCuadreFacturas.push({
            cheque: String(det?.cheque) ?? "",
            descripcion: String(det?.descripcion) ?? "",
            fecha: String(det?.fecha) ?? "",
            numero: String(det?.numero) ?? "",
            identificacion: String(det?.identificacion) ?? "",
            nombre: String(det?.nombre) ?? "",
            abono: String(det?.abono) ?? "",
            total: Number(det?.total) ?? 0,
            anulado: String(det?.anulado) ?? "",
          })
        })
        resumenTotales.facturas = resCargaCuadreFacturas;

        let resCargaCuadreNotasCredito: Array<any> = [];
        resCargaCuadre.notasCredito.forEach(function (det) {
          resCargaCuadreNotasCredito.push({
            descripcion: String(det?.descripcion) ?? "",
            fecha: String(det?.fecha) ?? "",
            numero: String(det?.numero) ?? "",
            identificacion: String(det?.identificacion) ?? "",
            nombre: String(det?.nombre) ?? "",
            abono: String(det?.abono) ?? "",
            total: Number(det?.total) ?? 0,
            anulado: String(det?.anulado) ?? "",
          })
        })
        resumenTotales.notasCredito = resCargaCuadreNotasCredito;


        let resCargaDepositosPorGarantia: Array<any> = [];
        resCargaCuadre.depositosGarantia.forEach(function (det) {
          resCargaDepositosPorGarantia.push({
            descripcion: String(det?.descripcion) ?? "",
            identificacion: String(det?.identificacion) ?? "",
            nombre: String(det?.nombre) ?? "",
            numero: String(det?.numero) ?? "",
            fecha: String(det?.registro) ?? "",
            tipo: Number(det?.tipo) ?? 0,
            total: Number(det?.total) ?? 0,
          })
        })
        resumenTotales.depositosGarantia = resCargaDepositosPorGarantia;

        let resCargaVentasPorLinea: Array<any> = [];
        resCargaCuadre.ventasPorLinea.forEach(function (det) {
          resCargaVentasPorLinea.push({
            descripcion: String(det?.descripcion) ?? "",
            total: Number(det?.total) ?? 0,
          })
        })
        resumenTotales.ventasPorLinea = resCargaVentasPorLinea;
      }


      let hora: string = `${formEdition?.horaCierre}${':'}${formEdition.minutoCierre}`;

      let objetoEsportar = {
        cabecera: {
          cuadreCodigo: formEdition?.ccaCodigo ?? 0,
          fechaCierre: formEdition?.fechaCierre ?? "",
          horaCierre: hora,
          puntoVentaCodigo: formEdition?.puntoVenta?.codigoLocal ?? 0,
          puntoVentaDescripcion: formEdition?.puntoVenta?.host ?? "",
          localNombre: formEdition?.local?.codigo ?? 0,
          localCodigo: formEdition?.local?.nombre ?? 0,
          cuadreNumero: formEdition?.numero ?? 0,
          usuarioNombre: formEdition?.usuarioNombre ?? "",
          usuarioCodigo: formEdition?.usuarioCodigo ?? 0,
          cuadreTotal: formEdition?.cuadreTotal ?? 0,
        }, detalle: resumenTotales
      }
      setDatosExportar(objetoEsportar);
      return true;
    } catch (error) {
      stopLoader();
      console.error(error)
      return false;
    }

  }, [
    formEdition,
    playLoader, stopLoader
  ]);

  const onParseInfoReporteResumen = React.useCallback(async (tipoImpresion: PLANTILLA_IMPRIMIR) => {
    console.log("onParseInfoReporteResumen formEdition", formEdition)
    let resCargaCuadre: any = null;
    playLoader();
    let findDetall: any = {
      ccaCod1: formEdition.ccaCod1,
      ccaCod2: formEdition.ccaCod2,
      ccaCod3: formEdition.ccaCod3,
      puntoVentaCodigo: formEdition?.puntoVenta?.codigoPuntoVenta ?? -1
    }
    console.log("findDetall", findDetall)

    try {
      resCargaCuadre = await CuadresService.getCuadreCajaReportResumen(findDetall);
      stopLoader();


      console.log("resCargaCuadre", resCargaCuadre)
      let resumenTotales: Array<any> = [];

      if (resCargaCuadre) {

        // resCargaCuadre.forEach(function (det) {
        //   resumenTotales.push({
        //     abono: Number(det?.abono) ?? 0,
        //     comprobante: String(det?.comprobante) ?? "",
        //     estado: String(det?.estado) ?? "",
        //     fecha: String(det?.fecha) ?? "",
        //     fpago: String(det?.fpago) ?? "",
        //     nombre: String(det?.nombre) ?? "",
        //     numero: String(det?.numero) ?? "",
        //     total: Number(det?.total) ?? 0,
        //     saldo: Number(det?.saldo) ?? 0,
        //   })
        // })

        let hora: string = `${formEdition?.horaCierre}${':'}${formEdition.minutoCierre}`;

        let objetoEsportar = {
          cabecera: {
            cuadreCodigo: formEdition?.ccaCodigo ?? 0,
            fechaCierre: formEdition?.fechaCierre ?? "",
            horaCierre: hora,
            puntoVentaCodigo: formEdition?.puntoVenta?.codigoLocal ?? 0,
            puntoVentaDescripcion: formEdition?.puntoVenta?.host ?? "",
            localNombre: formEdition?.local?.codigo ?? 0,
            localCodigo: formEdition?.local?.nombre ?? 0,
            cuadreNumero: formEdition?.numero ?? 0,
            usuarioNombre: formEdition?.usuarioNombre ?? "",
            usuarioCodigo: formEdition?.usuarioCodigo ?? 0,
            cuadreTotal: formEdition?.cuadreTotal ?? 0,
          },
          detalle: resCargaCuadre
        }

        setDatosExportar(objetoEsportar);


      }

      return true;
    } catch (error) {
      stopLoader();
      console.error(error)
      return false;
    }

  }, [
    formEdition,
    playLoader, stopLoader
  ]);

  const onParseInfoReporte = React.useCallback(async (tipoImpresion: PLANTILLA_IMPRIMIR) => {
    console.log("onParseInfoReporte formEdition", formEdition)
    let dataPrint: any = {};
    let resCargaCuadre: any = null;
    let cantidades = await onTotalizaCantidadComprobantes(tipoImpresion);
    if (tipoImpresion === PLANTILLA_IMPRIMIR.DENOMINACION_TICKET || tipoImpresion === PLANTILLA_IMPRIMIR.DENOMINACION_STARDAR) {
      playLoader();
      let findDetall: any = {
        ccaCod1: formEdition.ccaCod1
      }
      resCargaCuadre = await CuadresService.getCuadreCajaDetalleByCode(findDetall, currentTabCuadreIndex);
      console.log("resCargaCuadre", resCargaCuadre)
      stopLoader()

    } else if (tipoImpresion === PLANTILLA_IMPRIMIR.COBORSCARTERA_TICKETERA || tipoImpresion === PLANTILLA_IMPRIMIR.COBORSCARTERA_STARDAR) {
      playLoader();
      let findDetall: any = {
        ccaCod1: formEdition.ccaCod1,
        ccaCod2: formEdition.ccaCod2,
        ccaCod3: formEdition.ccaCod3
      }
      resCargaCuadre = await CuadresService.getCuadreCajaDetalleByCode(findDetall, currentTabCuadreIndex);
      stopLoader()
    }

    let hora: string = `${formEdition?.horaCierre}${':'}${formEdition.minutoCierre}`;

    dataPrint = {
      cabecera: {
        cuadreCodigo: formEdition?.ccaCodigo ?? 0,
        fechaCierre: formEdition?.fechaCierre ?? "",
        horaCierre: hora,
        puntoVentaCodigo: formEdition?.puntoVenta?.codigoLocal ?? 0,
        puntoVentaDescripcion: formEdition?.puntoVenta?.host ?? "",
        localNombre: formEdition?.local?.codigo ?? 0,
        localCodigo: formEdition?.local?.nombre ?? 0,
        cuadreNumero: formEdition?.numero ?? 0,
        usuarioNombre: formEdition?.usuarioNombre ?? "",
        usuarioCodigo: formEdition?.usuarioCodigo ?? 0,
        cuadreTotal: formEdition?.cuadreTotal ?? 0,
      },
      detalle: resCargaCuadre,
      totales: {
        facturas: cantidades?.facturas ?? 0,
        notasCredito: cantidades?.notasCredito ?? 0,
        retenciones: cantidades?.retenciones ?? 0,
        anticipos: cantidades?.anticipos ?? 0,
        cobrosCartera: cantidades?.cobrosCartera ?? 0,
        compras: cantidades?.compras ?? 0,
        pagosTesoreria: cantidades?.pagosTesoreria ?? 0,
        cheques: cantidades?.cheques ?? 0,
        credito: cantidades?.credito ?? 0,
        totalFacturasAnuladas: cantidades?.totalFacturasAnuladas ?? 0,
      },
    }
    console.log("dataPrint", dataPrint)
    setDatosExportar(dataPrint);



  }, [
    formEdition,
    playLoader, stopLoader, currentTabCuadreIndex,
    onTotalizaCantidadComprobantes
  ]);

  const printTicketera = React.useCallback(async (tipoImpresion: PLANTILLA_IMPRIMIR, design: boolean) => {
    console.log("printTicketera");
    setPlantillaNombre(tipoImpresion)
    await onParseInfoReporte(tipoImpresion)
    setReporte(design === true ? "Designer" : "Viewer")
  }, [onParseInfoReporte])

  const printComun = React.useCallback(async (tipoImpresion: PLANTILLA_IMPRIMIR, design: boolean) => {
    console.log("printComun");
    setPlantillaNombre(tipoImpresion)
    await onParseInfoReporte(tipoImpresion)
    setReporte(design === true ? "Designer" : "Viewer")
  }, [onParseInfoReporte])


  const printCuadreCajaTotales = React.useCallback(async (tipoImpresion: PLANTILLA_IMPRIMIR, design: boolean) => {
    console.log("printCuadreCajaTotales");
    setPlantillaNombre(tipoImpresion)
    let open = await onParseInfoReporteTotales(tipoImpresion)
    if (open) setReporte(design === true ? "Designer" : "Viewer")
  }, [onParseInfoReporteTotales])

  const printCuadreCajaDetalles = React.useCallback(async (tipoImpresion: PLANTILLA_IMPRIMIR, design: boolean) => {
    console.log("printCuadreCajaDetalles");
    setPlantillaNombre(tipoImpresion)
    let open = await onParseInfoReporteDetalle(tipoImpresion);
    if (open) setReporte(design === true ? "Designer" : "Viewer")
  }, [onParseInfoReporteDetalle])

  const printCuadreCajaResumen = React.useCallback(async (tipoImpresion: PLANTILLA_IMPRIMIR, design: boolean) => {
    console.log("printCuadreCajaResumen");
    setPlantillaNombre(tipoImpresion)
    let open = await onParseInfoReporteResumen(tipoImpresion);
    if (open) setReporte(design === true ? "Designer" : "Viewer")
  }, [onParseInfoReporteResumen])

  const onConfirmarPrint = React.useCallback((sms, design: boolean) => {
    let action: any = null;
    if (currentTabCuadreIndex === TabCuadres.Denominacion || currentTabCuadreIndex === TabCuadres.Facturas) {
      action = [
        Dialog.Action(
          <span><u>{"S"}</u>{"i"}</span>,
          (dialog) => {
            dialog.hide();
            printTicketera(PLANTILLA_IMPRIMIR.DENOMINACION_TICKET, design);
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"N"}</u>{"o"}</span>,
          (dialog) => {
            dialog.hide();
            printComun(PLANTILLA_IMPRIMIR.DENOMINACION_STARDAR, design);
          },
          'btn-danger',
          'c'
        ),
      ]
    }
    else if (currentTabCuadreIndex === TabCuadres.CobrosCartera) { // tab 5
      action = [
        Dialog.Action(
          <span><u>{"S"}</u>{"i"}</span>,
          (dialog) => {
            dialog.hide();
            printTicketera(PLANTILLA_IMPRIMIR.COBORSCARTERA_TICKETERA, design);
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"N"}</u>{"o"}</span>,
          (dialog) => {
            dialog.hide();
            printComun(PLANTILLA_IMPRIMIR.COBORSCARTERA_STARDAR, design);
          },
          'btn-danger',
          'c'
        ),
      ]
    }
    else if (currentTabCuadreIndex === TabCuadres.Totales) { // tab 8
      action = [
        Dialog.Action(
          <span><u>{"T"}</u>{"otal"}</span>,
          (dialog) => {
            dialog.hide(); // cuadrecajaDetalle
            printCuadreCajaTotales(PLANTILLA_IMPRIMIR.TOTALES_TOTALES, design);
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"R"}</u>{"esumen"}</span>,
          (dialog) => {
            dialog.hide(); // cuadrecaja
            printCuadreCajaResumen(PLANTILLA_IMPRIMIR.TOTALES_RESUMEN, design);
          },
          'btn-danger',
          'c'
        ),
        Dialog.Action(
          <span><u>{"D"}</u>{"etalle"}</span>,
          (dialog) => {
            dialog.hide(); // cuadrecajaDetalleCorpo
            printCuadreCajaDetalles(PLANTILLA_IMPRIMIR.TOTALES_DETALLE, design);
          },
          'btn-primary',
          'c'
        ),
      ]
    } else if (currentTabCuadreIndex === TabCuadres.Letras) { // tab 10

    }
    dialogRefCuadre.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body: <label style={{ fontSize: "14px", fontWeight: 600 }}>{sms}</label>,
      actions: action,
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
        dispatch(clearButtonClick(tabId))
        setReporte(null);
        Dialog.resetOptions();
      }
    });
    return;
  }, [
    printComun, printTicketera, currentTabCuadreIndex, tabId, dispatch,
    printCuadreCajaDetalles, printCuadreCajaResumen, printCuadreCajaTotales
  ]);



  const handlePrint = React.useCallback((design: boolean) => {
    console.log("handlePrint")
    console.log("datosEdicion", datosEdicion)

    Dialog.resetOptions();

    if (currentTabCuadreIndex === TabCuadres.Denominacion || currentTabCuadreIndex === TabCuadres.Facturas) {
      onConfirmarPrint("Desea imprimir en la ticketera?", design);
    } else if (currentTabCuadreIndex === TabCuadres.CobrosCartera) { // tab 5
      onConfirmarPrint("Desea imprimir en la ticketera principal?", design);
    } else if (currentTabCuadreIndex === TabCuadres.Totales) { // tab 8
      onConfirmarPrint("Desea imprimir?", design);
    } else if (currentTabCuadreIndex === TabCuadres.Letras) { // tab 10
      onConfirmarPrint("Desea imprimir en la ticketera?", design);

    }

  }, [
    onConfirmarPrint, currentTabCuadreIndex, datosEdicion
  ])


  const onErrorConfirm = React.useCallback((messages: any, tittle?: string) => {
    Dialog.resetOptions();
    dialogRefCuadre.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body:
        <>
          <strong style={{ fontSize: "14px" }}>{tittle}</strong>
          <br />
          <CAlert color='danger'>
            <ul className="errorList"  >
              {messages.map(function (item, id) {
                return <li key={id} >{item}</li>
              })}
            </ul>
          </CAlert>
        </>,
      actions: [
        Dialog.Action(
          <span><u>{"A"}</u>{"ceptar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"C"}</u>{"ancelar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-danger',
          'c'
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
      }
    });
    return;
  }, []);

  const onConfirmarRegistro = React.useCallback((sms, method) => {
    dialogRefCuadre.current.show({
      title: <><strong>{"Ácatha"}</strong></>,
      body: <label style={{ fontSize: "14px", fontWeight: 600 }}>{sms}</label>,
      actions: [
        Dialog.Action(
          <span><u>{"A"}</u>{"ceptar"}</span>,
          (dialog) => {
            method();
            dialog.hide();
          },
          'btn-success',
          'n'
        ),
        Dialog.Action(
          <span><u>{"C"}</u>{"ancelar"}</span>,
          (dialog) => {
            dialog.hide();
          },
          'btn-danger',
          'c'
        ),
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide();
      }
    });
    return;
  }, []);

  const onMarcaLocal = React.useCallback((newValue: Puntoventa) => {
    let filtrado = locales.findIndex((item: any) => {
      return Number(item.codigo) === Number(newValue.codigoLocal)
    });
    if (filtrado > -1) {
      dispatch(updateLocalOnly({
        local: locales[filtrado],
        key: tabId
      }));
    }
  }, [tabId, dispatch, locales]);


  const onLocalChanged = React.useCallback((newValue: LocalOption) => {
    if (newValue !== null) {
      dispatch(updateLocalOnly({
        local: newValue,
        key: tabId
      }));
    }
  }, [tabId, dispatch]);

  const onPuntoVentaChanged = React.useCallback((newValue: Puntoventa) => {
    console.log("onPuntoVentaChanged", newValue)
    if (newValue !== null) {
      dispatch(updatePuntoVentaOnly({
        puntoventa: newValue,
        key: tabId
      }));
      onMarcaLocal(newValue)
    } else {
      dispatch(updatePuntoVentaOnly({
        puntoventa: puntosVenta[0],
        key: tabId
      }));
      onMarcaLocal(puntosVenta[0])
    }
    setCurrentTabCuadreIndex(TabCuadres.Denominacion)
  }, [tabId, dispatch, onMarcaLocal, puntosVenta]);

  const onChangeDateCierre = React.useCallback((newValue) => {
    dispatch(updateFechaCierreOnly({
      fechaCierre: newValue.value,
      key: tabId
    }));
  }, [tabId, dispatch]);

  const onChangeHora = React.useCallback((newValue) => {
    dispatch(updateHoraCierreOnly({
      horaCierre: newValue.value,
      key: tabId
    }));
  }, [tabId, dispatch]);

  const onChangeMinutos = React.useCallback((newValue) => {
    dispatch(updateMinutoCierreOnly({
      minutoCierre: newValue.value,
      key: tabId
    }));
  }, [tabId, dispatch]);

  const cargarDenominacion = React.useCallback(async (denominacionApi: Array<DenominacionListado> | []) => {
    let denominacion: Array<any> = [];

    denominacionApi.forEach(function (det) {
      if (det.grupo === 0) {
        if (det.descripcion && String(det.descripcion).toUpperCase() === 'CIEN') {
          denominacion.push({ grupo: 0, descripcion: det.descripcion, total: Number(det.valor), valor: Number(det.valor), billeteValor: 100, cantidad: (Number(det.valor) / 100), tipo: 'billete' })
        }
        if (det.descripcion && String(det.descripcion).toUpperCase() === 'CINCUENTA') {
          denominacion.push({ grupo: 0, descripcion: det.descripcion, total: Number(det.valor), valor: Number(det.valor), billeteValor: 50, cantidad: (Number(det.valor) / 50), tipo: 'billete' })
        }
        if (det.descripcion && String(det.descripcion).toUpperCase() === 'VEINTE') {
          denominacion.push({ grupo: 0, descripcion: det.descripcion, total: Number(det.valor), valor: Number(det.valor), billeteValor: 20, cantidad: (Number(det.valor) / 20), tipo: 'billete' })
        }
        if (det.descripcion && String(det.descripcion).toUpperCase() === 'DIEZ') {
          denominacion.push({ grupo: 0, descripcion: det.descripcion, total: Number(det.valor), valor: Number(det.valor), billeteValor: 10, cantidad: (Number(det.valor) / 10), tipo: 'billete' })
        }
        if (det.descripcion && String(det.descripcion).toUpperCase() === 'CINCO') {
          denominacion.push({ grupo: 0, descripcion: det.descripcion, total: Number(det.valor), valor: Number(det.valor), billeteValor: 5, cantidad: (Number(det.valor) / 5), tipo: 'billete' })
        }
        if (det.descripcion && String(det.descripcion).toUpperCase() === 'UNO') {
          denominacion.push({ grupo: 0, descripcion: det.descripcion, total: Number(det.valor), valor: Number(det.valor), billeteValor: 1, cantidad: (Number(det.valor) / 1), tipo: 'billete' })
        }
      }
    })

    denominacionApi.forEach(function (det) {
      if (det.grupo === 1) {
        if (det.descripcion && String(det.descripcion).toUpperCase() === 'UND') {
          denominacion.push({ grupo: 1, descripcion: det.descripcion, total: Number(det.valor), valor: Number(det.valor), monedaValor: 1, cantidad: (Number(det.valor) / 1), tipo: 'moneda' })
        }
        if (det.descripcion && String(det.descripcion).toUpperCase() === 'CINCUENTA') {
          denominacion.push({ grupo: 1, descripcion: det.descripcion, total: Number(det.valor), valor: Number(det.valor), monedaValor: 0.50, cantidad: (Number(det.valor) / 0.50), tipo: 'moneda' })
        }
        if (det.descripcion && String(det.descripcion).toUpperCase() === 'VEINTECINCO') {
          denominacion.push({ grupo: 1, descripcion: det.descripcion, total: Number(det.valor), valor: Number(det.valor), monedaValor: 0.25, cantidad: (Number(det.valor) / 0.25), tipo: 'moneda' })
        }
        if (det.descripcion && String(det.descripcion).toUpperCase() === 'DIEZ') {
          denominacion.push({ grupo: 1, descripcion: det.descripcion, total: Number(det.valor), valor: Number(det.valor), monedaValor: 0.10, cantidad: (Number(det.valor) / 0.10), tipo: 'moneda' })
        }
        if (det.descripcion && String(det.descripcion).toUpperCase() === 'CINCO') {
          denominacion.push({ grupo: 1, descripcion: det.descripcion, total: Number(det.valor), valor: Number(det.valor), monedaValor: 0.05, cantidad: (Number(det.valor) / 0.05), tipo: 'moneda' })
        }
        if (det.descripcion && String(det.descripcion).toUpperCase() === 'UNC') {
          denominacion.push({ grupo: 1, descripcion: det.descripcion, total: Number(det.valor), valor: Number(det.valor), monedaValor: 0.01, cantidad: (Number(det.valor) / 0.01), tipo: 'moneda' })
        }
      }
    })
    return await denominacion;
  }, []);

  const cargarCuadre = React.useCallback(async (cuadre: any, setInitData: boolean) => {
    console.log("**cargarCuadre", cuadre)
    let datacuadre: CuadresCajaListado = cuadre.info;
    if (datosEdicion.imprimir) {
      // setVerFacturaPdf({ claveAcceso: datosEdicion.claveAcceso, design: false });
    }
    if (datosEdicion.loading === false) {
      return;
    }

    const data = { ...defaultDatosEdicionCuadreCaja }; //utilidades.unfreeze(defaultDatosEdicionFactura);
    try {
      if (setInitData) dispatch(setDatosEdicion({
        data: { ...data },
        key: tabId
      }));
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.find
      }))
      let findPuntosVenta = {
        mensaje: 'Todos'
      }
      const puntosVentaApi = await CuadresService.getPuntosVentas(findPuntosVenta);

      const getCuadre = await CuadresService.getCuadreCajaByCode(datacuadre.cuadreCodigo);

      if (getCuadre) {
        let parseCuadre: CuadresCajaListado = await parseInfoCuadre(getCuadre);

        data.puntosVenta = puntosVentaApi;
        if (puntosVentaApi) {
          setPuntosVenta(puntosVentaApi);
          if (parseCuadre.puntoVentaCodigo) {
            const puntoVenta = puntosVentaApi.find(x => Number(x.codigoPuntoVenta) === Number(parseCuadre.puntoVentaCodigo));
            if (puntoVenta) {
              data.puntoVenta = puntoVenta;
            }
          } else {
            data.puntoVenta = puntosVentaApi[0];
          }
        }

        let findLocales = {
          mensaje: 'Elija una opción'
        }
        const locales = await CuadresService.getLocales(findLocales);
        if (locales) {
          setLocales(locales);
          if (parseCuadre.localCodigo) {
            const localFind = locales.find(x => x.codigo === parseCuadre.localCodigo);
            if (localFind) {
              data.local = localFind;
            }
          } else {
            data.local = locales[0];
          }
        }

        const formasPg = await CuadresService.getComboTipoPago();
        if (formasPg.length > 0) {
          const formas: Array<formasPago> = [];
          for (const fpg of formasPg) {
            if (fpg.codigo !== 28) {
              formas.push({
                codigo: Number(fpg.codigo) ?? -1,
                descripcion: String(fpg.descripcion) ?? "",
                grupo: String(fpg.grupo) ?? "",
              });
            }
          }
          data.dpfpagos = formas;
        }

        let findVendedores = {
          mensaje: 'Todos'
        }
        const vendedoresOptions = await CuadresService.getVendedores(findVendedores);
        if (vendedoresOptions) {
          data.dpvendedores = vendedoresOptions;
          data.vendedor = vendedoresOptions[0];
        }

        let hora: number = 0;
        let min: number = 0;
        let sec: string = "00";

        let descHoraCierre = parseCuadre.cuadreFechaHoraCierre.split(':');
        if (descHoraCierre.length) {
          hora = Number(descHoraCierre[0]);
          min = Number(descHoraCierre[1]);
          sec = String(descHoraCierre[2]);
        }


        data.ccaCodigo = Number(parseCuadre?.cuadreCodigo) ?? 0;
        console.log("parse date fecha cierre api")
        console.log(DateUtils.apiDateToPickersDate(parseCuadre?.cuadreFechaCierre))
        data.fechaCierre = DateUtils.apiDateToPickersDate(parseCuadre?.cuadreFechaCierre) ?? ""; // String() ?? "";
        data.horaCierre = hora;
        data.minutoCierre = min;
        data.segundosCierre = sec;

        data.usuarioCodigo = Number(parseCuadre?.usuarioCodigo) ?? 0;
        data.usuarioNombre = String(parseCuadre?.usuarioNombre) ?? "";
        // data.cuadrePunto = String(parseCuadre?.cuadrePunto) ?? "";
        // data.cuadreTotal = Number(parseCuadre?.cuadreTotal) ?? 0;
        data.numero = Number(parseCuadre?.cuadreNumero) ?? 0;
        console.log("captura denominacion", parseCuadre?.cuadreDenominacion)
        data.denominacion = await cargarDenominacion(parseCuadre?.cuadreDenominacion ?? [])

      }
      const getAllCuadresView = await CuadresService.getAllCuadresView(datacuadre.cuadreFechaCierre + " " + datacuadre.cuadreFechaHoraCierre, datacuadre.localCodigo, datacuadre.puntoVentaCodigo);
      if (getAllCuadresView) {
        console.log("getAllCuadresView", getAllCuadresView)
        let parseCuadre = await parseInfoAllCuadres(data, getAllCuadresView);
        console.log("res parseCuadre", parseCuadre);
        data.venInicial1 = parseCuadre.venInicial1;
        data.venFinal1 = parseCuadre.venFinal1;
        data.ccaCod1 = parseCuadre.ccaCod1;

        data.venInicial2 = parseCuadre.venInicial2;
        data.venFinal2 = parseCuadre.venFinal2;
        data.ccaCod2 = parseCuadre.ccaCod2;

        data.venInicial3 = parseCuadre.venInicial3;
        data.venFinal3 = parseCuadre.venFinal3;
        data.ccaCod3 = parseCuadre.ccaCod3;

        data.venInicial4 = parseCuadre.venInicial4;
        data.venFinal4 = parseCuadre.venFinal4;
        data.ccaCod4 = parseCuadre.ccaCod4;
        data.venInicial5 = parseCuadre.venInicial5;
        data.venFinal5 = parseCuadre.venFinal5;
        data.ccaCod5 = parseCuadre.ccaCod5;

        data.venInicial6 = parseCuadre.venInicial6;
        data.venFinal6 = parseCuadre.venFinal6;
        data.ccaCod6 = parseCuadre.ccaCod6;

        data.venInicial7 = parseCuadre.venInicial7;
        data.venFinal7 = parseCuadre.venFinal7;
        data.ccaCod7 = parseCuadre.ccaCod7;

        data.venInicial8 = parseCuadre.venInicial8;
        data.venFinal8 = parseCuadre.venFinal8;
        data.ccaCod8 = parseCuadre.ccaCod8;

        data.venInicial9 = parseCuadre.venInicial9;
        data.venFinal9 = parseCuadre.venFinal9;
        data.ccaCod9 = parseCuadre.ccaCod9;

        data.venInicial10 = parseCuadre.venInicial10;
        data.venFinal10 = parseCuadre.venFinal10;
        data.ccaCod10 = parseCuadre.ccaCod10;

      }

      data.loader = {
        mensaje: '',
        show: false
      }
      data.loading = false;

      dispatch(setDatosEdicion({
        data: data,
        key: tabId
      }));

      // seleccionar para eliminarlo

      dispatch(seleccionarCuadre({
        cuadreCodigo: getCuadre?.ccaCodigo,
        cuadreFechaCierre: getCuadre?.cuadreFechaCierre,
        cuadreFechaHoraCierre: getCuadre?.cuadreFechaHoraCierre,
        localCodigo: getCuadre?.localCodigo,
        puntoVentaCodigo: getCuadre?.puntoCodigo,
      }));

      dispatch(setButtons({
        tabKey: tabId,
        buttons: {
          ...CuadresCajaButtons,
          Eliminar: true,
          Guardar: false,
          Deshacer: false,
          Descuento: false
        }
      }))

      dispatch(updateGenerarCuadreOnly({
        generarCuadre: true,
        key: tabId
      }));

      //   }
    } catch (error) {
      console.error(error)
      data.tieneError = true;
      data.mensajeError = JSON.stringify(error);
      data.loading = false;
      dispatch(setDatosEdicion({
        data: data,
        key: tabId
      }));
    }

  }, [
    datosEdicion.imprimir, datosEdicion.loading,
    dispatch, tabId, cargarDenominacion,
    parseInfoCuadre, parseInfoAllCuadres
  ]);


  const modoNuevo = React.useCallback(async (puntoVenta: string, template: number, limpiar: boolean = false) => {
    if (loading === false && !limpiar) {
      return;
    }
    const data = { ...defaultDatosEdicionCuadreCaja };
    dispatch(setButtons({
      tabKey: tabId,
      buttons: {
        ...CuadresCajaButtons,
        Editar: false,
        Imprimir: false,
        Exportar: false,
        Autorizar: false,
        Enviar: false,
        Guardar: true,
      }
    }))
    try {
      const fecha = DateUtils.getCurrentDateAsString(formatoFechasDatePickers);//  moment().format('yyyy-MM-DD');

      if (template > 0) {
        // const venta = await VentasService.getVenta(template); //VentasService.getVentas(0, "", "", "", "", "", "", undefined, clave_acceso, undefined, undefined, undefined, TiposComprobantesSri.Factura);
        // if (venta) {
        //   const detalles = await VentasService.getDetalleVenta(template, TiposComprobantesSri.Factura);
        //   const cliente = await ClientesService.getCliente(venta?.clienteIdentificacion);
        //   const tmpDet2 = VentaHelper.detallesToDetallesVenta(detalles);
        //   data.detalles = tmpDet2;
        //   data.cliente = cliente;
        //   data.observaciones = venta.observaciones;
        //   const formasPago = await VentasService.getFormasPago(cliente.identificacion);
        //   data.formasPago = formasPago;
        //   const fp = formasPago.find(fp => fp.codigo === venta.formaPago);
        //   if (fp) {
        //     data.formaPago = [fp];
        //   }
        //   data.desdePlantilla = true;
        // }
      } else {

        let findPuntosVenta = {
          mensaje: 'Todos'
        }
        const puntosVenta = await CuadresService.getPuntosVentas(findPuntosVenta);
        console.log("puntosVenta", puntosVenta);

        data.puntosVenta = puntosVenta;
        if (puntosVenta) {
          data.puntoVenta = puntosVenta[0];
          setPuntosVenta(puntosVenta);
        }
        let findLocales = {
          mensaje: 'Elija una opción'
        }
        const locales = await CuadresService.getLocales(findLocales);
        if (locales) {
          data.local = locales[0];
          setLocales(locales);
        }

        const formasPg = await CuadresService.getComboTipoPago();
        if (formasPg.length > 0) {
          const formas: Array<formasPago> = [];
          for (const fpg of formasPg) {
            if (fpg.codigo !== 28) {
              formas.push({
                codigo: Number(fpg.codigo) ?? -1,
                descripcion: String(fpg.descripcion) ?? "",
                grupo: String(fpg.grupo) ?? "",
              });
            }
          }
          data.dpfpagos = formas;
        }

        let findVendedores = {
          mensaje: 'Todos'
        }
        const vendedoresOptions = await CuadresService.getVendedores(findVendedores);
        console.log("vendedoresOptions", vendedoresOptions);

        if (vendedoresOptions) {
          data.dpvendedores = vendedoresOptions;
          data.vendedor = vendedoresOptions[0];
        }

      }

      let fechaActual = new Date();
      let hours = fechaActual.getHours();
      let mins = fechaActual.getMinutes();

      data.numero = 0;
      data.fechaCierre = fecha;
      data.horaCierre = hours;
      data.minutoCierre = mins;
      data.segundosCierre = "00";
      data.identificadorTransaccion = uuidv4();

      data.loading = false;
      data.loader = {
        mensaje: "",
        show: false
      }

    } catch (error) {
      data.loading = false;
      data.tieneError = true;
      data.mensajeError = typeof error == 'string' ? error : JSON.stringify(error);
      data.loader = {
        mensaje: "",
        show: false
      }
      setToast(data.mensajeError, 'danger')
    }
    dispatch(setDatosEdicion({
      data: data,
      key: tabId
    }));

  }, [
    loading, tabId, dispatch, setToast
  ]);

  const onActiveTabDenChange = React.useCallback((dataTab: TabCuadres) => {
    setCurrentTabCuadreIndex(dataTab);
  }, []);

  const validarFormulario = React.useCallback(async () => {

    let errors: any = []

    if (denominacion.length === 0) {
      errors.push('No existe registro de efectivo.')
    }
    if (dptotalresumen.length === 0) {
      errors.push("No existe información a registrar.");
    }
    if ((puntoVenta && puntoVenta.codigoPuntoVenta < 0) || (local && local.codigo < 0)) {
      errors.push('Debe elegir un local o punto de venta.')
    }
    if (fechaCierre === null || fechaCierre === "") {
      errors.push('Ingrese la fecha del cierre.')
    }
    if (minutoCierre === null || horaCierre === null) {
      errors.push('Ingrese la hora y minuto(s) del cuadre.')
    }
    let fecha1: string = "";

    if (minutoCierre === 0 || horaCierre === 0) {
      fecha1 = fechaCierre + " 00:00:00";
    } else {
      fecha1 = fechaCierre + " " + minutoCierre + ":" + minutoCierre + ":00";
    }


    if (fecha1 === fecha) {
      errors.push('Ya existe un Cuadre con la misma fecha y hora.\n')
    }


    if (errors.length > 0) {
      return {
        errors: true,
        messages: errors
      }
    } else {
      return {
        errors: false,
        messages: errors
      }
    }
  }, [
    puntoVenta, local, denominacion, minutoCierre,
    horaCierre, fechaCierre, fecha, dptotalresumen
  ]);



  const guardar = React.useCallback(async () => {
    console.log("guardar")
    if (usuario == null || empresa == null) {
      return;
    }

    dispatch(setCurrentExecutingAction({
      tabKey: tabId,
      buttonType: undefined
    }))
    dispatch(buttonClick({
      tabKey: tabId,
      button: ButtonTypes.none
    }))

    let res = await validarFormulario();
    if (res.errors === true) {
      onErrorConfirm(res.messages, "Antes de continuar revise lo siguiente:");
      return;
    }

    try {

      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: true,
            mensaje: 'Guardando Cuadre de Caja...'
          }
        },
        key: tabId
      }));

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: ButtonTypes.save
      }));

      let denominacion_: any = {};
      let objetoBilletes: Array<any> = [];
      let objetoMonedas: Array<any> = [];

      let objetoKeysDenominacion = denominacion;

      for (let key in objetoKeysDenominacion) {
        if (objetoKeysDenominacion[key]['grupo'] === 1) {
          let name = objetoKeysDenominacion[key]['descripcion'];
          let valor = objetoKeysDenominacion[key]['valor'];
          objetoBilletes.push({
            [name]: valor,
            grupo: objetoKeysDenominacion[key].grupo
          });
        } else if (objetoKeysDenominacion[key]['grupo'] === 2) {
          let name = objetoKeysDenominacion[key]['descripcion'];
          let valor = objetoKeysDenominacion[key]['valor'];
          objetoMonedas.push({
            [name]: valor,
            grupo: objetoKeysDenominacion[key].grupo
          });
        }
      }
      console.log("objetoBilletes", objetoBilletes)
      console.log("objetoMonedas", objetoMonedas)


      denominacion_ = {
        0: objetoBilletes,
        1: objetoMonedas,
      }


      console.log("denominacion_", denominacion_)


      let detalle1_: Array<DetalleCuadreRegistro> = [];
      let detalle2_: Array<DetalleCuadreRegistro> = [];
      let detalle3_: Array<DetalleCuadreRegistro> = [];
      let detalle7_: Array<DetalleCuadreRegistro> = [];
      let detalle8_: Array<DetalleCuadreRegistro> = [];
      let detalle9_: Array<DetalleCuadreRegistro> = [];
      let detalle10_: Array<DetalleCuadreRegistro> = [];

      // CCD_VALOR, SUB_CODIGO, SUB_VALOR

      dptotales1.forEach(function (det: formasPagoCuadre) {
        detalle1_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString()
        })
      })


      dptotales2.forEach(function (det: formasPagoCuadre) {
        detalle2_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString()
        })
      })

      dptotales3.forEach(function (det: formasPagoCuadre) {
        detalle3_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString()
        })
      })

      dptotales7.forEach(function (det: formasPagoCuadre) {
        detalle7_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString()
        })
      })

      dptotales8.forEach(function (det: formasPagoCuadre) {
        detalle8_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString()
        })
      })


      dptotales9.forEach(function (det: formasPagoCuadre) {
        detalle9_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString()
        })
      })

      dptotales10.forEach(function (det: formasPagoCuadre) {
        detalle10_.push({
          cuadreCajaDetalleValor: det.ccdValor,
          subCodigo: det.subCodigo,
          subValor: det.subValor.toString()
        })
      })






      const cuadreCaja: TCuadreCajaIngresar = {
        cuadreCajaFechaCierre: DateUtils.format(fechaCierre, formatoFechasApi),
        localCodigo: local?.codigo ?? -1,
        puntoVentaCodigo: puntoVenta?.codigoPuntoVenta ?? -1,
        usuario: usuario.codigo,
        denominacion: denominacion_,
        1: {
          cuadreCajaCodigo: ccaCod1,
          ventaFinal: venFinal1,
          ventaInicial: venInicial1,
          tipoCuadreCajaCodigo: 1,
        },
        2: {
          cuadreCajaCodigo: ccaCod2,
          ventaFinal: venFinal2,
          ventaInicial: venInicial2,
          tipoCuadreCajaCodigo: 2,
        },
        3: {
          cuadreCajaCodigo: ccaCod3,
          ventaFinal: venFinal3,
          ventaInicial: venInicial3,
          tipoCuadreCajaCodigo: 3,
        },
        4: {
          cuadreCajaCodigo: ccaCod4,
          ventaFinal: venFinal4,
          ventaInicial: venInicial4,
          tipoCuadreCajaCodigo: 4,
        },
        5: {
          cuadreCajaCodigo: ccaCod5,
          ventaFinal: venFinal5,
          ventaInicial: venInicial5,
          tipoCuadreCajaCodigo: 5,
        },
        6: {
          cuadreCajaCodigo: ccaCod6,
          ventaFinal: venFinal6,
          ventaInicial: venInicial6,
          tipoCuadreCajaCodigo: 6,
        },
        7: {
          cuadreCajaCodigo: ccaCod7,
          ventaFinal: venFinal7,
          ventaInicial: venInicial7,
          tipoCuadreCajaCodigo: 7,
        },
        8: {
          cuadreCajaCodigo: ccaCod8,
          ventaFinal: venFinal8,
          ventaInicial: venInicial8,
          tipoCuadreCajaCodigo: 8,
        },
        9: {
          cuadreCajaCodigo: ccaCod9,
          ventaFinal: venFinal9,
          ventaInicial: venInicial9,
          tipoCuadreCajaCodigo: 9,
        },
        10: {
          cuadreCajaCodigo: ccaCod10,
          ventaFinal: venFinal10,
          ventaInicial: venInicial10,
          tipoCuadreCajaCodigo: 10,
        },
        detalle1: detalle1_,
        detalle2: detalle2_,
        detalle3: detalle3_,
        detalle7: detalle7_,
        detalle8: detalle8_,
        detalle9: detalle9_,
        detalle10: detalle10_,
      }

      const resultado = await CuadresService.ingresarCuadre(cuadreCaja);
      const resCargaCuadre = await CuadresService.getCuadreCajaByCode(resultado?.["1"]);

      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          ccaCod1: Number(resultado?.["1"]) ?? 0,
          ccaCod2: Number(resultado?.["2"]) ?? 0,
          ccaCod3: Number(resultado?.["3"]) ?? 0,
          ccaCod4: Number(resultado?.["4"]) ?? 0,
          ccaCod5: Number(resultado?.["5"]) ?? 0,
          ccaCod6: Number(resultado?.["6"]) ?? 0,
          ccaCod7: Number(resultado?.["7"]) ?? 0,
          ccaCod8: Number(resultado?.["8"]) ?? 0,
          ccaCod9: Number(resultado?.["9"]) ?? 0,
          ccaCod10: Number(resultado?.["10"]) ?? 0,
          fecha: fechaCierre,
          loader: {
            show: false,
            mensaje: ''
          },
          imprimir: empresa.imprimeAutomaticamenteAlfacturar ?? false
        },
        key: tabId
      }));

      let parseCuadre: CuadresCajaListado = await parseInfoCuadre(resCargaCuadre);

      dispatch(changeEditStatus({
        estado: StatesEdition.save,
        tabKey: tabId,
        info: parseCuadre,
        buttons: {
          ...CuadresCajaButtons,
          Guardar: false,
          Editar: true,
          Deshacer: false,
          Imprimir: true
        }
      }));

      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Registro Satisfactorio.',
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Success
      }))

      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))


    } catch (error) {

      console.log(error);
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al guardar el cuadre de caja ' + (typeof error === 'string' ? error : JSON.stringify(error)),
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Danger
      }));
      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          loader: {
            show: false,
            mensaje: ''
          }
        },
        key: tabId
      }));
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
      return;

    }

  }, [
    empresa, local, onErrorConfirm, puntoVenta, usuario, validarFormulario,
    denominacion, datosEdicion, dispatch, tabId, dptotales1, dptotales2, dptotales3,
    dptotales7, dptotales8, dptotales9, dptotales10,
    ccaCod1, ccaCod10, ccaCod2, ccaCod3, ccaCod4, ccaCod5,
    ccaCod6, ccaCod7, ccaCod8, ccaCod9, fechaCierre, venFinal1,
    venFinal10, venFinal2, venFinal3, venFinal4, venFinal5,
    venFinal6, venFinal7, venFinal8, venFinal9, venInicial1,
    venInicial10, venInicial2, venInicial3, venInicial4,
    venInicial5, venInicial6, venInicial7, venInicial8,
    venInicial9, parseInfoCuadre
  ]);

  const modoEdicion = React.useCallback(() => {
    if (ccaCodigo === 0) {
      dispatch(addToast({
        autoHide: 5000,
        content: 'El pedido no puede ser editado.',
        fade: true,
        id: '',
        title: 'Editar Cuadre',
        type: ToastTypes.Info
      }));
    } else {

    }
  }, [dispatch, ccaCodigo]);

  const handleButtonClick = React.useCallback((button: ButtonTypes) => {
    console.log("handleButtonClick", button)
    switch (button) {
      case ButtonTypes.save:
        guardar();
        break;
      case ButtonTypes.edit:
        modoEdicion();
        break;
      case ButtonTypes.print:
        handlePrint(false);
        break;
      case ButtonTypes.print_design:
        handlePrint(true);
        break;
      default:
        break;
    }
    dispatch(clearButtonClick(tabId))
  }, [
    dispatch, guardar, tabId, modoEdicion, handlePrint
  ])

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.new) {
      modoNuevo(puntoVentaGlobal ?? "", tab.info?.codigo ?? 0);
    } else if (tab.editStatus === StatesEdition.save) {
      cargarCuadre(info, true);
    }
  }, []);


  React.useEffect(() => {
    if (tab.globalButtonClick && tab.globalButtonClick !== ButtonTypes.none) {
      handleButtonClick(tab.globalButtonClick)
    }
  }, [handleButtonClick, tab.globalButtonClick]);

  React.useEffect(() => {
    if (imprimir) {
      handlePrint(false);
    }
  }, [imprimir, handlePrint, tabId, dispatch])

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }} >
      <BlockUi tag="div" loader={LoadingIndicator} blocking={datosEdicion.loader.show} message={datosEdicion.loader.mensaje}  >
        {openModal !== null &&
          <ModalListadoComprobantes
            show={openModal === null ? false : true}
            onClose={() => { console.log("close setOpenModal"); setOpenModal(null) }}
            cargarComprobantes={() => { console.log("cargarComprobantes") }}
            tipo={openModal}
            modulo={modulo}
            puntoVenta={puntoVenta}
          />
        }
        <ReportWithLocalDataModal
          show={(reporte !== null || datosExportar.length > 0)}
          onClose={() => setReporte(null)}
          data={datosExportar}
          fileName={plantillaNombre}
          mode={reporte ?? 'Viewer'}
          parameters={{ 'Reporte_Filtro': "" }}
          template={plantillaNombre}
          key='reportDesignerCuadre'
        />

        {datosEdicion.tieneError &&
          <VisualizaError titulo='Error en el cuadre' mensaje={datosEdicion.mensajeError} onOk={() => {
            dispatch(setMuestraError({ key: props.tabId, tieneError: false, mensajeError: "" }))

          }} />
        }

        <Dialog ref={dialogRefCuadre} />

        <fieldset disabled={tab.editStatus === StatesEdition.save} >
          <div>
            <RowContainer gutters={true} >
              <CustomCol xs='12' md='3'>
                <div>
                  <RowContainer gutters={true}>

                    <CustomCol xs='12'>
                      <Labeled
                        label='Punto de Venta '
                      >
                        <SelectBox
                          id='selectPuntoVenta'
                          displayExpr='host'
                          keyExpr='codigoPuntoVenta'
                          options={puntosVenta}
                          selected={puntoVenta}
                          placeholder=""
                          multiple={false}
                          onChange={onPuntoVentaChanged}
                          disabled={datosEdicion.ccaCodigo > 0}
                          clearButton={true}
                        // onEnterKey={onEnterKey}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                </div>
              </CustomCol>
              <CustomCol xs='12' md='7'>
                <div>
                  <RowContainer>
                    <CustomCol xs='12' md='5'>
                      <Labeled
                        label='Fecha Cierre'
                      >
                        <DateBox
                          value={fechaCierre}
                          onValueChanged={onChangeDateCierre}
                          disabled={datosEdicion.ccaCodigo > 0}
                        />
                      </Labeled>
                    </CustomCol>
                    <CustomCol xs='12' md='7'>
                      <Labeled
                        label='Hora'
                      >
                        <CRow gutters={false} className={'d-flex align-items-center'}>
                          <CCol xs='3' md='3' >
                            <NumberBox
                              disabled={datosEdicion.ccaCodigo > 0} format="#00" min={0} max={24} placeholder="" value={horaCierre} showSpinButtons={true} onValueChanged={onChangeHora} />
                          </CCol>
                          <CCol xs='0' className={'ml-1 mr-1'} >
                            {' : '}
                          </CCol>
                          <CCol xs='3' md='3' >
                            <NumberBox
                              disabled={datosEdicion.ccaCodigo > 0} format="#00" min={0} max={59} placeholder="" value={minutoCierre} showSpinButtons={true} onValueChanged={onChangeMinutos} />
                          </CCol>
                          <CCol xs='3' md='3' className={'ml-1 mr-1'} >
                            {' : ' + segundosCierre}
                          </CCol>
                        </CRow>
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                </div>
              </CustomCol>
            </RowContainer>
            <RowContainer gutters={true} >
              <CustomCol xs='12' md='3'>
                <div>
                  <RowContainer gutters={true}>

                    <CustomCol xs='12'>
                      <Labeled
                        label='Local '
                      >
                        <SelectBox
                          id='selectLocal'
                          displayExpr='nombre'
                          keyExpr='codigo'
                          options={locales}
                          selected={local}
                          placeholder=""
                          multiple={false}
                          onChange={onLocalChanged}
                          disabled={datosEdicion.ccaCodigo > 0}
                          clearButton={true}
                        // onEnterKey={onEnterKey}
                        />
                      </Labeled>
                    </CustomCol>
                  </RowContainer>
                </div>
              </CustomCol>
              <CustomCol xs='12' md='2'>
                <Labeled
                  label='Sin Cuotas'
                >
                  <NumberBox
                    value={0}
                    placeholder=''
                    onValueChanged={data => {

                    }}
                    showClearButton={true}
                    width="100%"
                  >
                    <TextBoxButton
                      name="sinCuotas"
                      location="after"
                      options={{
                        type: "normal",
                        stylingMode: "contained",
                        icon: 'plus',
                        onClick: () => {
                          setOpenModal(MODAL_DOCUMENTOS.CUOTAS)
                        }
                      }}
                    />
                  </NumberBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs='12' md='2'>
                <Labeled
                  label='Sin Cheque'
                >
                  <NumberBox
                    value={0}
                    placeholder=''
                    onValueChanged={data => {

                    }}
                    showClearButton={true}
                    width="100%"
                  >
                    <TextBoxButton
                      name="sinCheque"
                      location="after"
                      options={{
                        type: "normal",
                        stylingMode: "contained",
                        icon: 'plus',
                        onClick: () => {
                          setOpenModal(MODAL_DOCUMENTOS.CHEQUES)

                        }
                      }}
                    />
                  </NumberBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs='12' md='2'>
                <Labeled
                  label='Sin Tarjetas'
                >
                  <NumberBox
                    value={0}
                    placeholder=''
                    onValueChanged={data => {

                    }}
                    showClearButton={true}
                    width="100%"
                  >
                    <TextBoxButton
                      name="sinTarjetas"
                      location="after"
                      options={{
                        type: "normal",
                        stylingMode: "contained",
                        icon: 'plus',
                        onClick: () => {
                          setOpenModal(MODAL_DOCUMENTOS.TARJETAS)

                        }
                      }}
                    />
                  </NumberBox>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs='12' md='12'>
                <div>
                  <CTabs
                    key="tabsCuadres"
                    activeTab={currentTabCuadreIndex}
                    onActiveTabChange={onActiveTabDenChange}
                  >
                    <CNav variant="tabs" >

                      <CNavItem  >
                        <CNavLink
                          data-tab={TabCuadres.Denominacion}
                        >
                          <TabNavTitleDen
                            id={TabCuadres.Denominacion}
                            current={TabCuadres.Denominacion === currentTabCuadreIndex}
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          data-tab={TabCuadres.Facturas}
                        >
                          <TabNavTitleDen
                            id={TabCuadres.Facturas}
                            current={TabCuadres.Facturas === currentTabCuadreIndex}
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          data-tab={TabCuadres.NotasCredito}
                        >
                          <TabNavTitleDen
                            id={TabCuadres.NotasCredito}
                            current={TabCuadres.NotasCredito === currentTabCuadreIndex}
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          data-tab={TabCuadres.Retenciones}
                        >
                          <TabNavTitleDen
                            id={TabCuadres.Retenciones}
                            current={TabCuadres.Retenciones === currentTabCuadreIndex}
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          data-tab={TabCuadres.Anticipos}
                        >
                          <TabNavTitleDen
                            id={TabCuadres.Anticipos}
                            current={TabCuadres.Anticipos === currentTabCuadreIndex}
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          data-tab={TabCuadres.CobrosCartera}
                        >
                          <TabNavTitleDen
                            id={TabCuadres.CobrosCartera}
                            current={TabCuadres.CobrosCartera === currentTabCuadreIndex}
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          data-tab={TabCuadres.Compras}
                        >
                          <TabNavTitleDen
                            id={TabCuadres.Compras}
                            current={TabCuadres.Compras === currentTabCuadreIndex}
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          data-tab={TabCuadres.PagosTesoreria}
                        >
                          <TabNavTitleDen
                            id={TabCuadres.PagosTesoreria}
                            current={TabCuadres.PagosTesoreria === currentTabCuadreIndex}
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          data-tab={TabCuadres.Totales}
                        >
                          <TabNavTitleDen
                            id={TabCuadres.Totales}
                            current={TabCuadres.Totales === currentTabCuadreIndex}
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          data-tab={TabCuadres.Cheques}
                        >
                          <TabNavTitleDen
                            id={TabCuadres.Cheques}
                            current={TabCuadres.Cheques === currentTabCuadreIndex}
                          />
                        </CNavLink>
                      </CNavItem>
                      <CNavItem>
                        <CNavLink
                          data-tab={TabCuadres.Letras}
                        >
                          <TabNavTitleDen
                            id={TabCuadres.Letras}
                            current={TabCuadres.Letras === currentTabCuadreIndex}
                          />
                        </CNavLink>
                      </CNavItem>

                    </CNav>
                    <CTabContent style={{ overflowY: 'unset' }}  >
                      <CTabPane
                        data-tab={TabCuadres.Denominacion}
                      >
                        <Denominacion
                          tab={tab}
                          setToast={setToast}
                          playLoader={playLoader}
                          stopLoader={stopLoader}
                          tabId={tabId}
                          onErrorConfirm={onErrorConfirm}
                          onConfirmarRegistro={onConfirmarRegistro}
                        />
                      </CTabPane>
                      <CTabPane
                        data-tab={TabCuadres.Facturas}
                      >
                        <Facturas
                          setToast={setToast}
                          playLoader={playLoader}
                          stopLoader={stopLoader}
                          tabId={tabId}
                          onErrorConfirm={onErrorConfirm}
                          onConfirmarRegistro={onConfirmarRegistro}
                        />
                      </CTabPane>
                      <CTabPane
                        data-tab={TabCuadres.NotasCredito}
                      >
                        <NotasCredito
                          setToast={setToast}
                          playLoader={playLoader}
                          stopLoader={stopLoader}
                          tabId={tabId}
                          onErrorConfirm={onErrorConfirm}
                          onConfirmarRegistro={onConfirmarRegistro}
                        />
                      </CTabPane>
                      <CTabPane data-tab={TabCuadres.Retenciones}>
                        <Retenciones
                          setToast={setToast}
                          playLoader={playLoader}
                          stopLoader={stopLoader}
                          tabId={tabId}
                          onErrorConfirm={onErrorConfirm}
                          onConfirmarRegistro={onConfirmarRegistro}
                        />
                      </CTabPane>
                      <CTabPane data-tab={TabCuadres.Anticipos}>
                        <Anticipos
                          setToast={setToast}
                          playLoader={playLoader}
                          stopLoader={stopLoader}
                          tabId={tabId}
                          onErrorConfirm={onErrorConfirm}
                          onConfirmarRegistro={onConfirmarRegistro}
                        />
                      </CTabPane>
                      <CTabPane data-tab={TabCuadres.CobrosCartera}>
                        <CobrosCartera
                          setToast={setToast}
                          playLoader={playLoader}
                          stopLoader={stopLoader}
                          tabId={tabId}
                          onErrorConfirm={onErrorConfirm}
                          onConfirmarRegistro={onConfirmarRegistro}
                        />
                      </CTabPane>
                      <CTabPane data-tab={TabCuadres.Compras}>
                        <ComprasCuadres
                          setToast={setToast}
                          playLoader={playLoader}
                          stopLoader={stopLoader}
                          tabId={tabId}
                          onErrorConfirm={onErrorConfirm}
                          onConfirmarRegistro={onConfirmarRegistro}
                        />
                      </CTabPane>
                      <CTabPane data-tab={TabCuadres.PagosTesoreria}>
                        <PagosTesoreria
                          setToast={setToast}
                          playLoader={playLoader}
                          stopLoader={stopLoader}
                          tabId={tabId}
                          onErrorConfirm={onErrorConfirm}
                          onConfirmarRegistro={onConfirmarRegistro}
                        />
                      </CTabPane>
                      <CTabPane data-tab={TabCuadres.Totales}>
                        <Totales
                          setToast={setToast}
                          playLoader={playLoader}
                          stopLoader={stopLoader}
                          tabId={tabId}
                          onErrorConfirm={onErrorConfirm}
                          onConfirmarRegistro={onConfirmarRegistro}
                        />
                      </CTabPane>
                      <CTabPane data-tab={TabCuadres.Cheques}>
                        <Cheques
                          setToast={setToast}
                          playLoader={playLoader}
                          stopLoader={stopLoader}
                          tabId={tabId}
                          onErrorConfirm={onErrorConfirm}
                          onConfirmarRegistro={onConfirmarRegistro}
                        />
                      </CTabPane>
                      <CTabPane data-tab={TabCuadres.Letras}>
                        <Letras
                          setToast={setToast}
                          playLoader={playLoader}
                          stopLoader={stopLoader}
                          tabId={tabId}
                          onErrorConfirm={onErrorConfirm}
                          onConfirmarRegistro={onConfirmarRegistro}
                        />
                      </CTabPane>
                    </CTabContent>
                  </CTabs>
                </div>
              </CustomCol>
            </RowContainer>

            <div id="detalleCuadreyTotales" >

              {isMobile &&
                <>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Subtotal</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      {/* <small>{datosEdicion.totales.subtotal.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Subtotal 0%</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      {/* <small>{datosEdicion.totales.subtotal0.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>ICE</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      {/* <small>{datosEdicion.totales.ice.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Subtotal IVA</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      {/* <small>{datosEdicion.totales.subtotalIva.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Impuestos</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      {/* <small>{datosEdicion.totales.iva.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Descuento  Items</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      {/* <small>{datosEdicion.totales.descuento.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Total</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      {/* <small>{datosEdicion.totales.total.toFixed(2)}</small> */}
                    </CustomCol>
                  </RowContainer>
                </>
              }
            </div>
          </div>
        </fieldset>
        {isMobile &&
          <div style={{ height: '40px' }} />
        }
      </BlockUi>
    </div >
  );
};


export const defaultDatosEdicionCuadreCaja: CuadresCajaDatosEdicion = {
  ccaCodigo: 0,
  usuarioNombre: "",
  usuarioCodigo: 0,
  cuadreTotal: 0,
  identificadorTransaccion: "",
  numero: 0,
  fecha: "",
  fechaCierre: "",
  puntosVenta: [],
  puntoVenta: null,
  horaCierre: 0,
  minutoCierre: 0,
  segundosCierre: "00",
  local: null,
  tieneError: false,
  mensajeError: "",
  loader: {
    mensaje: 'Cargando...',
    show: true
  },
  totales: {
    totalEfectivo: 0,
    totalEgresos: 0,
    descuadre: 0,
  },
  imprimir: false,
  loading: true,
  totalinicialb_ti: 0, //
  totalinicial_ti: 0,
  totalesaldosi_ti: 0,

  totalcajab_ti: 0,
  totalegreso_ti: 0,
  descuadre_ti: 0,
  descuadredes_ti: "",
  totalegresosb_ti: 0,
  valortotalb_ti: 0,
  anuladascierre1_ti: 0,
  total1_ti: 0,
  dptotales1: [],
  dptotales: [],
  dpfpagos: [],
  dptotalresumen: [],

  totalcaja_ti: 0,
  totalegresos_ti: 0,
  valortotal_ti: 0,
  dpegresosboveda: [],

  generarCuadre: false,
  ccaCod1: 0,
  ccaCod2: 0,
  ccaCod3: 0,
  ccaCod4: 0,
  ccaCod5: 0,
  ccaCod6: 0,
  ccaCod7: 0,
  ccaCod8: 0,
  ccaCod9: 0,
  ccaCod10: 0,
  venInicial1: 0,
  venFinal1: 0,
  venInicial2: 0,
  venFinal2: 0,
  venInicial3: 0,
  venFinal3: 0,
  venInicial4: 0,
  venFinal4: 0,
  venInicial5: 0,
  venFinal5: 0,
  venInicial6: 0,
  venFinal6: 0,
  venInicial7: 0,
  venFinal7: 0,
  venInicial8: 0,
  venFinal8: 0,
  venInicial9: 0,
  venFinal9: 0,
  venInicial10: 0,
  venFinal10: 0,
  dpcierre1: [],
  dpcierre2: [],
  dptotales2: [],
  anuladascierre2_ti: 0,
  total2_ti: 0,
  dpcierre3: [],
  dpcierre4: [],
  dpcierre5: [],

  dptotales3: [],
  anuladascierre3_ti: 0,
  total3_ti: 0,

  dpcierre7: [],
  dptotales7: [],
  total7_ti: 0,

  dpcierre8: [],
  dptotales8: [],
  total8_ti: 0,

  dpcierre9: [],
  dptotales9: [],
  totalcom_ti: 0,

  dpcierre10: [],
  dptotales10: [],
  totaltesoreria_ti: 0,

  dpvendedores: [],
  vendedor: null,
  dpingresosboveda: [],
  totalsaldoi_ti: 0,
  totalbovedai_ti: 0,
  totalingresosb_ti: 0,
  valortotalboveda_ti: 0,

  dpingresos: [],
  denominacion: []
}

const buttonsProductoStyle = { marginLeft: '2px' };

export const CuadresCajaButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: true,
  Imprimir: true,

}

export default React.memo(CuadreCaja);