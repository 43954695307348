import React, { memo } from 'react';
import { CCol, CRow, CTooltip, CLink } from '@coreui/react'
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Paging,
  LoadPanel,
  Pager, Button as DatagridButton
} from 'devextreme-react/data-grid'
import { RootState } from '../../../../../../../store/store';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { VentasService } from '../../../../../services/ventas.service';
import { CompraService } from '../../../../../../compras/services/compra.service';

type infoPaginationType = {
  desde: number,
  hasta: number,
  paginaActual: number,
  totalPaginas: number,
  totalRegistros: number
}

type findItems = {
  descripcion: string,
  codigo: string
}

interface TablaItemsProps {
  transaccion: string,
  name: string,
  data: any,
  capturaSeleccion: any,
  filaSeleccionada: any,
  actionDelete: any,
  funcion: string,
  returnItem: (item) => void,
  resetFunction: () => void,
  pagination: infoPaginationType,
  cliente?: any,
  proveedor?: any,
  fecha: any,
  dataSearch: any,
  setDataItems: (data) => void
}

const infoPaginationInit = {
  desde: 1,
  hasta: 10,
  paginaActual: 0,
  totalPaginas: 0,
  totalRegistros: 0
}

const infoFind = {
  descripcion: "",
  codigo: ""
}

const TablaItems = (props: TablaItemsProps) => {

  const dispatch = useDispatch();

  const tablaRef = React.useRef<any>();
  const tablaRefPager = React.useRef<any>();

  const { funcion, returnItem, pagination, cliente, proveedor, fecha, dataSearch, resetFunction, setDataItems, transaccion } = props;
  const loader = useSelector((state: RootState) => state.global.loader);

  const [data, setData] = React.useState<any>([])
  const [infoPagination, setInfoPagination] = React.useState<infoPaginationType>(infoPaginationInit);
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [pageIndex, setPageIndex] = React.useState<number>(10)
  const [dataSeacrh, setDataSeacrh] = React.useState<findItems>(infoFind)

  React.useEffect(() => {
    setDataSeacrh(dataSearch)
  }, [dataSearch]);

  React.useEffect(() => {
    setInfoPagination(pagination)
  }, [pagination]);

  React.useEffect(() => {
    if (funcion === 'Deshacer') {
      setData([])
    } else if (funcion === 'Buscar') {
      onResetTab();
      resetFunction()
      setInfoPagination(infoPaginationInit)
      onSearch(dataSearch)
    }
  }, [funcion]);

  React.useEffect(() => {
    setData(props.data);
  }, [props.data])


  const selectionChanged = (selectionChanged) => {
    if (data.length > 0) {
      if (selectionChanged['selectedRowsData'].length > 0) {

        let filtroImpresora: number = data.findIndex((item: any) => {
          return Number(item.codigo) === Number(selectionChanged['selectedRowsData'][0]['codigo']);
        });

        props.capturaSeleccion(
          {
            index: filtroImpresora,
            row: selectionChanged['selectedRowsData'][0],
            selectedRow: filtroImpresora
          }
        )

      }
    }
  }

  const onRowDoubleClick = React.useCallback((e) => {
    const { data } = e;
    const item = data;
    const id = uuidv4();
    returnItem(item)
  }, [dispatch]);

  const handleOptionChange = React.useCallback((e) => {
    if (e.fullName === "paging.pageSize") {
      setPageSize(e.value)
    }
    if (e.fullName === "paging.pageIndex") {
      setPageIndex(e.value)
    }
    if (e.fullName === "selectedRowKeys") {
      if (data._items && data._items.length > 0) {
        if (e['value'].length > 0) {
          let filtrado: number = data._items.findIndex((item: any) => {
            return String(item.codigoBarras) === String(e['value']);
          });
          if (filtrado > -1) {
            props.capturaSeleccion(
              {
                index: filtrado,
                row: data._items[filtrado],
                selectedRow: filtrado
              }
            )
          }
        }
      }
    }
  }, [data]);

  const onSearch = React.useCallback((dataSeacrh) => {
    if (transaccion === 'ventas') {
      let resFind = VentasService.getItemsGridDatasource(cliente, fecha, dataSeacrh);
      setData(resFind)
      setDataItems(resFind);
    } else if (transaccion === 'compras') {
      let resFind = CompraService.getItemsGridDatasource(proveedor, dataSeacrh);
      setData(resFind)
      setDataItems(resFind);
    }
  }, [infoPagination, transaccion, cliente, proveedor]);

  const onResetTab = React.useCallback(() => {
    setPageIndex(0)
  }, []);

  return (
    <>
      <CRow>
        <CCol lg="6" className="d-flex justify-content-start">
          {
            data.length > 0 && (
              <>
                < CTooltip
                  key={"tool-more"}
                  placement="top"
                  content={"Hacer doble click sobre el item para copiar el item a la transacción."}
                >
                  <div className="card-header-actions">
                    <CLink className="card-header-action" >
                      <CIcon name="cil-info" />
                    </CLink>
                  </div>
                </CTooltip>
              </>
            )
          }
        </CCol>
        <CCol lg="6" className="d-flex justify-content-end">
          {/* <div>
            <CBadge color='info'>
              {`${data.length} REGISTROS ENCONTRADOS`}
            </CBadge>
          </div> */}
        </CCol>
      </CRow>
      <CRow>
        <CCol className='d-flex justify-content-center mt-2' md="12" lg="12" xs="12" >
          <DataGrid
            ref={tablaRef}
            dataSource={data ?? []}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={selectionChanged}
            onRowDblClick={onRowDoubleClick}
            allowColumnReordering={true}
            allowColumnResizing
            columnResizingMode='widget'
            columnAutoWidth={true}
            activeStateEnabled={true}
            onOptionChanged={handleOptionChange}
            remoteOperations={true}
          >
            <Paging
              pageIndex={pageIndex}
              enabled={true}
              defaultPageSize={10}
              pageSize={pageSize}
            />
            <Pager
              ref={tablaRefPager}
              showPageSizeSelector={true}
              allowedPageSizes={[8, 12, 20]}
              showNavigationButtons={true}
              showInfo={true}
              infoText="Page #{0}. Total: {1} ({2} items)"
            />
            <LoadPanel enabled={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Column type='buttons' width='7%' >
              <DatagridButton icon='arrowleft' visible={true} hint='Seleccionar'
                onClick={(e) => {
                  returnItem(e.row.data)
                }} />
            </Column>
            <Column
              dataType='string'
              dataField='codigoBarras'
              caption='Barras'
              width='15%'
            />
            <Column
              dataType='string'
              dataField='codigoInterno'
              caption='Cod. Interno'
              width='15%'
            />
            <Column
              dataType='string'
              dataField='descripcion'
              caption='Descripción'
              minWidth={'60%'}
              width={'60%'}
            />
            <Column
              dataType='number'
              dataField='existencia'
              caption='Stock'
              width='10%'
            />
            <Column
              dataType='number' dataField='pvpiva' caption='PVP'
              width='15%'
            />

          </DataGrid>
        </CCol>
      </CRow>

    </>
  )
};

export default memo(TablaItems);