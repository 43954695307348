import { CCol, CRow } from '@coreui/react';
import * as React from 'react';
import { RootState } from '../../../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid, {
  Column, HeaderFilter,
  Paging, Pager, ColumnChooser, LoadPanel
} from 'devextreme-react/data-grid';
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../../../views/componentes/colContainer';
import { utilidades } from '../../../../../../../../../helpers/utilidades';
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize';
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper';
import { ResumenListado, VendedoresListado } from '../../../../../types/types';
import { updateDpTotalResumenOnly, updateVendedorOnly } from '../../../store/editDataReducer';
import Labeled from '../../../../../../../../../views/componentes/labeledInput/labeledInput';
import SelectBox from '../../../../../../../../../views/componentes/selectBox/selectBox';
interface ITotalesProps {
  setToast: (texto, type) => void,
  playLoader: () => void,
  stopLoader: () => void,
  onErrorConfirm: (sms, title) => void,
  onConfirmarRegistro: (sms, method) => void
  tabId: string,
}



const Totales: React.FunctionComponent<ITotalesProps> = (props) => {
  const {
    tabId, setToast, playLoader, stopLoader,
    onErrorConfirm, onConfirmarRegistro
  } = props;
  const dataGrid = React.useRef<any>();


  const dispatch = useDispatch();
  const [heigth] = useGridResultadosSize();

  const dptotalresumen = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotalresumen });
  const dptotales1 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales1 });
  const dptotales7 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales7 });
  const dptotales8 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales8 });
  const dptotales10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales10 });
  const dptotales2 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales2 });
  const dptotales3 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales3 });
  const dptotales9 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales9 });
  const dptotales = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales });
  const dpvendedores = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpvendedores });
  const vendedor = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].vendedor });

  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [showPageSizeSelector,] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>([]);
  const [loaderTable, setLoaderTable] = React.useState<boolean>(false);

  const clearFilter = React.useCallback(() => {
    dataGrid.current.instance.clearSorting();
    dataGrid.current.instance.clearFilter();
  }, []);

  const llenarData = React.useCallback((data) => {
    clearFilter();
    let item: any = []
    let contar: number = 0;
    try {
      data.map(function (key, i) {
        let itemAdd: any = {}
        itemAdd = {
          ...key,
          keyId: Number(i)
        }
        contar = contar + 1;
        item.push(itemAdd);
      })
      setData(item.map(x => utilidades.unfreeze(x)))
    } catch (error) {
      console.error("on llenarData", error)
    }
  }, [clearFilter]);



  const verTotales = React.useCallback((dptotales1, dptotales7, dptotales8, dptotales10, dptotales2, dptotales3, dptotales9, dptotales) => {
    console.log("verTotales")
    console.log("dptotales1", dptotales1)
    setLoaderTable(true)
    let dptotalresumenLocal: Array<any> = [];
    let i: number = 0;
    let j: number = 0;
    let existe: boolean = false;

    //facturas
    for (i = 0; i < dptotales1.length; i++) {
      let addResumen: ResumenListado = { valor: String(dptotales1[i].subValor) ?? "", facturas: Number(dptotales1[i].ccdValor) ?? 0, anticipos: 0, cobros: 0, pagos: 0, notasCredito: 0, retenciones: 0, compras: 0, total: 0 };
      dptotalresumenLocal.push(addResumen);
    }

    console.log("dptotalresumenLocal", dptotalresumenLocal)


    //anticipos
    for (i = 0; i < dptotales7.length; i++) {
      existe = false;
      for (j = 0; j < dptotalresumenLocal.length; j++) {
        if (dptotalresumenLocal[j].valor === dptotales7[i].subValor) {
          dptotalresumenLocal[j].anticipos = dptotales7[i].ccdValor;
          existe = true;
        }
      }
      if (existe === false) {
        let addResumen: ResumenListado = { valor: dptotales7[i].subValor, facturas: 0, anticipos: Number(dptotales7[i].ccdValor), cobros: 0, pagos: 0, notasCredito: 0, retenciones: 0, compras: 0, total: 0 };
        dptotalresumenLocal.push(addResumen);
      }
    }

    //cobros
    for (i = 0; i < dptotales8.length; i++) {
      existe = false;
      for (j = 0; j < dptotalresumenLocal.length; j++) {
        if (dptotalresumenLocal[j].valor === dptotales8[i].subValor) {
          dptotalresumenLocal[j].cobros = dptotales8[i].ccdValor;
          existe = true;
        }
      }
      if (existe === false) {
        let addResumen: ResumenListado = { valor: dptotales8[i].subValor, facturas: 0, anticipos: 0, cobros: Number(dptotales8[i].ccdValor), pagos: 0, notasCredito: 0, retenciones: 0, compras: 0, total: 0 };
        dptotalresumenLocal.push(addResumen);
      }
    }

    //pagos
    for (i = 0; i < dptotales10.length; i++) {
      existe = false;
      for (j = 0; j < dptotalresumenLocal.length; j++) {
        if (dptotalresumenLocal[j].valor === dptotales10[i].subValor) {
          dptotalresumenLocal[j].pagos = dptotales10[i].ccdValor;
          existe = true;
        }
      }
      if (existe === false) {
        let addResumen: ResumenListado = { valor: dptotales10[i].subValor, facturas: 0, anticipos: 0, cobros: 0, pagos: Number(dptotales10[i].ccdValor) * -1, notasCredito: 0, retenciones: 0, compras: 0, total: 0 };
        dptotalresumenLocal.push(addResumen);
      }
    }

    //Notas de Credito
    for (i = 0; i < dptotales2.length; i++) {
      existe = false;
      for (j = 0; j < dptotalresumenLocal.length; j++) {
        if (dptotalresumenLocal[j].valor === dptotales2[i].subValor) {
          dptotalresumenLocal[j].notasCredito = dptotales2[i].ccdValor;
          existe = true;
        }
      }
      if (existe === false) {
        let addResumen: ResumenListado = { valor: dptotales2[i].subValor, facturas: 0, anticipos: 0, cobros: 0, pagos: 0, notasCredito: Number(dptotales2[i].ccdValor) * -1, retenciones: 0, compras: 0, total: 0 };
        dptotalresumenLocal.push(addResumen);
      }
    }

    //Retenciones
    for (i = 0; i < dptotales3.length; i++) {
      existe = false;
      for (j = 0; j < dptotalresumenLocal.length; j++) {
        if (dptotalresumenLocal[j].valor === dptotales3[i].subValor) {
          dptotalresumenLocal[j].retenciones = dptotales3[i].ccdValor;
          existe = true;
        }
      }
      if (existe === false) {
        let addResumen: ResumenListado = { valor: dptotales3[i].subValor, facturas: 0, anticipos: 0, cobros: 0, pagos: 0, notasCredito: 0, retenciones: Number(dptotales3[i].ccdValor) * -1, compras: 0, total: 0 };
        dptotalresumenLocal.push(addResumen);
      }
    }

    //compras
    for (i = 0; i < dptotales9.length; i++) {
      existe = false;
      for (j = 0; j < dptotalresumenLocal.length; j++) {
        if (dptotalresumenLocal[j].valor === dptotales9[i].subValor) {
          dptotalresumenLocal[j].compras = dptotales9[i].ccdValor;
          existe = true;
        }
      }
      if (existe === false) {
        let addResumen: ResumenListado = { valor: dptotales9[i].subValor, facturas: 0, anticipos: 0, cobros: 0, pagos: 0, notasCredito: 0, retenciones: 0, compras: Number(dptotales9[i].ccdValor) * -1, total: 0 };
        dptotalresumenLocal.push(addResumen);
      }
    }

    console.log("dptotales", dptotales)
    console.log("dptotalresumenLocal", dptotalresumenLocal)

    //TOTALES
    for (i = 0; i < dptotales.length; i++) {
      for (j = 0; j < dptotalresumenLocal.length; j++) {
        if (dptotalresumenLocal[j].valor === dptotales[i].subValor) {
          dptotalresumenLocal[j].total = dptotales[i].ccdValor;
        }
      }
    }

    dispatch(updateDpTotalResumenOnly({
      dptotalresumen: dptotalresumenLocal,
      key: tabId
    }));

    setLoaderTable(false)

  }, [dispatch, tabId]);


  const onVendedorChanged = React.useCallback((newValue: VendedoresListado) => {
    if (newValue !== null) {
      dispatch(updateVendedorOnly({
        vendedor: newValue,
        key: tabId
      }));
    }
  }, [tabId, dispatch]);

  React.useEffect(() => {
    llenarData(dptotalresumen)
  }, [dptotalresumen]);

  React.useEffect(() => {
    verTotales(dptotales1, dptotales7, dptotales8, dptotales10, dptotales2, dptotales3, dptotales9, dptotales);
  }, [
    dptotales1, dptotales7, dptotales8, dptotales10, dptotales2, dptotales3, dptotales9, dptotales
  ]);


  return (
    <>
      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">

          <RowContainer gutters={true} >
            <CustomCol xs='12' md='6'>
              <div>
                <RowContainer gutters={true}>

                  <CustomCol xs='12'>
                    <Labeled
                      label='Elija un vendedor '
                    >
                      <SelectBox
                        id='selectVendedor'
                        displayExpr='completo'
                        keyExpr='vendedorCodigo'
                        options={dpvendedores}
                        selected={vendedor}
                        placeholder=''
                        multiple={false}
                        onChange={onVendedorChanged}
                        clearButton={true}
                      />
                    </Labeled>
                  </CustomCol>
                </RowContainer>
              </div>
            </CustomCol>
          </RowContainer>

          <RowContainer >
            <CustomCol xs='12' md='12'   >

              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr='keyId'
                dataSource={data}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode='widget'
                height={heigth}
                ref={dataGrid}
              >
                <LoadPanel enabled={loaderTable} />
                <Paging defaultPageSize={20}/>
                <Pager
                  visible={data.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(data)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <ColumnChooser enabled={true} mode="select" />
                <HeaderFilter visible={true} />
                <Column dataField='valor' dataType='string' caption='Formas de Pago' width='25%' allowEditing={false} allowSearch={false} allowFiltering visible={true} />
                <Column dataField='facturas' caption='Factura' width='12%' allowEditing={false} visible={true} />
                <Column dataField='anticipos' caption='Anticipos' width='12%' allowEditing={false} visible={true} />
                <Column dataField='cobros' caption='Cobros' width='12%' allowEditing={false} visible={true} />
                <Column dataField='notasCredito' caption='Notas de Crédito' allowEditing={false} width={'15%'} visible={true} />
                <Column dataField='retenciones' caption='Retenciones' width='12%' allowEditing={false} visible={true} />
                <Column dataField='compras' caption='Compras' width='12%' allowEditing={false} visible={true} />
                <Column dataField='pagos' caption='Pagos' width='12%' allowEditing={false} visible={true} />
                <Column dataField='total' caption='TOTAL' width='15%' allowEditing={false} visible={true} />

              </DataGrid>

            </CustomCol>
          </RowContainer>

        </CCol>
      </CRow>

    </>
  )
}
export default Totales;
