import { RequestHelper } from "../../../helpers/requestHelper";
import { SesionService } from "../../../services/sesion.service";
import { DateRange, PagedResult, PaginationInfo } from "../../../store/types";
import {
  CompraDetalleInfo,
  CompraFullInfo,
  CompraInfo,
  CompraIngresar,
  CompraModificar,
  FormaPagoModuloCompras,
  ItemCompras,
  SustentosTributarios,
} from "../types/types";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { ProveedorBusqueda } from "../../proveedores/store/types";

export const CompraService = {
  buscarCompras,
  buscarComprobantesModificar,
  getCompra,
  getDetallesCompra,
  getFormasPago,
  buscarItems,
  getItemCodigoBarras,
  ingresar,
  getItemsDatasource,
  getItemsGridDatasource,
  getSustentosTributarios,
  deleteCompra,
  plantillaImportar,
  exportarDemo
};

async function deleteCompra(info: CompraInfo) {
  const postData = {
    codigo: info.codigo,
    tipoDocumento: "01"
  }
  const result = await RequestHelper.deleteRequest<CompraInfo>(
    "compras",
    "compras/cancel",
    "",
    postData
  );
  return result;
}

async function getDetallesCompra(
  codigo: number
): Promise<Array<CompraDetalleInfo>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    compraCodigo: codigo,
  };
  const detalles = await RequestHelper.get<Array<CompraDetalleInfo>>(
    "compras",
    "compras/getDetail",
    "",
    queryData
  );
  return detalles;
}

async function getItemCodigoBarras(codigoBarras: string, proveedor: ProveedorBusqueda | null): Promise<ItemCompras> {
  const sesion = SesionService.getCurrentSesion();
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion();

  let queryData: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    descripcion: "",
    barras: codigoBarras,
    exacta: 1,
    identificacion: '',
    establecimiento: ''
  };
  if (proveedor) {
    queryData.identificacion = proveedor.identificacion;
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    queryData.establecimiento = sesionAutorizacionModulo.establecimiento;
  }
  const items = await RequestHelper.get<Array<ItemCompras>>(
    "inventario",
    "items/searchItemsLocalSimplePurchase",
    "",
    queryData
  );
  if (items.length === 0) {
    // eslint-disable-next-line no-throw-literal
    throw "No se encuentra un item con código " + codigoBarras;
  }
  const itemsParsed = parseApiItemsData(items);
  // const itemsParsed = items as Array<ItemCompras>;
  return itemsParsed[0];
}

async function buscarItemCodigoBarras(codigoBarras: string, identificacionProveedor: string): Promise<Array<ItemCompras>> {
  const sesion = SesionService.getCurrentSesion();
  const sesionAutorizacionModulo = SesionService.getCurrentModulosAutorizacion();

  const query = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    local: sesion.local.codigo,
    barras: codigoBarras,
    exacta: false,
    identificacion: identificacionProveedor ?? "",
    establecimiento: ''
  }
  if (sesionAutorizacionModulo && sesionAutorizacionModulo.establecimiento) {
    query.establecimiento = sesionAutorizacionModulo.establecimiento;
  }
  const itemsApi = await RequestHelper.get<Array<any>>('inventario', 'items/searchItemsLocalSimplePurchase', '', query);
  const items = parseApiItemsData(itemsApi);
  // const itemsParsed = itemsApi as Array<ItemCompras>;
  return items;
}

function getItemsDatasource(proveedor) {
  const store = new CustomStore({
    key: "barras",
    byKey: async (key) => {
      const item = await buscarItemCodigoBarras(key, proveedor?.identificacion ?? "");
      return item.length > 0 ? item[0] : undefined;
      //console.log(key);
    },
    load: async (loadOptions) => {
      console.log(loadOptions);
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      };
      let pagedResult: PagedResult<ItemCompras>;
      pagedResult = await buscarItemsPaged(
        loadOptions.searchValue ?? "", "", paginInfo);
      console.log("current page ", pagedResult.currentPage);
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      };
    },
  });
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: "barras",
  });
  return source;
}

function getItemsGridDatasource(proveedor, dataSeacrh) {
  console.log("getItemsGridDatasource", dataSeacrh)
  const store = new CustomStore({
    key: "codigoBarras",
    byKey: async (key) => {
      const item = await buscarItemCodigoBarras(key, proveedor?.identificacion ?? "");
      return item.length > 0 ? item[0] : undefined;
      //console.log(key);
    },
    load: async (loadOptions) => {
      console.log(loadOptions);
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      };
      let pagedResult: PagedResult<ItemCompras>;
      console.log("loadOptions", loadOptions)
      pagedResult = await buscarItemsPagenate(
        dataSeacrh['descripcion'] ?? "", dataSeacrh["codigoBarras"] ?? "", paginInfo);
      console.log("current page ", pagedResult.currentPage);
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      };
    },
  });
  const source = new DataSource({
    totalCount: store.totalCount,
    paginate: true,
    pageSize: 10,
    store: store,
    key: "barras",
  });
  return source;
}

async function buscarItemsPagenate(descripcion: string, codigoBarras: string, pagination: PaginationInfo): Promise<PagedResult<ItemCompras>> {
  const sesion = SesionService.getCurrentSesion();
  const query = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    local: sesion.local.codigo,
    descripcion: descripcion,
    barras: codigoBarras,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
  }
  const pagedApiResult = await RequestHelper.get<any>('inventario', 'items/searchItemsLocal', '', query);
  console.log("pagedApiResult", pagedApiResult)
  const itemsParsed = pagedApiResult.auto as Array<ItemCompras>;
  // const itemsParsed = parseApiItemsData(pagedApiResult.auto);
  return {
    currentPage: parseInt(pagedApiResult.paginaActual),
    from: parseInt(pagedApiResult.desde),
    to: parseInt(pagedApiResult.hasta),
    count: parseInt(pagedApiResult.totalRegistros),
    result: itemsParsed,
  };
}

async function buscarItemsPaged(descripcion: string, codigoBarras: string, pagination: PaginationInfo): Promise<PagedResult<ItemCompras>> {
  const sesion = SesionService.getCurrentSesion();
  const query = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    local: sesion.local.codigo,
    descripcion: descripcion,
    barras: codigoBarras,
    isPaged: true,
    page: pagination.current,
    limit: pagination.size,
    identificacion: '',
    establecimiento: ''
  }
  const pagedApiResult = await RequestHelper.get<any>('inventario', 'items/searchItemsLocalSimplePurchase', '', query);
  // const pagedApiResult = await RequestHelper.get<any>('inventario', 'items/searchItemsLocal', '', query);
  const itemsParsed = pagedApiResult.auto as Array<ItemCompras>;
  // const itemsParsed = parseApiItemsData(pagedApiResult.auto);
  return {
    currentPage: parseInt(pagedApiResult.paginaActual),
    from: parseInt(pagedApiResult.desde),
    to: parseInt(pagedApiResult.hasta),
    count: parseInt(pagedApiResult.totalRegistros),
    result: itemsParsed,
  };
}

async function buscarItems(
  codigoBarras: string,
  nombre: string = ""
): Promise<Array<ItemCompras>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    descripcion: nombre,
    barras: codigoBarras,
    identificacion: '',
    establecimiento: ''
  };
  const items = await RequestHelper.get<Array<ItemCompras>>(
    "inventario",
    "items/searchItemsLocalSimplePurchase",
    "",
    queryData
  );
  const itemsParsed = parseApiItemsData(items);

  return itemsParsed;
}

async function buscarComprobantesModificar(
  identificacion: string
): Promise<Array<CompraModificar>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    id: identificacion,
  };
  const apiData = await RequestHelper.get<Array<any>>(
    "compras",
    "invoicemodify/all",
    "",
    queryData
  );
  const compras: Array<CompraModificar> = [];
  for (const data of apiData) {
    const numero = data.NUMERO.split("-");
    compras.push({
      comprobante: parseInt(data.CODIGO), //comprobante
      descripcion: data.TIC_COMPROBANTE, //descripcion
      establecimiento: numero[0], //establecimiento
      puntoEmision: numero[1], //ptoemision
      numero: numero[2], //numero
      fecha: data.COM_FECHA, //fecha
      proveedorCodigo: parseInt(data.PRV_CODIGO), //proveedor
      proveedorIdentificacion: data.PRV_IDENTIFICACION, //identificacion
      proveedorNombre: data.PRV_NOMBRE, //nombre
      proveedorDireccion: data.PRV_DIRECCION, //direccion
      proveedorTelefono: data.PRV_TELEFONO, //telefono
      proveedorEmail: data.PRV_EMAIL, //email
      codigoFormaPago: parseInt(data.FPAGO), //fpago
      nombreFormaPago: data.FPAGO_DES, //fpagodes
    });
  }
  return compras;
}

async function buscarCompras(
  range: DateRange | null,
  numero: string,
  identificacion: string,
  tipoDoc: string,
  fpago: string,
  claveAcceso: string
): Promise<Array<CompraInfo>> {
  const sesion = SesionService.getCurrentSesion();
  const queryData: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
  };

  if (range) {
    queryData.desde = range.inicio;
    queryData.hasta = range.fin;
  }

  if (numero && numero.length > 0) {
    queryData.compraNumero = numero;
  }

  if (identificacion && identificacion.length > 0) {
    queryData.proveedorIdentificacion = identificacion;
  }

  if (tipoDoc && tipoDoc.length > 0) {
    queryData.compraTipoDocumento = tipoDoc;
  }

  if (fpago && fpago.length > 0) {
    queryData.compraFormaPago = fpago;
  }

  if (claveAcceso) {
    queryData.claveAcceso = claveAcceso;
  }

  const compras = await RequestHelper.get<Array<CompraInfo>>(
    "compras",
    "compras/getAll",
    "",
    queryData
  );
  return compras;
}

async function getCompra(codigo: number): Promise<CompraFullInfo> {
  const sesion = SesionService.getCurrentSesion();
  const queryData: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    codigo,
  };
  const compra = await RequestHelper.get<CompraFullInfo>(
    "compras",
    "compras/get",
    "",
    queryData
  );
  return compra;
}

async function getFormasPago() {
  const sesion = SesionService.getCurrentSesion();
  const queryData: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    movimiento: "C",
  };
  const apiData = RequestHelper.get<Array<FormaPagoModuloCompras>>(
    "generales",
    "paymentForms/getCboxFP",
    "",
    queryData
  );
  return apiData;
}

async function ingresar(compra: CompraIngresar): Promise<any> {
  const result = await RequestHelper.post(
    "compras",
    "compras/ingresar",
    compra
  );
  return result;
}

function parseApiItemsData(apiresult: Array<any>): Array<ItemCompras> {
  const items = apiresult.map(x => {
    const item: ItemCompras = {
      codigo: parseInt(x.codigo),
      codigoInterno: x.codigoint,
      codigoBarras: x.barras,
      descripcion: x.descripcion,
      existencia: parseFloat(x.existencia),
      pvdiva: parseFloat(x.pvdiva),
      pvpiva: parseFloat(x.pvpiva),
      pvd: parseFloat(x.pvd),
      pvp: parseFloat(x.pvp),
      codigoImpuesto: parseInt(x.impuesto),
      tieneIce: (x.ice === '-1'),
      porcentajeIva: parseFloat(x.porcentajeiva),
      costo: parseFloat(x.costo),
      tipoCodigo: parseInt(x.tipo),
      numeroSerie: parseInt(x.numserie),
      plantilla: parseInt(x.plantilla),
      freeitem: parseInt(x.freeitem),
      gradoAcoholico: parseInt(x.galcoholico),
      capacidad: parseFloat(x.capacidad),
      factor: parseFloat(x.factor),
      jerarquia: x.jerarquia,
      descuentos: parseFloat(x.descuentos),
      titadicional: x.titadicional,
      valadicional: x.valadicional,
      grupo: x.grupo,
      codigoUnidadVenta: parseInt(x.unidadv),
      tipoDescripcion: x.tipo_descripcion,
      codigoGrupo: parseInt(x.codgrupo)
    };
    return item;
  });
  return items;
}

async function getSustentosTributarios() {
  const sesion = SesionService.getCurrentSesion();
  const queryData: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    tipoComprobanteCodigo: "01",
  };
  const apiData = RequestHelper.get<Array<SustentosTributarios>>(
    "generales",
    "sustentosVouchers/getLivelihoods",
    "",
    queryData
  );
  return apiData;
}

async function plantillaImportar(objeto: any): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  let fileUpload: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    localCodigo: sesion.local.codigo,
    archivo: objeto.file,
    tipo: "archivo"
  }

  const data = await RequestHelper.postData<any>(
    "compras",
    "getExcelAll",
    fileUpload
  );
  return data;
}

function exportarDemo(fileName) {
  const sesion = SesionService.getCurrentSesion();
  const config = RequestHelper.getConfig("GET");
  let datosSearch = {
    ruc: sesion.empresa.ruc,
    archivo: fileName
  }
  var response = fetch(RequestHelper.getRequestUrl('generales', 'downloadFile', null, datosSearch), {
    method: 'get',
    headers: config.headers
  });
  return response;
}