import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../helpers/dateUtils";
import { RootState } from "../../../../../store/store";
import { FETCH_STATUS, TiposComprobantesSri } from "../../../../../store/types";
import { VentasService } from "../../../services/ventas.service";
import { SearchState } from "../../../types/generics";
import { Cliente, EstadoComprobante, FiltroBusquedaState, FormaPagoModuloVentas, VentaListado } from "../../../types/types";


const initialState: SearchState<VentaListado> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    cliente: null,
    activas: false,
    documento: '',
    formaPago: null,
    pendientesAbutorizar: false,
    establecimiento: null,
    estado: null,
    puntoEmision: null
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: []
}

export const fetchNotasCredito = createAsyncThunk<Array<VentaListado>, FiltroBusquedaState>(
  'ventas/searchNotasCredito',
  async (filtro) => {
    try {
      const notasCredito = await VentasService.getVentas(0, DateUtils.pickersDateToApiDate(filtro.fechaInicio) ?? "", DateUtils.pickersDateToApiDate(filtro.fechaFin) ?? "", filtro.puntoVenta,
        filtro.documento ?? undefined, filtro.estado ? filtro.estado.codigo.toString() : undefined, filtro.cliente?.identificacion, filtro.pendientesAbutorizar === undefined ? undefined : !filtro.pendientesAbutorizar,
        filtro.claveAcceso ?? "", filtro.formaPago ? filtro.formaPago.codigo.toString() : undefined, filtro.establecimiento ? filtro.establecimiento.establecimiento : undefined,
        filtro.puntoEmision ? filtro.puntoEmision.puntoEmision : undefined, TiposComprobantesSri.NotaCredito);
      return notasCredito;
    } catch (error) {
      return Promise.reject(error);
    }
  }

)


const searchNotasCreditoSlice = createSlice({
  name: 'searchNotasCredito',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      };
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaState>) {
      state.filter = action.payload;
    },
    setCustomer(state, action: PayloadAction<Cliente | null>) {
      state.filter.cliente = action.payload;
    },
    setSalesPoint(state, action: PayloadAction<string>) {
      state.filter.puntoVenta = action.payload;
    },
    setDateStart(state, action: PayloadAction<string>) {
      state.filter.fechaInicio = action.payload;
    },
    setDateEnd(state, action: PayloadAction<string>) {
      state.filter.fechaFin = action.payload;
    },
    setInvoiceNumber(state, action: PayloadAction<string>) {
      state.filter.documento = action.payload;
    },
    setInvoiceClaveAcceso(state, action: PayloadAction<string>) {
      state.filter.claveAcceso = action.payload;
    },
    setPendientesAutorizar(state, action: PayloadAction<boolean>) {
      state.filter.pendientesAbutorizar = action.payload;
    },
    setFormaPago(state, action: PayloadAction<FormaPagoModuloVentas | null>) {
      state.filter.formaPago = action.payload;
    },
    setStatus(state, action: PayloadAction<EstadoComprobante | null>) {
      state.filter.estado = action.payload;
    },
    setCleanResult(state) {
      state.resultados = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotasCredito.pending, (state) => {
      if (state.status === FETCH_STATUS.IDDLE || state.status === FETCH_STATUS.SUCCESS) {
        state.status = FETCH_STATUS.LOADING;
        state.mensaje = '';
      }
    })
    builder.addCase(fetchNotasCredito.fulfilled, (state, { payload }) => {
      state.resultados = payload;
      state.status = FETCH_STATUS.SUCCESS;
    })
    builder.addCase(fetchNotasCredito.rejected, (state, { payload }) => {
      state.resultados = [];
      state.status = FETCH_STATUS.FAILED;
      state.mensaje = payload as string;
    })
  }
})

export const selectFiltroBusqueda = (state: RootState) => { return state.ventas.notasCredito.search.filter };
export const selectResultadosBusqueda = (state: RootState) => { return state.ventas.notasCredito.search.resultados };
export const selectBusquedaEstado = (state: RootState) => { return state.ventas.notasCredito.search.status };
export const { changeFilter, setStatus, setCustomer, setDateEnd, setDateStart,
  setSalesPoint, setPendientesAutorizar, setFormaPago, setInvoiceNumber, resetState,
  setCleanResult, setInvoiceClaveAcceso } = searchNotasCreditoSlice.actions;
export const reducer = searchNotasCreditoSlice.reducer;