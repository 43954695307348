import { CAlert } from '@coreui/react'
import { content } from 'devexpress-dashboard/model/index.metadata'
import React from 'react'
import { CustomModalDevx } from '../../../../../views/componentes/modal/Modal'

interface Props {
  titulo: string,
  mensaje: string,
  content?: any,
  onOk: () => void
}

const VisualizaError = (props: Props) => {

  if (props.content) {
    return (
      <CustomModalDevx
        show
        size='sm'
        onClose={() => props.onOk()}
        title={props.titulo ? `Acatha - ${props.titulo}` : 'Acatha'}
      >
        {props.content}
      </CustomModalDevx>
    )
  }

  return (
    <CustomModalDevx
      show
      size='sm'
      onClose={() => props.onOk()}
      title={props.titulo ? `Acatha - ${props.titulo}` : 'Acatha'}
    >



      <CAlert
        color='danger'
      >
        {props.mensaje}
      </CAlert>


    </CustomModalDevx>
  )
}

export default VisualizaError
