import { combineReducers,  } from "@reduxjs/toolkit";
import {  WindowVentasState } from "../../../types/generics";
import { VentaListado } from "../../../types/types";
import { FacturaDatosEdicion } from "../../ventas/types/types";
import { menuReducer } from "./menuReducer";
import { reducer } from "./searchReducer";
import { tabsReducer } from "./tabsReducer";
import { datosEdicionReducer } from './editDataReducer'
// const initialState: WindowVentasState<InfoVenta> = {
//   menu: {},
//   search: {},
//   tabs: {
//     current: "",
//     tabs: []
//   }
// }

export const notasCreditoReducer = combineReducers<WindowVentasState<VentaListado, FacturaDatosEdicion>>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: reducer,
  editData: datosEdicionReducer
});