import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../helpers/dateUtils";
import { RootState } from "../../../../store/store";
import { FETCH_STATUS } from "../../../../store/types";
import { OrdenesListado, SalonesComandas } from '../types/types'
import { Cliente } from "../../../ventas/types/types";
import { ComandasService } from "../services/comandas.service";
import { FiltroBusquedaComandasState } from "../../types/generics/types";

const initialState: any = {
  filter: {
    numero: "",
    tipo: "COM",
    estado: false,
    estadoFacturadas: false,
    codigoSalon: null,
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: "",
  resultados: [],
};

export const fetchComandas = createAsyncThunk<
  Array<OrdenesListado>,
  FiltroBusquedaComandasState
>("compras/documentos/general/orders/getAll", async (filtro) => {
  try {
    console.log("filtro", filtro)
    if (filtro['fechaInicio'].length > 0) {
      filtro = {
        ...filtro,
        fechaInicio: DateUtils.pickersDateToApiDate(filtro.fechaInicio) ?? ""
      }
      console.log(filtro['fechaInicio'])
    }
    if (filtro['fechaFin'].length > 0) {
      filtro = {
        ...filtro,
        fechaFin: DateUtils.pickersDateToApiDate(filtro.fechaFin) ?? ""
      }
      console.log(filtro['fechaFin'])
    }
    console.log("filtroModificado", filtro)

    const comandas = await ComandasService.getComandas(filtro);
    console.log("comandas", comandas)
    return comandas;
  } catch (error) {
    console.error(error)
    return Promise.reject(error);
  }
});

const searchComandaSlice = createSlice({
  name: "searchComanda",
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      };
    },

    setCleanResult(state) {
      state.resultados = [];
    },
    setCustomer(state, action: PayloadAction<Cliente | null>) {
      state.filter.cliente = action.payload;
    },
    setNumero(state, action: PayloadAction<string>) {
      state.filter.numero = action.payload;
    },
    setDateStart(state, action: PayloadAction<string>) {
      state.filter.fechaInicio = action.payload;
    },
    setDateEnd(state, action: PayloadAction<string>) {
      state.filter.fechaFin = action.payload;
    },
    setStatus(state, action: PayloadAction<boolean | null>) {
      state.filter.estado = action.payload;
    },
    setSalon(state, action: PayloadAction<SalonesComandas | null>) {
      state.filter.codigoSalon = action.payload;
    },
    setEstado(state, action: PayloadAction<boolean | null>) {
      state.filter.estado = action.payload;
    },
    setFacturados(state, action: PayloadAction<boolean | null>) {
      state.filter.estadoFacturadas = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchComandas.pending, (state) => {
      if (
        state.status === FETCH_STATUS.IDDLE ||
        state.status === FETCH_STATUS.SUCCESS
      ) {
        state.status = FETCH_STATUS.LOADING;
        state.mensaje = "";
      }
    });
    builder.addCase(fetchComandas.fulfilled, (state, { payload }) => {
      state.resultados = payload;
      state.status = FETCH_STATUS.SUCCESS;
    });
    builder.addCase(fetchComandas.rejected, (state, { payload }) => {
      state.resultados = [];
      state.status = FETCH_STATUS.FAILED;
      state.mensaje = payload as string;
    });
  },
});

export const selectFiltroBusquedaVentas = (state: RootState) => {
  return state.restaurante.comandas.search.filter;
};
export const selectResultadosBusquedaComandas = (state: RootState) => {
  return state.restaurante.comandas.search.resultados;
};
export const selectBusquedaVentasEstado = (state: RootState) => {
  return state.restaurante.comandas.search.status;
};
export const {
  setCleanResult,
  setCustomer,
  setNumero,
  setSalon,
  setDateStart,
  setDateEnd,
  setStatus,
  resetState,
  setFacturados,
  setEstado
} = searchComandaSlice.actions;
export const searchComandaReducer = searchComandaSlice.reducer;
