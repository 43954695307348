import { CBadge, CCol, CRow } from '@coreui/react';
import DataGrid, {
  Column, FilterRow, HeaderFilter, Pager, Paging, Button as DatagridButton
} from 'devextreme-react/data-grid';
import React from 'react';
import { RootState } from '../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux'
import { utilidades } from '../../../../../helpers/utilidades';
import { setRegistros, setSeleccionarDato } from '../../store/modalAutorizacion';
import { Autorizacion } from '../../store/types';

interface ITablaAutorizacionesProps {
  onChanged: (data: Autorizacion) => void
}
const pageSizes = [10, 25, 50, 100, 200];

const Tabla = (props: ITablaAutorizacionesProps) => {
  const { onChanged } = props;
  const dispatch = useDispatch();

  const tablaRef = React.useRef<any>();
  const autorizacionesListado = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.autorizacionesListado);

  const [data, setData] = React.useState<any>([])

  const clearFilter = React.useCallback(() => {
    tablaRef.current.instance.clearSorting();
    tablaRef.current.instance.clearFilter();
  }, []);

  const llenarData = React.useCallback((data: any) => {
    clearFilter();
    let item: any = []
    try {
      item = data.map(function (key, i) {
        return {
          ...key,
        }
      })
      setData(item.map(x => utilidades.unfreeze(x)))
    } catch (error) {
      console.error("on llenarData", error)
    }
  }, [clearFilter]);

  const onRowDblClick = React.useCallback((row) => {
    const { data } = row;
    dispatch(setSeleccionarDato(data));
    dispatch(setRegistros([]));
    onChanged(data)
  }, [dispatch, onChanged]);

  const onSelectionChanged = React.useCallback(async (selectedRowsData) => {
    if (selectedRowsData['selectedRowsData'].length > 0) {
      const data = selectedRowsData['selectedRowsData'][0]
      dispatch(setSeleccionarDato(data));
    }
  }, [dispatch]);


  React.useEffect(() => {
    llenarData(autorizacionesListado)
  }, [autorizacionesListado]);

  return (
    <>
      <CRow>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginRight: '20px' }}>
          {data.length > 0 &&
            <div>
              <CBadge color='info'>
                {`${data.length} REGISTROS ENCONTRADOS`}
              </CBadge>
            </div>
          }
        </div>
      </CRow>
      <CRow>
        <CCol className='d-flex justify-content-center mt-2' md="12" lg="12" xs="12" >
          <DataGrid
            ref={tablaRef}
            dataSource={data ?? []}
            selection={{ mode: 'single' }}
            hoverStateEnabled={true}
            showBorders={true}
            showRowLines={true}
            onSelectionChanged={onSelectionChanged}
            onRowDblClick={onRowDblClick}
            allowColumnReordering={true}
            allowColumnResizing
            columnResizingMode='nextColumn'
            columnAutoWidth={true}
            keyExpr='codigo'
          >
            <Column type='buttons' width='7%' >
              <DatagridButton icon='add' visible={true} hint='Seleccionar'
                onClick={(e) => {
                  dispatch(setRegistros([]));
                  onChanged(e.row.data)
                }} />
            </Column>
            <Column
              dataType='string'
              dataField='descripcionTipoDocumento'
              caption='Comprobante'
              width='15%'
            />
            <Column
              dataType='string'
              dataField='numeroAutorizacion'
              caption='Aut.'
              width='15%'
            />
            <Column
              dataType='string'
              dataField='puntoEmision'
              caption='Pto.'
              minWidth={'15%'}
              width={'15%'}
            />
            <Column
              dataType='number'
              dataField='fechaCaducidad'
              caption='Caduca'
              width='15%'
            />
            <Column
              dataType='string'
              dataField='numeroActual'
              caption='#'
              width='15%'
            />
            <Paging defaultPageSize={10} />
            <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} showInfo />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
          </DataGrid>
        </CCol>
      </CRow>
    </>
  )
}
export default Tabla
