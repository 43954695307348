import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalSeleccionChequeState } from "./types";
import { AccionMenu, OptionGrupos } from "../../../../store/types"
import { SeleccionadoTableItem } from "../../../bancos/store/types"
import { ModalOpen } from "../../../contabilidad/general/store/types"
import { Cheque } from "../../../bancos/pages/cheques/store/types";

const initialState: ModalSeleccionChequeState = {
  modulo: 'modalSeleccionCheques',
  currentAction: "Buscar",
  currentFunction: "",
  aplicacion: 'modalSeleccionCheques',
  acciones: [],
  registros: [],
  seleccionados: [],
  selectSeleccionado: {
    index: null,
    row: [],
  },
  seleccionado: {
    index: null,
    row: [],
  },
  modal: {
    name: "",
    open: false,
    codModulo: 0,
    parametros: []
  },
  formasPagoSRI: [],
  formularioSeleccionCheques: {
    busqueda_ti: '',
    valorb_ti: '',
    fechab_ti: ''
  },
  valorSinRetencion: 0,
  total_ti: 0
}

const modalSeleccionChequesSlice = createSlice({
  name: "modalSeleccionCheques",
  initialState: initialState,
  reducers: {
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    obtenerAcciones(state, action: PayloadAction<Array<AccionMenu>>) {
      state.acciones = action.payload
    },
    setRegistros(state, action: PayloadAction<Array<Cheque>>) {
      state.registros = action.payload
    },
    setSeleccionados(state, action: PayloadAction<Array<Cheque>>) {
      state.seleccionados = action.payload
    },
    setSelectSeleccionado(state, action: PayloadAction<SeleccionadoTableItem>) {
      state.selectSeleccionado = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<SeleccionadoTableItem>) {
      let toAdd = {
        index: action.payload.index,
        row: action.payload.row,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state, action: PayloadAction<any>) {
      let toAdd = {
        index: null,
        row: []
      }
      state.seleccionado = toAdd
    },
    setResetSelecSeleccionado(state, action: PayloadAction<any>) {
      let toAdd = {
        index: null,
        row: []
      }
      state.selectSeleccionado = toAdd
    },
    setModalSeleccionCheques(state, action: PayloadAction<ModalOpen>) {
      let toAdd = {
        name: action.payload.name,
        open: action.payload.open,
        codModulo: action.payload.codModulo,
        parametros: action.payload.parametros
      }
      state.modal = toAdd
    },
    setFormasPagoSRI(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.formasPagoSRI = action.payload
    },
    setFormularioSeleccionCheques(state, action: PayloadAction<any>) {
      state.formularioSeleccionCheques = action.payload
    },
    setValorSinRetencion(state, action: PayloadAction<number>) {
      state.valorSinRetencion = action.payload
    },
    setTotal_ti(state, action: PayloadAction<number>) {
      state.total_ti = action.payload
    },
  }
})

export const { setResetSelecSeleccionado, setSelectSeleccionado, setTotal_ti, setSeleccionados, setValorSinRetencion, setFormularioSeleccionCheques, setFormasPagoSRI, setCurrentFunction, setModalSeleccionCheques, obtenerAcciones, setRegistros, setCurrentAction, setSeleccionarDato, setResetSeleccion } = modalSeleccionChequesSlice.actions;
export const modalSeleccionChequesReducer = modalSeleccionChequesSlice.reducer;