import React from "react";
import { CButton, CCol, CInput, CInputGroup, CInvalidFeedback } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import Labeled from "../../../../../views/componentes/labeledInput/labeledInput";
import { TABLECOL_CANTIDAD, TABLECOL_CODIGO, TABLECOL_COMANDOS, TABLECOL_DESCRIPCION, TABLECOL_FEEDBACK, TABLECOL_ISVALID, TABLECOL_IVA, TABLECOL_PRECIO, TABLECOL_SUBTOTAL, TABLECOL_TOTAL } from "../../../pages/ventas/types/types";
import RowContainer from "../../../../../views/componentes/rowContainer/rowContainer";
import { Divider } from "@material-ui/core";
import Button from "devextreme-react/button";

export function getColumnnasTablaDetalleVentas(
  onUpdateCell: (row: number, col: number, value: string) => void,
  onDeleteRow: (row) => void): Array<any> {
  const columnnasTablaDetalleVentas = [
    {
      name: "Commands",
      label: " ",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                type='danger'
                stylingMode='contained'
                icon='remove'
                onClick={() => {
                  onDeleteRow(tableMeta.rowIndex);
                }}>
              </Button>
            </>

          );
        }
      }
    },
    {
      label: "Código",
      name: "codigoBarras",
      options: {
        filter: true,
        size: 'small'
      }
    },
    {
      label: "Descripción",
      name: "descripcionItem",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Labeled
              label='Descripción'
            >
              <CInputGroup>
                <CInput
                  size='sm'
                  type='text'
                  value={value}
                  invalid={false}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    //updateValue(parseFloat(e.currentTarget.value))
                    onUpdateCell(tableMeta.rowIndex, tableMeta.columnIndex, e.currentTarget.value);
                  }}
                >

                </CInput>
                {false &&
                  <CInvalidFeedback >{ }</CInvalidFeedback>
                }
              </CInputGroup>
            </Labeled>


          )
        }
      }
    },
    {
      label: "Cant.",
      name: "cantidadItem",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <CInputGroup>
              <CInput
                type='number'
                size='sm'
                value={value}
                invalid={false}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  //updateValue(parseFloat(e.currentTarget.value))
                  onUpdateCell(tableMeta.rowIndex, tableMeta.columnIndex, e.currentTarget.value);
                }}
              >

              </CInput>
              {false &&
                <CInvalidFeedback >{tableMeta.rowData[TABLECOL_FEEDBACK].cantidad}</CInvalidFeedback>
              }
            </CInputGroup>
          )
        }
      }
    },
    {
      label: "puedeEditar",
      name: 'puedeEditar',
      options: {
        display: false
      }
    },
    {
      label: "valida",
      name: 'valida',
      options: {
        display: false
      }
    },
    {
      label: "feedback",
      name: 'feedback',
      options: {
        display: false
      }
    }
  ];
  return columnnasTablaDetalleVentas;
}


export const OptionsTablaDetalleVentas = {
  responsive: "vertical",
  selectableRows: 'none',
  padding: 'none',
  size: 'small',
  download: false,
  viewColumns: false,
  filter: false,
  pagination: true,
  print: false,
  search: false,
  elevation: 6,
  customRowRender: (data, dataIndex, rowIndex) => {
    return (
      <tr key={`$filaDetalle${rowIndex}`}>
        <th>
          <div className='container customMuiRowContainer'>
            <RowContainer>
              <CCol xs='8'>
                <Labeled
                  label='Código'
                >
                  <span>{data[TABLECOL_CODIGO]}</span>
                </Labeled>
              </CCol>
              <CCol xs='4'>
                {data[TABLECOL_COMANDOS]}
              </CCol>
            </RowContainer>
            <RowContainer>
              <CCol xs='12'>
                {data[TABLECOL_DESCRIPCION]}
              </CCol>
            </RowContainer>
            <RowContainer>
              <CCol xs='6'>
                <Labeled
                  label='Cantidad'
                >
                  {data[TABLECOL_CANTIDAD]}
                </Labeled>
              </CCol>
            </RowContainer>
          </div>
          <Divider />
        </th>
      </tr>

    )
  },
  textLabels: {
    body: {
      noMatch: "",
    },
  }
};
