import Autocomplete from '@material-ui/lab/Autocomplete';
import { throttle } from 'lodash';
import * as React from 'react';
import { VentasService } from '../../../services/ventas.service';
import '../sheetEditors/sheetDescripcionItemSelectEditor.scss'
import './venta.scss'
import { ItemVenta } from '../../../types/types';
import { DateUtils, formatoFechasApi } from '../../../../../helpers/dateUtils';
import { TextField } from '@material-ui/core';
interface ICustomAutocompleteProps {
  id: string,
  value: ItemVenta | null,
  onChange: (newValue: ItemVenta | null) => void,
  rucCliente: string,
  tipoBusqueda: "nombre" | "codigo",
  tieneError?: boolean,
  feedBack?: string,
  onBlur: any
}
//size="small"
const CustomAutocompleteProducto: React.FunctionComponent<ICustomAutocompleteProps> = (props) => {
  const [options, setOptions] = React.useState<Array<ItemVenta>>([]);
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string, rucCliente: string, tipoBusqueda: string }, callback: (results?: Array<ItemVenta>) => void) => {
        setLoading(true);
        const busqueda = request.tipoBusqueda === "nombre" ?
          VentasService
            .buscarItem(request.input, '', request.rucCliente, DateUtils.getCurrentDateAsString(formatoFechasApi))
          :
          VentasService
            .buscarItemCodigoBarras(request.input, request.rucCliente,  DateUtils.getCurrentDateAsString(formatoFechasApi));
        busqueda
          .then((resultados) => {
            callback(resultados);
            setLoading(false);
          }).catch(reason => {
            console.log(reason);
            setLoading(false);
          });
        //(autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 1000),
    [],
  );



  React.useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setOptions(props.value ? [props.value] : []);
      return undefined;
    }

    if (inputValue.length < 4) {
      setOptions(props.value ? [props.value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        rucCliente: props.rucCliente,
        tipoBusqueda: props.tipoBusqueda
      }, (results?: ItemVenta[]) => {
        if (active) {
          let newOptions: ItemVenta[] = [];

          if (props.value) {
            newOptions = [props.value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });
    return () => {
      active = false;
    };
  }, [props, inputValue, fetch])

  return (
    <Autocomplete
      size='small'
      multiple={false}
      id={props.id}
      loading={loading}
      loadingText='Buscando productos...'
      noOptionsText=''
      options={options}
      onBlur={props.onBlur}
      getOptionLabel={(option) =>
        props.tipoBusqueda === 'codigo' ?
          option.codigoBarras
          : option.descripcion}
      value={props.value}
      onChange={(event: any, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        props.onChange(newValue);
      }}
      renderOption={(option) => (
        <div className='autoCompleteItemContainer'>
          <div className='autoCompleteItemDescripcion'>{option.descripcion}</div>
          <div className='autoCompleteItemDetails'>
            <small className='autoCompleteItemDetail'>
              Código: {option.codigoBarras}
            </small>
            <small className={`autoCompleteItemDetail ${option.existencia < 0 ? 'existenciaNegativa' : (option.existencia === 0 ? 'existenciaCero' : '')}`}>
              Existencia: {option.existencia}
            </small>
          </div>
        </div>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="standard"
          label={props.tipoBusqueda === 'codigo' ? 'Código Producto' : 'Descripción producto'}
          size='small'
          placeholder={'Seleccionar producto'}
          error={props.tieneError}
          helperText={props.feedBack}
          fullWidth />
      )
      }
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
};

export default CustomAutocompleteProducto;
