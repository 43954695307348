import Axios from 'axios';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import { RequestHelperDotNet } from '../../../helpers/requestHelperDotNet';
import { UrlHelper } from '../../../helpers/urlHelper';
import LoadingIndicator from '../loadingindicator/loadingindicator';
import { ReportDesigner } from './ReportDesigner';
import { ReportViewer } from './ReportViewer';

// export type DataToReport = {
//   parameters: any,
//   fileName:string,
//   template:string,
//   data:Array<any>
// }

export interface IReportWithLocalDataProps {
  parameters: any,
  fileName: string,
  template: string,
  data: Array<any>,
  mode: "Viewer" | "Designer"
}

export const ReportWithLocalData: React.FC<IReportWithLocalDataProps> = (props) => {
  const [idReport,] = React.useState(uuidv4());
  const [loading, setLoading] = React.useState<boolean>(true);


  const registerData = React.useCallback(async (props: IReportWithLocalDataProps) => {
    const urls = UrlHelper.getUrls();
    const asc = await RequestHelperDotNet.getConfigAsync('POST', 'application/json');
    await Axios.post(`${urls.apiDasbooardsPath}ReportDesigner/RegisterReportData?dataId=${idReport}&template=${props.fileName}`,
      props, asc);
    setLoading(false);
  }, [idReport]);

  React.useEffect(() => {
    registerData(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerData]);


  return (
    <div key='ReportWithLocalData'>


      {loading &&
        <LoadingIndicator mensaje='Cargando....' />
      }
      {!loading && props.mode === 'Viewer' &&
        <ReportViewer
          reportName={props.template}
          dataId={idReport}
          
        />
      }
      {!loading && props.mode === 'Designer' &&
        <ReportDesigner
          reportName={props.template}
          dataId={idReport}
        >

        </ReportDesigner>
      }

    </div>)

};
