import { CButton, CCol, CFormGroup, CLabel, CRow, CTooltip } from '@coreui/react';
import * as React from 'react';
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { CuadresService } from '../../../../../services/cuadres.service';
import { DateUtils, formatoFechasApi } from '../../../../../../../../../helpers/dateUtils';
import {
  updateAnuladasCierre1Only, updateDpegresosBovedaOnly, updateDptotalesOnly,
  updateFacturasCuadreOnly, updateFormasPagoCuadreCuadreOnly, updateTotal1_tiOnly,
  updateTotalCajab_tiOnly, updateTotalCaja_tiOnly, updateTotalEgresosb_tiOnly,
  updateTotalEgresos_tiOnly, updateValorTotalb_tiOnly, updateValorTotal_tiOnly,
  updateFechaOnly
} from '../../../store/editDataReducer';
import DataGrid, {
  Column, HeaderFilter,
  Paging, Pager, LoadPanel
} from 'devextreme-react/data-grid';
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../../../views/componentes/colContainer';
import { utilidades } from '../../../../../../../../../helpers/utilidades';
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize';
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper';
import { ccDetalle, ComprobantesCuadresCajaListado, formasPagoCuadre } from '../../../../../types/types';
import TabFormasPago from '../componentes/tabFormasPago/tabFormasPago';

interface IFacturasProps {
  setToast: (texto, type) => void,
  playLoader: () => void,
  stopLoader: () => void,
  onErrorConfirm: (sms, title) => void,
  onConfirmarRegistro: (sms, method) => void
  tabId: string,
}



const Facturas: React.FunctionComponent<IFacturasProps> = (props) => {
  const {
    tabId, setToast, playLoader, stopLoader,
    onErrorConfirm, onConfirmarRegistro
  } = props;
  const dataGrid = React.useRef<any>();


  const dispatch = useDispatch();
  const [heigth] = useGridResultadosSize();

  const local = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].local });
  const puntoVenta = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].puntoVenta });
  const fechaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fechaCierre });
  const venInicial1 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial1 });
  const venFinal1 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal1 });
  const generarCuadre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre });
  const dpcierre1 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre1 });
  const totalcajab_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].totalcajab_ti });
  const totalegresosb_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].totalegresosb_ti });
  const valortotalb_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].valortotalb_ti });
  const anuladascierre1_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].anuladascierre1_ti });
  const total1_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].total1_ti });
  const dptotales1 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales1 });
  const dpfpagos = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpfpagos });
  const dptotales = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales });
  const totalegresos_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].totalegresos_ti });
  const totalcaja_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].totalcaja_ti });
  const dpegresosboveda = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpegresosboveda });




  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [showPageSizeSelector,] = React.useState<boolean>(true);
  const [loaderTable, setLoaderTable] = React.useState<boolean>(false);


  const resultadoEgresosBoveda = React.useCallback(async (dptotales1Local) => {

    dispatch(updateTotalCaja_tiOnly({ totalcaja_ti: 0, key: tabId }));
    dispatch(updateTotalEgresos_tiOnly({ totalegresos_ti: 0, key: tabId }));
    dispatch(updateValorTotal_tiOnly({ valortotal_ti: 0, key: tabId }));

    let efectivo: number = 0;
    let totalegresos: number = 0;
    for (let i = 0; i < dptotales1Local.length; i++) {
      if (dptotales1Local[i].subCodigo && Number(dptotales1Local[i].subCodigo) === 6) {
        efectivo = efectivo + Number(dptotales1Local[i].ccdValor);
      }
    }

    let totalcajaTi = Number(Number(efectivo.toFixed(2)));
    let totalcajabTi = Number(Number(efectivo.toFixed(2)));

    dispatch(updateTotalCaja_tiOnly({ totalcaja_ti: totalcajaTi, key: tabId }));
    dispatch(updateTotalCajab_tiOnly({ totalcajab_ti: totalcajabTi, key: tabId }));

    for (let i: number = 0; i < dpegresosboveda.length; i++) {
      totalegresos = totalegresos + Number(dpegresosboveda[i].egresoValor);
    }

    let totalegresosTi = Number(Number(totalegresos.toFixed(2)));
    let totalegresosbTi = Number(Number(totalegresos.toFixed(2)));
    let valortotalTi = Number((Number(totalcajaTi) - Number(totalegresosTi)).toFixed(2));
    let valortotalbTi = Number((Number(totalcajaTi) - Number(totalegresosTi)).toFixed(2));

    dispatch(updateTotalEgresos_tiOnly({ totalegresos_ti: totalegresosTi, key: tabId }));
    dispatch(updateTotalEgresosb_tiOnly({ totalegresosb_ti: totalegresosbTi, key: tabId }));
    dispatch(updateValorTotal_tiOnly({ valortotal_ti: valortotalTi, key: tabId }));
    dispatch(updateValorTotalb_tiOnly({ valortotalb_ti: valortotalbTi, key: tabId }));


  }, [dispatch, tabId, dpegresosboveda]);



  const getEgresosBoveda = React.useCallback(async (dptotales1Local) => {
    try {
      setLoaderTable(true)
      let fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi);
      let dpegresosbovedaApi = await CuadresService.getEgresosBoveda(puntoVenta?.codigoPuntoVenta ?? -1, fechaFilter);

      dispatch(updateDpegresosBovedaOnly({
        dpegresosboveda: dpegresosbovedaApi,
        key: tabId
      }));
      await resultadoEgresosBoveda(dptotales1Local);
      setLoaderTable(false)
    } catch (error) {
      dispatch(updateDpegresosBovedaOnly({
        dpegresosboveda: [],
        key: tabId
      }));
      await resultadoEgresosBoveda(dptotales1Local)
    }
  }, [dispatch, tabId, fechaCierre, puntoVenta, resultadoEgresosBoveda]);




  const resultadoCierreCaja = React.useCallback(async (dpCierreFacturasVar: Array<ComprobantesCuadresCajaListado> | []) => {
    console.log("*******************resultadoCierreCaja", dpCierreFacturasVar)
    let detalle = {
      ccdCodigo: 0,
      ccaCodigo: "",
      subValor: "",
      ccdValor: 0,
      subCodigo: 0,
    };

    let valor: number = 0;
    const dptotales1Local: Array<formasPagoCuadre> = [];
    if (dpCierreFacturasVar.length === 0) {
      for (const fpg of dpfpagos) {
        if (fpg.codigo !== 28) {
          dptotales1Local.push({
            subCodigo: Number(fpg.codigo) ?? -1,
            subValor: String(fpg.descripcion) ?? "",
            ccdValor: Number(valor),
          });
        }
      }

      dispatch(updateFormasPagoCuadreCuadreOnly({
        dptotales1: dptotales1Local,
        key: tabId
      }));

    } else {

      dispatch(updateFechaOnly({
        fecha: dpCierreFacturasVar[0].fecha,
        key: tabId
      }));

      let anuladas = 0;
      let i = 0;

      for (let j = 0; j <= dpfpagos.length - 1; j++) {
        valor = 0;
        for (i = 0; i < dpCierreFacturasVar.length; i++) {
          if (dpCierreFacturasVar[i].ventaFormaPagoDescripcion === dpfpagos[j].descripcion) {
            valor = valor + Number(Number(dpCierreFacturasVar[i].valor).toFixed(2));
          }
        }

        dptotales1Local.push({
          subCodigo: Number(dpfpagos[j].codigo),
          subValor: String(dpfpagos[j].descripcion),
          ccdValor: Number(Number(valor).toFixed(2)),
        });

      }

      dispatch(updateFormasPagoCuadreCuadreOnly({
        dptotales1: dptotales1Local,
        key: tabId
      }));

      let k = 0;

      for (k = 0; k < dpCierreFacturasVar.length; k++) {
        if (dpCierreFacturasVar[k].ventaEstado === "*") {
          anuladas += Number(dpCierreFacturasVar[k].ventaTotal);
        }
      }

      dispatch(updateAnuladasCierre1Only({
        anuladascierre1_ti: anuladas,
        key: tabId
      }));

      let total: number = 0;
      for (k = 0; k < dptotales1Local.length; k++) {
        total = total + dptotales1Local[k].ccdValor;
      }
      total = Number(Number(total).toFixed(2));
      dispatch(updateTotal1_tiOnly({
        total1_ti: total,
        key: tabId
      }));

      // BOVEDA_RO.EgresosBoveda
      await getEgresosBoveda(dptotales1Local);

      let dptotalesLocal: any = [];
      if (dptotales1Local.length > 0) {
        for (i = 0; i < dpfpagos.length; i++) {
          let x: ccDetalle | null = null;
          try {
            x = dptotales[i];
          } catch (ex) {
            x = null;
          }
          if (x == null) {
            let addDetallTotales: ccDetalle = {
              ...detalle,
              ccdValor: Number(Number(dptotales1Local[i].ccdValor).toFixed(2)),
              subCodigo: dpfpagos[i].codigo,
              subValor: dpfpagos[i].descripcion,
            }
            dptotalesLocal.push({ ...addDetallTotales });
          } else {
            let y: number = Number((x.ccdValor + Number(dptotales1Local[i].ccdValor)).toFixed(2));
            x.ccdValor = y;
            dptotalesLocal[i] = x;
          }

        }

        dispatch(updateDptotalesOnly({
          dptotales: dptotalesLocal,
          key: tabId
        }));

      }
    }


  }, [dispatch, tabId, dpfpagos, dptotales, getEgresosBoveda]);


  const getCierreCaja2 = React.useCallback(async () => {
    let fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi);
    let dpfacturasCuadre = await CuadresService.getCierreCaja2(fechaFilter, local?.codigo ?? -1, puntoVenta?.codigoPuntoVenta ?? -1, venInicial1, venFinal1);

    dispatch(updateFacturasCuadreOnly({
      dpcierre1: dpfacturasCuadre,
      key: tabId
    }));

    await resultadoCierreCaja(dpfacturasCuadre)
  }, [
    fechaCierre, tabId, dispatch, local, puntoVenta, venInicial1, venFinal1, resultadoCierreCaja
  ]);

  React.useEffect(() => {
    if (generarCuadre === true) {
      getCierreCaja2();
    }
  }, [generarCuadre])

  return (
    <>
      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">


          <RowContainer >
            <CustomCol xs='12' md='12'   >

              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr='ventaCodigo'
                dataSource={dpcierre1}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode='widget'
                height={heigth}
                ref={dataGrid}
              >
                <LoadPanel enabled={loaderTable} />
                <Paging defaultPageSize={20}/>
                <Pager
                  visible={dpcierre1.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(dpcierre1)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <HeaderFilter visible={true} />
                <Column dataField='ventaTipoDocumentoDescripcion' dataType='string' caption='Comprobante' width='15%' allowEditing={false} allowSearch={false} allowFiltering />
                <Column dataField='ventaNumero' caption='Número' width='20%' allowEditing={false} />
                <Column dataField='clienteNombre' caption='Cliente' width='20%' allowEditing={false} />
                <Column dataField='ventaTotal' caption='Total' width='15%' allowEditing={false} />
                <Column dataField='valor' caption='Abonado' allowEditing={false} width={'15%'} />
                <Column dataField='saldo' caption='Saldo' width='15%' allowEditing={false} />
                <Column dataField='ventaFormaPagoDescripcion' caption='F. Pago' width='15%' allowEditing={false} />
                <Column dataField='ventaEstado' caption='E' width='20%' allowEditing={false} />

              </DataGrid>

            </CustomCol>
          </RowContainer>

        </CCol>
      </CRow>

      <CRow >
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">

          <CCol lg='2' md="2" xs="12">
            <CFormGroup>
              <CLabel htmlFor="facEfectivo" className="col-form-label-md">
                <strong> {"Fac. Efectivo"} </strong>
              </CLabel>
              <CRow>
                <CCol md="12">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={totalcajab_ti}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
              </CRow>
            </CFormGroup>
          </CCol>
          <CCol lg='1' md="1" xs="12" className="d-flex align-items-center mr-0 ml-0">
            <strong>{"-"}</strong>
          </CCol>

          <CCol lg='2' md="2" xs="12">
            <CFormGroup>
              <CLabel htmlFor="egresos" className="col-form-label-md">
                <strong> {"Egresos"} </strong>
              </CLabel>
              <CRow>
                <CCol md="12">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={totalegresosb_ti}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
              </CRow>
            </CFormGroup>
          </CCol>
          <CCol lg='1' md="1" xs="12" className="d-flex align-items-center mr-0 ml-0">
            <strong>{"="}</strong>
          </CCol>

          <CCol lg='2' md="2" xs="12">
            <CFormGroup>
              <CLabel htmlFor="cierreCaja" className="col-form-label-md">
                <strong> {"Cierre Caja"} </strong>
              </CLabel>
              <CRow>
                <CCol md="12">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={valortotalb_ti}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
              </CRow>
            </CFormGroup>
          </CCol>

          <CCol lg="3" md="3" sm="12" className="d-flex justify-content-end  align-items-center">
            <TabFormasPago
              tabId={tabId}
              dptotales={dptotales1}
            />
          </CCol>

        </CCol>
      </CRow>


      <CRow >
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">

          <CCol lg='2' md="2" xs="12">
            <CFormGroup>
              <CLabel htmlFor="anuladas" className="col-form-label-md">
                <strong> {"Anuladas"} </strong>
              </CLabel>
              <CRow>
                <CCol md="12">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={anuladascierre1_ti}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
              </CRow>
            </CFormGroup>
          </CCol>

          <CCol lg='2' md="2" xs="12">
            <CFormGroup>
              <CLabel htmlFor="total" className="col-form-label-md">
                <strong> {"Total"} </strong>
              </CLabel>
              <CRow>
                <CCol md="12">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={total1_ti}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
              </CRow>
            </CFormGroup>
          </CCol>

        </CCol>
      </CRow>

      <CRow >
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-start  align-items-center">
          <CTooltip
            key={"tool-1"}
            placement="top"
            content={"Valor abonado del comprobante inmediato anterior listado."}
          >
            <div className={""} id="1">
              <CButton id="1" color="info" className="m-1" size='sm'   >
                <strong>{'('} &#92; {')'}</strong>
              </CButton>
            </div>
          </CTooltip>
          {" "}
          <CTooltip
            key={"tool-2"}
            placement="top"
            content={"Comprobantes Anulados."}
          >
            <div className={""} id="2asterisco">
              <CButton id="2" color="info" className="m-1" size='sm'   >
                <strong>{'(*)'}</strong>
              </CButton>
            </div>
          </CTooltip>
          {" "}
          <CTooltip
            key={"tool-3"}
            placement="top"
            content={"Si existe un comprobante fuera del rango de fechas y este tiene un abono se considera unicamente el valor del abono dentro de los totales."}
          >
            <div className={""} id="3">
              <CButton id="3" color="info" className="m-1" size='sm'   >
                <strong>{'?'}</strong>
              </CButton>
            </div>
          </CTooltip>
        </CCol>
      </CRow>
    </>
  )
}
export default Facturas;
