import * as React from 'react';
import { CustomModalDevx } from '../../../../views/componentes/modal/Modal';
import FormularioItem from "./busquedaItemsModal/Componentes/formularioItem";
import { Portal } from 'react-portal';
import ReactDom from 'react-dom'

export function ModificaItem(props: IModificaItemProps) {
  const { show, codigoBarras, onChanged, onCancel } = props;

  const [funcion, setFuncion] = React.useState<string>("");

  const modalContent = () => {
    return (
      <CustomModalDevx
        //portalElementId='#modal'
        //size='lg'
        show={show}
        title='Administración de productos'
        onClose={onCancel}
        size='md'
        toolbarItems={[
          {
            toolbar: 'bottom',
            widget: 'dxButton',
            location: 'after',
            options: {
              text: 'Aceptar',
              type: 'default',
              onClick: () => { setFuncion("Guardar") }
            }
          },
          {
            toolbar: 'bottom',
            widget: 'dxButton',
            location: 'after',
            options: {
              text: 'Cancelar',
              type: 'danger',
              onClick: props.onCancel
            }
          }
        ]}
      >
        <FormularioItem
          transaccion={props.transaccion}
          codigoBarras={codigoBarras}
          onChanged={onChanged}
          onCancel={onCancel}
          funcion={funcion}
          resetFunction={() => setFuncion('')}
          currentTab={codigoBarras !== "" ? "Editar" : "Nuevo"}
        />
      </CustomModalDevx>
    )
  }

  return (
    <Portal node={document && document.getElementById('root')} >
      {ReactDom.createPortal(modalContent(), document.body)}
    </Portal>
  )
};

interface IModificaItemProps {
  show: boolean,
  codigoBarras: string,
  onChanged?: (codigoBarras: string) => void;
  onCancel: () => void;
  transaccion: string;
}

export default ModificaItem;
