import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import {
  TTotalesFactura,
} from '../../../pages/ventas/types/types';
import { seleccionarSesion } from '../../../../../store/reducers';
import { RootState } from '../../../../../store/store';
import { ItemVenta, VentaListado } from '../../../types/types';
import { VentasService } from '../../../services/ventas.service';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { StatesEdition } from '../../../types/enums';
import config from '../../../../../config/config';
import { addToast } from '../../../../../store/toasterReducer';
import { TiposComprobantesSri, ToastTypes } from '../../../../../store/types';
import { DetalleVentaRedux } from '../../../pages/ventas/types/detalleVenta';
import Dialog from 'react-bootstrap-dialog'
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types';
import { VerRIDE } from '../../verRide/verRide';
// import AgregarDetalleLiquidacionMobile from './agregarDetalleLiquidacionMobile';
import AgregarDetalleLiquidacionMobil from './agregarDetalleLiquidacionMobil';
import ModificaItem from '../../items/modificarItem';
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../views/componentes/colContainer';
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput';
import { CBadge, CButton, CCol, CInput, CRow, CTooltip } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisH, faMinusCircle, faPlusCircle, } from '@fortawesome/pro-light-svg-icons';
import MUIDataTable from "mui-datatables";
import { getColumnnasTablaDetalleLiquidaciones, OptionsTablaDetalleVentas } from './helper';
import SelectBox from '../../../../../views/componentes/selectBox/selectBox';
import { DataSheetColumn } from '../../../../../views/componentes/customSheet/dataSheetColumn';
import CustomSheetNumberViewer from '../../../../../views/componentes/customSheet/viewers/sheetNumberViewer';
import { CellChangedArgs } from '../../../../../views/componentes/customSheet/cellChangedArgs';
import { RowCommand } from '../../../../../views/componentes/customSheet/genericRow';
import { SheetTextEditor } from '../../ventas/sheetEditors/sheetTextEditor';
import { changeEditStatus, clearButtonClick, closeTab, setButtons, setCurrentExecutingAction } from '../../../pages/liquidaciones/store/tabsReducer';
import {
  clearDatosEdicion, setDatosEdicion, setEditLoader, updateProveedor, updateDetalles,
  updateObservaciones, updateTotales, setMuestraError, setInfoError, updateFormaPago,
  setClaveAccesoGuardada,
  addDetalle
} from '../../../pages/liquidaciones/store/editDataReducer';
import VisualizaError from '../../../pages/shared/visualizaError/visualizaError';
import { CustomButtons, DocumentInfo, TabState } from '../../../types/generics';
import { CustomSheetTextEditor } from '../../../../../views/componentes/customSheet/editors/textEditor';
import { CustomSheetNumberEditor } from '../../../../../views/componentes/customSheet/editors/numberEditor';
import { v4 as uuidv4 } from 'uuid';
import { DateUtils, formatoFechasDatePickers } from '../../../../../helpers/dateUtils';
import Barcode from '../../../../../views/componentes/barcode/barcode';
import { CustomSheet } from '../../../../../views/componentes/customSheet/customSheet';
import { LiquidacionCompraDatosEdicion, TLiquidacionCompraIngresar } from '../../../pages/liquidaciones/types/types';
import { ProveedoresService } from '../../../../proveedores/services/proveedores.service';
import { ProveedorBusqueda } from '../../../../proveedores/store/types';
import { BuscarProveedorLookUp } from '../../../../proveedores/components/busquedaProveedor/BuscarProveedorLookUp';
import LoadingIndicator from '../../../../../views/componentes/loadingindicator/loadingindicator';
import { LiquidacionesComprasService } from '../../../pages/liquidaciones/services/liquidacionesCompra.service';
import { lh, MessagesKeys } from '../../../../../helpers/localizationHelper';
import { CompraService } from '../../../../compras/services/compra.service';
import { ItemCompras } from '../../../../compras/types/types';
import TextArea from 'devextreme-react/text-area';
import { SheetDescripcionItemSelectEditor } from './sheetEditors/sheetDescripcionItemSelectEditor';
import { DetalleLiquidacion, DetalleLiquidacionRedux } from '../../../pages/liquidaciones/types/detalleLiquidacion';
import {
  Validator,
  RequiredRule,
} from 'devextreme-react/validator';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ValidationSummary from 'devextreme-react/validation-summary';
import PopupReenvioMail from '../../ventas/busquedaVentas/popupReenvioMail/index'
import MainModalItems from '../../items/busquedaItemsModal/MainModalItems';
import { VentaHelper } from '../../../pages/liquidaciones/ventaHelper';

interface ILiquidacionProps {
  info: DocumentInfo<VentaListado>,
  tabId: string,
  tab: TabState<VentaListado>
}

const Liquidacion: React.FunctionComponent<ILiquidacionProps> = (props) => {
  const dispatch = useDispatch();
  const { tabId, tab, info } = props;
  const sesion = useSelector(seleccionarSesion);
  const usuario = useSelector((state: RootState) => { return state.global.session?.usuario });
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });
  const local = useSelector((state: RootState) => { return state.global.session?.local });
  //const currentTab = useSelector((state: RootState) => { return state.ventas.liquidaciones.tabs.tabs[props.tabIndex] });
  const globalButtonClick = useSelector((state: RootState) => { return state.ventas.liquidaciones.tabs.tabs[tabId].globalButtonClick });
  const currentTabEditSatus = useSelector((state: RootState) => { return state.ventas.liquidaciones.tabs.tabs[tabId].editStatus });
  const datosEdicion = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId] });
  const establecimiento = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].establecimiento });
  const puntoEmision = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].puntoEmision });
  const autorizacion = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].autorizacion });
  const claveAcceso = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].claveAcceso });
  const fecha = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].fecha });
  const proveedor = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].proveedor });
  const loading = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].loading });
  const totales = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].totales });
  const detalles = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].detalles });
  const formaPago = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].formaPago });
  const formasPago = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].formasPago });
  const observaciones = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].observaciones });
  const loader = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].loader });
  const tieneError = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].tieneError });
  const mensajeError = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].mensajeError });
  const autorizacionVenta = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].autorizacionVenta });
  const numerofactura = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].numerofactura });
  const fechaAutorizacion = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].fechaAutorizacion });
  const iva = useSelector((state: RootState) => { return state.ventas.liquidaciones.editData[props.tabId].iva });
  const puntoVenta = useSelector((state: RootState) => { return state.global.puntoVenta });
  const dialogRef = React.useRef<any>(null);
  const [filaEliminar, setFilaEliminar] = React.useState(-1);
  const [agregarDetalleMovil, setAgregarDetalleMovil] = React.useState(false);
  const [mostrarEdicionItems, setMostrarEdicionItems] = React.useState(false);
  const [mostrarBusquedaItems, setMostrarBusquedaItems] = React.useState(false);
  const [codigoBarrasItemEditar, setCodigoBarrasItemEditar] = React.useState('');
  const [verLiquidacionPdf, setVerFacturaPdf] = React.useState('');

  const [showReenviarMail, setShowReenviarMail] = React.useState<boolean>(false);

  const validationGroupRef = React.useRef<any>();

  const sheetCommands = React.useMemo(() => {
    const commads: Array<RowCommand<DetalleVentaRedux>> = [];
    //fas fa-file-invoice-dollar
    commads.push({
      id: 'Eliminar',
      text: 'Eliminar',
      icon: faMinusCircle,
      color: 'danger',
      onExecute: (rowIndex) => { setFilaEliminar(rowIndex) },
    });
    commads.push({
      id: 'Editar',
      text: 'Editar',
      icon: faEdit,
      onExecute: (rowIndex) => {
        setMostrarEdicionItems(true);
        setCodigoBarrasItemEditar(detalles[rowIndex].codigoBarras);
      },
    });
    return commads;
  }, [detalles]);

  const showEditLoader = React.useCallback((mensaje: string) => {
    dispatch(setEditLoader({
      key: tabId,
      info: {
        mensaje: mensaje,
        show: true
      }
    }));
  }, [dispatch, tabId])


  const hideEditLoader = React.useCallback(() => {
    dispatch(setEditLoader({
      key: tabId,
      info: {
        mensaje: '',
        show: false
      }
    }));
  }, [dispatch, tabId])

  const cargarLiquidacion = React.useCallback(async (numero: number, setInitData: boolean) => {
    if (loading === false) {
      return;
    }
    const data = { ...defaultDatosEdicionLiquidacionCompra }; //utilidades.unfreeze(defaultDatosEdicionFactura);
    try {
      if (setInitData) dispatch(setDatosEdicion({
        data: {
          ...data,
          loader: {
            show: true,
            mensaje: 'Cargando liquidación...'
          }
        },
        key: tabId
      }));
      const compra = await CompraService.getCompra(numero);

      if (compra) {
        const detalles = await CompraService.getDetallesCompra(compra.codigo); //VentasService.getDetalleVenta(numero, TiposComprobantesSri.LiquidacionCompra);
        const proveedor = await ProveedoresService.getProveedor(compra.proveedorIdentificacion);
        const formasPago = await CompraService.getFormasPago();


        const iva = await VentasService.getIva(compra.fecha, null);
        data.claveAcceso = compra.claveAcceso;
        data.iva = iva;
        data.establecimiento = compra.establecimiento;
        data.puntoEmision = compra.puntoEmision;
        data.numerofactura = compra.numero;
        data.observaciones = compra.observaciones;
        data.proveedor = proveedor;
        data.detalles = VentaHelper.detallesToDetallesVenta(detalles);
        data.formasPago = formasPago;
        data.fecha = DateUtils.apiDateToPickersDate(compra.fecha) ?? "";
        data.fechaAutorizacion = compra.fechaAutorizacion;
        const formaPago = formasPago.find(x => x.codigo === compra.formaPagoCodigo)
        if (formaPago) {
          data.formaPago = [formaPago];
        }

        data.loader = {
          mensaje: '',
          show: false
        }
        data.loading = false;
        dispatch(setDatosEdicion({
          data: data,
          key: tabId
        }));
        dispatch(setButtons({
          tabKey: tabId,
          buttons: {
            ...LiquidacionCompraButtons,
            Guardar: false,
            Autorizar: compra.fechaAutorizacion === '',
            Enviar: compra.fechaAutorizacion !== '',
            Deshacer: false,
            Descuento: false
          }
        }))
      }
    } catch (error) {
      data.loading = false;

      data.tieneError = true;
      data.mensajeError = error;
      dispatch(setDatosEdicion({
        data: data,
        key: tabId
      }));

    }
  }, [tabId, loading, dispatch]);

  const modoNuevo = React.useCallback(async (puntoVenta: string, limpiar: boolean = false) => {
    const data = { ...defaultDatosEdicionLiquidacionCompra };
    if (loading === false && !limpiar) {
      return;
    }
    try {
      //setLoading(true);
      const fecha = DateUtils.getCurrentDateAsString(formatoFechasDatePickers);//  moment().format('yyyy-MM-DD');
      const formasPago = await CompraService.getFormasPago();
      const autorizacion = await VentasService.getAutorizacion(puntoVenta, TiposComprobantesSri.LiquidacionCompra);
      const iva = await VentasService.getIva(DateUtils.pickersDateToApiDate(fecha), autorizacion);
      //const cliente = await ClientesService.getCliente(config.rucConsumidorFinal);
      //data.cliente = cliente;
      data.autorizacion = '';
      data.autorizacionVenta = autorizacion;
      data.fechaAutorizacion = 'NO AUTORIZADO (NUEVO)';
      data.detalles = [];
      data.iva = iva;
      data.fecha = fecha;
      data.establecimiento = autorizacion.establecimiento;
      data.puntoEmision = autorizacion.ptoemision;
      data.numerofactura = autorizacion.numActual.toString();
      data.identificadorTransaccion = uuidv4();
      data.observaciones = '';
      data.formasPago = formasPago;
      const fp = formasPago.find(fp => fp.nombre.toUpperCase() === "EFECTIVO");
      if (fp) {
        data.formaPago = [fp];
        //setFormaPago([fp]);
      }
      data.loading = false;
      data.loader = {
        mensaje: '',
        show: false
      }
    } catch (error) {
      data.loading = false;
      data.tieneError = true;
      data.mensajeError = typeof error == 'string' ? error : JSON.stringify(error);
      data.loader = {
        mensaje: '',
        show: false
      }
    }
    dispatch(setDatosEdicion({
      data: data,
      key: tabId
    }));
  }, [dispatch, tabId, loading]);


  const modoEdicion = React.useCallback(() => {
    if (proveedor == null) {
      return;
    }
    if (claveAcceso.length === 37 || claveAcceso.length === 49) {
      dispatch(addToast({
        autoHide: true,
        content: 'Una Liquidación de compra eléctronica no puede ser editada.',
        fade: true,
        id: '',
        title: 'Editar Liquidación',
        type: ToastTypes.Info
      }));
    } else {
    }
  }, [dispatch, proveedor, claveAcceso]);


  const guardar = React.useCallback(async () => {

    const validationResult = validationGroupRef.current.instance.validate();
    if (!validationResult.isValid) {
      dispatch(setDatosEdicion({
        data: {
          ...datosEdicion,
          tieneErroresValidar: true
        },
        key: tabId
      }));
      return;
    }
    if (usuario == null || empresa == null || local == null || puntoVenta == null || proveedor == null) {
      return;
    }
    dispatch(setDatosEdicion({
      data: {
        ...datosEdicion,
        tieneErroresValidar: false
      },
      key: tabId
    }));
    showEditLoader("Guardando liquidación...");
    try {
      const proveedorCompleto = await ProveedoresService.getProveedorByCode(proveedor.codigo);
      const liquidacion: TLiquidacionCompraIngresar = {
        autorizacion: "",
        establecimiento: establecimiento,
        puntoEmision: puntoEmision,
        fechaEmision: DateUtils.pickersDateToApiDate(fecha),
        pventa: puntoVenta ?? "",
        items: detalles.map((detalle) => {
          return {
            codigoPrincipal: detalle.codigoBarras,
            cantidad: detalle._cantidad,
            descripcion: detalle.descripcion,
            descuento: detalle._descuento,
            precioTotalSinImpuestos: detalle._subtotal,
            precio_unitario: detalle._precioUnitario,
            impuestos: [
              {
                codigo: detalle.tieneIva ? 3 : 2,
                baseImponible: detalle._subtotal,
                codigoPorcentaje: detalle.tieneIva ? 2 : 0,
                tarifa: parseInt((detalle.porcentajeiva * 100).toFixed(0)),
                valor: detalle._iva
              },

            ],
          }
        }),
        local: sesion?.local.codigo ?? 0,
        numero: 0,
        pagos: [{
          medio: formaPago[0].nombre,
          total: parseFloat(totales.total.toFixed(2))
        }],
        proveedor: {
          ciudad: proveedorCompleto.ciudadNombre ?? "",
          direccion: proveedor.direccion,
          email: proveedorCompleto.email ?? "",
          identificacion: proveedorCompleto.identificacion ?? "",
          razonSocial: proveedorCompleto.nombre ?? "",
          telefono: proveedorCompleto.telefono ?? ""
        },
        ruc: sesion?.empresa.ruc ?? "",
        tipoComprobante: TiposComprobantesSri.LiquidacionCompra,
        usuario: sesion?.usuario.codigo ?? 0,
        totales: {
          descuentoAdicional: 0,
          importeTotal: parseFloat(totales.total.toFixed(2)),
          impuestos: [
            {
              baseImponible: parseFloat(totales.subtotal0.toFixed(2)),
              codigoPorcentaje: 0,
              descuentoAdicional: 0,
              valor: 0
            },
            {
              baseImponible: parseFloat(totales.subtotalIva.toFixed(2)),
              codigoPorcentaje: 2,
              descuentoAdicional: 0,
              valor: parseFloat(totales.iva.toFixed(2))
            }
          ],
          totalSinImpuestos: parseFloat(totales.subtotal.toFixed(2))
        }
      }

      const resultado = await LiquidacionesComprasService.guardarLiquidacion(liquidacion);

      dispatch(changeEditStatus({
        estado: StatesEdition.save,
        tabKey: tabId,
        info: resultado,
        buttons: {
          ...LiquidacionCompraButtons,
          Guardar: false,
          Editar: true,
          Deshacer: false,
          Autorizar: resultado.estadoElectronico !== "AUTORIZADO",
          Enviar: resultado.estadoElectronico === "AUTORIZADO",
          Descuento: false,
          Anular: resultado.estadoElectronico === "AUTORIZADO",
        }
      }));

      dispatch(setClaveAccesoGuardada({ key: tabId, claveAcceso: resultado.claveAcceso, imprimir: true }))

      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Guardado',
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Success
      }))
      //await cargarVenta(resultado.clave_acceso, false);
    } catch (error) {
      console.log(error);
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al guardar liquidación ' + (typeof error === 'string' ? error : JSON.stringify(error)),
        fade: true,
        title: 'Guardar',
        type: ToastTypes.Danger
      }))
      dispatch(setCurrentExecutingAction({
        tabKey: tabId,
        buttonType: undefined
      }))
    }
    hideEditLoader();
  }, [datosEdicion, usuario, empresa, local, puntoVenta, proveedor, showEditLoader, hideEditLoader, establecimiento, puntoEmision, fecha, detalles, sesion, formaPago, totales, dispatch, tabId]);

  const deshacer = React.useCallback(() => {
    if (info.numero === 0) {
      dispatch(closeTab(tabId));
      dispatch(clearDatosEdicion(tabId))
    } else {
      dispatch(changeEditStatus({
        estado: StatesEdition.save,
        tabKey: tabId,
        buttons: { ...LiquidacionCompraButtons }
      }));
      cargarLiquidacion(info.numero, false);
    }

  }, [dispatch, cargarLiquidacion, tabId, info]);



  const sheetOnCellsChanged = React.useCallback(async (arrayOfChanges: Array<CellChangedArgs<DetalleLiquidacionRedux>>) => {
    const _detalles = detalles.slice(0);
    for (const change of arrayOfChanges) {
      const detalle = new DetalleLiquidacion(); //clase que realiza los calculos para un detalle
      detalle.setDetalle(change.rowData);
      switch (change.col.name) {
        case 'codigoBarras':
          showEditLoader('Buscando item...');
          const item = await CompraService.getItemCodigoBarras(detalle.codigoBarras, proveedor);
          if (item) {
            detalle.setItem(item);
            hideEditLoader();
          } else {
            dispatch(addToast({
              content: 'No se encuentra el item con código ' + change.newValue,
              title: 'Agregar item',
              type: ToastTypes.Warning,
              autoHide: 2000
            }))
            continue;
          }
          break;
        case 'cantidad':
          detalle.cantidad = change.newValue;
          detalle.calcular();
          break;
        case 'descripcion':
          if (typeof change.newValue === 'string') {
            detalle.descripcion = change.newValue;
          }
          break;
        case '_cantidad':
          detalle.cantidad = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        case '_porcentajeDescuento':
          detalle.porcentajeDescuento = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        case '_precioUnitario':
          detalle.precio = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        case '_precioIva':
          detalle.precioIva = parseFloat(change.newValue);
          //detalle.calcular();
          break;
        default:
          break;
      }
      if (change.rowIndex === -1) { //nueva fila
        _detalles.push(detalle.getDetalle());
      } else {
        _detalles[change.rowIndex] = detalle.getDetalle();
      }
    }
    dispatch(updateDetalles({
      detalles: _detalles,
      key: tabId
    }));
    hideEditLoader();
  }, [detalles, dispatch, hideEditLoader, showEditLoader, tabId, proveedor]);


  const calcularTotales = React.useCallback((detalles: Array<DetalleLiquidacionRedux>) => {
    let totalCero: TTotalesFactura = {
      descuento: 0,
      descuentoGloabal: 0,
      ice: 0,
      iva: 0,
      subtotal: 0,
      subtotal0: 0,
      subtotalIva: 0,
      total: 0
    };
    let totales = detalles.reduce((sum, next) => {
      sum.subtotal += next._subtotal;
      sum.subtotal0 += next._subtotal0;
      sum.subtotalIva += next._subtotalIva;
      sum.iva += next._iva;
      sum.descuento += next._descuento;
      sum.total += next._total;

      return sum;
    }, totalCero);



    dispatch(updateTotales({
      totales: totales,
      key: tabId
    }));
    //setTotales(totales);
  }, [tabId, dispatch])


  const agregarDetalleVentaMobile = React.useCallback((detalle: DetalleLiquidacion) => {
    const _detalles = detalles.slice(0);
    _detalles.push(detalle.getDetalle());
    dispatch(updateDetalles({
      key: tabId,
      detalles: _detalles
    }))
    setAgregarDetalleMovil(false);
  }, [detalles, tabId, dispatch])

  const cambiarProveedorSeleccionado = React.useCallback(async (proveedorORuc: string | ProveedorBusqueda | null) => {
    if (proveedorORuc === '') {
      dispatch(updateProveedor({
        proveedor: null,
        key: tabId,
        formasPago: []
      }));
      return;
    }
    const proveedor = (typeof proveedorORuc === 'string') ? await ProveedoresService.getProveedor(proveedorORuc) : proveedorORuc;
    if (proveedor) {
      if (proveedor.tipoIdentificacion === "01") {
        dispatch(setInfoError({ key: tabId, mensaje: lh.getMessage(MessagesKeys.LiquidacionCompraTipoIdentificacionIncorrecta), tieneError: true }))
      } else {
        dispatch(updateProveedor({
          proveedor: proveedor,
          key: tabId,
          formasPago: []
        }));
      }
    }
  }, [dispatch, tabId]);

  const onModalItemsItemSelected = React.useCallback((item: ItemVenta) => {
    const detalle = new DetalleLiquidacion();
    detalle.setItem(item);
    detalle.cantidad = 1;
    dispatch(addDetalle({
      key: tabId,
      detalle: detalle.getDetalle()
    }))
    setMostrarBusquedaItems(false);
  }, [tabId, dispatch]);

  //#region efectos

  const imprimir = React.useCallback(() => {
    setVerFacturaPdf(claveAcceso);
  }, [claveAcceso])


  const muiTableCellsChanged = React.useCallback((row, col, newValue) => {
    const _detalles = detalles.slice(0);
    //const detalle = _detalles[row];
    const _detalle = new DetalleLiquidacion();
    _detalle.setDetalle(_detalles[row]);

    //const dt = datosEdicion.detallesMuiTable;
    switch (col) {
      case 2://TABLECOL_DESCRIPCION
        _detalle.descripcion = newValue;
        //dt[row].descripcion = newValue;
        break;
      case 3://TABLECOL_CANTIDAD
        _detalle.cantidad = parseFloat(newValue);
        //dt[row].cantidad = newValue ? parseFloat(newValue) : 0;
        break;
      case 4://TABLECOL_PRECIO
        _detalle.precio = parseFloat(newValue);
        //dt[row].precio = newValue ? parseFloat(newValue) : 0;
        break;
      default:
        break;
    }
    _detalles[row] = _detalle.getDetalle();
    dispatch(updateDetalles({
      key: tabId,
      detalles: _detalles
    }))
  }, [detalles, tabId, dispatch]);

  const reenviarAutorizacion = React.useCallback(async () => {
    try {
      showEditLoader('Reenviando documento a autorizar...');
      await VentasService.reintentarAutorizacion(claveAcceso, TiposComprobantesSri.LiquidacionCompra);
      dispatch(addToast({
        content: 'El comprobante fue enviado al sri para su autorización.',
        type: ToastTypes.Info,
      }))
    } catch (error) {
      if (typeof (error) == 'string') {
        dispatch(addToast({
          content: error,
          type: ToastTypes.Danger,
        }))
      }
      console.log(error);
      //dispatch( )
    }
    hideEditLoader();
  }, [claveAcceso, showEditLoader, hideEditLoader, dispatch])

  React.useEffect(() => {

    if (currentTabEditSatus === StatesEdition.new) {
      modoNuevo(puntoVenta ?? "");
    } else {
      cargarLiquidacion(info.numero, true);
    }
  }, [puntoVenta, modoNuevo, cargarLiquidacion, info, currentTabEditSatus]);

  React.useEffect(() => {
    if (globalButtonClick && globalButtonClick !== ButtonTypes.none) {
      switch (globalButtonClick) {
        case ButtonTypes.edit:
          modoEdicion();
          break;
        case ButtonTypes.save: {
          guardar();
          break;
        }
        case ButtonTypes.undo:
          deshacer();
          break;
        case ButtonTypes.print:
          imprimir();
          break;
        case ButtonTypes.broom:
          modoNuevo(puntoVenta ?? "", true);
          break;
        case ButtonTypes.authorizations:
          reenviarAutorizacion();
          break;
        case ButtonTypes.sendMail:
          setShowReenviarMail(true);
          break;
        default:
          break;
      }
      dispatch(clearButtonClick(tabId))
    }
  }, [deshacer, guardar, modoEdicion, imprimir, modoNuevo, globalButtonClick, reenviarAutorizacion, puntoVenta, tabId, dispatch]);


  React.useEffect(() => {
    if (!loading) {
      calcularTotales(detalles);
    }
  }, [loading, detalles, calcularTotales])

  React.useEffect(() => {
    const eliminarDetalle = (indice: number, detalles: Array<DetalleLiquidacionRedux>) => {
      detalles.splice(indice, 1);
      dispatch(updateDetalles({
        detalles: detalles,
        key: tabId
      }));
      setFilaEliminar(-1);
    };

    const eliminarDetalleConfirm = (indice: number) => {
      const detalle = detalles[indice];
      dialogRef.current.show({
        title: 'Eliminar detalle',
        body: `¿Desea eliminar el item ${detalle.descripcion}?`,
        actions: [
          Dialog.Action(
            <span><u>E</u>liminar</span>,
            () => {
              eliminarDetalle(indice, detalles.slice(0));
            },
            'btn-success',
            'e'
          ),
          Dialog.Action(
            <span><u>C</u>ancelar</span>,
            (dialog) => {
              setFilaEliminar(-1);
              dialog.hide()
            },
            'btn-danger',
            'c'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide();
          setFilaEliminar(-1);
          console.log('closed by clicking background.')
        }
      });
      return;
    };
    if (filaEliminar >= 0) {
      eliminarDetalleConfirm(filaEliminar);
    }
  }, [tabId, filaEliminar, detalles, dispatch])




  //#endregion

  // if (datosEdicion.loading) {
  //   return <div style={{ width: '100%', height: '400px', padding: '50px' }}><SmallLoadingIndicator mensaje='Cargando venta...' /></div>
  // }

  const sm = window.innerWidth < 1000;

  return (
    <div style={{ padding: '10px', overflowX: 'hidden' }} >
      <BlockUi tag="div" loader={LoadingIndicator} blocking={loader.show} message={loader.mensaje}  >
        {showReenviarMail && proveedor != null && (
          <PopupReenvioMail
            showConfirm={showReenviarMail}
            itemReenviarMail={{
              claveAcceso: claveAcceso,
              proveedor: proveedor,
            }}
            closeConfirm={() => setShowReenviarMail(false)}
          />
        )}
        {tieneError &&
          <VisualizaError titulo='Error en liquidaciones' mensaje={mensajeError} onOk={() => {
            dispatch(setMuestraError({ key: props.tabId, tieneError: false, mensajeError: '' }))
            // if (currentTab.editStatus === StatesEdition.new) {
            //   deshacer();
            // }
          }} />
        }
        {verLiquidacionPdf && verLiquidacionPdf.length > 0 &&
          <VerRIDE modo='Legacy' claveAcceso={verLiquidacionPdf} onClose={() => setVerFacturaPdf('')} />
        }
        {agregarDetalleMovil &&
          <AgregarDetalleLiquidacionMobil
            key='agregarDetalleLiquidacionMovil'
            onOk={agregarDetalleVentaMobile}
            onCancel={() => setAgregarDetalleMovil(false)}
            rucCliente={proveedor ? proveedor.identificacion : config.rucConsumidorFinal}
            proveedor={proveedor}
          />
          // <AgregarDetalleLiquidacionMobile
          //   key='agregarDetalleLiquidacionMovil'
          //   onOk={agregarDetalleVentaMobile}
          //   onCancel={() => setAgregarDetalleMovil(false)}
          //   rucCliente={proveedor ? proveedor.identificacion : config.rucConsumidorFinal}
          // />

        }
        <Dialog ref={dialogRef} />
        {mostrarEdicionItems &&
          <ModificaItem
            transaccion={"compras"}
            show={mostrarEdicionItems}
            codigoBarras={codigoBarrasItemEditar}
            onCancel={() => setMostrarEdicionItems(false)}
            onChanged={(codigoBarras) => {
              setMostrarEdicionItems(false);
              setCodigoBarrasItemEditar('');
            }}
          />
        }
        {mostrarBusquedaItems === true && (
          <MainModalItems
            transaccion='compras'
            show={mostrarBusquedaItems}
            cliente={proveedor !== null ? proveedor : config.rucConsumidorFinal}
            onCancel={() => setMostrarBusquedaItems(false)}
            returnItem={onModalItemsItemSelected}
          />
        )}
        <fieldset disabled={tab.editStatus === StatesEdition.save} >
          <ValidationGroup id={`valGroupRetencion${props.tabId}`} ref={validationGroupRef}>
            {!isMobileOnly &&
              <RowContainer>
                <CustomCol>
                  <ValidationSummary />
                </CustomCol>
              </RowContainer>
            }
            <div>

              <RowContainer gutters={true} >
                <CustomCol xs='12' md='7'>
                  <div>
                    <RowContainer gutters={true}>

                      <CustomCol xs='12'>
                        <Labeled
                          label='Nombre / Razón Social del Proveedor'
                        >
                          <BuscarProveedorLookUp selected={proveedor} onChanged={(value) => {
                            cambiarProveedorSeleccionado(value ?? '');
                          }} allowAdd allowClear allowEdit >
                            <Validator>
                              <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Proveedor")}  ></RequiredRule>
                            </Validator>
                          </BuscarProveedorLookUp>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    {proveedor &&
                      <RowContainer>
                        <CustomCol
                          xs='12'
                        >
                          <Labeled
                            label='Dirección'
                          >
                            <CInput
                              size='sm'
                              readOnly
                              value={proveedor.direccion}
                            >

                            </CInput>
                          </Labeled>
                        </CustomCol>
                        <CustomCol
                          xs='4'
                        >
                          <Labeled
                            label='Teléfono'
                          >
                            <CInput
                              size='sm'
                              readOnly
                              value={proveedor.telefono ?? ''}
                            >

                            </CInput>
                          </Labeled>
                        </CustomCol>
                        <CustomCol
                          xs='8'
                        >
                          <Labeled
                            label='Correo eléctronico'
                          >
                            <CInput
                              size='sm'
                              readOnly
                              value={proveedor.email ?? ''}
                            >

                            </CInput>
                          </Labeled>
                        </CustomCol>
                      </RowContainer>
                    }
                  </div>
                </CustomCol>
                <CustomCol xs='12' md='5'>
                  <div>
                    <RowContainer>
                      <CustomCol xs='12' md='5'>
                        <Labeled
                          label='Fecha'
                        >
                          <CInput
                            size='sm'
                            type='date'
                            value={fecha}
                            readOnly
                          />
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs='12' md='7'>
                        <Labeled
                          label='Número'
                        >
                          <CRow gutters={false}>
                            <CCol xs='3' >
                              <CInput readOnly size='sm' placeholder='001' value={establecimiento} />
                            </CCol>
                            <CCol xs='3'>
                              <CInput readOnly size='sm' placeholder='001' value={puntoEmision} />
                            </CCol>
                            <CCol xs='6'>
                              <CInput readOnly size='sm' placeholder='0000000000' value={"0".repeat(10 - numerofactura.toString().length) + numerofactura.toString()} />
                            </CCol>
                          </CRow>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    <RowContainer>
                      <CustomCol
                        xs='6' md='3'
                      >
                        <Labeled
                          label='# Detalles'
                        >
                          <CInput
                            size='sm'
                            readOnly
                            value={detalles.length}
                          />
                        </Labeled>
                      </CustomCol>
                      <CustomCol
                        xs='6' md='3'
                      >
                        <Labeled
                          label='# Articulos'
                        >
                          <CInput
                            size='sm'
                            readOnly
                            value={detalles.reduce((prev, next) => prev + next._cantidad, 0).toFixed(2)}
                          />
                        </Labeled>
                      </CustomCol>
                      <CustomCol xs='12' md='6'>
                        <Labeled
                          label='Punto Venta'
                        >
                          <CInput
                            readOnly
                            value={puntoVenta ?? ''}
                            size='sm'
                          >

                          </CInput>
                        </Labeled>
                      </CustomCol>
                    </RowContainer>
                    {((tab.editStatus === StatesEdition.save) || (autorizacionVenta !== undefined && autorizacionVenta.aut == null)) && //Electronica
                      <>
                        < RowContainer >
                          <CustomCol xs='12'>
                            {!isMobileOnly &&
                              <Barcode text={claveAcceso} />
                            }
                            {isMobileOnly &&
                              <Labeled
                                label='Autorización'
                              >
                                <small>{autorizacion}</small>
                              </Labeled>
                            }

                          </CustomCol>
                        </RowContainer>
                        <RowContainer>
                          <CustomCol>
                            <Labeled
                              label={'Fecha Autorización'}
                            >
                              <CBadge
                                color={fechaAutorizacion ? 'info' : 'danger'}
                              >
                                {fechaAutorizacion ? datosEdicion.fechaAutorizacion : "No Autorizado"}
                              </CBadge>
                            </Labeled>
                          </CustomCol>
                        </RowContainer>
                      </>
                    }
                  </div>
                </CustomCol>
              </RowContainer>
              <div id="contentDetallLiquidacion" >
                {tab.editStatus !== StatesEdition.save &&
                  <RowContainer>
                    <CCol  >
                      {isMobileOnly &&
                        <CButton
                          size='md'
                          color='primary'
                          onClick={() => {
                            setAgregarDetalleMovil(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} />
                          <span style={{ marginLeft: '5px' }}>{"Agregar Detalle"}</span>
                        </CButton>
                      }

                      <CButton
                        id='btnRegistrarItem'
                        size='md'
                        color='info'
                        onClick={async () => {
                          //const result = await VentasService.desactivarItem(4436);
                          //console.log(result);
                          setCodigoBarrasItemEditar('');
                          setMostrarEdicionItems(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} />
                        <span style={{ marginLeft: '5px' }}>{"Crear Producto"}</span>
                      </CButton> {"  "}
                      <CTooltip
                        key={"tool-more"}
                        placement="top"
                        content={"Más opciones"}
                      >
                        <CButton
                          id='btnBuscarItem'
                          size='md'
                          color='light'
                          className="ml-2"
                          onClick={() => {
                            setMostrarBusquedaItems(true);
                          }}
                        >
                          <FontAwesomeIcon size="1x" icon={faEllipsisH} />
                        </CButton>
                      </CTooltip>

                    </CCol>
                  </RowContainer>
                }

                {isMobileOnly && //Mobiles
                  <RowContainer>
                    <div style={{ paddingTop: '5px' }}>
                      <MUIDataTable
                        data={detalles}
                        columns={getColumnnasTablaDetalleLiquidaciones(
                          muiTableCellsChanged,
                          (row) => setFilaEliminar(row)
                        )}
                        options={OptionsTablaDetalleVentas}
                      />
                    </div>
                  </RowContainer>
                }
                {isMobile && !isMobileOnly &&  //Tablets
                  < RowContainer >
                    <CCol xs='12'>

                      <div className='detallesTableContainer' style={{ maxHeight: `${window.innerHeight / 2}px` }}  >
                        <CustomSheet
                          striped
                          id='sheetDetalle'
                          blankRows={2}
                          columns={sheetColumns}
                          data={detalles}
                          editable={tab.editStatus !== StatesEdition.save}
                          initializeNewRow={(col, value) => {
                            const detalle: DetalleLiquidacion = new DetalleLiquidacion();
                            if (col.name === 'descripcion') {
                              const item = value as ItemCompras;
                              detalle.setItem(item);
                            } else {
                              detalle[col.name] = value;
                            }

                            return detalle.getDetalle();
                          }}
                          onCellChanged={sheetOnCellsChanged}
                          showRowNumber
                          rowCommands={sheetCommands}
                        />
                      </div>

                    </CCol>
                  </RowContainer>
                }

                {!isMobile && //Escritorio
                  < RowContainer >
                    <CCol xs='12'>
                      <div className='detallesTableContainer overflow-auto' onKeyDown={(e) => { }} style={{ width: window.innerWidth > 900 ? '100%' : `${window.innerWidth - 20}px`, maxHeight: `${window.innerHeight / 2}px` }}  >
                        <CustomSheet
                          striped
                          id='sheetDetalle'
                          blankRows={2}
                          columns={sheetColumns}
                          data={detalles}
                          editable={tab.editStatus !== StatesEdition.save}
                          initializeNewRow={(col, value) => {
                            const detalle: DetalleLiquidacion = new DetalleLiquidacion();
                            if (col.name === 'descripcion') {
                              const item = value as ItemCompras;
                              detalle.setItem(item);
                            } else {
                              detalle[col.name] = value;
                            }

                            return detalle.getDetalle();
                          }}
                          onCellChanged={sheetOnCellsChanged}
                          showRowNumber
                          rowCommands={sheetCommands}
                        />
                      </div>
                    </CCol>
                  </RowContainer>
                }
              </div>

              <RowContainer>
                <CustomCol xs='12' md={8}>
                  <Labeled
                    label='Observaciones'
                  >
                    <TextArea
                      value={observaciones}
                      onValueChanged={(e) => {
                        dispatch(updateObservaciones({
                          key: tabId,
                          observaciones: e.value ?? ''
                        }))
                      }}
                    >
                    </TextArea>
                  </Labeled>
                </CustomCol>
                <CustomCol xs='12' md='3'>
                  <Labeled
                    label='Forma Pago'
                  >
                    <SelectBox
                      id='selectFormaPago'
                      displayExpr='nombre'
                      keyExpr='codigo'
                      options={formasPago}
                      selected={formaPago && formaPago.length > 0 ? formaPago[0] : null}
                      placeholder='Forma de pago'
                      multiple={false}
                      onChange={(selected) => {
                        dispatch(updateFormaPago({
                          formaPago: selected ? [selected] : [],
                          key: props.tabId
                        }));
                      }}
                      clearButton={true}
                    >
                      <Validator>
                        <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Forma Pago")}  ></RequiredRule>
                      </Validator>
                    </SelectBox>
                  </Labeled>
                </CustomCol>

              </RowContainer>
              {!sm &&
                < RowContainer >
                  <div className='totalesContainer'>
                    <div className='totalContainer'>
                      <div className='totalLabel'>
                        <span>Subtotal 0%</span>
                      </div>
                      <div className='totalValue'>
                        <span>{totales.subtotal0.toFixed(2)} </span>
                      </div>
                    </div>
                    <div className='totalContainer'>
                      <div className='totalLabel'>
                        <span>Subtotal IVA</span>
                      </div>
                      <div className='totalValue'>
                        <span> {totales.subtotalIva.toFixed(2)} </span>
                      </div>
                    </div>
                    <div className='totalContainer'>
                      <div className='totalLabel'>
                        <span>Subtotal</span>
                      </div>
                      <div className='totalValue'>
                        <span> {totales.subtotal.toFixed(2)} </span>
                      </div>
                    </div>
                    <div className='totalContainer'>
                      <div className='totalLabel'>
                        <span>Descuento</span>
                      </div>
                      <div className='totalValue'>
                        <span> {totales.descuento.toFixed(2)} </span>
                      </div>
                    </div>
                    <div className='totalContainer'>
                      <div className='totalLabel'>
                        <span>IVA {iva}%</span>
                      </div>
                      <div className='totalValue'>
                        <span> {totales.iva.toFixed(2)} </span>
                      </div>
                    </div>
                    <div className='totalContainer'>
                      <div className='totalLabel'>
                        <span>Total</span>
                      </div>
                      <div className='totalValue'>
                        <span> {totales.total.toFixed(2)} </span>
                      </div>
                    </div>
                  </div>
                </RowContainer>
              }
              {sm &&
                <>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Subtotal</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      <small>{totales.subtotal.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Impuestos</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      <small>{totales.iva.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                  <RowContainer>
                    <CustomCol xs='8'>
                      <small>Total</small>
                    </CustomCol>
                    <CustomCol xs='4'>
                      <small>{totales.total.toFixed(2)}</small>
                    </CustomCol>
                  </RowContainer>
                </>
              }
            </div>

            {isMobileOnly &&
              <RowContainer>
                <CustomCol>
                  <ValidationSummary />
                </CustomCol>
              </RowContainer>
            }

          </ValidationGroup>
        </fieldset>
      </BlockUi>
    </div >
  );
};

const sheetColumns: Array<DataSheetColumn> = [
  {
    id: 1,
    colSpan: 1,
    name: 'codigoBarras',
    headerText: 'Código',
    width: '10%',
    order: 1,
    dataEditor: CustomSheetTextEditor,
    getReadOnly: (data) => {
      return data !== null;
    }
  },
  {
    id: 2,
    colSpan: 1,
    name: 'descripcion',
    headerText: 'Descripción',
    width: '20%',
    order: 2,
    getReadOnly: (data) => {
      return false;
    },
    getDataEditor: (data) => {
      return data == null ? SheetDescripcionItemSelectEditor : SheetTextEditor
    }
  },
  {
    id: 3,
    colSpan: 1,
    name: 'porcentajeiva',
    headerText: 'Imp.',
    width: '100px',
    order: 3,
    readOnly: true,
    getDisplay: (value, data: DetalleVentaRedux) => `${(data.porcentajeiva * 100).toFixed(2)}% - ${data._iva.toFixed(2)}`
  },
  {
    id: 4,
    colSpan: 1,
    name: '_cantidad',
    headerText: 'Cant.',
    width: '60px',
    order: 4,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer
  },
  {
    id: 5,
    colSpan: 1,
    name: '_porcentajeDescuento',
    headerText: 'Desc %.',
    width: '80px',
    order: 5,
    getReadOnly: (data) => {
      return true;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleVentaRedux) => `${data._porcentajeDescuento.toFixed(2)}% (${data._descuento.toFixed(2)})`
  },
  {
    id: 6,
    colSpan: 1,
    name: '_precioUnitario',
    headerText: 'P. Unit.',
    width: '80px',
    order: 6,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer
  },
  {
    id: 7,
    colSpan: 1,
    name: '_precioIva',
    headerText: 'Unit. IVA',
    width: '80px',
    order: 7,
    getReadOnly: (data) => {
      return data == null;
    },
    dataEditor: CustomSheetNumberEditor,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleVentaRedux) => data._precioIva.toFixed(4)
  },
  {
    id: 8,
    colSpan: 1,
    name: '_total',
    headerText: 'Subtotal',
    width: '80px',
    order: 8,
    readOnly: true,
    valueViewer: CustomSheetNumberViewer,
    getDisplay: (value, data: DetalleVentaRedux) => data._subtotal.toFixed(4)
  }
];

export const defaultDatosEdicionLiquidacionCompra: LiquidacionCompraDatosEdicion = {
  autorizacion: '',
  claveAcceso: '',
  codigoBarrasItemEditar: '',
  descuentoGlobal: 0,
  descuentoPorItemPorcentaje: 0,
  descuentoPorItemValor: 0,
  detalles: [],
  establecimiento: '',
  fecha: '',
  fechaAutorizacion: '',
  formaPago: [],
  formasPago: [],
  identificadorTransaccion: '',
  imprimir: false,
  iva: 0,
  loader: {
    mensaje: '',
    show: false,
  },
  loading: true,
  mensajeError: '',
  mostrarBusquedaItems: false,
  mostrarEdicionCliente: false,
  mostrarEdicionItems: false,
  numero: 0,
  numerofactura: '',
  observaciones: '',
  proveedor: null,
  puntoEmision: '',
  tieneError: false,
  totales: {
    descuento: 0,
    descuentoGloabal: 0,
    ice: 0,
    iva: 0,
    subtotal: 0,
    subtotal0: 0,
    subtotalIva: 0,
    total: 0
  },
  tieneErroresValidar: false
}

export const LiquidacionCompraButtons: CustomButtons = {
  Nuevo: true,
  Guardar: true,
  Buscar: true,
  Deshacer: true,
  Editar: true,
  Imprimir: true,
  Descuento: true,
  Credito: true,
  Enviar: true,
  Autorizar: true,

}


export default Liquidacion;