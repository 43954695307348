import * as React from 'react';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { Proveedor, ProveedorBusqueda } from '../../store/types';
import { ProveedoresService } from '../../services/proveedores.service';
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../views/componentes/colContainer';
import SelectBox from 'devextreme-react/select-box';
import { ModalAdminProveedores } from '../modalAdminProveedores/ModalAdminProveedores';
import Button from 'devextreme-react/button';


interface IBuscarProveedorLookUpProps {
  selected: ProveedorBusqueda | null,
  onChanged: (newValue: ProveedorBusqueda | null) => void,
  allowAdd?: boolean,
  allowClear?: boolean,
  allowEdit?: boolean,
  esTransportista?: boolean,
  disabled?: boolean,
  onEnterKey?: any
}



export const BuscarProveedorLookUp: React.FC<IBuscarProveedorLookUpProps> = (props) => {
  const { selected, onChanged, esTransportista, disabled, allowAdd, allowClear, allowEdit, onEnterKey } = props;
  const [mostrarEdicion, setMostrarEdicion] = React.useState(false);
  const [proveedorEditando, setProveedorEditando] = React.useState<ProveedorBusqueda | null>(null);
  const selectRef = React.useRef<any>();
  const datasource = React.useMemo(() => {
    return ProveedoresService.getProveedoresDatasource(true, esTransportista);
  }, [esTransportista]);

  const onLookUpValueChanged = React.useCallback(({ value }) => {
    if (typeof (value) == 'string') return;
    onChanged(value);
  }, [onChanged]);

  const getProveedorDisplayExpr = React.useCallback((proveedor: Proveedor) => {
    return proveedor ? `${proveedor.identificacion} - ${proveedor.nombre}` : '';
  }, []);


  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])

  const onModificarProveedorChanged = React.useCallback(async (identificacion) => {
    setMostrarEdicion(false);
    setProveedorEditando(null);
    if (selected) {
      await datasource.reload();
      if (selectRef) {
        selectRef.current.instance.repaint();
      }
    } else {
      const nuevo = await ProveedoresService.getProveedor(identificacion);
      onChanged(nuevo);
    }
    //onChanged(nuevo)
  }, [selected, onChanged, datasource]);

  const onModificarProveedorCancel = React.useCallback(() => {
    setProveedorEditando(null);
    setMostrarEdicion(false);
  }, []);

  if (allowAdd || allowEdit || allowClear) {
    return (
      <>
        <ModalAdminProveedores
          codigoProveedor={proveedorEditando ? proveedorEditando.codigo : null}
          onChanged={onModificarProveedorChanged}
          onCancel={onModificarProveedorCancel}
          show={mostrarEdicion}
          esTransportista={esTransportista ?? false}
        />
        <RowContainer gutters={false} >
          <CustomCol xs='12' md='9'>
            <SelectBox ref={selectRef}
              dataSource={datasource}
              searchEnabled
              displayExpr={getProveedorDisplayExpr}
              searchExpr={['nombre', 'identificacion']}
              searchTimeout={500}
              value={selected}
              onValueChanged={onLookUpValueChanged}
              onFocusIn={onSelectBoxFocusIn}
              disabled={disabled}
              onEnterKey={onEnterKey}
            >
              {props.children}
            </SelectBox>
          </CustomCol>
          <CustomCol md='3' xs='6'  >
            <div style={{ display: 'flex' }}>
              {allowEdit && selected &&

                <div style={{ marginLeft: '1px' }} >
                  <Button
                    stylingMode='contained'
                    type='default'
                    icon='edit'
                    onClick={() => {
                      setProveedorEditando(selected);
                      setMostrarEdicion(true);
                    }} >

                  </Button>
                </div>

              }
              {(allowAdd && !disabled) &&

                <div style={{ marginLeft: '1px' }} >
                  <Button
                    stylingMode='contained'
                    type='default'
                    icon='add'
                    onClick={() => {
                      setProveedorEditando(null);
                      setMostrarEdicion(true);
                    }} >

                  </Button>
                </div>

              }
              {!disabled && allowClear && selected &&

                <div style={{ marginLeft: '1px' }} >
                  <Button
                    stylingMode='contained'
                    type='danger'
                    icon='undo'
                    onClick={() => {
                      onChanged(null);
                    }} >
                  </Button>
                </div>

              }
            </div>
          </CustomCol>
        </RowContainer>
      </>
    );
  }


  return (
    <Lookup
      dataSource={datasource}
      showDataBeforeSearch
      value={selected}
      minSearchLength={3}
      searchTimeout={500}
      displayExpr={getProveedorDisplayExpr}
      searchExpr={['nombre', 'identificacion']}
      pageLoadMode='scrollBottom'
      showClearButton={true}
      onValueChanged={({ value }) => {
        onChanged(value);
      }}
      disabled={disabled}
      onEnterKey={onEnterKey}
    >
      <DropDownOptions
        tabIndex={3000}
        closeOnOutsideClick={true}
        showTitle={false}
      />
      {props.children}
    </Lookup >
  )
}

