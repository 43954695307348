import * as React from 'react';
import { CModal } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../store/toasterReducer';
import { ToastTypes } from '../../../store/types';
import { isMobile, isMobileOnly } from 'react-device-detect';
import ReactDOM from 'react-dom';
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

interface IPopupContentProps {
  show: boolean,
  title: string,
  subTitle?: string,
  onClose: () => void,
  size?: "sm" | "lg" | "xl",
  portalElementId?: string,
  canClose?: boolean,
  width?: string | number,
  height?: string | number,
}


export const PopupContent: React.FC<IPopupContentProps> = (props) => {
  const { title, subTitle, show, onClose, canClose } = props;

  const getHeight = React.useCallback((size: "sm" | "md" | "lg" | undefined, height: number | undefined): string | number | undefined => {
    if (height) {
      return height;
    }
    return undefined;
  }, []);

  return (
    <Popup
      title={title}
      height={300}
      visible={show}
      showTitle={title !== ""}
      closeOnOutsideClick={canClose || false}
      showCloseButton
      onHiding={onClose}
      shadingColor='rgba(0,0,0,0.4)'
      position={"center"}
      toolbarItems={
        [
          {
            options: {
              text: 'Aceptar',
              stylingMode: 'contained',
              type: 'default',
              icon: 'check',
              onClick: onClose
            },
            widget: 'dxButton',
            toolbar: 'bottom',
            location: 'center'
          }
        ]
      }
    >
      <ScrollView
        width={'100%'}
        height={'100%'}
      >
        <React.Fragment key='popupContentAdvertencias'>
          {subTitle !== "" &&
            (
              <label>{subTitle}</label>
            )
          }
          {props.children}
        </React.Fragment>
      </ScrollView>
    </Popup >
  );

};


