import { CRow } from '@coreui/react';
import * as React from 'react';

interface IRowContainerProps {
  className?: string,
  gutters?: boolean
}

const RowContainer: React.FunctionComponent<IRowContainerProps> = (props) => {
  return <CRow style={{ paddingTop: '5px' }} className={props.className ?? ''} gutters={props.gutters ?? true} >
    {props.children}
  </CRow>;
};

export default RowContainer;
