import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo } from "../../../../../store/types";
import { CustomDictionary } from "../../../../ventas/types/generics";
import {
  Cliente,
  FormaPagoModuloVentas,
  VentaInfo,
} from "../../../types/types";
import { DetalleRetencionRedux, RetencionDatosEdicion } from "../types/types";
import { ventasClearDatosEdicion } from "../../../store/reducers";

const initialState: CustomDictionary<RetencionDatosEdicion> = {};

const datosEdicionRetencionesSlice = createSlice({
  name: "retencionesVentasDatosEdicion",
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setFechaRetencion(
      state,
      action: PayloadAction<{ key: number | string; fecha: string }>
    ) {
      state[action.payload.key].fechaEmision = action.payload.fecha;
    },
    setNumeroRetencion(
      state,
      action: PayloadAction<{ key: number | string;   numero: string }>
    ) {
      state[action.payload.key].numero = action.payload.numero;
      const splitData = action.payload.numero.split("-");
      if (splitData.length === 3) {
        state[action.payload.key].establecimiento = splitData[0];
        state[action.payload.key].puntoEmision = splitData[1];
      }
    },
    setNumeroLote(
      state,
      action: PayloadAction<{ key: number | string; numero: string }>
    ) {
      state[action.payload.key].numeroLote = action.payload.numero;
    },
    setFormaPago(
      state,
      action: PayloadAction<{
        key: number | string;
        formaPago: FormaPagoModuloVentas;
      }>
    ) {
      state[action.payload.key].formaPago = action.payload.formaPago;
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: number | string;
        data: RetencionDatosEdicion;
      }>
    ) {
      state[action.payload.key] = action.payload.data;
    },
    setVenta(
      state,
      action: PayloadAction<{ key: number | string; venta: VentaInfo }>
    ) {
      state[action.payload.key].venta = action.payload.venta;
    },
    updateDetalles(
      state,
      action: PayloadAction<{
        key: number | string;
        detalles: Array<DetalleRetencionRedux>;
      }>
    ) {
      state[action.payload.key].detalles = action.payload.detalles;
    },
    updateCliente(
      state,
      action: PayloadAction<{
        key: number | string;
        cliente: Cliente | null;
        formasPago: Array<FormaPagoModuloVentas>;
      }>
    ) {
      state[action.payload.key].cliente = action.payload.cliente;
      if (state[action.payload.key]) {
        const formaPago = action.payload.formasPago.find(
          (x) => x.codigo === state[action.payload.key].formaPago?.codigo
        );
        state[action.payload.key].formaPago = formaPago
          ? formaPago
          : action.payload.formasPago.filter(
              (x) => x.descripcion === "EFECTIVO"
            )[0];
      } else {
        state[action.payload.key].formaPago = action.payload.formasPago.filter(
          (x) => x.descripcion === "EFECTIVO"
        )[0];
      }
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>
    ) {
      state[action.payload.key].loader = action.payload.info;
    },
    setMuestraError(
      state,
      action: PayloadAction<{ key: number | string; error: string }>
    ) {
      state[action.payload.key].error = action.payload.error;
    },
  },
});

export const {
  setDatosEdicion,
  updateDetalles,
  updateCliente,
  setEditLoader,
  ventasClearDatosEdicion: clearDatosEdicion,
  setMuestraError,
  setFechaRetencion,
  setNumeroRetencion,
  setNumeroLote,
  setFormaPago,
} = datosEdicionRetencionesSlice.actions;
export const datosEdicionReducer = datosEdicionRetencionesSlice.reducer;
