import { RequestHelper } from "../helpers/requestHelper";
import { IdentificacionSriValidationResult } from "../store/types";
import { SesionService } from "./sesion.service";

export const GeneralService = {
  validarIndentificacion,
  validarIndentificacionSri,
  deleteFileServer,
  validaIndentificacionGetData
};

async function validarIndentificacion(
  id: string
): Promise<IdentificacionSriValidationResult> {
  const apiQuery = {
    id,
  };
  const apiResult = await RequestHelper.get<any>(
    "validIdLocal",
    "",
    "",
    apiQuery
  );
  const result: IdentificacionSriValidationResult = {
    isValid: false,
  };
  if (apiResult.isValid) {
    result.isValid = true;
  } else {
    result.motivo = apiResult.motivo;
  }
  return result;
}

async function validarIndentificacionSri(
  id: string
): Promise<IdentificacionSriValidationResult> {
  const apiQuery = {
    id,
  };
  const apiResult = await RequestHelper.get<any>("validId", "", "", apiQuery);
  const result: IdentificacionSriValidationResult = {
    isValid: false,
  };
  if (apiResult.isValid) {
    result.isValid = true;
    result.nombre = apiResult.nombre;
    result.razonComercial = apiResult.razonComercial;
    result.direccion = apiResult.direccion;
  }
  return result;
}

function deleteFileServer(id) {
  const sesion = SesionService.getCurrentSesion();
  const apiQuery = {
    id: id,
    ruc: sesion.empresa.ruc,
  };
  const data = RequestHelper.deleteRequestAll('pdf', 'delete', '', apiQuery);
  return data;
}

async function validaIndentificacionGetData(
  apiQuery
): Promise<any> {
  const apiResult = await RequestHelper.getAllNoSession(
    "public",
    "getDataUser",
    "",
    apiQuery
  );

  return apiResult;
}