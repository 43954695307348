import axios, { AxiosRequestConfig, Method } from "axios";
import config from "../config/config";
import axiosRetry from "axios-retry";
import * as AxiosLogger from "axios-logger";
import { UrlHelper } from "./urlHelper";
import { Auth } from "aws-amplify";

export const RequestHelper = {
  //setToken,
  get,
  post,
  getRequestUrl,
  postUrlEncoded,
  postUrlEncodedAll,
  postData,
  postAll,
  execute,
  deleteRequest,
  putUrlEncodedAll,
  getAll,
  getConfig,
  getFile,
  deleteRequestAll,
  cerrarSesionOnUnauthorized,
  getAllNoSession,
  postDataNoSession,
  postAllNoSession,
  postUrlEncodedAllNoSession
};

export const API_URL = `${UrlHelper.getUrls().apiBasePath}/${config.apiVersion
  }`;

type ApiRequestEntityResult<T> = {
  error: boolean;
  message: string | null;
  auto: T;
};


function validateStatusAxios(status) {
  return status >= 200 && status < 500; // default
}

axiosRetry(axios, {
  retries: 3,
  retryCondition: (error) => {
    console.error(JSON.stringify(error));

    return error.message.includes("503") || error.message === "Network Error";
  },
});

const instance = axios.create();
AxiosLogger.setGlobalConfig({
  prefixText: "your prefix",
  dateFormat: "HH:MM:ss",
  status: false,
  headers: true,
  logger: (text) => text,

});

instance.interceptors.request.use(
  AxiosLogger.requestLogger,
  AxiosLogger.errorLogger
);

function getRequestUrl(
  modulo: string,
  controller: string,
  key: string | null,
  query: any | null
): string {
  let url = `${API_URL}/${modulo}${controller.length > 0 ? "/" : ""
    }${controller}${key === null ? "" : key}`;
  let params = "?";
  if (query !== null && query !== undefined) {
    const keys = Object.keys(query);
    for (let index = 0; index < keys.length; index++) {
      const value = query[keys[index]];
      params += `${keys[index]}=${value}&`;
    }
  }
  params = params.slice(0, -1);
  return url + params;
}

async function getFile(
  modulo: string,
  controller: string,
  key: string,
  query: any | null | undefined,
  type: any | undefined
): Promise<Blob> {
  try {
    const url = getRequestUrl(modulo, controller, key, query);
    var config = await getConfigAsync("GET");
    const response = await axios.get(url, {
      ...config,
      responseType: "blob",
    });
    return type ? new Blob([response.data], type) : new Blob([response.data]);
  } catch (error) {
    throw error;
  }
}

async function get<T>(
  modulo: string,
  controller: string,
  key: string,
  query: any | null | undefined
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, key, query);
    const cfg = await getConfigAsync("GET");
    const data = await axios.get<ApiRequestEntityResult<T>>(url, cfg);
    if (data.status === 200) {
      if (data.data.error) {
        throw data.data.message;
      } else {
        const apiData = data.data as ApiRequestEntityResult<T>;
        return apiData.auto;
      }
    } else if (data.status === 401) {
      cerrarSesionOnUnauthorized();
    }
    throw data.statusText;
  } catch (error) {
    throw error;
  }
}

async function getAll<T>(
  modulo: string,
  controller: string,
  key: string,
  query: any | null | undefined
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, key, query);
    const cfg = await getConfigAsync("GET");
    const data = await axios.get<ApiRequestEntityResult<T>>(url, cfg);
    if (data.status === 401) {
      cerrarSesionOnUnauthorized();
    }
    throw data.data;
  } catch (error) {
    throw error;
  }
}

async function getAllNoSession(
  modulo: string,
  controller: string,
  key: string,
  query: any | null | undefined
): Promise<any> {
  try {
    const url = getRequestUrl(modulo, controller, key, query);
    const cfg = await getConfigNoSession("GET");
    const data = await axios.get<any>(url, cfg);
    return data.data;
  } catch (error) {
    throw error;
  }
}

async function deleteRequest<T>(
  modulo: string,
  controller: string,
  key: string,
  query: any | null | undefined,
  postData?
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, key, query);
    const config = await getConfigAsync("DELETE");
    config.data = postData;
    const data = await axios.delete<ApiRequestEntityResult<T>>(url, config);
    if (data.status === 200) {
      if (data.data.error) {
        throw data.data.message;
      } else {
        const apiData = data.data as ApiRequestEntityResult<T>;
        return apiData.auto;
      }
    } else if (data.status === 401) {
      cerrarSesionOnUnauthorized();
    }
    throw data.statusText;
  } catch (error) {
    throw error;
  }
}

async function post<T>(
  modulo: string,
  controller: string,
  data: any | null
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, null, null);
    const cfg = await getConfigAsync("POST");
    const postResult = await axios.post(url, data, cfg);
    if (postResult.status === 200) {
      if (postResult.data.error) {
        throw postResult.data.message;
      } else {
        const apiData = postResult.data as ApiRequestEntityResult<T>;
        return apiData.auto;
      }
    } else if (postResult.status === 401) {
      cerrarSesionOnUnauthorized();
    }
    throw postResult.statusText;
  } catch (error) {
    throw error;
  }
}

function getConfig(
  method: Method,
  contentype: string = "application/json"
): AxiosRequestConfig {
  const tokenEmpresa = sessionStorage.getItem("tokenEmpresa");
  const sessionId = localStorage.getItem("sesionIdentificador") ?? "";
  let csrfToken = "";
  csrfToken = localStorage.getItem("token") ?? "";
  return {
    method: method,
    headers: {
      authorization: tokenEmpresa,
      client_id: config.CLIENT_ID,
      secret_key: config.SECRET_KEY,
      "x-csrf-token": csrfToken,
      "Content-Type": contentype,
      "Session-ID": sessionId,
    },
    validateStatus: validateStatusAxios
  };
}

async function getConfigAsync(
  method: Method,
  contentype: string = "application/json"
): Promise<AxiosRequestConfig> {
  const tokenEmpresa = sessionStorage.getItem("tokenEmpresa");
  const currentSesion = await Auth.currentSession();
  const token = currentSesion.getIdToken();
  const sessionId = localStorage.getItem("sesionIdentificador") ?? "";
  return {
    method: method,
    headers: {
      authorization: tokenEmpresa,
      client_id: config.CLIENT_ID,
      secret_key: config.SECRET_KEY,
      "x-csrf-token": token.getJwtToken(),
      "Content-Type": contentype,
      "Session-ID": sessionId,
    },
    validateStatus: validateStatusAxios
  };
}

async function getConfigAsyncNoSession(
  method: Method,
  contentype: string = "application/json"
): Promise<AxiosRequestConfig> {
  return {
    method: method,
    headers: {
      authorization: config.Authorization,
      client_id: config.CLIENT_ID,
      secret_key: config.SECRET_KEY,
      "Content-Type": contentype,
    },
    validateStatus: validateStatusAxios
  };
}

async function getConfigNoSession(
  method: Method,
): Promise<AxiosRequestConfig> {
  return {
    method: method,
    headers: {
      authorization: config.Authorization,
      client_id: config.CLIENT_ID,
      secret_key: config.SECRET_KEY
    },
    validateStatus: validateStatusAxios
  };
}

async function postUrlEncoded<T>(
  modulo: string,
  controller: string,
  data: any | null
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, null, null);
    const cfg = await getConfigAsync(
      "POST",
      "application/x-www-form-urlencoded"
    );
    const postResult = await axios.post(url, getBodyUrlEncoded(data), cfg);
    if (postResult.status === 200) {
      if (postResult.data.error) {
        throw postResult.data.message;
      } else {
        const apiData = postResult.data as ApiRequestEntityResult<T>;
        return apiData.auto;
      }
    } else if (postResult.status === 401) {
      cerrarSesionOnUnauthorized();
    }
    throw postResult.statusText;
  } catch (error) {
    throw error;
  }
}

async function execute<T>(url: string, config: AxiosRequestConfig) {
  const data = await axios.get<ApiRequestEntityResult<T>>(url, config);
  if (data.status === 200) {
    if (data.data.error) {
      throw data.data.message;
    } else {
      const apiData = data.data as ApiRequestEntityResult<T>;
      return apiData.auto;
    }
  } else if (data.status === 401) {
    cerrarSesionOnUnauthorized();
  }
  throw data.statusText;
}

async function postData<T>(
  modulo: string,
  controller: string,
  data: any | null
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, null, null);
    console.log("FormData", data);

    var formdata = new FormData();
    if (data.tipo !== "datos") {
      // for s3

      if (data.tipo === "uploads3") {
        if (data.type === "certificado") {
          formdata.append("password", data.password);
        }
        formdata.append("format", data.format);
        formdata.append("type", data.type);
        formdata.append("file", data.file);
        formdata.append("name", data.name);
        if (data.validateCertificate) {
          formdata.append("validateCertificate", data.validateCertificate);
        }
      } else {
        formdata.append("usuario", data.usuario);
        formdata.append("ruc", data.ruc);
        if (data.file) { formdata.append("file", data.file); }
        if (data.format) { formdata.append("format", data.format); }
        // validos para subir el logo de la empresa
        if (data.nombre) { formdata.append("nombre", data.nombre); }
        if (data.password) { formdata.append("password", data.password); }
        if (data.tipo) { formdata.append("tipo", data.tipo); }
        if (data.archivo) { formdata.append("archivo", data.archivo); }
        if (data.localCodigo) { formdata.append("localCodigo", data.localCodigo); }

      }

    } else if (data.tipo === "archivo") {
      formdata.append("ruc", data.ruc);
      formdata.append("usuario", data.usuario);
      formdata.append("archivo", data.archivo);
    } else {
      formdata.append("ruc", data.ruc);
      formdata.append("usuario", data.usuario);

    }
    const cfg = await getConfigAsync("POST", "multipart/form-data");
    const postResult = await axios.post(url, formdata, cfg);
    if (postResult.status === 200) {
      if (postResult.data.error) {
        throw postResult.data;
      } else {
        const apiData = postResult.data;
        return apiData;
      }
    } else if (postResult.status === 401) {
      cerrarSesionOnUnauthorized();
    }
    throw postResult;
  } catch (error) {
    throw error;
  }
}

async function postDataNoSession<T>(
  modulo: string,
  controller: string,
  data: any | null
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, null, null);

    var formdata = new FormData();

    if (data.tipo === "temporal") {
      formdata.append("ruc", data.ruc);
      formdata.append("file", data.file);
      if (data.format) {
        formdata.append("format", data.format);
      }
    } else {

      formdata.append("file", data.archivo);
      formdata.append("ruc", data.ruc);
      formdata.append("password", data.password);
      formdata.append("format", data.tipoArchivo);

      if (data.formato) {
        formdata.append("formato", data.formato);
      }
    }

    const cfg = await getConfigAsyncNoSession("POST", "multipart/form-data");

    const postResult = await axios.post(url, formdata, cfg);
    if (postResult.status === 200) {
      if (postResult.data.error) {
        throw postResult.data;
      } else {
        const apiData = postResult.data;
        return apiData;
      }
    } else if (postResult.status === 401) {
      cerrarSesionOnUnauthorized();
    }
    throw postResult;
  } catch (error) {
    throw error;
  }
}

async function putUrlEncodedAll<T>(
  modulo: string,
  controller: string,
  data: any | null
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, null, null);
    const cfg = await getConfigAsync(
      "PUT",
      "application/x-www-form-urlencoded"
    );
    const postResult = await axios.put(url, getBodyUrlEncoded(data), cfg);
    if (postResult.status === 401) {
      cerrarSesionOnUnauthorized();
    }
    throw postResult.data;
  } catch (error) {
    throw error;
  }
}

async function postUrlEncodedAll<T>(
  modulo: string,
  controller: string,
  data: any | null
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, null, null);
    const cfg = await getConfigAsync(
      "POST",
      "application/x-www-form-urlencoded"
    );
    const postResult = await axios.post(url, getBodyUrlEncoded(data), cfg);
    if (postResult.status === 401) {
      cerrarSesionOnUnauthorized();
    }
    throw postResult.data;
  } catch (error) {
    throw error;
  }
}

async function postUrlEncodedAllNoSession<T>(
  modulo: string,
  controller: string,
  data: any | null
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, null, null);
    const cfg = await getConfigAsyncNoSession(
      "POST",
      "application/x-www-form-urlencoded"
    );
    const postResult = await axios.post(url, getBodyUrlEncoded(data), cfg);
    if (postResult.status === 401) {
      cerrarSesionOnUnauthorized();
    }
    throw postResult.data;
  } catch (error) {
    throw error;
  }
}

async function postAll<T>(
  modulo: string,
  controller: string,
  data: any | null
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, null, null);
    const cfg = await getConfigAsync("POST");
    const postResult = await axios.post(url, data, cfg);
    if (postResult.status === 401) {
      cerrarSesionOnUnauthorized();
    }
    throw postResult.data;
  } catch (error) {
    throw error;
  }
}

async function postAllNoSession<T>(
  modulo: string,
  controller: string,
  data: any | null
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, null, null);
    const cfg = await getConfigAsyncNoSession("POST");
    const postResult = await axios.post(url, data, cfg);
    if (postResult.status === 401) {
      cerrarSesionOnUnauthorized();
    }
    throw postResult.data;
  } catch (error) {
    throw error;
  }
}

// async function postAllTEST<T>(modulo: string, controller: string, data: any | null): Promise<T> {
//   try {
//     const cfg = await getConfigAsync("POST");
//     const postResult = await axios.post(controller, data, cfg);
//     throw postResult.data;
//   } catch (error) {
//     throw error;
//   }
// }

function getBodyUrlEncoded(data) {
  const urlencoded = new URLSearchParams();
  const keys = Object.keys(data);
  for (let index = 0; index < keys.length; index++) {
    urlencoded.append(keys[index], data[keys[index]]);
  }
  return urlencoded;
}

async function deleteRequestAll<T>(
  modulo: string,
  controller: string,
  key: string,
  query: any | null | undefined
): Promise<T> {
  try {
    const url = getRequestUrl(modulo, controller, key, query);
    const config = await getConfigAsync("DELETE");
    console.log(config);
    const data = await axios.delete<ApiRequestEntityResult<T>>(url, config);
    if (data.status === 401) {
      cerrarSesionOnUnauthorized();
    }
    throw data.data;
  } catch (error) {
    throw error;
  }
}

function cerrarSesionOnUnauthorized() {
  // -- captura solo los puntos de venta
  let puntoObject: any = [];
  for (const key in puntoObject) {
    const obj = puntoObject[key];
    localStorage.setItem(obj['key'], obj['value'])
  }

  Object.keys(localStorage).reduce(function (obj, str) {
    if (str.includes("punto_venta")) {
      obj[str] = localStorage.getItem(str);
      puntoObject.push({ key: str, value: obj[str] })
    }
    return obj
  }, {});

  const st = localStorage.getItem("show_tour") ?? "";
  // await SesionService.closeSesion({ dispositivo: tipoDispositivo });

  localStorage.removeItem("token");
  // restaura los puntos de venta 

  for (const key in puntoObject) {
    const obj = puntoObject[key];
    localStorage.setItem(obj['key'], obj['value'])
  }
  localStorage.setItem("show_tour", st);
  // se agrega el setTimeout para evitar que al momento que se expulsa al usuario de la sesion, 
  // no se quede el login a pesar que el api ya responde que su cuenta fue verificada.
  setTimeout(function () {
    sessionStorage.clear();
    Auth.signOut({ global: false });
    window.location.reload();
  }, 1)
}
