import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import * as React from 'react';
import { LoaderInfo, TipoComprobante, TiposComprobantesSri, ToastTypes } from '../../../../store/types';
import CustomCol from '../../../../views/componentes/colContainer';
import Labeled from '../../../../views/componentes/labeledInput/labeledInput';
import { CustomModalDevx } from '../../../../views/componentes/modal/Modal';
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer';
import { BuscarProveedorLookUp } from '../../../proveedores/components/busquedaProveedor/BuscarProveedorLookUp';
import { ProveedorBusqueda } from '../../../proveedores/store/types';
import { SelectBuscarTiposComprobantes } from '../../../shared/components/buscarTiposComporbantes/SelectTiposComprobantes';
import {
  Validator,
  RequiredRule,
  PatternRule,
  RangeRule,
  AsyncRule,
} from 'devextreme-react/validator';
import { useDispatch, useSelector } from 'react-redux';
import { lh, MessagesKeys } from '../../../../helpers/localizationHelper';
import NumberBox from 'devextreme-react/number-box';
import { DateUtils, formatoFechasApi } from '../../../../helpers/dateUtils';
import { TiposComprobantesService } from '../../../shared/services/TiposComprobantes.service';
import { addToast } from '../../../../store/toasterReducer';
import { ProveedoresService } from '../../../proveedores/services/proveedores.service';
import LoadPanel from 'devextreme-react/load-panel';
import { XmlFacturacionElectronicaHelper } from '../../../../helpers/xmlFileHelper';
import { CompraIngresar, FormaPagoModuloCompras } from '../../types/types';
import { RootState } from '../../../../store/store';
import ValidationGroup from 'devextreme-react/validation-group';
import { CompraService } from '../../services/compra.service';
import SelectBox from '../../../../views/componentes/selectBox/selectBox';
import { VentasService } from '../../../ventas/services/ventas.service';
import { utilidades } from '../../../../helpers/utilidades';
import ValidationSummary from 'devextreme-react/validation-summary';
import { isMobileOnly } from 'react-device-detect';

interface IRegistrarCompraProps {
  onOk: (codigo: number) => void,
  onCancel: () => void,
}

export const RegistrarCompra: React.FC<IRegistrarCompraProps> = (props) => {
  const { onCancel, onOk } = props;
  const dispatch = useDispatch();
  const sesion = useSelector((state: RootState) => { return state.global.session });
  const pventa = useSelector((state: RootState) => { return state.global.puntoVenta });
  const [proveedor, setProveedor] = React.useState<ProveedorBusqueda | null>(null);
  const [tipoComprobante, setTipoComprobante] = React.useState<TipoComprobante | null>(null);
  const [fecha, setFecha] = React.useState<Date>(new Date());
  const [numero, setNumero] = React.useState<string>('');
  const [codigo, setCodigo] = React.useState<number>(0);
  const [autorizacion, setAutorizacion] = React.useState<string>('');
  const [subtotal0, setSubtotal0] = React.useState<number>(0);
  const [subtotalIva, setSubtotalIva] = React.useState<number>(0);
  const [iva, setIva] = React.useState<number>(0);
  const [formasPago, setFormasPago] = React.useState<Array<FormaPagoModuloCompras>>([]);
  const [formaPago, setFormaPago] = React.useState<FormaPagoModuloCompras>();
  const [loader, setLoader] = React.useState<LoaderInfo>({ show: false, mensaje: '' });
  const inputFile = React.useRef<any>(null);
  const [esXmlImportado, setEsXmlImportado] = React.useState<boolean>(false);
  //const [infoTributaria, setInfoTributaria] = React.useState<InfoTributariaXml | null>(null);
  const [crearProveedor, setCrearProveedor] = React.useState<boolean>(false);
  const validationGroupRef = React.useRef<any>();

  const [porcentajeIva, setPorcentajeIva] = React.useState(12);
  const [autorizacionCorrecta, setAutorizacionCorrecta] = React.useState(false);

  const validateProveedorOnClaveAccesoElectronica = React.useCallback(() => {
    const rucProveedorSegunAutorizacion = autorizacion.substr(10, 10);
    if (proveedor == null) {
      return Promise.resolve();
    }
    const rucProveedorSeleccionado = proveedor.identificacion.substr(0, 10);
    if (rucProveedorSegunAutorizacion !== rucProveedorSeleccionado) {
      return Promise.reject(`El ruc de proveedor segun la clave de acceso es ${autorizacion.substr(10, 13)}001, ud ha seleccionado un proveedor con ruc ${proveedor.identificacion}, corrija.`);
    }
    return Promise.resolve();
  }, [proveedor, autorizacion]);

  const onProveedorChanged = React.useCallback((newValue: ProveedorBusqueda | null) => {
    setProveedor(newValue);
  }, []);

  const onTipoComprobanteChanged = React.useCallback((newValue: TipoComprobante) => {
    setTipoComprobante(newValue);
  }, []);

  const onFechaChanged = React.useCallback((e) => {
    const { value } = e;
    if (value) {
      setFecha(value);
    }
  }, []);


  const onNumeroChanged = React.useCallback((e) => {
    const { value } = e;
    setNumero(value);
  }, []);



  const handleFileUpload = React.useCallback(async e => {
    const { files } = e.target;
    if (files && files.length) {
      try {
        setLoader({ show: true, mensaje: 'Recuperando Información del xml...' })
        const text = await XmlFacturacionElectronicaHelper.openFile(files[0])
        if (!text) {
          dispatch(addToast({
            content: "No se pudo recuperar la información del archivo seleccionado.",
            type: ToastTypes.Warning
          }));
          setLoader({ show: false, mensaje: '' })
          return;
        };
        const infoXml = await XmlFacturacionElectronicaHelper.proccesXml(text);
        if (infoXml === undefined) {
          dispatch(
            addToast({
              content: "El Xml no puede ser procesado, contenido incorrecto.",
              type: ToastTypes.Danger,
            })
          );
          setLoader({ show: false, mensaje: '' })
          return;
        }
        const tiposComprobantes = await TiposComprobantesService.getAll();
        const tipoComprobante = tiposComprobantes.find(x => x.codigo === infoXml.tipoComprobante);
        if (tipoComprobante === undefined) {
          dispatch(addToast({
            content: 'El xml es incorrecto, no se puede identificar el tipo de comprobante con código ' + infoXml.tipoComprobante,
            type: ToastTypes.Warning
          }));
          setAutorizacion('');
          setLoader({ show: false, mensaje: '' })
          return;
        }
        if (infoXml.tipoComprobante !== TiposComprobantesSri.Factura) {
          dispatch(addToast({
            content: 'Importar el tipo de comprobante ' + infoXml.tipoComprobante + " no está soportado.",
            type: ToastTypes.Warning
          }));
          setLoader({ show: false, mensaje: '' })
          return;
        }

        if (infoXml.comprobante === undefined) {
          dispatch(addToast({
            content: 'El xml es incorrecto, no se puede recuperar informacion del comprobante',
            type: ToastTypes.Warning
          }));
          setLoader({ show: false, mensaje: '' })
          return;
        }
        const factura = infoXml.comprobante;
        if (factura.infoFactura.identificacionComprador.substr(0, 10) !== (sesion?.empresa.ruc ?? '').substr(0, 10)) {
          dispatch(addToast({
            content: 'La factura no pertenece a la empresa actual, está emitida a ' + factura.infoFactura.razonSocialComprador,
            type: ToastTypes.Warning,
            autoHide: 5000
          }));
          setLoader({ show: false, mensaje: '' })
          return;
        }
        setTipoComprobante(tipoComprobante);
        setAutorizacion(infoXml.numeroAutorizacion);
        setFecha(DateUtils.strDateToDate(factura.infoFactura.fechaEmision, formatoFechasApi))
        setNumero(`${factura.infoTributaria.estab}-${factura.infoTributaria.ptoEmi}-${factura.infoTributaria.secuencial}`)
        const impuesto0 = factura.infoFactura.totalConImpuestos.find(x => x.valor === 0);
        const impuesto12 = factura.infoFactura.totalConImpuestos.find(x => x.valor !== 0);
        setSubtotal0(impuesto0 !== undefined ? impuesto0.baseImponible : 0);
        setSubtotalIva(impuesto12 !== undefined ? impuesto12.baseImponible : 0)
        setIva(impuesto12 !== undefined ? impuesto12.valor : 0);
        const proveedor = await ProveedoresService.getProveedor(factura.infoTributaria.ruc);
        if (proveedor) {
          setProveedor(proveedor);
        } else {
          //setInfoTributaria(factura.infoTributaria);
          setCrearProveedor(true);
        }
        setEsXmlImportado(true);
        console.log(infoXml);
      } catch (error) {
        console.log(error);
      }
    }
    setLoader({ show: false, mensaje: '' })
  }, [dispatch, sesion]);




  const onAutorizacionChanged = React.useCallback(async (e) => {
    const { value } = e;
    //const autorizacionValue = (value ?? '').trim();
    const stringValue = (value ?? '').trim() as string;
    setAutorizacion(stringValue);
    if (stringValue.length === 49) { //eléctronica
      setLoader({ show: true, mensaje: 'Recuperando Información...' })
      try {
        const strFecha = `${stringValue.substr(0, 2)}/${stringValue.substr(2, 2)}/${stringValue.substr(4, 4)}`
        const tipoComprobanteCodigo = stringValue.substr(8, 2);
        const tiposComprobantes = await TiposComprobantesService.getAll();
        const fechaAsDate = DateUtils.strDateToDate(strFecha, formatoFechasApi);
        const dateValue = fechaAsDate.toString();
        if (dateValue === 'Invalid Date') {
          const dateInvalid = 'Formato de fecha incorrecto, clave de acceso inválida';
          throw dateInvalid;
        }
        setFecha(fechaAsDate);
        const tipoComprobante = tiposComprobantes.find(x => x.codigo === tipoComprobanteCodigo);
        if (tipoComprobante !== undefined) {
          setTipoComprobante(tipoComprobante);
        } else {
          dispatch(addToast({
            content: 'La Autorización es incorrecta, no se puede identificar el tipo de comprobante con código ' + tipoComprobanteCodigo,
            type: ToastTypes.Warning
          }));
          setAutorizacion('');
          return;
        }
        const rucProveedor = stringValue.substr(10, 13);
        const proveedor = await ProveedoresService.getProveedor(rucProveedor);
        if (proveedor) {
          setProveedor(proveedor);
        }
        const establecimiento = stringValue.substr(24, 3);
        const puntoEmision = stringValue.substr(27, 3);
        const numeroComprobante = stringValue.substr(30, 9);
        setNumero(`${puntoEmision}-${establecimiento}-${numeroComprobante}`)
        setAutorizacionCorrecta(true);
      } catch (error) {
        dispatch(addToast({
          content: 'La autorización no tiene un formato correcto',
          type: ToastTypes.Warning
        }))
        setAutorizacionCorrecta(false);
      }
    } else {
      setAutorizacionCorrecta(true);
    }
    setLoader({ show: false, mensaje: '' })
  }, [dispatch]);

  const onSubtotal0Changed = React.useCallback((e) => {
    const { value } = e;
    setSubtotal0(value);
  }, []);

  const onSubtotalIvaChanged = React.useCallback((e) => {
    const { value } = e;
    setSubtotalIva(value);
    setIva(parseFloat((value * (porcentajeIva / 100.0)).toFixed(2)));
  }, [porcentajeIva]);

  const onIvaChanged = React.useCallback((e) => {
    const { value } = e;
    const valorIva = parseFloat(value.toFixed(2));
    const subtotalIva = valorIva * 100 / porcentajeIva;
    setIva(valorIva);
    setSubtotalIva(subtotalIva);
  }, [porcentajeIva]);

  const validate = React.useCallback(async () => {
    let resolved = false;
    let status = false;
    const p = new Promise(async (resolve) => {
      while (!resolved) {
        await utilidades.sleep(50);
      }
      resolve(resolved);
    });
    const validationResult = validationGroupRef.current.instance.validate();
    if (!validationResult.isValid && validationResult.status !== 'pending') {
      //setTieneErroresValidacion(true);
      return false;
    }
    if (validationResult.status === 'pending') {
      validationResult.complete.then(async resolve => {
        await resolve;
        status = resolve.isValid;
        resolved = true;
      });
      await p;
      return status;
    }
    return true;
  }, [])


  const onGuardarCompra = React.useCallback(async () => {
    const validationResult = await validate(); //validationGroupRef.current.instance.validate();
    if (!validationResult) {
      //setTieneErroresValidacion(true);
      return;
    }
    try {
      setLoader({ show: true, mensaje: 'Guardando Compra...' })
      const numeroFactura = numero.split('-');
      const fullProveedorInfo = await ProveedoresService.getProveedorByCode(proveedor?.codigo ?? 0);
      const compra: CompraIngresar = {
        usuario: sesion?.usuario.codigo ?? 0,
        ruc: sesion?.empresa.ruc ?? '',
        local: sesion?.local.codigo ?? 0,
        codigo: codigo,
        fechaEmision: DateUtils.dateToString(fecha, formatoFechasApi),
        establecimiento: numeroFactura[0],
        puntoEmision: numeroFactura[1],
        numero: numeroFactura[2],
        host: pventa ?? '',
        autorizacion: autorizacion,
        items: [],
        moneda: 'USD',
        proveedor: {
          identificacion: proveedor?.identificacion ?? '',
          nombreComercial: proveedor?.razonComercial ?? '',
          razonSocial: proveedor?.razonComercial ?? '',
          ciudad: fullProveedorInfo.ciudadNombre ?? '',
          contribuyenteEspecial: '',
          direccion: fullProveedorInfo.direccion ?? '',
          email: fullProveedorInfo.email ?? '',
          obligadoContabilidad: true,
          telefono: fullProveedorInfo.telefono ?? ''
        },
        pagos: [
          {
            medio: formaPago?.nombre ?? '',
            total: parseFloat((subtotal0 + subtotalIva + iva).toFixed(2)),
            id_medio: formaPago?.codigo.toString() ?? "",
          }
        ],
        totales: {
          descuentoAdicional: 0,
          importeTotal: parseFloat((subtotal0 + subtotalIva + iva).toFixed(2)),
          propina: 0,
          totalSinImpuestos: parseFloat((subtotal0 + subtotalIva).toFixed(2)),
          impuestos: [
            {
              codigo: 2,
              codigoPorcentaje: 0,
              descuentoAdicional: 0,
              baseImponible: subtotal0,
              valor: 0
            },
            {
              codigo: 2,
              codigoPorcentaje: 2,
              descuentoAdicional: 0,
              baseImponible: subtotalIva,
              valor: parseFloat(iva.toFixed(2))
            }
          ]
        },
        cuotas: []
      }
      const resultado = await CompraService.ingresar(compra);
      onOk(parseInt(resultado));
      dispatch(addToast({
        content: 'Compra registrada correctamente',
        type: ToastTypes.Success
      }))
    } catch (error) {
      dispatch(addToast({
        content: error,
        type: ToastTypes.Info
      }))
    }
    setLoader({ show: false, mensaje: '' })
  }, [validate, codigo, autorizacion, fecha, formaPago, iva, numero, proveedor, pventa, sesion, subtotal0, subtotalIva, onOk, dispatch]);

  const onAbrirXml = React.useCallback(() => {
    const current = inputFile.current;
    if (current != null) {
      current.click();
      //current.addEventListener('change',(e)=>console.log(e) )
      current.onchange = handleFileUpload;
    }
  }, [handleFileUpload])


  const cargarFormasPagoEIva = React.useCallback(async (fecha: string) => {
    const fp = await CompraService.getFormasPago();
    const iva = await VentasService.getIva(fecha, null);
    setPorcentajeIva(iva);
    setFormasPago(fp);

  }, []);

  const verificaAutorizacionValida = React.useCallback((value) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (autorizacionCorrecta === true) {
          resolve(autorizacionCorrecta);
        } else {
          setAutorizacion('')
          resetFormularioCompra();
          reject('Formato inválido');
          setAutorizacion(value.value)
        }
      }, 1000);
    });
  }, [autorizacionCorrecta]);

  const resetFormularioCompra = React.useCallback(() => {
    setProveedor(null);
    setTipoComprobante(null)
    setFecha(new Date());
    setNumero('');
    setSubtotal0(0);
    setSubtotalIva(0);
    setIva(0)
  }, []);

  React.useEffect(() => {
    cargarFormasPagoEIva(DateUtils.dateToString(fecha, formatoFechasApi));
  }, [fecha, cargarFormasPagoEIva]);

  return (
    <CustomModalDevx
      size='md'
      show
      title='Registrar documento de compra'
      toolbarItems={[
        {
          options: {
            text: 'Aceptar',
            stylingMode: 'contained',
            type: 'default',
            icon: 'check',
            onClick: onGuardarCompra
          },
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'after'
        },
        {
          options: {
            text: 'Cancelar',
            stylingMode: 'contained',
            type: 'danger',
            icon: 'remove',
            onClick: onCancel
          },
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'after'
        },
        {
          options: {
            text: 'Abrir Xml',
            stylingMode: 'contained',
            type: 'default',
            icon: 'upload',
            onClick: onAbrirXml
          },
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'before'
        }
      ]}
      onClose={onCancel}>
      <div key='RegistrarCompra' id='RegistrarCompra'>
        <ValidationGroup id={'validationGroupRegistrarCompra'} ref={validationGroupRef}>
          {!isMobileOnly &&
            <RowContainer>
              <CustomCol>
                <ValidationSummary />
              </CustomCol>
            </RowContainer>
          }
          <RowContainer>
            <CustomCol
              xs='12' lg='6' xxl='4'
            >
              <Labeled
                label='Autorización'
              >
                <input
                  id='inputAbrirXmlCompra'
                  style={{ display: "none" }}
                  accept=".xml"
                  ref={inputFile}
                  type="file"
                />
                <TextBox
                  value={autorizacion}
                  onValueChanged={(data) => {
                    onAutorizacionChanged(data)
                  }}
                  readOnly={esXmlImportado}
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Autorización")}  ></RequiredRule>
                    <PatternRule message={lh.getMessage(MessagesKeys.GlobalFormatoCampoIncorrecto, "Autorización")} pattern='^[0-9]{10}$|^[0-9]{49}$' />
                    <AsyncRule message={lh.getMessage(MessagesKeys.GlobalFormatoCampoIncorrecto, "Autorización")}
                      validationCallback={verificaAutorizacionValida} reevaluate />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol
              xs='12' lg='8'
            >
              <Labeled
                label='Proveedor'
              >
                <BuscarProveedorLookUp
                  selected={proveedor}
                  onChanged={onProveedorChanged}
                  disabled={esXmlImportado && !crearProveedor}
                  allowAdd allowClear allowEdit >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Proveedor")}  ></RequiredRule>
                    {autorizacion.length === 49 &&
                      <AsyncRule reevaluate validationCallback={validateProveedorOnClaveAccesoElectronica} message='El proveedor seleccionado tiene un ruc diferente al esperado.' />
                    }

                  </Validator>
                </BuscarProveedorLookUp>
              </Labeled>
            </CustomCol>
            <CustomCol
              xs='12' lg='4'
            >
              <Labeled
                label='Tipo Documento'
              >
                <SelectBuscarTiposComprobantes
                  selected={tipoComprobante}
                  onChanged={onTipoComprobanteChanged}
                  readOnly={esXmlImportado}
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Tipo Documento")}  ></RequiredRule>
                  </Validator>
                </SelectBuscarTiposComprobantes>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol
              xs='6' lg='4' xxl='2'
            >
              <Labeled
                label='Fecha'
              >
                <DateBox
                  applyValueMode='useButtons'
                  value={fecha}
                  onValueChanged={onFechaChanged}
                  readOnly={esXmlImportado}
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Fecha")}  ></RequiredRule>
                  </Validator>
                </DateBox>
              </Labeled>
            </CustomCol>
            <CustomCol
              xs='6' lg='4' xxl='2'
            >
              <Labeled
                label='Número'
              >
                <TextBox
                  name='Numero'
                  value={numero}
                  onValueChanged={onNumeroChanged}
                  useMaskedValue={true}
                  readOnly={esXmlImportado}
                  placeholder='000-000-000000000'
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Número")}  ></RequiredRule>
                    <PatternRule message={lh.getMessage(MessagesKeys.RetencionNroIncorrecto, "")} pattern='^[0-9]{3}-[0-9]{3}-[0-9]{1,10}$' />
                  </Validator>

                </TextBox>
              </Labeled>

            </CustomCol>
            <CustomCol
              xs='6' lg='4' xxl='2'
            >
              <Labeled
                label='Forma pago'
              >
                <SelectBox
                  id='formaPagoretencionVenta'
                  placeholder='Forma de Pago'
                  options={formasPago}
                  displayExpr='nombre'
                  keyExpr='codigo'
                  selected={formaPago ?? null}
                  onChange={(selected) => {
                    setFormaPago(selected);
                  }}
                  disabled={false}
                  clearButton={true}
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.RetencionFormaPagoRequerida)} />
                  </Validator>
                </SelectBox>
              </Labeled>

            </CustomCol>
          </RowContainer>
          <RowContainer>
            <CustomCol
              xs='3'
              lg='2'

            >
              <Labeled
                label='Subt. 0%'
              >
                <NumberBox
                  value={subtotal0}
                  onValueChanged={onSubtotal0Changed}
                  readOnly={esXmlImportado}
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Subt. 0%")}  ></RequiredRule>
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>
            <CustomCol
              xs='3'
              lg='2'
            >
              <Labeled
                label='Subt. IVA'
              >
                <NumberBox
                  value={subtotalIva}
                  onValueChanged={onSubtotalIvaChanged}
                  readOnly={esXmlImportado}
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Subt. IVA")}  ></RequiredRule>
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>
            <CustomCol
              xs='3'
              lg='2'
            >
              <Labeled
                label='Subt.'
              >
                <NumberBox
                  value={parseFloat((subtotal0 + subtotalIva).toFixed(2))}
                  readOnly
                />
              </Labeled>
            </CustomCol>
            <CustomCol
              xs='3'
              lg='2'
            >
              <Labeled
                label='IVA'
              >
                <NumberBox
                  value={iva}
                  onValueChanged={onIvaChanged}
                  readOnly={esXmlImportado}
                >
                  <Validator>
                    <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "IVA")}  ></RequiredRule>
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>
            <CustomCol
              xs='3'
              lg='2'
            >
              <Labeled
                label='Total'
              >
                <NumberBox
                  value={parseFloat((subtotal0 + subtotalIva + iva).toFixed(2))}
                  readOnly
                >
                  <Validator>
                    <RangeRule min={0.01} message='El total de la factura debe de ser mayor que 0'  ></RangeRule>
                  </Validator>
                </NumberBox>
              </Labeled>
            </CustomCol>
          </RowContainer>
          {isMobileOnly &&
            <RowContainer>
              <CustomCol>
                <ValidationSummary />
              </CustomCol>
            </RowContainer>
          }

        </ValidationGroup>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={'center'}
          visible={loader.show}
          showIndicator={true}
          shading={true}
          showPane={true}
          message={loader.mensaje}
          closeOnOutsideClick={false}
        />


      </div>
    </CustomModalDevx>
  );
};
