import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoaderInfo } from '../../../../../../store/types'
import { statusForms } from '../../../movimientosBancos/store/types'
import {
  IngresosCaja,
  Ingresos,
  DetalleIngreso,
  formularioBusqueda,
  Seleccionado, DatosFormularioIngresosCaja
} from './types'

const initialState: IngresosCaja = {
  modulo: 'ingresosCaja',
  registros: [],
  detalleIngreso: [],
  dpTipoTransaccion: [],
  loader: {
    show: false,
    mensaje: ''
  },
  formularioBusqueda: {
    interno: 0,
    numero: "",
    recibido: "",
    concepto: "",
    fechaDesde: "",
    fechaHasta: "",
  },
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  currentFormulario: null,
  currentAction: 'Buscar',
  currentFunction: "",
  formularioEdicion: {
    estadoFormulario: statusForms.pending,
    diarioNumero: 0,
    fechaIngreso: "",
    numeroDocumento: 0,
    numeroAsiento: 0,
    numeroTipo: 0,
    tipoDocumento: "",
    totalDebe: 0,
    totalHaber: 0,
    local: null,
    proyecto: null,
    codigoCompra: 0,
    totalCompra: 0,
    codigoRetencion: 0,
    totalRetencion: 0,
    codigoIngreso: 0,
    descripcion: "",
    lote: 0,
    asociadoNombre: "",
    puntoVentaDescripcion: "",
    puntoVentaCodigo: 0,
    asientoNumeroTipo: 0,
    detalleAsiento: []
  }
}

const ingresosCajaSlice = createSlice({
  name: 'ingresosCaja',
  initialState: initialState,
  reducers: {
    setData(state, action: PayloadAction<Array<Ingresos>>) {
      state.registros = action.payload
    },
    setDetalleIngreso(state, action: PayloadAction<Array<DetalleIngreso>>) {
      state.detalleIngreso = action.payload
    },
    setDpTipoTransaccion(state, action: PayloadAction<Array<any>>) {
      state.dpTipoTransaccion = action.payload
    },
    changeLoaderIngresosCaja(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      state.formularioBusqueda = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      state.seleccionado = action.payload;
    },
    setResetSeleccionIngresoCaja(state) {
      let toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setCurrentFormulario(state, action: PayloadAction<any>) {
      state.currentFormulario = action.payload
    },
    setCurrentAccionIngresos(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setCurrentFunctionIngresos(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setFormularioEdicionIngresos(state, action: PayloadAction<DatosFormularioIngresosCaja>) {
      state.formularioEdicion = action.payload
    },
  }
})
export const {
  setData, setDetalleIngreso, setDpTipoTransaccion, changeLoaderIngresosCaja, setFormularioBusqueda,
  setSeleccionarDato, setResetSeleccionIngresoCaja, setCurrentFormulario, setCurrentAccionIngresos,
  setCurrentFunctionIngresos, setFormularioEdicionIngresos
} = ingresosCajaSlice.actions
export const IngresosCajaReducer = ingresosCajaSlice.reducer
