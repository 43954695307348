import React from 'react';
import ko from 'knockout';
import 'devexpress-reporting/dx-webdocumentviewer';
import { UrlHelper } from '../../../helpers/urlHelper';
import { ajaxSetup } from '@devexpress/analytics-core/analytics-utils'
import { RequestHelperDotNet } from '../../../helpers/requestHelperDotNet';
import { isMobile } from 'react-device-detect';
import esCore from './localization/dx-analytics-core.es.json'
import esRep from './localization/dx-reporting.es.json'

export class ReportViewer extends React.Component {
  constructor(props) {
    super(props);
    //const sesion = SesionService.getCurrentSesion();
    const urls = UrlHelper.getUrls();
    const reportUrl = props.reportName; //`${sesion.empresa.ruc}-${sesion.usuario.codigo}-${props.reportName}`;
    this.reportUrl = ko.observable(reportUrl);
    this.requestOptions = {
      host: urls.reportServerBasePath,
      // Use this line for the ASP.NET MVC backend.                    
      //invokeAction: "/WebDocumentViewer/Invoke"
      // Use this line for the ASP.NET Core backend
      invokeAction: "DXXRDV"
    };
    const headers = RequestHelperDotNet.getConfig('get').headers;
    headers.dataId = props.dataId;
    delete headers['Content-Type'];
    ajaxSetup.ajaxSettings = {
      headers: headers,
    }
    this.callbacks = {
      CustomizeLocalization: function (s, e) {
        e.LoadMessages(esCore);
        e.LoadMessages(esRep);
        
      }
    };
    // ajaxSetup.ajaxSettings ={
    //   headers: {
    //     'Authorization': `Bearer ${localStorage.getItem('token')}`,
    //     'ReportData': JSON.stringify(props.reportData ?? {})
    //   }
    // }
  }
  render() {
    return (<div className={isMobile ? "fullScreen" : undefined} style={{ height: `${window.innerHeight - (window.innerHeight * 0.20)}px` }} ref="viewer" data-bind="dxReportViewer: $data"></div>);
  }
  componentDidMount() {
    ko.applyBindings({
      reportUrl: this.reportUrl,
      requestOptions: this.requestOptions,
      callbacks: this.callbacks
    }, this.refs.viewer);
  }
  componentWillUnmount() {
    ko.cleanNode(this.refs.viewer);
  }
};