import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo } from "../../../../../../store/types";
import { BalanceComprobacionState, Registros, Seleccionado, formularioBusqueda } from "./types";

const initialState: BalanceComprobacionState = {
    modulo: 'contabilidad',
    currentAction: "Buscar",
    currentFunction: "",
    aplicacion: 'balanceComprobacion',
    acciones: [],
    registros: [],
    registrosBackup: [],
    refrescaTabla: false,
    seleccionado: {
        index: null,
        row: null,
        selectedRow: null,
    },
    formularioBusqueda: {
        ASI_DIARIONRO: -1,
        INICIA_PERIODO: new Date().toDateString(),
        ASI_FECHA_D: new Date().toDateString(),
        ASI_FECHA_H: new Date().toDateString(),
        COD_PROYECTO: -1,
        COD_LOCALCC: -1,
        COD_TIPO_CTA: -1,
        CUENTA_COD: "",
        CUENTAS_ALL: false,
        IDIOMA_ALTERNO: false,
        VALOR: "",
        MAX_NIVEL: 1,
    },
    loader: {
        show: false,
        mensaje: ''
    },
}

const balanceComprobacionSlice = createSlice({
    name: "balanceComprobacion",
    initialState: initialState,
    reducers: {
        obtenerAcciones(state, action: PayloadAction<any>) {
            state.acciones = action.payload
        },
        setData(state, action: PayloadAction<Array<Registros>>) {
            state.registros = action.payload
        },
        setDataBackup(state, action: PayloadAction<Array<Registros>>) {
            state.registrosBackup = action.payload
        },
        setCurrentAccion(state, action: PayloadAction<any>) {
            state.currentAction = action.payload
        },
        setCurrentFunction(state, action: PayloadAction<any>) {
            state.currentFunction = action.payload
        },
        setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
            let toAdd = {
                index: action.payload.index,
                row: action.payload.row,
                selectedRow: action.payload.selectedRow,
            }
            state.seleccionado = toAdd
        },
        setResetSeleccion(state) {
            let toAdd = {
                index: null,
                row: null,
                selectedRow: null,
            }
            state.seleccionado = toAdd
        },
        setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
            let toAdd = {
                ASI_DIARIONRO: action.payload.ASI_DIARIONRO,
                INICIA_PERIODO: action.payload.INICIA_PERIODO,
                ASI_FECHA_D: action.payload.ASI_FECHA_D,
                ASI_FECHA_H: action.payload.ASI_FECHA_H,
                COD_PROYECTO: action.payload.COD_PROYECTO,
                COD_LOCALCC: action.payload.COD_LOCALCC,
                COD_TIPO_CTA: action.payload.COD_TIPO_CTA,
                CUENTA_COD: action.payload.CUENTA_COD,
                CUENTAS_ALL: action.payload.CUENTAS_ALL,
                IDIOMA_ALTERNO: action.payload.IDIOMA_ALTERNO,
                VALOR: action.payload.VALOR,
                MAX_NIVEL: action.payload.MAX_NIVEL,
            }
            state.formularioBusqueda = toAdd
        },
        changeLoaderBalance(state, action: PayloadAction<LoaderInfo>) {
            state.loader = action.payload
        },
    }
})

export const { changeLoaderBalance, setCurrentFunction, obtenerAcciones, setData, setDataBackup, setCurrentAccion, setSeleccionarDato, setResetSeleccion, setFormularioBusqueda } = balanceComprobacionSlice.actions;
export const balanceComprobacionReducer = balanceComprobacionSlice.reducer;