import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptionGrupos } from "../../../../../store/types";
import { OptionProyectos } from "../../../../bancos/general/types";
import { PageState, Registros, Seleccionado, OptionGruposDetalle, TablaCuentas, OptionGruposGenericoAsignaciones, OptionGruposGenericoAsignados } from "./types";

const initialState: PageState = {
  modulo: 'contabilidad',
  currentAction: "Buscar",
  currentFunction: "",
  aplicacion: 'asignaciones',
  acciones: [],
  registros: [],
  refrescaTabla: false,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  periodosContables: [],
  tipoAsiento: [],
  proyectos: [],
  proyectosBack: [],
  locales: [],
  puntosVenta: [],
  localesBack: [],
  tablaCuentas: {
    cuentas: [],
    seleccion: {
      index: null,
      row: null,
      selectedRow: null
    }
  },
  tabs: {
    ventas: null,
    compras: null,
  },
  loader: {
    show: false,
    message: ""
  },
  currentTab: "Ventas",
  currentSubTab: "",
  copiarCuenta: null,
  combosIva: [],
  combosIce: [],
  conceptosRetencionFuente: [],
  asignacionesRetencionFuente: [],
  conceptosRetencionFuenteVenta: [],
  asignacionesRetencionFuenteVenta: [],
  conceptosRetencionIva: [],
  conceptosRetencionIvaVenta: [],
  asignacionesRetencionIva: [],
  asignacionesRetencionIvaVenta: [],
  asignacionesCC: [],
  centrosCostos: [],
  asignacionesPuntoVentaItems: [],
  asignacionesPuntoVentaItemsVentas: {
    "ASG_CODIGO": "",
    "ASG_ITEM": "",
    "ASG_TIPO": "",
    "PLA_CODIGO": "",
    "PLA_NUMERO": "",
    "PLA_DESCRIPCION": "",
    "ASG_TRANSACCION": "VENTA",
  },
  asignacionesPuntoVentaItemsCostoVentas: {
    "ASG_CODIGO": "",
    "ASG_ITEM": "",
    "ASG_TIPO": "",
    "PLA_CODIGO": "",
    "PLA_NUMERO": "",
    "PLA_DESCRIPCION": "",
    "ASG_TRANSACCION": "VENTA",
  },
  asignacionesLocalesccCuentasCompra: {
    "ASG_CODIGO": "",
    "ASG_ITEM": "",
    "ASG_TIPO": "",
    "PLA_CODIGO": "",
    "PLA_NUMERO": "",
    "PLA_DESCRIPCION": "",
    "ASG_TRANSACCION": "COMPRA",
  },
  asignacionesLocalesccCuentaInventario: {
    "ASG_CODIGO": "",
    "ASG_ITEM": "",
    "ASG_TIPO": "",
    "PLA_CODIGO": "",
    "PLA_NUMERO": "",
    "PLA_DESCRIPCION": "",
    "ASG_TRANSACCION": "COMPRA",
  },
  asignacionesLocalesCC: [],
  asignacionesBancos: [],
  cuentasBancos: [],
  asignacionesImportaciones: [],
  tiposDepreciacion: [],
  asignacionesDepreciacion: [],
  asignacionesDepreciacionAcumulada: [],
  asignacionesNomina: [],
  cuentasAsignacionesNomina: [],
  asignacionesUtilidad: [],
  cuentasAsignadasUtilidad: [],
  asignacionesIndicadores: [],
  cuentasAsignacionesIndicadores: [],
  tiposDescuentosNomina: [],
  asignacionesDescuentosNomina: [],
  cuentasAsignadasDescuentosNomina: [],
  cuentasAsignadasDescuentosNominaGeneral: [],
  currentTabId: "",
  asignacionDpDevolucionesSinIva: [],
  asignacionDpDevolucionesIva: [],
  asignacionDpDescuentosSinIva: [],
  asignacionDpDescuentosConIva: [],
  asignacionDpTransporteSinIva: [],
  asignacionDpTransporteConIva: [],
  asignacionarCuentasVentasCompras: null,
  asignacionesCuentasCompras: [],
  asignacionesCuentasVentas: [],
  asignacionDpDevolucionesComprasSinIva: [],
  asignacionDpDevolucionesComprasConIva: [],
  asignacionDpDescuentosComprasSinIva: [],
  asignacionDpDescuentosComprasConIva: [],
  asignacionDpTransporteComprasConIva: [],
  asignacionDpTransporteComprasSinIva: [],
  formasDePago: [],
  puntosDeVenta: [],
  asignacionesFormasPagoCompras: [],
  formasDePagoCompra: [],
  asignacionesFormasPagoVentas: [],
  formasDePagoVenta: [],
  lugarBack: null,
  tarjetasFormaPagoVenta: [],
  comisionTarjetasFormaPagoVenta: [],
  asignacionIvaEnCompras: [],
  asignacionIvaEnVentas: [],
  asignacionIceEnVentas: [],
  asignacionIrbpnrEnVentas: [],
  asignacionServicioEnVentas: [],
  asignarCuentasIceIvaServices: null,
  asignacionesCuentasIceIvaServices: [],

}


const asignacionesSlice = createSlice({
  name: "asignaciones",
  initialState: initialState,
  reducers: {
    obtenerAcciones(state, action: PayloadAction<any>) {
      state.acciones = action.payload
    },
    setData(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },
    setCurrentAccion(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<any>) {
      state.currentFunction = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      let toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      let toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setPeriodosContables(state, action: PayloadAction<Array<OptionGruposDetalle>>) {
      state.periodosContables = action.payload
    },
    setTipoCuenta(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.tipoAsiento = action.payload
    },
    setProyectos(state, action: PayloadAction<Array<OptionProyectos>>) {
      state.proyectos = action.payload
    },
    setProyectosBack(state, action: PayloadAction<Array<OptionProyectos>>) {
      state.proyectosBack = action.payload
    },
    setLocales(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.locales = action.payload
    },
    setPuntosVenta(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.puntosVenta = action.payload
    },
    setLocalesBack(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.localesBack = action.payload
    },
    setTablaCuentas(state, action: PayloadAction<TablaCuentas>) {
      let toAdd = {
        cuentas: action.payload.cuentas,
        seleccion: action.payload.seleccion,
      }
      state.tablaCuentas = toAdd
    },
    changeLoaderAsignaciones(state, action: PayloadAction<any>) {
      let toAdd = {
        show: action.payload.show,
        message: action.payload.message,
      }
      state.loader = toAdd
    },
    setTab(state, action: PayloadAction<any>) {
      state.tabs = action.payload
    },
    setCurrentTab(state, action: PayloadAction<any>) {
      state.currentTab = action.payload
    },
    setCurrentSubTab(state, action: PayloadAction<any>) {
      state.currentSubTab = action.payload
    },
    setUsarCuenta(state, action: PayloadAction<any>) {
      state.copiarCuenta = action.payload
    },
    setUsarCuentaReset(state) {
      state.copiarCuenta = null
    },
    setCombosIva(state, action: PayloadAction<any>) {
      state.combosIva = action.payload
    },
    setCombosIce(state, action: PayloadAction<any>) {
      state.combosIce = action.payload
    },
    setConceptosRetencionFuente(state, action: PayloadAction<any>) {
      state.conceptosRetencionFuente = action.payload
    },
    setConceptosRetencionFuenteVenta(state, action: PayloadAction<any>) {
      state.conceptosRetencionFuenteVenta = action.payload
    },
    setAsignacionesRetencionFuente(state, action: PayloadAction<any>) {
      state.asignacionesRetencionFuente = action.payload
    },
    setAsignacionesRetencionFuenteVenta(state, action: PayloadAction<any>) {
      state.asignacionesRetencionFuenteVenta = action.payload
    },
    setConceptosRetencionIva(state, action: PayloadAction<any>) {
      state.conceptosRetencionIva = action.payload
    },
    setConceptosRetencionIvaVenta(state, action: PayloadAction<any>) {
      state.conceptosRetencionIvaVenta = action.payload
    },
    setAsignacionesRetencionIva(state, action: PayloadAction<any>) {
      state.asignacionesRetencionIva = action.payload
    },
    setAsignacionesRetencionIvaVenta(state, action: PayloadAction<any>) {
      state.asignacionesRetencionIvaVenta = action.payload
    },
    setAsignacionesCC(state, action: PayloadAction<any>) {
      state.asignacionesCC = action.payload
    },
    setCentrosCostos(state, action: PayloadAction<any>) {
      state.centrosCostos = action.payload
    },
    setAsignacionesPuntoVentaItems(state, action: PayloadAction<any>) {
      state.asignacionesPuntoVentaItems = action.payload
    },
    setAsignacionesPuntoVentaItemsVentas(state, action: PayloadAction<any>) {
      state.asignacionesPuntoVentaItemsVentas = action.payload
    },
    setAsignacionesPuntoVentaItemsCostoVentas(state, action: PayloadAction<any>) {
      state.asignacionesPuntoVentaItemsCostoVentas = action.payload
    },
    setAsignacionesLocalesccCuentasCompra(state, action: PayloadAction<any>) {
      state.asignacionesLocalesccCuentasCompra = action.payload
    },
    setAsignacionesLocalesccCuentaInventario(state, action: PayloadAction<any>) {
      state.asignacionesLocalesccCuentaInventario = action.payload
    },
    setAsignacionesLocalesCC(state, action: PayloadAction<any>) {
      state.asignacionesLocalesCC = action.payload
    },
    setAsignacionesBancos(state, action: PayloadAction<any>) {
      state.asignacionesBancos = action.payload
    },
    setCuentasBancos(state, action: PayloadAction<any>) {
      state.cuentasBancos = action.payload
    },
    setAsignacionesImportaciones(state, action: PayloadAction<any>) {
      state.asignacionesImportaciones = action.payload
    },
    setTiposDepreciacion(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.tiposDepreciacion = action.payload
    },
    setAsignacionesDepreciacion(state, action: PayloadAction<any>) {
      state.asignacionesDepreciacion = action.payload
    },
    setAsignacionesDepreciacionAcumulada(state, action: PayloadAction<any>) {
      state.asignacionesDepreciacionAcumulada = action.payload
    },
    setAsignacionesNomina(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.asignacionesNomina = action.payload
    },
    setCuentasAsignacionesNomina(state, action: PayloadAction<Array<any>>) {
      state.cuentasAsignacionesNomina = action.payload
    },
    setAsignacionesUtilidad(state, action: PayloadAction<any>) {
      state.asignacionesUtilidad = action.payload
    },
    setCuentasAsignadasUtilidad(state, action: PayloadAction<any>) {
      state.cuentasAsignadasUtilidad = action.payload
    },
    setAsignacionesIndicadores(state, action: PayloadAction<Array<any>>) {
      state.asignacionesIndicadores = action.payload
    },
    setCuentasAsignacionesIndicadores(state, action: PayloadAction<Array<any>>) {
      state.cuentasAsignacionesIndicadores = action.payload
    },
    setTiposDescuentosNomina(state, action: PayloadAction<Array<any>>) {
      state.tiposDescuentosNomina = action.payload
    },
    setAsignacionesDescuentosNomina(state, action: PayloadAction<Array<any>>) {
      state.asignacionesDescuentosNomina = action.payload
    },
    setCuentasAsignadasDescuentosNomina(state, action: PayloadAction<Array<any>>) {
      state.cuentasAsignadasDescuentosNomina = action.payload
    },
    setCuentasAsignadasDescuentosNominaGeneral(state, action: PayloadAction<Array<any>>) {
      state.cuentasAsignadasDescuentosNominaGeneral = action.payload
    },
    setCurrentTabId(state, action: PayloadAction<string>) {
      state.currentTabId = action.payload
    },
    setAsignacionDpDevolucionesSinIva(state, action: PayloadAction<Array<OptionGruposGenericoAsignados>>) {
      state.asignacionDpDevolucionesSinIva = action.payload
    },
    setAsignacionDpDevolucionesIva(state, action: PayloadAction<Array<OptionGruposGenericoAsignados>>) {
      state.asignacionDpDevolucionesIva = action.payload
    },
    setAsignacionDpDescuentosSinIva(state, action: PayloadAction<Array<OptionGruposGenericoAsignados>>) {
      state.asignacionDpDescuentosSinIva = action.payload
    },
    setAsignacionDpDescuentosConIva(state, action: PayloadAction<Array<OptionGruposGenericoAsignados>>) {
      state.asignacionDpDescuentosConIva = action.payload
    },
    setAsignacionDpTransporteConIva(state, action: PayloadAction<Array<OptionGruposGenericoAsignados>>) {
      state.asignacionDpTransporteConIva = action.payload
    },
    setAsignacionDpTransporteSinIva(state, action: PayloadAction<Array<OptionGruposGenericoAsignados>>) {
      state.asignacionDpTransporteSinIva = action.payload
    },
    setAsignacionarCuentasVentasCompras(state, action: PayloadAction<any>) {
      state.asignacionarCuentasVentasCompras = action.payload
    },
    setAsignacionesCuentasVentas(state, action: PayloadAction<any>) {
      state.asignacionesCuentasVentas = action.payload
    },
    setAsignacionesCuentasCompras(state, action: PayloadAction<any>) {
      state.asignacionesCuentasCompras = action.payload
    },
    setAsignacionDpDevolucionesComprasSinIva(state, action: PayloadAction<any>) {
      state.asignacionDpDevolucionesComprasSinIva = action.payload
    },
    setAsignacionDpDevolucionesComprasConIva(state, action: PayloadAction<any>) {
      state.asignacionDpDevolucionesComprasConIva = action.payload
    },
    setAsignacionDpDescuentosComprasSinIva(state, action: PayloadAction<any>) {
      state.asignacionDpDescuentosComprasSinIva = action.payload
    },
    setAsignacionDpDescuentosComprasConIva(state, action: PayloadAction<any>) {
      state.asignacionDpDescuentosComprasConIva = action.payload
    },
    setAsignacionDpTransporteComprasConIva(state, action: PayloadAction<any>) {
      state.asignacionDpTransporteComprasConIva = action.payload
    },
    setAsignacionDpTransporteComprasSinIva(state, action: PayloadAction<any>) {
      state.asignacionDpTransporteComprasSinIva = action.payload
    },
    // FORMAS PAGO
    setFormasDePago(state, action: PayloadAction<Array<any>>) {
      state.formasDePago = action.payload
    },
    setPuntosDeVenta(state, action: PayloadAction<Array<any>>) {
      state.puntosDeVenta = action.payload
    },
    // FORMAS PAGO / COMPRAS
    setAsignacionesFormasPagoCompras(state, action: PayloadAction<Array<any>>) {
      state.asignacionesFormasPagoCompras = action.payload
    },
    setFormasDePagoCompra(state, action: PayloadAction<Array<any>>) {
      state.formasDePagoCompra = action.payload
    },
    // FORMAS PAGO / VENTAS
    setAsignacionesFormasPagoVentas(state, action: PayloadAction<Array<any>>) {
      state.asignacionesFormasPagoVentas = action.payload
    },
    setLugarBack(state, action: PayloadAction<any>) {
      state.lugarBack = action.payload
    },
    setFormasDePagoVenta(state, action: PayloadAction<Array<any>>) {
      state.formasDePagoVenta = action.payload
    },
    setTarjetasFormaPagoVenta(state, action: PayloadAction<Array<any>>) {
      state.tarjetasFormaPagoVenta = action.payload
    },
    setComisionTarjetasFormaPagoVenta(state, action: PayloadAction<Array<any>>) {
      state.comisionTarjetasFormaPagoVenta = action.payload
    },
    //  IVA - ICE - SERVICIOS
    setAsignacionIvaEnCompras(state, action: PayloadAction<Array<any>>) {
      state.asignacionIvaEnCompras = action.payload
    },
    setAsignacionIvaEnVentas(state, action: PayloadAction<Array<any>>) {
      state.asignacionIvaEnVentas = action.payload
    },
    setAsignacionIceEnVentas(state, action: PayloadAction<Array<any>>) {
      state.asignacionIceEnVentas = action.payload
    },
    setAsignacionIrbpnrEnVentas(state, action: PayloadAction<Array<any>>) {
      state.asignacionIrbpnrEnVentas = action.payload
    },
    setAsignacionServicioEnVentas(state, action: PayloadAction<Array<any>>) {
      state.asignacionServicioEnVentas = action.payload
    },
    setAsignarCuentasIceIvaServices(state, action: PayloadAction<any>) {
      state.asignarCuentasIceIvaServices = action.payload
    },
    setAsignacionesCuentasIceIvaServices(state, action: PayloadAction<Array<any>>) {
      state.asignacionesCuentasIceIvaServices = action.payload
    },
  }
});

export const {
  setAsignacionesCuentasIceIvaServices,
  setAsignarCuentasIceIvaServices,
  setAsignacionIvaEnCompras,
  setAsignacionIvaEnVentas,
  setAsignacionIceEnVentas,
  setAsignacionIrbpnrEnVentas,
  setAsignacionServicioEnVentas,
  setLugarBack,
  setComisionTarjetasFormaPagoVenta,
  setTarjetasFormaPagoVenta,
  setAsignacionesFormasPagoVentas,
  setFormasDePagoCompra,
  setFormasDePagoVenta,
  setPuntosDeVenta,
  setAsignacionesFormasPagoCompras,
  setFormasDePago,
  changeLoaderAsignaciones,
  setAsignacionDpTransporteComprasSinIva,
  setAsignacionDpTransporteComprasConIva,
  setAsignacionDpDescuentosComprasConIva,
  setAsignacionDpDescuentosComprasSinIva,
  setAsignacionDpDevolucionesComprasConIva,
  setAsignacionDpDevolucionesComprasSinIva,
  setAsignacionarCuentasVentasCompras,
  setAsignacionesCuentasVentas,
  setAsignacionesCuentasCompras,
  setAsignacionDpTransporteConIva,
  setAsignacionDpTransporteSinIva,
  setAsignacionDpDescuentosConIva,
  setAsignacionDpDescuentosSinIva,
  setAsignacionDpDevolucionesIva,
  setAsignacionDpDevolucionesSinIva,
  setCuentasAsignadasDescuentosNominaGeneral,
  setCuentasAsignadasDescuentosNomina,
  setAsignacionesDescuentosNomina,
  setTiposDescuentosNomina,
  setCuentasAsignacionesIndicadores,
  setAsignacionesIndicadores,
  setCuentasAsignadasUtilidad,
  setAsignacionesUtilidad,
  setCuentasAsignacionesNomina,
  setAsignacionesNomina,
  setAsignacionesDepreciacionAcumulada,
  setAsignacionesDepreciacion,
  setTiposDepreciacion,
  setAsignacionesImportaciones,
  setCuentasBancos,
  setAsignacionesBancos,
  setAsignacionesLocalesCC,
  setAsignacionesLocalesccCuentaInventario,
  setAsignacionesLocalesccCuentasCompra,
  setAsignacionesPuntoVentaItemsVentas,
  setAsignacionesPuntoVentaItemsCostoVentas,
  setAsignacionesPuntoVentaItems,
  setCentrosCostos,
  setAsignacionesCC,
  setConceptosRetencionIvaVenta,
  setAsignacionesRetencionIvaVenta,
  setAsignacionesRetencionFuenteVenta,
  setConceptosRetencionFuenteVenta,
  setAsignacionesRetencionFuente,
  setAsignacionesRetencionIva,
  setConceptosRetencionFuente,
  setConceptosRetencionIva,
  setCombosIva,
  setCombosIce,
  setUsarCuentaReset,
  setUsarCuenta,
  setCurrentSubTab,
  setPuntosVenta,
  setCurrentTab,
  setTab,
  setTablaCuentas,
  obtenerAcciones,
  setData,
  setCurrentAccion,
  setCurrentFunction,
  setSeleccionarDato,
  setResetSeleccion,
  setPeriodosContables,
  setTipoCuenta,
  setProyectos,
  setProyectosBack,
  setLocales,
  setLocalesBack,
  setCurrentTabId } = asignacionesSlice.actions;
export const asignacionesReducer = asignacionesSlice.reducer;