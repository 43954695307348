import { combineReducers } from "@reduxjs/toolkit";
import { ModalVentasState } from "../types/modalVentasTypes";
import { configuracionesModalVentasReducer } from "./configuracionesModalVentaReducer";
import { datosModalEdicionReducer } from "./editDataReducer";
import { menuModalVentasReducer } from "./menuModalVentas";
import { tabsReducer } from "./tabsReducer";
import { searchVentasReducer } from "./searchReducer";

export const modalVentasReducer = combineReducers<ModalVentasState>({
  menu: menuModalVentasReducer,
  tabs: tabsReducer,
  search: searchVentasReducer,
  editData: datosModalEdicionReducer,
  configuraciones: configuracionesModalVentasReducer
});

