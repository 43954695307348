import { CContainer } from '@coreui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addToast } from '../../../../store/toasterReducer'
import { TiposComprobantesSri, ToastTypes } from '../../../../store/types'
import CustomCol from '../../../../views/componentes/colContainer'
import Labeled from '../../../../views/componentes/labeledInput/labeledInput'
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer'
import { VentasService } from '../../services/ventas.service'
import { Cliente, InfoDocumentoVentaAfectar } from '../../types/types'
import { CustomModalDevx } from '../../../../views/componentes/modal/Modal'
import BuscarClienteLookUp from '../busquedaCliente/busquedaCliente'
import LoadPanel from 'devextreme-react/load-panel'
import DataGrid, { Column, SearchPanel, HeaderFilter, Scrolling, LoadPanel as GridLoadPanel, Button as DatagridButton } from 'devextreme-react/data-grid';
import TextBox from 'devextreme-react/text-box'
import { ValidationGroup } from 'devextreme-react/validation-group';
import {
  Validator,
  PatternRule
} from 'devextreme-react/validator';
import ScrollView from 'devextreme-react/scroll-view';
import { useModalConvertSize } from '../../../../hooks/useModalSize'
import { isMobileOnly } from 'react-device-detect'


interface Props {
  tipo: TiposComprobantesSri,
  onOk: (documento: InfoDocumentoVentaAfectar) => void,
  onCancel: () => void
}

const DocumentoVentaAfectar = (props: Props) => {
  const { tipo, onOk, onCancel } = props;
  const dispatch = useDispatch();
  const [cliente, setCliente] = React.useState<Cliente | null>(null);
  const [comprobante, setComprobante] = useState('');
  const [facturas, setFacturas] = useState<Array<InfoDocumentoVentaAfectar>>([])
  const [buscando, setBuscando] = useState(false);
  const [modalSize] = useModalConvertSize("md");
  const [loadPanelEnabled, setloadPanelEnabled] = useState(true);
  const validationGroupRef = React.useRef<any>();

  const onRowDoubleClick = React.useCallback((e) => {
    const { data } = e;
    const documentoAfectar = data as InfoDocumentoVentaAfectar;
    onOk(documentoAfectar);
    //props.onDocumentSelected(data);
    //console.log(data);
  }, [onOk]);

  const buscarFacturas = useCallback(async () => {
    try {
      let validationResult = await validationGroupRef.current?.instance.validate();
      //console.log('validationResult',validationResult)
      if (validationResult === undefined || validationResult?.isValid) {
        setBuscando(true);
        const _facturas = await VentasService.buscarDocumentosAfectar(cliente ? cliente.identificacion : '', '', comprobante ?? '', tipo);
        setFacturas(_facturas);
      }
    } catch (error) {
      dispatch(addToast({
        title: 'Buscar documentos',
        content: (typeof error == 'string' ? error : JSON.stringify(error)),
        type: ToastTypes.Warning,
      }

      ))
    }
    setBuscando(false);
  }, [dispatch, tipo, cliente, comprobante])


  useEffect(() => {
    buscarFacturas();
  }, [buscarFacturas])

  return (
    <CustomModalDevx
      title='Listado de Comprobantes'
      onClose={onCancel}
      show
      size={modalSize}
      height={window.innerHeight * 0.8}
    >
      <ValidationGroup id={`valGroupDocumentoVentaAfectar`} ref={validationGroupRef}>
        <CContainer
          fluid
        >
          <RowContainer>
            <CustomCol xs='12' md='8'>
              <Labeled
                label='Cliente'
              >
                <BuscarClienteLookUp allowClear selected={cliente} onChanged={(value) => setCliente(value)} />
              </Labeled>
            </CustomCol>
            <CustomCol
              xs='12'
              md='4'
            >
              <Labeled
                label='Comprobante'
              >
                <TextBox
                  value={comprobante}
                  onValueChanged={({ value }) => setComprobante(value)}
                >
                  <Validator>
                    <PatternRule pattern='^[0-9]{1,10}$' message='El campo comprobante es numerico.' />
                  </Validator>
                </TextBox>
              </Labeled>
            </CustomCol>
          </RowContainer>
          <ScrollView
            direction="horizontal"
            id="scrollTabVentas"
            scrollByContent={true}
          >
            <DataGrid
              focusedRowEnabled={true}
              keyExpr='codigo'
              hoverStateEnabled={true}
              dataSource={facturas}
              showBorders={true}
              onRowDblClick={onRowDoubleClick}
              height={isMobileOnly ? `${window.innerHeight * 0.7}px` : `${(window.innerHeight * 0.7).toFixed(0)}px`}
              width='100%'
              onContentReady={() => setloadPanelEnabled(false)}
            >
              <SearchPanel visible={true} highlightCaseSensitive={true} />
              <HeaderFilter visible={true} />
              < Column type='buttons' width='40px' >
                <DatagridButton text='+' icon='plus' hint='Selecionar' onClick={async (e) => {
                  const data: InfoDocumentoVentaAfectar = e.row.data;
                  onOk(data);
                }} />
              </Column>
              <Column dataField='fecha' dataType='date' width='80px' allowSearch={false} />
              <Column dataField='tipoComprobanteNombre' caption='Tipo Comprobante' width='100px' allowSearch={false} />
              <Column dataField='numero' caption='Número' width='120px' />
              <Column dataField='clienteNombre' caption='Cliente' width={window.innerWidth < 1360 ? 'auto' : '*'} />
              <Column dataField='total' caption='Total' width='100px' alignment='right' allowSearch={false} />
              <Column dataField='formaPagoDescripcion' caption='Forma Pago' width='120px' allowSearch={false} />
              <Column dataField='puntoVenta' caption='P. Venta' width='80px' allowSearch={false} />
              <Scrolling mode="virtual" />
              <GridLoadPanel enabled={loadPanelEnabled} />
            </DataGrid>
          </ScrollView>
        </CContainer>
      </ValidationGroup>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={'center'}
        visible={buscando}
        showIndicator={true}
        shading={true}
        showPane={true}
        message='Buscando...'
        closeOnOutsideClick={false}
      />
    </CustomModalDevx>
  )
}

export default DocumentoVentaAfectar