import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";

export const autorizacionService = {
  cargarAutorizaciones, getTiposComprobantes, getEstados, ingresar
}

async function cargarAutorizaciones(find): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      ...find
    }
    const data = await RequestHelper.get<any>('compras/compras', 'autorizaciones/getAll', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function getTiposComprobantes(find): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      ...find
    }
    const data = await RequestHelper.get<any>('generales/voucherType', 'cboxload', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function getEstados(find): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      ...find
    }
    const data = await RequestHelper.get<any>('subtypes', 'cboxLoad', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function ingresar(autorizacionIngresar): Promise<any> {
  const sesion = SesionService.getCurrentSesion();
  try {

    let objRegister = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      ...autorizacionIngresar
    }
    const result = await RequestHelper.postAll(
      "compras/compras",
      "autorizaciones/save",
      objRegister
    );
    return result;
  } catch (error) {
    return error;
  }
}