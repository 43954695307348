import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { RequestHelper } from "../../../helpers/requestHelper";
import { utilidades } from "../../../helpers/utilidades";
import { SesionService } from "../../../services/sesion.service";
import { PagedResult, PaginationInfo } from "../../../store/types";
import {
  TiposEstados,
  TipoClasificacion,
  TipoIdentificacion,
  TipoProveedor,
  TiposOperadoras,
} from "../../ventas/types/types";
import { DetalleProveedor, Proveedor, ProveedorBusqueda, ProveedorSearchGrid } from "../store/types";
export const ProveedoresService = {
  getProveedoresDatasource,
  consultarProveedoresPaged,
  consultarProveedores,
  getProveedor,
  getProveedorByCode,
  getTiposProveedor,
  getClasificaciones,
  getTipoIdentificacion,
  getOperadoras,
  getEstados,
  modificarProveedor,
  getDetalleProveedor,
  getProveedorGridDatasource,
  eliminarProveedor
};

async function eliminarProveedor(codigoProveedor: number): Promise<boolean> {
  const sesion = SesionService.getCurrentSesion();
  const queryData = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    codigo: codigoProveedor
  }
  const deleteResult = await RequestHelper.deleteRequest<boolean>('proveedores', 'proveedores/inactive', '', queryData);
  return deleteResult;
}

function getProveedorGridDatasource(dataSearch: ProveedorSearchGrid) {
  console.log("getProveedorGridDatasource")
  const regex = new RegExp("^[0-9]{3,13}$");
  const store = new CustomStore({
    key: "codigo",
    byKey: async (key) => {
      const item = await getProveedorByCode(key);
      return item;
      //console.log(key);
    },
    load: async (loadOptions) => {
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      };
      let pagedResult: PagedResult<ProveedorBusqueda>;


      // nombre: string,
      //   identificacion: string,
      //     estado: boolean,
      //       esTransportista: boolean,
      //   paginInfo: PaginationInfo

      console.log('loadOptions', loadOptions);
      console.log('paginInfo', paginInfo);
      if (regex.test(loadOptions.searchValue)) {
        console.log('if');

        pagedResult = await consultarProveedoresPaged(
          "",
          loadOptions.searchValue.toString(),
          dataSearch['estado'],
          dataSearch['transportista'],
          paginInfo
        );
      } else {
        console.log('else');
        console.log("onSearch", dataSearch)
        pagedResult = await consultarProveedoresPaged(
          dataSearch['nombreComercial'],
          dataSearch['identificacion'],
          dataSearch['activos'],
          dataSearch['transportista'],
          paginInfo
        );
      }
      console.log("current page ", pagedResult.currentPage);
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      };
    },
  });
  const source = new DataSource({
    totalCount: store.totalCount,
    paginate: true,
    pageSize: 10,
    store: store,
    key: "codigo",
  });
  return source;
}

async function consultarProveedoresPaged(
  nombre: string,
  identificacion: string,
  estado: boolean,
  esTransportista: boolean,
  paginInfo: PaginationInfo
): Promise<PagedResult<ProveedorBusqueda>> {
  if (utilidades.canUseNetApi()) {
    return consultarProveedoresPagedApiNet(nombre, identificacion, paginInfo);
  } else {
    return consultarProveedoresPagedApiPhp(
      nombre,
      identificacion,
      estado,
      esTransportista,
      paginInfo
    );
  }
}

async function consultarProveedoresPagedApiPhp(
  nombre: string,
  identificacion: string,
  estado: boolean,
  esTransportista: boolean,
  pagination: PaginationInfo
): Promise<PagedResult<ProveedorBusqueda>> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    page: pagination.current,
    limit: pagination.size,
    isPaged: true,
    estado: estado ? 1 : 0,
  };

  if (identificacion && identificacion.length > 0) {
    query.identificacion = identificacion;
  }
  if (nombre && nombre.length > 0) {
    query.nombre = nombre;
  }
  if (esTransportista) {
    query.transportista = esTransportista;
  }
  const pagedApiResult = await RequestHelper.get<any>(
    "proveedores",
    "all",
    "",
    query
  );
  const proveedoresApi = pagedApiResult.auto as Array<ProveedorBusqueda>;

  const pagedResult: PagedResult<ProveedorBusqueda> = {
    count: pagedApiResult.totalRegistros,
    currentPage: pagedApiResult.paginaActual,
    from: pagedApiResult.desde,
    result: proveedoresApi,
    to: pagedApiResult.hasta,
  };
  return pagedResult;
}

async function consultarProveedoresPagedApiNet(
  nombre: string,
  identificacion: string,
  paginInfo: PaginationInfo
): Promise<PagedResult<ProveedorBusqueda>> {
  const pagedResult: PagedResult<ProveedorBusqueda> = {
    count: 0,
    currentPage: 1,
    from: 0,
    result: [],
    to: 0,
  };
  return pagedResult;
}

function getProveedoresDatasource(
  estado: boolean = true,
  esTransportista: boolean = false
) {
  const regex = new RegExp("^[0-9]{3,13}$");
  const store = new CustomStore({
    key: "codigo",
    byKey: async (key) => {
      var proveedor = await getProveedorByCode(key);
      return proveedor;
    },
    load: async (loadOptions) => {
      console.log(loadOptions);
      const paginInfo: PaginationInfo = {
        current: (loadOptions.skip ?? 0) / (loadOptions.take ?? 0) + 1,
        size: loadOptions.take ?? 10,
      };
      let pagedResult: PagedResult<ProveedorBusqueda>;
      if (regex.test(loadOptions.searchValue)) {
        pagedResult = await consultarProveedoresPaged(
          "",
          loadOptions.searchValue.toString(),
          estado,
          esTransportista,
          paginInfo
        );
      } else {
        pagedResult = await consultarProveedoresPaged(
          loadOptions.searchValue,
          "",
          estado,
          esTransportista,
          paginInfo
        );
      }
      console.log("current page ", pagedResult.currentPage);
      return {
        data: pagedResult.result,
        totalCount: pagedResult.count,
      };
    },
  });
  const source = new DataSource({
    paginate: true,
    pageSize: 10,
    store: store,
    key: "codigo",
  });
  return source;
}

async function consultarProveedores(
  nombre: string,
  identificacion: string,
  esTransportista: boolean | undefined = undefined
): Promise<Array<ProveedorBusqueda>> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    estado: 1,
  };

  if (identificacion && identificacion.length > 0) {
    query.identificacion = identificacion;
  }
  if (nombre && nombre.length > 0) {
    query.nombre = nombre;
  }
  if (esTransportista) {
    query.transportista = esTransportista;
  }
  const proveedoresApi = await RequestHelper.get<Array<ProveedorBusqueda>>(
    "proveedores",
    "all",
    "",
    query
  );
  return proveedoresApi;
}

async function getProveedor(
  identificacion: string,
  esTransportista: boolean | undefined = undefined
): Promise<ProveedorBusqueda | null> {
  const proveedores = await consultarProveedores(
    "",
    identificacion,
    esTransportista
  );
  const proveedor = proveedores.find(
    (p) => p.identificacion === identificacion
  );
  return proveedor ?? null;
}

async function getProveedorByCode(codigo: number): Promise<Proveedor> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    codigo: codigo,
  };
  const proveedor = await RequestHelper.get<Proveedor>(
    "proveedores",
    "proveedores/getByCode",
    "",
    query
  );
  return proveedor;
}

async function getTiposProveedor(): Promise<Array<TipoProveedor>> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
  };
  const cacheKey = "tiposProveedor" + JSON.stringify(query);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return JSON.parse(cached);
  } else {
    const tiposClienteApi = await RequestHelper.get<Array<any>>(
      "proveedores/proveedores",
      "supplier/cboxLoad",
      "",
      query
    );
    const tipos = [] as Array<TipoProveedor>;
    tiposClienteApi
      .filter((x) => x.codigo !== -1)
      .forEach((tipoClienteApi) => {
        if (tipoClienteApi.codigo !== "-1") {
          const tipo: TipoProveedor = {
            codigo: String(tipoClienteApi.codigo),
            descripcion: tipoClienteApi.descripcion,
          };
          tipos.push(tipo);
        }
      });
    sessionStorage.setItem(cacheKey, JSON.stringify(tipos));
    return tipos;
  }
}

async function getClasificaciones(
  mensaje: string
): Promise<Array<TipoClasificacion>> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    tipoCodigo: 90,
    mensaje: mensaje,
  };
  const cacheKey = "tiposClasificaciones" + JSON.stringify(query);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return JSON.parse(cached);
  } else {
    const tiposClasificacionApi = await RequestHelper.get<Array<any>>(
      "subtypes",
      "cboxLoad",
      "",
      query
    );
    const tipos = [] as Array<TipoClasificacion>;
    tiposClasificacionApi
      .filter((x) => x.SUB_CODIGO !== -1)
      .forEach((tipoClienteApi) => {
        if (tipoClienteApi.SUB_CODIGO !== "-1") {
          const tipo: TipoClasificacion = {
            codigo: parseInt(tipoClienteApi.SUB_CODIGO),
            descripcion: tipoClienteApi.SUB_VALOR,
          };
          tipos.push(tipo);
        }
      });
    sessionStorage.setItem(cacheKey, JSON.stringify(tipos));
    return tipos;
  }
}

async function getTipoIdentificacion(
  mensaje: string
): Promise<Array<TipoIdentificacion>> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    tipo: "COMPRA",
    mensaje: mensaje,
  };
  const cacheKey = "tiposIdentificacionCompra" + JSON.stringify(query);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return JSON.parse(cached);
  } else {
    const tiposIdentificacionApi = await RequestHelper.get<Array<any>>(
      "cuenta",
      "tipoId/listar",
      "",
      query
    );
    const tipos = [] as Array<TipoIdentificacion>;
    tiposIdentificacionApi
      .filter((x) => x.SUB_CODIGO !== -1)
      .forEach((tipoClienteApi) => {
        if (tipoClienteApi.SUB_CODIGO !== "-1") {
          const tipo: TipoIdentificacion = {
            codigo: tipoClienteApi.SUB_CODIGO,
            tipo: tipoClienteApi.SUB_VALOR,
          };
          tipos.push(tipo);
        }
      });
    sessionStorage.setItem(cacheKey, JSON.stringify(tipos));
    return tipos;
  }
}

async function getOperadoras(mensaje: string): Promise<Array<TiposOperadoras>> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    tipoCodigo: "COMPRA",
    mensaje: mensaje,
  };
  const cacheKey = "tiposOperadoras" + JSON.stringify(query);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return JSON.parse(cached);
  } else {
    const tiposIdentificacionApi = await RequestHelper.get<Array<any>>(
      "generales",
      "operadoras/listar",
      "",
      query
    );
    const tipos = [] as Array<TiposOperadoras>;
    tiposIdentificacionApi
      .filter((x) => x.OPE_CODIGO !== -1)
      .forEach((tipoClienteApi) => {
        if (tipoClienteApi.OPE_CODIGO !== "-1") {
          const tipo: TiposOperadoras = {
            codigo: tipoClienteApi.OPE_CODIGO,
            tipo: tipoClienteApi.OPE_DESCRIPCION,
          };
          tipos.push(tipo);
        }
      });
    sessionStorage.setItem(cacheKey, JSON.stringify(tipos));
    return tipos;
  }
}

async function getEstados(mensaje: string): Promise<Array<TiposEstados>> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    tipoCodigo: "1",
    mensaje: mensaje,
  };
  const cacheKey = "tiposEstados" + JSON.stringify(query);
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return JSON.parse(cached);
  } else {
    const tiposIdentificacionApi = await RequestHelper.get<Array<any>>(
      "subtypes",
      "cboxLoad",
      "",
      query
    );
    const tipos = [] as Array<TiposEstados>;
    tiposIdentificacionApi
      .filter((x) => x.SUB_CODIGO !== -1)
      .forEach((tipoClienteApi) => {
        if (tipoClienteApi.SUB_CODIGO !== "-1") {
          const tipo: TiposEstados = {
            codigo: tipoClienteApi.SUB_CODIGO,
            tipo: tipoClienteApi.SUB_VALOR,
          };
          tipos.push(tipo);
        }
      });
    sessionStorage.setItem(cacheKey, JSON.stringify(tipos));
    return tipos;
  }
}

async function modificarProveedor(proveedor: Proveedor, telefonos: any): Promise<string> {
  const sesion = SesionService.getCurrentSesion();

  let data_request = JSON.stringify({
    infoEmpresa: {
      ruc: sesion.empresa.ruc,
    },
    infoUsuario: {
      usuario: String(sesion?.usuario.codigo),
    },
    infoRegistro: {
      ...proveedor,
      telefonos: telefonos
    },
  });

  const apiresult = await RequestHelper.post<any>(
    "proveedores",
    "proveedores/save",
    data_request
  );
  return apiresult;
}


async function getDetalleProveedor(proveedorCodigo: number, asociadoTipo: string): Promise<Array<DetalleProveedor>> {
  const sesion = SesionService.getCurrentSesion();
  const query: any = {
    ruc: sesion.empresa.ruc,
    usuario: sesion.usuario.codigo,
    codigo: proveedorCodigo,
    asociadoTipo: asociadoTipo
  };

  const tiposClienteApi = await RequestHelper.get<Array<DetalleProveedor>>(
    "proveedores/proveedores",
    "getDetail",
    "",
    query
  );
  const tipos = [] as Array<DetalleProveedor>;
  tiposClienteApi
    .filter((x) => x.codigo !== -1)
    .forEach((itemDetalle) => {

      const tipo: DetalleProveedor = {
        codigo: Number(itemDetalle.codigo),
        descripcion: itemDetalle.descripcion,
        numero: itemDetalle.numero,
        tipo: itemDetalle.tipo,
        tipoDescripcion: itemDetalle.tipoDescripcion,
        estado: itemDetalle.estado,
        asociadoCodigo: Number(itemDetalle.asociadoCodigo),
        asociadoTipo: itemDetalle.asociadoTipo,
        principal: Number(itemDetalle.principal),
      };
      tipos.push(tipo);

    });
  return tipos;
}