import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo, OpenModal } from "../../../../../store/types";
import { FiltrarMayor } from "../../estadosFinancieros/store/types";
import { PageState, Registros, Seleccionado, formularioBusqueda, TablaCuentas } from "./types";

const initialState: PageState = {
  modulo: 'contabilidad',
  currentAction: "Buscar",
  currentFunction: "",
  aplicacion: 'mayorGeneral',
  acciones: [],
  registros: [],
  registrosBackup: [],
  refrescaTabla: false,
  seleccionado: {
    index: null,
    row: { ASI_DESCRIPCION: "" },
    selectedRow: null,
  },
  formularioBusqueda: {
    ASI_DIARIONRO: -1,
    INICIA_PERIODO: null,
    ASI_FECHA_D: null,
    ASI_FECHA_H: null,
    COD_PROYECTO: -1,
    COD_LOCALCC: -1,
    TIPO_CUENTA: -1,
    TIPO_ASIENTO: -1,
    CUENTA_COD: "",
    CUENTAS_ALL: false,
    IDIOMA_ALTERNO: false,
    VALOR: "",
    DESCRIPCION: "",
  },
  tablaCuentas: {
    cuentas: [],
    seleccion: {
      index: null,
      row: null,
      selectedRow: null
    }
  },
  modal: {
    modalName: "",
    open: false,
    codModulo: null
  },
  periodoCompartido: {
    cuentasAll: false,
    tablaCuentas: null,
    periodoContable: null,
    localCC: null,
    idiomaAlterno: false,
    tipoCuenta: null
  },
  loader: {
    show: false,
    mensaje: ''
  },
}


const mayorSlice = createSlice({
  name: "mayorGeneral",
  initialState: initialState,
  reducers: {
    obtenerAcciones(state, action: PayloadAction<any>) {
      state.acciones = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setData(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },
    setDataBackup(state, action: PayloadAction<Array<Registros>>) {
      state.registrosBackup = action.payload
    },
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      let toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      let toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      let toAdd = {
        ASI_DIARIONRO: action.payload.ASI_DIARIONRO,
        INICIA_PERIODO: action.payload.INICIA_PERIODO,
        ASI_FECHA_D: action.payload.ASI_FECHA_D,
        ASI_FECHA_H: action.payload.ASI_FECHA_H,
        COD_PROYECTO: action.payload.COD_PROYECTO,
        COD_LOCALCC: action.payload.COD_LOCALCC,
        TIPO_CUENTA: action.payload.TIPO_CUENTA,
        TIPO_ASIENTO: action.payload.TIPO_ASIENTO,
        CUENTA_COD: action.payload.CUENTA_COD,
        CUENTAS_ALL: action.payload.CUENTAS_ALL,
        IDIOMA_ALTERNO: action.payload.IDIOMA_ALTERNO,
        VALOR: action.payload.VALOR,
        DESCRIPCION: action.payload.DESCRIPCION,
      }
      state.formularioBusqueda = toAdd
    },
    setTablaCuentas(state, action: PayloadAction<TablaCuentas>) {
      let toAdd = {
        cuentas: action.payload.cuentas,
        seleccion: action.payload.seleccion,
      }
      state.tablaCuentas = toAdd
    },
    setVaciarTablaCuentas(state) {
      state.tablaCuentas.cuentas = []
    },
    setModalAsiento(state, action: PayloadAction<OpenModal>) {
      let toAdd = {
        modalName: action.payload.modalName,
        open: action.payload.open,
        codModulo: action.payload.codModulo
      }
      state.modal = toAdd
    },
    setPeridoCompartido(state, action: PayloadAction<FiltrarMayor>) {
      let toAdd = {
        cuentasAll: action.payload.cuentasAll,
        tablaCuentas: action.payload.tablaCuentas,
        periodoContable: action.payload.periodoContable,
        localCC: action.payload.localCC,
        idiomaAlterno: action.payload.idiomaAlterno,
        tipoCuenta: action.payload.tipoCuenta,
      }
      state.periodoCompartido = toAdd
    },
    setResetPeridoCompartido(state) {
      state.periodoCompartido = null
    },
    changeLoaderMayor(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  }
})

export const { changeLoaderMayor, setVaciarTablaCuentas, setResetPeridoCompartido, setPeridoCompartido, setCurrentFunction, setModalAsiento, setTablaCuentas, obtenerAcciones, setData, setDataBackup, setCurrentAccion, setSeleccionarDato, setResetSeleccion, setFormularioBusqueda } = mayorSlice.actions;
export const mayorReducer = mayorSlice.reducer;