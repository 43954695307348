import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { MenuShortCut } from "../../../store/types";

/**
 * Tipos botones admitidos en el menú global
 */
export enum ButtonTypes {
  none = "none",
  new = "Nuevo",
  save = "Guardar",
  edit = "Editar",
  undo = "Deshacer",
  discount = "Descuento",
  find = "Buscar",
  delete = "Eliminar",
  print = "Imprimir",
  print_design = "Imprimir_Diseño",
  authorizations = "Autorizar",
  sendMail = "Enviar",
  export = "Exportar",
  import = "Importar",
  export_design = "Exportar_diseño",
  broom = "Limpiar",
  times = "Salir",
  disable = "Anular",
  prices = "Precios",
  credit = "Credito",
  payments = "Pagos",
  clone = "Duplicar",
  advances = "Anticipos",
  prev = "Anterior",
  next = "Siguiente",
  regenerate = "Regenerar",
  facturar = "Facturar",
  extract = "Extraer",
}


export type MenuButton = {
  id: string;
  icon: IconDefinition | null;
  text: string;
  shortCut: MenuShortCut | null;
};
