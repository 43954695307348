import { CCard, CCardBody, CCardHeader, CCollapse, CFormGroup, CLink } from '@coreui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Autorizacion } from '../../store/types';
import CIcon from '@coreui/icons-react'
import RadioGroup from "devextreme-react/radio-group";
import Tabla from "../tabla/tabla";
import { RootState } from '../../../../../store/store';
import { setCurrentFunction, setRegistros, setResetSeleccion } from '../../store/modalAutorizacion';
import { autorizacionService } from '../../service/autorizacionServices';
import { ButtonTypes } from '../../../../../views/componentes/globalMenu/types';

type BuscarProps = {
  onChanged: (data) => void
  playLoader: () => void
  stopLoader: () => void
}
const estados = [
  {
    value: 1,
    label: "Activo",
  },
  {
    value: 2,
    label: "Inactivo",
  },

];
const Buscar = (props: BuscarProps) => {
  const { onChanged, stopLoader, playLoader } = props;
  const dispatch = useDispatch();
  const currentFunction = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.currentFunction);
  const infoAdicional = useSelector((state: RootState) => state.compras.compras.modalAutorizacion.infoAdicional);

  const [collapsed, setCollapsed] = React.useState(true)
  const [estado, setEstado] = React.useState<any>(estados[0])

  const handleSubmit = React.useCallback(async () => {

    playLoader()
    dispatch(setResetSeleccion());
    dispatch(setRegistros([]));

    let data_request = {
      ticCodigo: infoAdicional?.codigoTipoDocumento ?? "01",
      pertenece: infoAdicional?.codigoPertenece ?? 0,
      asociado: infoAdicional?.codigoAsociado ?? 0,
      estado: estado?.value ?? 1,
    }
    try {
      const data = await autorizacionService.cargarAutorizaciones(data_request);
      stopLoader()
      if (data) {
        let autorizaciones: Array<Autorizacion> = data.map(function (det) {
          return {
            codigo: String(det?.codigoAutorizacion) ?? "",
            codigoTipoDocumento: String(det?.codigoTIC) ?? "",
            descripcionTipoDocumento: String(det?.comprobanteTIC) ?? "",
            numeroAutorizacion: String(det?.autorizacion) ?? "",
            establecimiento: String(det?.establecimiento) ?? "",
            puntoEmision: String(det?.puntoEmision) ?? "",
            numeracioDesde: Number(det?.desde) ?? 0,
            numeracionHasta: Number(det?.hasta) ?? 0,
            fechaCaducidad: String(det?.caducidad) ?? "",
            numeroActual: String(det?.numeroActual) ?? "",
            codigoPertenece: Number(det?.pertenece) ?? 0,
            descripcionPertenece: String(det?.perteneceDescripcion) ?? "",
            codigoAsociado: String(det?.asociado) ?? "",
            identificacionAsociado: String(det?.idAsociado) ?? "",
            nombreAsociado: String(det?.asociadoDescripcion) ?? "",
            autorizacionImprenta: String(det?.imprenta) ?? "",
            autorizacionCompartida: Number(det?.compratida) ?? 1,
            estado: Number(det?.estado) ?? 0,
            comprobanteElectronico: det?.autorizacion.length === 10 ? false : true
          }
        });
        dispatch(setRegistros(autorizaciones))
      }

      return await data;
    } catch (error) {
      console.error(error);
      stopLoader();

    }
  }, [
    dispatch, infoAdicional, estado, playLoader, stopLoader
  ]);

  React.useEffect(() => {
    if (currentFunction === "Buscar") {
      dispatch(setCurrentFunction(""))
      handleSubmit()
    }
  }, [currentFunction]);

  React.useEffect(() => {
    if (currentFunction && currentFunction !== ButtonTypes.none) {
      switch (currentFunction) {
        case ButtonTypes.undo: {
          dispatch(setResetSeleccion());
          dispatch(setRegistros([]));
          break;
        }
        default:
          break;
      }
      dispatch(setCurrentFunction(ButtonTypes.none))
    }
  }, [currentFunction]);

  return (
    <>
      <CCard>

        <CCardHeader onClick={() => setCollapsed(!collapsed)} >
          <strong> {"Buscar"} </strong>
          <div className="card-header-actions">
            <CLink className="card-header-action" >
              <CIcon name={collapsed ? 'cil-chevron-bottom' : 'cil-chevron-top'} />
            </CLink>
          </div>
        </CCardHeader>
        <CCardBody>
          <CCollapse show={collapsed}>

            <CFormGroup row>
              <div className='dx-field-label col-lg-2 col-md-3 col-sm-12'>
                {'Estado'}
              </div>
              <div className='dx-field-value col-lg-9 col-md-9 col-sm-12'>
                <RadioGroup
                  name='activo'
                  dataSource={estados}
                  value={estado}
                  layout='horizontal'
                  displayExpr='label'
                  onValueChanged={(e) => {
                    setEstado(e.value)
                  }}
                />
              </div>
            </CFormGroup>

          </CCollapse>
          <Tabla onChanged={onChanged} />

        </CCardBody>




      </CCard>

    </>
  );
}
export default Buscar
