/**
 * Estado de edicion 
 */
export enum StatesEdition {
  new = 'new',
  edit = "edit",
  save = "save",
  doesNotApply = 'doesNotApply'
}

/**
 * Tipos de tabs definidos para el uso en un control de tabs de documentos
 */
export enum TabTypes {
  busqueda,
  documento,
  import
}

