import { combineReducers, } from "@reduxjs/toolkit";
import { menuReducer } from "./menuReducer";
import { tabsReducer } from "./tabsReducer";
import { searchCuadresCajaReducer } from "./searchReducer";
import { datosEdicionReducer } from "./editDataReducer";
import { CuadresCajaState } from "../types/types";
import { configuracionesCuadreReducer } from "./configuracionesComandaReducer";

export const cuadresCajaReducer = combineReducers<CuadresCajaState>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchCuadresCajaReducer,
  editData: datosEdicionReducer,
  consguraciones: configuracionesCuadreReducer
});