import { CButton, CCol, CLabel, CRow, CTooltip } from '@coreui/react';
import * as React from 'react';
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { CuadresService } from '../../../../../services/cuadres.service';
import { DateUtils, formatoFechasApi } from '../../../../../../../../../helpers/dateUtils';
import { updateDenominacionOnly, updateDescuadredes_tiOnly, updateDescuadre_tiOnly, updateDpIngresosOnly, updateGenerarCuadreOnly, updateTotalesaldosi_tiOnly, updateTotalinicialb_tiOnly, updateTotalinicial_tiOnly } from '../../../store/editDataReducer';
import { CuadresCajaListado, DenominacionListado, TabStateCuadre } from '../../../../../types/types';
import Dialog from 'react-bootstrap-dialog'
import { StatesEdition } from '../../../../../../../types/enums';
interface IDenominacionProps {
  // currentTab: string,
  tab: TabStateCuadre<CuadresCajaListado>,
  setToast: (texto, type) => void,
  playLoader: () => void,
  stopLoader: () => void,
  onErrorConfirm: (sms, title) => void,
  onConfirmarRegistro: (sms, method) => void
  tabId: string,
}

let objetoBilletes = [
  { descripcion: "CIEN", billeteValor: 100, cantidad: 0, total: 0.00, grupo: 1 },
  { descripcion: "CINCUENTA", billeteValor: 50, cantidad: 0, total: 0.00, grupo: 1 },
  { descripcion: "VEINTE", billeteValor: 20, cantidad: 0, total: 0.00, grupo: 1 },
  { descripcion: "DIEZ", billeteValor: 10, cantidad: 0, total: 0.00, grupo: 1 },
  { descripcion: "CINCO", billeteValor: 5, cantidad: 0, total: 0.00, grupo: 1 },
  { descripcion: "UNO", billeteValor: 1, cantidad: 0, total: 0.00, grupo: 1 },
];

let objetoMonedas = [
  { descripcion: "UND", monedaValor: 1, cantidad: 0, total: 0.00, grupo: 2 },
  { descripcion: "CINCUENTA", monedaValor: 0.50, cantidad: 0, total: 0.00, grupo: 2 },
  { descripcion: "VEINTECINCO", monedaValor: 0.25, cantidad: 0, total: 0.00, grupo: 2 },
  { descripcion: "DIEZ", monedaValor: 0.10, cantidad: 0, total: 0.00, grupo: 2 },
  { descripcion: "CINCO", monedaValor: 0.05, cantidad: 0, total: 0.00, grupo: 2 },
  { descripcion: "UNC", monedaValor: 0.01, cantidad: 0, total: 0.00, grupo: 2 },
]



const Denominacion: React.FunctionComponent<IDenominacionProps> = (props) => {
  const {
    tabId, setToast, playLoader, stopLoader,
    onErrorConfirm, onConfirmarRegistro, tab
  } = props;
  const dialogRefPrint = React.useRef<any>(null);

  const dispatch = useDispatch();

  const local = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].local });
  const puntoVenta = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].puntoVenta });
  const horaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].horaCierre });
  const minutoCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].minutoCierre });
  const fechaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fechaCierre });
  const formEdition = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId] });
  const totalegreso_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].totalegreso_ti });
  const descuadre_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].descuadre_ti });
  const descuadredes_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].descuadredes_ti });
  const dptotales = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales });

  const [objBilletes, setObjBilletes] = React.useState(objetoBilletes);
  const [objMonedas, setObjMonedas] = React.useState(objetoMonedas);
  const [denominacion, setDenominacion] = React.useState<Array<DenominacionListado> | []>([]);
  const [editableInput, setEditableInput] = React.useState<boolean>(false);

  const updateDenominacion = React.useCallback(() => {
    console.log("updateDenominacion");
    console.log("objBilletes", objBilletes);
    console.log("objMonedas", objMonedas);
    let denominacionVar: Array<DenominacionListado> = [];
    objBilletes.forEach(function (item: any) {
      denominacionVar.push({ descripcion: item.descripcion, valor: item.total, grupo: 1 })
    })
    objMonedas.forEach(function (item: any) {
      denominacionVar.push({ descripcion: item.descripcion, valor: item.total, grupo: 2 })
    })
    setDenominacion(denominacionVar);

    dispatch(updateDenominacionOnly({
      denominacion: denominacionVar,
      key: tabId
    }));
    console.log("denominacionVar", denominacionVar);

  }, [objBilletes, objMonedas, dispatch, tabId]);

  const aplicaNuevoValorBilletes = React.useCallback((data, i) => {
    const rowsDataUpdate = objBilletes.slice();
    rowsDataUpdate[i]['cantidad'] = data.value;
    rowsDataUpdate[i]['total'] = (Number(rowsDataUpdate[i]['cantidad']) * Number(rowsDataUpdate[i]['billeteValor']));
    setObjBilletes(rowsDataUpdate);
    updateDenominacion();
  }, [
    objBilletes, updateDenominacion
  ]);

  const aplicaNuevoValorMonedas = React.useCallback((data, i) => {
    const rowsDataUpdate = objMonedas.slice();
    rowsDataUpdate[i]['cantidad'] = data.value;
    rowsDataUpdate[i]['total'] = (Number(rowsDataUpdate[i]['cantidad']) * Number(rowsDataUpdate[i]['monedaValor']));
    setObjMonedas(rowsDataUpdate);
    updateDenominacion();
  }, [objMonedas, updateDenominacion]);

  const getValueEfectivo = React.useCallback(() => {
    let valorBilletes: number = 0;
    let valorMonedas: number = 0;
    let total: number = 0;
    objBilletes.forEach(function (item: any) {
      valorBilletes = valorBilletes + item.total;
    })
    objMonedas.forEach(function (item: any) {
      valorMonedas = valorMonedas + item.total;
    })
    total = Number(valorBilletes) + Number(valorMonedas);
    return total;
  }, [objMonedas, objBilletes]);

  const validarFormulario = React.useCallback(async () => {
    let errors: any = []
    if ((puntoVenta && puntoVenta.codigoPuntoVenta < 0) || (local && local.codigo < 0)) {
      errors.push('Debe elegir un local o punto de venta.')
    }
    if (denominacion.length === 0) {
      errors.push('No existe registro de efectivo.')
    }
    if (minutoCierre === null || horaCierre === null) {
      errors.push('Ingrese la hora y minuto(s) del cuadre.')
    }

    if (fechaCierre === null || fechaCierre === "") {
      errors.push('Ingrese la fecha del cierre.')
    }

    if (errors.length > 0) {
      return {
        errors: true,
        messages: errors
      }
    } else {
      return {
        errors: false,
        messages: errors
      }
    }
  }, [puntoVenta, local, denominacion, minutoCierre, horaCierre, fechaCierre]);

  const generarCuadre = React.useCallback(async () => {
    console.log("generarCuadre")
    dispatch(updateGenerarCuadreOnly({
      generarCuadre: true,
      key: tabId
    }));
    // if (ccaCod1 !== 0) { }
    // if (ccaCod2 !== 0) { }
    // if (ccaCod3 !== 0) { }
    // if (ccaCod4 !== 0) { }
    // if (ccaCod5 !== 0) { }
    // if (ccaCod6 !== 0) { }
    // if (ccaCod7 !== 0) { }
    // if (ccaCod8 !== 0) { }
    // if (ccaCod9 !== 0) { }

  }, [dispatch, tabId]);

  // const ingresosBoveda = React.useCallback(async () => {
  //   // geIngresosBoveda
  //   playLoader();
  //   let fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi);
  //   let dpingresosboveda = await CuadresService.geIngresosBoveda(puntoVenta?.codigoPuntoVenta ?? -1, fechaFilter);
  //   console.log("dpingresosboveda", dpingresosboveda);
  //   stopLoader();
  // }, [fechaCierre, tabId, dispatch, playLoader, stopLoader]);

  const resultadoEspecial = React.useCallback(async () => {

    setEditableInput(true)
    let fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi);
    let dpingresos = await CuadresService.getEspecial(String(fechaFilter), 98);
    console.log("dpingresos", dpingresos);

    let saldoinicial: number = 0;
    let saldoboveda: number = 0;

    if (dpingresos.length > 0) {
      for (const cuadreItem of dpingresos) {
        if (cuadreItem.puntoVentaEspecial === 'BOVEDA') {
          saldoboveda = saldoboveda + cuadreItem.ventaTotal;
        }
        else {
          saldoinicial = saldoinicial + cuadreItem.ventaTotal;
        }
      }

      dispatch(updateTotalinicial_tiOnly({
        totalinicial_ti: saldoinicial,
        key: tabId
      }));

      dispatch(updateTotalinicialb_tiOnly({
        totalinicialb_ti: saldoboveda,
        key: tabId
      }));

      let totalesaldosi_ti: number = 0;
      if (saldoboveda > 0) {
        totalesaldosi_ti = (Number(saldoboveda) - Number(saldoinicial));
      } else {
        totalesaldosi_ti = Number(saldoinicial);
      }

      dispatch(updateTotalesaldosi_tiOnly({
        totalesaldosi_ti: totalesaldosi_ti,
        key: tabId
      }));

      // await ingresosBoveda();

    }

    dispatch(updateDpIngresosOnly({
      dpingresos: dpingresos,
      key: tabId
    }));

    await generarCuadre();
  }, [fechaCierre, tabId, dispatch, generarCuadre]);

  const handleClickConfirmar = React.useCallback(async () => {
    let res = await validarFormulario();
    if (res.errors === true) {
      onErrorConfirm(res.messages, "Antes de continuar revise lo siguiente:")
    } else {
      let sms: string = "Esta a punto de registrar el cuadre, confirma la fecha " + fechaCierre + " y hora " + horaCierre.toString() + ":" + minutoCierre.toString();
      onConfirmarRegistro(sms, resultadoEspecial);
    }
  }, [
    validarFormulario, onErrorConfirm, onConfirmarRegistro, resultadoEspecial,
    horaCierre, minutoCierre, fechaCierre
  ]);

  const sortJSON = (data, key, orden) => {
    return data.sort(function (a: Number, b: Number) {
      var x = a[key],
        y = b[key];

      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  };

  const verificarDescuadre = React.useCallback((denominacion, totales) => {
    console.log("verificarDescuadre")
    console.log("totales", totales);
    console.log("denominacion", denominacion);

    let descuadre: number = 0;
    let totalDenominacion: number = 0;
    let textoDescuadre: string = "Sobrante";
    denominacion.forEach(function (det) {
      totalDenominacion = Number(totalDenominacion) + Number(det.valor);
    });
    let ccdValor = 0;
    if (totales && totales.length > 0 && totales?.[0]?.ccdValor) {
      ccdValor = Number(totales?.[0]?.ccdValor ?? 0)
    }
    descuadre = Number(totalDenominacion - Number(ccdValor));
    descuadre = Number(descuadre.toFixed(2));

    if (descuadre < 0) {
      textoDescuadre = "Faltante";
    } else {
      textoDescuadre = "Sobrante";
    }
    dispatch(updateDescuadre_tiOnly({
      descuadre_ti: descuadre,
      key: tabId
    }));
    dispatch(updateDescuadredes_tiOnly({
      descuadredes_ti: textoDescuadre,
      key: tabId
    }));
  }, [dispatch, tabId]);


  const initForm = React.useCallback((denominacion, edicion: boolean) => {
    let rowsObjetoBilletes: Array<any> = [];
    let rowsObjetoMonedas: Array<any> = [];

    if (edicion === false) {
      setEditableInput(false)

      rowsObjetoBilletes = [
        { descripcion: "CIEN", billeteValor: 100, cantidad: 0, total: 0.00 },
        { descripcion: "CINCUENTA", billeteValor: 50, cantidad: 0, total: 0.00 },
        { descripcion: "VEINTE", billeteValor: 20, cantidad: 0, total: 0.00 },
        { descripcion: "DIEZ", billeteValor: 10, cantidad: 0, total: 0.00 },
        { descripcion: "CINCO", billeteValor: 5, cantidad: 0, total: 0.00 },
        { descripcion: "UNO", billeteValor: 1, cantidad: 0, total: 0.00 },
      ];

      rowsObjetoMonedas = [
        { descripcion: "UND", monedaValor: 1, cantidad: 0, total: 0.00 },
        { descripcion: "CINCUENTA", monedaValor: 0.50, cantidad: 0, total: 0.00 },
        { descripcion: "VEINTECINCO", monedaValor: 0.25, cantidad: 0, total: 0.00 },
        { descripcion: "DIEZ", monedaValor: 0.10, cantidad: 0, total: 0.00 },
        { descripcion: "CINCO", monedaValor: 0.05, cantidad: 0, total: 0.00 },
        { descripcion: "UNC", monedaValor: 0.01, cantidad: 0, total: 0.00 },
      ]
    } else {
      setEditableInput(true)

      denominacion.forEach(function (det) {
        if (det.grupo === 0) {
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'CIEN') {
            rowsObjetoBilletes.push({ ...det, descripcion: det.descripcion, total: Number(det.total), billeteValor: 100, cantidad: Number(det.cantidad), tipo: 'billete' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'CINCUENTA') {
            rowsObjetoBilletes.push({ ...det, descripcion: det.descripcion, total: Number(det.total), billeteValor: 50, cantidad: Number(det.cantidad), tipo: 'billete' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'VEINTE') {
            rowsObjetoBilletes.push({ ...det, descripcion: det.descripcion, total: Number(det.total), billeteValor: 20, cantidad: Number(det.cantidad), tipo: 'billete' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'DIEZ') {
            rowsObjetoBilletes.push({ ...det, descripcion: det.descripcion, total: Number(det.total), billeteValor: 10, cantidad: Number(det.cantidad), tipo: 'billete' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'CINCO') {
            rowsObjetoBilletes.push({ ...det, descripcion: det.descripcion, total: Number(det.total), billeteValor: 5, cantidad: Number(det.cantidad), tipo: 'billete' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'UNO') {
            rowsObjetoBilletes.push({ ...det, descripcion: det.descripcion, total: Number(det.total), billeteValor: 1, cantidad: Number(det.cantidad), tipo: 'billete' })
          }
        }
      })
      rowsObjetoBilletes = sortJSON(rowsObjetoBilletes, "billeteValor", 'desc')

      denominacion.forEach(function (det) {
        if (det.grupo === 1) {
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'UND') {
            rowsObjetoMonedas.push({ ...det, descripcion: det.descripcion, total: Number(det.total), monedaValor: 1, cantidad: Number(det.cantidad), tipo: 'moneda' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'CINCUENTA') {
            rowsObjetoMonedas.push({ ...det, descripcion: det.descripcion, total: Number(det.total), monedaValor: 0.50, cantidad: Number(det.cantidad), tipo: 'moneda' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'VEINTECINCO') {
            rowsObjetoMonedas.push({ ...det, descripcion: det.descripcion, total: Number(det.total), monedaValor: 0.25, cantidad: Number(det.cantidad), tipo: 'moneda' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'DIEZ') {
            rowsObjetoMonedas.push({ ...det, descripcion: det.descripcion, total: Number(det.total), monedaValor: 0.10, cantidad: Number(det.cantidad), tipo: 'moneda' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'CINCO') {
            rowsObjetoMonedas.push({ ...det, descripcion: det.descripcion, total: Number(det.total), monedaValor: 0.05, cantidad: Number(det.cantidad), tipo: 'moneda' })
          }
          if (det.descripcion && String(det.descripcion).toUpperCase() === 'UNC') {
            rowsObjetoMonedas.push({ ...det, descripcion: det.descripcion, total: Number(det.total), monedaValor: 0.01, cantidad: Number(det.cantidad), tipo: 'moneda' })
          }
        }
      })
      rowsObjetoMonedas = sortJSON(rowsObjetoMonedas, "monedaValor", 'desc')

    }

    setObjBilletes(rowsObjetoBilletes);
    setObjMonedas(rowsObjetoMonedas);
  }, []);


  React.useEffect(() => {
    initForm([], false)
  }, [puntoVenta, local, initForm]);

  React.useEffect(() => {
    if (tab.editStatus === StatesEdition.save && formEdition.denominacion) {
      initForm(formEdition.denominacion, true);
    }
  }, [formEdition.denominacion]);

  React.useEffect(() => {
    verificarDescuadre(formEdition.denominacion, dptotales);
  }, [formEdition.denominacion, dptotales]);

  return (
    <>
      <Dialog ref={dialogRefPrint} />

      <CRow className={'m-2'}>
        <CCol lg="6" xl="6" md="6" sm="12">

          <CRow className={'mt-2 mp-2 d-flex justify-content-start'}>
            <CCol lg="12" xl="12" sm="12">
              <strong>{"BILLETES"}</strong>
            </CCol>
          </CRow>

          {objBilletes.map((billete, i) => {
            return (
              <CRow key={i} className={'m-2 d-flex justify-content-center align-items-center'}>
                <CCol xl="2" md="1" sm="1" className={'d-flex justify-content-end'} >
                  <strong>{billete.billeteValor}</strong>
                </CCol>
                <CCol xl="0" md="1" sm="1" >
                  <strong>{"X"}</strong>
                </CCol>
                <CCol xl="3" md="3" sm="2" >
                  <NumberBox readOnly={editableInput} format="#0" min={0} placeholder='' value={billete.cantidad} showSpinButtons={true} onValueChanged={(data) => aplicaNuevoValorBilletes(data, i)}
                  // onEnterKey={() => handleClickConfirmar()}
                  />
                </CCol>
                <CCol xl="0" md="1" sm="1" >
                  <strong>{"="}</strong>
                </CCol>
                <CCol xl="3" md="1" sm="1" >
                  <strong>{Number(billete.total).toFixed(2)}</strong>
                </CCol>
              </CRow>
            )
          })}


        </CCol>
        <CCol lg="6" xl="6" md="6" sm="12">

          <CRow className={'mt-2 mp-2 d-flex justify-content-start'}>
            <CCol lg="12" xl="12" sm="12">
              <strong>{"MONEDAS"}</strong>
            </CCol>
          </CRow>

          {objMonedas.map((moneda, i) => {
            return (
              <CRow key={i} className={'m-2 d-flex justify-content-center align-items-center'}>
                <CCol xl="2" md="1" sm="1" className={'d-flex justify-content-end'} >
                  <strong>{moneda.monedaValor}</strong>
                </CCol>
                <CCol xl="0" md="1" sm="1" >
                  <strong>{"X"}</strong>
                </CCol>
                <CCol xl="3" md="3" sm="2" >
                  <NumberBox readOnly={editableInput} format="#0" min={0} placeholder='' value={moneda.cantidad} showSpinButtons={true} onValueChanged={(data) => aplicaNuevoValorMonedas(data, i)}
                  // onEnterKey={() => handleClickConfirmar()}
                  />
                </CCol>
                <CCol xl="0" md="1" sm="1" >
                  <strong>{"="}</strong>
                </CCol>
                <CCol xl="3" md="1" sm="1" >
                  <strong>{Number(moneda.total).toFixed(2)}</strong>
                </CCol>
              </CRow>
            )
          })}

        </CCol>
      </CRow>

      <CRow className={'mt-2 mp-2 d-flex justify-content-center align-items-center'}>
        <CCol lg="6" xl="6" sm="12">

          <CRow gutters={false} className={' d-flex justify-content-center align-items-center'}>
            <CCol sm="0">
              <CLabel style={{ fontSize: "16px", fontWeight: 600 }}>{"TOTAL EFECTIVO: "} <strong>{getValueEfectivo().toFixed(2)}</strong> </CLabel>
            </CCol>
            <CCol sm="0">
              <CTooltip
                key={"tool-plus-account"}
                placement="top"
                content={"CONFIRMAR"}
              >
                <CButton
                  id="btnConfirmar"
                  color="secondary"
                  className="m-1"
                  size='sm'
                  onClick={() => handleClickConfirmar()}
                >
                  {"CONFIRMAR"}
                </CButton>
              </CTooltip>
            </CCol>
          </CRow>
        </CCol>
      </CRow>

      <CRow className={'mt-2 mp-2 d-flex justify-content-center align-items-center'}>
        <CCol lg="6" xl="6" sm="12">
          <CRow gutters={false} className={' d-flex justify-content-center align-items-center'}>
            <CCol sm="0">
              <CLabel style={{ fontSize: "16px", fontWeight: 600 }}>{"TOTAL EGRESOS: "} <strong>{Number(Number(totalegreso_ti).toFixed(2))}</strong> </CLabel>
            </CCol>
          </CRow>
        </CCol>
      </CRow>

      <CRow className={'mt-2 mp-2 d-flex justify-content-center align-items-center'}>
        <CCol lg="6" xl="6" sm="12">
          <CRow gutters={false} className={' d-flex justify-content-center align-items-center'}>
            <CCol sm="0">
              <CLabel className={"ml-2"} style={{ fontSize: "16px", fontWeight: 600, color: descuadredes_ti === "Faltante" ? "#A52A2A" : "" }}>{"DESCUADRE: "} <strong>{Number(Number(descuadre_ti).toFixed(2))}</strong> </CLabel>
            </CCol>
            <CCol sm="0">
              <CLabel className={"ml-2"} style={{ fontSize: "16px", fontWeight: 600, color: descuadredes_ti === "Faltante" ? "#A52A2A" : "" }}> <strong>{descuadredes_ti}</strong> </CLabel>
            </CCol>
          </CRow>
        </CCol>
      </CRow>

    </>
  )
}
export default Denominacion;
