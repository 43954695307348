import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo, Puntoventa } from "../../../../../../../store/types";
import { CustomDictionary } from "../../../../../types/generics";
import { ccDetalle, CuadresCajaDatosEdicion, CuadresCajaDatosEdicionPayload, ComprobantesCuadresCajaListado, formasPagoCuadre, LocalOption, AnticiposCuadresCajaListado, AbonosCuadresCajaListado, ComprasCuadresCajaListado, AbonoComprasCuadresCajaListado, ResumenListado, ChequesCuadresCajaListado, VendedoresListado, LetrasCuadresCajaListado, BovedaCuadresCajaListado, IngresosCuadresCajaListado, DenominacionListado } from "../../../types/types";
import { cuadresClearDatosEdicion } from "../../../store/reducers";

const initialState: CustomDictionary<CuadresCajaDatosEdicion> = {};

const datosEdicionCuadreCajaSlice = createSlice({
  name: "cuadresCajaDatosEdicion",
  initialState: initialState,
  reducers: {
    cuadresClearDatosEdicion,
    setFechaRetencion(
      state,
      action: PayloadAction<{ key: number | string; fecha: string }>
    ) {
      state[action.payload.key].fechaCierre = action.payload.fecha;
    },
    setDatosEdicion(
      state,
      action: PayloadAction<{
        key: number | string;
        data: CuadresCajaDatosEdicion;
      }>
    ) {
      state[action.payload.key] = action.payload.data;
    },
    initDatosEdicion(state, action: PayloadAction<CuadresCajaDatosEdicionPayload<CuadresCajaDatosEdicion>>) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data;
      }
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>
    ) {
      state[action.payload.key].loader = action.payload.info;
    },
    setMuestraError(state, action: PayloadAction<{ key: number | string, tieneError: boolean, mensajeError: string }>) {
      state[action.payload.key].tieneError = action.payload.tieneError;
      state[action.payload.key].mensajeError = action.payload.mensajeError;
    },
    updatePuntoVentaOnly(state, action: PayloadAction<{ key: number | string, puntoventa: Puntoventa }>) {
      state[action.payload.key].puntoVenta = action.payload.puntoventa;
    },
    updateHoraCierreOnly(state, action: PayloadAction<{ key: number | string, horaCierre: number }>) {
      state[action.payload.key].horaCierre = action.payload.horaCierre;
    },
    updateMinutoCierreOnly(state, action: PayloadAction<{ key: number | string, minutoCierre: number }>) {
      state[action.payload.key].minutoCierre = action.payload.minutoCierre;
    },
    updateFechaCierreOnly(state, action: PayloadAction<{ key: number | string, fechaCierre: string }>) {
      state[action.payload.key].fechaCierre = action.payload.fechaCierre;
    },
    updateLocalOnly(state, action: PayloadAction<{ key: number | string, local: LocalOption }>) {
      state[action.payload.key].local = action.payload.local;
    },
    updateTotalinicialb_tiOnly(state, action: PayloadAction<{ key: number | string, totalinicialb_ti: number }>) {
      state[action.payload.key].totalinicialb_ti = action.payload.totalinicialb_ti;
    },
    updateTotalinicial_tiOnly(state, action: PayloadAction<{ key: number | string, totalinicial_ti: number }>) {
      state[action.payload.key].totalinicial_ti = action.payload.totalinicial_ti;
    },
    updateTotalesaldosi_tiOnly(state, action: PayloadAction<{ key: number | string, totalesaldosi_ti: number }>) {
      state[action.payload.key].totalesaldosi_ti = action.payload.totalesaldosi_ti;
    },
    updateCcaCod1Only(state, action: PayloadAction<{ key: number | string, ccaCod1: number }>) {
      state[action.payload.key].ccaCod1 = action.payload.ccaCod1;
    },
    updateGenerarCuadreOnly(state, action: PayloadAction<{ key: number | string, generarCuadre: boolean }>) {
      state[action.payload.key].generarCuadre = action.payload.generarCuadre;
    },
    updateFacturasCuadreOnly(state, action: PayloadAction<{ key: number | string, dpcierre1: Array<ComprobantesCuadresCajaListado> | [] }>) {
      state[action.payload.key].dpcierre1 = action.payload.dpcierre1;
    },
    updateFormasPagoCuadreCuadreOnly(state, action: PayloadAction<{ key: number | string, dptotales1: Array<formasPagoCuadre> | [] }>) {
      state[action.payload.key].dptotales1 = action.payload.dptotales1;
    },
    updateAnuladasCierre1Only(state, action: PayloadAction<{ key: number | string, anuladascierre1_ti: number }>) {
      state[action.payload.key].anuladascierre1_ti = action.payload.anuladascierre1_ti;
    },
    updateTotal1_tiOnly(state, action: PayloadAction<{ key: number | string, total1_ti: number }>) {
      state[action.payload.key].total1_ti = action.payload.total1_ti;
    },
    updateDptotalesOnly(state, action: PayloadAction<{ key: number | string, dptotales: Array<ccDetalle> | [] }>) {
      state[action.payload.key].dptotales = action.payload.dptotales;
    },
    updateTotalCaja_tiOnly(state, action: PayloadAction<{ key: number | string, totalcaja_ti: number }>) {
      state[action.payload.key].totalcaja_ti = action.payload.totalcaja_ti;
    },
    updateTotalEgresos_tiOnly(state, action: PayloadAction<{ key: number | string, totalegresos_ti: number }>) {
      state[action.payload.key].totalegresos_ti = action.payload.totalegresos_ti;
    },
    updateValorTotal_tiOnly(state, action: PayloadAction<{ key: number | string, valortotal_ti: number }>) {
      state[action.payload.key].valortotal_ti = action.payload.valortotal_ti;
    },
    updateTotalCajab_tiOnly(state, action: PayloadAction<{ key: number | string, totalcajab_ti: number }>) {
      state[action.payload.key].totalcajab_ti = action.payload.totalcajab_ti;
    },
    updateTotalEgreso_tiOnly(state, action: PayloadAction<{ key: number | string, totalegreso_ti: number }>) {
      state[action.payload.key].totalegreso_ti = action.payload.totalegreso_ti;
    },
    updateDescuadre_tiOnly(state, action: PayloadAction<{ key: number | string, descuadre_ti: number }>) {
      state[action.payload.key].descuadre_ti = action.payload.descuadre_ti;
    },
    updateDescuadredes_tiOnly(state, action: PayloadAction<{ key: number | string, descuadredes_ti: string }>) {
      state[action.payload.key].descuadredes_ti = action.payload.descuadredes_ti;
    },
    updateTotalEgresosb_tiOnly(state, action: PayloadAction<{ key: number | string, totalegresosb_ti: number }>) {
      state[action.payload.key].totalegresosb_ti = action.payload.totalegresosb_ti;
    },
    updateValorTotalb_tiOnly(state, action: PayloadAction<{ key: number | string, valortotalb_ti: number }>) {
      state[action.payload.key].valortotalb_ti = action.payload.valortotalb_ti;
    },
    updateDpegresosBovedaOnly(state, action: PayloadAction<{ key: number | string, dpegresosboveda: Array<any> | [] }>) {
      state[action.payload.key].dpegresosboveda = action.payload.dpegresosboveda;
    },
    updateDpCierre2Only(state, action: PayloadAction<{ key: number | string, dpcierre2: Array<ComprobantesCuadresCajaListado> | [] }>) {
      state[action.payload.key].dpcierre2 = action.payload.dpcierre2;
    },
    updateDptotales2Only(state, action: PayloadAction<{ key: number | string, dptotales2: Array<formasPagoCuadre> | [] }>) {
      state[action.payload.key].dptotales2 = action.payload.dptotales2;
    },
    updateAnuladasCierre2_tiOnly(state, action: PayloadAction<{ key: number | string, anuladascierre2_ti: number }>) {
      state[action.payload.key].anuladascierre2_ti = action.payload.anuladascierre2_ti;
    },
    updateTotal2_tiOnly(state, action: PayloadAction<{ key: number | string, total2_ti: number }>) {
      state[action.payload.key].total2_ti = action.payload.total2_ti;
    },
    updateDpCierre3Only(state, action: PayloadAction<{ key: number | string, dpcierre3: Array<ComprobantesCuadresCajaListado> | [] }>) {
      state[action.payload.key].dpcierre3 = action.payload.dpcierre3;
    },
    updateDptotales3Only(state, action: PayloadAction<{ key: number | string, dptotales3: Array<formasPagoCuadre> | [] }>) {
      state[action.payload.key].dptotales3 = action.payload.dptotales3;
    },
    updateAnuladasCierre3_tiOnly(state, action: PayloadAction<{ key: number | string, anuladascierre3_ti: number }>) {
      state[action.payload.key].anuladascierre3_ti = action.payload.anuladascierre3_ti;
    },
    updateTotal3_tiOnly(state, action: PayloadAction<{ key: number | string, total3_ti: number }>) {
      state[action.payload.key].total3_ti = action.payload.total3_ti;
    },
    updateDpCierre4Only(state, action: PayloadAction<{ key: number | string, dpcierre4: Array<ChequesCuadresCajaListado> | [] }>) {
      state[action.payload.key].dpcierre4 = action.payload.dpcierre4;
    },
    updateDpCierre5Only(state, action: PayloadAction<{ key: number | string, dpcierre5: Array<LetrasCuadresCajaListado> | [] }>) {
      state[action.payload.key].dpcierre5 = action.payload.dpcierre5;
    },
    updateDpCierre7Only(state, action: PayloadAction<{ key: number | string, dpcierre7: Array<AnticiposCuadresCajaListado> | [] }>) {
      state[action.payload.key].dpcierre7 = action.payload.dpcierre7;
    },
    updateDptotales7Only(state, action: PayloadAction<{ key: number | string, dptotales7: Array<formasPagoCuadre> | [] }>) {
      state[action.payload.key].dptotales7 = action.payload.dptotales7;
    },
    updateTotal7_tiOnly(state, action: PayloadAction<{ key: number | string, total7_ti: number }>) {
      state[action.payload.key].total7_ti = action.payload.total7_ti;
    },

    updateDpCierre8Only(state, action: PayloadAction<{ key: number | string, dpcierre8: Array<AbonosCuadresCajaListado> | [] }>) {
      state[action.payload.key].dpcierre8 = action.payload.dpcierre8;
    },
    updateDptotales8Only(state, action: PayloadAction<{ key: number | string, dptotales8: Array<formasPagoCuadre> | [] }>) {
      state[action.payload.key].dptotales8 = action.payload.dptotales8;
    },
    updateTotal8_tiOnly(state, action: PayloadAction<{ key: number | string, total8_ti: number }>) {
      state[action.payload.key].total8_ti = action.payload.total8_ti;
    },

    updateDpCierre9Only(state, action: PayloadAction<{ key: number | string, dpcierre9: Array<ComprasCuadresCajaListado> | [] }>) {
      state[action.payload.key].dpcierre9 = action.payload.dpcierre9;
    },
    updateDptotales9Only(state, action: PayloadAction<{ key: number | string, dptotales9: Array<formasPagoCuadre> | [] }>) {
      state[action.payload.key].dptotales9 = action.payload.dptotales9;
    },
    updateTotalCom_tiOnly(state, action: PayloadAction<{ key: number | string, totalcom_ti: number }>) {
      state[action.payload.key].totalcom_ti = action.payload.totalcom_ti;
    },

    updateDpCierre10Only(state, action: PayloadAction<{ key: number | string, dpcierre10: Array<AbonoComprasCuadresCajaListado> | [] }>) {
      state[action.payload.key].dpcierre10 = action.payload.dpcierre10;
    },
    updateDptotales10Only(state, action: PayloadAction<{ key: number | string, dptotales10: Array<formasPagoCuadre> | [] }>) {
      state[action.payload.key].dptotales10 = action.payload.dptotales10;
    },
    updateTotalTesoreria_tiOnly(state, action: PayloadAction<{ key: number | string, totaltesoreria_ti: number }>) {
      state[action.payload.key].totaltesoreria_ti = action.payload.totaltesoreria_ti;
    },

    updateDpTotalResumenOnly(state, action: PayloadAction<{ key: number | string, dptotalresumen: Array<ResumenListado> }>) {
      state[action.payload.key].dptotalresumen = action.payload.dptotalresumen;
    },

    updateDpVendedoresOnly(state, action: PayloadAction<{ key: number | string, dpvendedores: Array<VendedoresListado> }>) {
      state[action.payload.key].dpvendedores = action.payload.dpvendedores;
    },
    updateVendedorOnly(state, action: PayloadAction<{ key: number | string, vendedor: VendedoresListado }>) {
      state[action.payload.key].vendedor = action.payload.vendedor;
    },

    updateIngresosBovedaOnly(state, action: PayloadAction<{ key: number | string, dpingresosboveda: Array<BovedaCuadresCajaListado> }>) {
      state[action.payload.key].dpingresosboveda = action.payload.dpingresosboveda;
    },
    updateTotalSaldoi_tiOnly(state, action: PayloadAction<{ key: number | string, totalsaldoi_ti: number }>) {
      state[action.payload.key].totalsaldoi_ti = action.payload.totalsaldoi_ti;
    },
    updateTotalBovedai_tiOnly(state, action: PayloadAction<{ key: number | string, totalbovedai_ti: number }>) {
      state[action.payload.key].totalbovedai_ti = action.payload.totalbovedai_ti;
    },
    updateTotalIngresosb_tiOnly(state, action: PayloadAction<{ key: number | string, totalingresosb_ti: number }>) {
      state[action.payload.key].totalingresosb_ti = action.payload.totalingresosb_ti;
    },
    updateValorTotalBoveda_tiOnly(state, action: PayloadAction<{ key: number | string, valortotalboveda_ti: number }>) {
      state[action.payload.key].valortotalboveda_ti = action.payload.valortotalboveda_ti;
    },
    updateDpIngresosOnly(state, action: PayloadAction<{ key: number | string, dpingresos: Array<IngresosCuadresCajaListado> }>) {
      state[action.payload.key].dpingresos = action.payload.dpingresos;
    },

    updateDenominacionOnly(state, action: PayloadAction<{ key: number | string, denominacion: Array<DenominacionListado> }>) {
      state[action.payload.key].denominacion = action.payload.denominacion;
    },
    updateFechaOnly(state, action: PayloadAction<{ key: number | string, fecha: string }>) {
      state[action.payload.key].fecha = action.payload.fecha;
    },

    updateCcaCodigoOnly(state, action: PayloadAction<{ key: number | string, ccaCodigo: number }>) {
      state[action.payload.key].ccaCodigo = action.payload.ccaCodigo;
    },
    updateUsuarioNombreOnly(state, action: PayloadAction<{ key: number | string, usuarioNombre: string }>) {
      state[action.payload.key].usuarioNombre = action.payload.usuarioNombre;
    },
    updateUsuarioCodigoOnly(state, action: PayloadAction<{ key: number | string, usuarioCodigo: number }>) {
      state[action.payload.key].usuarioCodigo = action.payload.usuarioCodigo;
    },
    updateCuadreTotalOnly(state, action: PayloadAction<{ key: number | string, cuadreTotal: number }>) {
      state[action.payload.key].cuadreTotal = action.payload.cuadreTotal;
    },
  },
});

export const {
  setDatosEdicion,
  setEditLoader,
  setMuestraError,
  setFechaRetencion,
  initDatosEdicion,
  cuadresClearDatosEdicion: clearDatosEdicion,
  updatePuntoVentaOnly,
  updateMinutoCierreOnly,
  updateHoraCierreOnly,
  updateFechaCierreOnly,
  updateLocalOnly,
  updateTotalinicialb_tiOnly,
  updateTotalinicial_tiOnly,
  updateTotalesaldosi_tiOnly,
  updateCcaCod1Only,
  updateGenerarCuadreOnly,
  updateFacturasCuadreOnly,
  updateFormasPagoCuadreCuadreOnly,
  updateAnuladasCierre1Only,
  updateTotal1_tiOnly,
  updateDptotalesOnly,
  updateTotalCaja_tiOnly,
  updateTotalEgresos_tiOnly,
  updateValorTotal_tiOnly,
  updateTotalCajab_tiOnly,
  updateTotalEgresosb_tiOnly,
  updateValorTotalb_tiOnly,
  updateDpegresosBovedaOnly,
  updateDpCierre2Only, updateDptotales2Only, updateAnuladasCierre2_tiOnly, updateTotal2_tiOnly,
  updateDpCierre3Only, updateDptotales3Only, updateAnuladasCierre3_tiOnly, updateTotal3_tiOnly,
  updateDpCierre7Only, updateDptotales7Only, updateTotal7_tiOnly,
  updateDpCierre8Only, updateDptotales8Only, updateTotal8_tiOnly,
  updateDpCierre9Only, updateTotalCom_tiOnly, updateDptotales9Only,
  updateDpCierre10Only, updateDptotales10Only, updateTotalTesoreria_tiOnly,
  updateDpTotalResumenOnly, updateDpCierre4Only, updateDpVendedoresOnly, updateVendedorOnly,
  updateDpCierre5Only, updateIngresosBovedaOnly, updateTotalSaldoi_tiOnly, updateTotalBovedai_tiOnly,
  updateTotalIngresosb_tiOnly, updateValorTotalBoveda_tiOnly, updateDpIngresosOnly, updateDenominacionOnly,
  updateFechaOnly, updateCcaCodigoOnly, updateUsuarioNombreOnly, updateUsuarioCodigoOnly, updateCuadreTotalOnly,
  updateTotalEgreso_tiOnly, updateDescuadre_tiOnly, updateDescuadredes_tiOnly
} = datosEdicionCuadreCajaSlice.actions;
export const datosEdicionReducer = datosEdicionCuadreCajaSlice.reducer;
