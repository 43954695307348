import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo } from "../../../../../store/types";
import { ImportarPlantillas } from "../types/types";

const initialState: ImportarPlantillas = {
  loader: {
    mensaje: 'Cargando...',
    show: false
  },
  openImport: false
}

const importarPlatillasSlice = createSlice(
  {
    name: 'importar',
    initialState: initialState,
    reducers: {
      setEditLoader(
        state,
        action: PayloadAction<{ key: number | string; info: LoaderInfo }>
      ) {
        state.loader = action.payload.info;
      },
      setOpenImport(
        state,
        action: PayloadAction<boolean>
      ) {
        state.openImport = action.payload;
      },
    }
  }
)

export const { setOpenImport, setEditLoader } = importarPlatillasSlice.actions;
export const importarReducer = importarPlatillasSlice.reducer;