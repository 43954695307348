import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccionMenu } from "../../../../store/types";
import { SeleccionadoTableItem } from "../../../bancos/store/types";
import { ModalOpen } from "../../../contabilidad/general/store/types";
import { Alerta } from "../../../contabilidad/pages/diarioGeneral/components/modalAnticipos/store/types";
import { ModalNovedadesState, Registros } from "./types";

const initialState: ModalNovedadesState = {
  modulo: 'novedades',
  currentAction: "Buscar",
  currentFunction: "",
  aplicacion: 'modalNovedades',
  acciones: [],
  registros: [],
  seleccionado: {
    index: null,
    row: null,
  },
  refrescaTabla: false,
  modal: {
    open: false,
    name: "",
    parametros: [],
    codModulo: 0
  },
  motrarAlerta: {
    mostrar: false,
    mensaje: ""
  }
}

const modalNovedadesSlice = createSlice({
  name: "Novedades",
  initialState: initialState,
  reducers: {
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    obtenerAcciones(state, action: PayloadAction<Array<AccionMenu>>) {
      state.acciones = action.payload
    },
    setRegistros(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<SeleccionadoTableItem>) {
      let toAdd = {
        index: action.payload.index,
        row: action.payload.row
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      let toAdd = {
        index: null,
        row: null,
      }
      state.seleccionado = toAdd
    },
    setModalNovedades(state, action: PayloadAction<ModalOpen>) {
      let toAdd = {
        open: action.payload.open,
        name: action.payload.name,
        parametros: action.payload.parametros,
        codModulo: action.payload.codModulo
      }
      state.modal = toAdd
    },
    setMostrarAlerta(state, action: PayloadAction<Alerta>) {
      let toAdd = {
        mostrar: action.payload.mostrar,
        mensaje: action.payload.mensaje,
      }
      state.motrarAlerta = toAdd
    },
  }
})

export const { setCurrentFunction, setMostrarAlerta, setModalNovedades, obtenerAcciones, setRegistros, setCurrentAction, setSeleccionarDato, setResetSeleccion } = modalNovedadesSlice.actions;
export const modalNovedadesReducer = modalNovedadesSlice.reducer;