import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalOpen } from '../../../../../contabilidad/general/store/types'
import { EgresosState, Egresos, DetalleEgreso, DatosFormularioEgresos, formularioBusqueda } from "./types";
import { LoaderInfo, Seleccionado } from "../../../../../../store/types";
import { statusForms } from "../../store/types";

const initialState: EgresosState = {
  modulo: 'egresosND',
  codModulo: null,
  actionDisplay: '',
  currentAction: 'Buscar',
  currentFunction: "",
  registros: [],
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  detalleEgreso: [],
  modalDisplay: {
    open: false,
    name: '',
    parametros: [],
    codModulo: 0
  },
  formulario: {},
  dpTipoTransaccion: [],
  loader: {
    show: false,
    mensaje: ''
  },
  formularioBusqueda: {
    fechaDesde: "",
    fechaHasta: "",
    codigo: 0,
    numero: "",
    aceptado: "",
    tipo: 0,
    concepto: "",
    planCuentasCodigo: 0,
    fecha: ""
  },
  currentFormulario: null,
  formularioEdicion: {
    estadoFormulario: statusForms.pending,
    diarioNumero: 0,
    fechaEgreso: "",
    fechaAuditoria: "",
    usuario: "",
    numeroDocumento: 0,
    numeroAsiento: 0,
    numeroTipo: 0,
    tipoDocumento: "",
    totalDebe: 0,
    totalHaber: 0,
    proyecto: null,
    local: null,
    codigoIngreso: 0,
    descripcion: "",
    lote: 0,
    asociadoNombre: "",
    detalleAsiento: []
  }
}

const egresosNDSlice = createSlice({
  name: "egresoND",
  initialState: initialState,
  reducers: {
    setCodModulo(state, action: PayloadAction<number | null>) {
      state.codModulo = action.payload
    },
    setActionDisplay(state, action: PayloadAction<string>) {
      state.actionDisplay = action.payload
    },
    setCurrentAccionEgresos(state, action: PayloadAction<any>) {
      state.currentAction = action.payload
    },
    setCurrentFunctionEgresos(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setData(state, action: PayloadAction<Array<Egresos>>) {
      state.registros = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      state.seleccionado = action.payload;
    },
    setResetSeleccionEgresosND(state) {
      let toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setDetalleEgreso(state, action: PayloadAction<Array<DetalleEgreso>>) {
      state.detalleEgreso = action.payload
    },
    setModalDisplay(state, action: PayloadAction<ModalOpen>) {
      state.modalDisplay = action.payload
    },
    setFormulario(state, action: PayloadAction<any>) {
      state.formulario = action.payload
    },
    setDpTipoTransaccion(state, action: PayloadAction<Array<any>>) {
      state.dpTipoTransaccion = action.payload
    },
    changeLoaderEngresosND(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setCurrentFormulario(state, action: PayloadAction<any>) {
      state.currentFormulario = action.payload
    },
    setFormularioEdicionEgresos(state, action: PayloadAction<DatosFormularioEgresos>) {
      state.formularioEdicion = action.payload
    },
    setFormularioBusqueda(state, action: PayloadAction<formularioBusqueda>) {
      state.formularioBusqueda = action.payload
    },
  }
})
export const {
  setCodModulo,
  setActionDisplay,
  setCurrentAccionEgresos,
  setCurrentFunctionEgresos,
  setData,
  setSeleccionarDato,
  setResetSeleccionEgresosND,
  setDetalleEgreso,
  setModalDisplay,
  setFormulario,
  setDpTipoTransaccion,
  changeLoaderEngresosND,
  setCurrentFormulario,
  setFormularioEdicionEgresos,
  setFormularioBusqueda
} = egresosNDSlice.actions;
export const EgresosNDReducer = egresosNDSlice.reducer;

