import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../store/store";
import { TiposComprobantesSri } from "../../../../../../store/types";
import { ButtonTypes } from "../../../../../../views/componentes/globalMenu/types";
import { InvoiceButtons } from "../../../../../ventas/components/ventas/venta/venta";
import { StatesEdition, TabTypes } from "../../../../../ventas/types/enums";
import { CustomButtons } from "../../../../../ventas/types/generics";
import { VentaListado } from "../../../../../ventas/types/types";
import { EnumTabs } from "../../../types/generico";
import { TabsStateModal, TabStateModal } from "../types/generics";
import { modalVentasCloseTab, modalExisteTab } from "./reducers";

const initialState: TabsStateModal<VentaListado> = {
  current: EnumTabs.TabSearch,
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: "Búsqueda",
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: EnumTabs.TabSearch,
      buttons: {
        Buscar: true,
        Nuevo: true,
        Exportar: false,
      },
    },
  },
};


const tabsVentasSlice = createSlice({
  name: "modalVentasTabs",
  initialState: initialState,
  reducers: {
    modalVentasCloseTab,
    openTab(
      state,
      action: PayloadAction<{ key: string; venta?: VentaListado, templateMode?: boolean }>
    ) {
      if (action.payload.venta === undefined || action.payload.templateMode) {
        const tab: TabStateModal<VentaListado> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: "Nueva",
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,
          info: {
            claveAcceso: "",
            numero: action.payload.templateMode && action.payload.venta ? action.payload.venta.comprobante : 0,
            tipoComprobante: TiposComprobantesSri.Factura,
          },
          buttons: InvoiceButtons,
          templateMode: action.payload.templateMode
        };
        state.tabs[action.payload.key] = tab;
        state.current = action.payload.key;
      } else {
        const tabKey = modalExisteTab(state.tabs, action.payload.venta.comprobante);
        if (tabKey) {
          state.current = tabKey;
        } else {
          const tab: TabStateModal<VentaListado> = {
            tabKey: action.payload.key,
            id: action.payload.venta.comprobante,
            tittle: `${action.payload.venta.establecimiento}-${action.payload.venta.puntoEmision}-${action.payload.venta.numero}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              claveAcceso: action.payload.venta.claveAcceso,
              numero: action.payload.venta.comprobante,
              tipoComprobante: TiposComprobantesSri.Factura,
              info: action.payload.venta,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: InvoiceButtons,
          };
          state.tabs[action.payload.key] = tab;
          state.current = action.payload.key;
        }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string;
        estado: StatesEdition;
        info?: VentaListado;
        buttons: CustomButtons
      }>
    ) {
      const { estado, tabKey, info, buttons } = action.payload;
      state.tabs[tabKey].editStatus = estado;
      state.tabs[tabKey].currentExecutingAction = undefined;
      state.tabs[tabKey].buttons = buttons;
      if (info) {
        state.tabs[tabKey].info = {
          claveAcceso: info.claveAcceso,
          numero: info.comprobante,
          tipoComprobante: TiposComprobantesSri.Factura,
          info: info ?? null,
        };
        state.tabs[tabKey].id = info.comprobante;
        state.tabs[
          action.payload.tabKey
        ].tittle = `${info.establecimiento}-${info.puntoEmision}-${info.numero}`;
      }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button;
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none;
      }

    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload;
      }
    },
    setTabInfoAsInactive(state, action: PayloadAction<string>) {
      //if (action.payload >= state.tabs.length) return;
      const info = state.tabs[action.payload].info;
      if (info !== undefined) {
        if (info.info) {
          info.info.estado = "ANULADO";
          info.info.estadoElectronico = "ANULADO";
        }
        state.tabs[action.payload].info = info;
      }
    },
    setButtons(state, action: PayloadAction<{ tabKey: string, buttons: CustomButtons }>) {
      const { buttons, tabKey } = action.payload;
      state.tabs[tabKey].buttons = buttons;
      state.tabs[tabKey].currentExecutingAction = undefined;
    },
    setModalCurrentExecutingAction(state, action: PayloadAction<{ tabKey: string, buttonType: ButtonTypes | undefined }>) {
      const { buttonType, tabKey } = action.payload;
      state.tabs[tabKey].currentExecutingAction = buttonType;
    }
  },
});

export const selectTabsventas = (state: RootState) => {
  return state.restaurante.comandas.facturacion.tabs;
};
export const {
  modalVentasCloseTab: closeTabModalVenta,
  openTab,
  changeCurrentTab,
  changeEditStatus,
  clearButtonClick,
  buttonClick,
  setTabInfoAsInactive,
  setButtons,
  setModalCurrentExecutingAction
} = tabsVentasSlice.actions;
export const tabsReducer = tabsVentasSlice.reducer;
