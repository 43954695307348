import ReactDataSheet from "react-datasheet";
import { Autorizacion, LoaderInfo } from "../../../../../store/types";
import { SheetDescripcionItemSelectEditor } from "../../../components/ventas/sheetEditors/sheetDescripcionItemSelectEditor";
import { SheetNumberEditor } from "../../../components/ventas/sheetEditors/sheetNumberEditor";
import { SheetTextEditor } from "../../../components/ventas/sheetEditors/sheetTextEditor";
import { AutorizacionDocumentoVenta, Cliente, CuotaCreditoVenta, FormaPagoModuloVentas } from "../../../types/types";
import { DetalleVentaRedux } from "./detalleVenta";


export const COL_NUMERO = 0;
export const COL_COMANDOS = 1;
export const COL_CODIGOITEM = 2;
export const COL_NOMBREITEM = 3;
//export const COL_UNIDAD = 5;
export const COL_IMPUESTO = 4;
export const COL_CANTIDAD = 5;
export const COL_PORCENTAJEDESCUENTO = 6;
export const COL_PRECIOUNITARIO = 7;
export const COL_PRECIOUNITARIOCONIVA = 8
export const COL_SUBTOTALLINEA = 9;

export const TABLECOL_COMANDOS = 0;
export const TABLECOL_CODIGO = 1;
export const TABLECOL_DESCRIPCION = 2;
export const TABLECOL_CANTIDAD = 3;
export const TABLECOL_PRECIO = 4;
export const TABLECOL_SUBTOTAL = 5;
export const TABLECOL_IVA = 6;
export const TABLECOL_TOTAL = 7;
export const TABLECOL_PUEDEEDITAR = 8;
export const TABLECOL_ISVALID = 9;
export const TABLECOL_FEEDBACK = 10;

export type ConfiguracionesVentas = {
  usaDescuentoGlobal: boolean,
  autorizacion: Autorizacion | null
  autorizacionDocumento: AutorizacionDocumentoVenta | null
}

export interface GridDetalleVentasElement extends ReactDataSheet.Cell<GridDetalleVentasElement, string> {
  display: string;
  value: any;
  valid: boolean;
  hint?: string;
  hintType?: "info" | "warning" | "danger"
  data?: any;
  readOnly: boolean;
  loading?: boolean;
  hasRowData?: boolean;
  eliminar?: any;
  verDetalles?: any;
  cliente: Cliente,
  fecha: string
}


export const ColumnasGridVentaDetalle = [

  {
    id: COL_NUMERO,
    name: 'numero',
    label: '#',
    className: 'xxxxx',
    readOnly: true,
    width: '40px'
  },
  {
    id: COL_COMANDOS,
    name: 'comandos',
    label: '',
    className: '',
    readOnly: true,
    width: '40px'
  },
  {
    id: COL_CODIGOITEM,
    name: 'codigo',
    label: 'Código',
    fieldName: 'codigoBarras',
    className: '',
    width: '10%',
    readOnly: false,
    dataEditor: SheetTextEditor,
  },
  {
    id: COL_NOMBREITEM,
    name: 'descripcion',
    label: 'Descripción',
    fieldName: 'descripcion',
    fieldExpresion: 'descripcion',
    className: '',
    width: '25%',
    readOnly: false,
    dataEditor: SheetDescripcionItemSelectEditor,
  },
  {
    id: COL_IMPUESTO,
    name: 'impuesto',
    label: 'Imp.',
    fieldName: 'impuesto',
    fieldExpresion: 'impuesto',
    className: '',
    width: '70px',
    readOnly: true,
  }, {
    id: COL_CANTIDAD,
    name: 'cantidad',
    label: 'Cantidad',
    fieldName: 'cantidad',
    className: '',
    width: '60px',
    readOnly: false,
    dataEditor: SheetNumberEditor,
  },
  {
    id: COL_PORCENTAJEDESCUENTO,
    name: 'descuentoPorcentaje',
    label: 'Desc. %',
    fieldName: 'descuentoPorcentaje',
    className: '',
    width: '70px',
    readOnly: false,
    dataEditor: SheetNumberEditor,
  },
  {
    id: COL_PRECIOUNITARIO,
    name: 'precioUnitario',
    label: 'P. Unit.',
    fieldName: 'pvp',
    className: '',
    width: '70px',
    readOnly: false,
    dataEditor: SheetNumberEditor,
  },
  {
    id: COL_PRECIOUNITARIOCONIVA,
    name: 'precioUnitarioIva',
    label: 'Unit. IVA',
    fieldName: 'pvpiva',
    className: '',
    width: '70px',
    readOnly: false,
    dataEditor: SheetNumberEditor,
  },
  {
    id: COL_SUBTOTALLINEA,
    name: 'subtotalLinea',
    label: 'Subtotal',
    fieldName: 'pvpiva',
    className: '',
    width: '70px',
    readOnly: true,
  },
]

export type DetalleValidationResult = {
  resultado: boolean,
  error?: string
}

export type TFacturaIngresarEstablecimiento = {
  punto_emision: string,
  codigo: string,
  direccion: string
}

export type TFacturaIngresarEmisor = {
  ruc: string,
  razon_social: string,
  nombre_comercial: string,
  contribuyente_especial: string,
  direccion: string,
  obligado_contabilidad: boolean,
  establecimiento: TFacturaIngresarEstablecimiento,

}



export type TFaturaIngresarComprador = {
  tipo_identificacion: string,
  identificacion: string,
  nombres: string,
  razon_social: string,
  direccion: string,
  email: string,
  telefono: string,
  ciudad: string
}


export type TNotaCreditoIngresarComprador = TFaturaIngresarComprador & {
  nombre_comercial: string
}

export type TFacturaIngresarInformacionAdicional = {
  Cliente: string,
  Enviado_a: string,
  Direccion: string,
  Telefono: string
}

export type TFacturaIngresarImpuesto = {
  codigo: number,
  codigo_porcentaje: string,
  descuento_adicional: number,
  base_imponible: number,
  valor: number
}

export type TFacturaIngresarImpuestoDetalle = {
  codigo: number,
  tarifa: string,
  codigo_porcentaje: number,
  base_imponible: number,
  valor: number
}


export type TFacturaIngresarTotales = {
  total_sin_impuestos: number,
  importe_total: number,
  propina: number,
  impuestos: Array<TFacturaIngresarImpuesto>,
  descuento: number,
  descuento_adicional: number
}

export type TFacturaIngresarFormaPago = {
  value: number,
  label: string
}

export type TFacturaIngresarFormaPago2 = {
  total: number,
  medio: string,
  id_medio: string
}

export type TFacturaIngresarDetalle = {
  codigo_auxiliar: string,
  impuestos: Array<TFacturaIngresarImpuestoDetalle>,
  precio_unitario: number,
  cantidad: number,
  precio_total_sin_impuestos: number,
  descripcion: string,
  descuento: number,
  detalles_adicionales: any,
  codigo_principal: string
}

export type TFacturaIngresarCuotasCredito = {
  cuota: number,
  fechaVencimiento: string,
  saldo: number,
  interes: number,
  saldoCapital: number,
  letra: string
}

export type TFacturaIngresar = {
  identificador: string,
  usuario: string,
  tipodoc: string,
  tipo_emision: string,
  local: string,
  pventa: string,
  fecha_emision: string,
  emisor: TFacturaIngresarEmisor,
  comprador: TFaturaIngresarComprador,
  informacion_adicional: TFacturaIngresarInformacionAdicional,
  totales: TFacturaIngresarTotales,
  observaciones: string,
  moneda: string,
  formaPago: TFacturaIngresarFormaPago,
  ambiente: string,
  items: Array<TFacturaIngresarDetalle>,
  cuotas: Array<TFacturaIngresarCuotasCredito>,
  pagos: Array<TFacturaIngresarFormaPago2>
}

export type TIngresarNotaCreditoImpuestoTotal = {
  totalImpuesto: Array<{
    baseImponible: number,
    codigoPorcentaje: number,
    valor: number
  }>
}



export type FacturacionEdicionPayload<T> = {
  key: number | string,
  data: T
}

export type FacturaDatosEdicion = {
  identificadorTransaccion: string,
  numero: number,
  cliente: Cliente | null,
  fecha: string,
  detalles: Array<DetalleVentaRedux>,
  formaPago: Array<FormaPagoModuloVentas>,
  formasPago: Array<FormaPagoModuloVentas>,
  observaciones: string,
  establecimiento: string,
  puntoEmision: string,
  numerofactura: string,
  autorizacion: string,
  fechaAutorizacion: string,
  claveAcceso: string,
  tieneError: boolean,
  mensajeError: string,
  loader: LoaderInfo,
  iva: number,
  totales: TTotalesFactura,
  descuentoGlobal: number,
  descuentoPorItemPorcentaje: number,
  descuentoPorItemValor: number,
  mostrarEdicionCliente: boolean,
  codigoBarrasItemEditar: string,
  mostrarEdicionItems: boolean,
  mostrarBusquedaItems: boolean,
  autorizacionVenta?: AutorizacionDocumentoVenta,
  imprimir: boolean,
  loading: boolean,
  documentoOrigen?: DocumentoOrigenFacturacion,
  motivoNotaCredito: string,
  cuotas: Array<CuotaCreditoVenta>,
  desdePlantilla: boolean
}


export type DocumentoOrigenFacturacion = {
  tipoDoc: string,
  numero: string,
  comprobante: number,
  fechaEmision: string,
  totalDocumento: number,
  formaPagoCodigo: number
}


export type UpdateDatosEdicion = {
  key: keyof FacturaDatosEdicion,
  value: any,
  tabId: number
}

export type TTotalesFactura = {
  subtotal0: number;
  subtotalIva: number;
  subtotal: number;
  iva: number;
  ice: number;
  total: number;
  descuento: number;
  descuentoGloabal: number;
}


// export type VentasState = WindowVentasState<VentaInfo, FacturaDatosEdicion> & {
//   configuraciones: ConfiguracionesVentas
// }