import { combineReducers } from "@reduxjs/toolkit";
import { MovimientosBancosState } from "./types";
import { IngresosNCReducer } from '../ingresosNC/store/IngresosReducers';
import { EgresosNDReducer } from '../egresosND/store/EgresosReducers';
import { ProtestosClientesReducer } from "../protestosClientes/store/protestosClientesReducers";
import { ProtestosProveedoresReducer } from "../protestosProveedores/store/protestosProveedoresReducers";

export const MovimientoBancosReducer = combineReducers<MovimientosBancosState>({
  ingresosNC: IngresosNCReducer,
  egresosND: EgresosNDReducer,
  protestoClientes: ProtestosClientesReducer,
  protestosProveedores: ProtestosProveedoresReducer
})
