import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DesarrolloState } from "./types";

const initialState: DesarrolloState = {
  modulo: 'desarrollador',
  currentAction: "",
  currentFunction: "",
  currentTab: 0, // 0,1,2
  currentSubTab: 0,
  datosUsuario: null
}

const desarrolloSlice = createSlice({
  name: "desarrollador",
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setCurrentTab(state, action: PayloadAction<number>) {
      state.currentTab = action.payload
    },
    setCurrentSubTab(state, action: PayloadAction<number>) {
      state.currentSubTab = action.payload
    },
    setDataUsuario(state, action: PayloadAction<any>) {
      state.datosUsuario = action.payload
    },
  }
})
export const {
  setCurrentAccion,
  setCurrentFunction,
  setDataUsuario,
  setCurrentTab,
  setCurrentSubTab
} = desarrolloSlice.actions;
export const DesarrolloReducer = desarrolloSlice.reducer;

