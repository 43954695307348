import { CompraDetalleInfo } from "../../../types/types";
import { DetalleCompra, DetalleCompraRedux } from "../types/detalleCompra";

export const CompraHelper = {
    detallesToDetallesCompra
}

export function detallesToDetallesCompra(detalles: Array<CompraDetalleInfo>): Array<DetalleCompraRedux> {
    return detalles.map(det => {
        const detalle: DetalleCompraRedux = {
            codigo: det.codigo,
            codigoLocal: det.localCodigo,
            codigoInterno: det.itemCodigo.toString(),
            codigoBarras: det.itemBarras,
            descripcion: det.itemDescripcion,
            porcentajeiva: det.porcentajeIva / 100,
            _cantidad: det.cantidad,
            _precioUnitario: det.precioUnitario,
            tieneIva: det.porcentajeIva > 0,
            imagenes: [],// det.imagenes,
            _descuento: 0,
            _iva: 0,
            _porcentajeDescuento: det.descuento,
            _precioIva: 0,
            _subtotal: 0,
            _subtotal0: 0,
            _subtotalIva: 0,
            _total: 0,
            fecha: ''
        }
        const _detalle: DetalleCompra = new DetalleCompra();
        _detalle.setDetalle(detalle);

        // const detalle = new DetalleVenta();
        // detalle.setData(det.codigo, det.codigoint, det.barras, det.descripcion, det.porcentajeiva);
        // detalle.cantidad = det.cantidad;
        // detalle.precio = det.pvd;
        // detalle.imagenes = det.imagenes;
        return _detalle.getDetalle();
    })
    //return [];
}