import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoaderInfo } from '../../../../../../store/types';
import { DetalleVentaRedux } from '../../../../../ventas/pages/ventas/types/detalleVenta';
import { FacturacionEdicionPayload, FacturaDatosEdicion, TTotalesFactura } from '../../../../../ventas/pages/ventas/types/types';
import { CustomDictionary } from '../../../../../ventas/types/generics';
import { Cliente, CuotaCreditoVenta, FormaPagoModuloVentas } from '../../../../../ventas/types/types';
const initialState: CustomDictionary<FacturaDatosEdicion> = {

}
export const ventasClearDatosEdicion = (state, action) => {
  delete state[action.payload];
};
const datosEdicionModalVentasSlice = createSlice({
  name: 'modalVentasDatosEdicion',
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(state, action: PayloadAction<FacturacionEdicionPayload<FacturaDatosEdicion>>) {
      state[action.payload.key] = action.payload.data;
    },
    updateTotales(state, action: PayloadAction<{ key: number | string, totales: TTotalesFactura }>) {
      state[action.payload.key].totales = action.payload.totales;
      state[action.payload.key].descuentoGlobal = action.payload.totales.descuentoGloabal;
    },
    updateDetalles(state, action: PayloadAction<{ key: number | string, detalles: Array<DetalleVentaRedux> }>) {
      state[action.payload.key].detalles = action.payload.detalles;
    },
    addDetalle(state, action: PayloadAction<{ key: number | string, detalle: DetalleVentaRedux }>) {
      state[action.payload.key].detalles.push(action.payload.detalle);
    },
    updateCliente(state, action: PayloadAction<{ key: number | string, cliente: Cliente | null, formasPago: Array<FormaPagoModuloVentas>, formaPago: Array<FormaPagoModuloVentas>, detalles?: Array<DetalleVentaRedux> }>) {
      state[action.payload.key].cliente = action.payload.cliente;
      state[action.payload.key].formasPago = action.payload.formasPago;
      state[action.payload.key].formaPago = action.payload.formaPago;
      // if (state[action.payload.key].formaPago.length > 0) {
      //   const formaPago = action.payload.formasPago.find(x => x.codigo === state[action.payload.key].formaPago[0].codigo);
      //   state[action.payload.key].formaPago = formaPago ? [formaPago] : action.payload.formasPago.filter(x => x.descripcion === "EFECTIVO");
      // } else {
      //   state[action.payload.key].formaPago = action.payload.formasPago.filter(x => x.descripcion === "EFECTIVO");
      // }
      if (action.payload.detalles) state[action.payload.key].detalles = action.payload.detalles;

    },
    updateClienteOnly(state, action: PayloadAction<{ key: number | string, cliente: Cliente }>) {
      state[action.payload.key].cliente = action.payload.cliente;
    },
    updateObservaciones(state, action: PayloadAction<{ key: number | string, observaciones: string }>) {
      state[action.payload.key].observaciones = action.payload.observaciones;
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(state, action: PayloadAction<FacturacionEdicionPayload<FacturaDatosEdicion>>) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data;
      }
    },
    setEditLoader(state, action: PayloadAction<{ key: number | string, info: LoaderInfo }>) {
      state[action.payload.key].loader = action.payload.info;
    },
    setMuestraError(state, action: PayloadAction<{ key: number | string, tieneError: boolean, mensajeError: string }>) {
      state[action.payload.key].tieneError = action.payload.tieneError;
      state[action.payload.key].mensajeError = action.payload.mensajeError;
    },
    setImprimir(state, action: PayloadAction<{ key: number | string, imprimir: boolean }>) {
      state[action.payload.key].imprimir = action.payload.imprimir;
    },
    setFormaPago(state, action: PayloadAction<{ key: number | string, formaPago?: FormaPagoModuloVentas }>) {
      const { formaPago, key } = action.payload;
      state[key].formaPago = formaPago ? [formaPago] : [];
      if (formaPago && formaPago.descripcion !== "CREDITO") {//Limpiar cuotas
        state[key].cuotas = []
      }
    },
    setCuotasCredito(state, action: PayloadAction<{ key: number | string, cuotas: Array<CuotaCreditoVenta> }>) {
      const { key, cuotas } = action.payload;
      state[key].cuotas = cuotas;
    },
    setMostrarBusquedaItems(state, action: PayloadAction<{ key: string, mostrarBusquedaItems: boolean }>) {
      const { key, mostrarBusquedaItems } = action.payload;
      state[key].mostrarBusquedaItems = mostrarBusquedaItems;
    }
  },
})


export const { initDatosEdicion, setDatosEdicion, updateTotales, updateDetalles,
  updateCliente, updateObservaciones, setEditLoader, ventasClearDatosEdicion: clearDatosEdicion,
  setMuestraError, addDetalle, setImprimir, setFormaPago, setCuotasCredito, setMostrarBusquedaItems, updateClienteOnly } = datosEdicionModalVentasSlice.actions;
export const datosModalEdicionReducer = datosEdicionModalVentasSlice.reducer;