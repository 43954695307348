import { combineReducers } from "@reduxjs/toolkit";
import { ComandasState } from "../types/types";
import { menuComandasReducer } from "./menuComandasReducer";
import { datosEdicionComandaReducer } from "./editDataReducer";
import { tabsReducer } from "./tabsReducer";
import { configuracionesComandaReducer } from "./configuracionesComandaReducer";
import { searchComandaReducer } from "./searchComandaReducer";
import { modalVentasReducer } from "../components/factura/store/modalVentasReducer";

export const comandasReducer = combineReducers<ComandasState>({
  menu: menuComandasReducer,
  tabs: tabsReducer,
  search: searchComandaReducer,
  editData: datosEdicionComandaReducer,
  configuraciones: configuracionesComandaReducer,
  facturacion: modalVentasReducer
});