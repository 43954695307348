import Lookup from 'devextreme-react/lookup';
import { DropDownOptions } from 'devextreme-react/select-box';
import * as React from 'react';
import { InventarioService } from '../../services/inventario.service';
import { UnidadInventario } from '../../store/types';

interface IBuscarUnidadLookUpProps {
  selected?: number,
  onChanged: (newValue: number | undefined) => void,
  allowEdit?: boolean,
  allowClear?: boolean,
  allowAdd?: boolean
}

export const BuscarUnidadLookUp: React.FC<IBuscarUnidadLookUpProps> = (props) => {
  const { selected, onChanged } = props;
  const [listado, setListado] = React.useState<Array<UnidadInventario>>()

  const getDisplayExpr = React.useCallback((unidad: UnidadInventario) => {
    return unidad ? unidad.nombre : '';
  }, []);

  React.useEffect(() => {
    const cargarUnidad = async () => {
      const unidad = await InventarioService.getUnidades();
      setListado(unidad);
    }
    cargarUnidad();
  }, [])

  return (
    <Lookup
      dataSource={listado}
      showDataBeforeSearch
      value={selected}
      minSearchLength={3}
      searchTimeout={500}
      displayExpr={getDisplayExpr}
      searchExpr={['nombre']}
      pageLoadMode='scrollBottom'
      showClearButton={true}
      valueExpr='codigo'
      onValueChanged={({ value }) => {
        onChanged(value);
      }}
    >
      <DropDownOptions
        tabIndex={3000}
        closeOnOutsideClick={true}
        showTitle={false}
      />
      {props.children}
    </Lookup>
  )
};
