import { CCol, CFormGroup, CLabel, CRow } from '@coreui/react';
import * as React from 'react';
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { CuadresService } from '../../../../../services/cuadres.service';
import { DateUtils, formatoFechasApi } from '../../../../../../../../../helpers/dateUtils';
import {
  updateDpCierre8Only, updateDptotales8Only, updateDptotalesOnly,
  updateTotal8_tiOnly
} from '../../../store/editDataReducer';
import DataGrid, {
  Column, HeaderFilter, Paging, Pager,
  Grouping,
  GroupPanel, ColumnChooser
} from 'devextreme-react/data-grid';
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../../../views/componentes/colContainer';
import { utilidades } from '../../../../../../../../../helpers/utilidades';
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize';
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper';
import { AbonosCuadresCajaListado, ccDetalle, formasPagoCuadre } from '../../../../../types/types';
import TabFormasPago from '../componentes/tabFormasPago/tabFormasPago';

interface ICobrosCarteraProps {
  setToast: (texto, type) => void,
  playLoader: () => void,
  stopLoader: () => void,
  onErrorConfirm: (sms, title) => void,
  onConfirmarRegistro: (sms, method) => void
  tabId: string,
}

const CobrosCartera: React.FunctionComponent<ICobrosCarteraProps> = (props) => {
  const {
    tabId, setToast, playLoader, stopLoader,
    onErrorConfirm, onConfirmarRegistro
  } = props;
  const dataGrid = React.useRef<any>();

  const dispatch = useDispatch();
  const [heigth] = useGridResultadosSize();

  const local = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].local });
  const puntoVenta = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].puntoVenta });
  const fechaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fechaCierre });
  const venInicial8 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial8 });
  const venFinal8 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal8 });
  const generarCuadre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre });
  const total8_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].total8_ti });
  const dptotales8 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales8 });
  const dpfpagos = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpfpagos });
  const dptotales = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales });
  const dpcierre8 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre8 });

  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [showPageSizeSelector,] = React.useState<boolean>(true);

  const resultadoCuadresAbonos = React.useCallback(async (dpCierreFacturasVar: Array<AbonosCuadresCajaListado> | []) => {
    console.log("resultadoCuadresAbonos")
    console.log("dpCierreFacturasVar", dpCierreFacturasVar)
    console.log("dpfpagos", dpfpagos);

    let detalle = {
      ccdCodigo: 0,
      ccaCodigo: "",
      subValor: "",
      ccdValor: 0,
      subCodigo: 0,
    };

    let valor: number = 0;
    const dptotales8Local: Array<formasPagoCuadre> = [];
    if (dpCierreFacturasVar.length === 0) {
      for (const fpg of dpfpagos) {
        dptotales8Local.push({
          subCodigo: Number(fpg.codigo) ?? -1,
          subValor: String(fpg.descripcion) ?? "",
          ccdValor: valor,
        });
      }

      console.log("dptotales8Local", dptotales8Local)
      dispatch(updateDptotales8Only({
        dptotales8: dptotales8Local,
        key: tabId
      }));

    } else {

      let i = 0;

      for (let j = 0; j <= dpfpagos.length - 1; j++) {
        valor = 0;
        for (i = 0; i < dpCierreFacturasVar.length; i++) {
          if (dpCierreFacturasVar[i].abonoVentaTipoPago === dpfpagos[j].codigo) {
            valor = valor + Number(Number(dpCierreFacturasVar[i].abonoVentaValor).toFixed(2));
          }
        }

        dptotales8Local.push({
          subCodigo: Number(dpfpagos[j].codigo),
          subValor: String(dpfpagos[j].descripcion),
          ccdValor: Number(Number(valor).toFixed(2)),
        });

      }

      console.log("dptotales8Local", dptotales8Local)
      dispatch(updateDptotales8Only({
        dptotales8: dptotales8Local,
        key: tabId
      }));

      let k = 0;

      let total: number = 0;
      for (k = 0; k < dptotales8Local.length; k++) {
        total = total + dptotales8Local[k].ccdValor;
      }
      total = Number(Number(total).toFixed(2));
      dispatch(updateTotal8_tiOnly({
        total8_ti: total,
        key: tabId
      }));

      let dptotalesLocal: any = [];
      console.log("dptotales8Local ", dptotales8Local);
      if (dptotales8Local.length > 0) {
        for (i = 0; i < dpfpagos.length; i++) {
          let x: ccDetalle | null = null;
          try {
            x = dptotales[i];
          } catch (ex) {
            x = null;
          }
          if (x == null) {
            let addDetallTotales: ccDetalle = {
              ...detalle,
              ccdValor: Number(Number(dptotales8Local[i].ccdValor).toFixed(2)),
              subCodigo: dpfpagos[i].codigo,
              subValor: dpfpagos[i].descripcion,
            }
            dptotalesLocal.push({ ...addDetallTotales });
          } else {
            let y: number = Number((x.ccdValor + Number(dptotales8Local[i].ccdValor)).toFixed(2));
            x.ccdValor = y;
            dptotalesLocal[i] = x;
          }

        }

        dispatch(updateDptotalesOnly({
          dptotales: dptotalesLocal,
          key: tabId
        }));

      }
    }


  }, [dispatch, tabId, dpfpagos, dptotales]);

  const getCuadresAbonos = React.useCallback(async () => {
    console.log("getCuadresAbonos");
    let fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi);
    let dpCierreRet = await CuadresService.getAbonosCuadres(fechaFilter, local?.codigo ?? -1, puntoVenta?.codigoPuntoVenta ?? -1, venInicial8, venFinal8);
    console.log("getCuadresAbonos dpCierreRet", dpCierreRet);

    dispatch(updateDpCierre8Only({
      dpcierre8: dpCierreRet,
      key: tabId
    }));

    await resultadoCuadresAbonos(dpCierreRet)
  }, [
    fechaCierre, tabId, dispatch, local, puntoVenta, venInicial8, venFinal8, resultadoCuadresAbonos
  ]);

  React.useEffect(() => {
    if (generarCuadre === true) {
      getCuadresAbonos();
    }
  }, [generarCuadre])

  return (
    <>
      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">


          <RowContainer >
            <CustomCol xs='12' md='12'   >

              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr='ventaCodigo'
                dataSource={dpcierre8}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode='widget'
                height={heigth}
                ref={dataGrid}
              >
                <GroupPanel visible={true} />
                <Grouping autoExpandAll={true} />
                <Paging defaultPageSize={20}/>
                <Pager
                  visible={dpcierre8.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(dpcierre8)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} mode="select" />
                <Column dataField='ingresoValor' caption='' width='20%' allowEditing={false} groupIndex={0} />
                <Column dataField='ventaFecha' caption='Fecha de Registro' width='20%' allowEditing={false} />
                <Column dataField='clienteNombre' caption='Cliente' width='20%' allowEditing={false} />
                <Column dataField='pagoVentaVencimiento' caption='Vencimiento' width='15%' allowEditing={false} />
                <Column dataField='abonoVentaFecha' caption='Fecha Abono' allowEditing={false} width={'15%'} />
                <Column dataField='ventaTipoDocumentoDescripcion' caption='Comprobante' width='15%' allowEditing={false} visible={false} />
                <Column dataField='puntoVentaHost' caption='POS' width='15%' allowEditing={false} />
                <Column dataField='vendedorCompleto' caption='Vendedor(AC)' width='20%' allowEditing={false} visible={false} />
                <Column dataField='ventaNumero' caption='Número' width='20%' allowEditing={false} visible={false} />
                <Column dataField='abonoVentaTipoPagoDescripcion' caption='Forma Pago' width='20%' allowEditing={false} visible={false} />
                <Column dataField='abonoVentaTipoPagoDocumentoDescripcion' caption='Doc.' width='20%' allowEditing={false} visible={false} />
                <Column dataField='pagoVentaCuota' caption='Cuota' width='20%' allowEditing={false} />
                <Column dataField='abonoVentaValor' caption='Abono' width='20%' allowEditing={false} />
                <Column dataField='pagoVentaSaldo' caption='Saldo' width='20%' allowEditing={false} />

              </DataGrid>

            </CustomCol>
          </RowContainer>

        </CCol>
      </CRow>

      <CRow >
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">


          <CCol lg="3" md="3" sm="12" className="d-flex justify-content-end  align-items-center">
            <TabFormasPago
              tabId={tabId}
              dptotales={dptotales8}
            />
          </CCol>

        </CCol>
      </CRow>


      <CRow >
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">

          <CCol lg='2' md="2" xs="12">
            <CFormGroup>
              <CLabel htmlFor="total" className="col-form-label-md">
                <strong> {"Total"} </strong>
              </CLabel>
              <CRow>
                <CCol md="12">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={total8_ti}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
              </CRow>
            </CFormGroup>
          </CCol>

        </CCol>
      </CRow>

    </>
  )
}
export default CobrosCartera;
