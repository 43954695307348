import * as React from 'react';
import { Cliente } from '../../types/types';
import { ClientesService } from '../../services/clientes.service';
import { CTooltip } from '@coreui/react';
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../views/componentes/colContainer';
import { ModificarCliente } from '../clientes/modificarCliente';
import config from '../../../../config/config';
import { SelectBox } from 'devextreme-react/select-box';
import Button from 'devextreme-react/button';



interface IBuscarClienteLookUpProps {
  selected: Cliente | null,
  onChanged: (newValue: Cliente | null) => void,
  allowEdit?: boolean,
  allowClear?: boolean,
  allowAdd?: boolean,
  disabled?: boolean,
  children?: React.ReactNode,
  onEnterKey?: any
}

const ClienteSearchExpresion = ['nombres', 'identificacion'];

const BuscarClienteLookUp: React.FC<IBuscarClienteLookUpProps> = (props) => {
  const { selected, onChanged, allowAdd, allowClear, allowEdit, disabled, onEnterKey } = props;
  const [clienteLocal, setClienteLocal] = React.useState<Cliente>();
  const [mostrarEdicion, setMostrarEdicion] = React.useState(false);
  const [clienteEditando, setClienteEditando] = React.useState<Cliente | null>(null);
  const selectRef = React.useRef<any>();

  //const [selectedInternal, setSelectedInternal] = React.useState<string>();
  //const ref = React.useRef<Lookup>(null);


  const datasource = React.useMemo(() => {
    return ClientesService.getClientesDatasource();
  }, []);

  const onLookUpValueChanged = React.useCallback(async ({ value, previousValue }) => {
    if (typeof (value) == 'string' || value?.codigo === clienteLocal?.codigo) return;
    setClienteLocal(value);
    //const cliente = await  datasource.store().byKey(value);
    await onChanged(value);
  }, [onChanged, clienteLocal]);

  const onModificarClienteChanged = React.useCallback(async (cliente: Cliente, isNew) => {
    setMostrarEdicion(false);
    setClienteEditando(null);
    if (isNew) {
      await onChanged(cliente);
      return;
    }
    if (selected && selected.codigo === cliente.codigo) {
      await datasource.reload();
      if (selectRef) {
        //const nuevo = await ClientesService.getCliente(cliente);
        await onChanged(cliente);
        //onChanged()
        //selectRef.current.instance.repaint();
      }
    } else {
      //const nuevo = await ClientesService.getCliente(cliente);
      await onChanged(cliente);
    }
    //onChanged(identificacion);
  }, [selected, datasource, onChanged]);

  const onModificarClienteCancel = React.useCallback(() => {
    setClienteEditando(null);
    setMostrarEdicion(false);
  }, []);

  const getClienteDisplayExpr = React.useCallback((cliente: Cliente) => {
    return cliente ? `${cliente.identificacion} - ${cliente.nombres}` : '';
  }, []);


  const onSelectBoxFocusIn = React.useCallback((e) => {
    e.element.querySelector("input.dx-texteditor-input").select();
  }, [])


  const onEditarClienteCLick = React.useCallback(() => {
    setClienteEditando(selected);
    setMostrarEdicion(true);
  }, [selected])

  const onCrearClienteCLick = React.useCallback(() => {
    setClienteEditando(null);
    setMostrarEdicion(true);
  }, [])

  const onQuitarClienteClick = React.useCallback(() => {
    onChanged(null);
  }, [onChanged])

  React.useEffect(() => {
    console.log('BuscarClienteLookUp cambio onChanged');
  }, [onChanged])


  React.useEffect(() => {
    setClienteLocal(selected ?? undefined)
  }, [selected])

  React.useEffect(() => {
    if (selectRef.current) {
      selectRef.current.instance.repaint();
      // datasource.reload().then(()=>{

      // });
    }
  }, [clienteLocal, datasource])

  if (allowAdd || allowEdit || allowClear) {
    return (
      <>
        <ModificarCliente identificacion={clienteEditando?.identificacion ?? ''}
          show={mostrarEdicion}
          onChanged={onModificarClienteChanged}
          onCancel={onModificarClienteCancel} />
        <RowContainer gutters={false} >
          <CustomCol xs='12' md='9'>
            <SelectBox
              ref={selectRef}
              dataSource={datasource}
              searchEnabled
              displayExpr={getClienteDisplayExpr}
              searchExpr={ClienteSearchExpresion}
              searchTimeout={500}
              value={clienteLocal}
              onValueChanged={onLookUpValueChanged}
              onFocusIn={onSelectBoxFocusIn}
              disabled={disabled}
              onEnterKey={onEnterKey}
            >
              {props.children}
            </SelectBox>
          </CustomCol>
          <CustomCol md='3' xs='6'  >
            <div style={{ display: 'flex' }}>
              {allowEdit && selected && selected.identificacion !== config.rucConsumidorFinal &&
                <CTooltip content='Editar Cliente' >
                  <div style={{ marginLeft: '1px' }} >
                    <Button
                      stylingMode='contained'
                      type='default'
                      icon='edit'
                      onClick={onEditarClienteCLick} />
                  </div>
                </CTooltip>
              }
              {!disabled && allowAdd &&
                <CTooltip content='Crear Cliente'>
                  <div style={{ marginLeft: '1px' }} >
                    <Button
                      stylingMode='contained'
                      type='default'
                      icon='add'
                      onClick={onCrearClienteCLick}
                      elementAttr={{
                        id: "btnAddClient"
                      }}
                    />
                  </div>
                </CTooltip>
              }
              {!disabled && allowClear && selected &&
                <CTooltip content='Quitar cliente seleccionado'>
                  <div style={{ marginLeft: '1px' }} >
                    <Button
                      stylingMode='contained'
                      type='danger'
                      icon='undo'
                      onClick={onQuitarClienteClick} />
                  </div>
                </CTooltip>
              }
            </div>
          </CustomCol>
        </RowContainer>
      </>
    );
  }

  return (
    <SelectBox
      ref={selectRef}
      dataSource={datasource}
      searchEnabled
      displayExpr={getClienteDisplayExpr}
      searchExpr={ClienteSearchExpresion}
      searchTimeout={500}
      //onEnterKey={(e) => { console.log(e) }}
      value={clienteLocal}
      onValueChanged={onLookUpValueChanged}
      disabled={disabled}
      onFocusIn={onSelectBoxFocusIn}
      onEnterKey={onEnterKey}
    >
      {props.children}
    </SelectBox>
  )
}
// function shallowEqual(object1, object2) {
//   const keys1 = Object.keys(object1);
//   const keys2 = Object.keys(object2);

//   if (keys1.length !== keys2.length) {
//     return false;
//   }

//   for (let key of keys1) {
//     if (object1[key] !== object2[key]) {
//       return false;
//     }
//   }

//   return true;
// }

// function areEqual(prevProps, nextProps) {
//   const equals = shallowEqual(prevProps, nextProps);
//   console.log('prevProps', prevProps)
//   console.log('nextProps', nextProps)
//   return equals;
//   /*
//   return true if passing nextProps to render would return
//   the same result as passing prevProps to render,
//   otherwise return false
//   */
// }

export default React.memo(BuscarClienteLookUp);