import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { CustomModalDevx } from '../modal/Modal';
import { ReportWithLocalData, IReportWithLocalDataProps } from './ReportWithLocalData'


export const ReportWithLocalDataModal: React.FC<IReportWithLocalDataProps & { show: boolean, onClose: () => void }> = (props) => {
  const { show, onClose } = props;

  const modalWidth = React.useMemo(() => {
    if (isMobile) {
      const valor: string = "100%";
      return valor;
    } else {
      return window.innerWidth - (window.innerWidth * 0.1);
    }
  }, []);

  const modalHeight = React.useMemo(() => {
    if (isMobile) {
      const valor: string = "100%";
      return valor;
    } else {
      return window.innerHeight - (window.innerHeight * 0.1);
    }
  }, [])

  if(!show){
    return null;
  }

  return (
    <CustomModalDevx
      show={show}
      onClose={onClose}
      title='Reporte'
      width={modalWidth}
      height={modalHeight}
    >
      <React.Fragment key={`reportWithLocalDataModalContent${props.template}`} >
          <ReportWithLocalData
            {...props}
            key='reportDesigner'
          >
          </ReportWithLocalData>
      </React.Fragment>
    </CustomModalDevx >
  );
};
