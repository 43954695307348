import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptionGrupos, Seleccionado } from "../../../../../store/types";
import { Proveedor } from "../../../store/types";
import {  ProveedoresState } from "./types";
const initialState: ProveedoresState = {
    modulo: 'proveedores',
    currentAction: "Buscar",
    registros: [],
    seleccionado: {
        index: null,
        row: null,
        selectedRow: null,
    },
    tipos: [],
    estados: []
}

const proveedoresSlice = createSlice({
    name: "proveedores",
    initialState: initialState,
    reducers: {
        setCurrentAccion(state, action: PayloadAction<any>) {
            state.currentAction = action.payload
        },
        setData(state, action: PayloadAction<Array<Proveedor>>) {
            state.registros = action.payload
        },
        setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
            let toAdd = {
                index: action.payload.index,
                row: action.payload.row,
                selectedRow: action.payload.selectedRow,
            }
            state.seleccionado = toAdd
        },
        setResetSeleccion(state) {
            let toAdd = {
                index: null,
                row: null,
                selectedRow: null,
            }
            state.seleccionado = toAdd
        },
        setTiposOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
            state.tipos = action.payload
        },
        setOptionsEstados(state, action: PayloadAction<Array<OptionGrupos>>) {
            state.estados = action.payload
        },
    }
})
export const {
    setOptionsEstados,
    setData,
    setCurrentAccion,
    setSeleccionarDato,
    setResetSeleccion,
    setTiposOptions
} = proveedoresSlice.actions;
export const ProveedoresReducer = proveedoresSlice.reducer;

