import { Cliente, FormaPagoModuloVentas, VentaInfo } from "../../../types/types"
import { DocumentoOrigenFacturacion } from "../../ventas/types/types"


export type RetencionDatosEdicion = {
  codigo:number,
  cliente: Cliente | null,
  fechaEmision: string,
  documentoOrigen?: DocumentoOrigenFacturacion,
  establecimiento: string,
  puntoEmision: string,
  numero: string,
  numeroAutorizacion: string,
  baseRenta: number,
  baseIva: number,
  formaPago?: FormaPagoModuloVentas,
  formasPago: Array<FormaPagoModuloVentas>,
  detalles: Array<DetalleRetencionRedux>,
  numeroLote: string,
  venta?: VentaInfo,
  loader: {
    show: boolean,
    mensaje: string,
  },
  error?: string,
  loading: boolean
}

export type DetalleRetencionRedux = {
  tipo: TiposRetencion,
  anioFiscal: number,
  baseImponible: number,
  concepto:ConceptoRetencion| null ,
  conceptoCodigo:string,
  porcentaje: number,
  valor: number,
  fecha:string,
}

export enum TiposRetencion {
  Fuente = 'Fuente',
  IVA = 'IVA',
  NoDefinido = 'NA'
}

export enum TiposPorcentajeRetencion {
  Unico ='Unico',
  Opciones = 'Opciones',
  Limite = 'Limite'
} 


export type RetencionventaListado = {
  codigo: number,
  autorizacion: string,
  establecimiento: string,
  puntoEmision: string,
  numero: string,
  numeroCompleto: string,
  clienteCodigo: number,
  clienteNombre: number,
  fecha: string,
  total: number,
  ventaCodigo: number,
  usuarioCodigo: number,
  formaPagoCodigo: number,
  formaPago: string,
  estadoCodigo: number,
  estado: string,
  local: string,
  loteTC: string,
  ventaNumero: string
}

export type RetencionventaListadoConFechaTransaccion = RetencionventaListado & {
  fechaTransaccion:Date
}

export type ConceptoRetencion = {
  codigo: string,
  concepto: string,
  porcentaje: number,
  estado: boolean,
  tipo: TiposRetencion,
  tipoPorcentaje:TiposPorcentajeRetencion,
  opciones?: Array<number>,
  rango?:{
    inferior:number,
    superior:number
  } 
}