import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../store/store";
import { Autorizacion } from "../../../../../../store/types";
import { ConfiguracionesModalVentas } from "../types/modalVentasTypes";

const initialState: ConfiguracionesModalVentas = {
  usaDescuentoGlobal: false,
  autorizacion: null,
}

const configuracionesModaVentaSlice = createSlice(
  {
    name: 'configuraciones',
    initialState: initialState,
    reducers: {
      initialize(state, acion: PayloadAction<Autorizacion>) {
        state.autorizacion = acion.payload;
      },
      toogleUsaDescuentoGlobal(state, action: PayloadAction) {
        state.usaDescuentoGlobal = !state.usaDescuentoGlobal;
      },
    }
  }
)


export const selectUsaDescuentoGlobal = (state: RootState) => { return state.restaurante.comandas.configuraciones.usaDescuentoGlobal };
export const configuracionesModalVentasReducer = configuracionesModaVentaSlice.reducer;
export const { toogleUsaDescuentoGlobal } = configuracionesModaVentaSlice.actions;