import { SesionService } from "../../../../../services/sesion.service";
import { ItemVenta } from "../../../types/types";
import { DetalleValidationResult } from "./types";




export class DetalleVentaTable {
  private _iva: number;
  private _subtotal: number;
  private _subtotal0: number;
  private _subtotalIva: number;
  private _total: number;
  private _cantidad: number = 0;
  private _precioUnitario: number = 0;
  private _precioIva: number = 0;
  private _item: ItemVenta | null = null;
  private _porcentajeDescuento: number = 0;
  private _descuento: number = 0;
  codigo: number;
  codigoInterno: string;
  codigoBarras: string;
  descripcion: string;
  tieneIva: boolean;
  porcentajeiva: number;
  imagenes: Array<string>;
  puedeEditar: boolean;
  valida: any;
  feedback: any;

  get item(): ItemVenta | null {
    return this._item;
  }

  //#region cantidad
  get cantidad(): number {
    return this._cantidad;
  }
  set cantidad(newCantidad: number) {
    if (newCantidad < 0) {
      throw new Error("la cantidad no puede ser menor a cero");
    }
    this._cantidad = newCantidad;
    this.calcular();
  }
  //#endregion


  //#region precio
  get precio(): number {
    return this._precioUnitario;
  }
  set precio(newPrecio: number) {
    if (newPrecio < 0) {
      throw new Error("el precio no puede ser menor a cero");
    }
    this._precioUnitario = newPrecio;
    this.calcular();
  }
  //#endregion

  get subtotal(): number {
    return this._subtotal;
  }

  get subtotal0(): number {
    return this._subtotal0;
  }

  get subtotalIVA(): number {
    return this._subtotalIva;
  }

  get iva(): number {
    return this._iva;
  }

  get total(): number {
    return this._total;
  }


  get precioIva(): number {
    return this._precioIva;
  }


  get descuento(): number {
    return this._descuento;
  }


  get porcentajeDescuento(): number {
    return this._porcentajeDescuento;
  }
  set porcentajeDescuento(newValue: number) {
    this._porcentajeDescuento = newValue;
    this.calcular();
  }

  constructor(puedeEditar: boolean) {
    this.codigo = 0;
    this.codigoInterno = '';
    this.codigoBarras = '';
    this.descripcion = '';
    this.tieneIva = false;
    this._iva = 0;
    this._subtotal0 = 0;
    this._subtotal = 0;
    this._total = 0;
    this._subtotalIva = 0;
    this.porcentajeiva = 0;
    this.imagenes = [];
    this.puedeEditar = puedeEditar;
    this.valida = {};
    this.feedback = {};
  }

  public calcular() {
    this._subtotal0 = this.tieneIva ? 0 : this.cantidad * this._precioUnitario;
    this._subtotalIva = this.tieneIva ? this.cantidad * this._precioUnitario : 0;
    this._descuento = (this._subtotal0 + this.subtotalIVA) * (this.porcentajeDescuento / 100.0);
    this._iva = (this._subtotalIva - (this._subtotalIva * (this.porcentajeDescuento / 100.0))) * this.porcentajeiva;
    this._subtotal = (this._subtotal0 + this._subtotalIva - this._descuento);
    this._total = this.subtotal + this._iva;
    this._precioIva = this.precio * (this.tieneIva ? (1 + this.porcentajeiva) : 1)
  }

  public makeObjectValid() {
    this.valida = {};
    this.feedback = {};
    this.valida.descripcion = true;
    this.valida.cantidad = true;
    this.valida.precio = true;
  }

  public validate() {
    this.valida = {};
    this.feedback = {};
    if (this.descripcion == null || this.descripcion.length === 0) {
      this.valida.descripcion = false;
      this.feedback.descripcion = 'Debe de ingresar la descripción del producto';
    } else {
      this.valida.descripcion = true;
    }

    if (isNaN(this.cantidad)) {
      this.valida.cantidad = false;
      this.feedback.cantidad = 'Debe de ingresar la cantidad del producto';
    } else if (this.cantidad <= 0) {
      this.valida.cantidad = false;
      this.feedback.cantidad = 'La cantidad debe de ser mayor a cero';
    } else {
      this.valida.cantidad = true;
    }


    if (isNaN(this.precio)) {
      this.valida.precio = false;
      this.feedback.precio = 'Debe de ingresar el precio del producto';
    } else if (this.precio <= 0) {
      this.valida.precio = false;
      this.feedback.precio = 'El precio debe de ser mayor a cero';
    }
    else if (this.superaDescuentoMaximo()) {
      this.valida.precio = false;
      this.feedback.precio = 'El precio es inferior al descuento máximo autorizado para la empresa y local';
    } else {
      this.valida.precio = true;
    }

  }



  private superaDescuentoMaximo(): boolean {
    if (this.item == null || this.item === undefined) {
      return false;
    }

    const sesion = SesionService.getCurrentSesion();
    const descuentoMaximo: number = sesion.local.descuento > 0 ?
      sesion.local.descuento
      :
      sesion.empresa.descuento;
    const precioMinimo = this.item.pvp - (this.item.pvp * (descuentoMaximo / 100.0));
    if (this.precio < precioMinimo) {
      return true;
    } else
      return false;
  }

  public establecerItem(item: ItemVenta) {
    this.codigo = item.codigo;
    this.codigoInterno = item.codigoInterno;
    this.codigoBarras = item.codigoBarras;
    this.descripcion = item.descripcion;
    this.tieneIva = item.porcentajeIva > 0;
    this._iva = 0;
    this._subtotal0 = 0;
    this._subtotal = 0;
    this._total = 0;
    this._subtotalIva = 0;
    this.porcentajeiva = (item.porcentajeIva / 100);
    this.imagenes = [];
    this._item = item;
  }

}