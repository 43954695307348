import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { addToast } from '../../../../store/toasterReducer';
import { TiposComprobantesSri, ToastTypes } from '../../../../store/types';
import ModalLoader from '../../../../views/componentes/modalLoader/modalLoader';
import { PdfVisualizer } from '../../../../views/componentes/pdfVisualizer/pdfVisualizer';
import { ReportWithLocalDataModal } from '../../../../views/componentes/xtrareports/ReportWithLocalDataModal';
import { VentasHelper } from '../../pages/helper/helper';
import { RideService } from '../../services/ride.service';
import { VentasService } from '../../services/ventas.service';

interface IVerFacturaProps {
  claveAcceso: string,
  onClose: () => void,
  modo: 'DevExpressReport' | 'Legacy',
  vista?: 'Viewer' | 'Designer'
}

export function VerRIDE(props: IVerFacturaProps) {
  const { modo, claveAcceso, onClose, vista } = props;
  const [loading, setLoading] = React.useState(true);
  const [paths, setPaths] = React.useState<{
    pdf: string, xml: string, url: string, urlBlobPdf: string
  }>();
  const [error, setError] = React.useState('');
  const dispatch = useDispatch();
  const [reportData, setReportData] = React.useState<any>();
  const [ivaComprobante, setIvaComprobante] = React.useState<any>(0);

  const [templateName, setTemplateName] = React.useState('RideFactura');
  const empresa = useSelector((state: RootState) => { return state.global.session?.empresa });

  const cargarPdfLegacy = React.useCallback(async () => {
    try {
      const path = await VentasService.obtenerRutasDocumentoElectornico(claveAcceso);
      const blob = await VentasService.obtenerPdfFactura(claveAcceso);
      setPaths({
        pdf: path.pdf,
        url: path.url,
        xml: path.xml,
        urlBlobPdf: window.URL.createObjectURL(blob)
      });
    } catch (error) {
      setError(JSON.stringify(error));
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }, [claveAcceso])


  const loadReportData = React.useCallback(async () => {
    const voucherType = VentasHelper.getVoucherTypeFromAccesKey(claveAcceso);
    if (voucherType === undefined) {
      dispatch(addToast({
        autoHide: 3500,
        content: 'No se pudo encontrar un tipo de documento válido en la clave de acceso ' + claveAcceso,
        type: ToastTypes.Warning
      }))
      onClose();
      return;
    }
    try {
      let data: any;
      let iva: any = 0;
      switch (voucherType) {
        case TiposComprobantesSri.NotaCredito:
          setTemplateName('RideNotaCredito');
          data = await RideService.getRideData(claveAcceso);
          iva = await VentasHelper.comprobanteIva(data, TiposComprobantesSri.NotaCredito);
          data.procentajeIva = iva;
          break;
        case TiposComprobantesSri.NotaDebito:
          setTemplateName('RideNotaDebito');
          data = await RideService.getRideData(claveAcceso);
          iva = await VentasHelper.comprobanteIva(data, TiposComprobantesSri.NotaDebito);
          data.procentajeIva = iva;
          break;
        case TiposComprobantesSri.Factura:
          data = await RideService.getRideData(claveAcceso);
          iva = await VentasHelper.comprobanteIva(data, TiposComprobantesSri.Factura);
          data.procentajeIva = iva;
          break;
        case TiposComprobantesSri.Retencion:
          setTemplateName('RideRetencion');
          data = await RideService.getRideData(claveAcceso);
          data.procentajeIva = 0;
          break;
        case TiposComprobantesSri.GuiaRemision:
          setTemplateName('RideGuiaRemision');
          data = await RideService.getRideData(claveAcceso);
          data.procentajeIva = 0;
          break;
        case TiposComprobantesSri.LiquidacionCompra:
          setTemplateName('RideLiquidacionCompra');
          data = await RideService.getRideData(claveAcceso);
          iva = await VentasHelper.comprobanteIva(data, TiposComprobantesSri.LiquidacionCompra);
          data.procentajeIva = iva;
          break;
        default:
          break;
      }
      setReportData(data);
      setIvaComprobante(iva);
    } catch (error) {
      setError(JSON.stringify(error));
    }
    finally {
      setLoading(false);
    }
  }, [claveAcceso, onClose, dispatch])


  React.useEffect(() => {
    if (modo === 'Legacy') {
      cargarPdfLegacy();
    } else {
      loadReportData();
    }
  }, [modo, cargarPdfLegacy, loadReportData]);

  React.useEffect(() => {
    if (error && error.length > 0) {
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al descargar el archivo pdf ' + error,
        fade: true,
        title: 'Descargar Pdf',
        type: ToastTypes.Warning

      }))
      onClose();
    }
  }, [error, onClose, dispatch])

  if (modo === 'Legacy') {
    return (
      <>
        {loading &&
          <ModalLoader show={true} message='Descargando documento...' />
        }
        {!loading &&
          <PdfVisualizer
            fileName={`${props.claveAcceso}`}
            urlPdf={paths?.pdf ?? ''}
            urlXml={paths?.xml ?? ''}
            urlPrint={paths?.url ?? ''}
            urlBlobPdf={paths?.urlBlobPdf ?? ''}
            onClose={props.onClose} />
        }

      </>
    );
  }
  return (
    <React.Fragment key='RideDocumentoElectronico'>
      {loading &&
        <ModalLoader show={true} message='Descargando documento...' />
      }
      <ReportWithLocalDataModal
        show={!loading}
        onClose={onClose}
        data={[reportData]}
        fileName={templateName}
        mode={vista ?? 'Viewer'}
        parameters={{
          leyendaFacturas: empresa?.leyendaComprobantesElctronicos ?? "",
          PorcentajeIVA: ivaComprobante ?? 0
        }}
        template={templateName}
        key='reportDesigner'
      />
    </React.Fragment>
  )
}
