import { useEffect, useState } from "react"
import { isMobile } from "react-device-detect";

export const useGridResultadosSize = () => {
  const [size, setSize] = useState<{ width: number, heigth: number }>({ width: window.innerWidth, heigth: window.innerHeight });

  const getGridResultadoBusquedaHeigth = () => {
    if (isMobile) {
      if (window.innerWidth < 400) {
        return window.innerHeight - 320;
      } else {
        return window.innerHeight - 260;
      }
    } else {
      return undefined;
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, heigth: window.innerHeight })
    }
    window.addEventListener('resize', handleResize);
    return window.removeEventListener('resize', handleResize);
  }, [])

  return (
    [undefined, getGridResultadoBusquedaHeigth()]
  )
}