import { combineReducers,  } from "@reduxjs/toolkit";
import { WindowVentasState } from "../../../types/generics";
import { GuiaDatosEdicion, GuiaInfo } from "../../../types/types";
import { editDataReducer } from "./editDataReduce";
import { menuReducer } from "./menuReducer";
import { searchGuiasReducer } from "./searchReducer";
import { tabsReducer } from "./tabsReducer";

// const initialState: WindowVentasState<InfoVenta> = {
//   menu: {},
//   search: {},
//   tabs: {
//     current: "",
//     tabs: []
//   }
// }

export const guiasRemisionReducer = combineReducers<WindowVentasState<GuiaInfo, GuiaDatosEdicion>>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchGuiasReducer,
  editData: editDataReducer
});

