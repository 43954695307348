import {
  ColumnasGridVentaDetalle,
  COL_CANTIDAD,
  COL_CODIGOITEM,
  COL_COMANDOS,
  COL_PORCENTAJEDESCUENTO,
  COL_IMPUESTO,
  COL_NOMBREITEM,
  COL_NUMERO,
  COL_PRECIOUNITARIO,
  COL_PRECIOUNITARIOCONIVA,
  COL_SUBTOTALLINEA,
  GridDetalleVentasElement,
} from "./types/types";
import { range } from 'lodash'
import SheetRowNumberViewer from "../../components/ventas/sheetViewers/sheetRowNumberViewer";
import SheetCommandViewer from "../../components/ventas/sheetViewers/SheetCommandViewer";
import SheetTextViewer from "../../components/ventas/sheetViewers/SheetTextViewer";
import SheetNumberViewer from "../../components/ventas/sheetViewers/SheetNumberViewer";
import { DetalleVenta, DetalleVentaRedux } from "./types/detalleVenta";
import { DetalleVentaTable } from "./types/detalleVentaTable";
import { Cliente, ItemVenta, VentaDetalleInfo } from "../../types/types";
export const VentaHelper = {
  generateGridVenta,
  obtenerFilasEnBlanco,
  detallesToDetallesVenta,
  detallesGridToDetalleVenta,
  generateTableVenta,
  tableVentaToDetallesVenta
}

function getViewer(col) {
  switch (col.id) {
    case COL_NUMERO:
      return SheetRowNumberViewer;
    case COL_COMANDOS:
      return SheetCommandViewer
    case COL_CODIGOITEM:
      return SheetTextViewer;
    case COL_CANTIDAD:
      return SheetNumberViewer;
    case COL_PORCENTAJEDESCUENTO:
      return SheetNumberViewer;
    case COL_PRECIOUNITARIO:
      return SheetNumberViewer;
    case COL_PRECIOUNITARIOCONIVA:
    case COL_SUBTOTALLINEA:
      return SheetNumberViewer;
    default:
      return SheetTextViewer
  }
}

function getCellValue(detalle, col): any {
  switch (col.id) {
    case COL_CODIGOITEM:
      return detalle.codigoBarras;
    case COL_NOMBREITEM:
      return detalle.descripcion;
    case COL_IMPUESTO:
      return detalle.porcentajeiva;
    case COL_CANTIDAD:
      return detalle.cantidad;
    case COL_PRECIOUNITARIO:
      return detalle.precio;
    case COL_PRECIOUNITARIOCONIVA:
      return detalle.precioIva;
    case COL_SUBTOTALLINEA:
      return detalle.subtotal;
    default:
      return ''
  }
}

function getCellReadOnlyForEmptyRows(col): boolean {
  switch (col.id) {
    case COL_CODIGOITEM:
    case COL_NOMBREITEM:
      return false;
    case COL_PRECIOUNITARIO:
    case COL_CANTIDAD:
    case COL_PRECIOUNITARIOCONIVA:
    case COL_PORCENTAJEDESCUENTO:
      return true;
    default:
      return false;
  }
}

function tableVentaToDetallesVenta(detalles: Array<DetalleVentaTable>,
  decimales: number): Array<DetalleVenta> {
  const rows = detalles.map(det => {
    const detalle = new DetalleVenta();
    detalle.setData(det.codigo, det.codigoInterno, det.codigoBarras, det.descripcion, det.item?.porcentajeIva);
    detalle.cantidad = det.cantidad;
    detalle.precio = det.precio;
    return detalle;
  });
  return rows;
}


function generateTableVenta(detalles: Array<DetalleVenta>, puedeEditar: boolean,
  decimales: number): Array<DetalleVentaTable> {
  const rows = detalles.map(det => {
    const detalle = new DetalleVentaTable(puedeEditar);
    detalle.codigo = det.codigo;
    detalle.codigoInterno = det.codigoInterno;
    detalle.codigoBarras = det.codigoBarras;
    detalle.descripcion = det.descripcion;
    detalle.tieneIva = det.tieneIva;
    detalle.porcentajeiva = det.porcentajeiva;
    detalle.cantidad = det.cantidad;
    detalle.precio = det.precio;
    //Cuando ser carga una venta dado que ya está guardada es válida siempre
    detalle.makeObjectValid();
    return detalle;
  });
  return rows;
}

function generateGridVenta(detalles: Array<DetalleVenta>, editable: boolean,
  decimales: number, cliente: Cliente, fecha: string): Array<Array<GridDetalleVentasElement>> {
  let rows: Array<Array<GridDetalleVentasElement>> = [];
  let indx = 0;
  //let columnas = editable ? ColumnasGridVentaDetalle : ColumnasGridVentaDetalle.filter(x => x.id !== 1);
  let columnas = ColumnasGridVentaDetalle.sort(function (a, b) {
    return a.id - b.id;
  });
  rows = rows.concat(detalles.map(detalle => {
    indx += 1;
    return (
      columnas.map(col => {
        const value = getCellValue(detalle, col);
        const cell: GridDetalleVentasElement = {
          readOnly: editable ? col.readOnly : !editable,
          value: col.id === 0 ? indx : value,
          display: col.id === 0 ? indx.toString() : value.toString(),
          valid: true,
          hint: '',
          dataEditor: col.dataEditor as any,
          valueViewer: getViewer(col) as any,
          cliente: cliente,
          fecha: fecha
        };
        return (cell)
      })
    )
  }));

  if (editable) {
    rows = rows.concat(obtenerFilasEnBlanco(5, indx, cliente, fecha));
  }
  return rows;
}

function obtenerFilasEnBlanco(numeroFilas, currentIndex, cliente, fecha): Array<Array<GridDetalleVentasElement>> {
  const emptyArray = range(numeroFilas);
  let indx = currentIndex;
  const cols = ColumnasGridVentaDetalle;
  cols.sort(function (a, b) {
    return a.id - b.id;
  });
  const rows = emptyArray.map(x => {
    indx += 1;
    return (
      cols.map(col => {
        const cell: GridDetalleVentasElement = {
          readOnly: (col.readOnly ?? false) ? true : getCellReadOnlyForEmptyRows(col),
          value: col.id === 0 ? indx : '',
          display: '',
          valid: true,
          //expr: col.fieldExpresion ? '' : '',
          dataEditor: col.dataEditor as any,
          valueViewer: getViewer(col) as any,
          hasRowData: false,
          cliente: cliente,
          fecha: fecha
        }
        return (cell)
      })
    )
  })
  return rows;
}



function detallesToDetallesVenta(detalles: Array<VentaDetalleInfo>, presicionDecimal:number): Array<DetalleVentaRedux> {
  return detalles.map(det => {
    const detalle: DetalleVentaRedux = {
      codigo: det.codigo,
      codigoInterno: det.codigoInterno,
      codigoBarras: det.codigoBarras,
      descripcion: det.descripcion,
      porcentajeiva: det.porcentajeIva / 100,
      _cantidad: det.cantidad,
      _precioUnitario: det.pvp,
      tieneIva: det.porcentajeIva > 0,
      imagenes: det.imagenes,
      _descuento: 0,
      _iva: 0,
      _porcentajeDescuento: det.descuento,
      _precioIva: 0,
      _subtotal: 0,
      _subtotal0: 0,
      _subtotalIva: 0,
      _total: 0,
      fecha: ''
    }
    const _detalle: DetalleVenta = new DetalleVenta();
    _detalle.setDetalle(detalle, presicionDecimal);

    // const detalle = new DetalleVenta();
    // detalle.setData(det.codigo, det.codigoint, det.barras, det.descripcion, det.porcentajeiva);
    // detalle.cantidad = det.cantidad;
    // detalle.precio = det.pvd;
    // detalle.imagenes = det.imagenes;
    return _detalle.getDetalle();
  })
  //return [];
}



function detallesGridToDetalleVenta(detallesGrid: Array<Array<GridDetalleVentasElement>>): Array<DetalleVenta> {
  const detalles = detallesGrid
    .filter(filaDetalle => filaDetalle[COL_CODIGOITEM].display.length > 0)//Solo filas con datos
    .map(filaDetalle => {
      const item = filaDetalle[COL_CODIGOITEM].data as ItemVenta;
      const detalle = new DetalleVenta();
      detalle.setData(item.codigo, item.codigoInterno, item.codigoBarras, item.descripcion, item.porcentajeIva);
      detalle.cantidad = filaDetalle[COL_CANTIDAD].value;
      detalle.precio = filaDetalle[COL_PRECIOUNITARIO].value;
      detalle.porcentajeDescuento = filaDetalle[COL_PORCENTAJEDESCUENTO].value;
      //detalle.calcular();
      return detalle;
    });
  return detalles;
}
