import { CInput } from '@coreui/react';
import React, { PureComponent,  } from 'react';

export default function NumberBox() {
  return (
    <CInput
      size='sm'
      type='number'
    />
  )
}


class CustomSheetNumberEditor extends PureComponent {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  handleChange(e) {
    this.props.onChange(e.target.value)
  }

  render() {
    const { value, onKeyDown } = this.props
    return (
      <input
        ref={this.inputRef}
        pattern="^\d*(\.\d{0,2})?$"
        type='number'
        value={value}
        onChange={this.handleChange}
        onKeyDown={onKeyDown}
        size='sm'
        style={{ width: '100%', height: '100%' }}
        className='form-control form-control-sm'
      />
    )
  }
}




export { CustomSheetNumberEditor };