import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormIngresos, ModalIngresoChequeState } from "./types";
import { AccionMenu, OptionGrupos } from "../../../../store/types"
import { SeleccionadoTableItem } from "../../../bancos/store/types"
import { ModalOpen } from "../../../contabilidad/general/store/types"
import { Cheque } from "../../../bancos/pages/cheques/store/types";

const initialState: ModalIngresoChequeState = {
  modulo: 'modalIngresoCheques',
  currentAction: "Buscar",
  currentFunction: "",
  aplicacion: 'modalIngresoCheques',
  acciones: [],
  registros: [],
  seleccionado: {
    index: null,
    row: [],
  },
  modal: {
    name: "",
    open: false,
    codModulo: 0,
    parametros: []
  },
  formasPagoSRI: [],
  formularioIngresoCheques: {
    valor_ti: 0,
    valorsinretencion: 0,
    total_ti: 0,
    totales: 0,
    total: 0
  }
}

const modalIngresoChequesSlice = createSlice({
  name: "modalIngresoCheques",
  initialState: initialState,
  reducers: {
    setCurrentAction(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    obtenerAcciones(state, action: PayloadAction<Array<AccionMenu>>) {
      state.acciones = action.payload
    },
    setRegistros(state, action: PayloadAction<Array<Cheque>>) {
      state.registros = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<SeleccionadoTableItem>) {
      let toAdd = {
        index: action.payload.index,
        row: action.payload.row,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state, action: PayloadAction<any>) {
      let toAdd = {
        index: null,
        row: []
      }
      state.seleccionado = toAdd
    },
    setModalIngresoCheques(state, action: PayloadAction<ModalOpen>) {
      let toAdd = {
        name: action.payload.name,
        open: action.payload.open,
        codModulo: action.payload.codModulo,
        parametros: action.payload.parametros
      }
      state.modal = toAdd
    },
    setFormasPagoSRI(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.formasPagoSRI = action.payload
    },
    setFormularioIngresoCheques(state, action: PayloadAction<FormIngresos>) {
      state.formularioIngresoCheques = action.payload
    },
  }
})

export const { setFormularioIngresoCheques, setFormasPagoSRI, setCurrentFunction, setModalIngresoCheques, obtenerAcciones, setRegistros, setCurrentAction, setSeleccionarDato, setResetSeleccion } = modalIngresoChequesSlice.actions;
export const modalIngresoChequesReducer = modalIngresoChequesSlice.reducer;