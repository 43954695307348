import { CModal, CModalBody, CModalHeader } from '@coreui/react';
import * as React from 'react';
import { SmallLoadingIndicator } from '../loadingindicator/loadingindicator';

interface IModalLoaderProps {
  show: boolean,
  message: string
}

const ModalLoader: React.FunctionComponent<IModalLoaderProps> = (props) => {
  return (
    <CModal
      show={props.show}
      backdrop={true}
      closeOnBackdrop={false}
    >
      <CModalBody>
        <SmallLoadingIndicator mensaje={props.message} />
      </CModalBody>
    </CModal>
  );
};

export default ModalLoader;
