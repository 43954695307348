import React from 'react';
export default function SheetRowNumberViewer(props) {
  const { row } = props

  return (
    <div className='sheetColHeader' style={{ width: '100%', height: '100%' }}>
      <small>{row + 1}</small>
    </div>
  )

  
}
