import * as React from 'react';
import { SelectBox as DevExSelectBox } from 'devextreme-react/select-box';
import DataSource from 'devextreme/data/data_source';
//import './selectBox.scss'

interface ISelectBoxProps {
  id: string
  keyExpr: string
  displayExpr: string
  multiple?: boolean
  onChange: (selected: any) => void;
  options: any[];
  placeholder: string;
  selected: any | null;
  disabled?: boolean
  clearButton?: boolean
  invalid?: boolean,
  minSearchLength?: number,
  elementAttr?: any,
  onEnterKey?: any,
}

const SelectBox: React.FunctionComponent<ISelectBoxProps> = (props) => {
  // const [showClear, setShowClear] = React.useState(false);
  // const [selected, setSelected] = React.useState<Array<any>>([]);
  const { onChange, clearButton } = props;
  const dataSource = React.useMemo(() => {
    return new DataSource({
      store: {
        data: props.options,
        type: 'array',
        key: props.keyExpr
      }
    })
  }, [props.options, props.keyExpr]);


  const selectBoxValueChanged = React.useCallback(({ value }) => {
    onChange(value);
  }, [onChange]);

  return (
    <DevExSelectBox
      dataSource={dataSource}
      searchEnabled={true}
      searchMode={'contains'}
      searchExpr={props.displayExpr}
      displayExpr={props.displayExpr}
      minSearchLength={props.minSearchLength ?? 3}
      searchTimeout={500}
      showDataBeforeSearch
      showSelectionControls={props.multiple ? props.multiple : false}
      onValueChanged={selectBoxValueChanged}
      showClearButton={clearButton}
      value={props.selected}
      disabled={props.disabled}
      placeholder={props.placeholder ?? 'Seleccionar...'}
      elementAttr={props.elementAttr}
      onEnterKey={props.onEnterKey}
    >
      {props.children}
    </DevExSelectBox>
  )


  // return (
  //   <Autocomplete
  //     size="small"
  //     id={props.id}
  //     disableClearable={props.clearButton !== undefined ? !props.clearButton : false}
  //     multiple={props.multiple}
  //     options={props.options}
  //     getOptionLabel={(option) => option[props.labelKey]}
  //     disabled={props.disabled}
  //     onChange={(event: any, newValue) => {
  //       props.onChange([newValue]);
  //     }}
  //     value={props.selected && props.selected.length > 0 ? props.selected[0] : null}
  //     renderInput={(params) => <TextField {...params} label="" variant='standard' fullWidth />}
  //   />
  // )
};





export default SelectBox;
