import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo } from "../../../../../store/types";
import { CustomDictionary } from "../../../../ventas/types/generics";
import {
  LiquidacionCompraDatosEdicion,
  FacturacionEdicionPayload,
  TTotalesFactura,
} from "../types/types";
import { ventasClearDatosEdicion } from "../../../store/reducers";
import { ProveedorBusqueda } from "../../../../proveedores/store/types";
import { FormaPagoModuloCompras } from "../../../../compras/types/types";
import { DetalleLiquidacionRedux } from "../types/detalleLiquidacion";
const initialState: CustomDictionary<LiquidacionCompraDatosEdicion> = {};
const datosEdicionVentasSlice = createSlice({
  name: "ventasLiquidacionesDatosEdicion",
  initialState: initialState,
  reducers: {
    ventasClearDatosEdicion,
    setDatosEdicion(
      state,
      action: PayloadAction<
        FacturacionEdicionPayload<LiquidacionCompraDatosEdicion>
      >
    ) {
      state[action.payload.key] = action.payload.data;
    },
    updateTotales(
      state,
      action: PayloadAction<{ key: number | string; totales: TTotalesFactura }>
    ) {
      state[action.payload.key].totales = action.payload.totales;
      state[action.payload.key].descuentoGlobal =
        action.payload.totales.descuentoGloabal;
    },
    updateDetalles(
      state,
      action: PayloadAction<{
        key: number | string;
        detalles: Array<DetalleLiquidacionRedux>;
      }>
    ) {
      state[action.payload.key].detalles = action.payload.detalles;
    },
    addDetalle(state, action: PayloadAction<{key:number | string, detalle:DetalleLiquidacionRedux}>){
      state[action.payload.key].detalles.push(action.payload.detalle);
    },
    updateProveedor(
      state,
      action: PayloadAction<{
        key: number | string;
        proveedor: ProveedorBusqueda | null;
        formasPago: Array<FormaPagoModuloCompras>;
      }>
    ) {
      state[action.payload.key].proveedor = action.payload.proveedor;
      if (state[action.payload.key].formaPago.length > 0) {
        const formaPago = action.payload.formasPago.find(
          (x) => x.codigo === state[action.payload.key].formaPago[0].codigo
        );
        state[action.payload.key].formaPago = formaPago
          ? [formaPago]
          : action.payload.formasPago.filter((x) => x.nombre === "EFECTIVO");
      } else {
        state[action.payload.key].formaPago = action.payload.formasPago.filter(
          (x) => x.nombre === "EFECTIVO"
        );
      }
    },
    updateObservaciones(
      state,
      action: PayloadAction<{ key: number | string; observaciones: string }>
    ) {
      state[action.payload.key].observaciones = action.payload.observaciones;
    },
    //Establecer datos edicion para un tab nuevo
    initDatosEdicion(
      state,
      action: PayloadAction<
        FacturacionEdicionPayload<LiquidacionCompraDatosEdicion>
      >
    ) {
      if (!state[action.payload.key]) {
        state[action.payload.key] = action.payload.data;
      }
    },
    setEditLoader(
      state,
      action: PayloadAction<{ key: number | string; info: LoaderInfo }>
    ) {
      state[action.payload.key].loader = action.payload.info;
    },
    setMuestraError(
      state,
      action: PayloadAction<{
        key: number | string;
        tieneError: boolean;
        mensajeError: string;
      }>
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError;
      state[action.payload.key].mensajeError = action.payload.mensajeError;
    },
    setLocalLoader(
      state,
      action: PayloadAction<{ key: string | number; loader: LoaderInfo }>
    ) {
      state[action.payload.key].loader = action.payload.loader;
    },
    setInfoError(
      state,
      action: PayloadAction<{
        key: string | number;
        tieneError: true;
        mensaje: string;
      }>
    ) {
      state[action.payload.key].tieneError = action.payload.tieneError;
      state[action.payload.key].mensajeError = action.payload.mensaje;
    },
    updateFormaPago(
      state,
      action: PayloadAction<{
        key: string | number;
        formaPago: Array<FormaPagoModuloCompras>;
      }>
    ) {
      state[action.payload.key].formaPago = action.payload.formaPago;
    },
    setClaveAccesoGuardada(
      state,
      action: PayloadAction<{
        key: string | number;
        claveAcceso: string;
        imprimir: boolean;
      }>
    ) {
      state[action.payload.key].claveAcceso = action.payload.claveAcceso;
      state[action.payload.key].imprimir = action.payload.imprimir;
    },
  },
});

export const {
  initDatosEdicion,
  setDatosEdicion,
  updateTotales,
  updateDetalles,
  addDetalle,
  updateProveedor,
  updateObservaciones,
  setEditLoader,
  ventasClearDatosEdicion: clearDatosEdicion,
  setMuestraError,
  setLocalLoader,
  setInfoError,
  updateFormaPago,
  setClaveAccesoGuardada
} = datosEdicionVentasSlice.actions;
export const datosEdicionReducer = datosEdicionVentasSlice.reducer;
