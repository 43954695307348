import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoaderInfo, OptionGrupos } from "../../../../store/types";
import { OptionProyectos } from "../../../bancos/general/types";
import { GeneralState, OptionGruposDetalle, ModalOpen } from "./types";

const initialState: GeneralState = {
  modulo: 'general',
  proyectos: [],
  proyectosBack: [],
  locales: [],
  localesBack: [],
  tipoAsiento: [],
  tipoCuenta: [],
  categorias: [],
  cuentasFlujoOptions: [],
  periodosContables: [],
  cuentasPertenecen: [],
  cuentasPertenecenBackup: [],
  modal: {
    open: false,
    name: "",
    parametros: [],
    codModulo: 0
  },
  loader: {
    show: false,
    mensaje: ''
  },
}

const generalSlice = createSlice({
  name: "general",
  initialState: initialState,
  reducers: {
    setTiposCuenta(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.tipoCuenta = action.payload
    },
    setTiposOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.tipoAsiento = action.payload
    },
    setCategoriasOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.categorias = action.payload
    },
    setCuentasFlujoOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.cuentasFlujoOptions = action.payload
    },
    setProyectos(state, action: PayloadAction<Array<OptionProyectos>>) {
      state.proyectos = action.payload
    },
    setProyectosBack(state, action: PayloadAction<Array<OptionProyectos>>) {
      state.proyectosBack = action.payload
    },
    setLocales(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.locales = action.payload
    },
    setLocalesBack(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.localesBack = action.payload
    },
    setPeriodosContables(state, action: PayloadAction<Array<OptionGruposDetalle>>) {
      state.periodosContables = action.payload
    },
    setCuentasOptions(state, action: PayloadAction<Array<OptionGrupos>>) {
      state.cuentasPertenecen = action.payload
    },
    setCuentasOptionsBackup(state, action: PayloadAction<Array<any>>) {
      state.cuentasPertenecenBackup = action.payload
    },
    setGeneralModal(state, action: PayloadAction<ModalOpen>) {
      state.modal = action.payload
    },
    changeLoaderModulo(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
  }
})

export const {
  setTiposCuenta,
  setTiposOptions,
  setCategoriasOptions,
  setCuentasFlujoOptions,
  setProyectos,
  setProyectosBack,
  setLocales,
  setLocalesBack,
  setPeriodosContables,
  setCuentasOptions,
  setCuentasOptionsBackup,
  setGeneralModal,
  changeLoaderModulo
} = generalSlice.actions;

export const generalReducer = generalSlice.reducer;