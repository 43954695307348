import { combineReducers } from "@reduxjs/toolkit";

import { RolesUsuariosReducer } from "../rolesUsuario/store/RolesUsuariosReducers"
import { EmpresaReducer } from "../cuenta/empresa/store/EmpresaReducers"
import { UsuarioReducer } from "../cuenta/usuario/store/UsuarioReducers"
import { DesarrolloReducer } from "../desarrollador/store/DesarrolloReducers"
import { ModuloAdminState } from "./types";

export const moduloAdministracionReducer = combineReducers<ModuloAdminState>({
  rolesUsuarios: RolesUsuariosReducer,
  empresa: EmpresaReducer,
  usuario: UsuarioReducer,
  desarrollo: DesarrolloReducer,
})