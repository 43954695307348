import { Ciudad } from "../modulos/ventas/store/types";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { ButtonTypes } from "../views/componentes/globalMenu/types";

export type Usuario = {
  codigo: number;
  nombres: string;
  apellidos: string;
  idsesion: string;
  email: string;
  firma: string;
  cedula: string;
  telefono: string;
  direccion: string;
  codciudad: string;
  ciudad: string;
  codprovincia: string;
  provincia: string;
  pais: string;
  privilegio: string;
  rol: string;
  asistente: number;
};

export type TipoComprobantes = {
  codigo: number;
  comprobante: string;
  archivo: string;
  limite: string;
  estado: string;
  descripcionEstado: string;
  nombre: string;
  ipImpresora: string;
  nombreImpresora: string;
  puertoImpresora: string;
};

export type Impresiones = {
  codigoImpresion: number;
  codigoTic: number;
  archivoImpresion: string;
  limiteImpresion: number;
};

export type Puntoventa = {
  codigoPuntoVenta: number;
  host: string;
  codigoLocal: number;
  nombreLocal: string;
  especial: string;
  mac: string;
  uso: string;
  restaurante: number;
  toOrden: number;
  emula: number;
  estado: number;
  nombreDocumento: string;
};

export interface LocalEmpresa {
  codigo: number;
  nombre: string;
  ciudad?: Ciudad;
  ciudadCodigo: number;
  ciudadNombre: string;
  clase: string;
  descuento: number;
  direccion: string;
  telefono: string;
  tipoRol: string;
  controlaKardex: boolean;
}

export type LocalEmpresaFullInfo = LocalEmpresa & {
  claseCodigo: number;
  claseDescripcion: string;
  contrato: number;
  estadoCodigo: number;
  estadoDescripcion: string;
  gerente: string;
  listaPrecios: number;
  observaciones: string;
  passwordDokan: string;
  perteneceCodigo: number;
  perteneceDescripcion: string;
  siglas: string;
  siglasBod: string;
  tipo: number;
  tipoDescripcion: string;
  uaf: string;
  usuarioDokan: string;
  web: string;
  paisCodigo: number;
  provinciaCodigo: number;
}

export interface Mensajes {
  mensajes: [];
  mostrar: boolean;
  modo: string; // item,todos
  pos: number;
}

export interface Empresa {
  codigo: number;
  nombre: string;
  caduca: string;
  certificado: string;
  comercial: string;
  descuento: number;
  direccion: string;
  email: string;
  iproforma: string;
  logo: string;
  modulos: string;
  precision: number;
  ruc: string;
  telefono: string;
  token: string;
  transparencia: string;
  locales: LocalEmpresa[] | null;
  uso_electronica: number;
  imprimeAutomaticamenteAlfacturar: boolean; //EMP_PREGIMP
  pathArchivosPaginaWeb; //EMP_PATHWEB
  cuotasVenta: number; //EMP_CUOTASV
  cuotasVentaDias: number; //EMP_DIASCUOTA
  formaPagoDefectoCompra: number; //EMP_FPAGOCOMPRA
  formaPagoDefectoVenta: number; //EMP_FPAGOVENTA
  planCodigo: number;
  configurado: boolean;
  leyendaComprobantesElctronicos: string;
  codigoTipoNegocio: number;
}

export interface SessionInfo {
  empresa: Empresa;
  local: LocalEmpresa;
  usuario: Usuario;
  ingreso: string;
}

export interface Autorizacion {
  DOCUMENTO: string,
  AUT_CODIGO: any;
  TIC_CODIGO: any;
  AUT_AUTORIZACION: any;
  AUT_ESTABLECIMIENTO: any;
  AUT_PTOEMISION: any;
  AUT_DESDE: any;
  AUT_HASTA: any;
  AUT_CADUCIDAD: any;
  AUT_NUM_ACTUAL: any;
  AUT_PERTENECE: any;
  AUT_ASOCIADO: any;
  AUT_IMPRENTA: any;
  AUT_COMPARTIDA: any;
  EMP_MODFACTURACION: number;
  COD_IMPRESION: number | null;
  COD_IMPRESORA: number | null;
  COD_TIPONEGOCIO: number
}

export interface Impresora {
  nombre: string;
  codigo: number;
}

export type ConfigConsumidorFinal = {
  email: string;
  codigo: number;
  ruc: string;
};

type sidebarState = {
  sidebarShow: "responsive" | boolean;
  asideShow: boolean;
  canCloseAside: boolean;
  darkMode: boolean;
  showNotificacion: boolean;
  modoImpresion: boolean;
  loadTab: string;
  formDataEmpresa: any;
  formAutorizaciones: Array<Autorizacion>;
  formAutorizacion: Autorizacion;
  formPuntoventa: Puntoventa;
  impresoras: Array<Impresora>;
  tiposAgentes: Array<TipoAgente>;
  tiposNegocios: Array<TipoNegocio>;
  configConsumidorFinal: ConfigConsumidorFinal;
};

export type LoaderInfo = {
  show: boolean;
  mensaje: string;
};

export type AlertInfo = {
  show: boolean;
  alertTitle: string;
  mensaje: string;
  detalles: string[];
  tipo?: string;
};

export type SesionUsuario = {
  usuario: Usuario;
  empresa: Empresa;
  local: LocalEmpresa;
  ingreso: string;
};

export type ErrorInfo = {
  show: boolean;
  titulo?: string;
  mensaje?: string;
  error?: any;
  retryFn?: () => void;
};

export type ModalPerfil = {
  show: boolean;
  titulo?: string;
  mensaje?: string;
  idmodal?: string;
  error?: any;
};

export type Tour = {
  show: boolean;
  usoElectronica: boolean;
};

export type ScreenLock = {
  show?: boolean;
};

export interface GlobalState {
  usuario: Usuario | null;
  empresasUsuario: Array<Empresa> | null;
  sidebar: sidebarState;
  loader: LoaderInfo;
  alert: AlertInfo;
  session: SesionUsuario | null;
  error: ErrorInfo;
  puntoVenta: string | null;
  mensajes: Mensajes | null;
  modal: ModalPerfil;
  tour: Tour;
  screenLock: ScreenLock | null;
  menu: any | null;
  puntosVenta: Array<Puntoventa>;
  tipoComprobantes: Array<TipoComprobantes>;
  impresiones: Array<Impresiones>;
  loadUser: boolean
}

export type SesionIniciadaPayload = {
  isOk: boolean;
  error?: string;
  usuario?: Usuario;
  empresas?: Array<Empresa>;
  sesion?: SessionInfo;
};

export interface InfoPlan {
  cadena: string;
  estado: string;
  id: number;
  plan: number;
  precio: number;
}

export interface TipoAgente {
  codigo: Number;
  descripcion: string;
}

export interface TipoNegocio {
  codigo: Number;
  descripcion: string;
}

export interface Plan {
  id: Number;
  plan: string;
  cadena: string;
  estado: boolean;
  detalle?: any;
  precio: number;
}

export type Seleccionado = {
  index: any;
  row: any;
  selectedRow: any;
};

export type OptionGrupos = {
  value: number;
  label: string;
};

export const ESTABLECER_EMPRESA_LOCAL = "ESTABLECER_EMPRESA_LOCAL";
export const ESTABLECER_USUARIO = "ESTABLECER_USUARIO";
/*
interface EstablecerEmpresaYLocalAction {
  type: typeof ESTABLECER_EMPRESA_LOCAL
  payload: EmpresaYLocal
}
*/
interface EstablecerUsuarioAction {
  type: typeof ESTABLECER_USUARIO;
  payload: string;
}

export type GlobalActionTypes = EstablecerUsuarioAction;

export const estaSeleccionadaEmpresa = (state: GlobalState) => state.session;
export const empresa = (state: GlobalState) => state.session?.empresa;
export const local = (state: GlobalState) => state.session?.local;
export const sidebar = (state: GlobalState) => state.sidebar;
export const hayError = (state: GlobalState) => state.error.show;
export const errorInfo = (state: GlobalState) => state.error;
//export const useTypedSelector: TypedUseSelectorHook<GlobalState> = useSelector
export type Rol = {
  rol: string;
};

export type rolPayload = {
  rol: Rol;
};

export type PuntoventaPayload = {
  Puntoventa: Puntoventa;
};

export type PlanPayload = {
  plan: Plan;
};

export type Respuesta = {
  respuesta: any;
};

export type RespuestaPayload = {
  respuesta: Respuesta;
};

export type AutorizacionPayload = {
  autorizacion: Autorizacion;
};

export enum ToastTypes {
  Success,
  Danger,
  Warning,
  Info,
}

export type TToastNotification = {
  id?: string;
  autoHide?: boolean | number;
  fade?: boolean;
  title?: string;
  content: any;
  type: ToastTypes;
};

export type TToaster = {
  notifications: Array<TToastNotification>;
};

export type MenuShortCut = {
  modificadores: Array<"ctrl" | "shift">;
  keyCode: number;
};

export type AccionMenu = {
  nombre: string;
  icon: string;
  shortcut: MenuShortCut | null;
  main: boolean;
  modal: boolean;
  actionType: ButtonTypes;
};

export type IconInfo = {
  prefix: string;
  iconName: string;
};

export type OpcionMenu = {
  codigo: number;
  nombre: string;
  url: string;
  icon: string;
  acciones?: Array<AccionMenu>;
  tieneHijos: boolean;
  opciones?: Array<OpcionMenu>;
};

export type ModuloMenu = {
  codigo: number;
  nombre: string;
  url: string | null;
  icon: IconDefinition;
  opciones: Array<OpcionMenu>;
};

export enum FETCH_STATUS {
  IDDLE,
  LOADING,
  SUCCESS,
  FAILED,
}

export enum TiposComprobantesSri {
  Factura = "01",
  LiquidacionCompra = "03",
  NotaCredito = "04",
  NotaDebito = "05",
  GuiaRemision = "06",
  Retencion = "07",
}

export enum FormatosCertificados {
  P12 = ".p12",
  PFX = ".pfx",
}

export type Urls = {
  apiBasePath: string;
  documentServerBasePath: string;
  origin: string;
  pathUri: string;
  pathLogos: string;
  pathFotos: string;
  pathFotosNotificaciones: string;
  reportServerBasePath: string;
  pathPlantillas: string;
  apiDotNetPath: string;
  apiDasbooardsPath: string;
};

export type DateRange = {
  inicio: string;
  fin: string;
};

export type PagedResult<T> = {
  currentPage: number;
  from: number;
  to: number;
  count: number;
  result: Array<T>;
};

export type PaginationInfo = {
  current: number;
  size: number;
};

export type IdentificacionSriValidationResult = {
  isValid: boolean;
  motivo?: string;
  nombre?: string;
  razonComercial?: string;
  direccion?: string;
};

export type DotNetPagedResult<T> = {
  page: number;
  pageSize: number;
  count: number;
  data: Array<T>;
};

export type AcathaMessage = {
  codigo: number;
  descripcion: string;
  fechaInicio: string;
  fechaFin: string;
  titulo?: string;
  imagen?: string;
  permanente: number;
};

export type TipoComprobante = {
  codigo: string;
  comprobante: string;
  archivo: string | null;
  limite: string | null;
  estado: number;
  descripcionEstado: string | null;
  nombreImpresora: string | null;
  ipImpresora: string | null;
  puertoImpresora: string | null;
};

// type utilizado para abrir cualquier modal y mantener guardado el modulo desde donde se abrio el modal
export type OpenModal = {
  modalName: string;
  open: boolean;
  codModulo: number | null;
  parametros?: any
};

export enum DashboardModos {
  NoHabilitado = 0,
  Visualizacion = 1,
  VisualizacionEdicion = 2,
}

export type Dashboard = {
  id: number;
  nombre: string;
  nombreInterno: string;
  modo: DashboardModos;
};

export type RowStyle = {
  FontBool?: boolean;
  FontName?: string;
  NumberFormat?: string;
};

export enum DashboardModes {
  Viewer = "Viewer",
  Designer = "Designer",
}

export type DashboardsState = {
  loading: boolean;
  mode: DashboardModes;
  dashboards: Array<Dashboard>;
  currentDashboard?: Dashboard;
};

export type Provincia = {
  codigoPais: number;
  codigo: number;
  nombre: string;
  codigoInec: string;
};

export type Pais = {
  nombre: string;
  codigo: number;
  codigoUaf: string;
  provincias?: Array<Provincia>;
};

export enum TIPOS_DISPOSITIVOS {
  pc = 'pc',
  tablet = 'tablet',
  movil = 'movil',
}

export type RegistrarSesion = {
  tokenSesion: string,
  dispositivo: string,
  empresa: number,
  usuario: number,
  identificadorSesion: string,
  navegador: string,
  sistemaOperativo: string,
  ip: string,
  mac: string,
  equipo: string
};
