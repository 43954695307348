import { RequestHelper } from "../../../helpers/requestHelper"
import { SesionService } from '../../../services/sesion.service'

export const bancoService = {
  getPeriodoActivo,
  getPeriodos,
  getProyectos,
  puntosVentaListar,
  getTipoTransacciones
}

async function getTipoTransacciones(findTipos): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      ...findTipos
    }
    const data = await RequestHelper.getAll<any>('bancos', 'typeTransaction/cboxLoad', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function getPeriodoActivo(): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo
    }
    const data = await RequestHelper.getAll<any>('generales', 'period/getActive', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function getPeriodos(): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo
    }
    const data = await RequestHelper.getAll<any>('generales', 'period/getAll', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

async function getProyectos(): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo
    }
    const data = await RequestHelper.getAll<any>('proyectos/listar', '', "", obj);
    return data;
  } catch (error) {
    return error;
  }
}


async function puntosVentaListar(sms: string): Promise<any> {
  try {
    const sesion = SesionService.getCurrentSesion();
    let obj = {
      usuario: sesion.usuario.codigo,
      empresa: sesion.empresa.codigo,
      codigoLocal: sesion.local.codigo,
      ruc: sesion.empresa.ruc,
      mensaje: sms
    };
    const data = await RequestHelper.get<any>(
      "sidebar",
      "pointSales/getPointsSales",
      "",
      obj
    );
    return data;
  } catch (error) {
    return error;
  }
}