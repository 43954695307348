import React from 'react'
import { CCol, CRow, CAlert } from '@coreui/react'
import { useDispatch } from 'react-redux'
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
} from 'devextreme-react/data-grid'
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer'
import CustomCol from '../../../../../views/componentes/colContainer'
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput'
import { lh, MessagesKeys } from '../../../../../helpers/localizationHelper';
import TextBox from 'devextreme-react/text-box';
import {
  Validator,
  RequiredRule
} from 'devextreme-react/validator';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ValidationSummary from 'devextreme-react/validation-summary';
import SelectBox from '../../../../../views/componentes/selectBox/selectBox';
import CheckBox from 'devextreme-react/check-box'
import { TiposOperadoras } from '../../../../ventas/types/types'
import { ToastTypes } from '../../../../../store/types'
import { addToast } from '../../../../../store/toasterReducer'
import { utilidades } from '../../../../../helpers/utilidades'
import Button from 'devextreme-react/button'

type ErrorInfo = {
  tieneError: boolean,
  mensaje?: string
}

type Contactos = {
  codigo: number,
  numero: string,
  descripcion: string
  tipo?: number
  tipoDescripcion?: string,
  telefonoCodigo?: number,
  telefonoDescripcion?: string,
  principal: boolean,
  asociadoTipo: string
}

const asociadoTipo: string = "PROVEEDOR";

interface TabContactosProps {
  data: Array<any>
  actionSelect: (data) => void,
  tiposOperadoras: Array<TiposOperadoras>,
  returnContactos: (contactos) => void
}

const TIPOSTELEFONO = [
  { valor: 0, descripcion: "Elija una opción" },
  { valor: 1, descripcion: "CASA" },
  { valor: 2, descripcion: "CELULAR" },
  { valor: 3, descripcion: "FAX" },
  { valor: 4, descripcion: "TRABAJO" }];

const TabContactos = (props: TabContactosProps) => {

  const dispatch = useDispatch()

  const [errorInfo, setErrorInfo] = React.useState<ErrorInfo>({ tieneError: false });
  const [tiposTelefonos,] = React.useState<Array<any>>(TIPOSTELEFONO);
  const [telefono, setTelefono] = React.useState<string>('');
  const [tipoTelefono, setTipoTelefono] = React.useState<any | null>(null);
  const [tipoOperadora, setTipoOperadora] = React.useState<TiposOperadoras | null>(null);
  const [tiposOperadora, setTiposOperadoras] = React.useState<Array<TiposOperadoras>>([]);
  const [principal, setPrincipal] = React.useState<boolean>(false);
  const [toolAdd, setToolAdd] = React.useState<boolean>(false);
  const [toolRemove, setToolRemove] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [contactos, setContactos] = React.useState<Array<Contactos>>([]);
  const [seleccionarDato, setSeleccionarDato] = React.useState<any | null>(null);

  const validationGroupTelefonosRef = React.useRef<any>();

  React.useEffect(() => {
    if (props.tiposOperadoras.length > 0) {
      setTiposOperadoras(props.tiposOperadoras);
    }
    if (props.data.length > 0) {
      llenarDataRegistros(props.data);
    } else {
      setContactos([])
    }
  }, [props]);

  const onSelectionChanged = selectedRowsData => {
    if (selectedRowsData['selectedRowsData'].length > 0) {
      const data = selectedRowsData['selectedRowsData'][0]
      setSeleccionarDato({
        index: 1,
        row: data
      })
    }
  }

  const llenarDataRegistros = React.useCallback(async (dataReg) => {
    let item: any = []
    try {
      item = dataReg.map(function (key) {
        return {
          ...key,
          codigo: key.codigo,
          numero: key.numero,
          descripcion: key.descripcion,
          // tipo: key.,
          tipoDescripcion: key.tipoDescripcion,
          // telefonoCodigo: key.,
          // telefonoDescripcion: key.,
          principal: key.principal,
          asociadoTipo: key.asociadoTipo
        }
      })
      setContactos(item.map(x => utilidades.unfreeze(x)))

    } catch (error) {
      console.error("on llenarDataRegistros", error)
    }
  }, [setContactos]);

  const handleClickAdd = React.useCallback(async (values) => {
    setIsSubmitting(true);
    try {

      let validationResult = await validationGroupTelefonosRef.current.instance.validate();

      if (validationResult.isValid === false) {
        setIsSubmitting(false);
        return false;
      }

      const fnAgregar = async () => {

        let filtrado = contactos.findIndex((item: any) => {
          return item.numero === values.telefono;
        });

        if (filtrado > -1) {
          dispatch(addToast({
            id: '',
            autoHide: true,
            content: 'El teléfono que intenta ingresar ya esta registrado : ' + values.telefono,
            fade: true,
            title: 'Proveedor',
            type: ToastTypes.Warning
          }))
          return false;
        }

        const numeros: Contactos = {
          codigo: 0,
          numero: values.telefono,
          descripcion: values.tipoTelefono?.descripcion ?? "",
          tipo: values.tipoOperadora?.codigo ?? 0,
          tipoDescripcion: values.tipoOperadora?.tipo ?? "",
          telefonoCodigo: values.tipoTelefono?.descripcion ?? "",
          telefonoDescripcion: values.tipoTelefono?.valor ?? "",
          principal: values.principal,
          asociadoTipo: asociadoTipo
        }

        let contactosCopy = contactos.slice();
        contactosCopy.push(numeros)
        setContactos(contactosCopy)
        props.returnContactos(contactosCopy)
        setIsSubmitting(false);
        validationGroupTelefonosRef.current.instance.reset()
      }

      if (validationResult.complete) {
        validationResult.complete.then(async resolve => {
          await resolve;
          if (!resolve.isValid) {
            setIsSubmitting(false);
            return;
          }
          fnAgregar();
        })
      } else {
        if (!validationResult.isValid) {
          setIsSubmitting(false);
          return;
        } else {
          fnAgregar();
        }
      }


    } catch (error) {
      console.error(error)
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al agregar este contacto, \n' + error,
        fade: true,
        title: 'Proveedor',
        type: ToastTypes.Danger
      }))
      setIsSubmitting(false);
    }

  }, [setContactos, contactos, asociadoTipo])

  const handleClickRemove = () => {
    if (seleccionarDato === null) {
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: "No se encuentra seleccionado un contacto para eliminarlo.",
        fade: true,
        title: 'Contactos',
        type: ToastTypes.Info
      }))
    } else {
      let filtrado = contactos.findIndex((item: any) => {
        return item.numero === seleccionarDato['row']['numero'];
      });
      if (filtrado > -1) {
        const rowsBefore = contactos.slice();
        rowsBefore.splice(filtrado, 1);
        setContactos(rowsBefore)
        props.returnContactos(rowsBefore)
        setSeleccionarDato(null)
        dispatch(addToast({
          id: '',
          autoHide: true,
          content: "Contacto removido correctamente.",
          fade: true,
          title: 'Contactos',
          type: ToastTypes.Success
        }))
      }
    }
  }

  const onMouseEnter = () => {
    console.log('onMouseEnter')
  }

  return (
    <>
      <ValidationGroup id={`valGroupModificarTelefonosProveedor}`} ref={validationGroupTelefonosRef}>

        <RowContainer className="ml-2">
          <ValidationSummary />
        </RowContainer>

        <RowContainer>
          <CustomCol xs='12' md='4' >
            {errorInfo.tieneError &&
              <CAlert
                key='modificaTelefonosProveedorErrorAlert'
                color='danger'
                show={10000}
                closeButton
                onShowChange={() => { setErrorInfo({ tieneError: false }) }}
              >
                {errorInfo.mensaje}
              </CAlert>
            }
          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs='12' md='3' >
            <Labeled
              label='Teléfono'
            >
              <TextBox
                name='telefono'
                placeholder='Teléfono'
                value={telefono}
                onValueChanged={({ value }) => setTelefono(value)}
                inputAttr={{
                  autoComplete: "nope"
                }}
              >
                <Validator>
                  <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Teléfono")} />
                </Validator>
              </TextBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs='12' md='2' >
            <Labeled
              label='Tipos'
            >
              <SelectBox
                id='selectTiposTelefono'
                options={tiposTelefonos}
                placeholder=''
                onChange={(value) => {
                  if (value) {
                    setTipoTelefono(value);
                    setTipoOperadora(null);
                  } else {
                    setTipoTelefono(null);
                    setTipoOperadora(null);
                  }
                }}
                selected={tipoTelefono}
                displayExpr='descripcion'
                keyExpr='codigo'
                elementAttr={{
                  autoComplete: "nope"
                }}
              >
                {/* <Validator>
                  <RequiredRule message={lh.getMessage(MessagesKeys.ProveedorTipoTelefonoRequerida)} />
                </Validator> */}
              </SelectBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs='12' md='2' >
            <Labeled
              label='Operadora'
            >
              <SelectBox
                id='selectTiposOperadora'
                options={tiposOperadora}
                placeholder=''
                onChange={(value) => {
                  if (value) {
                    setTipoOperadora(value)
                  } else {
                    setTipoOperadora(null);
                  }
                }}
                selected={tipoOperadora}
                displayExpr='tipo'
                keyExpr='codigo'
                disabled={tipoTelefono?.valor === 2 ? false : true}
                elementAttr={{
                  autoComplete: "nope"
                }}
              >
                {/* <Validator>
                  {tipoTelefono?.valor === 2 &&
                    <RequiredRule message={lh.getMessage(MessagesKeys.ProveedorTipoOperadoraRequerida)} />
                  }
                </Validator> */}
              </SelectBox>
            </Labeled>
          </CustomCol>
          <CustomCol xs='12' md='1'>
            <Labeled
              label='Principal'
            >
              <CheckBox
                value={principal}
                onValueChanged={() =>
                  setPrincipal(!principal)
                }
              />
            </Labeled>
          </CustomCol>

          <CustomCol xs='12' md='2' >

            {/* <Tooltip
                id="toolAdd"
                target="#btnAdd"
                visible={toolAdd}
                closeOnOutsideClick={false}
                position="top"
              >
                <div>{"Agregar"}</div>
              </Tooltip> */}
            <Button
              id="btnAdd"
              className="ml-1 mt-3"
              icon='add'
              stylingMode='contained'
              type='default'
              onClick={() =>
                handleClickAdd({
                  telefono,
                  tipoTelefono,
                  tipoOperadora,
                  principal: principal
                })
              }
              disabled={isSubmitting}
              elementAttr={{
                onMouseEnter: () => onMouseEnter()
              }}
            />

            {/* <Tooltip
                    id="toolRemove"
                    target="#btnRemove"
                    visible={toolRemove}
                    closeOnOutsideClick={false} position="top"
                  >
                    <div>{"Remover"}</div>
                  </Tooltip> */}
            <Button
              id="btnRemove"
              className="ml-1 mt-3"
              icon='trash'
              stylingMode='contained'
              type='danger'
              onClick={handleClickRemove}
              disabled={isSubmitting}
              elementAttr={{
                onmouseover: () => setToolRemove(!toolRemove)
              }}
            />

          </CustomCol>

        </RowContainer>

        <RowContainer>
          <CustomCol xs='12' md='8' >
            <CRow>
              <CCol className='d-flex justify-content-end'>{contactos.length}</CCol>
            </CRow>
            <DataGrid
              selection={{ mode: 'single' }}
              hoverStateEnabled={true}
              dataSource={contactos}
              showBorders={true}
              showRowLines={true}
              onSelectionChanged={onSelectionChanged}
            >
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Column
                dataType='string'
                dataField='numero'
                caption='Numero'
                width='20%'
              />
              <Column
                dataType='string'
                dataField='descripcion'
                caption='Descripción'
                width='20%'
              />
              <Column dataField='tipoDescripcion' caption='Operadora' width='20%' />
              <Column
                dataType='boolean' dataField='principal' caption='Principal' width='20%' />
            </DataGrid>

          </CustomCol>
        </RowContainer>

      </ValidationGroup>
    </>
  )
}

export default TabContactos
