import * as React from 'react';
import './pdfVisualizer.scss';
import { Document, Page } from 'react-pdf';
import { CButton, CModal } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faDownload, faFileCode, faPrint, faWindowClose } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../store/toasterReducer';
import { ToastTypes } from '../../../store/types';
import { isMobile, isMobileOnly } from 'react-device-detect';
import ReactDOM from 'react-dom';
interface IPdfVisualizerProps {
  urlPdf: string,
  urlBlobPdf: string,
  urlXml: string,
  urlPrint: string,
  fileName: string,
  onClose: () => void
}


export const PdfVisualizer: React.FC<IPdfVisualizerProps> = (props) => {
  const { onClose, } = props;
  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [error, setError] = React.useState(false);
  const dispatch = useDispatch();
  //const [url, setUrl] = React.useState('./samples/pdf.pdf')

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadFailed({ message }) {
    setError(true);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }



  React.useEffect(() => {
    if (error) {
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al descargar el archivo pdf ',
        fade: true,
        title: 'Descargar Pdf',
        type: ToastTypes.Warning

      }))
      onClose();
    }
  }, [error, dispatch, onClose])

  const style = !isMobile ? { width: `${(window.innerWidth > 1000 ? 800 : window.innerWidth - 100)}px` } : {}
  const element = document.querySelector("#modal");
  if (element) {
    return (
      ReactDOM
        .createPortal(
          <CModal
            show={true}
            onClose={onClose}
            allowTransparency
            size={isMobileOnly ? "xl" : 'lg'}
          >
            {/* <div style={{ width: `${(window.innerWidth - 25)}px`, height: `${(window.innerHeight - 25)}px` }}>
        <iframe style={{ width: '100%', height: '100%' }} src='https:\/\/www.acatha.com\/edocs\/print.php?type=MTAxMjIwMjAwMTAxOTAzNDA1OTcwMDExMDAxMDAxMTI0NTExNTc2MTkyNDEzNjExMS5wZGY='>

        </iframe>
      </div> */}
            <div style={style} >
              <div className='pdfVisualizerContainer'>
                <div className='pdfVisualizerLayoutContainer'>
                  <div className='pdfVisualizerLayoutToolbar'>
                    <div className='pdfVisualizerToolbar'>
                      <div className='pdfVisualizerToolbar-left'>
                        {pageNumber > 1 &&
                          < CButton
                            variant='ghost'
                            disabled={pageNumber === 1 || error}
                            onClick={previousPage}
                          >
                            <FontAwesomeIcon icon={faCaretLeft} size='2x' />
                          </CButton>
                        }
                        {numPages > 1 && pageNumber < numPages &&
                          <CButton
                            variant='ghost'
                            disabled={pageNumber >= numPages || error}
                            onClick={nextPage}
                          >
                            <FontAwesomeIcon icon={faCaretRight} size='2x' />
                          </CButton>
                        }
                      </div>
                      <div className='pdfVisualizerToolbar-right'>
                        <CButton
                          disabled={error}
                          variant='ghost'
                          onClick={() => {
                            const link = document.createElement('a');
                            link.href = 'data:application/octet-stream;base64,' + (props.urlPdf).toString();
                            // link.href = props.urlPdf;
                            link.download = `${props.fileName}.pdf`;
                            //window.open(props.url, '_blank')
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);

                          }}
                        >
                          <FontAwesomeIcon icon={faDownload} size='2x' />
                        </CButton>
                        {props.urlXml &&
                          <CButton
                            disabled={error}
                            variant='ghost'
                            onClick={() => {
                              const link = document.createElement('a');
                              // link.href = props.urlXml;
                              link.href = 'data:application/octet-stream;base64,' + (props.urlXml).toString();
                              link.download = `${props.fileName}.xml`;
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                              //window.open(props.url, '_blank')
                            }}
                          >
                            <FontAwesomeIcon icon={faFileCode} size='2x' />
                          </CButton>
                        }
                        <CButton
                          disabled={error}
                          variant='ghost'
                          onClick={async () => {

                            var res = await fetch(props.urlBlobPdf);
                            const b = await res.blob();
                            const blob = new Blob([b], { type: 'application/pdf;base64' });
                            const fileURL = URL.createObjectURL(blob);
                            window.open(fileURL, '_blank');

                          }}
                        >
                          <FontAwesomeIcon icon={faPrint} size='2x' />
                        </CButton>
                        {isMobile &&
                          <CButton
                            disabled={error}
                            variant='ghost'
                            onClick={() => {
                              props.onClose()
                            }}
                          >
                            <FontAwesomeIcon icon={faWindowClose} size='2x' />
                          </CButton>
                        }
                      </div>
                    </div>
                  </div>
                  <div className='pdfVisualizerLayoutMain'>
                    <Document
                      file={props.urlBlobPdf}
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={onDocumentLoadFailed}
                    >
                      {isMobile &&
                        <Page pageNumber={pageNumber} width={window.innerWidth - 10} />
                      }
                      {!isMobile &&
                        <Page pageNumber={pageNumber} width={window.innerWidth > 1000 ? 800 : window.innerWidth - 100} />
                      }
                    </Document>
                  </div>
                </div>
              </div>
            </div>
          </CModal >
          , element)
    );
  }

  return <></>;

};


