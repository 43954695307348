import { CButton, CCol, CFormGroup, CLabel, CRow, CTooltip } from '@coreui/react';
import * as React from 'react';
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { CuadresService } from '../../../../../services/cuadres.service';
import { DateUtils, formatoFechasApi } from '../../../../../../../../../helpers/dateUtils';
import {
  updateAnuladasCierre2_tiOnly, updateDpCierre2Only, updateDptotales2Only, updateDptotalesOnly,
  updateTotal2_tiOnly
} from '../../../store/editDataReducer';
import DataGrid, {
  Column, HeaderFilter, Paging, Pager, LoadPanel
} from 'devextreme-react/data-grid';
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../../../views/componentes/colContainer';
import { utilidades } from '../../../../../../../../../helpers/utilidades';
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize';
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper';
import { ccDetalle, ComprobantesCuadresCajaListado, formasPagoCuadre } from '../../../../../types/types';
import TabFormasPago from '../componentes/tabFormasPago/tabFormasPago';

interface INotasCreditoProps {
  setToast: (texto, type) => void,
  playLoader: () => void,
  stopLoader: () => void,
  onErrorConfirm: (sms, title) => void,
  onConfirmarRegistro: (sms, method) => void
  tabId: string,
}



const NotasCredito: React.FunctionComponent<INotasCreditoProps> = (props) => {
  const {
    tabId, setToast, playLoader, stopLoader,
    onErrorConfirm, onConfirmarRegistro
  } = props;
  const dataGrid = React.useRef<any>();


  const dispatch = useDispatch();
  const [heigth] = useGridResultadosSize();

  const local = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].local });
  const puntoVenta = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].puntoVenta });
  const fechaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fechaCierre });
  const venInicial2 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial2 });
  const venFinal2 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal2 });
  const generarCuadre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre });
  const anuladascierre2_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].anuladascierre2_ti });
  const total2_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].total2_ti });
  const dptotales2 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales2 });
  const dpfpagos = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpfpagos });
  const dptotales = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales });
  const dpcierre2 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre2 });

  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [showPageSizeSelector,] = React.useState<boolean>(true);
  const [loaderTable, setLoaderTable] = React.useState<boolean>(false);

  const resultadoCierreCajaNC = React.useCallback(async (dpCierreFacturasVar: Array<ComprobantesCuadresCajaListado> | []) => {
    console.log("resultadoCierreCajaNC")
    console.log("dpCierreFacturasVar", dpCierreFacturasVar)
    console.log("dpfpagos", dpfpagos);

    let detalle = {
      ccdCodigo: 0,
      ccaCodigo: "",
      subValor: "",
      ccdValor: 0,
      subCodigo: 0,
    };

    let valor: number = 0;
    const dptotales2Local: Array<formasPagoCuadre> = [];
    if (dpCierreFacturasVar.length === 0) {
      for (const fpg of dpfpagos) {
        dptotales2Local.push({
          subCodigo: Number(fpg.codigo) ?? -1,
          subValor: String(fpg.descripcion) ?? "",
          ccdValor: valor,
        });
      }

      console.log("dptotales2Local", dptotales2Local)
      dispatch(updateDptotales2Only({
        dptotales2: dptotales2Local,
        key: tabId
      }));

    } else {

      let anuladas = 0;
      let i = 0;

      for (let j = 0; j <= dpfpagos.length - 1; j++) {
        valor = 0;
        for (i = 0; i < dpCierreFacturasVar.length; i++) {
          if (dpCierreFacturasVar[i].ventaFormaPagoDescripcion === dpfpagos[j].descripcion) {
            valor = valor + Number(Number(dpCierreFacturasVar[i].valor).toFixed(2));
          }
        }

        dptotales2Local.push({
          subCodigo: Number(dpfpagos[j].codigo),
          subValor: String(dpfpagos[j].descripcion),
          ccdValor: Number(Number(valor).toFixed(2)),
        });

      }

      dispatch(updateDptotales2Only({
        dptotales2: dptotales2Local,
        key: tabId
      }));

      let k = 0;

      for (k = 0; k < dpCierreFacturasVar.length; k++) {
        if (dpCierreFacturasVar[k].ventaEstado === "*") {
          anuladas += Number(dpCierreFacturasVar[k].ventaTotal);
        }
      }

      dispatch(updateAnuladasCierre2_tiOnly({
        anuladascierre2_ti: anuladas,
        key: tabId
      }));

      let total: number = 0;
      for (k = 0; k < dptotales2Local.length; k++) {
        total = total + dptotales2Local[k].ccdValor;
      }
      total = Number(Number(total).toFixed(2));
      dispatch(updateTotal2_tiOnly({
        total2_ti: total,
        key: tabId
      }));

      let dptotalesLocal: any = [];
      if (dptotales2Local.length > 0) {
        for (i = 0; i < dpfpagos.length; i++) {
          let x: ccDetalle | null = null;
          try {
            x = dptotales[i];
          } catch (ex) {
            x = null;
          }
          if (x == null) {
            let addDetallTotales: ccDetalle = {
              ...detalle,
              ccdValor: Number((-1 * Number(dptotales2Local[i].ccdValor)).toFixed(2)),
              subCodigo: dpfpagos[i].codigo,
              subValor: dpfpagos[i].descripcion,
            }
            dptotalesLocal.push({ ...addDetallTotales });
          } else {
            let y: number = Number((x.ccdValor - Number(dptotales2Local[i].ccdValor)).toFixed(2));
            x.ccdValor = y;
            dptotalesLocal[i] = x;
          }

        }

        dispatch(updateDptotalesOnly({
          dptotales: dptotalesLocal,
          key: tabId
        }));

      }
    }


  }, [dispatch, tabId, dpfpagos, dptotales]);

  const getCierreCajaNC = React.useCallback(async () => {
    console.log("getCierreCajaNC");
    setLoaderTable(true)
    let fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi);
    let dpCierre = await CuadresService.getCierreCajaNC(fechaFilter, local?.codigo ?? -1, puntoVenta?.codigoPuntoVenta ?? -1, venInicial2, venFinal2);
    console.log("dpCierre", dpCierre);

    dispatch(updateDpCierre2Only({
      dpcierre2: dpCierre,
      key: tabId
    }));

    await resultadoCierreCajaNC(dpCierre);
    setLoaderTable(false)

  }, [
    fechaCierre, tabId, dispatch, local, puntoVenta, venInicial2, venFinal2, resultadoCierreCajaNC
  ]);

  React.useEffect(() => {
    if (generarCuadre === true) {
      getCierreCajaNC();
    }
  }, [generarCuadre])

  return (
    <>
      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">


          <RowContainer >
            <CustomCol xs='12' md='12'   >

              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr='ventaCodigo'
                dataSource={dpcierre2}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode='widget'
                height={heigth}
                ref={dataGrid}
              >
                <LoadPanel enabled={loaderTable} />
                <Paging defaultPageSize={20}/>
                <Pager
                  visible={dpcierre2.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(dpcierre2)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <HeaderFilter visible={true} />
                <Column dataField='ventaTipoDocumentoDescripcion' dataType='string' caption='Comprobante' width='15%' allowEditing={false} allowSearch={false} allowFiltering />
                <Column dataField='ventaNumero' caption='Número' width='20%' allowEditing={false} />
                <Column dataField='clienteNombre' caption='Cliente' width='20%' allowEditing={false} />
                <Column dataField='ventaTotal' caption='Total' width='15%' allowEditing={false} />
                <Column dataField='valor' caption='Abonado' allowEditing={false} width={'15%'} />
                <Column dataField='saldo' caption='Saldo' width='15%' allowEditing={false} />
                <Column dataField='ventaFormaPagoDescripcion' caption='F. Pago' width='15%' allowEditing={false} />
                <Column dataField='ventaEstado' caption='E' width='20%' allowEditing={false} />

              </DataGrid>

            </CustomCol>
          </RowContainer>

        </CCol>
      </CRow>

      <CRow >
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">


          <CCol lg="3" md="3" sm="12" className="d-flex justify-content-end  align-items-center">
            <TabFormasPago
              tabId={tabId}
              dptotales={dptotales2}
            />
          </CCol>

        </CCol>
      </CRow>


      <CRow >
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">

          <CCol lg='2' md="2" xs="12">
            <CFormGroup>
              <CLabel htmlFor="anuladas" className="col-form-label-md">
                <strong> {"Anuladas"} </strong>
              </CLabel>
              <CRow>
                <CCol md="12">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={anuladascierre2_ti}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
              </CRow>
            </CFormGroup>
          </CCol>

          <CCol lg='2' md="2" xs="12">
            <CFormGroup>
              <CLabel htmlFor="total" className="col-form-label-md">
                <strong> {"Total"} </strong>
              </CLabel>
              <CRow>
                <CCol md="12">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={total2_ti}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
              </CRow>
            </CFormGroup>
          </CCol>

        </CCol>
      </CRow>

      <CRow >
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-start  align-items-center">
          <CTooltip
            key={"tool-1"}
            placement="top"
            content={"Valor abonado del comprobante inmediato anterior listado."}
          >
            <div className={""} id="1">
              <CButton id="1" color="info" className="m-1" size='sm'   >
                <strong>{'('} &#92; {')'}</strong>
              </CButton>
            </div>
          </CTooltip>
          {" "}
          <CTooltip
            key={"tool-2"}
            placement="top"
            content={"Comprobantes Anulados."}
          >
            <div className={""} id="2asterisco">
              <CButton id="2" color="info" className="m-1" size='sm'   >
                <strong>{'(*)'}</strong>
              </CButton>
            </div>
          </CTooltip>
          {" "}
          <CTooltip
            key={"tool-3"}
            placement="top"
            content={"Si existe un comprobante fuera del rango de fechas y este tiene un abono se considera unicamente el valor del abono dentro de los totales."}
          >
            <div className={""} id="3">
              <CButton id="3" color="info" className="m-1" size='sm'   >
                <strong>{'?'}</strong>
              </CButton>
            </div>
          </CTooltip>
        </CCol>
      </CRow>
    </>
  )
}
export default NotasCredito;
