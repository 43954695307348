import * as React from 'react';
import { Proveedor } from '../../store/types';
import { useDispatch } from 'react-redux';
import { ProveedoresService } from '../../services/proveedores.service';
import { LoadPanel } from 'devextreme-react/load-panel';
import { CustomModalDevx } from '../../../../views/componentes/modal/Modal';
import { PopupContent } from '../../../../views/componentes/popupContent';
import {
  CAlert, CCard, CCardBody
} from '@coreui/react';
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../views/componentes/colContainer';
import Labeled from '../../../../views/componentes/labeledInput/labeledInput';
import { lh, MessagesKeys } from '../../../../helpers/localizationHelper';
import TextBox from 'devextreme-react/text-box';
import {
  Validator,
  RequiredRule,
  PatternRule,
  AsyncRule,
  StringLengthRule
} from 'devextreme-react/validator';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ValidationSummary from 'devextreme-react/validation-summary';
import SelectBox from '../../../../views/componentes/selectBox/selectBox';
import { TipoClasificacion, TipoIdentificacion, TipoProveedor, TiposEstados, TiposOperadoras } from '../../../ventas/types/types';
import { GeneralService } from '../../../../services/general.service';
import { Ciudad } from '../../../ventas/store/types';
import { Pais, Provincia, ToastTypes } from '../../../../store/types';
import { addToast } from '../../../../store/toasterReducer';
import { localidadesService } from '../../../../services/localidades.service';
import { isMobile } from 'react-device-detect';
import TextArea from 'devextreme-react/text-area';
import CheckBox from 'devextreme-react/check-box'
import NumberBox from 'devextreme-react/number-box';
import TabContactos from "./tabContactos/tabContactos"
// import Buscar from "./buscar"
import { isMobileOnly } from 'react-device-detect';
import { useModalConvertSize } from '../../../../hooks/useModalSize';
// import { TipoProveedor } from '../../pages/proveedores/store/types';

interface IModalAdminProveedoresProps {
  esTransportista: boolean,
  codigoProveedor: number | null,
  onChanged: (codigoProveedor: string) => void;
  onCancel: () => void;
  show: boolean,
}

type ErrorInfo = {
  tieneError: boolean,
  mensaje?: string
}

type TipoVisitas = {
  codigo: number,
  valor: string
}

const diasVisita: Array<TipoVisitas> = [
  { codigo: 0, valor: "" },
  { codigo: 1, valor: "LUNES" },
  { codigo: 2, valor: "MARTES" },
  { codigo: 3, valor: "MIERCOLES" },
  { codigo: 4, valor: "JUEVES" },
  { codigo: 5, valor: "VIERNES" },
  { codigo: 6, valor: "SABADO" },
  { codigo: 7, valor: "DOMINGO" }];

type DatosModificarProveedor = {
  razonSocial: string,
  nombreComercial: string | null,
  tipoIdentificacion: TipoIdentificacion | null,
  ruc: string | null,
  direccion: string | null,
  proveedorSeguros: boolean,
  artesanoCalificacion: boolean,
  transportista: boolean,
  email: string | null,
  clasificacion: TipoClasificacion | null,
  tipoProveedor: TipoProveedor | null,
  tipoEstado: TiposEstados | null,
  reposicion: number,
  diaVisita: TipoVisitas | null,
  ciudad: Ciudad | null,
  relacionada: boolean,
  contactos: Array<any>
}

export const emailPatern = /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,}[\;]?)+){1,}$/

export const ModalAdminProveedores: React.FC<IModalAdminProveedoresProps> = (props) => {

  const dispatch = useDispatch();

  const [modalSize] = useModalConvertSize("lg");

  const validationGroupRef = React.useRef<any>();

  const { show, codigoProveedor, onCancel, onChanged, esTransportista } = props;

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorInfo, seterrorInfo,] = React.useState<ErrorInfo>({ tieneError: false });
  const [razonSocial, setRazonSocial] = React.useState<string>("");
  const [tiposIdentificacion, setTiposIdentificacion] = React.useState<Array<TipoIdentificacion>>([]);
  const [tipoIdentificacion, setTipoIdentificacion] = React.useState<TipoIdentificacion | null>(null);
  const [ruc, setRuc] = React.useState<string>('');
  const [esNuevo, setEsNuevo] = React.useState(false);
  const [relacionada, setRelacionada] = React.useState(false);
  const [codigo, setCodigo] = React.useState<number>(0);
  const [nombreComercial, setNombreComercial] = React.useState<string>('');
  const [tipoProveedor, setTipoProveedor] = React.useState<TipoProveedor | null>(null);
  const [email, setEmail] = React.useState<string>("");
  const [direccion, setDireccion] = React.useState('');
  const [provincias, setProvincias] = React.useState<Array<Provincia>>([]);
  const [provincia, setProvincia] = React.useState<Provincia | null>(null);
  const [paises, setPaises] = React.useState<Array<Pais>>([]);
  const [pais, setPais] = React.useState<Pais | null>(null);
  const [ciudades, setCiudades] = React.useState<Array<Ciudad>>([]);
  const [ciudad, setCiudad] = React.useState<Ciudad | null>(null);
  const [tiposProveedores, setTiposProveedores] = React.useState<Array<TipoProveedor>>([]);
  const [proveedorSeguros, setProveedorSeguros] = React.useState<boolean>(false);
  const [artesanoCalificacion, setArtesanoCalificacion] = React.useState<boolean>(false);
  const [transportista, setTransportista] = React.useState<boolean>(false);
  const [clasificaciones, setClasificaciones] = React.useState<Array<TipoClasificacion>>([]);
  const [clasificacion, setClasificacion] = React.useState<TipoClasificacion | null>(null);
  const [reposicion, setReposicion] = React.useState<number>(1);
  const [diasVisitas,] = React.useState<Array<TipoVisitas>>(diasVisita);
  const [diaVisita, setDiaVisita] = React.useState<TipoVisitas | null>(null);
  const [tiposEstados, setTiposEstados] = React.useState<Array<TiposEstados>>([]);
  const [estado, setEstado] = React.useState<TiposEstados | null>(null);
  const [tiposOperadora, setTiposOperadoras] = React.useState<Array<TiposOperadoras>>([]);
  const [contactos, setContactos] = React.useState<Array<any>>([]);
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false);
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>([]);

  const validateId = React.useCallback(async (params, esNuevo) => {
    const validacionIdentificacion = await GeneralService.validarIndentificacion(params.value ?? '');
    if (validacionIdentificacion.isValid) {
      // if (esNuevo) {
      //   const proveedor = await ProveedoresService.getProveedorByCode({ nombre: '', identificacion: params.value ?? '' });
      //   if (proveedor.length > 0) return Promise.reject(`El proveedor con identificacion ${(params.value ?? '')} ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`)
      // }
      return Promise.resolve();
    } else {
      return Promise.reject(validacionIdentificacion.motivo ?? 'Error al validar identificación');
    }
  }, []);

  const cargarCiudades = React.useCallback(async (provincia) => {
    let find: number = -1;
    if (provincia === null) {
      find = -1;
    } else {
      find = provincia.codigo;
    }
    const ciudades = await localidadesService.getDpCiudades(find, "Elija una opción");
    setCiudades(ciudades)
    setCiudad(ciudades[0])
  }, [provincia]);

  const cargarProvincias = React.useCallback(async (pais) => {
    if (pais === null) {
      setProvincias(paises[0]['provincias'] ?? [])
    } else {
      setProvincias(pais['provincias'])
      setProvincia(pais['provincias'][0])
    }
  }, [provincia, paises]);

  const cargarProveedor = React.useCallback(async (codigoProveedor) => {

    if (validationGroupRef.current) {
      validationGroupRef.current.instance.reset()
    }


    try {
      setLoading(true);
      setContactos([])
      //await utilidades.sleep(25000);
      const localidades = await localidadesService.getDpPaises("Elija una opción");
      const tipoIdentificacionesApi = await ProveedoresService.getTipoIdentificacion("Identificación");
      const tipos = await ProveedoresService.getTiposProveedor();
      const tipoEstados = await ProveedoresService.getEstados("Estado");
      const tipoOperadoras = await ProveedoresService.getOperadoras("Elija una Operadora");
      const clasificacion = await ProveedoresService.getClasificaciones("Clasificación");
      const busqueda = codigoProveedor === null ? null : await ProveedoresService.getProveedorByCode(Number(codigoProveedor));
      console.log("busqueda", busqueda)
      setTiposProveedores(tipos);
      setClasificaciones(clasificacion)
      setTiposEstados(tipoEstados)
      setTiposOperadoras(tipoOperadoras)
      setTiposIdentificacion(tipoIdentificacionesApi)
      setPaises(localidades)
      setProvincias(localidades?.[2]?.['provincias'] ?? [])

      if (!busqueda) {
        console.log("busqueda 1", busqueda)
        setEsNuevo(true);
        setCodigo(0);
        setNombreComercial('');
        setTipoProveedor(tipos[0]);
        setTipoIdentificacion(tipoIdentificacionesApi[0]);
        setClasificacion(clasificacion[0])
        setEmail('');
        setDireccion('');
        setEstado(tipoEstados[0])
        setTipoProveedor(tipos[0])
        setPais(localidades[2])
        setProvincia(localidades?.[2]?.['provincias']?.[0] ?? null)
        setProveedorSeguros(false)
        setArtesanoCalificacion(false)
        setTransportista(esTransportista)
        setReposicion(1)

        const ciudades = await localidadesService.getDpCiudades(-1, "Elija una opción");
        setCiudades(ciudades)
        setCiudad(ciudades[0])

      } else {
        console.log("busqueda 2", busqueda)

        setLoading(true);
        const detalleProveedor = await ProveedoresService.getDetalleProveedor(busqueda?.codigo ?? 0, "PROVEEDOR");

        const ciudades = await localidadesService.getDpCiudades(busqueda.provinciaCodigo ?? -1, "Elija una opción");
        setCiudades(ciudades)

        let contacts: any = detalleProveedor.map(function (key) {
          return {
            ...key,
            principal: Number(key.principal) === 1 ? true : false
          }
        })
        setEsNuevo(false);
        setCodigo(busqueda.codigo ?? 0);
        setContactos(contacts)
        setNombreComercial(busqueda.nombre ?? "");
        setRazonSocial(busqueda.razonComercial ?? "")
        setRuc(busqueda.identificacion ?? "")
        setDireccion(busqueda.direccion ?? "")
        setEmail(busqueda.email ?? "")
        setProveedorSeguros(Number(busqueda.seguros) === 1 ? true : false)
        setArtesanoCalificacion(Number(busqueda.artesanos) === 1 ? true : false)
        setTransportista(Number(busqueda.transportista) === 1 ? true : false)
        setReposicion(Number(busqueda.intervalo) ?? 1)

        setTipoIdentificacion(tipoIdentificacionesApi.find(x => String(x.codigo) === String(busqueda.tipoIdentificacion)) ?? null);
        setTipoProveedor(tipos.find(x => String(x.codigo) === String(busqueda.tipoProveedorCodigo)) ?? null);
        setClasificacion(clasificacion.find(x => String(x.codigo) === String(busqueda.clasificacion)) ?? null);
        setEstado(tipoEstados.find(x => String(x.codigo) === String(busqueda.estado)) ?? null);
        setDiaVisita(diasVisitas.find(x => String(x.codigo) === String(busqueda.diaReposicion)) ?? null);
        setPais(paises.find(x => Number(x.codigo) === Number(busqueda.paisCodigo)) ?? null);
        setProvincia(provincias.find(x => Number(x.codigo) === Number(busqueda.provinciaCodigo)) ?? null);
        setCiudad(ciudades.find(x => Number(x.codigo) === Number(busqueda.ubicacion)) ?? null);
        setLoading(false);

      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al cargar el proveedor. Error:\n' + error,
        fade: true,
        title: 'Proveedor',
        type: ToastTypes.Danger
      }))

    }

  }, [dispatch, diasVisitas, esTransportista]);




  const handleSubmit = React.useCallback(async (values: DatosModificarProveedor, verificarNuevo: boolean) => {
    setLoading(true)
    // try {
    let validationResult = await validationGroupRef.current.instance.validate();
    if (validationResult.isValid === false) {
      setLoading(false)
      setShowErrorPopup(true)
      setShowErrorMessages(validationResult.brokenRules)
      setIsSubmitting(false);
      return;
    }
    // validationResult.complete.then(async resolve => {
    //   await resolve;
    // if (!validationResult.isValid) {
    //   setShowErrorPopup(true)
    //   setIsSubmitting(false);
    //   return;
    // }

    let contactos: any = [];
    if (values.contactos && values.contactos.length === 0) {
      setLoading(false)
      setIsSubmitting(false);
      dispatch(addToast({
        title: 'Crear proveedor',
        content: `Debe ingresar al menos un numero de contacto.`,
        type: ToastTypes.Warning,
        autoHide: 5000,
      }))
      return false;
    } else {
      contactos = values.contactos;
    }

    if (verificarNuevo === true) {
      const proveedor = await ProveedoresService.getProveedor(values.ruc ?? "");
      setIsSubmitting(false);
      setLoading(false)
      if (proveedor) {
        dispatch(addToast({
          title: 'Crear proveedor',
          content: `'El proveedor con identificación ${(values.ruc ?? '')} ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`,
          type: ToastTypes.Warning,
          autoHide: 2500,
        }))
        return;
      }
    }

    let diaVisita = "1";
    if (values.diaVisita && values.diaVisita.codigo) {
      diaVisita = String(values.diaVisita.codigo);
    }

    let ubicacion = 1;
    if (values.ciudad && values.ciudad?.codigo !== -1) {
      ubicacion = values.ciudad?.codigo;
    }

    const proveedor: Proveedor = {
      codigo: verificarNuevo ? 0 : codigo,
      tipoIdentificacion: String(values.tipoIdentificacion?.codigo) ?? "01",
      identificacion: values.ruc ?? "",
      identificacionString: values.ruc ?? "",
      // tributario: values.tributario,
      // fechaRegitro: values.fechaRegitro,
      nombre: values.razonSocial,
      razonComercial: values.nombreComercial ?? "",
      direccion: values.direccion ?? "",
      // telefono: values.telefono,
      // fax: values.fax,
      // celular: values.celular,
      email: values.email ?? "",
      ubicacion: ubicacion ?? 1,
      // ciudadNombre: values.ciudadNombre,
      clasificacion: values.clasificacion?.codigo ?? 0,
      diasCredito: 0,
      limiteCredito: 0,
      descuento: 0,
      // personaAtencionCompras: values.personaAtencionCompras,
      // personaAtencionPagos: values.personaAtencionPagos,
      saldo: 0,
      comprasAnuales: 0,
      // fechaUltimoPago: values.fechaUltimoPago,
      // documentoUltimoPago: values.documentoUltimoPago,
      montoPago: 0,
      // fechaUltimaCompra: values.fechaUltimaCompra,
      // documentoUltimaCompra: values.documentoUltimaCompra,
      montoCompra: 0,
      // observaciones: values.observaciones,
      // web: values.web,
      // tipoEmpresa: values.tipoEmpresa,
      // especialidad: values.especialidad,
      tipoAgenteCodigo: Number(values.tipoProveedor?.codigo) ?? 0,
      seguros: values.proveedorSeguros === true ? 1 : 0,
      artesanos: values.artesanoCalificacion === true ? 1 : 0,
      tipoProveedorCodigo: String(values.tipoProveedor?.codigo) ?? "01",
      relacionado: values.tipoIdentificacion?.codigo === "03" ? (values.relacionada === true ? "SI" : 'NO') : "NO",
      estado: String(values.tipoEstado?.codigo) ?? "1",
      intervalo: values.reposicion,
      diaReposicion: diaVisita,
      transportista: values.transportista === true ? "1" : "0",
      tributario: ''
    }
    try {
      await ProveedoresService.modificarProveedor(proveedor,
        contactos);

      dispatch(addToast({
        title: 'Proveedor',
        content: `Registro Satisfactorio.`,
        type: ToastTypes.Success,
        autoHide: 5000,
      }))
      setLoading(false)
      setIsSubmitting(false);
      onChanged(values.ruc ?? '');
    } catch (error) {
      setLoading(false);
      setIsSubmitting(false);
      console.log("catch 1")
      dispatch(addToast({
        type: ToastTypes.Danger,
        content: error,
        autoHide: 5000
      }))
    }

    // }, reject => {
    //   setLoading(false)
    //   setShowErrorPopup(true)
    //   setIsSubmitting(false);
    // })

    // } catch (error) {
    //   console.log("catch 2")
    //   console.error(error)
    //   setLoading(false)
    //   seterrorInfo({ tieneError: true, mensaje: 'Error al guardar el proveedor, \n' + error });
    //   setIsSubmitting(false);
    //   dispatch(addToast({
    //     type: ToastTypes.Danger,
    //     content: 'Error al guardar el proveedor, \n' + error,
    //     autoHide: 5000
    //   }))
    // }

  }, [dispatch, onChanged, codigo]);

  const getMessage = (mensaje, replace) => {
    let message = mensaje.replace("@dinamic_var@", replace);
    return message;
  }

  const inputDraw = () => {
    if (tipoIdentificacion && tipoIdentificacion.codigo === "02") {
      return (inputIdentificacionCedula())
    } else if (tipoIdentificacion && tipoIdentificacion.codigo === "01") {
      return (inputIdentificacionRuc())
    } else {
      return (
        inputIdentificacionPasaporte()
      )
    }
  }

  const inputIdentificacionCedula = () => {
    return (
      <div key={'textCedula'}>
        <TextBox
          name='ruc'
          placeholder='R.U.C / Identificación'
          value={ruc}
          onValueChanged={({ value }) => setRuc(value)}
        >
          <Validator>
            <RequiredRule trim message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionRequerido)} />
            <StringLengthRule max='10' message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} />
            <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} reevaluate={true} ignoreEmptyValue validationCallback={(params) => validateId(params, esNuevo)} />
          </Validator >
        </TextBox>
      </div>
    )
  }

  const inputIdentificacionRuc = () => {
    return (
      <div key={'textRuc'}>
        <TextBox
          name='ruc'
          placeholder='R.U.C / Identificación'
          value={ruc}
          onValueChanged={({ value }) => setRuc(value)}
        >
          <Validator>
            <PatternRule message={lh.getMessage(MessagesKeys.ClienteNumeroRucIncorrecto, "")} pattern='^[0-9]{5,}|^[0-9]{10}$|^[0-9]{13}$' />
            <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroRucIncorrecto, "")} reevaluate={true} ignoreEmptyValue validationCallback={(params) => validateId(params, esNuevo)} />
            <StringLengthRule min='13' message={lh.getMessage(MessagesKeys.ClienteNumeroRucIncorrecto, "")} />
          </Validator>
        </TextBox>
      </div>
    )
  }

  const inputIdentificacionPasaporte = () => {
    return (
      <div key='textPasaporte'>
        <TextBox
          name='ruc'
          placeholder='R.U.C / Identificación'
          value={ruc}
          onValueChanged={({ value }) => setRuc(value)}
        >
          <Validator>
            <RequiredRule trim message={lh.getMessage(MessagesKeys.ClienteNumeroPasaporteRequerido, "")} />
            <StringLengthRule
              max='15'
              message={
                getMessage(lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionMax, ""), 15)
              }
              ignoreEmptyValue
            />
          </Validator>
        </TextBox>
      </div>
    )
  }

  const loadPanelShow = () => {
    return (
      <div>
        <LoadPanel
          id='loadingPanelDevx'
          shadingColor="rgba(0,0,0,0.4)"
          visible={loading}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </div>
    )
  }

  React.useEffect(() => {
    if (show) {
      console.log("show", show)
      cargarProveedor(codigoProveedor);
    }
  }, [cargarProveedor, show, codigoProveedor]);

  if (!show) {
    return null;
  }

  return (
    <>
      {loading && loadPanelShow()}
      <CustomModalDevx
        size={modalSize}
        show={show}
        title='Administración de Proveedor'
        onClose={onCancel}
        toolbarItems={
          [
            {
              options: {
                text: 'Guardar',
                stylingMode: 'contained',
                type: 'default',
                icon: 'check',
                onClick: () => handleSubmit(
                  {
                    razonSocial: razonSocial,
                    nombreComercial: nombreComercial,
                    tipoIdentificacion: tipoIdentificacion,
                    ruc: ruc,
                    direccion: direccion,
                    proveedorSeguros: proveedorSeguros,
                    artesanoCalificacion: artesanoCalificacion,
                    transportista: transportista,
                    email: email,
                    clasificacion: clasificacion,
                    tipoProveedor: tipoProveedor,
                    tipoEstado: estado,
                    reposicion: reposicion,
                    diaVisita: diaVisita,
                    ciudad: ciudad,
                    relacionada: relacionada,
                    contactos: contactos
                  }, esNuevo)
              },
              widget: 'dxButton',
              toolbar: 'bottom',
              location: 'after'
            },
            {
              options: {
                text: 'Cancelar',
                stylingMode: 'contained',
                type: 'danger',
                icon: 'remove',
                onClick: onCancel
              },
              widget: 'dxButton',
              toolbar: 'bottom',
              location: 'after'
            }
          ]
        }
        height={isMobile ? '100%' : undefined}
      >


        <fieldset>
          <ValidationGroup id={`valGroupModificarProveedor}${codigoProveedor}`} ref={validationGroupRef}>
            {!isMobileOnly &&
              <RowContainer>
                <CustomCol>
                  <ValidationSummary />
                </CustomCol>
              </RowContainer>
            }
            {errorInfo.tieneError &&
              <CAlert
                key='modificaProveedorErrorAlert'
                color='danger'
                show={10000}
                closeButton
                onShowChange={() => { seterrorInfo({ tieneError: false }) }}
              >
                {errorInfo.mensaje}
              </CAlert>
            }
            <RowContainer>
              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Razón Social'
                >
                  <TextBox
                    name='razonSocial'
                    placeholder='Razón Social'
                    value={razonSocial}
                    onValueChanged={({ value }) => setRazonSocial(value)}
                    elementAttr={{
                      autoFocus: true
                    }}
                  >
                    <Validator>
                      <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Razón Social")} />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Nombre Comercial'
                >
                  <TextBox
                    name='nombreComercial'
                    placeholder='Nombre Comercial'
                    value={nombreComercial ?? ""}
                    onValueChanged={({ value }) => setNombreComercial(value)}
                  >
                    <Validator>
                      <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Nombre Comercial")} />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Tipo identificación'
                >
                  <SelectBox
                    id='selectTiposIdentificacion'
                    options={tiposIdentificacion}
                    placeholder='Tipo de indetificación'
                    onChange={(value) => {
                      if (value) {
                        setTipoIdentificacion(value);
                        setRuc("")
                      } else {
                        setRuc("")
                        setTipoIdentificacion(null);
                      }
                    }}
                    selected={tipoIdentificacion}
                    displayExpr='tipo'
                    keyExpr='codigo'
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  >
                    <Validator>
                      <RequiredRule message={lh.getMessage(MessagesKeys.ClienteTipoIdentificacionRequerida)} />
                    </Validator>
                  </SelectBox>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Identificación'
                >
                  {inputDraw()}
                  {/* <TextBox
                    name='ruc'
                    placeholder='R.U.C / Identificacion'
                    value={ruc}
                    onValueChanged={({ value }) => setRuc(value)}
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  >
                    <Validator>
                      <RequiredRule trim message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionRequerido)} />
                      <PatternRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} pattern='^[0-9]{5,}|^[0-9]{10}$|^[0-9]{13}$' />
                      <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} reevaluate={true} ignoreEmptyValue validationCallback={(params) => validateId(params, esNuevo)} />
                    </Validator>
                  </TextBox> */}
                </Labeled>
              </CustomCol>
              <CustomCol xs='12' md="8">
                <Labeled
                  label='Dirección'
                >
                  {isMobile &&
                    <TextArea
                      value={direccion}
                      onValueChanged={({ value }) => setDireccion(value)}
                      height={90}
                    >
                      <Validator>
                        <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Dirección")} />
                      </Validator>
                    </TextArea>
                  }
                  {!isMobile &&
                    <TextBox
                      value={direccion}
                      onValueChanged={({ value }) => setDireccion(value)}
                    >
                      <Validator>
                        <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Dirección")} />
                      </Validator>
                    </TextBox>
                  }

                </Labeled>
              </CustomCol>
            </RowContainer>

            <RowContainer>
              <CustomCol xs='12' md='12'>
                <CCard >
                  <CCardBody>
                    <TabContactos
                      data={contactos}
                      actionSelect={(data) => console.log("data", data)}
                      tiposOperadoras={tiposOperadora}
                      returnContactos={(contactos) => setContactos(contactos)}
                    />
                  </CCardBody>
                </CCard>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Proveedor de Seguros'
                >
                  <CheckBox
                    value={proveedorSeguros}
                    onValueChanged={() =>
                      setProveedorSeguros(!proveedorSeguros)
                    }
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Artesano con calificación'
                >
                  <CheckBox
                    value={artesanoCalificacion}
                    onValueChanged={() =>
                      setArtesanoCalificacion(!artesanoCalificacion)
                    }
                  />
                </Labeled>
              </CustomCol>
              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Transportista'
                >
                  <CheckBox
                    value={transportista}
                    onValueChanged={() =>
                      setTransportista(!transportista)
                    }
                  />
                </Labeled>
              </CustomCol>
            </RowContainer>

            <RowContainer>
              <CustomCol >
                <Labeled
                  label='Email'
                >
                  <TextBox
                    value={email}
                    onValueChanged={({ value }) => setEmail(value)}
                  >
                    <Validator>
                      <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Email")} />
                      <PatternRule pattern={emailPatern} message={lh.getMessage(MessagesKeys.GlobalFormatoCampoIncorrecto, "Email")} />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
            </RowContainer>

            <RowContainer>

              <CustomCol xs='12' md='4'>
                <Labeled label='País' >
                  <SelectBox
                    id='selectPais'
                    options={paises}
                    placeholder='País'
                    onChange={(value) => {
                      if (value) {
                        setPais(value);
                        cargarProvincias(value)
                      } else {
                        setPais(null);
                        cargarProvincias(null)
                      }
                    }}
                    selected={pais}
                    displayExpr='nombre'
                    keyExpr='codigo'
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  >
                  </SelectBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs='12' md='4'>
                <Labeled label='Provincia' >
                  <SelectBox
                    id='selectProvincia'
                    options={provincias}
                    placeholder='Provincia'
                    onChange={(value) => {
                      if (value) {
                        setProvincia(value);
                        cargarCiudades(value)
                      } else {
                        setProvincia(null);
                        cargarCiudades(null)
                      }
                    }}
                    selected={provincia}
                    displayExpr='nombre'
                    keyExpr='codigo'
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  >
                  </SelectBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs='12' md='4'>
                <Labeled label='Ciudad' >
                  <SelectBox
                    id='selectCiudades'
                    options={ciudades}
                    placeholder='Ciudad'
                    onChange={(value) => {
                      if (value) {
                        setCiudad(value);
                      } else {
                        setCiudad(null);
                      }
                    }}
                    selected={ciudad}
                    displayExpr='nombre'
                    keyExpr='codigo'
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  >
                    <Validator>
                      <RequiredRule message={lh.getMessage(MessagesKeys.ProveedorUbicacionRequerida)} />
                    </Validator>
                  </SelectBox>
                </Labeled>
              </CustomCol>

            </RowContainer>

            <RowContainer>
              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Clasificación'
                >
                  <SelectBox
                    id='selectClasificacion'
                    options={clasificaciones}
                    placeholder='Clasificación'
                    onChange={(value) => {
                      if (value) {
                        setClasificacion(value);
                      } else {
                        setClasificacion(null);
                      }
                    }}
                    selected={clasificacion}
                    displayExpr='descripcion'
                    keyExpr='codigo'
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  >
                    <Validator>
                      <RequiredRule message={lh.getMessage(MessagesKeys.ProveedorClasificacionRequerida)} />
                    </Validator>
                  </SelectBox>
                </Labeled>
              </CustomCol>

              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Tipo de proveedor'
                >
                  <SelectBox
                    id='selectTipoProveedor'
                    options={tiposProveedores}
                    placeholder='Tipo de proveedor'
                    onChange={(value) => {
                      if (value) {
                        setTipoProveedor(value);
                      } else {
                        setTipoProveedor(null);
                      }
                    }}
                    selected={tipoProveedor}
                    displayExpr='descripcion'
                    keyExpr='codigo'
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  >
                    <Validator>
                      <RequiredRule message={lh.getMessage(MessagesKeys.ProveedorTipoRequerida)} />
                    </Validator>
                  </SelectBox>
                </Labeled>
              </CustomCol>

              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Estado'
                >
                  <SelectBox
                    id='selectEstados'
                    options={tiposEstados}
                    placeholder='Estado'
                    onChange={(value) => {
                      if (value) {
                        setEstado(value);
                      } else {
                        setEstado(null);
                      }
                    }}
                    selected={estado}
                    displayExpr='tipo'
                    keyExpr='codigo'
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  >
                    <Validator>
                      <RequiredRule message={lh.getMessage(MessagesKeys.EstadoRequerido)} />
                    </Validator>
                  </SelectBox>
                </Labeled>
              </CustomCol>

              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Intervalo de reposición'
                >
                  <NumberBox
                    name="reposicion"
                    id="reposicion"
                    value={reposicion}
                    onValueChanged={(data) => setReposicion(Number(data.value))}
                    showClearButton={true}
                  />
                </Labeled>
              </CustomCol>

              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Día de visíta'
                >
                  <SelectBox
                    id='selectDiasVisita'
                    options={diasVisitas}
                    placeholder=''
                    onChange={(value) => {
                      if (value) {
                        setDiaVisita(value);
                      } else {
                        setDiaVisita(null);
                      }
                    }}
                    selected={diaVisita}
                    displayExpr='valor'
                    keyExpr='codigo'
                    elementAttr={{
                      autocomplete: "nope",
                      autoComplete: "nope",
                    }}
                  >
                    <Validator>
                    </Validator>
                  </SelectBox>
                </Labeled>
              </CustomCol>

            </RowContainer>

            {isMobileOnly &&
              (
                <>
                  <RowContainer className="ml-2">
                    <CustomCol>
                      <ValidationSummary />
                    </CustomCol>
                  </RowContainer>
                  {showErrorPopup && (
                    < PopupContent
                      show={showErrorPopup}
                      title={"Acatha"}
                      subTitle={"Por favor revise las siguientes advertencias:"}
                      onClose={() => {
                        setShowErrorPopup(false);
                        setShowErrorMessages([]);
                      }}
                      size={"sm"}
                      canClose={true}
                    >
                      <CAlert color='danger'>
                        <ul>
                          {showErrorMessages.map(function (item, id) {
                            return <li key={id}>{item['message']}</li>
                          })}
                        </ul>
                      </CAlert>
                    </PopupContent>
                  )}
                </>
              )
            }



          </ValidationGroup>
        </fieldset>

        <RowContainer>
          <CustomCol xs='12' md='4'>
          </CustomCol>
        </RowContainer>
      </CustomModalDevx>
    </>
  );
}
