import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmpresaState } from "./types";

const initialState: EmpresaState = {
  modulo: 'empresa',
  currentAction: "Editar",
  currentFunction: "",
  currentTab: 0, // 0,1,2
  currentSubTab: 0,
  datosEmpresa: null
}

const empresaSlice = createSlice({
  name: "empresa",
  initialState: initialState,
  reducers: {
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setCurrentTab(state, action: PayloadAction<number>) {
      state.currentTab = action.payload
    },
    setCurrentSubTab(state, action: PayloadAction<number>) {
      state.currentSubTab = action.payload
    },
    setDataEmpresa(state, action: PayloadAction<any>) {
      state.datosEmpresa = action.payload
    },
  }
})
export const {
  setCurrentAccion,
  setCurrentFunction,
  setDataEmpresa,
  setCurrentTab,
  setCurrentSubTab
} = empresaSlice.actions;
export const EmpresaReducer = empresaSlice.reducer;

