import * as React from 'react';
import RowContainer from '../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../views/componentes/colContainer';
import Labeled from '../../../../../views/componentes/labeledInput/labeledInput';
import {
  Validator,
  AsyncRule,
} from 'devextreme-react/validator';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ValidationSummary from 'devextreme-react/validation-summary';
import { BuscarItemParaLiquidacion } from './buscarItemParaLiquidacion';
import NumberBox from 'devextreme-react/number-box';
import { CustomModalDevx } from '../../../../../views/componentes/modal/Modal';
//import { lh, MessagesKeys } from '../../../../../helpers/localizationHelper';
import { ItemCompras } from '../../../../compras/types/types';
import { DetalleLiquidacion } from '../../../pages/liquidaciones/types/detalleLiquidacion';
//import CustomAutocompleteProducto from './autocompleteItem';

// type DatosDetalle = {
//   item: ItemCompras | null,
//   cantidad: number,
//   precio: number,
//   precioConIva: number
// }

interface IAgregarDetalleLiquidacionMobilProps {
  rucCliente: string;
  onCancel: () => void;
  onOk: (detalle: DetalleLiquidacion) => void;
  proveedor: any;
}



const AgregarDetalleLiquidacionMobil: React.FunctionComponent<IAgregarDetalleLiquidacionMobilProps> = (props) => {
  const { onCancel, onOk, proveedor } = props;

  const textBoxCantidad = React.useRef<any>(null);
  const [item, SetItem] = React.useState<ItemCompras | null>(null);
  const [cantidad, setCantidad] = React.useState(1);
  const [precio, setPrecio] = React.useState(0);
  const [precioConIva, setPrecioConIva] = React.useState(0);
  const validationGroupRef = React.useRef<any>();

  const getTotal = React.useCallback((tipo: 'subtotal' | 'iva' | 'total') => {
    switch (tipo) {
      case "subtotal":
        return cantidad * precio;
      case "iva":
        if (item) {
          return (cantidad * precio) * (item.porcentajeIva / 100.0)
        } else {
          return 0;
        }
      case "total":
        return (getTotal('subtotal') + getTotal('iva'))
      default:
        break;
    }
  }, [cantidad, precio, item])


  const onSubmit = React.useCallback(() => {
    const validationResult = validationGroupRef.current.instance.validate();
    if (!validationResult.isValid) {
      // setTieneErroresValidacion(true);
      return;
    };
    validationResult.complete.then(async resolve => {
      await resolve;
      if (resolve.isValid) {
        if (item) {
          const detalle = new DetalleLiquidacion();
          detalle.setItem(item);
          detalle.cantidad = cantidad;
          detalle.precio = precio;
          //setSubmitting(false);
          onOk(detalle);
        }
      }
    })
  }, [item, cantidad, precio, onOk]);

  const validateCantidad = React.useCallback((params) => {
    const { value } = params;
    return value <= 0 ? Promise.reject("La cantidad debe ser mayor a 0.") : Promise.resolve();
  }, []);

  const validatePrecio = React.useCallback((params) => {
    const { value } = params;
    return value <= 0 ? Promise.reject("El precio debe ser mayor a 0.") : Promise.resolve();
  }, []);

  React.useEffect(() => {
    if (item) {
      console.log("item", item)
      setPrecio(item.pvp);
      setPrecioConIva(item.porcentajeIva > 0 ? item.pvpiva : item.pvp)
      textBoxCantidad?.current?.instance?.focus();
    }
  }, [item]);



  return (
    <CustomModalDevx
      show={true}
      size='sm'
      title='Agregar Detalle'
      onClose={() => { }}
      height={300}
      position='center'
      toolbarItems={[
        {
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'center',
          options: {
            text: 'Aceptar',
            icon: "check",
            type: "success",
            onClick: onSubmit
          }
        },
        {
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'center',
          options: {
            text: 'Cancelar',
            icon: "close",
            type: "danger",
            onClick: onCancel
          }
        }
      ]}
    >
      <ValidationGroup id={'AgregarDetalleVentaMovil'} ref={validationGroupRef}>
        <RowContainer >
          <CustomCol xs='12'>
            <Labeled
              label='Producto'
            >
              {/* <BuscarItemVentasLookUp
                selected={item}
                onChanged={(value) => SetItem(value)}
                cliente={cliente}
                fecha={fecha} >
                <Validator>
                  <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Producto")}  ></RequiredRule>
                </Validator>
              </BuscarItemVentasLookUp> */}
              <BuscarItemParaLiquidacion
                selected={item}
                tipoBusqueda={"nombre"}
                onChanged={(value) => {
                  SetItem(value)
                }}
                proveedor={proveedor}
              />
            </Labeled>
          </CustomCol>
          <CustomCol xs='4'>
            <Labeled
              label='Cantidad'
            >
              <NumberBox
                value={cantidad}
                onValueChanged={({ value }) => setCantidad(value ?? 0)}
                ref={textBoxCantidad}
              >
                <Validator>
                  <AsyncRule validationCallback={validateCantidad} reevaluate />
                </Validator>
              </NumberBox>
            </Labeled>

          </CustomCol>
          <CustomCol xs='4'>
            <Labeled
              label='Precio'
            >
              <NumberBox
                value={precio}
                onValueChanged={({ value }) => {
                  const nuevoPrecio = (value ?? 0);
                  setPrecio(nuevoPrecio);
                  if (item) {
                    const nuevoPreciova = item.porcentajeIva > 0 ? nuevoPrecio * (1 + (item.porcentajeIva / 100)) : nuevoPrecio;
                    setPrecioConIva(parseFloat(nuevoPreciova.toFixed(2)))
                  } else {
                    setPrecioConIva(nuevoPrecio);
                  }
                }} >
                <Validator>
                  <AsyncRule validationCallback={validatePrecio} reevaluate />
                </Validator>
              </NumberBox>
            </Labeled>

          </CustomCol>
          <CustomCol xs='4'>
            <Labeled
              label='Precio IVA'
            >
              <NumberBox
                value={precioConIva}
                onValueChanged={({ value }) => {
                  const nuevoPrecioIva = (value ?? 0) as number;
                  setPrecioConIva(nuevoPrecioIva)
                  if (item) {
                    const nuevoPrecio = item.porcentajeIva > 0 ? nuevoPrecioIva / (1 + (item.porcentajeIva / 100)) : nuevoPrecioIva;
                    setPrecio(parseFloat(nuevoPrecio.toFixed(4)))
                  } else {
                    setPrecio(nuevoPrecioIva);
                  }

                }} />
            </Labeled>

          </CustomCol>
        </RowContainer>
        <RowContainer>
          <CustomCol xs='4'>
            <Labeled
              label='Subtotal'
            >
              <NumberBox
                readOnly
                value={getTotal('subtotal').toFixed(2)} />
            </Labeled>
          </CustomCol>
          <CustomCol xs='4'>
            <Labeled
              label='Iva'
            >
              <NumberBox
                readOnly
                value={getTotal('iva').toFixed(2)} />
            </Labeled>
          </CustomCol>
          <CustomCol xs='4'>
            <Labeled
              label='Total'
            >
              <NumberBox
                readOnly
                value={parseFloat(getTotal('total').toFixed(2))} />
            </Labeled>
          </CustomCol>
        </RowContainer>
        <ValidationSummary />
      </ValidationGroup>
    </CustomModalDevx>
  );
};

export default AgregarDetalleLiquidacionMobil;
