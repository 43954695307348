import { configureStore, createReducer, createSlice } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import reducer from './reducers';
import { DashboardsState, GlobalState, TToaster } from './types';
import logger from 'redux-logger';
import { getDefaultMiddleware } from '@reduxjs/toolkit'
import { saveState } from './storageRedux'
import { throttle } from 'lodash';
import { contabilidadReducer } from '../modulos/contabilidad/store/contabilidadReducers'
import { ContabilidadState } from '../modulos/contabilidad/store/types'
import { moduloBancosReducer } from '../modulos/bancos/store/moduloBancosReducers';
import { toasterReducer } from './toasterReducer';
import { moduloVentasReducer } from '../modulos/ventas/store/moduloVentasReducer';
import { ModuloVentasState } from '../modulos/ventas/types/types';
import { ModuloBancosState } from '../modulos/bancos/store/types';
import { ModuloComprasState } from '../modulos/compras/types/types';
import { moduloComprasReducer } from '../modulos/compras/store/moduloComprasReducer';
import { moduloProveedoresReducer } from '../modulos/proveedores/store/moduloProveedoreseducers';
import { ModuloProveedoresState } from '../modulos/proveedores/store/types';
import { ModuloNominaState } from '../modulos/nomina/store/types';
import { ModuloNominaReducer } from '../modulos/nomina/store/moduloUsuariosReducers';
import { dashboardsReducer } from './dashboardsReducer';
import { ModuloAdminState } from '../modulos/admin/store/types';
import { moduloAdministracionReducer } from '../modulos/admin/store/moduloAdminReducers';
import { WizardState } from '../containers/component/manualConfig/store/types';
import { WizardReducer } from '../containers/component/manualConfig/store/WizardRecucers';
import { ModuloRestauranteState } from '../modulos/restaurante/types/types';
import { restauranteReducer } from '../modulos/restaurante/store/restauranteReducer';
import { sharedReducer } from '../modulos/shared/store/sharedReducer';
import { SharedState } from '../modulos/shared/types/types';
import { ModuloClientesState } from '../modulos/clientes/store/types';
import { moduloClientesReducer } from '../modulos/clientes/store/moduloClientesReducers';

export interface RootState {
  global: GlobalState,
  wizard: WizardState,
  ventas: ModuloVentasState,
  toaster: TToaster,
  contabilidad: ContabilidadState,
  bancos: ModuloBancosState,
  compras: ModuloComprasState,
  proveedores: ModuloProveedoresState,
  nomina: ModuloNominaState,
  dashboards: DashboardsState,
  administracion: ModuloAdminState,
  restaurante: ModuloRestauranteState,
  shared: SharedState,
  clientes: ModuloClientesState,
}

const appReducer = combineReducers<RootState>({
  global: reducer,
  wizard: WizardReducer,
  ventas: moduloVentasReducer,
  toaster: toasterReducer,
  contabilidad: contabilidadReducer,
  bancos: moduloBancosReducer,
  compras: moduloComprasReducer,
  proveedores: moduloProveedoresReducer,
  nomina: ModuloNominaReducer,
  dashboards: dashboardsReducer,
  administracion: moduloAdministracionReducer,
  restaurante: restauranteReducer,
  shared: sharedReducer,
  clientes: moduloClientesReducer
})

//const rootInitialState: { appState: RootState }

// const rootSlice = createSlice({
//   name: 'appState',
//   initialState: (undefined as unknown) as RootState,
//   reducers: {
//     resetRedux(state, action) {

//     }
//   }
// })

const rootReducer = (state, action) => {
  // if (action.type === 'global/onSesionIniciada') {

  //   return appReducer(state, action);
  // }
  return appReducer(state, action);
}

const middlewares = getDefaultMiddleware();


if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}


const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares
});

store.subscribe(throttle(() => {
  saveState(store.getState().global.session);
}, 1000))

export default store;