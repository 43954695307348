import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccionMenu, LoaderInfo } from "../../../../../store/types";
import { PageState, Seleccionado, Registros, Impuesto } from "./types";

const initialState: PageState = {
  modulo: 'contabilidad',
  currentAction: "Buscar",
  currentFunction: "",
  currentExecute: null,
  aplicacion: 'periodo',
  acciones: [],
  registros: [],
  impuestos: [],
  refrescaTabla: false,
  seleccionado: {
    index: null,
    row: null,
    selectedRow: null,
  },
  seleccionadoImp: {
    index: null,
    row: null,
    selectedRow: null,
  },
  loader: {
    show: false,
    mensaje: ''
  },
}

const periodosSlice = createSlice({
  name: "periodo",
  initialState: initialState,
  reducers: {
    obtenerAcciones(state, action: PayloadAction<Array<AccionMenu>>) {
      state.acciones = action.payload
    },
    setData(state, action: PayloadAction<Array<Registros>>) {
      state.registros = action.payload
    },
    setDataImpuestos(state, action: PayloadAction<Array<Impuesto>>) {
      state.impuestos = action.payload
    },
    setCurrentAccion(state, action: PayloadAction<string>) {
      state.currentAction = action.payload
    },
    setCurrentFunction(state, action: PayloadAction<string>) {
      state.currentFunction = action.payload
    },
    setSeleccionarDato(state, action: PayloadAction<Seleccionado>) {
      let toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionado = toAdd
    },
    setResetSeleccion(state) {
      let toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionado = toAdd
    },
    setSeleccionarDatoImp(state, action: PayloadAction<Seleccionado>) {
      let toAdd = {
        index: action.payload.index,
        row: action.payload.row,
        selectedRow: action.payload.selectedRow,
      }
      state.seleccionadoImp = toAdd
    },
    setResetSeleccionImp(state) {
      let toAdd = {
        index: null,
        row: null,
        selectedRow: null,
      }
      state.seleccionadoImp = toAdd
    },
    changeLoaderPeriodos(state, action: PayloadAction<LoaderInfo>) {
      state.loader = action.payload
    },
    setCurrentExecute(state, action: PayloadAction<any>) {
      state.currentExecute = action.payload
    },
  }
})

export const { setCurrentExecute, changeLoaderPeriodos, setCurrentFunction, setSeleccionarDatoImp, setResetSeleccionImp, setDataImpuestos, obtenerAcciones, setData, setCurrentAccion, setSeleccionarDato, setResetSeleccion } = periodosSlice.actions;
export const periodosReducer = periodosSlice.reducer;