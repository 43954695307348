//import moment, { Moment } from 'moment';
import { isMobile } from "react-device-detect";
import { SesionService } from "../services/sesion.service";
import { RequestHelper } from "./requestHelper";

export const utilidades = {
  sleep,
  unfreeze,
  getServerCurrentDateAsString,
  canUseNetApi,
  filtraTeclasNoNumericas,
  getGridFullWidth,
  filtrarTeclas,
  filtrarTeclasRangos,
  filtraTeclasNoNumericasAndKeyD,
  sortJSON
};

function sortJSON(data, key, orden) {
  return data.sort(function (a: Number, b: Number) {
    var x = a[key],
      y = b[key];

    if (orden === "asc") {
      return x < y ? -1 : x > y ? 1 : 0;
    }

    if (orden === "desc") {
      return x > y ? -1 : x < y ? 1 : 0;
    }
  });
};

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function getServerCurrentDateAsString() {
  const sesion = SesionService.getCurrentSesion();
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
    };
    const data = await RequestHelper.get<any>("getDate", "", "", obj);
    return data;
  } catch (error) {
    return error;
  }
}

function unfreeze<T>(o: T): T {
  var oo: any = undefined;
  if (o instanceof Array) {
    oo = [];
    var clone = function (v) {
      oo.push(v);
    };
    o.forEach(clone);
  } else if (o instanceof String) {
    // eslint-disable-next-line no-new-wrappers
    oo = new String(o).toString();
  } else if (typeof o == "object") {
    oo = {};
    for (var property in o) {
      oo[property] = o[property];
    }
  }
  return oo as T;
}

function canUseNetApi() {
  const canUse =
    (process.env.REACT_APP_USE_APINET ?? "false") === "false" ? false : true;
  return canUse;
}

function filtraTeclasNoNumericas(e) {
  if (
    !(
      (e.event.keyCode >= 96 && e.event.keyCode <= 107) ||
      (e.event.keyCode >= 48 && e.event.keyCode <= 57)
    )
  ) {
    if (
      e.event.keyCode !== 8 &&
      e.event.keyCode !== 9 &&
      e.event.keyCode !== 46 &&
      e.event.keyCode !== 37 &&
      e.event.keyCode !== 39
    ) {
      e.event?.preventDefault();
    }
  }
}

function filtrarTeclas(e, teclas: Array<number>) {
  if (teclas.findIndex((x) => x === e.event.keyCode) !== -1) {
    e.event?.preventDefault();
  }
}

function filtrarTeclasRangos(e, rangos: Array<{ min: number; max: number }>) {
  const keyCode = e.event.keyCode;
  for (const rango of rangos) {
    if (keyCode >= rango.min && keyCode <= rango.max) {
      e.event?.preventDefault();
      return;
    }
  }
}

function getGridFullWidth() {
  if (isMobile) {
    return window.innerWidth * 0.8;
  } else {
    return "100%";
  }
}

function filtraTeclasNoNumericasAndKeyD(e) {
  if (
    !(
      (e.event.keyCode >= 96 && e.event.keyCode <= 107) ||
      (e.event.keyCode >= 48 && e.event.keyCode <= 57)
    )
  ) {
    if (
      e.event.keyCode !== 8 &&
      e.event.keyCode !== 9 &&
      e.event.keyCode !== 46 &&
      e.event.keyCode !== 37 &&
      e.event.keyCode !== 39 &&
      e.event.keyCode !== 68 &&
      e.event.keyCode !== 13 &&
      e.event.keyCode !== 190 &&
      e.event.keyCode !== 188 &&
      e.event.keyCode !== 110
    ) {
      e.event?.preventDefault();
    }
  }
}
