import { CCol, CFormGroup, CLabel, CRow } from '@coreui/react';
import * as React from 'react';
import { NumberBox } from 'devextreme-react/number-box'
import { RootState } from '../../../../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { CuadresService } from '../../../../../services/cuadres.service';
import { DateUtils, formatoFechasApi } from '../../../../../../../../../helpers/dateUtils';
import {
  updateDpCierre10Only, updateDptotales10Only, updateDptotalesOnly,
  updateTotalTesoreria_tiOnly
} from '../../../store/editDataReducer';
import DataGrid, {
  Column, HeaderFilter, Paging, Pager,
  Grouping,
  GroupPanel, ColumnChooser
} from 'devextreme-react/data-grid';
import RowContainer from '../../../../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../../../../views/componentes/colContainer';
import { utilidades } from '../../../../../../../../../helpers/utilidades';
import { useGridResultadosSize } from '../../../../../../../../../hooks/useGridResultadosSize';
import { getAllowedPageSizes } from '../../../../../../../../../helpers/Helper';
import { AbonoComprasCuadresCajaListado, ccDetalle, formasPagoCuadre } from '../../../../../types/types';
import TabFormasPago from '../componentes/tabFormasPago/tabFormasPago';

interface IPagosTesoreriaProps {
  setToast: (texto, type) => void,
  playLoader: () => void,
  stopLoader: () => void,
  onErrorConfirm: (sms, title) => void,
  onConfirmarRegistro: (sms, method) => void
  tabId: string,
}

const PagosTesoreria: React.FunctionComponent<IPagosTesoreriaProps> = (props) => {
  const {
    tabId, setToast, playLoader, stopLoader,
    onErrorConfirm, onConfirmarRegistro
  } = props;
  const dataGrid = React.useRef<any>();

  const dispatch = useDispatch();
  const [heigth] = useGridResultadosSize();

  const local = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].local });
  const puntoVenta = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[tabId].puntoVenta });
  const fechaCierre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].fechaCierre });
  const venInicial10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venInicial10 });
  const venFinal10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].venFinal10 });
  const generarCuadre = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].generarCuadre });
  const totaltesoreria_ti = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].totaltesoreria_ti });
  const dptotales10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales10 });
  const dpfpagos = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpfpagos });
  const dptotales = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dptotales });
  const dpcierre10 = useSelector((state: RootState) => { return state.ventas.cuadres.cuadresCaja.editData[props.tabId].dpcierre10 });

  const [displayMode,] = React.useState<string | 'full' | 'compact'>('full');
  const [showInfo,] = React.useState<boolean>(true);
  const [showNavButtons,] = React.useState<boolean>(true);
  const [showPageSizeSelector,] = React.useState<boolean>(true);

  const resultadoCuadresAbonos = React.useCallback(async (dpCierreFacturasVar: Array<AbonoComprasCuadresCajaListado> | []) => {
    console.log("resultadoCuadresAbonos")
    console.log("dpCierreFacturasVar", dpCierreFacturasVar)
    console.log("dpfpagos", dpfpagos);

    let detalle = {
      ccdCodigo: 0,
      ccaCodigo: "",
      subValor: "",
      ccdValor: 0,
      subCodigo: 0,
    };

    let valor: number = 0;
    const dptotales10Local: Array<formasPagoCuadre> = [];
    if (dpCierreFacturasVar.length === 0) {
      for (const fpg of dpfpagos) {
        dptotales10Local.push({
          subCodigo: Number(fpg.codigo) ?? -1,
          subValor: String(fpg.descripcion) ?? "",
          ccdValor: valor,
        });
      }

      console.log("dptotales10Local", dptotales10Local)
      dispatch(updateDptotales10Only({
        dptotales10: dptotales10Local,
        key: tabId
      }));

    } else {

      let i = 0;

      for (let j = 0; j <= dpfpagos.length - 1; j++) {
        valor = 0;
        for (i = 0; i < dpCierreFacturasVar.length; i++) {
          if (dpCierreFacturasVar[i].compraTipoDocumentoCodigo === dpfpagos[j].codigo) {
            valor = valor + Number(Number(dpCierreFacturasVar[i].abonoCuotaValor).toFixed(2));
          }
        }

        dptotales10Local.push({
          subCodigo: Number(dpfpagos[j].codigo),
          subValor: String(dpfpagos[j].descripcion),
          ccdValor: Number(Number(valor).toFixed(2)),
        });

      }

      console.log("dptotales10Local", dptotales10Local)
      dispatch(updateDptotales10Only({
        dptotales10: dptotales10Local,
        key: tabId
      }));

      let k = 0;

      let total: number = 0;
      for (k = 0; k < dptotales10Local.length; k++) {
        total = total + dptotales10Local[k].ccdValor;
      }
      total = Number(Number(total).toFixed(2));
      dispatch(updateTotalTesoreria_tiOnly({
        totaltesoreria_ti: total,
        key: tabId
      }));

      let dptotalesLocal: any = [];
      console.log("dptotales10Local ", dptotales10Local);
      if (dptotales10Local.length > 0) {
        for (i = 0; i < dpfpagos.length; i++) {
          let x: ccDetalle | null = null;
          try {
            x = dptotales[i];
          } catch (ex) {
            x = null;
          }
          if (x == null) {
            let addDetallTotales: ccDetalle = {
              ...detalle,
              ccdValor: Number(Number(dptotales10Local[i].ccdValor).toFixed(2)),
              subCodigo: dpfpagos[i].codigo,
              subValor: dpfpagos[i].descripcion,
            }
            dptotalesLocal.push({ ...addDetallTotales });
          } else {
            let y: number = Number((x.ccdValor - Number(dptotales10Local[i].ccdValor)).toFixed(2));
            x.ccdValor = y;
            dptotalesLocal[i] = x;
          }

        }

        dispatch(updateDptotalesOnly({
          dptotales: dptotalesLocal,
          key: tabId
        }));

      }
    }


  }, [dispatch, tabId, dpfpagos, dptotales]);

  const getPagosCuadres = React.useCallback(async () => {
    console.log("getPagosCuadres");
    let fechaFilter = DateUtils.format(fechaCierre, formatoFechasApi);
    let dpCierreRet = await CuadresService.getPagosCuadres(fechaFilter, local?.codigo ?? -1, puntoVenta?.codigoPuntoVenta ?? -1, venInicial10, venFinal10);
    console.log("getPagosCuadres dpCierreRet", dpCierreRet);

    dispatch(updateDpCierre10Only({
      dpcierre10: dpCierreRet,
      key: tabId
    }));

    await resultadoCuadresAbonos(dpCierreRet)
  }, [
    fechaCierre, tabId, dispatch, local, puntoVenta, venInicial10, venFinal10, resultadoCuadresAbonos
  ]);

  React.useEffect(() => {
    if (generarCuadre === true) {
      getPagosCuadres();
    }
  }, [generarCuadre])

  return (
    <>
      <CRow className={'m-2'}>
        <CCol lg="12" xl="12" sm="12">


          <RowContainer >
            <CustomCol xs='12' md='12'   >

              <DataGrid
                selection={{ mode: 'single' }}
                focusedRowEnabled={true}
                keyExpr='compraCodigo'
                dataSource={dpcierre10}
                showBorders={true}
                width={utilidades.getGridFullWidth()}
                allowColumnResizing
                columnResizingMode='widget'
                height={heigth}
                ref={dataGrid}
              >
                <GroupPanel visible={true} />
                <Grouping autoExpandAll={true} />
                <Paging defaultPageSize={20}/>
                <Pager
                  visible={dpcierre10.length > 0 ? true : false}
                  allowedPageSizes={getAllowedPageSizes(dpcierre10)}
                  displayMode={displayMode}
                  showPageSizeSelector={showPageSizeSelector}
                  showInfo={showInfo}
                  showNavigationButtons={showNavButtons}
                />
                <HeaderFilter visible={true} />
                <ColumnChooser enabled={true} mode="select" />
                <Column dataField='egresoValor' caption='' width='20%' allowEditing={false} groupIndex={0} />
                <Column dataField='compraFecha' caption='Fecha de Registro' width='20%' allowEditing={false} />
                <Column dataField='proveedorNombre' caption='Proveedor' width='20%' allowEditing={false} />
                <Column dataField='vencimiento' caption='Vencimiento' width='15%' allowEditing={false} />
                <Column dataField='abonoFecha' caption='Fecha Abono' allowEditing={false} width={'15%'} />
                <Column dataField='compraTipoDocumentoDescripcion' caption='Comprobante' width='15%' allowEditing={false} visible={false} />
                <Column dataField='puntoVentaHost' caption='POS' width='15%' allowEditing={false} />
                <Column dataField='compraNumero' caption='Número' width='20%' allowEditing={false} visible={false} />
                <Column dataField='compraFormaPagoDescripcion' caption='Forma Pago' width='20%' allowEditing={false} visible={false} />
                <Column dataField='abonoCompraTipoPagoDocumentoDescripcion' caption='Doc.' width='20%' allowEditing={false} visible={false} />
                <Column dataField='compraCuota' caption='Cuota' width='20%' allowEditing={false} />
                <Column dataField='abonoCuotaValor' caption='Pago' width='20%' allowEditing={false} />
                <Column dataField='pagoCuotaSaldo' caption='Saldo' width='20%' allowEditing={false} />

              </DataGrid>

            </CustomCol>
          </RowContainer>

        </CCol>
      </CRow>

      <CRow >
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">


          <CCol lg="3" md="3" sm="12" className="d-flex justify-content-end  align-items-center">
            <TabFormasPago
              tabId={tabId}
              dptotales={dptotales10}
            />
          </CCol>

        </CCol>
      </CRow>


      <CRow >
        <CCol lg="12" md="12" sm="12" className="d-flex justify-content-end  align-items-center">

          <CCol lg='2' md="2" xs="12">
            <CFormGroup>
              <CLabel htmlFor="total" className="col-form-label-md">
                <strong> {"Total"} </strong>
              </CLabel>
              <CRow>
                <CCol md="12">
                  <NumberBox
                    readOnly
                    width="100%"
                    value={totaltesoreria_ti}
                    placeholder=""
                    format="#,##0.00"
                  />
                </CCol>
              </CRow>
            </CFormGroup>
          </CCol>

        </CCol>
      </CRow>

    </>
  )
}
export default PagosTesoreria;
