import * as React from 'react';
import { Ciudad } from '../../store/types';
import { Cliente, TipoCliente, TipoIdentificacion } from '../../types/types';
import { useDispatch } from 'react-redux';
import { localidadesService } from '../../../../services/localidades.service';
import { ClientesService } from '../../services/clientes.service';
import { addToast } from '../../../../store/toasterReducer';
import { ToastTypes } from '../../../../store/types';
import { CAlert } from '@coreui/react';
import RowContainer from '../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../views/componentes/colContainer';
import Labeled from '../../../../views/componentes/labeledInput/labeledInput';
import SelectBox from '../../../../views/componentes/selectBox/selectBox';
import TextBox from 'devextreme-react/text-box';
import {
  Validator,
  RequiredRule,
  PatternRule,
  AsyncRule,
  StringLengthRule
} from 'devextreme-react/validator';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ValidationSummary from 'devextreme-react/validation-summary';
import { lh, MessagesKeys } from '../../../../helpers/localizationHelper';
import { CustomModalDevx } from '../../../../views/componentes/modal/Modal';
import { isMobile } from 'react-device-detect';
import TextArea from 'devextreme-react/text-area';
import { BuscarCiudadLookUp } from '../../../shared/components/buscarCiudad/lookUpBuscarCiudad';
import { GeneralService } from '../../../../services/general.service';
import { LoadPanel } from 'devextreme-react/load-panel';
import { PopupContent } from '../../../../views/componentes/popupContent';
import { utilidades } from '../../../../helpers/utilidades';
import { useModalConvertSize } from '../../../../hooks/useModalSize';
import { isMobileOnly } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';

//const sms = validations['es']
//const position = { of: '#modificarCliente' };
export interface IModificarClienteProps {
  identificacion: string,
  onChanged: (newData: Cliente, isNew: boolean) => void;
  onCancel: () => void;
  //tabId: number,
  show: boolean,
}

type DatosModificarCliente = {
  tipoCliente: TipoCliente | null,
  tipoIdentificacion?: TipoIdentificacion | null,
  ruc?: string,
  nombres?: string | null,
  nombreComercial?: string | null,
  email?: string | null,
  telefono?: string | null,
  telefonoBackup?: string | null,
  direccion?: string,
  ciudad: Ciudad | null
}
// const rucRegex: RegExp = new RegExp('^[0-9]{13}$');
// const cedulaRegex: RegExp = new RegExp('^[0-9]{10}$');
export const emailsRegex = new RegExp("^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,}[\;]?)+){1,}$");
export const emailPatern = /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,}[\;]?)+){1,}$/
type ErrorInfo = {
  tieneError: boolean,
  mensaje?: string
}
const TIPOSIDENTIFICACION = [
  {
    codigo: '01',
    tipo: 'RUC'
  },
  {
    codigo: '02',
    tipo: 'CEDULA'
  },
  {
    codigo: '03',
    tipo: 'PASAPORTE'
  }
]

export function ModificarCliente(props: IModificarClienteProps) {
  const { show, identificacion, onCancel, onChanged } = props;
  const dispatch = useDispatch();
  const [modalSize] = useModalConvertSize("lg")
  const [tiposIdentificacion,] = React.useState<Array<TipoIdentificacion>>(TIPOSIDENTIFICACION)
  const [loading, setLoading] = React.useState<boolean>(false);
  const [codigo, setCodigo] = React.useState<number>(0);
  const [ruc, setRuc] = React.useState('');
  const [nombres, setNombres] = React.useState('');
  const [nombreComercial, setNombreComercial] = React.useState<string | null>('');
  const [tipoCliente, setTipocliente] = React.useState<TipoCliente | null>(null);
  const [tipoIdentificacion, setTipoIdentificacion] = React.useState<TipoIdentificacion | null>(null);
  const [tiposClientes, setTiposClientes] = React.useState<Array<TipoCliente>>([]);
  const [email, setEmail] = React.useState<string>();
  const [telefonoBackup, setTelefonoBackup] = React.useState<string>('');
  const [telefono, setTelefono] = React.useState<string>('');
  const [direccion, setDireccion] = React.useState('');
  const [ciudad, setCiudad] = React.useState<Ciudad | null>(null);
  const [esNuevo, setEsnuevo] = React.useState(false);
  const [errorInfo, seterrorInfo,] = React.useState<ErrorInfo>({ tieneError: false });
  //const [validadoIdentificacionClienteNuevo, setvalidadoIdentificacionClienteNuevo] = React.useState(true);
  //const local = useSelector((state: RootState) => { return state.global.session?.local });
  const validationGroupRef = React.useRef<any>();
  const [showErrorPopup, setShowErrorPopup] = React.useState<boolean>(false);
  const [showErrorMessages, setShowErrorMessages] = React.useState<Array<any>>([]);

  const validate = React.useCallback(async () => {
    let resolved = false;
    let status = false;
    const p = new Promise(async (resolve) => {
      while (!resolved) {
        await utilidades.sleep(50);
      }
      resolve(resolved);
    });
    const validationResult = validationGroupRef.current.instance.validate();
    if (!validationResult.isValid && validationResult.status !== 'pending') {
      //setTieneErroresValidacion(true); 
      setShowErrorMessages(validationResult.brokenRules)
      return false;
    }
    if (validationResult.status === 'pending') {
      validationResult.complete.then(async resolve => {
        await resolve;
        status = resolve.isValid;
        resolved = true;
      });
      await p;
      return status;
    }
    return true;
  }, [])

  const validateId = React.useCallback(async (params, esNuevo) => {
    const validacionIdentificacion = await GeneralService.validarIndentificacion(params.value ?? '');
    if (validacionIdentificacion.isValid) {
      if (esNuevo) {
        const clientes = await ClientesService.consultarClientes({ nombre: '', identificacion: params.value ?? '' });
        if (clientes.length > 0) return Promise.reject(`El cliente con cedula ${(params.value ?? '')} ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`)
      }
      return Promise.resolve();
    } else {
      return Promise.reject(validacionIdentificacion.motivo ?? 'Error al validar identificación');
    }
  }, []);


  const handleSubmit = React.useCallback(async (values: DatosModificarCliente, verificarNuevo: boolean) => {
    setLoading(true);

    const validationResult = await validate();

    if (!validationResult) {
      setShowErrorPopup(true)
      setLoading(false);
      return false;
    }
    if (verificarNuevo) {
      if (tipoIdentificacion && tipoIdentificacion.codigo !== "03") {
        const cliente = await ClientesService.consultarClientes({ nombre: '', identificacion: values.ruc ?? '' });
        setLoading(false);
        if (cliente && cliente.length > 0) {
          dispatch(addToast({
            title: 'Crear cliente',
            content: `'El cliente con cedula ${(values.ruc ?? '')} ya se encuentra registrado, si desea modificar los datos debe dar clic en la opción de edición.`,
            type: ToastTypes.Danger,
            autoHide: 3500,
          }))
          return;
        }
      }
    }
    const cliente: Cliente = {
      codigo: verificarNuevo ? 0 : codigo,
      identificacion: values.ruc ?? '',
      nombres: values.nombres ?? '',
      nombreComercial: values.nombreComercial ?? '',
      direccion: values.direccion ?? '',
      telefono: values.telefono + "||" + values.telefonoBackup,
      email: values.email ?? '',
      descuento: 0,
      ciudad: values.ciudad?.nombre ?? '',
      ciudadCodigo: values.ciudad?.codigo ?? 0,
      tipoCliente: values.tipoCliente?.codigo ?? 0,
      tipoIdentificacion: values.tipoIdentificacion?.codigo ?? "01"
    };
    try {
      const respuesta = await ClientesService.modificarCliente(cliente);
      setLoading(false);
      if (respuesta['error'] === false) {
        setToast(respuesta['message'], "success")
        onChanged({ ...cliente, codigo: parseInt(respuesta['auto'].codigo as any), telefono: values.telefono || null }, verificarNuevo);
      } else {
        seterrorInfo({ tieneError: true, mensaje: 'Error al guardar el cliente, \n' + respuesta['message'] });
        setToast(respuesta['message'], "danger")
      }

      // validationResult.complete.then(async resolve => {
      //   await resolve;
      //   if (!resolve.isValid) {
      //     setLoading(false);
      //     return;
      //   }

      // }, reject => {
      //   setLoading(false);
      // })
    } catch (error) {
      setLoading(false);
      seterrorInfo({ tieneError: true, mensaje: 'Error al guardar el cliente, \n' + error });
    }
  }, [validate, onChanged, codigo, dispatch]);

  const setToast = (texto, type) => {
    let tipo: ToastTypes = ToastTypes.Info;
    if (type === 'warning') {
      tipo = ToastTypes.Warning;
    } else if (type === 'success') {
      tipo = ToastTypes.Success;
    } if (type === 'info') {
      tipo = ToastTypes.Info;
    } if (type === 'danger') {
      tipo = ToastTypes.Danger;
    }
    dispatch(addToast({
      id: '',
      autoHide: 3500,
      title: 'Acatha',
      content: texto,
      fade: true,
      type: tipo
    }));
  }

  const cargarCliente = React.useCallback(async (identificacion: string) => {
    try {
      setLoading(true);
      //await utilidades.sleep(25000);
      const tipos = await ClientesService.getTiposClienntes();
      const busqueda = identificacion === "" ? null : await ClientesService.getCliente(identificacion);
      setLoading(false);
      if (!busqueda) {
        setEsnuevo(true);
        setCodigo(0);
        setRuc(identificacion);
        setNombres('');
        setNombreComercial('');
        setTipocliente(tipos[0]);
        setTipoIdentificacion(identificacion.length === 13 ? tiposIdentificacion[0] : tiposIdentificacion[1]);
        setEmail('');
        setTelefono('');
        setTelefonoBackup('')
        setDireccion('');
        const ciudadLocal = localidadesService.getCiudadLocalActual();
        if (ciudadLocal) {
          setCiudad(ciudadLocal);
        }

      } else {
        const cliente = busqueda;
        const ciudad = await localidadesService.getCiudad(cliente.ciudadCodigo);
        setEsnuevo(false);
        setCodigo(cliente.codigo);
        setRuc(cliente.identificacion);
        setNombres(cliente.nombres);
        setNombreComercial(cliente.nombreComercial);
        setEmail(cliente.email ?? undefined);
        setTelefono(cliente.telefono ?? '');
        setTelefonoBackup(cliente.telefono ?? '')
        setDireccion(cliente.direccion);
        setCiudad(ciudad);
        setTipocliente(tipos.find(x => x.codigo === cliente.tipoCliente) ?? null);
        if (tiposIdentificacion.length > 0 && cliente.identificacion !== null) {
          setTipoIdentificacion(cliente.identificacion.length === 10 ?
            tiposIdentificacion[1] :
            (cliente.identificacion.length === 13 ?
              tiposIdentificacion[0] : tiposIdentificacion[2]))
        }
      }
      setTiposClientes(tipos);
    } catch (error) {
      setLoading(false);
      dispatch(addToast({
        id: '',
        autoHide: true,
        content: 'Error al cargar cliente. Error:\n' + error,
        fade: true,
        title: 'Clientes',
        type: ToastTypes.Danger
      }))

    }
    setLoading(false);
  }, [dispatch, tiposIdentificacion]);

  const getMessage = (mensaje, replace) => {
    let message = mensaje.replace("@dinamic_var@", replace);
    return message;
  }


  const inputDraw = () => {
    if (tipoIdentificacion && tipoIdentificacion.codigo === "02") {
      return (inputIdentificacionCedula())
    } else if (tipoIdentificacion && tipoIdentificacion.codigo === "01") {
      return (inputIdentificacionRuc())
    } else {
      return (
        inputIdentificacionPasaporte()
      )
    }
  }

  const inputIdentificacionCedula = () => {
    return (
      <div key={'textCedula'}>
        <TextBox
          name='ruc'
          placeholder='R.U.C / Identificación'
          value={ruc}
          onValueChanged={({ value }) => setRuc(value)}
        >
          <Validator>
            <RequiredRule trim message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionRequerido)} />
            <StringLengthRule max='10' message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} />
            <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionIncorrecto, "")} reevaluate={true} ignoreEmptyValue validationCallback={(params) => validateId(params, esNuevo)} />
          </Validator >
        </TextBox>
      </div>
    )
  }

  const inputIdentificacionRuc = () => {
    return (
      <div key={'textRuc'}>
        <TextBox
          name='ruc'
          placeholder='R.U.C / Identificación'
          value={ruc}
          onValueChanged={({ value }) => setRuc(value)}
        >
          <Validator>
            <PatternRule message={lh.getMessage(MessagesKeys.ClienteNumeroRucIncorrecto, "")} pattern='^[0-9]{5,}|^[0-9]{10}$|^[0-9]{13}$' />
            <AsyncRule message={lh.getMessage(MessagesKeys.ClienteNumeroRucIncorrecto, "")} reevaluate={true} ignoreEmptyValue validationCallback={(params) => validateId(params, esNuevo)} />
            <StringLengthRule min='13' message={lh.getMessage(MessagesKeys.ClienteNumeroRucIncorrecto, "")} />
          </Validator>
        </TextBox>
      </div>
    )
  }

  const loadPanelShow = () => {
    return (
      <div>
        <LoadPanel
          id='loadingPanelDevx'
          shadingColor="rgba(0,0,0,0.4)"
          visible={loading}
          showIndicator={true}
          shading={true}
          showPane={true}
          closeOnOutsideClick={false}
        />
      </div>
    )
  }

  const inputIdentificacionPasaporte = () => {
    return (
      <div key='textPasaporte'>
        <TextBox
          name='ruc'
          placeholder='R.U.C / Identificación'
          value={ruc}
          onValueChanged={({ value }) => setRuc(value)}
        >
          <Validator>
            <RequiredRule trim message={lh.getMessage(MessagesKeys.ClienteNumeroPasaporteRequerido, "")} />
            <StringLengthRule
              max='15'
              message={
                getMessage(lh.getMessage(MessagesKeys.ClienteNumeroIdentificacionMax, ""), 15)
              }
              ignoreEmptyValue
            />
          </Validator>
        </TextBox>
      </div>
    )
  }

  React.useEffect(() => {
    if (show) {
      cargarCliente(identificacion);
    }
  }, [cargarCliente, show, identificacion]);

  if (!show) {
    return null;
  }

  return (
    <>
      {loading && loadPanelShow()}
      <CustomModalDevx
        size={modalSize}
        show={show}
        title='Administración de cliente'
        onClose={onCancel}
        toolbarItems={[
          {
            options: {
              accessKey: 'btnAceptarCliente',
              id: 'btnAceptarCliente',
              text: 'Aceptar',
              stylingMode: 'contained',
              type: 'default',
              icon: 'check',
              onClick: () => handleSubmit(
                {
                  tipoIdentificacion: tipoIdentificacion,
                  tipoCliente: tipoCliente,
                  ciudad: ciudad,
                  direccion: direccion,
                  email: email,
                  nombreComercial: nombreComercial,
                  nombres: nombres,
                  ruc: ruc,
                  telefono: telefono,
                  telefonoBackup: telefonoBackup
                }, esNuevo)
            },
            widget: 'dxButton',
            toolbar: 'bottom',
            location: 'after'
          },
          {
            options: {
              id: 'btnCancelarCliente',
              text: 'Cancelar',
              stylingMode: 'contained',
              type: 'danger',
              icon: 'remove',
              onClick: onCancel
            },
            widget: 'dxButton',
            toolbar: 'bottom',
            location: 'after'
          },
        ]}
        height={!isMobileOnly ? 400 : undefined}
      >





        <fieldset>
          <ValidationGroup id={`valGroupModificarCliente${props.identificacion}`} ref={validationGroupRef}>
            {errorInfo.tieneError &&
              <CAlert
                key='modificaClienteErrorAlert'
                color='danger'
                show={10000}
                closeButton
                onShowChange={() => { seterrorInfo({ tieneError: false }) }}
              >
                {errorInfo.mensaje}
              </CAlert>
            }
            <RowContainer>
              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Tipo identificación'
                >
                  <SelectBox
                    id='selectTiposIdentificacion'
                    options={tiposIdentificacion}
                    placeholder='Tipo de indetificación'
                    onChange={(value) => {
                      console.log("value", value)
                      if (value) {
                        console.log("if")
                        //setFieldValue('tipoIdentificacion', value[0], true);
                        setTipoIdentificacion(value);
                        setRuc("")
                      } else {
                        console.log("else")
                        //setFieldValue('tipoIdentificacion', null);
                        setRuc("")
                        setTipoIdentificacion(null);
                      }
                    }}
                    selected={tipoIdentificacion}
                    displayExpr='tipo'
                    keyExpr='codigo'
                  >
                    <Validator>
                      <RequiredRule message={lh.getMessage(MessagesKeys.ClienteTipoIdentificacionRequerida)} />
                    </Validator>
                  </SelectBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs='12' md='8'>
                <Labeled
                  label='Tipo Cliente'
                >
                  <SelectBox
                    id='selectTiposCliente'
                    options={tiposClientes}
                    placeholder='Tipo de cliente'
                    onChange={(value) => {
                      if (value) {
                        //setFieldValue('tipoCliente', value);
                        setTipocliente(value);
                      } else {
                        setTipocliente(null);
                        //setFieldValue('tipoCliente', null);
                      }
                    }}
                    selected={tipoCliente}
                    displayExpr='descripcion'
                    keyExpr='codigo'
                  >
                    <Validator>
                      <RequiredRule trim message={lh.getMessage(MessagesKeys.ClienteTipoClienteRequerido)} />
                    </Validator>
                  </SelectBox>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Identificación'
                >
                  {inputDraw()}
                </Labeled>
              </CustomCol>
              <CustomCol xs='12' md='8'>
                <Labeled
                  label='Nombres'
                >
                  <TextBox
                    name='nombres'
                    placeholder='Nombres'
                    value={nombres}
                    onValueChanged={({ value }) => setNombres(value)}
                    inputAttr={{
                      'autocomplete': "nope"
                    }}
                  >
                    <Validator>
                      <RequiredRule trim message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "nombre")} />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Ciudad'
                >
                  <BuscarCiudadLookUp value={ciudad} onChanged={(newValue) => setCiudad(newValue)} />
                </Labeled>
              </CustomCol>
              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Teléfono'
                >
                  <TextBox
                    value={telefono}
                    onValueChanged={({ value }) => setTelefono(value)}
                    inputAttr={{
                      'autocomplete': "nope"
                    }}
                  >
                    <Validator>
                      <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Teléfono")} />
                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
              <CustomCol xs='12' md='4'>
                <Labeled
                  label='Email'
                >
                  <TextBox
                    value={email}
                    onValueChanged={({ value }) => setEmail(value)}
                    inputAttr={{
                      'autocomplete': "nope"
                    }}
                  >
                    <Validator>
                      <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Email")} />
                      <PatternRule pattern={emailPatern} message={lh.getMessage(MessagesKeys.GlobalFormatoCampoIncorrecto, "Email")} />

                    </Validator>
                  </TextBox>
                </Labeled>
              </CustomCol>
            </RowContainer>
            <RowContainer>
              <CustomCol xs='12'>
                <Labeled
                  label='Dirección'
                >
                  {isMobile &&
                    <TextArea
                      value={direccion}
                      onValueChanged={({ value }) => setDireccion(value)}
                      height={90}
                      inputAttr={{
                        'autocomplete': "nope"
                      }}
                    >
                      <Validator>
                        <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Dirección")} />
                      </Validator>
                    </TextArea>
                  }
                  {!isMobile &&
                    <TextBox
                      value={direccion}
                      onValueChanged={({ value }) => setDireccion(value)}
                      inputAttr={{
                        'autocomplete': "nope"
                      }}
                    >
                      <Validator>
                        <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Dirección")} />
                      </Validator>
                    </TextBox>
                  }

                </Labeled>
              </CustomCol>
            </RowContainer>



            {!isMobileOnly && (
              <>
                <RowContainer className="ml-2">
                  <CustomCol>
                    <ValidationSummary />
                  </CustomCol>
                </RowContainer>
              </>
            )}

            {isMobileOnly &&
              (
                <>
                  <RowContainer className="ml-2">
                    <CustomCol>
                      <ValidationSummary />
                    </CustomCol>
                  </RowContainer>
                  {showErrorPopup && (
                    < PopupContent
                      show={showErrorPopup}
                      title={"Acatha"}
                      subTitle={"Por favor revise las siguientes advertencias:"}
                      onClose={() => {
                        setShowErrorPopup(false);
                        setShowErrorMessages([]);
                      }}
                      size={"sm"}
                      canClose={true}
                    >
                      <CAlert color='danger'>
                        <ul className="errorList">
                          {showErrorMessages.map(function (item, id) {
                            return <li style={{ marginLeft: '-35px;' }} key={id}>{item['message']}</li>
                          })}
                        </ul>
                      </CAlert>
                    </PopupContent>
                  )}
                </>
              )
            }

          </ValidationGroup>
        </fieldset >
      </CustomModalDevx >
    </>
  )
}