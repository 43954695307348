import { combineReducers, } from "@reduxjs/toolkit";
import { ventasReducer } from "../pages/ventas/store/ventasReducer";
import { notasCreditoReducer } from "../pages/notasCredito/store/notasCreditoReducer";
import { notasDebitoReducer } from "../pages/notasDebito/store/notasDebitoReducer";
import { guiasRemisionReducer } from '../pages/guiasRemision/store/guiasReducer'
import { ModuloVentasState } from "../types/types";
import { retencionesVentasReducer } from "../pages/retenciones/store/retencionesVentasReducer";
import { liquidacionesReducer } from "../pages/liquidaciones/store/liquidacionesReducer";
import { cuadresReducer } from "../pages/cuadres/store/cuadresReducer";

// const initialState: VentasState{

// }


export const moduloVentasReducer = combineReducers<ModuloVentasState>({
  ventas: ventasReducer,
  notasCredito: notasCreditoReducer,
  notasDebito: notasDebitoReducer,
  guiasRemision: guiasRemisionReducer,
  retenciones: retencionesVentasReducer,
  liquidaciones: liquidacionesReducer,
  cuadres: cuadresReducer
})
