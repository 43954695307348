import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/store";
import { Autorizacion } from "../../../../store/types";
import { ConfiguracionesComandas, ConfiguracionesModal } from "../types/types";

const initialState: ConfiguracionesComandas = {
  usaDescuentoGlobal: false,
  autorizacion: null,
  modalDetalleOrdenConfiguracion: {
    open: false,
    name: "",
    info: null
  },
  modalVentaConfiguracion: {
    open: false,
    name: "",
    info: null
  },
  comandaSeleccionada: null
}

const configuracionesComandasSlice = createSlice(
  {
    name: 'configuraciones',
    initialState: initialState,
    reducers: {
      initialize(state, acion: PayloadAction<Autorizacion>) {
        state.autorizacion = acion.payload;
      },
      toogleUsaDescuentoGlobal(state, acion: PayloadAction) {
        state.usaDescuentoGlobal = !state.usaDescuentoGlobal;
      },
      toogleModalFacturacion(state, acion: PayloadAction<ConfiguracionesModal>) {
        state.modalVentaConfiguracion = acion.payload;
      },
      toogleModalDetalleOrden(state, acion: PayloadAction<ConfiguracionesModal>) {
        state.modalDetalleOrdenConfiguracion = acion.payload;
      },
      seleccionarComanda(state, acion: PayloadAction<any>) {
        state.comandaSeleccionada = acion.payload;
      }
    }
  }
)

export const selectUsaDescuentoGlobal = (state: RootState) => { return state.restaurante.comandas.configuraciones.usaDescuentoGlobal };
export const configuracionesComandaReducer = configuracionesComandasSlice.reducer;
export const {
  toogleUsaDescuentoGlobal, toogleModalFacturacion, toogleModalDetalleOrden, seleccionarComanda
} = configuracionesComandasSlice.actions;