import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store/store";
import { TiposComprobantesSri } from "../../../../../store/types";
import { ButtonTypes } from "../../../../../views/componentes/globalMenu/types";
import { StatesEdition, TabTypes } from "../../../types/enums";
import { CustomButtons, TabsState, TabState } from "../../../types/generics";
import { GuiaInfo } from "../../../types/types";
import { ventasCloseTab, existeTab } from "../../../store/reducers";
import { RemisionGuideButtons } from "../../../components/guiasRemision/guiaRemision/guiaRemision";

const initialState: TabsState<GuiaInfo> = {
  current: "BUSQUEDA",
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: "Búsqueda",
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: "BUSQUEDA",
      buttons: {
        Nuevo:true,
        Buscar:true,
        Exportar:false
      },
    },
  },
};

const tabsGuiasSlice = createSlice({
  name: "guiasTabs",
  initialState: initialState,
  reducers: {
    ventasCloseTab,
    openTab(state, action: PayloadAction<{ key: string; guia?: GuiaInfo }>) {
      const { guia, key } = action.payload;
      if (guia === undefined) {
        const tab = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: "Nueva",
          type: TabTypes.documento,
          imprimir: false,
          tabKey: action.payload.key,

          info: {
            claveAcceso: "",
            numero: 0,
            tipoComprobante: TiposComprobantesSri.GuiaRemision,
          },
          buttons: RemisionGuideButtons,
        };
        state.tabs[key] = tab;
        state.current = action.payload.key;
      } else {
        const tabKey = existeTab(state.tabs, guia.codigo);
        if (tabKey) {
          state.current = tabKey;
        } else {
          const tab: TabState<GuiaInfo> = {
            tabKey: action.payload.key,
            id: guia.codigo,
            tittle: `${guia.establecimiento}-${guia.puntoEmision}-${guia.numero}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              claveAcceso: guia.claveAcceso,
              numero: guia.codigo,
              tipoComprobante: TiposComprobantesSri.Factura,
              info: action.payload.guia,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons: RemisionGuideButtons,
          };
          state.tabs[key] = tab;
          state.current = key;
        }
      }
      // if (action.payload.guia == null) {
      //   // if (existeTabNuevo(state.tabs as any)) {
      //   //   state.current = state.tabs.findIndex((t) => t.id === -1);
      //   //   return;
      //   // }
      //   const tab = {
      //     id: -1,
      //     canClose: true,
      //     editStatus: StatesEdition.new,
      //     globalButtonClick: ButtonTypes.none,
      //     tittle: "Nueva",
      //     type: TabTypes.documento,
      //     imprimir: false,
      //     tabKey: action.payload.key,
      //     info: {
      //       claveAcceso: "",
      //       numero: 0,
      //       tipoComprobante: TiposComprobantesSri.GuiaRemision,
      //       canSendAutorizacion: false,
      //       canSendMail: false,
      //       canInactive: false,
      //     },
      //   };
      //   state.tabs.push(tab);
      //   state.current = state.tabs.length - 1;
      // } else {
      //   const idTab = existeTab(state.tabs, action.payload.guia.codigo);
      //   if (idTab > 0) {
      //     state.current = idTab;
      //   } else {
      //     const tab: TabState<GuiaInfo> = {
      //       tabKey: action.payload.key,
      //       id: action.payload.guia.codigo,
      //       tabId: state.currentIndentifier,
      //       tittle: `${action.payload.guia.establecimiento}-${action.payload.guia.puntoEmision}-${action.payload.guia.numero}`,
      //       canClose: true,
      //       editStatus: StatesEdition.save,
      //       type: TabTypes.documento,
      //       info: {
      //         claveAcceso: action.payload.guia.claveAcceso,
      //         numero: action.payload.guia.codigo,
      //         tipoComprobante: TiposComprobantesSri.Factura,
      //         info: action.payload.guia,
      //         canSendAutorizacion: !(
      //           action.payload.guia.estadoElectronico === "AUTORIZADO"
      //         ),
      //         canSendMail:
      //           action.payload.guia.estadoElectronico === "AUTORIZADO",
      //         canInactive: action.payload.guia.estadoCodigo !== 2,
      //       },
      //       globalButtonClick: ButtonTypes.none,
      //       imprimir: false,
      //     };
      //     state.tabs.push(tab);
      //     state.current = state.tabs.length - 1;
      //   }
      // }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string;
        estado: StatesEdition;
        info?: GuiaInfo;
        buttons: CustomButtons;
      }>
    ) {
      const { estado, tabKey, info, buttons } = action.payload;
      state.tabs[tabKey].editStatus = estado;
      state.tabs[tabKey].currentExecutingAction = undefined;
      state.tabs[tabKey].buttons = buttons;
      if (info) {
        state.tabs[tabKey].info = {
          claveAcceso: info.claveAcceso,
          numero: info.codigo,
          tipoComprobante: TiposComprobantesSri.GuiaRemision,
          info: info ?? null,
        };
        state.tabs[tabKey].id = info.codigo;
        state.tabs[
          action.payload.tabKey
        ].tittle = `${info.establecimiento}-${info.puntoEmision}-${info.numero}`;
      }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button;
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none;
      }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload;
      }
    },
    setTabInfoAsInactive(state, action: PayloadAction<string>) {
      //if (action.payload >= state.tabs.length) return;
      const info = state.tabs[action.payload].info;
      if (info !== undefined) {
        if (info.info) {
          info.info.estadoDescripcion = "ANULADO";
          info.info.estadoElectronico = "ANULADO";
        }
        state.tabs[action.payload].info = info;
      }
    },
    setButtons(
      state,
      action: PayloadAction<{ tabKey: string; buttons: CustomButtons }>
    ) {
      const { buttons, tabKey } = action.payload;
      state.tabs[tabKey].buttons = buttons;
      state.tabs[tabKey].currentExecutingAction = undefined;
    },
    setCurrentExecutingAction(
      state,
      action: PayloadAction<{
        tabKey: string;
        buttonType: ButtonTypes | undefined;
      }>
    ) {
      const { buttonType, tabKey } = action.payload;
      state.tabs[tabKey].currentExecutingAction = buttonType;
    },
  },
});

export const selectTabsGuias = (state: RootState) => {
  return state.ventas.guiasRemision.tabs;
};
export const {
  openTab,
  changeCurrentTab,
  buttonClick,
  changeEditStatus,
  clearButtonClick,
  ventasCloseTab: closeTab,
  setButtons,
  setCurrentExecutingAction,
  setTabInfoAsInactive,

} = tabsGuiasSlice.actions;
export const tabsReducer = tabsGuiasSlice.reducer;
