import React from 'react';
export default function CustomSheetTextViewer(props) {
  const { cell } = props

  return (
    <div style={{ width: 'auto', height: '100%', textAlign: 'left', marginLeft:'2px' }}>
      <span style={{ margin: 'auto' }} >{cell.display}</span>
    </div>
  )
}
