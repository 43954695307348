import { CTooltip } from '@coreui/react';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function getStyle(type) {
  switch (type) {
    case "info":
      return "limegreen";
    case "warning":
      return "gold;";
    case "danger":
      return "orangered;";
    default:
      break;
  }
}

export default function CustomSheetNumberViewer(props) {
  const { cell } = props
  if (cell.hint) {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ width: 'auto' }}>
          <span>
            {cell.display}
          </span>
        </div>
        <div style={{ width: '20px' }}>
          {cell.hint &&
            <CTooltip
              content={cell.hint}
            >
              <FontAwesomeIcon icon={faInfoCircle} size='sm' style={{ color: "red" }} />
            </CTooltip>
          }
        </div>

      </div>
    )
  }
  return (
    <div style={{ width: 'auto', height: '100%', textAlign: 'right', marginRight:'2px' }}>
      <span style={{ margin: 'auto' }}>
        {cell.display}
      </span>
    </div>
  )
}


