import { RequestHelper } from "../../../../helpers/requestHelper";
import { SesionService } from "../../../../services/sesion.service";
import { Cheque } from "../../../bancos/pages/cheques/store/types";

export const listadoComprobantesService = {
  verificarSinCuotas, verificarSinTarjetas, verificarSinCheques,
  verificarSinCuotasPunto, verificarSinChequesPunto, verificarSinTarjetasPunto
}


async function verificarSinCuotas(find): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      tipo: find
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'clientes/DocCuentasPorCobrar',
      'sales/verifySalesWithoutDues',
      '',
      obj
    )
    return data
  } catch (error) {
    return error
  }
}

async function verificarSinCheques(find): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      tipo: find
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'clientes/DocCuentasPorCobrar',
      'sales/verifySalesWithoutChecks',
      '',
      obj
    )
    return data
  } catch (error) {
    return error
  }
}

async function verificarSinTarjetas(find): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      tipo: find
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'clientes/DocCuentasPorCobrar',
      'sales/verifySalesWithoutCreditCards',
      '',
      obj
    )
    return data
  } catch (error) {
    return error
  }
}


async function verificarSinCuotasPunto(find, puntoVenta): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      tipo: find,
      puntoVenta: puntoVenta
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'generales/sales',
      'verifyWithoutCuotDot',
      '',
      obj
    )
    return data
  } catch (error) {
    return error
  }
}

async function verificarSinChequesPunto(find, puntoVenta): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      tipo: find,
      puntoVenta: puntoVenta
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'generales/sales',
      'verifyWithoutChecksDot',
      '',
      obj
    )
    return data
  } catch (error) {
    return error
  }
}

async function verificarSinTarjetasPunto(find, puntoVenta): Promise<any> {
  const sesion = SesionService.getCurrentSesion()
  try {
    let obj = {
      ruc: sesion.empresa.ruc,
      usuario: sesion.usuario.codigo,
      tipo: find,
      puntoVenta: puntoVenta
    }
    const data = await RequestHelper.getAll<Array<Cheque>>(
      'generales/sales',
      'verifyWithoutCardDot',
      '',
      obj
    )
    return data
  } catch (error) {
    return error
  }
}
