// import { AxiosRequestConfig, Method } from "axios";
// import config from "../config/config";
import { RequestHelper } from "../helpers/requestHelper";
import {
  Empresa,
  LocalEmpresa,
  SesionIniciadaPayload,
  SesionUsuario,
  Usuario,
  RegistrarSesion
} from "../store/types";
import { desencriptarJson } from "../helpers/Helper";
import { utilidades } from "../helpers/utilidades";
import { RequestHelperDotNet } from "../helpers/requestHelperDotNet";
import { loadAutorizacionModuloState } from "../store/storageRedux";

export const SesionService = {
  verificarUsuario,
  iniciarSesion,
  getCurrentSesion,
  getHaySesionActiva,
  solicitarVincularUsuario,
  registrarSesion,
  verificarSesion,
  closeSesion,
  updateSesion,
  closeOtherSesion,
  getIpServer,
  closeAllSesion,
  getCurrentModulosAutorizacion,
  verificarTerminosAceptados,
  generarAceptarTerminos
};



async function parseIniciarSesionData(
  apiData: any
): Promise<SesionIniciadaPayload> {
  //const urls = UrlHelper.getUrls();
  const _usuario = apiData.usuario;
  const usuario: Usuario = {
    codigo: parseInt(_usuario.codigo),
    nombres: _usuario.nombres,
    apellidos: _usuario.apellidos,
    idsesion: _usuario.idsesion,
    email: _usuario.email,
    firma: _usuario.firma,
    rol: _usuario.rol,
    asistente: _usuario.asistente,
    cedula: _usuario.cedula,
    telefono: _usuario.telefono,
    direccion: _usuario.direccion,
    ciudad: _usuario.ciudad,
    codciudad: _usuario.codciudad,
    codprovincia: _usuario.codprovincia,
    provincia: _usuario.provincia,
    pais: _usuario.pais,
    privilegio: "",
  };

  const empresas: Array<Empresa> = [];
  for await (const _empresa of apiData.empresas) {

    const empresa: Empresa = {
      codigo: parseInt(_empresa.codigo),
      logo: `${_empresa.logo}`,
      ruc: _empresa.ruc,
      nombre: _empresa.nombre,
      comercial: _empresa.comercial,
      direccion: _empresa.direccion,
      telefono: _empresa.telefono,
      email: _empresa.email,
      token: _empresa.token,
      descuento: parseFloat(_empresa.descuento),
      iproforma: _empresa.iproforma,
      transparencia: _empresa.transparencia,
      modulos: _empresa.modulos,
      precision: parseInt(_empresa.precision),
      uso_electronica: parseInt(_empresa.uso_electronica),
      certificado: _empresa.certificado,
      caduca: _empresa.caduca,
      imprimeAutomaticamenteAlfacturar:
        _empresa.EMP_PREGIMP === "1" ? false : true,
      pathArchivosPaginaWeb: _empresa.EMP_PATHWEB,
      locales: [],
      cuotasVenta: parseInt(_empresa.EMP_CUOTASV),
      cuotasVentaDias: parseInt(_empresa.EMP_DIASCUOTA),
      formaPagoDefectoCompra: parseInt(_empresa.EMP_FPAGOCOMPRA ?? 0),
      formaPagoDefectoVenta: parseInt(_empresa.EMP_FPAGOVENTA ?? 0),
      planCodigo: parseInt(_empresa.plan_id ?? 1),
      configurado: _empresa.configurado ?? false,
      leyendaComprobantesElctronicos: _empresa.EMP_LEYENDACE ?? "",
      codigoTipoNegocio: Number(_empresa?.tipo_negocio_id) ?? -1,
    };
    const locales: Array<LocalEmpresa> = [];
    for await (const _local of _empresa.locales) {
      const local: LocalEmpresa = {
        codigo: parseInt(_local.codigo),
        nombre: _local.nombre,
        direccion: _local.direccion,
        telefono: _local.telefono,
        clase: _local.clase,
        descuento: parseFloat(_local.descuento),
        tipoRol: _local.tipoRol,
        controlaKardex: _local.kardex === "0",
        ciudadCodigo: parseInt(_local.ciucodigo),
        ciudadNombre: _local.ciudad,
      };
      locales.push(local);
    }
    empresa.locales = locales;
    empresas.push(empresa);
  }

  return { isOk: true, empresas: empresas, usuario: usuario };
}

async function iniciarSesion(token: string): Promise<SesionIniciadaPayload> {
  try {
    // if (isIOS13 === true || isMacOs === true) {
    //   sessionStorage.setItem("token", token);
    // } else {
    localStorage.setItem("token", token);
    // }
    if (utilidades.canUseNetApi()) {
      return iniciarSesionApiNet();
    } else {
      return iniciarSesionApiV4();
    }
    //RequestHelper.setToken(token);

    //return { isOk: true, empresas: apiData.empresas, usuario: apiData.usuario };
  } catch (error) {
    return { isOk: false, error: error };
  }
}

async function iniciarSesionApiNet() {
  const apiData = await RequestHelperDotNet.get<any>(
    "Login",
    "Login",
    "",
    null
  );
  const _usuario = apiData.user;
  const usuario: Usuario = {
    codigo: _usuario.codigo,
    nombres: _usuario.nombres,
    apellidos: _usuario.apellidos,
    idsesion: _usuario.usuarioNombre,
    email: _usuario.email,
    firma: _usuario.firma,
    rol: _usuario.tipo,
    asistente: _usuario.asistente,
    cedula: _usuario.identificacion,
    telefono: _usuario.telefono,
    direccion: _usuario.direccion,
    ciudad: _usuario.ciudad,
    codciudad: _usuario.ciudadCodigo,
    codprovincia: _usuario.ciudadProvinciaCodigo,
    provincia: '',
    pais: '',
    privilegio: "",
  };

  const empresas: Array<Empresa> = [];
  for await (const _empresa of apiData.companies) {
    const empresa: Empresa = {
      codigo: _empresa.codigo,
      logo: _empresa.pathLogoEmpresa,
      ruc: _empresa.ruc,
      nombre: _empresa.nombre,
      comercial: _empresa.nombreComercial,
      direccion: _empresa.direccion,
      telefono: _empresa.telefono,
      email: _empresa.email,
      token: _empresa.token,
      descuento: _empresa.descuento,
      iproforma: _empresa.ipProforma,
      transparencia: _empresa.transparencia,
      modulos: _empresa.modulos,
      precision: parseInt(_empresa.precisionDecimal),
      uso_electronica: parseInt(_empresa.modoFacturacion),
      certificado: _empresa.certificado,
      caduca: _empresa.certificadoCaduca,
      imprimeAutomaticamenteAlfacturar:
        _empresa.PreguntaAlImprimir === 1 ? false : true,
      pathArchivosPaginaWeb: _empresa.EMP_PATHWEB,
      locales: [],
      cuotasVenta: parseInt(_empresa.cuotasVenta),
      cuotasVentaDias: parseInt(_empresa.cuotasVentaDias),
      formaPagoDefectoCompra: _empresa.formaPagoDefectoCompra,
      formaPagoDefectoVenta: _empresa.formaPagoDefectoVenta,
      planCodigo: parseInt(_empresa.plan_id ?? 1),
      configurado: _empresa.configurado ?? false,
      leyendaComprobantesElctronicos: _empresa.EMP_LEYENDACE ?? "",
      codigoTipoNegocio: Number(_empresa?.tipo_negocio_id) ?? -1,
    };
    const locales: Array<LocalEmpresa> = [];
    for await (const _local of _empresa.locals) {
      const local: LocalEmpresa = {
        codigo: _local.codigo,
        nombre: _local.nombre,
        direccion: _local.direccion,
        telefono: _local.telefono,
        clase: _local.clase,
        descuento: parseFloat(_local.descuento),
        tipoRol: _local.tipoRol,
        controlaKardex: _local.kardex === "0",
        ciudadCodigo: parseInt(_local.ciudadCodigo),
        ciudadNombre: _local.ciudad,
      };
      locales.push(local);
    }
    empresa.locales = locales;
    empresas.push(empresa);
  }

  return { isOk: true, empresas: empresas, usuario: usuario };

  //return parsed;
}

async function iniciarSesionApiV4() {
  const apiData = await RequestHelper.post<any>("login", "autenticar", null);
  const parsed = await parseIniciarSesionData(apiData);

  return parsed;
}

async function verificarUsuario(
  token: string,
  setToken: boolean = true
): Promise<boolean> {
  try {
    if (utilidades.canUseNetApi()) {
      return verificarUsuarioApiNet(token, setToken);
    } else {
      return verificarUsuarioApiV4(token, setToken);
    }
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function verificarUsuarioApiNet(token: string, setToken: boolean = true) {
  if (setToken) {
    // if (isIOS13 === true || isMacOs === true) {
    //   sessionStorage.setItem("token", token);
    // } else {
    localStorage.setItem("token", token);
    // }
  }
  const apiData = setToken ? {} : { email: token };
  const existe = await RequestHelperDotNet.get<any>(
    "Login",
    "MailCheckout",
    "",
    apiData
  );
  return existe;
}

async function verificarUsuarioApiV4(token: string, setToken: boolean = true) {
  if (setToken) {
    // if (isIOS13 === true || isMacOs === true) {
    //   sessionStorage.setItem("token", token);
    // } else {
    localStorage.setItem("token", token);
    // }
  }
  if (utilidades.canUseNetApi()) {
  } else {
  }
  const apiData = setToken ? {} : { email: token };
  const existe = await RequestHelper.get<boolean>(
    "login",
    "mailCheckout",
    "",
    apiData
  );
  console.log("existe", existe)
  return existe;
}

function getCurrentModulosAutorizacion(): any {
  // const serializedState = sessionStorage.getItem('reduxState') ?? '';
  // const state = JSON.parse(serializedState);
  const serializedState = loadAutorizacionModuloState();
  return serializedState as any;
}

function getCurrentSesion(): SesionUsuario {
  // const serializedState = sessionStorage.getItem('reduxState') ?? '';
  // const state = JSON.parse(serializedState);
  const serializedState = loadState();
  return serializedState as SesionUsuario;
}

function getHaySesionActiva() {
  try {
    const serializedState = getCurrentSesion(); //sessionStorage.getItem('reduxState') ?? '';
    //JSON.parse(serializedState);
    return serializedState !== undefined;
  } catch (error) {
    return false;
  }
}

const loadState = () => {
  try {
    const encriptedState = sessionStorage.getItem("reduxState");
    if (encriptedState === null) {
      return undefined;
    }
    const serializedState = desencriptarJson(encriptedState);
    const data = JSON.parse(serializedState);
    return data;
  } catch (err) {
    return undefined;
  }
};

async function solicitarVincularUsuario(objeto): Promise<boolean> {
  try {
    const existeVerificar = await RequestHelper.get<boolean>(
      "requestAccess",
      "",
      "",
      objeto
    );
    return existeVerificar;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function registrarSesion(data: RegistrarSesion) {
  let dataRegistro = {
    infoRegistro: {
      ...data
    }
  }
  const apiData = await RequestHelper.post<any>("sessions", "store", dataRegistro);
  return apiData;
}

async function updateSesion(data) {
  let dataRegistro = {
    infoRegistro: {
      "dispositivo": data['dispositivo'],
      "tokenSesion": data['tokenSesion'],
      "empresa": data['empresa'],
      "usuario": data['usuario'],
      "identificadorSesion": data['identificadorSesion']
    }
  }
  const apiData = await RequestHelper.post<any>("sessions", "update", dataRegistro);
  return apiData;
}


async function verificarSesion(dataVerificar): Promise<any> {
  try {
    const apiData = await RequestHelper.getAll<any>("sessions", "getSession", "", dataVerificar);
    return apiData;
  } catch (error) {
    return error;
  }
}

async function closeSesion(dataVerificar): Promise<any> {

  const sesion = SesionService.getCurrentSesion();
  try {
    let dataClose = {
      empresa: sesion.empresa.codigo,
      usuario: sesion.usuario.codigo,
      dispositivo: dataVerificar['dispositivo'],
      identificadorSesion: dataVerificar['identificadorSesion']
    }
    const apiData = await RequestHelper.deleteRequestAll<any>("sessions", "deactivate", "", dataClose);
    return apiData;
  } catch (error) {
    return error;
  }
}

async function closeOtherSesion(dataSesion): Promise<any> {
  try {
    let dataClose = {
      identificadorSesion: dataSesion['identificadorSesion']
    }
    const apiData = await RequestHelper.deleteRequestAll<any>("sessions", "deactivate", "", dataClose);
    return apiData;
  } catch (error) {
    return error;
  }
}

async function closeAllSesion(dataSesion): Promise<any> {
  try {
    let dataClose = {
      empresa: dataSesion['empresa'],
      usuario: dataSesion['usuario'],
      dispositivo: dataSesion['dispositivo']
    }
    const apiData = await RequestHelper.deleteRequestAll<any>("sessions", "deactivateAll", "", dataClose);
    return apiData;
  } catch (error) {
    return error;
  }
}

async function getIpServer(): Promise<any> {
  try {
    const apiData = await RequestHelper.getAll<any>("generales", "getIP", "", "");
    return apiData;
  } catch (error) {
    return error;
  }
}

async function verificarTerminosAceptados(dataVerificar): Promise<boolean> {
  try {
    const apiData = await RequestHelper.get<boolean>("terminos", "varificate", "", dataVerificar);
    return apiData;
  } catch (error) {
    return false;
  }
}

async function generarAceptarTerminos(dataRegistro) {
  const apiData = await RequestHelper.post<any>("terminos", "accept", dataRegistro);
  return apiData;
}