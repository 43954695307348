import { combineReducers, } from "@reduxjs/toolkit";
import { WindowVComprasState } from "../../../types/genercis";
import {  RetencionDatosEdicion, RetencionInfo } from "../../../types/types";
import { menuReducer } from "./menuReducer";
import { searchRetencionesReducer } from "./searchReducer";
import { datosEdicionReducer } from "./editDataReducer";
import { tabsReducer } from "./tabsReducer";

export const retencionesReducer = combineReducers<WindowVComprasState<RetencionInfo, RetencionDatosEdicion>>({
  menu: menuReducer,
  tabs: tabsReducer,
  search: searchRetencionesReducer,
  editData: datosEdicionReducer
});

