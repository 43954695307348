import { Urls } from "../store/types";

export const UrlHelper = {
    getUrls
}


/*
REACT_APP_API_URL=https://prd.acatha.com/amfphp/services/SIGNUM/API
REACT_APP_PATH_URI=https://44.232.138.230/amfphp/services/SIGNUM/
REACT_APP_PATH_LOGOS=https://44.232.138.230/SolucionEmpresarial/SigNum/isologos/
REACT_APP_PATH_FOTOS=https://44.232.138.230/SolucionEmpresarial/SigNum/fotos/Usuarios/
REACT_APP_PATH_FOTOS_NOTIFICACIONES=https://44.232.138.230/SolucionEmpresarial/SigNum/fotos/Notificaciones/
*/

function getHost(location:Location) {
    return `${location.protocol}//${location.hostname}`
}

function getUrls(): Urls {
    const location = window.location;
    if (location.hostname === 'localhost') {
        const urls: Urls = {
            origin: location.origin,
            apiBasePath: process.env.REACT_APP_API_URL ?? '', //location.origin + '/amfphp/services/SIGNUM/API',
            pathUri: process.env.REACT_APP_PATH_URI ?? '',
            pathLogos: process.env.REACT_APP_PATH_LOGOS ?? '',
            pathFotos: process.env.REACT_APP_PATH_FOTOS ?? '',
            pathFotosNotificaciones: process.env.REACT_APP_PATH_FOTOS_NOTIFICACIONES ?? '',
            documentServerBasePath: process.env.REACT_APP_DOCUMENT_SERVER_PATH ?? '',
            reportServerBasePath: process.env.REACT_APP_REPORT_SERVER_BASE_PATH ?? '',
            pathPlantillas : process.env.REACT_APP_SERVER_BASE_PATH + 'SolucionEmpresarial/SigNum/bin/modelos/',
            apiDotNetPath: process.env.REACT_APP_APINET_URL ?? 'http://localhost:3000/ventas',
            apiDasbooardsPath:  process.env.REACT_APP_APIDASBOARDNET_URL ?? 'http://dev.acatha.com:5020/api/',
        };
        return urls;
    } else {
        const urls: Urls = {
            origin: location.origin,
            apiBasePath: (process.env.REACT_APP_API_URL ?? '') ===''? location.origin + '/amfphp/services/SIGNUM/API' : process.env.REACT_APP_API_URL ??'',
            pathUri: (process.env.REACT_APP_PATH_URI ??'') ===''?  location.origin + '/amfphp/services/SIGNUM/' : (process.env.REACT_APP_PATH_URI ??''),
            pathLogos: (process.env.REACT_APP_PATH_LOGOS ?? '') ===''? location.origin + '/SolucionEmpresarial/SigNum/isologos/': process.env.REACT_APP_PATH_LOGOS ?? '',
            pathFotos: (process.env.REACT_APP_PATH_FOTOS ?? '') ===''? location.origin + '/SolucionEmpresarial/SigNum/fotos/Usuarios/':(process.env.REACT_APP_PATH_FOTOS ?? ''),
            pathFotosNotificaciones: (process.env.REACT_APP_PATH_FOTOS_NOTIFICACIONES ?? '') ===''? location.origin + '/SolucionEmpresarial/SigNum/fotos/Notificaciones/': process.env.REACT_APP_PATH_FOTOS_NOTIFICACIONES ?? '',
            documentServerBasePath: (process.env.REACT_APP_DOCUMENT_SERVER_PATH ?? '') ===''? location.origin:process.env.REACT_APP_DOCUMENT_SERVER_PATH ?? '',
            reportServerBasePath:(process.env.REACT_APP_REPORT_SERVER_BASE_PATH ?? '') ===''? getHost(location) + ':5020/': process.env.REACT_APP_REPORT_SERVER_BASE_PATH ?? '',
            pathPlantillas : ((process.env.REACT_APP_SERVER_BASE_PATH ??'' ) ===''?  location.origin: process.env.REACT_APP_SERVER_BASE_PATH  ??'') + '/SolucionEmpresarial/SigNum/bin/modelos/' ,
            apiDotNetPath: ( process.env.REACT_APP_APINET_URL ?? '') ===''? (getHost(location)  + ':5010/api/' ?? ''): ( process.env.REACT_APP_APINET_URL ?? ''),
            apiDasbooardsPath: ( process.env.REACT_APP_APIDASBOARDNET_URL ?? '') ===''? (getHost(location) + ':5020/api/' ?? '')  : ( process.env.REACT_APP_APIDASBOARDNET_URL ?? '')
        };
        return urls;
    }

}