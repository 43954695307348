import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateUtils } from "../../../../../helpers/dateUtils";
import { RootState } from "../../../../../store/store";
import { FETCH_STATUS } from "../../../../../store/types";
import { VentasService } from "../../../services/ventas.service";
import { SearchState } from "../../../types/generics";
import { FiltroBusquedaState, GuiaInfo } from "../../../types/types";


const initialState: SearchState<GuiaInfo> = {
  filter: {
    fechaInicio: DateUtils.getCurrentDateAsString(),
    fechaFin: DateUtils.getCurrentDateAsString(),
    puntoVenta: '',
    cliente: null,
    activas: false,
    documento: '',
    formaPago: null,
    pendientesAbutorizar: false,
    establecimiento: null,
    estado: null,
    puntoEmision: null
  },
  status: FETCH_STATUS.IDDLE,
  mensaje: '',
  resultados: []
}

export const fetchGuias = createAsyncThunk<Array<GuiaInfo>, FiltroBusquedaState>(
  'ventas/searchGuias',
  async (filtro) => {
    try {
      const guias = await
        VentasService.getGuias(filtro.claveAcceso ?? "", DateUtils.pickersDateToApiDate(filtro.fechaInicio) ?? "",
          DateUtils.pickersDateToApiDate(filtro.fechaFin) ?? "", filtro.activas ?? true, "");
      return guias;
    } catch (error) {
      return Promise.reject(error);
    }
  }

)


const searchGuiasSlice = createSlice({
  name: 'searchGuias',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = {
        ...initialState,
        filter: {
          ...initialState.filter,
          fechaInicio: DateUtils.getCurrentDateAsString(),
          fechaFin: DateUtils.getCurrentDateAsString(),
        },
      };
    },
    changeFilter(state, action: PayloadAction<FiltroBusquedaState>) {
      state.filter = action.payload;
    },

    setCleanResult(state) {
      state.resultados = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGuias.pending, (state) => {
      if (state.status === FETCH_STATUS.IDDLE || state.status === FETCH_STATUS.SUCCESS) {
        state.status = FETCH_STATUS.LOADING;
        state.mensaje = '';
      }
    })
    builder.addCase(fetchGuias.fulfilled, (state, { payload }) => {
      state.resultados = payload;
      state.status = FETCH_STATUS.SUCCESS;
    })
    builder.addCase(fetchGuias.rejected, (state, { payload }) => {
      state.resultados = [];
      state.status = FETCH_STATUS.FAILED;
      state.mensaje = payload as string;
    })
  }
})

export const selectFiltroBusquedaGuias = (state: RootState) => { return state.ventas.guiasRemision.search.filter };
export const selectResultadosBusquedaGuias = (state: RootState) => { return state.ventas.guiasRemision.search.resultados };
export const selectBusquedaGuiasEstado = (state: RootState) => { return state.ventas.guiasRemision.search.status };
export const { changeFilter, resetState, setCleanResult } = searchGuiasSlice.actions;
export const searchGuiasReducer = searchGuiasSlice.reducer;