import { combineReducers } from "@reduxjs/toolkit";
import { diarioReducer } from '../pages/diarioGeneral/store/diarioReducer';
import { mayorReducer } from '../pages/mayorGeneral/store/mayorReducer';
import { tipoCuentaReducer } from '../pages/tiposCuentas/store/tipocuentaReducer';
import { planCuentasReducer } from '../pages/planCuentas/store/cuentasReducer';
import { cuentasReducer } from '../pages/cuentasFlujo/store/cuentasReducer';
import { ContabilidadState } from "./types";
import { modalCuentasReducer } from '../pages/planCuentas/modalCuentas/store/modalCuentasReducer';
import { modalVerificarAsientosReducer } from '../pages/diarioGeneral/components/modalVerificarAsiento/store/modalVerificarAsientosReducer';
import { modalAnticiposReducer } from '../pages/diarioGeneral/components/modalAnticipos/store/modalAnticiposReducer';
import { estadosFinancierosReducer } from '../pages/estadosFinancieros/store/estadosFinancierosReducer';
import { periodosReducer } from '../pages/periodoContable/store/periodoReducers';
import { asignacionesReducer } from '../pages/asignaciones/store/asignacionesReducer';
import { generalReducer } from '../general/store/generalReducers';

export const contabilidadReducer = combineReducers<ContabilidadState>({
    tipoCuenta: tipoCuentaReducer,
    cuentasFlujo: cuentasReducer,
    planCuentas: planCuentasReducer,
    diarioGeneral: diarioReducer,
    mayorGeneral: mayorReducer,
    modalCuentas: modalCuentasReducer,
    modalVerificarAsientos: modalVerificarAsientosReducer,
    modalAnticipos: modalAnticiposReducer,
    estadosFinancieros: estadosFinancierosReducer,
    periodosContables: periodosReducer,
    asignaciones: asignacionesReducer,
    general: generalReducer
})
