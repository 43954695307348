import * as React from 'react';
import { Portal } from 'react-portal';
import Modalform from '../../../../../../views/componentes/modalform';
import { CButton, CCol, CRow, CLabel, CTooltip, } from '@coreui/react';
import { Cliente } from '../../../../types/types';
import { TiposComprobantesSri, ToastTypes } from '../../../../../../store/types';
import { addToast } from '../../../../../../store/toasterReducer';
import { useDispatch } from 'react-redux';
import { ClientesService } from '../../../../services/clientes.service';
import { VentasService } from '../../../../services/ventas.service';
import * as configGlobal from '../../../../../../store/reducers';
import { ProveedoresService } from '../../../../../proveedores/services/proveedores.service';
import { Proveedor, ProveedorBusqueda } from '../../../../../proveedores/store/types';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ValidationSummary from 'devextreme-react/validation-summary';
import RowContainer from '../../../../../../views/componentes/rowContainer/rowContainer';
import CustomCol from '../../../../../../views/componentes/colContainer';
import Labeled from '../../../../../../views/componentes/labeledInput/labeledInput';
import {
  Validator,
  RequiredRule,
  PatternRule
} from 'devextreme-react/validator';
import { lh, MessagesKeys } from '../../../../../../helpers/localizationHelper';
import TextArea from 'devextreme-react/text-area';


export const emailPatern = /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{1,}[\;]?)+){1,}$/

interface IPupopReenvioMailProps {
  showConfirm: boolean,
  itemReenviarMail: { cliente?: Cliente, claveAcceso: string, proveedor?: ProveedorBusqueda } | null,
  closeConfirm: () => void
}

enum TipoRemitente {
  cliente = "Cliente",
  proveedor = "Proveedor",
}

const clienteInit = {
  ciudad: "",
  ciudadCodigo: 0,
  codigo: 0,
  descuento: 0,
  direccion: "",
  email: "",
  identificacion: "",
  nombreComercial: "",
  nombres: "",
  telefono: "" ?? "N/A",
  tipoCliente: 0,
  tipoIdentificacion: "",
}

export function PupopReenvioMail(props: IPupopReenvioMailProps) {

  const { showConfirm, itemReenviarMail, closeConfirm } = props;

  const dispatch = useDispatch();
  const validationGroupRef = React.useRef<any>();

  const [messageConfirm, setMessageConfirm] = React.useState<string>("");
  const [codigo, setCodigo] = React.useState<number>(0);
  const [mailReenvio, setMailReenvio] = React.useState<string>("");
  const [mailBackupReenvio, setMailBackupReenvio] = React.useState<string>("");
  const [sendSameMail, setSendSameMail] = React.useState<boolean>(true);
  const [clienteModificar, setClienteModificar] = React.useState<Cliente>(clienteInit);
  const [proveedorModificar, setProveedorModificar] = React.useState<Proveedor | null>(null);
  const [tipoDocumento, setTipoDocumento] = React.useState<TiposComprobantesSri>(TiposComprobantesSri.Factura);

  React.useEffect(() => {
    if (itemReenviarMail) {
      setSendSameMail(true)
      saveMailRemitente(itemReenviarMail)
      setMessageConfirm("Desea enviar el comprobante al mismo correo?");
    }
  }, [itemReenviarMail, showConfirm])

  const saveMailRemitente = (itemReenviar) => {
    console.log("saveMailRemitente")
    console.log(itemReenviar.cliente?.email)
    if (itemReenviar.claveAcceso && itemReenviar.claveAcceso.length > 0) {
      switch (itemReenviar.claveAcceso.substr(8, 2)) {
        case '01':
          setMailReenvio(itemReenviar.cliente?.email ?? "")
          return true
        case '04':
          setMailReenvio(itemReenviar.cliente?.email ?? "")
          return true
        case '05':
          setMailReenvio(itemReenviar.cliente?.email ?? "")
          return true
        case '06':
          setMailReenvio(itemReenviar.cliente?.email ?? "")
          return true
        case '03':
          setMailReenvio(itemReenviar.proveedor?.email ?? "")
          return true
        case '07':
          setMailReenvio(itemReenviar.proveedor?.email ?? "")
          return true
        default:
          setMailReenvio(itemReenviar.cliente?.email ?? "")
          return true
      }
    } else {
      dispatch(addToast({
        title: 'Error',
        content: 'No se puede obtener la clave de acceso.',
        type: ToastTypes.Warning,
        autoHide: 5000
      }))
      closeConfirm();
    }
  }

  const cargarCliente = React.useCallback(async (dataCliente: any) => {
    console.log("cargarCliente")
    console.log("dataCliente", dataCliente)
    playLoader();
    const cliente = await ClientesService.consultarClientes({ nombre: dataCliente?.nombre, identificacion: dataCliente?.identificacion ?? '', codigo: dataCliente.codigo });
    console.log("cliente", cliente)
    setClienteModificar({
      ciudad: cliente[0]['ciudad'],
      ciudadCodigo: cliente[0]['ciudadCodigo'],
      codigo: cliente[0]['codigo'],
      descuento: cliente[0]['descuento'],
      direccion: cliente[0]['direccion'],
      email: cliente[0]['email'],
      identificacion: cliente[0]['identificacion'],
      nombreComercial: cliente[0]['nombreComercial'],
      nombres: cliente[0]['nombres'],
      telefono: cliente[0]['telefono'] ?? "N/A",
      tipoCliente: cliente[0]['tipoCliente'],
      tipoIdentificacion: cliente[0]['tipoIdentificacion'],
    })
    setMailBackupReenvio(cliente[0]['email'] ?? "")
    setCodigo(cliente[0]['codigo']);
    stopLoader();
  }, []);

  const cargarProveedor = React.useCallback(async (codigo: number) => {
    playLoader();
    const proveedor = await ProveedoresService.getProveedorByCode(codigo);
    console.log("proveedor", proveedor)
    setProveedorModificar(proveedor)
    setMailBackupReenvio(proveedor['email'] || "")
    setCodigo(proveedor['codigo'] || 0);
    stopLoader();
  }, []);


  const detectaDocumento = React.useCallback(async (claveAcceso: string) => {
    console.log("detectaDocumento", claveAcceso)

    switch (claveAcceso.substr(8, 2)) {
      case '01':
        console.log("case", claveAcceso.substr(8, 2))
        setTipoDocumento(TiposComprobantesSri.Factura);
        cargarCliente(itemReenviarMail?.cliente ?? "");
        return false
      case '04':
        console.log("case", claveAcceso.substr(8, 2))
        setTipoDocumento(TiposComprobantesSri.NotaCredito);
        cargarCliente(itemReenviarMail?.cliente ?? "");
        return false
      case '05':
        console.log("case", claveAcceso.substr(8, 2))
        setTipoDocumento(TiposComprobantesSri.NotaDebito);
        cargarCliente(itemReenviarMail?.cliente ?? "");
        return false
      case '06':
        console.log("case", claveAcceso.substr(8, 2))
        setTipoDocumento(TiposComprobantesSri.GuiaRemision);
        cargarCliente(itemReenviarMail?.cliente ?? "");
        return false
      case '03':
        console.log("case", claveAcceso.substr(8, 2))
        setTipoDocumento(TiposComprobantesSri.LiquidacionCompra);
        cargarProveedor(itemReenviarMail?.proveedor?.codigo ?? 0);
        return false
      case '07':
        console.log("case", claveAcceso.substr(8, 2))
        setTipoDocumento(TiposComprobantesSri.Retencion);
        cargarProveedor(itemReenviarMail?.proveedor?.codigo ?? 0);
        return false
      default:
        return false
    }


  }, []);

  const reenviarComprobante = async () => {
    let validationResult = await validationGroupRef.current.instance.validate();
    if (validationResult.isValid === false) {
      dispatch(addToast({
        title: 'Cambiar Correo',
        content: 'Tiene errores por favor verifíquelos.',
        type: ToastTypes.Info,
        autoHide: 5000
      }))
      return false;
    } else {

      if (mailReenvio === "" || mailReenvio === null) {
        dispatch(addToast({
          title: 'Cambiar Correo',
          content: 'Por favor ingrese una cuenta de correo.',
          type: ToastTypes.Warning,
          autoHide: 5000
        }))
      } else {
        await actualizaMailUsuario(mailReenvio);
      }
    }
  }


  const actualizaMailUsuario = async (nuevoCorreo: string) => {
    console.log("actualizaMailUsuario")
    console.log("nuevoCorreo", nuevoCorreo)
    let resUpdateMail: any = "";
    if (tipoDocumento === "01" || tipoDocumento === "04" || tipoDocumento === "05" || tipoDocumento === "06") {
      resUpdateMail = await updateMailRemitente(nuevoCorreo, tipoDocumento, codigo)
    } else if (tipoDocumento === "03" || tipoDocumento === "07") {
      resUpdateMail = await updateMailRemitente(nuevoCorreo, tipoDocumento, codigo)
    }

    console.log("resUpdateMail", resUpdateMail);

    try {
      setTimeout(function () {
        aplicarEnvioConActualizacion(tipoDocumento);
      }, 50)
    } catch (error) {
      dispatch(addToast({
        title: 'Cambiar Correo',
        content: 'Error al cambiar de correo, \n' + error,
        type: ToastTypes.Warning,
        autoHide: 5000
      }))
    }

  }

  const aplicarEnvioConActualizacion = async (tipoDocumento: string) => {
    console.log("aplicarEnvioConActualizacion");
    let resReenvio = await reenviarEmail();
    console.log("resReenvio", resReenvio);

    if (resReenvio === true) {
      if (mailReenvio !== mailBackupReenvio) {
        let resResUpdate: any = await updateMailRemitente(mailBackupReenvio, tipoDocumento, codigo);
        console.log("resResUpdate", resResUpdate);
      }
    }
  }

  const playLoader = () => {
    dispatch(configGlobal.changeLoader({ show: true, mensaje: 'Cargando...' }))
  }

  const stopLoader = () => {
    dispatch(configGlobal.changeLoader({ show: false, mensaje: '' }))
  }

  const reenviarEmail = async () => {
    try {
      playLoader()
      const dataReenvio = await VentasService.reenviarDocumento(props.itemReenviarMail?.claveAcceso ?? "");

      stopLoader()
      closeConfirm();
      dispatch(addToast({
        title: 'Reenviar Factura',
        content: 'El comprobante fue reenviado al correo',
        type: ToastTypes.Success,
        autoHide: 5000
      }))

      return dataReenvio;

    } catch (error) {
      console.error(error)
      stopLoader()
      dispatch(addToast({
        title: 'Reenviar Factura',
        content: 'No se pudo reenviar la factura.',
        type: ToastTypes.Warning,
        autoHide: 5000
      }))

    }

  }

  const updateMailRemitente = async (mail: string, tipoDocumento: string, codigo: number) => {
    let tipoRemitente: TipoRemitente = TipoRemitente.cliente;
    if (tipoDocumento === "01" || tipoDocumento === "04" || tipoDocumento === "05" || tipoDocumento === "06") {
      tipoRemitente = TipoRemitente.cliente;
    } else if (tipoDocumento === "03" || tipoDocumento === "07") {
      tipoRemitente = TipoRemitente.proveedor;
    }

    const dataUpdate = await ClientesService.modificarRemitenteMail({ tipoRemitente: tipoRemitente, email: mail, codigo: codigo });
    return dataUpdate;
  }


  const aplicarReenvio = async () => {
    let resReenvio = await reenviarEmail();
    if (resReenvio) {
      updateMailRemitente(mailBackupReenvio, tipoDocumento, codigo)
    }

  }

  const retornaTipo = (clave: string) => {
    let tipo: string = '01';
    if (clave.substr(8, 2) === "01") {
      tipo = "01";
    } else if (clave.substr(8, 2) === "04") {
      tipo = "04";
    } else if (clave.substr(8, 2) === "05") {
      tipo = "05";
    } else if (clave.substr(8, 2) === "06") {
      tipo = "06";
    } else if (clave.substr(8, 2) === "03") {
      tipo = "03";
    } else if (clave.substr(8, 2) === "07") {
      tipo = "07";
    }
    return tipo;
  }

  const botonReenvioOtros = (itemReenviarMail) => {
    console.log('botonReenvioOtros')
    console.log('itemReenviarMail', itemReenviarMail)
    let tipo = retornaTipo(itemReenviarMail.claveAcceso);
    console.log('tipo', tipo)
    if (tipo === "01" || tipo === "04" || tipo === "05" || tipo === "06") {
      if (clienteModificar.codigo !== 0) {
        setSendSameMail(false);
        return false;
      } else {
        dispatch(addToast({
          title: 'Cambiar Correo',
          content: 'No se puede realizar el reenvio a otro correo para esta factura, por que se emitio a Consumidor Final',
          type: ToastTypes.Info,
          autoHide: 5000
        }))
      }
    } else if (tipo === "03" || tipo === "07") {
      console.log("proveedorModificar", proveedorModificar)
      if (proveedorModificar?.codigo && Number(proveedorModificar?.codigo) !== 0) {
        setSendSameMail(false);
        return false;
      } else {
        dispatch(addToast({
          title: 'Cambiar Correo',
          content: 'No se puede realizar el reenvio a otro correo para esta factura, por que se emitio a Consumidor Final',
          type: ToastTypes.Info,
          autoHide: 5000
        }))
      }
    }
  }

  const renderBody = () => {
    return (
      <>
        <CLabel>{messageConfirm}</CLabel>
        <CRow>
          <CCol lg={6}>
            <CButton block color="primary" onClick={() => {
              setSendSameMail(true);
              reenviarEmail();
            }}>
              {"Si"}
            </CButton>
          </CCol>
          {" "}
          <CCol lg={6}>
            <CButton block color="secondary" onClick={() => {
              botonReenvioOtros(itemReenviarMail)
            }}>
              {"No"}
            </CButton>
          </CCol>

        </CRow>
        <br />
        {sendSameMail === false && (
          <>
            <ValidationGroup id={`cambiarCorreoRemitente`} ref={validationGroupRef}>
              <label>
                {"Para reenviar a varios correos puede mandarlos seguido del caracter "}
              </label>
              <CTooltip
                key={"tool-mail-add"}
                placement="top"
                content={"(;) Ejemplo : acatha@acatha.com.ec; acatha@austrosoft.com.ec; austrosoft@austrosoft.com.ec "}
              >
                <label style={{ fontSize: "18px", fontWeight: 700 }}>{";"} </label>
              </CTooltip>
              <RowContainer                >
                <CustomCol
                  xs='12' md='12'>
                  <Labeled
                    label='Email'
                  >
                    <TextArea
                      width='100%'
                      height={90}
                      value={mailReenvio}
                      onValueChanged={({ value }) => {
                        if (value) {
                          let val = value.replace(/\r?\n?/g, '');
                          setMailReenvio(val.trim())
                        }
                      }}
                    >
                      <Validator>
                        <RequiredRule message={lh.getMessage(MessagesKeys.GlobalCampoRequerido, "Email")} />
                        <PatternRule pattern={emailPatern} message={lh.getMessage(MessagesKeys.GlobalFormatoCampoIncorrecto, "- Email:")} />
                      </Validator>
                    </TextArea>
                  </Labeled>
                </CustomCol>
              </RowContainer>


              <ValidationSummary />

              <CRow className=" d-flex align-items-end justify-content-end mt-2"  >
                <CCol lg={6} >
                  <CButton block color="success" onClick={() => { reenviarComprobante() }} >
                    {"Enviar"}
                  </CButton>
                </CCol>
              </CRow>

            </ValidationGroup>
          </>
        )
        }
      </>
    )
  }

  const renderFooter = () => {
    return (
      <>
      </>
    )
  }


  React.useEffect(() => {
    if (showConfirm) {
      detectaDocumento(itemReenviarMail?.claveAcceso ?? "")
    }
  }, [detectaDocumento, showConfirm, itemReenviarMail]);

  return (

    <Portal node={document && document.getElementById('root')} >
      <Modalform
        name='confirm'
        headerTitle={'Acatha'}
        childrenBody={renderBody()}
        childrenFooter={renderFooter()}
        closeOnBackdrop={false}
        show={showConfirm}
        onClose={() => props.closeConfirm()}
        centered={true}
        size=""
      />
    </Portal>
  );
};

export default PupopReenvioMail;