import React from 'react'
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'

type ModalformProps = {
  childrenBody?: React.ReactElement,
  childrenFooter?: React.ReactElement,
  backdrop?: boolean, // muestra color de fondo
  closeOnBackdrop?: boolean, // permite que se cierre el modal desde el fondo
  size?: '' | 'sm' | 'lg' | 'xl',
  show: boolean,
  headerTitle: string,
  name: string,
  onClose: () => void,
  centered: boolean
  closeHeader?: boolean
}

const defaultProps = {
  backdrop: true,
  closeOnBackdrop: true,
  size: 'md',
  headerTitle: "Acatha",
  centered: false,
  closeHeader: true,
}

/*
  children?: ChildElement;
  className?: className;
  innerRef?: innerRef;
  show?: boolean;
  centered?: boolean;
  size?: '' | 'sm' | 'lg' | 'xl';
  backdrop?: boolean;
  color?: string;
  borderColor?: string;
  onOpened?: Function;
  onClosed?: Function;
  fade?: boolean;
  closeOnBackdrop?: boolean;
  onClose?: Function;
  addContentClass?: string;
  centered: true
*/

const Modalform = (props: ModalformProps) => {

  return (
    <>

      <CModal
        show={props.show}
        onClose={() => props.onClose()}
        size={props.size}
        backdrop={props.backdrop}
        closeOnBackdrop={props.closeOnBackdrop}
        name={props.name}
        id={props.name}
        key={props.name}
        centered={props?.centered}
      >

        < CModalHeader closeButton={props.closeHeader}>
          <CModalTitle>{props.headerTitle}</CModalTitle>
        </CModalHeader>

        <CModalBody>

          {props.childrenBody}

        </CModalBody>
        {props.childrenFooter && (
          <CModalFooter>
            {props.childrenFooter}
          </CModalFooter>
        )}
      </CModal>

    </>
  )
}

Modalform.defaultProps = defaultProps;

export default Modalform
