import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TiposComprobantesSri } from "../../../../../store/types";
import { ButtonTypes } from "../../../../../views/componentes/globalMenu/types";
import { StatesEdition, TabTypes } from "../../../types/enums";
import { CustomButtons, TabsState, TabState } from "../../../types/generics";
import { VentaListado } from "../../../types/types";
import { v4 as uuidv4 } from "uuid";
import { ventasCloseTab, existeTab } from "../../../store/reducers";
import { CreditNoteButtons } from "../../../components/notasCredito/notaCredito/notaCredito";

const initialState: TabsState<VentaListado> = {
  current: "BUSQUEDA",
  tabs: {
    BUSQUEDA: {
      id: 0,
      canClose: false,
      editStatus: StatesEdition.doesNotApply,
      globalButtonClick: ButtonTypes.none,
      tittle: "Búsqueda",
      type: TabTypes.busqueda,
      imprimir: false,
      tabKey: "BUSQUEDA",
      buttons:{
        Buscar: true,
        Nuevo: true,
        Exportar: false,
      }
    },
  },
};


const tabsNotasCreditoSlice = createSlice({
  name: "notasCreditoTabs",
  initialState: initialState,
  reducers: {
    ventasCloseTab,
    openTab(
      state,
      action: PayloadAction<{ key: string; venta?: VentaListado }>
    ) {
      const { key, venta } = action.payload;
      if (venta === undefined) {
        // if (existeTabNuevo(state.tabs as any)) {
        //   state.current = state.tabs.findIndex((t) => t.id == -1);
        //   return;
        // }
        const tab: TabState<VentaListado> = {
          id: -1,
          canClose: true,
          editStatus: StatesEdition.new,
          globalButtonClick: ButtonTypes.none,
          tittle: "Nueva",
          type: TabTypes.documento,
          imprimir: false,
          tabKey: key,
          info: {
            claveAcceso: "",
            numero: 0,
            tipoComprobante: TiposComprobantesSri.NotaCredito,
          },
          buttons:CreditNoteButtons
        };
        state.tabs[key] = tab;
        state.current = key;
      } else {
        const tabKey = existeTab(state.tabs, venta.comprobante);
        if (tabKey) {
          state.current = tabKey;
        } else {
          const tab: TabState<VentaListado> = {
            tabKey: action.payload.key,
            id: venta.comprobante,
            tittle: `${venta.establecimiento}-${venta.puntoEmision}-${venta.numero}`,
            canClose: true,
            editStatus: StatesEdition.save,
            type: TabTypes.documento,
            info: {
              claveAcceso: venta.claveAcceso,
              numero: venta.comprobante,
              tipoComprobante: TiposComprobantesSri.NotaCredito,
              info: action.payload.venta,
            },
            globalButtonClick: ButtonTypes.none,
            imprimir: false,
            buttons:CreditNoteButtons
          };
          state.tabs[key] = tab;
          state.current = key;
        }
      }
    },
    changeEditStatus(
      state,
      action: PayloadAction<{
        tabKey: string;
        estado: StatesEdition;
        info?: VentaListado;
        buttons:CustomButtons
      }>
    ) {
      const {estado, tabKey, info, buttons} = action.payload;
      state.tabs[tabKey].editStatus = estado;
      state.tabs[tabKey].currentExecutingAction = undefined;
      state.tabs[tabKey].buttons = buttons;
      if (info) {
        state.tabs[tabKey].info = {
          claveAcceso: info.claveAcceso,
          numero: info.comprobante,
          tipoComprobante: TiposComprobantesSri.NotaCredito,
          info: info ?? null,
        };
        state.tabs[tabKey].id = info.comprobante;
        state.tabs[
          action.payload.tabKey
        ].tittle = `${info.establecimiento}-${info.puntoEmision}-${info.numero}`;
      }
    },
    buttonClick(
      state,
      action: PayloadAction<{ tabKey: string; button: ButtonTypes }>
    ) {
      state.tabs[action.payload.tabKey].globalButtonClick =
        action.payload.button;
    },
    clearButtonClick(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.tabs[action.payload].globalButtonClick = ButtonTypes.none;
      }
      // if (action.payload < state.tabs.length) {
      //   state.tabs[action.payload].globalButtonClick = ButtonTypes.none;
      // }
    },
    changeCurrentTab(state, action: PayloadAction<string>) {
      if (Object.prototype.hasOwnProperty.call(state.tabs, action.payload)) {
        state.current = action.payload;
      }
      // if (action.payload >= state.tabs.length) return;
      // state.current = action.payload;
    },
    setTabInfoAsInactive(state, action: PayloadAction<string>) {
      const info = state.tabs[action.payload].info;
      if (info !== undefined) {
        if (info.info) {
          info.info.estado = "ANULADO";
          info.info.estadoElectronico = "ANULADO";
        }
        state.tabs[action.payload].info = info;
      }
    },
    setButtons(state, action:PayloadAction<{tabKey:string, buttons:CustomButtons}>){
      const { buttons, tabKey } = action.payload;
      state.tabs[tabKey].buttons = buttons;
    },
    setCurrentExecutingAction(state, action:PayloadAction<{tabKey:string, buttonType: ButtonTypes | undefined}>){
      const { buttonType, tabKey } = action.payload;
      state.tabs[tabKey].currentExecutingAction = buttonType;
    }
  },
});

//export const selectMenuVentas = (state: RootState) => { return state.ventas.menuVentas };
export const {
  openTab,
  ventasCloseTab: closeTab,
  changeEditStatus,
  buttonClick,
  clearButtonClick,
  changeCurrentTab,
  setTabInfoAsInactive,
  setButtons,
  setCurrentExecutingAction
} = tabsNotasCreditoSlice.actions;
export const tabsReducer = tabsNotasCreditoSlice.reducer;
